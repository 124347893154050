package com.osg.utils

import com.osg.truebase.data.user.GeoLocationDegrees
import kotlin.math.*

const val EARTH_RADIUS_KM: Double = 6371.0


/**
 * Calculates the Haversine distance between this location and the destination location.
 * For more information, see [Haversine formula](https://en.wikipedia.org/wiki/Haversine_formula#:~:text=The%20haversine%20formula%20determines%20the,and%20angles%20of%20spherical%20triangles).
 */
fun haversine(
    srcLocation: GeoLocationDegrees,
    destLocation: GeoLocationDegrees
): Double {
    val dPhi = (destLocation.lat - srcLocation.lat).toRadians()
    val dLambda = (destLocation.lang - srcLocation.lang).toRadians()
    val phiSrc = srcLocation.lat.toRadians()
    val phiDst = destLocation.lat.toRadians()
    val a = sin(dPhi/2.0).pow(2) + cos(phiSrc) * cos(phiDst) * sin(dLambda/2).pow(2)
    val c = 2.0 * atan2(sqrt(a), sqrt(1.0-a))
    return EARTH_RADIUS_KM * c
}