package com.osg.truebase.ui.button

import androidx.compose.animation.animateContentSize
import androidx.compose.animation.core.Spring
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.core.spring
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.outlined.MoreVert
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.unit.DpOffset
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.unit.dp
import com.osg.ui.core.resources.string
import com.osg.truebase.ui.button.FabItem
import com.osg.truebase.ui.ds.GeneralIconButtonItem
import com.osg.truebase.ui.ds.IGeneralIconButtonUi
import com.osg.ui.core.commonStates.TrueVector
import com.osg.ui.core.commonStates.asVector
import com.osg.ui.core.modifirer.noRippleClickable
import com.osg.ui.core.resources.UiText


@Composable
fun DropDownAnimatingIcon(
    dropDownOptions: List<GeneralIconButtonItem>,
    modifier: Modifier = Modifier,
) {
    var isSelected by remember { mutableStateOf(false) }
    val layoutDirection = LocalLayoutDirection.current
    val gearAnimationDegree = remember {
        if (layoutDirection == LayoutDirection.Ltr) {
            90f
        } else {
            -90f
        }
    }
    val degree = animateFloatAsState(
        targetValue = if (isSelected) gearAnimationDegree else 0f,
        animationSpec = spring(
            dampingRatio = 0.3f,
            stiffness = Spring.StiffnessVeryLow
        ),
        label = "degreeAnimation"
    )

    BadgedBox(
        modifier = modifier.noRippleClickable { isSelected = true },
        badge = {},
    ) {
        Icon(
            modifier = Modifier.align(Alignment.CenterEnd)
                .rotate(degree.value),
            imageVector = Icons.Outlined.MoreVert,
            contentDescription = null,
            tint = LocalContentColor.current
        )
        DropDownMenu(dropDownOptions, isSelected) {
            isSelected = false
        }
    }
}

@Composable
fun OptionsButton(
    dropDownOptions: List<GeneralIconButtonItem>,
    imageVector: ImageVector,
) {
    var clicked by remember { mutableStateOf(false) }
    IconButton(
        modifier = Modifier,
        onClick = { clicked = true }
    ) {
        Icon(
            modifier = Modifier,
            imageVector = imageVector,
            contentDescription = null
        )
        DropDownMenu(dropDownOptions, clicked) {
            clicked = false
        }
    }
}

@Composable
fun DropDownMenu(
    buttonContents: List<GeneralIconButtonItem>,
    expanded: Boolean,
    onDismissRequest: () -> Unit
) {
    DropdownMenu(
        modifier = Modifier.Companion.animateContentSize(),
        expanded = expanded,
        onDismissRequest = onDismissRequest,
        offset = DpOffset(0.dp, 0.dp)
    ) {
        buttonContents.forEach { buttonContent ->
            DropDownItem(
                uiText =  buttonContent.uiText,
                trueVector = buttonContent.trueVector,
                onClick = {
                    buttonContent.onClick()
                    onDismissRequest()
                }
            )
        }
    }
}


@Composable
fun DropDownItem(
    uiText: UiText,
    trueVector: TrueVector,
    onClick: () -> Unit
) {
    DropdownMenuItem(
        text = { Text(uiText.string) },
        leadingIcon = {
            Icon(
                imageVector = trueVector.asVector(),
                contentDescription = null
            )
        },
        onClick = onClick
    )
}