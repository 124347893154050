package osg.uiZone.matchZone.journey

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.style.LineHeightStyle
import androidx.compose.ui.unit.dp

@Composable
fun PanelCard(
    title: String,
    description: String,
    cardValueText: String,
    imageVector: ImageVector,
    modifier: Modifier = Modifier.Companion,
) {
    Column(
        modifier = modifier.padding(16.dp),
        verticalArrangement = Arrangement.spacedBy(10.dp)
    ) {
        Text(
            style = MaterialTheme.typography.titleMedium.copy(
                fontWeight = journeyTitleWeight
            ),
            text = title
        )
        Row(
            modifier = Modifier,
            horizontalArrangement = Arrangement.spacedBy(8.dp)
        ) {
            Icon(
                imageVector = imageVector,
                contentDescription = title,
            )
            Text(
                text = cardValueText,
                style = MaterialTheme.typography.titleLarge.copy(
                    lineHeightStyle = LineHeightStyle(
                        alignment = LineHeightStyle.Alignment.Top,
                        trim = LineHeightStyle.Trim.Both
                    ),
                    fontWeight = journeyValWeight
                )
            )
        }
        Text(
            style = MaterialTheme.typography.bodySmall,
            text = description,
        )
    }
}