package osg.uiZone.matchZone.state.tmEntUi

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.PlayArrow
import androidx.compose.runtime.Composable
import com.osg.appData.matchZone.invitation.Invitation
import com.osg.ice.ui.comp.DragFab
import osg.uiZone.navigation.IceBreakerDestination

data class TmEntApproved(
    val invitation: Invitation,
    internal val entertainment: NewEntertainmentUiData,
) : TmEntertainmentUiType by entertainment {
    @Composable
    fun EnterGameButton(
        onNavigateToGame: (IceBreakerDestination) -> Unit
    ) {
        DragFab(
            fabIcon = Icons.Default.PlayArrow,
            text = "",
            extend = true,
            onClick = {
                onNavigateToGame(
                    IceBreakerDestination(
                        invitation
                    )
                )
            },
        )
    }
}