package osg.uiZone.navigation

import kotlinx.serialization.Serializable

@Serializable
sealed interface SearchDestination : Destination {
    @Serializable
    data object SearchMain : SearchDestination

    @Serializable
    data object Preference: SearchDestination
}