package osg.uiZone.matchZone.chat

import androidx.compose.animation.Crossfade
import androidx.compose.animation.core.*
import androidx.compose.foundation.background
import androidx.compose.foundation.gestures.detectDragGesturesAfterLongPress
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Send
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.dialog.FunctionalityNotAvailablePopup
import com.osg.truebase.ui.graphics.coloredRippleAnimationModifier
import com.osg.ui.core.resources.string
import kotlinx.coroutines.delay
import osg.uiZone.matchZone.icons.Mic
import osg.uiZone.search.components.SwingIconButton
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.swipe_to_cancel_recording
import kotlin.math.abs
import kotlin.math.absoluteValue
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds


@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun MultiActionButton(
    actionButtonState: ActionButtonState,
    recording: Boolean,
    onSendText: () -> Unit,
    onStartRecording: () -> Boolean,
    onFinishRecording: () -> Unit,
    onCancelRecording: () -> Unit,
    modifier: Modifier = Modifier
) {
    Row(
        modifier = modifier
    ) {
        var swipeOffset by remember { mutableStateOf(0f) }
        if (recording) {
            RecordGestureIndicator(
                modifier = Modifier,
                swipeOffset = { swipeOffset }
            )
        }
//        val scope = rememberCoroutineScope()
//        val tooltipState = remember { TooltipState() }
        Box(
            modifier = modifier
                .coloredRippleAnimationModifier(
                    start = recording,
                    colors = List(3){ LocalContentColor.current }
                )
        ) {
            Crossfade(targetState = actionButtonState, label = "cross fade") { actionButton ->
                when (actionButton) {
                    ActionButtonState.Text -> {
                        SwingIconButton(
                            modifier = Modifier,
                            imageVector = Icons.AutoMirrored.Filled.Send,
                            onClick = onSendText
                        )
                    }

                    ActionButtonState.Record -> {
                        //TODO implement recording
                        var functionalityNotAvailablePopupShown by remember { mutableStateOf(false) }
                        if (functionalityNotAvailablePopupShown) {
                            FunctionalityNotAvailablePopup { functionalityNotAvailablePopupShown = false }
                        }
                        SwingIconButton(
                            modifier = Modifier,
                            imageVector = Icons.Default.Mic,
                            onClick = {
                                functionalityNotAvailablePopupShown = true
                            }
                        )
//                        TooltipBox(
//                            positionProvider = TooltipDefaults.rememberRichTooltipPositionProvider(),
//                            tooltip = {
//                                RichTooltip {
//                                    Text(Res.string.touch_and_hold_to_record.string)
//                                }
//                            },
//                            enableUserInput = false,
//                            state = tooltipState
//                        ) {
//                            Icon(
//                                imageVector = Icons.Default.Mic,
//                                contentDescription = "Record",
//                                modifier = Modifier
//                                    .voiceRecordingGesture(
//                                        horizontalSwipeProgress = { swipeOffset },
//                                        onSwipeProgressChanged = { swipeOffset = it },
//                                        onClick = { scope.launch { tooltipState.show() } },
//                                        onStartRecording = onStartRecording,
//                                        onFinishRecording = onFinishRecording,
//                                        onCancelRecording = onCancelRecording,
//                                    )
//                            )
//                        }
                    }
                }
            }

        }
    }

}


private fun Modifier.voiceRecordingGesture(
    horizontalSwipeProgress: () -> Float,
    onSwipeProgressChanged: (Float) -> Unit,
    onClick: () -> Unit = {},
    onStartRecording: () -> Boolean = { false },
    onFinishRecording: () -> Unit = {},
    onCancelRecording: () -> Unit = {},
    swipeToCancelThreshold: Dp = 200.dp,
    verticalThreshold: Dp = 80.dp,
): Modifier = this
    .pointerInput(Unit) { detectTapGestures { onClick() } }
    .pointerInput(Unit) {
        var offsetY = 0f
        var dragging = false
        val swipeToCancelThresholdPx = swipeToCancelThreshold.toPx()
        val verticalThresholdPx = verticalThreshold.toPx()

        detectDragGesturesAfterLongPress(
            onDragStart = {
                onSwipeProgressChanged(0f)
                offsetY = 0f
                dragging = true
                onStartRecording()
            },
            onDragCancel = {
                onCancelRecording()
                dragging = false
            },
            onDragEnd = {
                if (dragging) {
                    onFinishRecording()
                }
                dragging = false
            },
            onDrag = { change, dragAmount ->
                if (dragging) {
                    onSwipeProgressChanged(horizontalSwipeProgress() + dragAmount.x)
                    offsetY += dragAmount.y
                    val offsetX = horizontalSwipeProgress()
                    if (
                        offsetX < 0 &&
                        abs(offsetX) >= swipeToCancelThresholdPx &&
                        abs(offsetY) <= verticalThresholdPx
                    ) {
                        onCancelRecording()
                        dragging = false
                    }
                }
            }
        )
    }


@Composable
fun RecordingIndicator(
    modifier: Modifier = Modifier,
) {

    Row(
        modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically
    ) {
        RecordCounter()
    }
}

@Composable
fun RecordGestureIndicator(
    modifier: Modifier = Modifier,
    swipeOffset: () -> Float
) {
    Box(
        modifier = modifier
    ) {
        val swipeThreshold = with(LocalDensity.current) { 200.dp.toPx() }
        Text(
            modifier = Modifier
                .align(Alignment.Center)
                .graphicsLayer {
                    translationX = swipeOffset() / 2
                    alpha = 1 - (swipeOffset().absoluteValue / swipeThreshold)
                },
            textAlign = TextAlign.Center,
            text = Res.string.swipe_to_cancel_recording.string,
            style = MaterialTheme.typography.bodyLarge
        )
    }
}

@Composable
fun RecordCounter(
    modifier: Modifier = Modifier
) {
    var duration by remember { mutableStateOf(Duration.ZERO) }
    LaunchedEffect(Unit) {
        while (true) {
            delay(1000)
            duration += 1.seconds
        }
    }
    val infiniteTransition = rememberInfiniteTransition(label = "pulse")

    val animatedPulse = infiniteTransition.animateFloat(
        initialValue = 1f,
        targetValue = 0.2f,
        animationSpec = infiniteRepeatable(
            tween(2000),
            repeatMode = RepeatMode.Reverse
        ),
        label = "pulse",
    )
    Box(
        modifier
            .size(56.dp)
            .padding(24.dp)
            .graphicsLayer {
                scaleX = animatedPulse.value; scaleY = animatedPulse.value
            }
            .clip(CircleShape)
            .background(Color.Red)
    )
    Text(
        duration.toComponents { minutes, seconds, _ ->
            val min = minutes.toString().padStart(2, '0')
            val sec = seconds.toString().padStart(2, '0')
            "$min:$sec"
        },
    )
}