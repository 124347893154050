package com.osg.appData.bots

enum class TestUsers{
    MaleTestUser,
    MaleTestUser2,
    FemaleTestUser;
    companion object {
        fun isTesterUid(uid: String?): Boolean{
            return uid?.startsWith(TEST_USER_UID_PREFIX) == true
        }
        private const val TEST_USER_UID_PREFIX = "testUserUidPrefix"

        fun emulatedMail(uid: String): String{
            return "${uid.lowercase()}@test.com"
        }

        fun emulatedPassword(uid: String): String{
            return "password$uid"
        }
    }

    val uid: String
        get() = "${TEST_USER_UID_PREFIX}_${this.name}"

    val email: String
        get() = emulatedPassword(this.uid)


    val password: String
        get() = emulatedPassword(this.uid)
}