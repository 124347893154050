package com.osg.appData.relationshipStage

import com.osg.appData.common.SettingsService
import com.osg.appData.matchZone.MatchInfo
import com.osg.utils.time.TimeService.daysDifferenceFromNow

fun MatchInfo.computeRelationshipStage(): Int {
    val daysPassed = matchTime.daysDifferenceFromNow().toInt()
    return 1 + daysPassed / SettingsService.onlineSettings.daysStageRatio
}

fun MatchInfo.icePotentialGain(): Int {
    // TODO: Implement this
    return 20
}