package osg.uiZone.search

import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavController
import androidx.navigation.NavGraphBuilder
import androidx.navigation.compose.composable
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.ui.commonStates.MainViewUi
import osg.uiZone.navigation.MatchZonePromo
import osg.uiZone.navigation.SearchDestination
import osg.uiZone.search.ds.CheckMatchState
import osg.uiZone.search.screens.SearchPreferencesScreen
import osg.uiZone.search.screens.SearchScreen

fun NavGraphBuilder.searchGraph(
    navController: NavController,
    showTopBar: (Boolean) -> Unit
) {
    composable<SearchDestination.SearchMain> { backStackEntry ->
        AppLogger.d("SearchGraph", "SearchScreen entrance")
        val searchViewModel = viewModel { SearchViewModel() }
        val screenState by searchViewModel.searchUiState.collectAsState()
        val matchStateFlow by searchViewModel.matchStateFlow.collectAsState()
        SearchScreen(
            searchScreenState = screenState,
            onDecision = searchViewModel::onDecision,
            onNotificationApproved = searchViewModel::onNotificationApproved,
            onPreferences = {
                navController.navigate(
                    SearchDestination.Preference
                )
            },
            showTopBar = showTopBar
        )

        LaunchedEffect(matchStateFlow){
            if (matchStateFlow is CheckMatchState.Match){
                navController.navigate(
                    MatchZonePromo
                ){
                    popUpTo(SearchDestination.SearchMain::class) {
                        inclusive = true
                    }
                }
            }
        }
    }

    composable<SearchDestination.Preference> {
        val preferencesScreenViewModel = viewModel {
            SearchPreferencesScreenViewModel()
        }
        val uiState by preferencesScreenViewModel.prefrencesState.collectAsState()
        uiState.MainViewUi { preferencesMatch ->
            SearchPreferencesScreen(
                preferencesMatch = preferencesMatch,
                savePreferences = preferencesScreenViewModel::savePrefrences
            )
        }
    }
}

