package database

import io.ktor.client.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.serialization.json.Json

object DatabaseClient {
    const val DATABASE_URL = "https://truematch-9d5af-default-rtdb.firebaseio.com"
    private val client = HttpClient()

    suspend fun push(
        path: String,
        body: Map<String, String>
    ) {
        val fullUrl = "$DATABASE_URL/$path.json"
        val serializedBody = Json.encodeToString(body)
        val response: HttpResponse = client.post(fullUrl) {
            contentType(ContentType.Application.Json)
            setBody(serializedBody)
        }

        println("Response: ${response.status.value}")
    }
}