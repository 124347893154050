package osg.uiZone.common.pyrotechnics.pager

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.dp


@OptIn(ExperimentalFoundationApi::class)
@Composable
fun SlidingIndicator(
    totalPages: Int,
    pageFraction: () -> Float,
    modifier: Modifier
){
    val dotHeight = 6.dp
    val dotWidth = dotHeight*5
    val spacing = 10.dp
    val activeColor = Color.White
    val distance = with(LocalDensity.current) { (dotWidth + spacing).toPx() }
    val inactiveColor = Color.White.copy(alpha = 0.5f)
    Box(
        modifier = modifier.wrapContentSize().graphicsLayer {
            if (pageFraction() > totalPages - 2) {
                val distanceFromLastPage = totalPages - pageFraction() - 1
                alpha = distanceFromLastPage
            }
        },
        contentAlignment = Alignment.CenterStart
    ) {
        Row(
            horizontalArrangement = Arrangement.spacedBy(spacing),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            repeat(totalPages) {
                Box(
                    modifier = Modifier
                        .size(width = dotWidth, height = dotHeight)
                        .background(
                            color = inactiveColor,
                            shape = RoundedCornerShape(3.dp)
                        )
                )
            }
        }
        Box(
            Modifier
                .graphicsLayer {
                    translationX = pageFraction() * distance
                }
                .size(width = dotWidth, height = dotHeight)
                .background(
                    color = activeColor,
                    shape = RoundedCornerShape(3.dp),
                )
        )
    }
}