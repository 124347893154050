package com.osg.appData.profile

import com.osg.truebase.data.user.Gender
import kotlinx.serialization.Serializable


@Serializable
data class TechnicalProfile(
    val age: Int, // TODO change to date of birth
    val gender: Gender,
    val isSingle: Boolean = true,
)


fun FullProfile.asTechnical(): TechnicalProfile {
    return TechnicalProfile(
        age = this.publicProfile.age,
        gender = this.publicProfile.gender!!,
    )
}