package osg.uiZone.matchZone.entertainment

import androidx.compose.ui.graphics.Color
import com.osg.appData.matchZone.invitation.EntertainmentType
import com.osg.truebase.data.resources.ILocalAnimations
import org.jetbrains.compose.resources.StringResource
import osg.uiZone.matchZone.entertainment.game.GameEnt
import osg.uiZone.matchZone.entertainment.spark.SparkEnt

abstract class EntertainmentProperties {
    abstract val loadingAnimation: ILocalAnimations
    abstract fun getType(): EntertainmentType
    abstract fun getFeedTitleResource(): StringResource
    abstract fun getIngredientTitleResource(): StringResource
    abstract fun getGradientColors():  List<Color>
    abstract fun getDetailsTitleResource(): StringResource
}

val EntertainmentType.properties: EntertainmentProperties
    get() = when (this) {
        EntertainmentType.GAME -> GameEnt
        EntertainmentType.SPARK -> SparkEnt
    }
