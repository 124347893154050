package osg.uiZone.common.vmRepo

import com.osg.truebase.ui.datastore.IDataStore
import org.koin.mp.KoinPlatform.getKoin
import osg.uiZone.common.baseRepos.ICommonMessagingService
import osg.uiZone.common.baseRepos.MessagingService
import osg.uiZone.common.baseRepos.profile.IMyProfileDependencies
import osg.uiZone.common.baseRepos.profile.IPresence
import osg.uiZone.common.baseRepos.profile.MyProfileScope.Companion.myProfileHandler


interface IEntryModelRepository: IDataStore, ICommonMessagingService, IPresence

class EntryModelRepositoryImpl(
) : ICommonMessagingService by MessagingService(),
    IDataStore by getKoin().get<IDataStore>(),
    IMyProfileDependencies by myProfileHandler,
    IEntryModelRepository