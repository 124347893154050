package com.osg.matchmaker.ml.fake

import com.osg.appData.ml.LlmAdminRequest
import com.osg.appData.ml.LlmOutput
import com.osg.matchmaker.ml.LlmInstructions.PROMOTION_INSTRUCTION

fun getFakeLovingBotOutput(llmAdminRequest: LlmAdminRequest): LlmOutput {
    return if (llmAdminRequest.instruction == PROMOTION_INSTRUCTION) {
        LlmOutput(
            results = llmAdminRequest.prompts.map {
                "based on your shared interests, I think you two will get along well"
            }
        )
    } else if (llmAdminRequest.instruction.contains("human")) {
        LlmOutput(
            results = llmAdminRequest.prompts.map {
                "sorry I am not feeling well today, let's talk tomorrow"
            }
        )
    } else {
        LlmOutput(
            results = llmAdminRequest.prompts.map {
                "I believe the next candidates will be a better match for you"
            }
        )
    }
}