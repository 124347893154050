@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.osg.truebase.ui.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.FontResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainFont0 {
  public val lora_variable_font_wght: FontResource by 
      lazy { init_lora_variable_font_wght() }
}

@InternalResourceApi
internal fun _collectCommonMainFont0Resources(map: MutableMap<String, FontResource>) {
  map.put("lora_variable_font_wght", CommonMainFont0.lora_variable_font_wght)
}

internal val Res.font.lora_variable_font_wght: FontResource
  get() = CommonMainFont0.lora_variable_font_wght

private fun init_lora_variable_font_wght(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:lora_variable_font_wght",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui.generated.resources/font/lora_variable_font_wght.ttf", -1, -1),
    )
)
