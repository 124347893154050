package com.osg.matchmaker.matchZone

import com.osg.appData.candidate.UnMatchRequest
import com.osg.appData.common.DataRoot.Companion.matchZoneDataPaths
import com.osg.appData.functions.FunctionResponseType
import com.osg.appData.matchZone.Message
import com.osg.appData.ml.LlmUnmatchChatRequest
import com.osg.appData.profile.MatchStatus
import com.osg.appData.profile.MatchZoneData
import com.osg.appData.resource.SpecsQueries.MATCH_MAKER_ID
import com.osg.matchmaker.ml.IMlHandler
import com.osg.matchmaker.ml.LlmInstructions.getUnmatchInstruction
import com.osg.matchmaker.ml.buildChatLlmMessage
import com.osg.matchmaker.singleTransaction.ISingleTransaction
import com.osg.matchmaker.singleTransaction.SingleTransactionHandler
import com.osg.truebase.data.logger.AppLogger
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put

interface IMatchZoneCfRepo : IMlHandler, ISingleTransaction {
    suspend fun pushMassage(path: String, data: Message)
    suspend fun removeNode(path: String)
    suspend fun updateMatchStatus(path: String, data: JsonElement)
}

class UnMatchZoneCf(
    private val dbHandler: IMatchZoneCfRepo,
) {
    val transactionHandler: SingleTransactionHandler = SingleTransactionHandler(
        singleTransaction = dbHandler
    )

    suspend fun handleUnmatchChatMessage(llmUnmatchChatRequest: LlmUnmatchChatRequest) {
        val instruction = getUnmatchInstruction(
            llmUnmatchChatRequest.myProfile,
            llmUnmatchChatRequest.candidateProfile
        )
        val sortedMessages = llmUnmatchChatRequest.messages.sortedBy { it.timestamp }
        val adminRequest = buildChatLlmMessage(
            historyMessages = llmUnmatchChatRequest.messages,
            instruction = instruction
        )
        val response = dbHandler.lovingBotRequest(adminRequest)
        val message = Message(
            uid = MATCH_MAKER_ID,
            text = response.results.first(),
            timestamp = sortedMessages.last().timestamp + 30
        )

        dbHandler.pushMassage(
            data = message,
            path = llmUnmatchChatRequest.chatPath
        )
    }

    suspend fun onUnMatched(
        unMatchRequest: UnMatchRequest
    ): FunctionResponseType {
        val matchZoneDataPath = matchZoneDataPaths(unMatchRequest.requesterIdentity.uid)
        dbHandler.removeNode(matchZoneDataPath)
        val otherUserDataPath = matchZoneDataPaths(unMatchRequest.matchIdentity.uid)
        dbHandler.updateMatchStatus(otherUserDataPath, buildJsonObject {
            put(
                MatchZoneData::matchStatus.name,
                MatchStatus.UnMatchedByOther.name
            )
        })
        transactionHandler.transactSingleState(
            unMatchRequest.requesterIdentity,
            targetIsSingle = true
        )
        transactionHandler.transactSingleState(
            unMatchRequest.matchIdentity,
            targetIsSingle = true
        )

        // TODO: notify the other user
        AppLogger.d(
            "onUnmatch",
            "unmatch ${unMatchRequest.requesterIdentity} with ${unMatchRequest.matchIdentity}"
        )
        return FunctionResponseType.SUCCESS
    }

}