package com.osg.truebase.ui.theme.typography

import androidx.compose.material3.Typography
import androidx.compose.runtime.Composable
import androidx.compose.ui.text.font.FontFamily
import com.osg.truebase.ui.generated.resources.Res
import com.osg.truebase.ui.generated.resources.lora_variable_font_wght
import org.jetbrains.compose.resources.Font
import org.jetbrains.compose.resources.FontResource
import osg.cross.theme.typography.unifiedTypography

val trueMatchTypography: Typography
    @Composable
    get() {
        return LoadTypography(
            variableFont = Res.font.lora_variable_font_wght,
        )
    }


@Composable
fun LoadTypography(variableFont: FontResource): Typography {
    val loadedFont = Font(variableFont)
    val variableFontFamily = FontFamily(loadedFont)
    return variableFontFamily.unifiedTypography
}