package com.osg.truematch.demo
import challenge.trueAutoResources.IceMediaLocalChallengeAllMedia
import com.osg.previewLab.ice.MultiMediaDownloaderSimImpl
import com.osg.truematch.demo.baseModuleSim.GeoSim
import harmoney.trueAutoResources.IceMediaLocalHarmoneyAllMedia
import osg.uiZone.graph.TrueMatchCommonApp

val tmPreviewApp: TrueMatchCommonApp by lazy {
    TrueMatchCommonApp(
        geoService = ::GeoSim,
        iceMultiMediaDownloader = { MultiMediaDownloaderSimImpl(
            localRemoteAllMedia = IceMediaLocalChallengeAllMedia + IceMediaLocalHarmoneyAllMedia,
            delayTime = 0L,
        ) },
    )
}

