package com.osg.truebase.ui.assigners.def

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier

interface GenObjAssigner<T> {
    @Composable
    fun content(
        modifier: Modifier,
        current: T?,
        onAnswered: (T) -> Unit
    )
}

fun <ASSIGNER_TYPE, TARGET> GenObjAssigner<ASSIGNER_TYPE>.genObjTransformer(
    reverseTransformer: (TARGET) -> ASSIGNER_TYPE?,
    transformer: (ASSIGNER_TYPE, TARGET?) -> TARGET,
): GenObjAssigner<TARGET>{
    return object : GenObjAssigner<TARGET> {
        @Composable
        override fun content(
            modifier: Modifier,
            current: TARGET?,
            onAnswered: (TARGET) -> Unit
        ) {
            var data = current?.let(reverseTransformer)
            content(
                modifier = modifier,
                current = data
            ){ res ->
                data = res
                onAnswered(transformer(res, current))
            }
        }
    }
}

