package com.osg.truebase.ui.ds

import com.osg.ui.core.commonStates.TrueVector
import com.osg.ui.core.resources.UiText

interface IGeneralIconButtonUi {
    val trueVector: TrueVector
    val uiText: UiText
}

data class GeneralIconButtonUi(
    override val trueVector: TrueVector,
    override val uiText: UiText,
): IGeneralIconButtonUi

fun IGeneralIconButtonUi.toItem(onClick: () -> Unit): GeneralIconButtonItem {
    return GeneralIconButtonItem(
        trueVector = trueVector,
        uiText = uiText,
        onClick = onClick
    )
}

data class GeneralIconButtonItem(
    override val trueVector: TrueVector,
    override val uiText: UiText,
    val onClick: () -> Unit
): IGeneralIconButtonUi