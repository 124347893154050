package com.osg.di

import androidx.compose.runtime.Composable
import androidx.navigation.NavHostController
import androidx.navigation.compose.rememberNavController
import com.osg.def.auth.AuthService
import com.osg.def.database.DatabaseService
import com.osg.def.database.IAppConfig
import com.osg.def.database.IPlatformStorage
import com.osg.def.database.LaunchStringResolver
import com.osg.ice.IceRepository
import com.osg.ice.IceRepositoryImpl
import com.osg.ice.challengeAccepted.ChallengeAccepted
import com.osg.ice.harmonyTunes.ui.HarmonyTunesImpl
import com.osg.truebase.data.iceDef.IceMultiMediaDownloader
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.logger.PlatformLoggerFactory
import com.osg.truebase.player.audio.TrueAudioPlayer
import com.osg.truebase.ui.datastore.IDataStore
import org.koin.core.component.KoinComponent
import org.koin.core.component.get
import org.koin.core.context.loadKoinModules
import org.koin.core.definition.KoinDefinition
import org.koin.core.module.Module
import org.koin.core.parameter.ParametersHolder
import org.koin.core.scope.Scope
import org.koin.dsl.bind
import org.koin.dsl.module

abstract class IBaseApp(
    resolveEnvironmentConfig: (LaunchStringResolver) -> IAppConfig,
    iceMultiMediaDownloader: Scope.(ParametersHolder) -> IceMultiMediaDownloader,
    datastoreImpl:  Module.() -> KoinDefinition<out IDataStore>,
    appCommonModules: List<Module> = emptyList(),
): KoinComponent {
    init {
        loadKoinModules(
            listOf(
                module {
                    single<IAppConfig> { resolveEnvironmentConfig(get()) }
                    single<IceMultiMediaDownloader>(
                        definition = iceMultiMediaDownloader
                    )
                    factory<TrueAudioPlayer> {
                        TrueAudioPlayer(get())
                    }
                    factory<IceRepository>{
                        IceRepositoryImpl(get())
                    }
                    factory<ChallengeAccepted> {
                        ChallengeAccepted(
                            iceDataStorage = get(),
                            iceBreakerRepository = get(),
                            mediaDbDownloader = get<IPlatformStorage>()
                        )
                    }
                    factory<HarmonyTunesImpl> {
                        HarmonyTunesImpl(
                            htRepoLoader = get(),
                            iceDataStorage = get(),
                            audioPlayerLoader = get()
                        )
                    }
                    single<AuthService> {
                        AuthService(get())
                    }
                    datastoreImpl()
                    single<DatabaseService> {
                        DatabaseService(get())
                    } bind DatabaseService::class
                },
            ) + appCommonModules
        )
        AppLogger.loggerFactory = get<PlatformLoggerFactory>()
        AppLogger.d("App initialization complete")
    }

    @Composable
    abstract fun AppEntry(
        navController: NavHostController = rememberNavController()
    )
}