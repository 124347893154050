package osg.uiZone.matchZone.model

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.stateIn
import kotlinx.coroutines.launch
import osg.uiZone.common.vmRepo.matchZone.MatchScope
import osg.uiZone.search.screens.PromoUiStates

interface IMatchZonePromoRepo{
    fun promoDataFlow(): Flow<PromoUiStates.Ready>
    suspend fun setWatchedPromo()
}

class MatchZonePromoModel(
    matchZonePromoRepo: IMatchZonePromoRepo = MatchScope().matchZoneRepository
) : ViewModel(), IMatchZonePromoRepo by matchZonePromoRepo {
    val uiState: StateFlow<PromoUiStates> = matchZonePromoRepo.promoDataFlow().stateIn(
        scope = viewModelScope,
        started = SharingStarted.WhileSubscribed(5000L),
        initialValue = PromoUiStates.Loading
    )


    init {
        viewModelScope.launch {
            matchZonePromoRepo.setWatchedPromo()
        }
    }
}