package osg.uiZone.common.paint

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.requiredSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

@Composable
fun ImagesDuet(
    modifier: Modifier = Modifier,
    firstImage: Painter,
    secondImage: Painter,
    imageHeight : Dp = 300.dp,
    imageWidth: Dp = imageHeight/1.3f,
    rotationAngle: Float = 15f,
    overLapRatio: Float = 0.25f
) {
    val imageOffset = (imageWidth -imageWidth*overLapRatio)/2f
    val finalPadding = imageHeight * 0.1f
    val cornerSize = finalPadding

    Box(
        modifier = modifier.padding(horizontal = imageOffset*2f, vertical = imageHeight*0.15f),
    ) {
        val commonModifier = Modifier
            .requiredSize(width = imageWidth, height = imageHeight)

        Image(
            modifier = commonModifier
                .offset(x=imageOffset)
                .rotate(rotationAngle)
                .clip(RoundedCornerShape(cornerSize)),

            painter = firstImage,
            contentDescription = null,
            contentScale = ContentScale.Crop
        )
        Image(
            modifier = commonModifier
                .offset(x=-imageOffset)
                .rotate(-rotationAngle)
                .clip(RoundedCornerShape(cornerSize)),
            painter = secondImage,
            contentDescription = null,
            contentScale = ContentScale.Crop
        )
    }
}