package osg.uiZone.common.composeUtils

import androidx.compose.runtime.Composable
import androidx.compose.runtime.SideEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember

@Composable
fun <T> rememberPrevious(
    current: T,
): T? {
    val ref = remember { mutableStateOf(current) }

    // launched after render, so the current render will have the old value anyway
    SideEffect {
        ref.value = current
    }

    return ref.value
}