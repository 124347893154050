package com.osg.truebase.spotlight

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.tween
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.foundation.layout.Box
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.composed
import androidx.compose.ui.layout.LayoutCoordinates
import androidx.compose.ui.layout.onGloballyPositioned
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.update
import kotlin.time.Duration.Companion.milliseconds

data class TutorialStepUiElemetnt(
    val step: ITutorialStep,
    val layoutCoordinates: LayoutCoordinates,
)

// Registry for tutorial targets
class TutorialTargetRegistry(
    storageSeenLoader: Set<String>
) {
    private val collectedTargets = MutableStateFlow(emptyMap<ITutorialStep, LayoutCoordinates>())
    val seenTargets = MutableStateFlow<Set<String>>(storageSeenLoader)

    @OptIn(FlowPreview::class)
    val targets = combine(
        collectedTargets.debounce(1000.milliseconds), // to avoid flickering
        seenTargets
    ) { targets, seen ->
        targets.filterKeys {
            it.id !in seen
        }.map {
            TutorialStepUiElemetnt(
                it.key,
                it.value
            )
        }.sortedBy {
            it.step.idx
        }.toSet()
    }

    fun addTarget(step: ITutorialStep, bounds: LayoutCoordinates) {
        collectedTargets.update { currentMap ->
            currentMap + (step to bounds)
        }
    }

    fun markAsSeen(step: ITutorialStep) {
        seenTargets.update { currentMap ->
            currentMap + step.id
        }
    }
}

private const val STATE_NOT_PROVIDED_MESSAGE = "TutorialTargetRegistry not provided"

val LocalTutorialTargetRegistry = compositionLocalOf<TutorialTargetRegistry> {
    error(STATE_NOT_PROVIDED_MESSAGE)
}


// Custom modifier to mark a composable as a tutorial target
fun Modifier.tutorialTarget(step: ITutorialStep): Modifier = composed {
    val tutorialTargetRegistry = LocalTutorialTargetRegistry.current
    onGloballyPositioned { coordinates ->
        tutorialTargetRegistry.addTarget(step, coordinates)
    }
}

@Composable
fun TutorialScope(
    disabilityDelayMillis: Int = 500,
    seenIds: Set<String>,
    onSeen: (Set<String>) -> Unit,
    content: @Composable () -> Unit,
) {
    CompositionLocalProvider(
        LocalTutorialTargetRegistry provides TutorialTargetRegistry(seenIds),
    ) {
        var screenSizeState by remember { mutableStateOf<LayoutCoordinates?>(null) }
        Box(
            modifier = Modifier
                .onGloballyPositioned { coordinates ->
                    screenSizeState = coordinates
                }
        ) {
            content()
            val localRegistry = LocalTutorialTargetRegistry.current
            val targetState by localRegistry.targets.collectAsState(emptySet())
            AnimatedVisibility(
                visible = targetState.isNotEmpty(),
                enter = fadeIn(animationSpec = tween(500, delayMillis = disabilityDelayMillis)),
                exit = fadeOut(animationSpec = tween(200)),
            ) {
                screenSizeState?.let { screenSize ->
                    targetState.firstOrNull()?.let { top ->
                        TutorialOverlay(
                            tutorialOverlayState = TutorialOverlayState(
                                step = top.step as ITutorialStepUi,
                                targetBounds = screenSize.localBoundingBoxOf(top.layoutCoordinates),
                            ),
                            screenSize = screenSize.size,
                            onSeen = {
                                localRegistry.markAsSeen(it)
                                onSeen(seenIds + it.id)
                            }
                        )
                    }
                }
            }
        }
    }
}