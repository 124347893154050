package com.osg.trumatch.rootdata
import com.osg.appData.matchZone.EntertainmentStore
import com.osg.appData.matchZone.SparkNodeData
import com.osg.truebase.data.iceDef.IceTag
import com.osg.truebase.data.nodeData.toRemoteImage
import com.osg.utils.plus
import root.data.trueAutoResources.SparksMediaRemoteImages

val benedict: SparkNodeData = SparkNodeData(
    description = "Enjoy a picnic with wine, cheese, and pretzels",
    image = SparksMediaRemoteImages.BENEDICT_COUPON.toRemoteImage(),
    details = "",
    ingredients = listOf("Wine", "Cheese", "Pretzels"),
    id = "Benedict",
    name = "Benedict",
    unlockStage = 3,
    value = 20,
    slogan = "Hard to resist the temptation of sushi",
    vibes = IceTag.Intimacy + IceTag.Culinary
)

val BikeSunsetRide: SparkNodeData = SparkNodeData(
    description = "The perfect blend of coziness and charm, for an unforgettable date experience. Sip on our artisanal coffees, crafted with love and precision, as you and your partner indulge in delightful conversations. Our intimate ambiance sets the stage for romance, with soft lighting and comfortable seating arrangements. Whether you're sparking a new connection or nurturing an existing one, COFFEOLLE promises a memorable rendezvous. Escape the hustle and bustle of everyday life and savor the moment together at COFFEOLLE. Reserve your table now for an enchanting coffee date experience like no other. Let love brew at COFFEOLLE! ",
    image = SparksMediaRemoteImages.BIKE.toRemoteImage(),
    details = "",
    ingredients = emptyList(),
    id = "BikeSunsetRide",
    name = "Bike Sunset Ride",
    unlockStage = 4,
    value = 25,
    slogan = "Pedal your way to love",
    vibes = IceTag.Nature + IceTag.Sport
)




val CanvasChemistry: SparkNodeData = SparkNodeData(
    description = "Get creative and connect with your partner in a fun and romantic painting class!",
    image = SparksMediaRemoteImages.PAINT_DATE.toRemoteImage(),
    details = "Unleash your creativity together in our intimate painting class designed for dating couples! Join us for a delightful evening filled with artistic exploration and romantic bonding. Under the guidance of skilled instructors, you'll learn basic painting techniques while expressing your love on canvas. Sip on complimentary wine as you laugh, create, and connect in a relaxed atmosphere. Whether you're seasoned artists or beginners, this class promises to ignite sparks of creativity and deepen your connection in a memorable way. No prior painting experience required – just bring your partner and an open heart!",
    ingredients = emptyList(),
    id = "CanvasChemistry",
    name = "Canvas & Chemistry",
    unlockStage = 2,
    value = 20,
    slogan = "Paint, Create, Connect",
    vibes = IceTag.Romance + IceTag.Party
)

val CoffeeDate: SparkNodeData = SparkNodeData(
    description = "Go to a cozy coffee shop and enjoy much more than just coffee",
    image = SparksMediaRemoteImages.COFFEE.toRemoteImage(),
    details = "the perfect blend of coziness and charm, for an unforgettable date experience. Sip on our artisanal coffees, crafted with love and precision, as you and your partner indulge in delightful conversations. Our intimate ambiance sets the stage for romance, with soft lighting and comfortable seating arrangements. Whether you're sparking a new connection or nurturing an existing one, COFFEOLLE promises a memorable rendezvous. Escape the hustle and bustle of everyday life and savor the moment together at COFFEOLLE. Reserve your table now for an enchanting coffee date experience like no other. Let love brew at COFFEOLLE!",
    ingredients = listOf("Coffee", "Croissant", "Special Cake"),
    id = "COFFEOLLE",
    name = "COFFEOLLE",
    unlockStage = 1,
    value = 15,
    slogan = "Connect with the aroma of coffee",
    vibes = IceTag.Culinary + IceTag.Relax
)



val DizzyFrishdon: SparkNodeData = SparkNodeData(
    description = "Buzzing bar with outdoor seating offering finger foods.",
    image = SparksMediaRemoteImages.COUPON_DIZZY.toRemoteImage(),
    details = "",
    ingredients = listOf("Drinks", "Special Pizza", "Surprises"),
    id = "DizzyFrishdon",
    name = "Dizzy Frishdon",
    unlockStage = 1,
    value = 20,
    slogan = "Get Dizzy",
    vibes = IceTag.Party + IceTag.Funny
)


val EscapeRoom = SparkNodeData(
    description = "Solve puzzles and escape the room in under 60 minutes",
    image = SparksMediaRemoteImages.ESCAPE_ROOM.toRemoteImage(),
    details = "",
    ingredients = emptyList(),
    id = "EscapeRoom",
    name = "Escape Room",
    unlockStage = 3,
    value = 25,
    slogan = "Can you escape?",
    vibes = IceTag.ProblemSolving + IceTag.Mystery
)



val FooSushi = SparkNodeData(
    description = "Japanese sushi restaurant with a variety of rolls and sashimi.",
    image = SparksMediaRemoteImages.FOO_COUPON.toRemoteImage(),
    details = "",
    ingredients = listOf("Sushi Rolls", "Alcohol Drinks", "Dessert"),
    id = "FooSushi",
    name = "Foo Sushi",
    unlockStage = 1,
    value = 25,
    slogan = "Dance on the bar",
    vibes = IceTag.Culinary + IceTag.Party
)


val HorseRiding = SparkNodeData(
    description = "Experience the thrill of horseback riding together as you and your partner embark on a romantic journey through picturesque trails. Let the gentle rhythm of hooves and the beauty of nature set the scene for intimate conversations and shared moments of joy. Whether you're beginners or seasoned riders, this activity offers the perfect blend of adventure and tranquility, creating unforgettable memories to cherish. With each stride, feel the bond between you and your loved one strengthen, as you explore the great outdoors hand in hand. Share laughter, steal glances, and revel in the feeling of freedom as you navigate the trails on these majestic creatures. Horseback riding is not just an activity—it's a chance to connect on a deeper level and create a lasting connection amid the beauty of the natural world. So saddle up and embark on a romantic escapade sure to ignite passion and strengthen your bond.",
    image = SparksMediaRemoteImages.HORSES.toRemoteImage(),
    details = "",
    ingredients = listOf("1 hour ride", "Wine"),
    id = "HorseRiding",
    name = "Horse Riding Date",
    unlockStage = 6,
    value = 80,
    slogan = "Riding into the Sunset",
    vibes = IceTag.Romance + IceTag.Nature
)

val spaIsland = SparkNodeData(
    description = "the perfect blend of coziness and charm, for an unforgettable date experience. Sip on our artisanal coffees, crafted with love and precision, as you and your partner indulge in delightful conversations. Our intimate ambiance sets the stage for romance, with soft lighting and comfortable seating arrangements. Whether you're sparking a new connection or nurturing an existing one, COFFEOLLE promises a memorable rendezvous. Escape the hustle and bustle of everyday life and savor the moment together at COFFEOLLE. Reserve your table now for an enchanting coffee date experience like no other. Let love brew at COFFEOLLE! ",
    image = SparksMediaRemoteImages.SPA.toRemoteImage(),
    details = "",
    ingredients = listOf("Couples Massage", "Spa Facilities"),
    id = "SpaIsland",
    name = "Spa Island",
    unlockStage = 6,
    value = 50,
    slogan = "Relax Together",
    vibes = IceTag.Intimacy + IceTag.Relax
)


val entStore: EntertainmentStore = EntertainmentStore(
    sparks = mapOf(
        benedict.id to benedict,
        BikeSunsetRide.id to BikeSunsetRide,
        CanvasChemistry.id to CanvasChemistry,
        CoffeeDate.id to CoffeeDate,
        DizzyFrishdon.id to DizzyFrishdon,
        EscapeRoom.id to EscapeRoom,
        FooSushi.id to FooSushi,
        HorseRiding.id to HorseRiding,
        spaIsland.id to spaIsland
    )
)