package com.osg.ui.core.resources

import org.jetbrains.compose.resources.StringArrayResource
import org.jetbrains.compose.resources.StringResource

sealed class UiArray {
    data class ArrayValue(val array: List<String>) : UiArray()
    data class StringResourceArray(val array: List<StringResource>) : UiArray()
    data class ArrayResource(val resourceId: StringArrayResource) : UiArray()
    data object Empty : UiArray()
}

fun List<String>.rawToUiArray(): UiArray {
    return UiArray.ArrayValue(this)
}

fun StringArrayResource.toUiArray(): UiArray {
    return UiArray.ArrayResource(this)
}

fun List<StringResource>.toUiArray(): UiArray {
    return UiArray.StringResourceArray(this)
}

operator fun StringResource.plus(other: StringResource): UiArray.StringResourceArray {
    return UiArray.StringResourceArray(listOf(this, other))
}

operator fun UiArray.StringResourceArray.plus(other: StringResource): UiArray {
    return UiArray.StringResourceArray(array + other)
}