package osg.uiZone.matchZone.state.tmEntUi

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Send
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.ui.Modifier
import appData.trueAutoResources.MatchingInterest
import com.osg.appData.matchZone.Eligibility
import com.osg.appData.matchZone.ITmEntertainmentData
import com.osg.appData.matchZone.MatchInfo
import com.osg.appData.matchZone.ice.IcebreakerCollection
import com.osg.appData.matchZone.invitation.EntertainmentType
import com.osg.appData.matchZone.invitation.InvitationContent
import com.osg.appData.matchZone.invitation.InvitationStatus
import com.osg.appData.relationshipStage.computeRelationshipStage
import com.osg.appData.relationshipStage.icePotentialGain
import com.osg.ice.challengeAccepted.ds.dataUi
import com.osg.ice.ui.comp.DragFab
import com.osg.truebase.data.iceDef.InviteAction
import com.osg.truebase.data.iceDef.SharedIcebreakerCollection
import com.osg.ui.core.commonStates.TrueImage
import com.osg.ui.core.resources.UiArray
import com.osg.ui.core.resources.UiText
import com.osg.ui.core.resources.toUiText
import com.osg.ui.core.resources.uiText
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.StringResource
import osg.uiZone.matchZone.entertainment.TMEntertainmentDataUiBase
import osg.uiZone.matchZone.entertainment.baseContent.*
import osg.uiZone.matchZone.entertainment.game.resolveEligibility
import osg.uiZone.matchZone.entertainment.toEntertainmentDataBase
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.allStringResources
import truematch.uizone.generated.resources.game_invitation_text
import truematch.uizone.generated.resources.spark_invitation_text

@OptIn(ExperimentalResourceApi::class)
val MatchingInterest.stringResource: StringResource
    get() {
        return Res.allStringResources[this.stringResourceKey]!!
    }

@Immutable
data class NewEntertainmentUiData(
    override val name: UiText,
    override val slogan: UiText,
    override val description: UiText,
    override val image: TrueImage,
    override val ingredients: UiArray,
    override val eligibility: Eligibility,
    override val value: Int,
    override val enabled: Boolean,
    override val entData: ITmEntertainmentData,
    ) : TmEntertainmentUiType, IEntResponse, ITmEntertainmentData by entData {

    override fun toResponse(response: InviteAction): InvitationContent {
        return toNewInvitationContent()
    }

    @Composable
    override fun FabView(
        modifier: Modifier,
        onOption: (InvitationContent) -> Unit
    ) {
        DragFab(
            fabIcon = Icons.AutoMirrored.Filled.Send,
            text = "",
            extend = true,
            onClick = {
                onOption(
                    toResponse(InviteAction.NEW)
                )
            },
        )
    }
}


fun ITmEntertainmentData.toNewInvitationContent(): InvitationContent {
    return InvitationContentImpl(
        content = id,
        type = type,
        status = InvitationStatus.NEW,
    )
}

fun NewEntertainmentUiData.invitationUiText(invitorName: String): UiText {
    return if (this.type == EntertainmentType.GAME) {
        Res.string.game_invitation_text.toUiText(
            invitorName.uiText,
            this.name
        )
    } else {
        Res.string.spark_invitation_text.toUiText(
            invitorName.uiText,
            this.name
        )
    }
}


fun TMEntertainmentDataUiBase.toEntertainmentDataUi(
    matchInfo: MatchInfo,
): NewEntertainmentUiData {
    return NewEntertainmentUiData(
        name = this.name,
        slogan = this.slogan,
        description = this.description,
        image = this.image,
        value = matchInfo.icePotentialGain(),
        ingredients = this.ingredients,
        eligibility = this.resolveEligibility(
            relationshipStage = matchInfo.computeRelationshipStage(),
        ),
        entData = this,
        enabled = enabled
    )
}

val IcebreakerCollection.Companion.baseContentList: List<TMEntertainmentDataUiBase> by lazy {
    IcebreakerCollection.entries.map {
        it.baseContent
    }
}


val IcebreakerCollection.baseContent: TMEntertainmentDataUiBase
    get() = when (this) {
        IcebreakerCollection.ChallengeAccepted -> {
            SharedIcebreakerCollection.ChallengeAccepted.dataUi.toEntertainmentDataBase(
                unlockStage = 1
            )
        }
        IcebreakerCollection.HarmonyTunes -> {
            SharedIcebreakerCollection.HarmonyTunes.dataUi.toEntertainmentDataBase(
                unlockStage = 1,
            )
        }
        IcebreakerCollection.BlindCanvas -> blindCanvasBase
        IcebreakerCollection.LieAi -> lieAiBase
        IcebreakerCollection.CheckList -> checkListBase
        IcebreakerCollection.InPicture -> inPictureBase
        IcebreakerCollection.Janga -> jangaBase
        IcebreakerCollection.SwipeEscape -> swipeEscapeBase
        IcebreakerCollection.WhoWants -> whoWantsBase
        IcebreakerCollection.PieceOfMatch -> pieceOfMatchBase
        IcebreakerCollection.EmojiResonance -> emojiResonanceBase
    }

