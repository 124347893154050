package osg.uiZone.matchZone.state

import androidx.compose.runtime.Immutable
import com.osg.appData.matchZone.Message


@Immutable
data class UnmatchUiState(
    val isUserUnmatched: Boolean,
    val myUid: String,
    val conversation: List<Message>,
    val unmatchedName: String
)