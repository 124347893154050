package com.osg.ui.core.commonStates

import androidx.compose.ui.graphics.painter.BitmapPainter
import androidx.compose.ui.graphics.painter.Painter
import com.osg.ui.core.imageProcces.scale
import com.osg.ui.core.imageProcces.toImageBitmap


internal data class PainterCacheKey(
    val image: TrueImage,
    val maxDimPixels: Int,
)

internal val imageCache = AsyncCache<PainterCacheKey, Painter>()

suspend fun TrueImage.fromPainterCache(
    maxDimPixels: Int,
): Painter {
    val key = PainterCacheKey(
        image = this,
        maxDimPixels = maxDimPixels,
    )
    return imageCache.getOrLoad(
        key = key
    ) {
        var bitmap = this@fromPainterCache.asBytes().toImageBitmap().scale(maxDimPixels)
        bitmap = bitmap.scale(maxDimPixels)
        BitmapPainter(bitmap)
    }
}