package com.osg.truebase.ui.ds

import com.osg.ui.core.commonStates.TrueVector
import com.osg.ui.core.resources.UiText

data class UrlStringRes(
    override val trueVector: TrueVector,
    override val uiText: UiText,
    val url: String,
): IGeneralIconButtonUi

fun IGeneralIconButtonUi.toUrlStringRes(
    url: String,
): UrlStringRes {
    return UrlStringRes(
        trueVector = trueVector,
        uiText = uiText,
        url = url
    )
}

fun buildCommonUrlStringResList(
    privacyPolicyUrl: String,
    termsOfServiceUrl: String,
    contactMail: String,
): List<UrlStringRes> {
    return listOf(
        PreDefinedButtonUi.PRIVACY_POLICY.toUrlStringRes(privacyPolicyUrl),
        PreDefinedButtonUi.TERMS_OF_SERVICE.toUrlStringRes(termsOfServiceUrl),
        PreDefinedButtonUi.CONTACT_MAIL.toUrlStringRes(contactMail),
    )
}