package com.osg.truebase.ui.assigners.wrapper

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.navigationBars
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.material3.Button
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.generated.resources.Res
import com.osg.truebase.ui.strings.CommonStringRes
import com.osg.truebase.ui_strings.generated.resources.*
import org.jetbrains.compose.resources.StringResource
import com.osg.truebase.ui.graphics.GradientProgressBar
import com.osg.truebase.ui.graphics.shimmerLoadingAnimation
import com.osg.ui.core.resources.string
import com.osg.truebase.ui.theme.spacing

const val NO_BREAK_SPACE = "\u00A0"

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun QuestionTopAppBar(
    questionIndex: Int,
    totalQuestionsCount: Int,
    modifier: Modifier = Modifier
) {
    Column(
        modifier = modifier.fillMaxWidth(),
        verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {

        TopAppBarTitle(
            questionIndex = questionIndex,
            totalQuestionsCount = totalQuestionsCount,
        )

        GradientProgressBar(
            initialValue = questionIndex.toFloat(),
            currentValue = questionIndex.toFloat() + 1,
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 20.dp),
            maxValue = totalQuestionsCount.toFloat(),
        )
    }
}

@Composable
private fun TopAppBarTitle(
    questionIndex: Int,
    totalQuestionsCount: Int,
    modifier: Modifier = Modifier
) {
    Row(
        modifier = modifier,
    ) {
        Text(
            text = (questionIndex + 1).toString(),
            style = MaterialTheme.typography.labelMedium,
            color = MaterialTheme.colorScheme.onSurface.copy(alpha = 0.6f)
        )
        Text(
            text = NO_BREAK_SPACE + "${CommonStringRes.current_of_total.string} $totalQuestionsCount",
            style = MaterialTheme.typography.labelMedium,
            color = MaterialTheme.colorScheme.onSurface.copy(alpha = 0.38f)
        )
    }
}

@Composable
fun QuestionsBottomBar(
    shouldShowPreviousButton: Boolean,
    isNextButtonEnabled: Boolean,
    onPreviousPressed: () -> Unit,
    onNextPressed: () -> Unit,
    nextButtonTextRes: StringResource,
    showLoading: Boolean = false
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .windowInsetsPadding(WindowInsets.navigationBars)
            .padding(horizontal = 16.dp, vertical = 4.dp)
    ) {
        if (shouldShowPreviousButton) {
            OutlinedButton(
                modifier = Modifier
                    .weight(1f)
                    .height(48.dp),
                onClick = onPreviousPressed
            ) {
                Text(text = CommonStringRes.previous.string)
            }
            Spacer(modifier = Modifier.width(16.dp))
        }
        Button(
            modifier = Modifier
                .weight(1f)
                .height(48.dp)
                .shimmerLoadingAnimation(showLoading),
            onClick = {
                onNextPressed()
            },
            enabled = isNextButtonEnabled && !showLoading,
        ) {
            Text(text = nextButtonTextRes.string)
        }
    }
}