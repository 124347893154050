package com.osg.matchmaker.search

import com.osg.appData.bots.BOT_UID_PREFIX
import com.osg.appData.candidate.CandidateNode
import com.osg.appData.candidate.CandidateZone
import com.osg.appData.candidate.Decision
import com.osg.appData.candidate.MatchRequestData
import com.osg.appData.common.*
import com.osg.appData.common.DataRoot.Companion.matchZoneDataPaths
import com.osg.appData.common.DataRoot.Companion.userArchivePath
import com.osg.appData.common.DataRoot.Companion.userCandidatesPath
import com.osg.appData.common.DataRoot.Companion.userDataPath
import com.osg.appData.common.DataRoot.Companion.userMapGenderPath
import com.osg.appData.functions.FunctionResponseType
import com.osg.appData.matchZone.MatchInfo
import com.osg.appData.matchZone.MatchZoneNode
import com.osg.appData.matchZone.Price
import com.osg.appData.profile.*
import com.osg.matchmaker.matchZone.INITIAL_BALANCE
import com.osg.matchmaker.matchZone.resolveMatchZonePath
import com.osg.matchmaker.ml.MatchOs
import com.osg.matchmaker.ml.ds.CandidateDecisionData
import com.osg.matchmaker.ml.integration
import com.osg.matchmaker.singleTransaction.SingleTransactionHandler
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.nodeData.QueryType
import com.osg.truebase.data.user.UserLocation
import com.osg.utils.haversine
import com.osg.utils.time.TimeService.getUtcTime
import kotlin.math.roundToInt

class SearchZoneCf(
    private val searchRepo: ISearchCfRepo,
){
    private val transactionHandler = SingleTransactionHandler(searchRepo)
    private val matchOs = MatchOs(searchRepo)
    suspend fun findMatch(uid: String): FunctionResponseType {
        val userDataPath = userDataPath(uid)
        val userData: UserData = searchRepo.fetchUserData(userDataPath)
        val candidates = resolveTechnicalCandidates(
            interactRecord = userData.interactRecord,
            requesterProfile = userData.profile
        )

        val candidateZone =  if (candidates.isNotEmpty()) {
            generateCandidateZone(candidates, userData)
        } else {
            CandidateZone()
        }

        createCopyInArchive(uid, candidateZone)
        searchRepo.setCandidateZone(
            data = candidateZone,
            path = userCandidatesPath(uid)
        )

        if (candidateZone.candidateNodes.isEmpty()) {
            AppLogger.d("No candidates found for user with uid $uid")
            return FunctionResponseType.NO_RESULTS
        }
        return FunctionResponseType.SUCCESS
    }

    suspend fun generateCandidateZone(
        technicalCandidates: List<FullProfile>,
        userData: UserData
    ): CandidateZone {
        val candidateDecisionData = getReactionOnUser(technicalCandidates, userData)
        val matchOsOutputs = matchOs.runMatchOs(candidateDecisionData, userData.profile)
        val candidatesPromotions = matchOs.resolveMatchIncentives(
            candidates = candidateDecisionData,
            requestProfile = userData.profile,
            matchOsOutput = matchOsOutputs
        )
        return integration(
            candidates = candidateDecisionData,
            candidatesPromotions = candidatesPromotions,
            matchOsOutput = matchOsOutputs,
        )
    }


    private fun getReactionOnUser(
        technicalCandidates: List<FullProfile>,
        userData: UserData
    ): List<CandidateDecisionData> {
        val reactionRecord = userData.interactRecord.onUserReaction
        return technicalCandidates.map {
            CandidateDecisionData(
                fullProfile = it,
                decision = reactionRecord[it.publicProfile.uid]?.decision ?: Decision.UNKNOWN,
            )
        }
    }


    suspend fun resolveTechnicalCandidates(
        interactRecord: UsersInteractRecord,
        requesterProfile: FullProfile
    ): List<FullProfile> {
        AppLogger.d("resolveTechnicalCandidates for ${requesterProfile.publicProfile.uid}")
        val preferencesMatch = requesterProfile.personalProfile.preferencesMatch
        val matchedPreferences = resolveMatchPreferences(preferencesMatch).filter {
            it != requesterProfile.publicProfile.uid
        }

        val matchedRecord = matchedPreferences.filterOnRecord(interactRecord) {
            it
        }

        val technicalCandidatesProfiles = matchedRecord.map { searchRepo.fetchProfile(
            DataRoot.fullProfilePath(it)
        ) }

        val candidateDesiresFilter = filterOnCandidateDesires(
            technicalCandidatesProfiles,
            requesterProfile
        )

        return candidateDesiresFilter
    }

    fun filterOnCandidateDesires(
        technicalCandidates: List<FullProfile>,
        requesterProfile: FullProfile
    ): List<FullProfile> {
        return technicalCandidates.filter { candidate ->
            val distance = calculateDistance(requesterProfile.publicProfile.location!!, candidate.publicProfile.location!!)
            AppLogger.d("candidate: ${candidate.publicProfile.uid}:")
            candidate.personalProfile.preferencesMatch.canBeMatchedWith(requesterProfile, distance)
        }
    }

    private fun calculateDistance(
        requesterProfile: UserLocation,
        candidateProfile: UserLocation
    ): Int {
        return haversine(requesterProfile, candidateProfile).roundToInt()
    }

    suspend fun resolveMatchPreferences(
        preferencesMatch: PreferencesMatch
    ): Set<String> {
        val ageQuery = QueryType.Range(
            TechnicalProfile::age.name,
            preferencesMatch.ageMin!!.toDouble(),
            preferencesMatch.ageMax!!.toDouble()
        )
        val queryPath = userMapGenderPath(preferencesMatch.dateGender!!)
        val technicalCandidates: Map<String, TechnicalProfile> = searchRepo.queryTechnicalProfiles(queryPath, ageQuery)
        val singleCandidates = technicalCandidates.filterValues { it.isSingle }
        AppLogger.d("got ${technicalCandidates.size} technicalCandidates and ${singleCandidates.size} singleCandidate")
        return singleCandidates.keys
    }

    private fun PreferencesMatch.canBeMatchedWith(requesterProfile: FullProfile, distance: Int): Boolean {
        val isAgeMatch = requesterProfile.publicProfile.age in ageMin!!..ageMax!!
        val isGenderMatch = requesterProfile.publicProfile.gender == dateGender
        val isDistanceMatch = distance <= maxDistance!!
        AppLogger.d("isAgeMatch: $isAgeMatch, ($ageMin < ${requesterProfile.publicProfile.age} < $ageMax)" +
                "isGenderMatch: $isGenderMatch," +
                " isDistanceMatch: $isDistanceMatch (distance: $distance maxDistance: $maxDistance)")
        return isAgeMatch && isGenderMatch && isDistanceMatch
    }

    suspend fun createCopyInArchive(uid: String, candidateZone: CandidateZone) {
        if (candidateZone.candidateNodes.isEmpty()) {
            return
        }

        val archiveStruct: Map<String, CandidateNode> =
            candidateZone.candidateNodes.associateBy { it.uid }
        val archivePath = userArchivePath(uid)
        searchRepo.updateNode(archivePath, archiveStruct)
    }


    suspend fun onItsAMatch(matchRequestData: MatchRequestData): FunctionResponseType {
        val successForRequester = transactionHandler.transactSingleState(
            matchRequestData.requesterIdentity,
            targetIsSingle = false
        )

        if (successForRequester.not()) {
            AppLogger.e(
                "onItsAMatch",
                "requester ${matchRequestData.requesterIdentity} is not single"
            )
            return FunctionResponseType.NO_RESULTS
        }

        val successForMatch = if (matchRequestData.matchIdentity.uid.contains(BOT_UID_PREFIX)) {
            true
        } else {
            transactionHandler.transactSingleState(
                matchRequestData.matchIdentity,
                targetIsSingle = false
            )
        }

        if (successForMatch.not()) {
            transactionHandler.transactSingleState(
                matchRequestData.requesterIdentity,
                targetIsSingle = true
            ) // revert the changes since the other user was taken
            return FunctionResponseType.NO_RESULTS
        }

        val matchZonePath = resolveMatchZonePath(
            matchRequestData.requesterIdentity.uid,
            matchRequestData.matchIdentity.uid
        )

        uploadUserPaths(
            targetProfileUid = matchRequestData.requesterIdentity.uid,
            targetMatchedWithUid = matchRequestData.matchIdentity.uid,
            matchZonePath = matchZonePath,
            isSuperLikeByOther = matchRequestData.candidateDecision == Decision.SUPER_LIKE
        )

        uploadUserPaths(
            targetProfileUid = matchRequestData.matchIdentity.uid,
            targetMatchedWithUid = matchRequestData.requesterIdentity.uid,
            matchZonePath = matchZonePath,
            isSuperLikeByOther = matchRequestData.requesterDecision == Decision.SUPER_LIKE
        )

        constructMatchZone(matchZonePath)
        val otherUserPresence: Presence = searchRepo.fetchPresence(
            DataRoot.presencePath(matchRequestData.matchIdentity.uid)
        )

        if (otherUserPresence.online.not()) {
            searchRepo.sendNote(
                NotificationData(
                    toUid = matchRequestData.matchIdentity.uid,
                    fromUid = matchRequestData.requesterIdentity.uid,
                    uiData = NotificationUiData(
                        type = NotificationType.MATCH
                    )
                )
            )
        }

        return FunctionResponseType.SUCCESS
    }

    suspend fun uploadUserPaths(
        targetProfileUid: String,
        targetMatchedWithUid: String,
        matchZonePath: String,
        isSuperLikeByOther: Boolean
    ) {
        val userPath = MatchZoneData(
            candidateUid = targetMatchedWithUid,
            matchZoneNodePath = matchZonePath,
            superLiked = isSuperLikeByOther,
        )

        val path = matchZoneDataPaths(targetProfileUid)
        searchRepo.storeMatchZoneData(
            matchZoneData = userPath,
            path = path
        )
    }

    private suspend fun constructMatchZone(
        matchZonePath: String,
    ) {
        val matchZone = MatchZoneNode(
            matchInfo = MatchInfo(
                balance = Price(INITIAL_BALANCE),
                matchTime = getUtcTime()
            )
        )

        searchRepo.storeMatchZoneNode(
            path = matchZonePath,
            matchZoneNode = matchZone,
        )
    }
}
