package com.osg.appData.matchZone

import com.osg.appData.common.DataRoot
import kotlinx.serialization.Serializable


@Serializable
data class EntertainmentStore(
    val sparks: Map<String, SparkNodeData> = emptyMap(),
){
    companion object {
        fun entDataPath(id: String): String {
            return "$entStorePath/$id"
        }

        val entStorePath: String = DataRoot::entertainmentStore.name
    }

}