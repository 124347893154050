package com.osg.matchmaker.ml

import com.osg.appData.candidate.CandidateNode
import com.osg.appData.candidate.CandidateZone
import com.osg.appData.candidate.MatchIncentive
import com.osg.appData.ml.MatchOsOutput
import com.osg.appData.utills.sortByOther
import com.osg.matchmaker.ml.ds.CandidateDecisionData
import com.osg.utils.time.TimeService.getUtcTime

fun integration(
    candidates: List<CandidateDecisionData>,
    candidatesPromotions: List<MatchIncentive>,
    matchOsOutput: MatchOsOutput,
): CandidateZone {
    val candidateNodes = candidates.mapIndexed { index, it ->
        CandidateNode(
            uid= it.fullProfile.publicProfile.uid,
            matchIncentive = candidatesPromotions[index],
            decision = it.decision,
        )
    }
    val overallGrades = calculateOverallGrades(matchOsOutput)
    val sortedCandidates = candidateNodes.sortByOther(overallGrades)
    return CandidateZone(
        candidateNodes = sortedCandidates,
        requestTimeStamp = getUtcTime()
    )
}

fun calculateOverallGrades(matchOsOutput: MatchOsOutput): List<Float> {
    return matchOsOutput.candidateGrades.map {
        it.embeddingGrade
    }
}

