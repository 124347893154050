package osg.uiZone.matchZone.state

import androidx.compose.animation.core.Spring
import androidx.compose.animation.core.spring
import androidx.compose.material.icons.Icons
import androidx.compose.runtime.Immutable
import androidx.compose.ui.unit.DpOffset
import androidx.compose.ui.unit.dp
import com.osg.ui.core.commonStates.asTrueVector
import osg.uiZone.common.pyrotechnics.animatedNavBar.colorButtons.ButtonBackground
import osg.uiZone.common.pyrotechnics.animatedNavBar.colorButtons.ColorButtonAnimation
import osg.uiZone.matchZone.icons.IceSkating
import osg.uiZone.matchZone.icons.LocalBar
import osg.uiZone.matchZone.icons.Route
import osg.uiZone.navigation.*
import osg.uiZone.tutorial.TutorialStepCollection
import truematch.uizone.generated.resources.*


@Immutable
enum class MainZonePlaces(
    val animationType: ColorButtonAnimation,
    val tutorialStep: TutorialStepCollection,
) {
    JourneyZone(
        animationType = ColorButtonAnimation(
            animationSpec = spring(dampingRatio = 0.7f, stiffness = 20f),
            background = ButtonBackground(
                resource = Res.drawable.circle_background,
                offset = DpOffset(2.5.dp, 3.dp)
            ),
            icon = Icons.Outlined.Route.asTrueVector(),
        ),
        tutorialStep = TutorialStepCollection.SharedJourneyExplanation
    ),
    Spark(
        animationType = ColorButtonAnimation(
            animationSpec = spring(dampingRatio = 0.7f, stiffness = 20f),
            background = ButtonBackground(
                resource = Res.drawable.rectangle_background,
                offset = DpOffset(1.dp, 2.dp)
            ),
            icon = Icons.Outlined.LocalBar.asTrueVector(),
        ),
        tutorialStep = TutorialStepCollection.MatchZoneSparksButton
    ),
    IceBreaker(
        animationType = ColorButtonAnimation(
            animationSpec = spring(
                dampingRatio = 0.3f,
                stiffness = Spring.StiffnessVeryLow
            ),
            background = ButtonBackground(
                resource = Res.drawable.quadrangle_background,
                offset = DpOffset(1.dp, 1.5.dp)
            ),
            icon = Icons.Outlined.IceSkating.asTrueVector(),
        ),
        tutorialStep = TutorialStepCollection.MatchZoneIceBreakerButton
    ),
    ChatZone(
        animationType = ColorButtonAnimation(
            animationSpec = spring(dampingRatio = 0.7f, stiffness = 20f),
            background = ButtonBackground(
                resource = Res.drawable.circle_background,
                offset = DpOffset(2.5.dp, 3.dp)
            ),
            icon = Res.drawable.chat_nav.asTrueVector(),
        ),
        tutorialStep = TutorialStepCollection.MatchZoneChatButton
    ),
}

data class PlaceSettings(
    val showOptions: Boolean,
    val navigationOptions: List<MatchZoneDestination>,
    val showBack: Boolean,
)


val matchZoneDestinations: List<MatchZoneDestination> by lazy {
    listOf(
        SharedJourney,
        MatchZoneChat,
        IceBreakerFeed,
        SparkFeed
    )
}

val MatchZoneDestination.placeSettings: PlaceSettings
    get() {
        return PlaceSettings(
            showBack = this != SharedJourney,
            showOptions = true,
            navigationOptions = listOf(
                MatchZoneChat,
                IceBreakerFeed,
                SparkFeed,
            )
        )
    }