package com.osg.def.database

import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.nodeData.QueryType
import com.osg.truebase.data.nodeData.TransactionTarget
import com.osg.truebase.data.nodeData.asString
import com.osg.truebase.data.nodeData.fromString
import kotlinx.coroutines.channels.ProducerScope
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.serialization.json.Json

data class KmpNode<T>(
    val key: String,
    val data: T
)

fun interface ListenerRegistration {
    fun remove()
}

fun interface ISetNode {
    suspend fun setBooleanNode(path: String, value: Boolean)
}

class DatabaseService(
    val databaseImpl: IDatabaseEngine
): ISetNode, IDisconnect, IRemoveNode {

    suspend inline fun <reified T> fetchObject(
        path: String,
    ): T {
        val stringObj = databaseImpl.fetchObject(path)
        return stringObj.fromString()
    }

    suspend inline fun <reified T> runQuery(
        path: String,
        queryType: QueryType
    ): Map<String,T> {
        val stringObj = databaseImpl.runQuery(path, queryType)
        return stringObj.fromString()
    }


    suspend inline fun <reified T> setNode(path: String, value: T) {
        databaseImpl.setNode(path, value.asString())
    }

    suspend inline fun <reified T> updateNode(
        path: String,
        value: T
    ){
        databaseImpl.updateNode(path, value.asString())
    }

    suspend inline fun <reified T> updateNodeMap(
        path: String,
        value: Map<String, T>,
    ){
        val ser = Json.encodeToString(value)
        databaseImpl.updateNode(path, ser)
    }

    override fun setFalseOnDisconnect(path: String) {
        databaseImpl.setFalseOnDisconnect(path)
    }



    inline fun <reified T> onValueChangeFlow(path: String): Flow<T?> {
        return callbackFlow {
            val listener = databaseImpl.observeValueChange(path) { stringVal, error ->

                if (error is KmpDatabaseException.ValueNotFound) {
                    trySend(null)
                    return@observeValueChange
                }
                else if (error != null) {
                    close(KmpDatabaseException.FirebaseError(error.message ?: "Unknown Error", path))
                    AppLogger.e("onValueChangeFlow", "path $path \n error: ${error.cause} message: ${error.message}")
                    return@observeValueChange
                }
                else{
                    AppLogger.d("onValueChangeFlow", "new val: $stringVal")
                    trySend(
                        stringVal?.fromString()
                    )
                }
            }

            awaitClose {
                AppLogger.d("onValueChangeFlow closed $path")
                listener.remove()
            }
        }
    }


    inline fun <reified T> getChildFlow(path: String): Flow<KmpNode<T>> {
        return callbackFlow {
            val listener = databaseImpl.observeNewChild(path, childCompletion(path))

            awaitClose {
                AppLogger.d("getChildFlow closed $path")
                listener.remove()
            }
        }
    }

    inline fun <reified T> ProducerScope<KmpNode<T>>.childCompletion(path: String): (KmpSnapshot?, String?) -> Unit =
        { stringVal, error ->
            try {
                val data = stringVal?.serializedValue!!.fromString<T>()
                trySend(
                    KmpNode(
                        key = stringVal.key,
                        data = data
                    )
                )
            } catch (e: Exception) {
                AppLogger.e("childCompletion", "error in childCompletion: $e")
                close(
                    KmpDatabaseException.FirebaseError(error ?: "Unknown Error $e", path)
                )
            }
        }

    inline fun <reified T> getChildFlow(
        path: String,
        query: QueryType.StartAt,
    ): Flow<KmpNode<T>> {
        return callbackFlow {
            val listener = databaseImpl.observeNewChild(path, query, childCompletion(path))

            awaitClose {
                AppLogger.d("getChildFlow closed $path")
                listener.remove()
            }
        }
    }

    override suspend fun removeNode(nodePath: String) {
        try {
            databaseImpl.removeNode(nodePath)
        } catch (e: Exception) {
            throw e
        }
    }

    override suspend fun setBooleanNode(path: String, value: Boolean) {
        databaseImpl.setPrimitiveNode(path, value)
    }

    suspend fun setPrimitiveNode(path: String, value: Any) = databaseImpl.setPrimitiveNode(path, value)

    suspend inline fun <reified T> transaction(
        path: String,
        crossinline transactionOperation: (T?) -> TransactionTarget<T>
    ): Boolean {
        return databaseImpl.transaction(path){ stringVal ->
            AppLogger.d("transaction", "old val: $stringVal")
            val transactionTarget = transactionOperation(
                stringVal?.fromString()
            )
            TransactionTarget<String>(
                success = transactionTarget.success,
                newValue = transactionTarget.newValue?.asString()
            ).also {
                AppLogger.d("transaction", "new val: ${it.newValue} success: ${it.success}")
            }
        }
    }



    suspend inline fun <reified T> push(nodePath: String, obj: T): String? = databaseImpl.pushNode(nodePath, obj.asString())

}