package osg.uiZone.profile

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.osg.truebase.ui.commonStates.MainViewUiState
import com.osg.truebase.ui.commonStates.toSuccess
import com.osg.truebase.ui.dialog.ImageBytes
import com.osg.utils.filterToData
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import osg.uiZone.common.baseRepos.profile.IMyFullProfileFlow
import osg.uiZone.common.baseRepos.profile.IProfileImagesStateFlow
import osg.uiZone.common.baseRepos.profile.ISaveMyImages
import osg.uiZone.common.baseRepos.profile.ISaveMyProfile
import osg.uiZone.common.baseRepos.profile.MyProfileScope.Companion.myProfileHandler


sealed class EditImageCommand {
    data class Edit(val idx: Int, val imageBytes: ImageBytes) : EditImageCommand()
    data class Delete(val idx: Int) : EditImageCommand()
    data class Add(val imageBytes: ImageBytes) : EditImageCommand()
}

interface IEditProfileDependencies : IMyFullProfileFlow, ISaveMyProfile, ISaveMyImages, IProfileImagesStateFlow
class EditProfileViewModel(
    private val editProfileDependencies: IEditProfileDependencies = myProfileHandler,
) : ViewModel() {

    val editUiState: StateFlow<MainViewUiState<ProfileEditUiState>> = combine(
        editProfileDependencies.myProfileStateFlow.filterToData(),
        editProfileDependencies.myLocalImagesFlow.filterNotNull()
    ) { profile, images ->
        ProfileEditUiState(
            profile.publicProfile,
            images
        ).toSuccess()
    }.stateIn(
        scope = viewModelScope,
        started = SharingStarted.WhileSubscribed(),
        initialValue = MainViewUiState.Loading
    )


    fun saveProfile(
        profileEditUiState: ProfileEditUiState
    ) {
        viewModelScope.launch {
            editProfileDependencies.saveMyProfile(
                profileEditUiState.publicProfile
            )
            editProfileDependencies.saveMyImages(
                profileEditUiState.profileImages
            )
        }
    }
}