package com.osg.trumatch.bots.botProfiles

import bots.trueAutoResources.BotsMediaRemoteImages
import com.osg.appData.profile.*
import com.osg.appData.register.OpenDeepQuestions
import com.osg.truebase.data.nodeData.RemoteProfileImage
import com.osg.truebase.data.user.Gender
import com.osg.truebase.preview.data.IsraelLocation

internal val yuvalShemla = FullProfile(
    personalProfile = PersonalProfile(

        facebookId = "10225277936830288",
        lastName = "Shemla",
        preferencesMatch = PreferencesMatch(
            ageMax = 35,
            ageMin = 25,
            maxDistance = 30,
            dateGender = Gender.Female
        ),
    ),
    publicProfile = PublicProfile(
        birthDay = "05/15/1995",
        firstName = "Yuval",
        gender = Gender.Male,
        height = 175,
        occupation = "Climber",
        picturesCommentList = listOf(
            RemoteProfileImage(
                comment = "",
                mediaPath = BotsMediaRemoteImages.M4I0.mediaPath
            ),
            RemoteProfileImage(
                comment = "Scaling new heights 🧗‍♂️✨ #WallClimbing",
                mediaPath = BotsMediaRemoteImages.M4I1.mediaPath
            ),
            RemoteProfileImage(
                comment = "",
                mediaPath = BotsMediaRemoteImages.M4I2.mediaPath
            )
        ),
        stage = UserStage.REGISTERED,
        verified = true,
        openQuestionAnswer = listOf(
            OpenAnswer(
                answer = "Passionate wall climber with a love for scaling new heights. Always eager to take on new challenges and push my limits. Let's conquer the walls together. 🧗‍♂️ #WallClimbing",
                questionId = OpenDeepQuestions.INTRODUCTION
            )
        ),
        location = IsraelLocation.KFAR_SABA.location
    )
)