package osg.uiZone.graph

import androidx.compose.animation.*
import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.tween
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Scaffold
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.navigation.NavBackStackEntry
import androidx.navigation.NavDestination.Companion.hasRoute
import androidx.navigation.NavHostController
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.currentBackStackEntryAsState
import androidx.navigation.compose.rememberNavController
import androidx.navigation.toRoute
import com.osg.truebase.data.logger.AppLogger
import osg.uiZone.common.vmRepo.matchZone.MatchScope
import osg.uiZone.landing.model.landingGraph
import osg.uiZone.matchZone.bar.MatchZoneTopBar
import osg.uiZone.navigation.*
import osg.uiZone.register.registerGraph
import osg.uiZone.search.components.SearchScreenTopBar
import osg.uiZone.search.searchGraph


@Composable
fun SetupNavGraph(
    navController: NavHostController = rememberNavController()
) {
    var showTopBar by remember { mutableStateOf(true) }
    Scaffold(
        contentWindowInsets = WindowInsets(0, 0, 0, 0),
        topBar = {
            if (showTopBar){
                TmTopBar(navController)
            }
        },
    ) {innerPadding ->
        NavHost(
            modifier = Modifier.padding(innerPadding),
            navController = navController,
            startDestination = LandingDestination,
            enterTransition = tmEnterTransition,
            exitTransition = tmExitTransition
        ) {
            landingGraph(navController)
            registerGraph(navController)
            searchGraph(navController){
                showTopBar = it
            }
            matchZoneGraph(navController)
            commonGraph(navController)
        }
    }

}


@Composable
fun TmTopBar(
    navController: NavHostController,
){
//    justTry {
//        AppLogger.d("navController.graph.nodes", navController.graph.nodes.toString())
//    }
    val navBackStackEntry by navController.currentBackStackEntryAsState()
    val destination = navBackStackEntry.currentGraph
    if (destination is SearchDestination) {
        SearchScreenTopBar(
            onNavigation = {
                navController.navigate(it){
                    launchSingleTop = true
                }
            },
        )
    }else if (destination is MatchZoneDestination) {
        val topBarState by MatchScope().matchZoneRepository.barOnlineData.collectAsState()
        MatchZoneTopBar(
            topBarState = topBarState,
            selectedDestination = destination,
            onNavigation = {
                navController.navigate(it){
                    launchSingleTop = true
                }
            },
            onBack = navController::navigateUp,
        )
    }else{
        AppLogger.d("TmTopBar", "destination: $destination")
    }
}

val NavBackStackEntry?.currentGraph : Destination?
    get(){
        AppLogger.d("NavBackStackEntry", "current route: ${this?.destination?.route}")
        val destination = this?.destination?: return null
        return when {
            destination.hasRoute<LandingDestination>() -> this.toRoute<LandingDestination>()
            destination.hasRoute<SearchDestination.SearchMain>() -> this.toRoute<SearchDestination.SearchMain>()
            destination.hasRoute<SearchDestination.Preference>() -> this.toRoute<SearchDestination.Preference>()
            destination.hasRoute<SharedJourney>() -> this.toRoute<SharedJourney>()
            destination.hasRoute<MatchZoneChat>() -> this.toRoute<MatchZoneChat>()
            destination.hasRoute<SparkFeed>() -> this.toRoute<SparkFeed>()
            destination.hasRoute<IceBreakerFeed>() -> this.toRoute<IceBreakerFeed>()
            else -> null
        }
    }


val tmExitTransition:
        AnimatedContentTransitionScope<NavBackStackEntry>.() -> ExitTransition  = {
    val destination = this.initialState.currentGraph
    when(destination){
        is LandingDestination -> fadeOut(
            tween(
                durationMillis = 200,
                easing = LinearEasing
            )
        )
        else -> slideOutOfContainer(
            towards = AnimatedContentTransitionScope.SlideDirection.Start,
            animationSpec = tween(
                durationMillis = 200,
                easing = LinearEasing
            ),
            targetOffset = { fullOffset -> (fullOffset * 0.3f).toInt() }
        )
    }
}

val tmEnterTransition:
        AnimatedContentTransitionScope<NavBackStackEntry>.() -> EnterTransition = {
    val destination = this.initialState.currentGraph
    when(destination){
        is LandingDestination -> fadeIn(
            tween(
                durationMillis = 200,
                easing = LinearEasing
            )
        )
        else -> slideIntoContainer(
            towards = AnimatedContentTransitionScope.SlideDirection.Start,
            animationSpec = tween(
                durationMillis = 200,
                easing = LinearEasing
            )
        )
    }
}