package bot.media.trueAutoResources


enum class BotsMediaLocalImages(override val mediaPath: String): com.osg.truebase.data.resources.ILocalImages {
	FIRST_CAR("composeResources/truematch.bot_media.generated.resources/files/journey_samples/first_car.jpg"),
	FIRST_DOG("composeResources/truematch.bot_media.generated.resources/files/journey_samples/first_dog.jpeg"),
	M4I0("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botYuvalShemla/IMAGES/m4i0.webp"),
	M4I2("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botYuvalShemla/IMAGES/m4i2.webp"),
	M4I1("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botYuvalShemla/IMAGES/m4i1.webp"),
	M3I2("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botTalMorad/IMAGES/m3i2.webp"),
	M3I0("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botTalMorad/IMAGES/m3i0.webp"),
	M3I1("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botTalMorad/IMAGES/m3i1.webp"),
	YALI0("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botYali/yali0.jpg"),
	YALI2("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botYali/yali2.jpg"),
	YALI1("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botYali/yali1.jpg"),
	F0B0("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botOMER/IMAGES/f0b0.jpg"),
	F0B1("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botOMER/IMAGES/f0b1.jpg"),
	F0B2("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botOMER/IMAGES/f0b2.jpg"),
	M1I0("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botMARSHALL/IMAGES/m1i0.jpg"),
	M1I2("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botMARSHALL/IMAGES/m1i2.webp"),
	M1I1("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botMARSHALL/IMAGES/m1i1.jpg"),
	ORI3("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/testUserUidPrefix_MaleTestUser/IMAGES/ori3.jpg"),
	ORI1("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/testUserUidPrefix_MaleTestUser/IMAGES/ori1.jpg"),
	ORI2("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/testUserUidPrefix_MaleTestUser/IMAGES/ori2.jpg"),
	F0I2("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/testUserUidPrefix_FemaleTestUser/IMAGES/f0i2.webp"),
	F0I0("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/testUserUidPrefix_FemaleTestUser/IMAGES/f0i0.webp"),
	F0I1("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/testUserUidPrefix_FemaleTestUser/IMAGES/f0i1.webp"),
	M2I1("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botYEHUDA/IMAGES/m2i1.jpg"),
	M2I0("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botYEHUDA/IMAGES/m2i0.jpg"),
	M2I2("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botYEHUDA/IMAGES/m2i2.webp"),
	F1B1("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botMISHEL/IMAGES/f1b1.jpg"),
	F1B0("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botMISHEL/IMAGES/f1b0.jpg"),
	F1B2("composeResources/truematch.bot_media.generated.resources/files/USERS_STORAGE/botMISHEL/IMAGES/f1b2.jpg");
}

val BotsMediaLocalAllMedia: List<com.osg.truebase.data.resources.ILocalMediaResource> = BotsMediaLocalImages.entries