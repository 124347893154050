package com.osg.truebase.ui.filekit.core

import com.osg.truebase.ui.dialog.PickerType
import kotlinx.browser.document
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Uint8Array
import org.khronos.webgl.get
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.asList
import org.w3c.files.FileReader
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlin.coroutines.suspendCoroutine
import org.w3c.files.File

suspend fun pickFile(
    type: PickerType,
): File? = withContext(Dispatchers.Default) {
    suspendCoroutine { continuation ->
        // Create input element
        val input = document.createElement("input") as HTMLInputElement

        // Visually hide the element
        input.style.display = "none"

        document.body?.appendChild(input)

        // Configure the input element
        input.apply {
            this.type = "file"

            // Set the allowed file types
            when (type) {
                is PickerType.Image -> accept = "image/*"
                is PickerType.Video -> accept = "video/*"
                is PickerType.ImageAndVideo -> accept = "image/*,video/*"
                is PickerType.File -> type.extensions?.let {
                    accept = type.extensions.joinToString(",") { ".$it" }
                }
            }

            multiple = false

            // max is not supported for file inputs
        }

        // Setup the change listener
        input.onchange = { event ->
            try {
                // Get the selected files
                val files = event.target
                    ?.unsafeCast<HTMLInputElement>()
                    ?.files
                    ?.asList()

                // Return the result
                continuation.resume(files?.firstOrNull())
            } catch (e: Throwable) {
                continuation.resumeWithException(e)
            } finally {
                document.body?.removeChild(input)
            }
        }

        input.oncancel = {
            continuation.resume(null)
            document.body?.removeChild(input)
        }

        // Trigger the file picker
        input.click()
    }
}

suspend fun readBytes(
    file: File
): ByteArray = withContext(Dispatchers.Main) {
    suspendCoroutine { continuation ->
        val reader = FileReader()
        reader.onload = { event ->
            try {
                // Read the file as an ArrayBuffer
                val arrayBuffer = event
                    .target
                    ?.unsafeCast<FileReader>()
                    ?.result
                    ?.unsafeCast<ArrayBuffer>()
                    ?: throw IllegalStateException("Could not read file")

                // Convert the ArrayBuffer to a ByteArray
                val bytes = Uint8Array(arrayBuffer)

                // Copy the bytes into a ByteArray
                val byteArray = ByteArray(bytes.length)
                for (i in 0 until bytes.length) {
                    byteArray[i] = bytes[i]
                }

                // Return the ByteArray
                continuation.resume(byteArray)
            } catch (e: Exception) {
                continuation.resumeWithException(e)
            }
        }

        // Read the file as an ArrayBuffer
        reader.readAsArrayBuffer(file)
    }
}