package osg.uiZone.common.baseRepos.auth

import com.osg.def.auth.AuthService
import com.osg.truebase.signin.ISignInProviders
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

fun injectAuth(): AuthService {
    return object : KoinComponent{
        val authService: AuthService by inject()
    }.authService
}

fun injectSignIn(): ISignInProviders {
    return object : KoinComponent{
        val module: ISignInProviders by inject()
    }.module
}