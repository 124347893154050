package com.osg.ice.state

import androidx.compose.runtime.Immutable
import com.osg.truebase.ui.animation.LottieAnimation

@Immutable
data class ChallengeAcceptedUiState(
    val question: String,
    val options: List<String>,
    val isMyTurn: Boolean,
    val round: Int,
    val totalRounds: Int,
    val myProfile: IceUiPlayerProfile,
    val opponentProfile: IceUiPlayerProfile,
    val roundStatus: RoundStatus,
)


@Immutable
sealed class RoundStatus(
    val enabledSelection: Boolean,
){
    @Immutable
    data class Initial(
        val animation: LottieAnimation,
    ): RoundStatus(
        enabledSelection = true
    )

    @Immutable
    data class WaitingForOpponentAnswer(
        val myAnswerIndex: Int,
    ): RoundStatus(
        enabledSelection = false
    )

    @Immutable
    data class ShowAnswers(
        val myAnswerIndex: Int,
        val opponentAnswerIndex: Int,
        val currentResult: IceMatchResult,
        val prevResult: IceMatchResult
    ): RoundStatus(
        enabledSelection = false
    )

}

data class IceMatchResult(
    val myScore: Int,
    val opponentScore: Int,
)