package com.osg.truebase.ui.annotatedText

import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.SpanStyle


fun String.toAnnotatedString(
    basedSpanStyle: SpanStyle,
): AnnotatedString {
    val cleanString = this.removePrefix("</markdown>")
    val annotatedString =
        markdownStringToAnnotated(cleanString, basedSpanStyle)
    return annotatedString
}