package osg.uiZone.matchZone.journey.items

import androidx.compose.material.icons.Icons

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.osg.ui.core.resources.string
import osg.uiZone.matchZone.icons.AccountBalance
import osg.uiZone.matchZone.journey.PanelCard
import osg.uiZone.matchZone.state.JourneyPanelItem
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.balance_title

@Composable
fun JourneyBalance(
    journeyBalanceData: JourneyPanelItem.Balance
) {
    PanelCard(
        modifier = Modifier,
        title = Res.string.balance_title.string.uppercase(),
        imageVector = Icons.Default.AccountBalance,
        cardValueText = journeyBalanceData.balance,
        description  = "To gain more, challenge your match to exciting IceBreaker\u00A0⛸"
    )
}