package osg.uiZone.common.ds.ui

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector


data class ActionButton(
    val icon: ImageVector,
    val color: Color,
    val onClick: () -> Unit,
)
