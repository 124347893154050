package osg.uiZone.matchZone.entertainment.baseContent

import com.osg.appData.matchZone.ice.IcebreakerCollection
import com.osg.truebase.data.iceDef.IceTag
import com.osg.ui.core.commonStates.asTrueImage
import com.osg.ui.core.resources.uiText
import com.osg.utils.plus
import osg.uiZone.matchZone.entertainment.TMEntertainmentDataUiBase
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.janga_description
import truematch.uizone.generated.resources.janga_name
import truematch.uizone.generated.resources.janga_slogan
import uiZone.trueAutoResources.LocalImages

val jangaBase = TMEntertainmentDataUiBase(
    description = Res.string.janga_description.uiText,
    image = LocalImages.JANGA.asTrueImage(),
    name = Res.string.janga_name.uiText,
    
    unlockStage = 2,
    slogan = Res.string.janga_slogan.uiText,
    vibes = IceTag.Adrenaline + IceTag.ProblemSolving,
    id = IcebreakerCollection.Janga.name,
)