
import androidx.compose.animation.core.*
import androidx.compose.foundation.layout.*
import androidx.compose.material3.*
import androidx.compose.material3.adaptive.currentWindowAdaptiveInfo
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.adaptiveUi.isCompact
import com.osg.truebase.ui.graphics.blurAll
import com.osg.truebase.ui.graphics.shader.MESH_SHADER
import com.osg.truebase.ui.graphics.shader.TrueShader
import com.osg.truebase.ui.graphics.shader.shaderBackground
import com.osg.truebase.ui.paint.PreGradientBackground
import com.osg.truebase.ui.theme.spacing
import com.osg.truematch.demo.tmPreviewApp
import com.osg.ui.core.resources.string
import common.WebBar
import osg.cross.landing.ds.commonStringRes
import truematch.crossui.generated.resources.app_slogan
import truematch.web.generated.resources.Res
import truematch.web.generated.resources.start_demo_button


@Composable
fun WebLandingContent() {
    var isShowDialogState by remember { mutableStateOf(false) }
    Scaffold(
        modifier = Modifier.fillMaxSize().blurAll(isShowDialogState),
        topBar = {
            WebBar(
                modifier = Modifier
                    .fillMaxWidth(),
                onWaitingListClick = {
                    isShowDialogState = true
                },
            )
        }
    ) { innerPadding ->
        var startDemo by remember { mutableStateOf(false) }
        Box(
            modifier = Modifier.Companion.fillMaxSize().shaderBackground(
                TrueShader(shaderData = MESH_SHADER).runtimeEffect.apply {
                    PreGradientBackground.LogoBackground.colors.forEachIndexed { index, color ->
                        setColorUniform("iColor${index + 1}", color)
                    }
                }
            ),
        ) {
            if (startDemo) {
                val isCompact = currentWindowAdaptiveInfo().windowSizeClass.isCompact()
                Box(
                    Modifier.align(Alignment.Companion.Center)
                        .padding(innerPadding)
                        .padding(bottom = 8.dp)
                        .then(
                            if (isCompact) {
                                Modifier.fillMaxWidth()
                            } else {
                                Modifier.aspectRatio(0.56f)
                            }
                        )
                ){
                    tmPreviewApp.AppEntry()
                }
            }else{
                Column(
                    modifier = Modifier.align(Alignment.Companion.Center),
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.medium)
                ) {
                    Text(
                        text = commonStringRes.app_slogan.string,
                        fontWeight = FontWeight.Bold,
                        style = MaterialTheme.typography.displayMedium,
                        textAlign = TextAlign.Center,
                        color = Color.White
                    )
                    Button(
                        colors = ButtonDefaults.buttonColors().copy(
                            containerColor = Color.White,
                        ),
                        onClick = {
                            startDemo = true
                        }
                    ){
                        val infiniteTransition = rememberInfiniteTransition(label = "")
                        val rotateAnimation by infiniteTransition.animateFloat(
                            initialValue = 0f,
                            targetValue = 100f,
                            animationSpec = infiniteRepeatable(
                                animation = tween(2000, easing = LinearEasing),
                                repeatMode = RepeatMode.Reverse
                            ), label = ""
                        )
                        Text(
                            modifier = Modifier,
                            text = Res.string.start_demo_button.string,
                            style = MaterialTheme.typography.bodyLarge.copy(
                                brush = Brush.horizontalGradient(
                                    colors = PreGradientBackground.LogoBackground.colors,
                                    startX = rotateAnimation
                                )
                            ),
                            textAlign = TextAlign.Center,
                        )
                    }
                }
            }
        }
        if (isShowDialogState) {
            WaitlistPop(
                onDismissRequest = {
                    isShowDialogState = false
                },
            )
        }
    }
}
