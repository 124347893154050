package com.osg.truebase.preview.data

import com.osg.truebase.data.user.UserLocation

val israeliMaleFirstNames = listOf("Eitan", "Yosef", "Amit", "Doron", "Itai", "Shimon", "Ori", "Elad", "Omer", "Aviv")
val israeliFemaleFirstNames = listOf("Maya", "Tamar", "Liora", "Shira", "Nofar", "Yael", "Noa", "Adi", "Shir", "Dana")
val israeliLastNames = listOf("Cohen", "Levi", "Mizrachi", "Peretz", "Sasson")



enum class IsraelLocation(val city: String, val lat: Double, val lang: Double) {
    TEL_AVIV("Tel Aviv-Yafo", 32.0800, 34.7800),
    JERUSALEM("Jerusalem", 31.7800, 35.2200),
    HAIFA("Haifa", 32.8200, 34.9800),
    EILAT("Eilat", 29.5577, 34.9519),
    BEER_SHEVA("Be'er Sheva", 31.2520, 34.7915),
    NAZARETH("Nazareth", 32.6996, 35.3035),
    ASHDOD("Ashdod", 31.8014, 34.6431),
    NETANYA("Netanya", 32.3215, 34.8532),
    HOLON("Holon", 32.0158, 34.7874),
    RISHON_LEZION("Rishon LeZion", 31.9714, 34.7894),
    PETAH_TIKVA("Petah Tikva", 32.0871, 34.8875),
    ASHKELON("Ashkelon", 31.6688, 34.5743),
    REHOVOT("Rehovot", 31.8942, 34.8112),
    TIBERIAS("Tiberias", 32.7922, 35.5312),
    RAMAT_GAN("Ramat Gan", 32.0684, 34.8248),
    HADERA("Hadera", 32.4418, 34.9196),
    HERZLIYA("Herzliya", 32.1663, 34.8433),
    KFAR_SABA("Kfar Saba", 32.1750, 34.9067),
    MODIIN("Modi'in-Maccabim-Re'ut", 31.8983, 35.0104),
    BAT_YAM("Bat Yam", 32.0238, 34.7503),
    LOD("Lod", 31.9510, 34.8953),
    ACRE("Acre (Akko)", 32.9276, 35.0818);

    val location: UserLocation
        get() = UserLocation(
            city = city,
            lat = lat,
            lang = lang
        )
}
