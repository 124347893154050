package com.osg.truebase.ui.assigners

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import com.osg.truebase.ui.annotatedText.asAnnotatedString
import com.osg.truebase.ui.assigners.def.GenObjAssigner
import com.osg.ui.core.resources.UiText

sealed class LinesType {
    data object Single : LinesType()
    data class Multi(val minLines: Int) : LinesType()
}

data class TextFieldAssigner(
    private val linesType: LinesType = LinesType.Single,
    private val keyboardType: KeyboardType = KeyboardType.Text,
    private val directions: UiText,
    private val validate: (String) -> Boolean,
) : GenObjAssigner<String> {
    @Composable
    override fun content(
        modifier: Modifier,
        current: String?,
        onAnswered: (String) -> Unit,
    ) {
        val focusManager = LocalFocusManager.current
        Box(
            modifier = modifier.fillMaxWidth(),
            contentAlignment = Alignment.Center,
        ) {
            var answer by remember { mutableStateOf("") }
            OutlinedTextField(
                minLines = when (linesType) {
                    is LinesType.Single -> 1
                    is LinesType.Multi -> linesType.minLines
                },
                singleLine = linesType is LinesType.Single,
                keyboardOptions = KeyboardOptions(
                    keyboardType = keyboardType,
                    imeAction = ImeAction.Done
                ),
                value = answer,
                onValueChange =
                    { newAnswer ->
                        answer = newAnswer
                        val isValid = validate(newAnswer)
                        if (isValid) {
                            onAnswered(newAnswer)
                        }

                    },
                keyboardActions = KeyboardActions(
                    onDone = {
                        focusManager.clearFocus()
                    }
                ),
                label = {
                    Text(
                        directions.asAnnotatedString(),
                        style = LocalTextStyle.current.copy(
                            color = MaterialTheme.colorScheme.secondary
                        )
                    )
                },
                modifier = Modifier.fillMaxWidth(),
                colors = OutlinedTextFieldDefaults.colors(
                    focusedBorderColor = MaterialTheme.colorScheme.secondary
                )
            )
        }
    }
}