package osg.uiZone.common.baseRepos.handlers

import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.user.UserLocation
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.withTimeout
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import com.osg.appData.location.ICurrentLocation
import com.osg.appData.location.IPlatformGps
import com.osg.appData.location.PlatformCoordination


interface IUserLocationFlow{
    val currentLocationState: Flow<UserLocation>
}

interface ILocationService: IUserLocationFlow

class LocationService(
):ILocationService, KoinComponent{
    private val gpsService: IPlatformGps by inject()
    private val currentLocationService: ICurrentLocation by inject()

    override val currentLocationState: Flow<UserLocation> = flow {
        val gpsLocation = try {
            withTimeout(10000L) {
                gpsService.fetchGpsLocation()
            }
        }catch (e:Exception){
            AppLogger.e("LocationService", e.message?:"")
            PlatformCoordination(
                lat = 32.76144680538592,
                lang = 34.963453383676715
            )
        }
        val fetchedLocation = currentLocationService.fetchCurrentLocation(gpsLocation)
        emit(fetchedLocation)
    }
}