package com.osg.appData.ml

import kotlinx.serialization.Serializable

@Serializable
data class CandidateMlGrade(
    val uid: String,
    val embeddingGrade: Float,
    val matchingPointsGrade: List<Float> = emptyList(),
)

