package osg.uiZone.expect.permission

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect

class JsPermissionHandler: PermissionHandler{
    @Composable
    override fun AskPermission(
        permission: PermissionType,
        callback: (PermissionStatus) -> Unit
    ) {
        LaunchedEffect(Unit) {
            callback(PermissionStatus.GRANTED)
        }
    }

    @Composable
    override fun ResolvePermissionStatus(
        permission: PermissionType,
        callback: (PermissionStatus) -> Unit
    ) {
        LaunchedEffect(Unit) {
            callback(PermissionStatus.GRANTED)
        }
    }

    @Composable
    override fun Force(
        permission: PermissionType,
        callback: (PermissionStatus) -> Unit
    ) {
        LaunchedEffect(Unit) {
            callback(PermissionStatus.GRANTED)
        }
    }

}

actual val permissionHandler: PermissionHandler
    get() = JsPermissionHandler()

