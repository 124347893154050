package com.osg.truebase.data.user

import kotlinx.serialization.Serializable

interface GeoLocationDegrees {
    val lat: Double
    val lang: Double
}


@Serializable
data class UserLocation (
    override val lat: Double = 0.0,
    override val lang: Double = 0.0,
    val locatedCity: String = "", // by geocoding
    val city: String = "", // chosen by user
    val countryCode: String = "",
    val country: String = "",
): GeoLocationDegrees