package osg.uiZone.utills

import appData.trueAutoResources.MatchingInterest

val stringToEmoji = mapOf(
    "Music" to "🎵",
    "Romance" to "🌹",
    "Party" to "🕺",
    "Funny" to "🤣",
    "Drinks" to "🍸",
    "Special Pizza" to "🍕",
    "Sushi Rolls" to "🍣",
    "Alcohol Drinks" to "🍹",
    "Dessert" to "🍰",
    "Cheese" to "🧀",
    "Pretzels" to "🥨",
    "Wine" to "🍷",
    "Surprises" to "🎁",
    "Interests" to "🎨",
    "Lifestyle" to "🪞",
    "Spontaneity" to "✈️",
    "Humor" to "🙊",
    "Think" to "🤔",
    "Sync" to "📶",
    "Trivia" to "🧠",
    "Nostalgia" to "📼",
    "Culinary" to "🍜",
    "Food" to "🍜",
    "Mystery" to "🕵️‍♂️",
    "Problem Solving" to "🕵️‍♂️",
    "Fantasy" to "🧚‍♂️",
    "Intimacy" to "🌄",
    "Art" to "🎨",
    "Adventure" to "🏞️",
    "Open-Mindedness" to "🎁",
    "Warmth" to "☀️",
    "Ambitious" to "🚀",
    "Trust" to "🤝",
    "Sports" to "🤸",
    "Relax" to "🛀",
    "Chemistry" to "🧪",
    "Emotion" to "🤗",
    "Intellectual Attraction" to "🧠",
    "Humor" to "🤣",
    "Curiosity" to "🔍",
    "Perspective" to "👁",
    "Memories" to "💭",
    "Storytelling" to "🎙️",
    "Nature" to "🌳",
    "Sense" to "👃",
    "Coffee" to "☕",
    "Tea" to "🍵",
    "Cocktails" to "🍹",
    "Croissant" to "🥐",
    "Cake" to "🍰",
    "Creativity" to "🎨",
    "Communication" to "📞",
    "Financial Compatibility" to "📈",
)

val MatchingInterest.emoji: String
    get(){
        return when(this){
            MatchingInterest.EmotionalConnection -> "🤗"
            MatchingInterest.Creativity -> "🎨"
            MatchingInterest.PhysicalChemistry -> "🧪"
            MatchingInterest.CommunicationStyle -> "📞"
            MatchingInterest.IntellectualAttraction -> "🧠"
            MatchingInterest.SenseOfHumor -> "🤣"
            MatchingInterest.SenseOfAdventure -> "🏞️"
            MatchingInterest.Spontaneity -> "✈️"
            MatchingInterest.FinancialCompatibility -> "📈"
            MatchingInterest.Curiosity -> "🔍"
            MatchingInterest.KindnessAndWarmth -> "☀️"
            MatchingInterest.OpenMindedness -> "🎁"
        }
    }

fun findEmoji(name: String): String {
    return stringToEmoji.keys.find {
        name.contains(it, ignoreCase = true)
    }?.let { stringToEmoji[it] } ?: "?"
}
fun String.prefixEmoji(): String {
    val emoji = findEmoji(this)
    return "$emoji $this"
}

fun String.suffixEmoji(): String {
    val emoji = findEmoji(this)
    return "$this $emoji"
}