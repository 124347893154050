package com.osg.trumatch.bots.nonProd

import bots.trueAutoResources.BotsMediaRemoteImages
import com.osg.appData.profile.StageHighLight
import com.osg.truebase.data.nodeData.IRemoteImageResource
import com.osg.truebase.data.nodeData.RemoteImage

enum class StageHighlightsCollection(
    val title: String,
    val description: String,
    val stage: Int
) {
    PET("First Dog", "A photo of your first pet or a beloved childhood animal", 1),
    TOY("Favorite Toy", "Picture of a toy or game you loved as a child", 1),
    CONCERT("First Concert", "Photo or ticket from your first concert or event", 1),
    COSTUME("Halloween Costume", "A fun or silly costume you wore for Halloween", 1),
    SNACK("Favorite Snack", "A snack or treat you loved as a kid", 1),

    CAR("First Car", "Image of your first car", 2),
    VACATION("First Vacation", "A picture from your first family trip", 2),
    TROPHY("Achievement", "Photo of a trophy or achievement you’re proud of", 2),
    FRIENDS("Friend Group", "A group photo with friends from childhood", 2),
    HANGOUT("Hangout Spot", "Your favorite place to hang out as a teenager", 2),

    HOME("Childhood Home", "Image of the place where you grew up", 3),
    FAMILY("Family Photo", "A sentimental family photo", 3),
    INSPIRATION("Inspiration", "Photo of someone or something that inspires you", 3),
    NOTE("Handwritten Note", "A note or letter from someone special", 3),
    LIFE_MOMENT("Life-Changing Moment", "Picture of a moment that changed your life", 3),

    LESSON("Life Lesson", "A picture reminding you of a difficult but important life lesson", 4),
    LOSS("Someone You’ve Lost", "A photo of someone who had a lasting impact on your life", 4),
    DREAM("Dream or Goal", "An image representing a goal or dream of yours", 4),
    CELEBRATION("Special Celebration", "Snapshot of a deeply personal celebration", 4),
    FEAR("Overcoming Fear", "A photo tied to overcoming your biggest fear", 4);
}

val stageHighlightsList = listOf(
    StageHighlightsCollection.PET.toStageHighLight(BotsMediaRemoteImages.FIRST_DOG),
    StageHighlightsCollection.CAR.toStageHighLight(BotsMediaRemoteImages.FIRST_CAR),
    StageHighlightsCollection.HOME.toStageHighLight(BotsMediaRemoteImages.M2I0),
    StageHighlightsCollection.DREAM.toStageHighLight(BotsMediaRemoteImages.F0B1),
    StageHighlightsCollection.LOSS.toStageHighLight(BotsMediaRemoteImages.F1B2),
    StageHighlightsCollection.LOSS.toStageHighLight(BotsMediaRemoteImages.M3I0),
)


fun StageHighlightsCollection.toStageHighLight(
    media: IRemoteImageResource
): StageHighLight {
    return StageHighLight(
        title = title,
        unlockStage = stage,
        media = RemoteImage(
            media.mediaPath
        )
    )
}