package osg.uiZone.register.model

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.osg.appData.profile.TechnicalProfile
import com.osg.appData.profile.UserStage
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.ui.commonStates.MainViewUiState
import com.osg.ui.core.commonStates.TrueImage
import com.osg.truebase.ui.commonStates.toSuccess
import com.osg.utils.firstNotNull
import com.osg.utils.waitForSuccess
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import osg.uiZone.common.baseRepos.profile.*
import osg.uiZone.common.baseRepos.profile.MyProfileScope.Companion.myProfileHandler
import osg.uiZone.matchZone.model.EditImageType
import osg.uiZone.profile.EditImageCommand
import osg.uiZone.profile.editImage

data class ImagePickState(
    val currentImages: List<TrueImage>,
    val minimumImageToSave: Int,
)


interface ImagePickDependencies: ISaveMyImages, IProfileImagesStateFlow, ISaveMyProfile, IMyFullProfileFlow, IUpdateUserMap

class ImagePickViewModel(
    private val editProfileDependencies: ImagePickDependencies = myProfileHandler,
) : ViewModel() {
    private val editedImages = MutableStateFlow<List<EditImageType>?>(null)
    val uiState: StateFlow<MainViewUiState<ImagePickState>> = editedImages.map {
        if (it == null) {
            MainViewUiState.Loading
        } else {
            ImagePickState(
                currentImages = it.map { it.trueImage },
                minimumImageToSave = 3
            ).toSuccess()
        }
    }.stateIn(
        scope = viewModelScope,
        started = SharingStarted.WhileSubscribed(),
        initialValue = MainViewUiState.Loading
    )

    init {
        AppLogger.d("ImagePickViewModel init")
        viewModelScope.launch {
            editedImages.value = editProfileDependencies.myLocalImagesFlow.firstNotNull()
        }
    }

    fun editImage(
        editImageCommand: EditImageCommand
    ) {
        viewModelScope.launch {
            val currentImages = editedImages.firstNotNull()
            editedImages.value = currentImages.editImage(editImageCommand)
        }
    }


    fun finishRegister(
        onFinished: () -> Unit
    ) {
        viewModelScope.launch {
            editProfileDependencies.saveMyImages(
                editedImages.firstNotNull()
            )

            val regProfile =  editProfileDependencies.myProfileStateFlow.waitForSuccess().publicProfile.apply {
                stage = UserStage.REGISTERED
            }

            editProfileDependencies.saveMyProfile(regProfile)
            editProfileDependencies.updateUserMap(TechnicalProfile(
                age = regProfile.age,
                gender = regProfile.gender!!,
            ))
            onFinished()
        }
    }
}