package com.osg.truematch.demo.baseModuleSim

import com.osg.appData.candidate.Decision
import com.osg.appData.candidate.Reaction
import com.osg.appData.common.DataRoot
import com.osg.appData.common.UserData
import com.osg.appData.common.UsersInteractRecord
import com.osg.appData.profile.FullProfile
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.resources.ILocalMediaResource
import com.osg.trumatch.bots.nonProd.Bots
import com.osg.trumatch.rootdata.buildRoot
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.encodeToJsonElement
import org.jetbrains.compose.resources.InternalResourceApi
import osg.uiZone.di.FunctionsService

class SimManager(
    val initialStorageData: List<ILocalMediaResource>,
    val initialProfile: FullProfile,
    val databaseDelay: Long = 0L,
    val dataStorageDelay: Long = 0L,
) {
    @OptIn(InternalResourceApi::class)
    suspend fun prepareDb(): JsonElement {
        val onUserReactionMap = mutableMapOf<String, Reaction>()
        Bots.entries.forEach { bot ->
            val reaction = Reaction(
                decision = Decision.LIKE,
                fromUid = bot.getBotUid(),
                toUid = initialProfile.publicProfile.uid,
            )
            onUserReactionMap += bot.getBotUid() to reaction
        }

        val root = buildRoot(
            DataRoot(
                usersData = mapOf(
                    initialProfile.publicProfile.uid to UserData(
                        profile = initialProfile,
                        interactRecord = UsersInteractRecord(
                            onUserReaction = onUserReactionMap
                        )
                    )
                ),
            )
        )

        AppLogger.d(root.toString())
        return Json.encodeToJsonElement(root)
    }

    fun cfInitializer(): suspend CloudFunctionsEngineSim.() -> Unit = {
        val functionsService = FunctionsService()
        Bots.entries.forEach {
            this.callerUid = it.fullProfile.publicProfile.uid
            functionsService.findMeMatchRequest(this.callerUid)
        }
    }

}

