package com.osg.truebase.ui.commonStates

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import com.osg.truebase.ui.animation.LottieAnimation
import com.osg.truebase.ui.animation.LottieAnimationView
import com.osg.truebase.ui.dialog.AlertDialogExitWithError
import com.osg.truebase.ui.dialog.FatalErrorType

sealed class MainViewUiState<out T> {
    data class Success<T>(val data: T) : MainViewUiState<T>()
    data object Loading : MainViewUiState<Nothing>()
    class Error(val error: FatalErrorType) : MainViewUiState<Nothing>()
}

fun <T> T.toSuccess(): MainViewUiState<T> = MainViewUiState.Success(this)

@Composable
fun <T> MainViewUiState<T>.MainViewUi(
    ladingContent: @Composable () -> Unit = {GenLoadingScreen()},
    errorContent: @Composable (FatalErrorType) -> Unit = { AlertDialogExitWithError(it) },
    content: @Composable (T) -> Unit
){
    when(this){
        is MainViewUiState.Success<T> -> {
            content(this.data)
        }

        is MainViewUiState.Error -> {
            errorContent(this.error)
        }
        MainViewUiState.Loading -> {
            ladingContent()
        }
    }
}

@Composable
fun GenLoadingScreen(){
    LottieAnimationView(
        modifier = Modifier
            .fillMaxSize(),
        animationData = LottieAnimation.Loading,
        contentScale = ContentScale.FillWidth
    )
}