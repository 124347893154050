package com.osg.previewLab.signin

import baseModuleSim.AppleSignInSim
import baseModuleSim.GoogleSignInSim
import com.osg.truebase.signin.AppSignInData
import com.osg.truebase.signin.ISignInProvider
import com.osg.truebase.signin.ISignInProviders
import com.osg.truebase.signin.SignInResult


class SignInProviderSim(
    val result: Result<SignInResult> = Result.success(SignInResult(uid = "testUid")),
) : ISignInProviders {
    override fun buildSignInProviders(
        appSignInData: AppSignInData,
    ): List<ISignInProvider> {

        return listOf(
            AppleSignInSim(result),
            GoogleSignInSim(result)
        )
    }
}