package osg.uiZone.graph


import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.statusBarsPadding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavController
import androidx.navigation.NavGraphBuilder
import androidx.navigation.compose.composable
import com.osg.appData.matchZone.invitation.EntertainmentType
import com.osg.ice.CaDependencies
import com.osg.ice.HtDependencies
import com.osg.ice.IIceBreakerDependencies
import com.osg.ice.challengeAccepted.ChallengeAccepted
import com.osg.ice.harmonyTunes.ui.HarmonyTunesImpl
import com.osg.ice.state.IceMatchResult
import com.osg.truebase.ui.commonStates.MainViewUi
import org.koin.compose.koinInject
import osg.uiZone.matchZone.ChatScreen
import osg.uiZone.matchZone.UnmatchedMainScreen
import osg.uiZone.matchZone.entertainment.EntFeedDestination
import osg.uiZone.matchZone.entertainment.IceResultsScreen
import osg.uiZone.matchZone.entertainment.TmEntertainmentDetailsScreen
import osg.uiZone.matchZone.entertainment.data.IceInProcces
import osg.uiZone.matchZone.entertainment.data.IceResultsScreenUiState
import osg.uiZone.matchZone.journey.SharedJourneyScreen
import osg.uiZone.matchZone.model.*
import osg.uiZone.navigation.*
import osg.uiZone.search.screens.ItsMatchScreen

fun NavGraphBuilder.matchZoneGraph(
    navController: NavController
) {
    composable<SharedJourney> {
        val sharedJourneyModel = viewModel { SharedJourneyModel() }
        val uiState by sharedJourneyModel.uiState.collectAsState()
        uiState.MainViewUi { journeyPaneState ->
            SharedJourneyScreen(
                items = journeyPaneState.journeyPanelItems,
                modifier = Modifier.fillMaxSize(),
            )
        }
    }

    composable<MatchZoneChat> {
        val matchZoneChatModel = viewModel { MatchZoneChatModel() }
        val chatZoneUiData by matchZoneChatModel.uiState.collectAsState()
        chatZoneUiData.MainViewUi { state ->
            ChatScreen(
                chatZoneUiData = state,
                modifier = Modifier,
                sendMessage = matchZoneChatModel::sendMessage,
                onRecorderOps = matchZoneChatModel::onRecorderOps,
                onSparkMoveClick = matchZoneChatModel::onSparkMoveClick
            )
        }
    }
    composable<SparkFeed>{ backStackEntry ->
        EntFeedDestination(
            entType = EntertainmentType.SPARK,
            onDestination = navController::navigate
        )
    }

    composable<IceBreakerFeed>{ backStackEntry ->
        EntFeedDestination(
            entType = EntertainmentType.GAME,
            onDestination = navController::navigate
        )
    }

    composable<EntertainmentDetailsDst>{ backStackEntry ->
        val viewModel = viewModel { EntertainmentDetailsModel() }
        val uiState by viewModel.uiState.collectAsState()
        uiState.MainViewUi { entertainmentDataType ->
            TmEntertainmentDetailsScreen(
                entertainmentDataType = entertainmentDataType,
                onInvitationResponse = viewModel::onInvitationResponse,
                onStartGame = {iceBreakerDestination ->
                    navController.navigate(iceBreakerDestination){
                        popUpTo<SharedJourney>{
                            inclusive = false
                        }
                    }
                },
                onBack = navController::navigateUp
            )
        }
    }

    composable<MatchZonePromo> {
        val matchZonePromoModel = viewModel { MatchZonePromoModel() }
        val uiState by matchZonePromoModel.uiState.collectAsState()
        ItsMatchScreen(
            promoUiState = uiState,
            onNavMatchZone = {
                navController.navigate(
                    SharedJourney
                ) {
                    launchSingleTop = true
                    popUpTo(MatchZonePromo) {
                        inclusive = true
                    }
                }
            },
        )
    }

    composable<UnamtchZone> {
        val unMatchModel = viewModel { UnmatchedModel() }
        val unMatchUiState by unMatchModel.unMatchUiState.collectAsState()
        unMatchUiState.MainViewUi {unMatchState ->
            UnmatchedMainScreen(
                modifier = Modifier,
                unmatchUiStates = unMatchState,
                onRematchClicked = navController::popBackStack,
                onLeaveToSearchClicked = {
                    unMatchModel.onUnmatchFinalClicked {
                        navController.navigate(it) {
                            launchSingleTop = true
                            popUpTo(SharedJourney::class) {
                                inclusive = true
                            }
                        }
                    }
                },

                onSendChatClickListener = unMatchModel::sendMessage,
            )
        }

    }

    composable<IceBreakerDestination> { backStackEntry ->
        val viewModel = viewModel { IceBreakerSetupViewModel() }

        val uiState by viewModel.uiState.collectAsState()
        uiState.MainViewUi { iceBreakerState ->
            when(iceBreakerState){
                is IceInProcces -> {
                    iceBreakerState.iceBreakerDependencies.Content(viewModel::onIceMatchResult)
                }
                is IceResultsScreenUiState ->{
                    IceResultsScreen(
                        iceResultsScreenUiState = iceBreakerState,
                        onContinue = {
                            navController.navigate(
                                SharedJourney
                            ){
                                launchSingleTop = true
                                popUpTo(MatchZonePromo) {
                                    inclusive = true
                                }
                            }
                        }
                    )
                }
            }
        }

    }
}

@Composable
fun IIceBreakerDependencies.Content(
    onGameResults: (IceMatchResult) -> Unit
) {
    when(this){
        is CaDependencies -> {
            val challengeAccepted = koinInject<ChallengeAccepted>()
            challengeAccepted.ChallengeAcceptedEntry(
                iceBreakerDependencies = this,
                onGameResults = onGameResults,
                modifier = Modifier.statusBarsPadding()
            )
        }

        is HtDependencies -> {
            val harmonyTunesImpl = koinInject<HarmonyTunesImpl>()
            harmonyTunesImpl.HarmonyTunesEntry(
                iceBreakerDependencies = this,
                onGameResults = onGameResults,
                modifier = Modifier.statusBarsPadding()
            )
        }
    }
}