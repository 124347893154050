package com.osg.appData.common

import com.osg.appData.matchZone.MatchZoneNode
import com.osg.utils.buildPath
import kotlinx.serialization.Serializable

@Serializable
data class MatchZonesRoot(
    val botMatchZone: Map<String, MatchZoneNode> = emptyMap(),
    val userMatchZone: Map<String, MatchZoneNode> = emptyMap(),
){
    companion object {
        val botMatchZonePath: String = buildPath(DataRoot::matchZonesRoot.name, MatchZonesRoot::botMatchZone.name)

        val userMatchZonePath: String = buildPath(DataRoot::matchZonesRoot.name, MatchZonesRoot::userMatchZone.name)
    }
}