package osg.uiZone.search.components

import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.core.spring
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import com.osg.appData.profile.OpenAnswer
import com.osg.appData.register.OpenDeepQuestions
import com.osg.ui.core.resources.string
import com.osg.ui.core.commonStates.asPainter
import com.osg.ui.core.modifirer.noRippleClickable
import com.osg.truebase.ui.theme.dimensions
import osg.uiZone.common.pyrotechnics.animatedNavBar.colorButtons.degreesRotationInterpolation
import osg.uiZone.common.pyrotechnics.animatedNavBar.colorButtons.rotationWithTopCenterAnchor
import osg.uiZone.profile.IncentiveCardType
import osg.uiZone.profile.ItemToolOption


@Composable
fun IncentiveCard(
    modifier: Modifier = Modifier,
    textCard: IncentiveCardType.TextCard
) {
    var itemToolOption by remember { mutableStateOf<ItemToolOption<OpenAnswer>?>(null) }
    Row(
        modifier = modifier,
        verticalAlignment = Alignment.Top
    ) {
        Image(
            painter = textCard.profileImage.asPainter(),
            contentDescription = null,
            contentScale = ContentScale.Crop,
            modifier = Modifier
                .padding(top = 4.dp)
                .size(MaterialTheme.dimensions.smallImageSize)
                .clip(CircleShape)
        )

        Spacer(modifier = Modifier.width(8.dp))
        Column(
            modifier = Modifier.padding(end = 20.dp)
        ) {
            TopCardView(
                modifier = Modifier.fillMaxWidth(),
                title = textCard.title.string,
                itemActionOptions = textCard.itemActionOptions,
                onEditClicked = {
                    itemToolOption = it
                }
            )
            Spacer(modifier = Modifier.height(8.dp))
            Text(
                text = textCard.description,
                style = MaterialTheme.typography.bodyMedium,
                color = MaterialTheme.colorScheme.onSurface,
            )
        }
    }

    ItemTextToolDialog(
        idx = textCard.idx,
        title = textCard.title.string,
        currentText = textCard.description,
        itemToolOption = itemToolOption
    ) {
        itemToolOption = null
    }
}


@Composable
private fun ItemTextToolDialog(
    idx: String,
    title: String,
    currentText: String,
    itemToolOption: ItemToolOption<OpenAnswer>?,
    onRelease: () -> Unit
) {
    if (itemToolOption == null) return
    when (itemToolOption) {
        is ItemToolOption.Edit -> {
            var localCurrentText by remember { mutableStateOf(currentText) }
            Dialog(onDismissRequest = {
                itemToolOption.onCommit(
                    OpenAnswer(
                        questionId = OpenDeepQuestions.valueOf(idx),
                        answer = localCurrentText
                    )
                )
                onRelease()
            }){
                TextField(
                    value = localCurrentText,
                    onValueChange ={
                        localCurrentText = it
                    },
                    label = { Text(title) },
                    modifier = Modifier.padding(16.dp)
                )
            }
        }

        is ItemToolOption.Delete -> {
            LaunchedEffect(Unit) {
                onRelease()
            }
        }
    }
}


@Composable
fun SwingIconButton(
    modifier: Modifier = Modifier,
    imageVector: ImageVector,
    onClick: () -> Unit = {},
) {
    var trigger by remember { mutableStateOf(false) }
    val rotationFraction by animateFloatAsState(
        targetValue = if (trigger) 1f else 0f,
        animationSpec = spring(dampingRatio = 0.7f, stiffness = 20f)
    )
    val rotationAngle = degreesRotationInterpolation(30f, rotationFraction)

    Icon(
        modifier = modifier
            .noRippleClickable(
                onClick = {
                    trigger = !trigger
                    onClick()
                }
            )
            .rotationWithTopCenterAnchor(rotationAngle),
        imageVector = imageVector,
        contentDescription = null,
    )
}