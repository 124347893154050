package osg.uiZone.profile

import androidx.compose.runtime.Immutable
import com.osg.appData.candidate.CandidateProfile
import com.osg.appData.profile.IPublicProfile
import com.osg.appData.profile.OpenAnswer
import com.osg.ui.core.commonStates.TrueImage
import osg.uiZone.profile.IncentiveCardType.ImageComment.Companion.toImageCommentCards
import osg.uiZone.profile.IncentiveCardType.TextCard.Companion.toTextCards
import osg.uiZone.profile.TechnicalDetails.Companion.toTechnicalDetails

@Immutable
data class ProfileUiItems(
    val header: CoverImage,
    val technicalDetails: TechnicalDetails,
    val previewItemsList: List<IncentiveCardType>,
    val initialPos: Int = 0,
)


fun CandidateProfile.profileItemsState(
    profileImages: List<TrueImage>,
): ProfileUiItems {
    val aiCards = IncentiveCardType.AiMatchIncentive(
        matchIncentive = matchIncentive
    )

    return publicProfile.profileItemsState(
        extraCards = listOf(aiCards),
        profileImages = profileImages,
    )
}


fun IPublicProfile.profileItemsState(
    extraCards: List<IncentiveCardType> = emptyList(),
    technicalDetailsAction: List<ItemToolOption<EditableDetails>> = emptyList(),
    imageCommentCardsActions: List<ItemToolOption<EditImageCommand>> = emptyList(),
    profileImages: List<TrueImage>,
    textCardActions: List<ItemToolOption<OpenAnswer>> = emptyList(),
): ProfileUiItems {
    val coverImage = CoverImage(
        truePicture = profileImages.first(),
        itemActionOptions = imageCommentCardsActions
    )
    val profileImage = profileImages.first()
    val restImages = profileImages.drop(1)
    val technicalCard = toTechnicalDetails(technicalDetailsAction)
    val imageCard = toImageCommentCards(
        itemActionOptions = imageCommentCardsActions,
        profileImage = profileImage,
        profileImages=restImages)
    val openQuestionsCards = toTextCards(textCardActions, trueProfileImage = profileImage)
    return ProfileUiItems(
        header = coverImage,
        technicalDetails = technicalCard,
        previewItemsList = blendLists(extraCards, openQuestionsCards, imageCard)
    )
}