package com.osg.truebase.ui.graphics.shader

import androidx.compose.animation.core.withInfiniteAnimationFrameMillis
import androidx.compose.foundation.gestures.detectDragGestures
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.composed
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.input.pointer.pointerInput
import com.osg.truebase.data.logger.AppLogger


val timeState
    @Composable
    get() = produceState(0f) {
        while (true) {
            withInfiniteAnimationFrameMillis { frameTimeMillis ->
                value = frameTimeMillis / 1000f
            }
        }
    }

@Composable
fun timeStateRunner(
    run: Boolean = true,
) = produceState(0f) {
    while (run) {
        withInfiniteAnimationFrameMillis { frameTimeMillis ->
            value = frameTimeMillis / 1000f
        }
    }
}

fun Modifier.shaderDragBackground(
    runtimeShader: CommonRuntimeEffect,
) = composed {
    // Step 3. Set an animator to continuously update the time state every second.
    val time by timeState
    // Step 4. Create a position state to store the position of the touch event.
    var position by remember { mutableStateOf(Offset.Zero) }
    pointerInput(Unit) {
        // Step 5. Add a gesture detector to update the position state on touch events.
        detectTapGestures {
            position = Offset(
                x = it.x,
                y = size.height - it.y
            )
        }
    }.pointerInput(Unit) {
            // Step 6. Add a gesture detector to update the position state when drag events occur.
            detectDragGestures { _, dragAmount ->
                position = Offset(
                    x = position.x + dragAmount.x,
                    y = position.y - dragAmount.y
                )
            }
        }
        // Step 7. Update the size state when the component is placed in the Compose hierarchy.
        .drawBehind {
            // Step 8. Set global constants for the Shader.
            runtimeShader.setFloatUniform("iResolution", size.width, size.height)
            runtimeShader.setFloatUniform("iTime", time)
            runtimeShader.setFloatUniform("iMouse", position.x, position.y)
            drawRect(brush = runtimeShader.brush)
        }
}


fun Modifier.shaderBackground(
    runtimeShader: CommonRuntimeEffect,
    run: Boolean = true,
) = composed {
    val time by timeStateRunner(run)
    drawWithCache {
            // Step 8. Set global constants for the Shader.
//            AppLogger.d("shaderBackground: size.width: ${size.width}, size.height: ${size.height}")
            runtimeShader.setFloatUniform("iResolution", size.width, size.height)
            onDrawBehind {
                runtimeShader.setFloatUniform("iTime", time)
                drawRect(brush = runtimeShader.brush)
            }
        }

    }
