package com.osg.ice.ui

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowForward
import androidx.compose.material.icons.filled.Lock
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.TileMode
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.osg.ice.ui.ds.IEntertainmentCollection
import com.osg.ice.ui.ds.IEntertainmentDataUi
import com.osg.truebase.ice.generated.resources.Res
import com.osg.truebase.ice.generated.resources.soon
import com.osg.ui.core.resources.string
import com.osg.ui.core.commonStates.asPainter
import com.osg.truebase.ui.paint.drawDiagonalLabel
import com.osg.truebase.ui.theme.spacing

private val HighlightCardWidth = 170.dp

@Composable
fun EntertainmentCollectionView(
    entertainmentCollection: IEntertainmentCollection,
    onEntDetails: (String) -> Unit = {},
    modifier: Modifier = Modifier
) {
    Column(modifier = modifier) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween,
            modifier = Modifier.fillMaxWidth()
        ) {
            entertainmentCollection.CollectionHeader()
            IconButton(
                onClick = {}, // TODO maybe in place expand
                modifier = Modifier.align(Alignment.CenterVertically)
            ) {
                Icon(
                    imageVector = Icons.AutoMirrored.Filled.ArrowForward,
                    contentDescription = null,
                )
            }
        }

        HighlightedEnt(
            entertainmentData = entertainmentCollection.entertainmentDataUiList,
            onEntDetails = onEntDetails,
        )
    }
}

@Composable
private fun HighlightedEnt(
    entertainmentData: List<IEntertainmentDataUi>,
    onEntDetails: (String) -> Unit,
    modifier: Modifier = Modifier
) {
    val rowState = rememberLazyListState()
    LazyRow(
        state = rowState,
        modifier = modifier,
        horizontalArrangement = Arrangement.spacedBy(16.dp),
    ) {
        items(entertainmentData) {entertainment ->
            HighlightEntItem(
                entertainment = entertainment,
                onEntertainmentClick = {
                    onEntDetails(entertainment.id)
                },
            )
        }
    }
}

@Composable
private fun HighlightEntItem(
    entertainment: IEntertainmentDataUi,
    onEntertainmentClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    ElevatedCard(
        modifier = modifier
            .size(
                width = HighlightCardWidth,
                height = 250.dp
            )
            .padding(bottom = 16.dp)
    ) {
        Column(
            modifier = Modifier
                .clickable(
                    onClick = onEntertainmentClick,
                    enabled = entertainment.enabled
                ).then(
                    if (entertainment.enabled) {
                        Modifier
                    } else {
                        Modifier.drawDiagonalLabel(
                            text = Res.string.soon.string,
                            color = MaterialTheme.colorScheme.error,
                            style = MaterialTheme.typography.titleSmall.copy(
                                color = MaterialTheme.colorScheme.onError
                            ),
                            labelTextRatio = 5f
                        )
                    }
                )
                .fillMaxSize()
        ) {
            Box(
                modifier = Modifier
                    .height(160.dp)
                    .fillMaxWidth()
            ) {
                Box(
                    modifier = Modifier
                        .height(100.dp)
                        .fillMaxWidth()
                        .background(
                            Brush.horizontalGradient(
                                colors = entertainment.backgroundGradient,
                                tileMode = TileMode.Mirror
                            )
                        )
                )
                if (entertainment.enabled.not()) {
                    Icon(
                        modifier = Modifier
                            .align(Alignment.TopCenter)
                            .padding(8.dp),
                        imageVector = Icons.Filled.Lock,
                        contentDescription = null,
                    )
                }

                Image(
                    painter = entertainment.image.asPainter(),
                    contentDescription = null,
                    modifier = Modifier
                        .size(120.dp)
                        .align(Alignment.BottomCenter)
                        .clip(CircleShape)
                        .background(
                            Brush.horizontalGradient(
                                colors = entertainment.backgroundGradient.map { it.copy(alpha = 0.5f) },
                                tileMode = TileMode.Mirror
                            )
                        ),
                    contentScale = ContentScale.Crop,
                    colorFilter = entertainment.imageFilter,
                )
            }
            Text(
                text = entertainment.name.string,
                maxLines = 2,
                style = MaterialTheme.typography.titleSmall,
                color = if (entertainment.enabled) {
                    MaterialTheme.colorScheme.primary
                } else {
                    MaterialTheme.colorScheme.onSurface
                },
                textAlign = TextAlign.Center,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = MaterialTheme.spacing.small)
            )
            Text(
                text = entertainment.smallSubTitle.string,
                style = MaterialTheme.typography.bodySmall,
                color = MaterialTheme.colorScheme.secondary,
                textAlign = TextAlign.Center,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = MaterialTheme.spacing.small)
            )
        }
    }
}
