package com.osg.ice.challengeAccepted.ds

import com.osg.ice.ui.ds.EntertainmentDataUi
import com.osg.truebase.data.iceDef.IceTag
import com.osg.truebase.data.iceDef.SharedIcebreakerCollection
import com.osg.truebase.ice.generated.resources.Res
import com.osg.truebase.ice.generated.resources.challenge_accepted
import com.osg.truebase.ice.generated.resources.challenge_accepted_description
import com.osg.truebase.ice.generated.resources.challenge_accepted_name
import com.osg.truebase.ice.generated.resources.challenge_accepted_slogan
import com.osg.truebase.ice.generated.resources.harmony_tunes_description
import com.osg.truebase.ice.generated.resources.harmony_tunes_name
import com.osg.truebase.ice.generated.resources.harmony_tunes_slogan
import com.osg.truebase.ice.generated.resources.harmony_tunes_t
import com.osg.ui.core.commonStates.asTrueImage
import com.osg.ui.core.resources.uiText

val SharedIcebreakerCollection.dataUi: EntertainmentDataUi
    get() = when (this) {
        SharedIcebreakerCollection.ChallengeAccepted -> EntertainmentDataUi(
            name = Res.string.challenge_accepted_name.uiText,
            description = Res.string.challenge_accepted_description.uiText,
            slogan = Res.string.challenge_accepted_slogan.uiText,
            image = Res.drawable.challenge_accepted.asTrueImage(),
            vibes = listOf(IceTag.ProblemSolving, IceTag.Sport, IceTag.Trivia),
            id = this.name
        )
        SharedIcebreakerCollection.HarmonyTunes -> {
            EntertainmentDataUi(
                name = Res.string.harmony_tunes_name.uiText,
                description = Res.string.harmony_tunes_description.uiText,
                slogan = Res.string.harmony_tunes_slogan.uiText,
                image = Res.drawable.harmony_tunes_t.asTrueImage(),
                vibes = listOf(IceTag.Music, IceTag.Trivia),
                id = this.name
            )
        }
    }