package com.osg.truebase.ui.annotatedText

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Stable
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.SpanStyle
import com.osg.ui.core.resources.UiText
import com.osg.ui.core.resources.string

@Stable
@Composable
fun UiText.asAnnotatedString(
    basedSpanStyle: SpanStyle = SpanStyle(),
): AnnotatedString {
    return this.string.toAnnotatedString(basedSpanStyle)
}


