package com.osg.truebase.data

enum class LanguageSupport(
    val languageCodes: List<String>,
    val isoCode: String,
    val regionDefault: String,
    val currencyCode: String,
) {
    ENGLISH(
        languageCodes = listOf("en"),
        isoCode = "en",
        regionDefault = "US",
        currencyCode = "USD",
    ),
    HEBREW(
        languageCodes = listOf("he", "iw"),
        isoCode = "he",
        regionDefault = "IL",
        currencyCode = "ILS",
    );

    val localIdentifier: String
        get() = "${isoCode}_${regionDefault}"

    fun selectString(
        en: String,
        he: String
    ): String {
        return when (this) {
            ENGLISH -> en
            HEBREW -> he
        }
    }

    operator fun invoke(
        en: String,
        he: String
    ): String {
        return when (this) {
            ENGLISH -> en
            HEBREW -> he
        }
    }
}