package osg.uiZone.common.bar


import androidx.compose.animation.core.*
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material.icons.filled.Close
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.graphics.drawscope.rotate
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.paint.PreGradientBackground
import com.osg.truebase.ui.theme.brandColors
import com.osg.truebase.ui.theme.spacing
import com.osg.ui.core.commonStates.TrueImage
import com.osg.ui.core.commonStates.asPainter
import com.osg.ui.core.modifirer.noRippleClickable
import com.osg.ui.core.resources.string
import com.osg.ui.core.resources.vector
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.back
import truematch.uizone.generated.resources.heart_bold
import truematch.uizone.generated.resources.rose

@Composable
fun ProfileImageBar(
    image: TrueImage,
    candidateName: String,
    onClick: () -> Unit = {},
    modifier: Modifier = Modifier,
) {
    Row(
        modifier = modifier.noRippleClickable(onClick),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Image(
            painter = image.asPainter(256),
            contentScale = ContentScale.Crop,
            contentDescription = null,
            modifier = Modifier
                .size(65.dp)
                .padding(8.dp)
                .clip(CircleShape)
                .aspectRatio(1f)
        )
        Text(
            text = candidateName,
            color = MaterialTheme.colorScheme.onSurface,
            style = MaterialTheme.typography.titleMedium,
        )
    }
}


@Composable
fun ElevatedIconButton(
    modifier: Modifier = Modifier,
    icon: ImageVector,
    color: Color,
    onClick: () -> Unit,
) {
    IconButton(
        modifier = modifier,
        onClick = onClick,
        enabled = true
    ) {
        Icon(
            modifier = Modifier.fillMaxSize(),
            contentDescription = null,
            imageVector = icon,
            tint = color
        )
    }
}


@Composable
fun DecisionBar(
    modifier: Modifier = Modifier,
    onDislike: () -> Unit,
    onLike: () -> Unit,
    onSuperLike: () -> Unit
) {
    Row(
        modifier = modifier,
        horizontalArrangement = Arrangement.spacedBy(
            space = MaterialTheme.spacing.large,
            alignment = Alignment.CenterHorizontally
        ),
    ) {
        DecisionFloatingButton(
            onClick = onDislike,
            imageVector = Icons.Filled.Close,
            containerColor = MaterialTheme.brandColors.dislikeContainer,
            contentColor = MaterialTheme.brandColors.dislike,
        )
        DecisionFloatingButton(
            modifier = Modifier,
            onClick = onSuperLike,
            imageVector = Res.drawable.rose.vector,
            containerColor = MaterialTheme.brandColors.superLikeContainer,
            contentColor = MaterialTheme.brandColors.superLike,
        )
        DecisionFloatingButton(
            onClick = onLike,
            imageVector = Res.drawable.heart_bold.vector,
            containerColor = MaterialTheme.brandColors.likeContainer,
            contentColor = MaterialTheme.brandColors.like,
        )
    }
}

@Composable
fun DecisionFloatingButton(
    modifier: Modifier = Modifier,
    onClick: () -> Unit = {},
    imageVector: ImageVector,
    containerColor: Color = FloatingActionButtonDefaults.containerColor,
    contentColor: Color = contentColorFor(containerColor),
) {
    FloatingActionButton(
        containerColor = containerColor,
        contentColor = contentColor,
        modifier = modifier,
        onClick = onClick,
        shape = CircleShape,
    ) {
        Icon(
            contentDescription = null,
            imageVector = imageVector,
        )
    }
}

@Composable
fun ImageFloatingButtonAnimated(
    modifier: Modifier = Modifier,
    onClick: () -> Unit = {},
    imageVector: ImageVector,
    borderWidth: Dp = 5.dp,
    size: Dp = 70.dp
) {

    LargeFloatingActionButton(
        modifier = modifier.size(size),
        onClick = onClick,
        shape = CircleShape,
    ) {
        val infiniteTransition = rememberInfiniteTransition(label = "")
        val rotateAnimation = infiniteTransition.animateFloat(
            initialValue = 0f,
            targetValue = 360f,
            animationSpec = infiniteRepeatable(
                animation = tween(2000, easing = LinearEasing),
            ), label = ""
        )
        Image(
            imageVector = imageVector,
            contentScale = ContentScale.Fit,
            contentDescription = null,
            modifier = Modifier
                .drawBehind {
                    rotate(rotateAnimation.value) {
                        drawCircle(
                            Brush.sweepGradient(
                                PreGradientBackground.Rainbow.colors
                            ),
                            style = Stroke(width = borderWidth.toPx())
                        )
                    }
                }
                .aspectRatio(1f)
                .padding(borderWidth)
                .clip(CircleShape)
                .padding(borderWidth)
        )
    }
}

@Composable
fun FloatingNavFab(
    modifier: Modifier = Modifier,
    enabled : Boolean = true,
    vector: ImageVector = Icons.AutoMirrored.Filled.ArrowBack,
    onClick: () -> Unit
) {
    IconButton(
        onClick = onClick,
        enabled = enabled,
        modifier = modifier
            .statusBarsPadding()
            .padding(horizontal = 16.dp, vertical = 10.dp)
            .size(36.dp)
            .background(
                color = MaterialTheme.brandColors.navIconSurface,
                shape = CircleShape
            )
    ) {
        Icon(
            modifier = Modifier,
            imageVector = vector,
            tint = MaterialTheme.brandColors.navIconColor,
            contentDescription = Res.string.back.string
        )
    }
}