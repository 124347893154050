package com.osg.ui.core.resources

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import org.jetbrains.compose.resources.StringResource
import org.jetbrains.compose.resources.stringResource

@Immutable
sealed class UiText {
    class StringValue(
        val str: String,
        val args: UiTextArgs
    ) : UiText()

    class FromStringResource(
        val resourceId: StringResource,
        val args: UiTextArgs
    ) : UiText()

    data object Empty : UiText()
}


@Immutable
sealed class UiTextArgs {
    class Strings(
        val values: List<String>
    ) : UiTextArgs()

    class Resources(
        val values: List<StringResource>
    ) : UiTextArgs()

    class NestedUiText(
        val values: List<UiText>
    ) : UiTextArgs()
}

val UiTextArgs.strings: List<String>
    @Composable
    get() = when (this) {
        is UiTextArgs.Strings -> values
        is UiTextArgs.Resources -> {
            values.map { stringResource(it).escapeChars() }
        }

        is UiTextArgs.NestedUiText -> {
            values.map { uiText ->
                uiText.string
            }
        }
    }

fun StringResource.toUiText(vararg args: StringResource): UiText {
    return UiText.FromStringResource(this, UiTextArgs.Resources(args.toList()))
}

fun StringResource.toUiText(vararg args: String): UiText {
    return UiText.FromStringResource(this, UiTextArgs.Strings(args.toList()))
}

fun StringResource.toUiText(vararg args: UiText): UiText {
    return UiText.FromStringResource(this, UiTextArgs.NestedUiText(args.toList()))
}

fun String.toUiText(vararg args: String): UiText {
    return UiText.StringValue(this, UiTextArgs.Strings(args.toList()))
}

val StringResource.uiText: UiText
    get() = UiText.FromStringResource(this, UiTextArgs.Strings(emptyList()))


val String.uiText: UiText
    get()  = if (this.isEmpty()){
        UiText.Empty
    }else{
        UiText.StringValue(this, UiTextArgs.Strings(emptyList()))
    }


