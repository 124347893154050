package osg.uiZone.matchZone.entertainment
import androidx.compose.foundation.layout.*
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.osg.appData.matchZone.MatchInfo
import com.osg.appData.relationshipStage.computeRelationshipStage
import com.osg.ui.core.resources.string
import osg.uiZone.expect.formatPrice
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.balance_title
import truematch.uizone.generated.resources.stage_title

@Composable
fun BalanceCard(
    matchInfo: MatchInfo,
    modifier: Modifier = Modifier
) {
    ElevatedCard(
        modifier = modifier,
        elevation = CardDefaults.cardElevation(defaultElevation = 6.dp)
    ) {
        Column(
            modifier = Modifier
                .padding(10.dp)

        ) {
            BalanceItem(
                title = Res.string.balance_title.string,
                value = matchInfo.balance.formatPrice(),
            )
            HorizontalDivider(Modifier.padding(5.dp))
            BalanceItem(
                title = Res.string.stage_title.string,
                value = matchInfo.computeRelationshipStage().toString(),
            )
        }
    }
}

@Composable
fun BalanceItem(
    title: String,
    value: String,
    modifier: Modifier = Modifier
) {
    Column(modifier = modifier) {
        Text(
            text = title,
            style = MaterialTheme.typography.bodySmall,
            color = MaterialTheme.colorScheme.primary
        )
        Spacer(Modifier.size(5.dp))
        Row(verticalAlignment = Alignment.CenterVertically) {
            Text(
                text = value,
                style = MaterialTheme.typography.titleSmall,
                color = MaterialTheme.colorScheme.secondary
            )
            Spacer(Modifier.size(1.dp))
        }
    }
}
