package com.osg.appData

import kotlin.reflect.KProperty1

interface INodePath<V>{
    val pathParts: List<String>
}

val INodePath<*>.path: String
    get() = pathParts.joinToString("/")

data class RootChild<V>(
    private val property: KProperty1<*, V>,
): INodePath<V>{
    override val pathParts: List<String> = listOf(property.name)
}

data class NodePath<V>(
    private val basePath: INodePath<*>,
    private val property: KProperty1<*, V>,
): INodePath<V>{
    override val pathParts: List<String> = basePath.pathParts + property.name
}

data class DicNodePath<V>(
    private val basePath: INodePath<*>,
    private val key: String,
): INodePath<V>{
    override val pathParts: List<String> = basePath.pathParts + key
}