package osg.uiZone.matchZone.state.tmEntUi

import androidx.compose.material.icons.Icons

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.osg.appData.matchZone.invitation.Invitation
import com.osg.appData.matchZone.invitation.InvitationContent
import com.osg.appData.matchZone.invitation.InvitationStatus
import com.osg.appData.matchZone.invitation.status
import com.osg.truebase.data.iceDef.InviteAction
import com.osg.truebase.ui.button.DragMultiFab
import com.osg.truebase.ui.button.fabItem
import com.osg.ui.core.commonStates.asTrueVector
import osg.uiZone.matchZone.icons.AltRoute

data class ResponseEntertainmentUi(
    val invitation: Invitation,
    internal val entertainment: NewEntertainmentUiData,
) : IEntResponse, TmEntertainmentUiType by entertainment {
    override fun toResponse(response: InviteAction): InvitationContent {
        return toResponse(response.status)
    }

    fun toResponse(response: InvitationStatus): Invitation {
        return invitation.copy(
            status = response
        )
    }

    @Composable
    override fun FabView(
        modifier: Modifier,
        onOption: (InvitationContent) -> Unit
    ) {
        DragMultiFab(
            fabIcon = Icons.AutoMirrored.Filled.AltRoute.asTrueVector(),
            fabItems = listOf(
                InviteAction.REJECTED.fabItem,
                InviteAction.APPROVED.fabItem,
            ),
            onClick = {
                onOption(
                    toResponse(InviteAction.valueOf(it))
                )
            },
        )
    }
}