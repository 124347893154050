package com.osg.truebase.player.audio

enum class PlayerAction{
    Play,
    Pause,
    Repeat,
}



val PlayerState.action: PlayerAction
    get() = when (this) {
        PlayerState.Playing -> PlayerAction.Pause
        PlayerState.Paused -> PlayerAction.Play
        PlayerState.Finished -> PlayerAction.Repeat
    }