package com.osg.ice.harmonyTunes.ui.doubleSideLetter

import androidx.compose.animation.core.*
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.theme.spacing
import kotlin.math.PI
import kotlin.math.sin

@Immutable
data class LetterState(
    val value: String,
    val idx: Int,
    val letterType: LetterType,
)

@Immutable
data class WordState(
    val letters: List<LetterState>,
){
    val answerState: AnswerStateType = if (letters.any { it.letterType.isFinal().not()}) {
        AnswerStateType.NotFull
    } else if (letters.any { it.letterType == LetterType.Wrong }) {
        AnswerStateType.Wrong
    } else {
        AnswerStateType.Correct
    }
}

@OptIn(ExperimentalLayoutApi::class)
@Composable
fun MultiDecorTextField(
    answerState: AnswerState,
    onValueChange: (TextFieldValue) -> Unit,
    onFinishedAnimation: () -> Unit = {},
    modifier: Modifier = Modifier,
    boxWidth: Dp = 38.dp,
    boxHeight: Dp = 38.dp,
    keyboardOptions: KeyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Text),
    keyboardActions: KeyboardActions = KeyboardActions(),
) {
    val transition = updateTransition(targetState = answerState.answerType == AnswerStateType.NotFull, label = "")
    LaunchedEffect(transition.currentState){
        if (transition.currentState.not()){
            onFinishedAnimation()
        }
    }

    BasicTextField(modifier = modifier,
        value = answerState.currentValue,
        singleLine = true,
        onValueChange = onValueChange,
        enabled = answerState.answerType == AnswerStateType.NotFull,
        keyboardOptions = keyboardOptions,
        keyboardActions = keyboardActions,
        decorationBox = {
            FlowRow(
                horizontalArrangement = Arrangement.spacedBy(
                    boxWidth / 2,
                    Alignment.CenterHorizontally
                ),
                verticalArrangement = Arrangement.spacedBy(
                    MaterialTheme.spacing.medium,
                    Alignment.CenterVertically
                ),

                modifier = modifier.padding(8.dp)
            ) {
                answerState.transformedValues.forEach { word ->
                    DecoratedWord(
                        word = word,
                        transition = transition,
                        xDelay = answerState.xDelay,
                        xDuration = answerState.xDuration,
                        yDelay = answerState.yDelay,
                        yDuration = answerState.yDuration,
                        boxWidth = boxWidth,
                        boxHeight = boxHeight,
                    )
                }
            }
        })
}


@Composable
fun DecoratedWord(
    word: WordState,
    transition: Transition<Boolean>,
    modifier: Modifier = Modifier,
    xDelay: Int = 250,
    xDuration: Int = 500,
    yDelay: Int = 100,
    yDuration: Int = 500,
    boxWidth: Dp = 38.dp,
    boxHeight: Dp = 38.dp,
) {

    Row(
        modifier,
        horizontalArrangement = Arrangement.spacedBy(
            0.dp,
            Alignment.CenterHorizontally
        ),
    ) {

        word.letters.forEach { letterState ->
            val previousDelay = (letterState.idx - 1) * xDelay + xDuration

            val rotationX by transition.animateFloat(
                transitionSpec = {
                    tween(
                        delayMillis = letterState.idx * xDelay,
                        durationMillis = xDuration
                    )
                },
                label = ""
            ) { if (it) 0f else 180f }

            val translationY by transition.animateFloat(
                transitionSpec = {
                    tween(
                        delayMillis = previousDelay + letterState.idx * yDelay,
                        durationMillis = yDuration,
                        easing = FastOutSlowInEasing
                    )
                },
                label = ""
            ) { if (it) 0f else 180f }
            DoubleSide(
                rotationX = rotationX,
                translationY = -100 * sin(translationY * PI.toFloat() / 180f),
                flipType = FlipType.HORIZONTAL,
                cameraDistance = 100f,
                front = {
                    CardLetter(
                        value = letterState.value,
                        color = letterState.letterType.frontColor,
                        boxWidth = boxWidth,
                        boxHeight = boxHeight,
                    )
                },
                back = {
                    CardLetter(
                        value = letterState.value,
                        color = letterState.letterType.backColor,
                        boxWidth = boxWidth,
                        boxHeight = boxHeight
                    )
                }
            )
        }
    }
}