package osg.uiZone.matchZone.chat

import androidx.compose.animation.core.animateDp
import androidx.compose.animation.core.updateTransition
import androidx.compose.foundation.background
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material3.ExtendedFloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.osg.appData.matchZone.Message
import com.osg.ui.core.resources.string
import kotlinx.coroutines.launch
import osg.uiZone.matchZone.icons.ArrowDownward
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.jumpBottom

@Composable
fun ChatMessages(
    conversation: List<Message>,
    myUid: String,
    modifier: Modifier = Modifier
) {
    val scope = rememberCoroutineScope()
    val scrollState = rememberLazyListState()
    val focusManager = LocalFocusManager.current
    LaunchedEffect(conversation.size) {
        scrollState.animateScrollToItem(0)
    }
    Box(modifier = modifier) {
        LazyColumn(
            // this is the only way that I found that chat will go up together with the messages (conversation reversed as well)
            reverseLayout = true,
            verticalArrangement = Arrangement.Top,
            state = scrollState,
            modifier = Modifier
                .pointerInput(Unit) {
                    detectTapGestures { focusManager.clearFocus() }
                }
                .fillMaxSize()
        ) {
            itemsIndexed(conversation.reversed(), key = { _, item -> item.timestamp }) { position, item ->
                val showDate = if (position == conversation.size - 1) {
                    true
                } else {
                    conversation[position].isDifferentDays(conversation[position + 1])
                }
                ChatItem(item, showDate, item.isFromMe(myUid))
            }
        }

        val jumpToBottomButtonEnabled by remember {
            derivedStateOf {
                scrollState.firstVisibleItemIndex != 0 ||
                        scrollState.firstVisibleItemIndex > 1
            }
        }

        JumpToBottom(
            // Only show if the scroller is not at the bottom
            enabled = jumpToBottomButtonEnabled,
            onClicked = {
                scope.launch {
                    scrollState.animateScrollToItem(0)
                }
            },
            modifier = Modifier.align(Alignment.BottomCenter)
        )
    }
}

@Composable
fun ChatItem(
    message: Message,
    showDate: Boolean,
    isFromMe: Boolean
) {
    val padIdentity = if (isFromMe) Pair(12.dp, 0.dp) else Pair(0.dp, 12.dp)
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .padding(8.dp),
    ) {
        if (showDate) {
            Row(
                modifier = Modifier
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.Center
            )
            {
                DateBox(message)
            }
        }
        Box(
            modifier = Modifier
                .padding(start = padIdentity.first, end = padIdentity.second)
                .align(if (isFromMe) Alignment.End else Alignment.Start)
                .clip(
                    RoundedCornerShape(
                        topStart = 48f,
                        topEnd = 48f,
                        bottomStart = if (isFromMe) 48f else 0f,
                        bottomEnd = if (isFromMe) 0f else 48f
                    )
                )
                .background(if (isFromMe) MaterialTheme.colorScheme.primaryContainer else MaterialTheme.colorScheme.tertiaryContainer)
                .padding(16.dp)
        ) {
            Text(
                style = MaterialTheme.typography.bodyLarge,
                color = if (isFromMe) MaterialTheme.colorScheme.onPrimaryContainer else MaterialTheme.colorScheme.onTertiaryContainer,
                text = message.text
            )
        }
    }
}

@Composable
fun DateBox(message: Message) {
    val timeStampString = message.messageTimestamp().string
    Box(
        modifier = Modifier
            .wrapContentSize()
            .padding(8.dp),
        contentAlignment = Alignment.Center
    )
    {
        Text(
            color = MaterialTheme.colorScheme.onSurface.copy(alpha = 0.6f),
            style = MaterialTheme.typography.bodySmall,
            text = timeStampString,
            textAlign = TextAlign.Center
        )
    }
}

private enum class Visibility {
    VISIBLE,
    GONE
}

/**
 * Shows a button that lets the user scroll to the bottom.
 */
@Composable
fun JumpToBottom(
    enabled: Boolean,
    onClicked: () -> Unit,
    modifier: Modifier = Modifier
) {
    // Show Jump to Bottom button
    val transition = updateTransition(
        if (enabled) Visibility.VISIBLE else Visibility.GONE,
        label = "JumpToBottom visibility animation"
    )
    val bottomOffset by transition.animateDp(label = "JumpToBottom offset animation") {
        if (it == Visibility.GONE) {
            (-32).dp
        } else {
            32.dp
        }
    }
    if (bottomOffset > 0.dp) {
        ExtendedFloatingActionButton(
            icon = {
                Icon(
                    imageVector = Icons.Filled.ArrowDownward,
                    modifier = Modifier.height(18.dp),
                    contentDescription = null
                )
            },
            text = {
                Text(text = Res.string.jumpBottom.string)
            },
            onClick = onClicked,
            containerColor = MaterialTheme.colorScheme.surface,
            contentColor = MaterialTheme.colorScheme.primary,
            modifier = modifier
                .offset(x = 0.dp, y = -bottomOffset)
                .height(36.dp)
        )
    }
}