package osg.uiZone.navigation

import com.osg.appData.matchZone.invitation.Invitation
import kotlinx.serialization.Serializable
import osg.uiZone.matchZone.state.MainZonePlaces
import osg.uiZone.matchZone.state.tmEntUi.EntertainmentDetailsUiType

@Serializable
sealed interface MatchZoneDestination : Destination {
    val mainZonePlaces: MainZonePlaces
}

@Serializable
data object SharedJourney : MatchZoneDestination {
    override val mainZonePlaces: MainZonePlaces = MainZonePlaces.JourneyZone
}

@Serializable
data object MatchZoneChat : MatchZoneDestination {
    override val mainZonePlaces: MainZonePlaces = MainZonePlaces.ChatZone
}

@Serializable
data object SparkFeed : MatchZoneDestination {
    override val mainZonePlaces: MainZonePlaces = MainZonePlaces.Spark
}

@Serializable
data object IceBreakerFeed : MatchZoneDestination {
    override val mainZonePlaces: MainZonePlaces = MainZonePlaces.IceBreaker
}

@Serializable
class EntertainmentDetailsDst : Destination {
    constructor(detailsUiType: EntertainmentDetailsUiType){
        DstArgumentHolder.argumentStateFlow.value = EntertainmentDetailsDstArgument(detailsUiType)
    }
}



@Serializable
class IceBreakerDestination: Destination {
    constructor(invitation: Invitation){
        DstArgumentHolder.argumentStateFlow.value = IceBreakerDestinationArgument(invitation)
    }
}

@Serializable
data object MatchZonePromo: Destination

@Serializable
data object UnamtchZone : Destination

//val entFeedType = object : NavType<EntertainmentType>(
//    isNullableAllowed = false
//){
//    override fun get(
//        bundle: SavedState,
//        key: String
//    ): EntertainmentType? {
//        return bundle.read{
//            val type = EntertainmentType.valueOf(this.getString(key))
//            return type
//        }
//    }
//
//    override fun parseValue(value: String): EntertainmentType {
//        val type = EntertainmentType.valueOf(value)
//        return type
//    }
//
//    override fun put(
//        bundle: SavedState,
//        key: String,
//        value: EntertainmentType
//    ) {
//        bundle.write {
//            this.putString(key, value.name)
//        }
//    }
//}
