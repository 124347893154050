package com.osg.appData.resource

data object StorageQueries {
    private const val USER_IMAGE_STORAGE: String = "IMAGES"
    private const val ROOT: String = "USERS_STORAGE"

    private fun userRootPath(uid: String): String {
        return "$ROOT/$uid"
    }

    fun userImagesPath(uid: String): String {
        return "${userRootPath(uid)}/$USER_IMAGE_STORAGE"
    }

    fun userImagePath(uid: String, imageStamp: String): String {
        return "${userImagesPath(uid)}/${imageStamp}"
    }
}