package com.osg.truebase.ui.dialog

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.osg.truebase.ui.filekit.core.pickFile
import com.osg.truebase.ui.filekit.core.readBytes

object JsImagePicker : IPlatformImagePicker {
    @Composable
    override fun ImagePicker(
        onImagePicked: (ImageBytes?) -> Unit
    ) {
        LaunchedEffect(Unit) {
            val file = pickFile(PickerType.Image)
            if (file != null) {
                val bytes = readBytes(file)
                onImagePicked(
                    ImageBytes(
                        bytes = bytes,
                        fileType = file.type
                    )
                )
            }

        }

    }

}

actual val platformImagePicker: IPlatformImagePicker = JsImagePicker