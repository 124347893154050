package com.osg.ui.core.resources

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Stable

val UiText.string: String
    @Stable
    @Composable
    get() {
        return when (this) {
            is UiText.StringValue -> {
                str.format(args.strings)
            }

            is UiText.FromStringResource -> {
                resourceId.string.format(args.strings)
            }

            is UiText.Empty -> {
                ""
            }
        }
    }