package com.osg.appData.matchZone.invitation


import com.osg.truebase.data.iceDef.IChallengeAcceptedInvitation
import com.osg.truebase.data.iceDef.IHarmonyTuneInvitation
import com.osg.truebase.data.iceDef.ht.MusicGenre
import com.osg.utils.time.TimeService
import kotlinx.serialization.Serializable

interface InvitationContent{
    val content: String
    val type: EntertainmentType
    val status: InvitationStatus
}

@Serializable
sealed interface InvitationType{
    @Serializable
    data class ChallengeAcceptedInvitation(
        override val rounds: Int,
        override val gameRootPath: String,
        override val inviterUid: String
    ): IChallengeAcceptedInvitation

    data class HarmonyTuneInvitation(
        override val genres: List<MusicGenre>,
        override val rounds: Int,
        override val gameRootPath: String,
        override val inviterUid: String
    ): IHarmonyTuneInvitation
}

@Serializable
data class Invitation(
    override val content: String,
    override val status: InvitationStatus,
    override val type: EntertainmentType,
    val senderUid: String,
    val sentTimestamp: Long,
): InvitationContent

fun InvitationContent.toInvitation(senderUid: String): Invitation{
    return Invitation(
        content = content,
        status = status,
        type = type,
        senderUid = senderUid,
        sentTimestamp = TimeService.getUtcTime()
    )
}