package osg.uiZone.matchZone.entertainment.baseContent

import com.osg.appData.matchZone.ice.IcebreakerCollection
import com.osg.truebase.data.iceDef.IceTag
import com.osg.ui.core.commonStates.asTrueImage
import com.osg.ui.core.resources.uiText
import com.osg.utils.plus
import osg.uiZone.matchZone.entertainment.TMEntertainmentDataUiBase
import truematch.uizone.generated.resources.*

val pieceOfMatchBase = TMEntertainmentDataUiBase(
    description = Res.string.pieces_of_match_description.uiText,
    image = Res.drawable.puzzel.asTrueImage(),
    name = Res.string.pieces_of_match_name.uiText,
    
    unlockStage = 2,
    slogan = Res.string.pieces_of_match_slogan.uiText,
    vibes = IceTag.Memories + IceTag.ProblemSolving,
    id = IcebreakerCollection.PieceOfMatch.name,
)