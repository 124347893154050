package com.osg.trumatch.bots.botProfiles

import bots.trueAutoResources.BotsMediaRemoteImages
import com.osg.appData.profile.*
import com.osg.appData.register.OpenDeepQuestions
import com.osg.truebase.data.nodeData.RemoteProfileImage
import com.osg.truebase.data.user.Gender
import com.osg.truebase.preview.data.IsraelLocation
import com.osg.trumatch.bots.nonProd.stageHighlightsList


internal val omerProfile = FullProfile(
    personalProfile = PersonalProfile(
        facebookId = "10225277936830285",
        lastName = "Nudelman",
        preferencesMatch = PreferencesMatch(
            ageMax = 35,
            ageMin = 25,
            maxDistance = 150,
            dateGender = Gender.Male
        ),
    ),
    publicProfile = PublicProfile(
        birthDay = "01/24/1997",
        firstName = "Omer",
        gender = Gender.Female,
        height = 176,
        occupation = "Actor",
        picturesCommentList = listOf(
            RemoteProfileImage(
                comment = "",
                mediaPath = BotsMediaRemoteImages.F0B0.mediaPath
            ),
            RemoteProfileImage(
                comment = "Pink elegance \uD83D\uDC96✨ #FashionMoment",
                mediaPath = BotsMediaRemoteImages.F0B1.mediaPath
            ),
            RemoteProfileImage(
                comment = "",
                mediaPath = BotsMediaRemoteImages.F0B2.mediaPath
            )
        ),
        stage = UserStage.REGISTERED,
        verified = true,
        openQuestionAnswer = listOf(
            OpenAnswer(
                answer = "Passionate actor and social media influencer. Bringing charisma to both screen and life. Seeking genuine connections and memorable moments. Let's create our own story together. 🌟 #ActorLife #InfluencerLove #RealConnections",
                questionId = OpenDeepQuestions.INTRODUCTION
            )
        ),
        location = IsraelLocation.TEL_AVIV.location,
        stageHighLights = stageHighlightsList
    )
)