package bot

import com.osg.appData.bots.BOT_UID_PREFIX
import com.osg.appData.bots.BOT_UID_REGEX
import com.osg.appData.candidate.Decision
import com.osg.appData.candidate.Reaction
import com.osg.appData.common.DataRoot.Companion.allReactionWritePath
import com.osg.appData.common.MatchZonesRoot
import com.osg.appData.common.UserData
import com.osg.appData.common.UserData.Companion.userDataRoot
import com.osg.appData.matchZone.MatchZoneNode.Companion.resolveChatPath
import com.osg.appData.matchZone.MatchZoneNode.Companion.resolveIceBreakersPath
import com.osg.appData.matchZone.MatchZoneNode.Companion.resolveInvitationPath
import com.osg.appData.matchZone.Message
import com.osg.appData.matchZone.ice.IcebreakerCollection
import com.osg.appData.matchZone.invitation.EntertainmentType
import com.osg.appData.matchZone.invitation.Invitation
import com.osg.appData.matchZone.invitation.InvitationStatus
import com.osg.appData.path
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.nodeData.fromString
import com.osg.truebase.database.events.EventHandler
import com.osg.truebase.ice.events.BotActions
import com.osg.truebase.ice.events.ValueCreatedEvent
import com.osg.utils.time.TimeService
import kotlinx.coroutines.delay

private val matchZoneBot = "${MatchZonesRoot.botMatchZonePath}/{userIds}"
private val gameRootPath: String = resolveIceBreakersPath(matchZoneBot, "{gameId}")


class TmBotActions(
    private val databaseHandler: IBotActionRepo
): BotActions(
    databaseHandler,
    BOT_UID_PREFIX,
    gameRootPath
){
    val valCreatedEvents: List<ValueCreatedEvent> by lazy {
        buildIceEvents() + newMessageEventHandler() + invitationHandler() + newUserBurnHandler() + newInvitationWhenMatch()
    }

    fun newInvitationWhenMatch(): ValueCreatedEvent {
        return EventHandler(
            matchZoneBot
        ) { nativeEvent ->
            val matchZonePath = nativeEvent.resolvedPath
            BOT_UID_REGEX.find(matchZonePath)?.value?.let { botUid ->
                val newInvitation = Invitation(
                    senderUid = botUid,
                    status = InvitationStatus.NEW,
                    sentTimestamp = TimeService.getUtcTime(),
                    content = IcebreakerCollection.HarmonyTunes.name,
                    type = EntertainmentType.GAME,
                )
                val invitationPath = resolveInvitationPath(
                    matchZonePath,
                )
                delay(15*1000)
                databaseHandler.pushInvitation(
                    invitationPath,
                    newInvitation
                )
            }
        }.toValueCreatedEvent(
            this::newInvitationWhenMatch.name
        )
    }

    fun newMessageEventHandler(): ValueCreatedEvent{
        return EventHandler(
            "${resolveChatPath(matchZoneBot)}/{messageId}"
        ) { nativeEvent ->
            val data = nativeEvent.serilizedData.fromString<Message>()
            if (data.uid.contains(BOT_UID_PREFIX)) {
                return@EventHandler
            }

            val chatPath = nativeEvent.resolvedPathParent
            val botUid = BOT_UID_REGEX.find(chatPath)!!.value
            delay(3000)
            databaseHandler.pushMessage(
                chatPath,
                Message(
                    uid = botUid,
                    text = "Hello",
                    timestamp = data.timestamp + 30
                )
            )
        }.toValueCreatedEvent(
            this::newMessageEventHandler.name
        )
    }

    fun invitationHandler(): ValueCreatedEvent{
        return EventHandler(
            "${resolveInvitationPath(matchZoneBot)}/{invitationId}"
        ) { nativeEvent ->
            val invitationPath = nativeEvent.resolvedPathParent
            val invitation = nativeEvent.serilizedData.fromString<Invitation>()
            val botUid = BOT_UID_REGEX.find(invitationPath)!!.value
            if (invitation.senderUid != botUid) {
                val response = when(invitation.status){
                    InvitationStatus.NEW -> {
                        InvitationStatus.APPROVED
                    }
                    InvitationStatus.COMPLETED -> {
                        InvitationStatus.COMPLETED
                    }
                    else -> {
                        return@EventHandler
                    }
                }
                val acceptedInv = invitation.copy(
                    senderUid = botUid,
                    status = response,
                    sentTimestamp = TimeService.getUtcTime()
                )
                databaseHandler.pushInvitation(
                    invitationPath,
                    acceptedInv
                )
            }
        }.toValueCreatedEvent(
            this::invitationHandler.name
        )
    }

    private val uidKey = "uid"
    fun newUserBurnHandler(): ValueCreatedEvent{
        return EventHandler(
            UserData.userDataPath("{$uidKey}").path
        ) { nativeEvent ->
            val uid = nativeEvent.params[uidKey]!!
            if (BOT_UID_REGEX.matches(uid)){
                AppLogger.d("Ignoring bot with uid $uid")
                return@EventHandler
            }

            AppLogger.d("Reacting to user with uid $uid")
            val allBotsUid = databaseHandler.fetchUsersData(userDataRoot.path).filterKeys { uid->
                BOT_UID_REGEX.matches(uid)
            }.map { it.key }
            allBotsUid.forEach { botUid ->
                val reaction = Reaction(
                    decision = Decision.LIKE,
                    fromUid = botUid,
                    toUid = uid,
                )
                val writePaths = allReactionWritePath(botUid, uid)
                writePaths.forEach { path ->
                    databaseHandler.setReaction(path, reaction)
                }
            }
        }.toValueCreatedEvent(
            this::newUserBurnHandler.name
        )
    }
}



