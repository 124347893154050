package osg.uiZone.navigation

import com.osg.appData.matchZone.invitation.Invitation
import kotlinx.coroutines.flow.MutableStateFlow
import osg.uiZone.matchZone.state.tmEntUi.EntertainmentDetailsUiType


// TODO Remove this: it was created because of the bug in compose navigation in browser "IllegalArgumentException: Navigation destination that matches route NavDeepLinkRequest"
sealed interface DstArgumentType

data class EntertainmentDetailsDstArgument(
    val detailsUiType: EntertainmentDetailsUiType
): DstArgumentType

data class IceBreakerDestinationArgument(
    val invitation: Invitation
): DstArgumentType
object DstArgumentHolder{
    val argumentStateFlow = MutableStateFlow<DstArgumentType?>(null)
}