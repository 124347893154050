package com.osg.truebase.ice.events

import com.osg.def.database.DatabaseService
import com.osg.truebase.data.iceDef.IceContentPreferences
import com.osg.truebase.data.iceDef.IceDirStruct
import com.osg.truebase.data.iceDef.IceLiveEvent
import com.osg.truebase.data.nodeData.fromString
import com.osg.truebase.database.events.EventHandler

interface IBaseBotActionRepo {
    suspend fun pushLiveData(path: String, value: IceLiveEvent)
    suspend fun pushPreferences(path: String, value: IceContentPreferences)
}

val DatabaseService.botRepo: IBaseBotActionRepo
    get() = object : IBaseBotActionRepo {
        override suspend fun pushLiveData(path: String, data: IceLiveEvent) {
            push(path, data)
        }

        override suspend fun pushPreferences(
            path: String,
            data: IceContentPreferences
        ) {
            push(path, data)
        }
    }

data class ValueCreatedEvent(
    val name: String,
    val eventHandler: EventHandler
)


open class BotActions(
    private val databaseHandler: IBaseBotActionRepo,
    private val botUid: String,
    private val gameRootPath: String
) {
    fun buildIceEvents(): List<ValueCreatedEvent> {
        return listOf(
            iceEventHandler(),
            prefrenceEventHandler()
        )
    }

    fun iceEventHandler(): ValueCreatedEvent {
        return EventHandler(
            "${IceDirStruct.iceEventPath(gameRootPath)}/{eventId}"
        ) { nativeEvent ->
            val iceLiveEvent = nativeEvent.serilizedData.fromString<IceLiveEvent>()
            if (iceLiveEvent.uid.contains(botUid).not()) {
                val response = iceLiveEvent.iceResponseType.genRandomResponse(botUid, iceLiveEvent)
                databaseHandler.pushLiveData(
                    nativeEvent.resolvedPathParent,
                    response
                )
            }
        }.toValueCreatedEvent(
            this::iceEventHandler.name
        )
    }

    fun prefrenceEventHandler(): ValueCreatedEvent {
        return EventHandler(
            "${IceDirStruct.iceSettingsPath(gameRootPath)}/{settingsId}"
        ) { nativeEvent ->
            val otherPlayerPreferences = nativeEvent.serilizedData.fromString<IceContentPreferences>()
            if (otherPlayerPreferences.uid.contains(botUid).not()) {
                val botPref = IceContentPreferences(
                    seenRoundsIds = listOf("dummyId"),
                    uid = botUid
                )
                databaseHandler.pushPreferences(nativeEvent.resolvedPathParent, botPref)
            }
        }.toValueCreatedEvent(
            this::prefrenceEventHandler.name
        )
    }

    fun EventHandler.toValueCreatedEvent(
        functionName: String,
    ): ValueCreatedEvent {
        return ValueCreatedEvent(
            name = functionName,
            eventHandler = this
        )
    }
}