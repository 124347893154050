package com.osg.truebase.ui.ds

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.Settings
import com.osg.truebase.ui.icons.ContactMail
import com.osg.truebase.ui.icons.PrivacyTip
import com.osg.truebase.ui.icons.RoomService
import com.osg.truebase.ui.strings.CommonStringRes
import com.osg.truebase.ui_button.generated.resources.Res
import com.osg.truebase.ui_button.generated.resources.logout_vec
import com.osg.truebase.ui_strings.generated.resources.settings_feedback
import com.osg.truebase.ui_strings.generated.resources.settings_logout
import com.osg.truebase.ui_strings.generated.resources.settings_privacy_policy
import com.osg.truebase.ui_strings.generated.resources.settings_remove_account
import com.osg.truebase.ui_strings.generated.resources.settings_terms_of_service
import com.osg.ui.core.commonStates.TrueVector
import com.osg.ui.core.commonStates.asTrueVector
import com.osg.ui.core.resources.UiText
import com.osg.ui.core.resources.uiText

enum class PreDefinedButtonUi(
    override val trueVector: TrueVector,
    override val uiText: UiText
): IGeneralIconButtonUi{
    LOGOUT(
        trueVector = Res.drawable.logout_vec.asTrueVector(),
        uiText = CommonStringRes.settings_logout.uiText
    ),
    REMOVE_ACCOUNT(
        trueVector = Icons.Default.Delete.asTrueVector(),
        uiText = CommonStringRes.settings_remove_account.uiText
    ),
    PRIVACY_POLICY(
        trueVector = Icons.Default.PrivacyTip.asTrueVector(),
        uiText = CommonStringRes.settings_privacy_policy.uiText
    ),
    TERMS_OF_SERVICE(
        trueVector = Icons.Default.RoomService.asTrueVector(),
        uiText = CommonStringRes.settings_terms_of_service.uiText
    ),
    CONTACT_MAIL(
        trueVector = Icons.Default.ContactMail.asTrueVector(),
        uiText = CommonStringRes.settings_feedback.uiText
    ),
}

