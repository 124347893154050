package osg.uiZone.register.ui


import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.sp
import com.osg.truebase.ui.animation.LottieAnimationView
import com.osg.truebase.ui.animation.toResource
import com.osg.ui.core.commonStates.TrueImage
import com.osg.truebase.ui.components.paint.PictureHolderGrid
import com.osg.truebase.ui.dialog.ImageBytes
import com.osg.truebase.ui.dialog.platformImagePicker
import osg.uiZone.expect.permission.PermissionHandlerScreen
import osg.uiZone.expect.permission.PermissionStatus
import osg.uiZone.expect.permission.PermissionUiRationale
import osg.uiZone.profile.EditDialogToShow
import osg.uiZone.profile.EditImageCommand
import uiZone.trueAutoResources.LocalAnimations

@Composable
fun EditProfileImagesView(
    currentImages: List<TrueImage>,
    editImageCommand: (EditImageCommand) -> Unit,
) {
    var dialogToShow by remember { mutableStateOf<EditDialogToShow>(EditDialogToShow.AskPermission) }
    PictureHolderGrid(
        pictures = currentImages,
        totalItems = 6,
        onAddPicture = {
            dialogToShow = EditDialogToShow.Pick
        },
        onExistPictureClicked = { idx ->
            dialogToShow = EditDialogToShow.RemovePicture(
                idx = idx
            )
        }
    )
    ImagePickDialogs(
        dialogToShow = dialogToShow,
        onNewPictureSelected = { imBytes ->
            editImageCommand(
                EditImageCommand.Add(
                    imBytes
                )
            )
            dialogToShow = EditDialogToShow.None
        },
        onDismissDialog = {
            dialogToShow = EditDialogToShow.None
        },
        onRemovePicture = { idx ->
            editImageCommand(
                EditImageCommand.Delete(
                    idx = idx
                )
            )
            dialogToShow = EditDialogToShow.None
        },
    )
}

@Composable
fun ImagePickDialogs(
    dialogToShow: EditDialogToShow,
    onNewPictureSelected: (ImageBytes) -> Unit,
    onDismissDialog: () -> Unit,
    onRemovePicture: (Int) -> Unit,
) {
    when (dialogToShow) {
        is EditDialogToShow.RemovePicture -> {
            DeleteConfirmationDialog(
                onDismissDialog = onDismissDialog,
                onConfirm = {
                    onRemovePicture(
                        dialogToShow.idx
                    )
                },
            )
        }

        is EditDialogToShow.Pick -> {
            platformImagePicker.ImagePicker { imageBytes ->
                if (imageBytes != null) {
                    onNewPictureSelected(imageBytes)
                }
            }
        }

        is EditDialogToShow.Loading -> {
            LottieAnimationView(
                modifier = Modifier.fillMaxSize(),
                animationData = LocalAnimations.ANIMATION_LOGO.toResource(),
            )
        }

        is EditDialogToShow.AskPermission -> {
            PermissionHandlerScreen(
                permissionType = PermissionUiRationale.GALLERY,
                onPermissionStatus = {
                    if (it == PermissionStatus.GRANTED) {
                        onDismissDialog()
                    }
                },
            )
        }

        is EditDialogToShow.None -> {
            // nothing to do :)
        }
    }
}


@Composable
fun DeleteConfirmationDialog(
    onDismissDialog: () -> Unit,
    onConfirm: () -> Unit,
) {
    AlertDialog(
        onDismissRequest = onDismissDialog,
        title = {
            Text(
                text = "delete picture",
                fontWeight = FontWeight.SemiBold,
                fontSize = 16.sp
            )
        },
        text = {
            Text("confirm delete picture")
        },
        confirmButton = {
            TextButton(
                onClick = onConfirm
            ) {
                Text("delete")
            }
        },
        dismissButton = {
            TextButton(
                onClick = onDismissDialog
            ) {
                Text("cancel")
            }
        }
    )
}