@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package truematch.crossui.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.FontResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainFont0 {
  public val dancing_script: FontResource by 
      lazy { init_dancing_script() }

  public val lora_variable_font_wght: FontResource by 
      lazy { init_lora_variable_font_wght() }

  public val ws_v: FontResource by 
      lazy { init_ws_v() }
}

@InternalResourceApi
internal fun _collectCommonMainFont0Resources(map: MutableMap<String, FontResource>) {
  map.put("dancing_script", CommonMainFont0.dancing_script)
  map.put("lora_variable_font_wght", CommonMainFont0.lora_variable_font_wght)
  map.put("ws_v", CommonMainFont0.ws_v)
}

public val Res.font.dancing_script: FontResource
  get() = CommonMainFont0.dancing_script

private fun init_dancing_script(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:dancing_script",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/font/dancing_script.ttf", -1, -1),
    )
)

public val Res.font.lora_variable_font_wght: FontResource
  get() = CommonMainFont0.lora_variable_font_wght

private fun init_lora_variable_font_wght(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:lora_variable_font_wght",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/font/lora_variable_font_wght.ttf", -1, -1),
    )
)

public val Res.font.ws_v: FontResource
  get() = CommonMainFont0.ws_v

private fun init_ws_v(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:ws_v",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/font/ws_v.ttf", -1, -1),
    )
)
