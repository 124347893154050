package com.osg.truebase.data.logger

interface PlatformLogger {
    fun e(message: String)
    fun d(message: String)
}

interface PlatformLoggerFactory {
    fun getLogger(subsystem:String, tag: String): PlatformLogger
}

expect val BaseLoggerFactory: PlatformLoggerFactory

object EmptyLoggerFactory: PlatformLoggerFactory {
    override fun getLogger(subsystem: String, tag: String): PlatformLogger {
        return object : PlatformLogger {
            override fun e(message: String) {}
            override fun d(message: String) {}
        }
    }
}

object AppLogger: PlatformLogger {
    var loggerFactory: PlatformLoggerFactory = BaseLoggerFactory

    override fun e(message: String) {
        val logger = loggerFactory.getLogger("", "AppLogger")
        logger.e(message)
    }

    override fun d(message: String) {
        val logger = loggerFactory.getLogger("", "AppLogger")
        logger.d(message)
    }

    fun e(tag: String?, message: String) {
        val localLogger = loggerFactory.getLogger("", tag.toString())
        localLogger.e(message)
    }

    fun d(tag: String?, message: String) {
        val localLogger = loggerFactory.getLogger("", tag.toString())
        localLogger.d(message)
    }
}