package appData.trueAutoResources




enum class MatchingInterest(val stringResourceKey: String, val englishValue: String){
	EmotionalConnection("emotional_connection", "Emotional Connection"),
	Creativity("creativity", "Creativity"),
	PhysicalChemistry("physical_chemistry", "Physical Chemistry"),
	CommunicationStyle("communication_style", "Communication Style"),
	IntellectualAttraction("intellectual_attraction", "Intellectual Attraction"),
	SenseOfHumor("sense_of_humor", "Sense of Humor"),
	SenseOfAdventure("sense_of_adventure", "Sense of Adventure"),
	Spontaneity("spontaneity", "Spontaneity"),
	FinancialCompatibility("financial_compatibility", "Financial Compatibility"),
	Curiosity("curiosity", "Curiosity"),
	KindnessAndWarmth("kindness_and_warmth", "Kindness and Warmth"),
	OpenMindedness("open_mindedness", "Open-Mindedness"),
}