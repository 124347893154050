package com.osg.truebase.ui.dialog

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import com.osg.truebase.ui.error.ExitApp
import com.osg.truebase.ui.generated.resources.Res
import com.osg.truebase.ui_strings.generated.resources.network_error
import org.jetbrains.compose.resources.StringResource
import com.osg.truebase.ui_strings.generated.resources.error_title
import com.osg.truebase.ui_strings.generated.resources.exit_app
import com.osg.truebase.ui_strings.generated.resources.location_not_supported
import com.osg.truebase.ui_strings.generated.resources.permission_error
import com.osg.truebase.ui_strings.generated.resources.unexpected_error
import com.osg.truebase.ui.generated.resources.vec_bug
import com.osg.truebase.ui.generated.resources.vec_location_off
import com.osg.truebase.ui.generated.resources.vec_signal_off
import com.osg.truebase.ui.strings.CommonStringRes
import com.osg.truebase.ui_strings.generated.resources.network_error
import com.osg.ui.core.resources.string
import com.osg.ui.core.resources.vector
import org.jetbrains.compose.resources.DrawableResource

enum class FatalErrorType(
    val messageResource: StringResource,
    val icon: DrawableResource,
) {
    NETWORK_ERROR(CommonStringRes.network_error, Res.drawable.vec_signal_off),
    UNKNOWN_ERROR(CommonStringRes.unexpected_error, Res.drawable.vec_bug),
    PERMISSION_ERROR(CommonStringRes.permission_error, Res.drawable.vec_bug),
    UNSUPPORTED_LOCATION(CommonStringRes.location_not_supported, Res.drawable.vec_location_off),
}


@Composable
fun AlertDialogExitWithError(
    fatalErrorType: FatalErrorType,
) {
    var isConfirm by remember { mutableStateOf(false) }
    val text = fatalErrorType.messageResource.string
    AlertDialog(
        icon = {
            Icon(fatalErrorType.icon.vector, contentDescription = text)
        },
        title = {
            Text(
                modifier = Modifier.fillMaxWidth(),
                textAlign = TextAlign.Center,
                text = CommonStringRes.error_title.string
            )
        },
        text = {
            Text(
                modifier = Modifier.fillMaxWidth(),
                textAlign = TextAlign.Center,
                text = text
            )
        },
        onDismissRequest = {
            isConfirm = true
        },
        confirmButton = {
            TextButton(
                onClick = {
                    isConfirm = true
                }
            ) {
                Text(CommonStringRes.exit_app.string)
            }
        }
    )

    if (isConfirm) {
        ExitApp()
    }
}

@Composable
fun FunctionalityNotAvailablePopup(onDismiss: () -> Unit) {
    AlertDialog(
        onDismissRequest = onDismiss,
        text = {
            Text(
                text = "Functionality not available \uD83D\uDE48",
                style = MaterialTheme.typography.bodyMedium
            )
        },
        confirmButton = {
            TextButton(onClick = onDismiss) {
                Text(text = "CLOSE")
            }
        }
    )
}