package osg.uiZone.matchZone.state

import androidx.compose.runtime.Immutable
import com.osg.truebase.data.nodeData.IImageDeclaration
import osg.uiZone.matchZone.journey.items.StageHighLightUi
import osg.uiZone.matchZone.state.tmEntUi.NewEntertainmentUiData

@Immutable
sealed class JourneyPanelItem(
    val order : Int,
    val spanSize: Int = 3,
) {
    @Immutable
    data class StageHighlights(
        val currentStage: Int,
        val highlights: List<StageHighLightUi>,
    ) : JourneyPanelItem(
        order = 0,
    )

    @Immutable
    data class RelationShipStage(
        val stage: Int,
        val entData: List<NewEntertainmentUiData>,
        val initialStage: Int = 0
    ) : JourneyPanelItem(
        order = 1,
    )
    @Immutable
    data class Balance(
        val balance: String,
    ) : JourneyPanelItem(
        order = 2,
    )
    @Immutable
    data class SaveTheDate(
        val sparkEvents: List<SparkEvent>
    ) : JourneyPanelItem(
        order = 4,
    )
    @Immutable
    data class Playlist(
        val playlist: List<IImageDeclaration>
    ) : JourneyPanelItem(
        order = 3,
    )
}