@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package truematch.web.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val join_waiting_list: StringResource by 
      lazy { init_join_waiting_list() }

  public val join_waiting_list_button: StringResource by 
      lazy { init_join_waiting_list_button() }

  public val join_waiting_list_description: StringResource by 
      lazy { init_join_waiting_list_description() }

  public val start_demo_button: StringResource by 
      lazy { init_start_demo_button() }

  public val thank_you_join: StringResource by 
      lazy { init_thank_you_join() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("join_waiting_list", CommonMainString0.join_waiting_list)
  map.put("join_waiting_list_button", CommonMainString0.join_waiting_list_button)
  map.put("join_waiting_list_description", CommonMainString0.join_waiting_list_description)
  map.put("start_demo_button", CommonMainString0.start_demo_button)
  map.put("thank_you_join", CommonMainString0.thank_you_join)
}

internal val Res.string.join_waiting_list: StringResource
  get() = CommonMainString0.join_waiting_list

private fun init_join_waiting_list(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:join_waiting_list", "join_waiting_list",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ), "composeResources/truematch.web.generated.resources/values-he/web.commonMain.cvr", 261, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.web.generated.resources/values/web.commonMain.cvr", 261, 49),
    )
)

internal val Res.string.join_waiting_list_button: StringResource
  get() = CommonMainString0.join_waiting_list_button

private fun init_join_waiting_list_button(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:join_waiting_list_button", "join_waiting_list_button",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ), "composeResources/truematch.web.generated.resources/values-he/web.commonMain.cvr", 10, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.web.generated.resources/values/web.commonMain.cvr", 10, 52),
    )
)

internal val Res.string.join_waiting_list_description: StringResource
  get() = CommonMainString0.join_waiting_list_description

private fun init_join_waiting_list_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:join_waiting_list_description", "join_waiting_list_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ), "composeResources/truematch.web.generated.resources/values-he/web.commonMain.cvr", 63, 197),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.web.generated.resources/values/web.commonMain.cvr", 63, 197),
    )
)

internal val Res.string.start_demo_button: StringResource
  get() = CommonMainString0.start_demo_button

private fun init_start_demo_button(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:start_demo_button", "start_demo_button",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.web.generated.resources/values/web.commonMain.cvr", 311, 53),
    )
)

internal val Res.string.thank_you_join: StringResource
  get() = CommonMainString0.thank_you_join

private fun init_thank_you_join(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:thank_you_join", "thank_you_join",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ), "composeResources/truematch.web.generated.resources/values-he/web.commonMain.cvr", 311, 70),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.web.generated.resources/values/web.commonMain.cvr", 365, 70),
    )
)
