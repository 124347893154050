package com.osg.appData.auth

import com.osg.appData.profile.PublicProfile
import com.osg.truebase.data.user.Gender
import kotlinx.serialization.Serializable


@Serializable
data class BasicIdentity(
    val uid: String,
    val gender: Gender,
    val age: Int,
)

val PublicProfile.basicIdentity: BasicIdentity
    get() = BasicIdentity(uid, gender!!, age)