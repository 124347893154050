package com.osg.truebase.ui.locale

import androidx.compose.ui.text.intl.Locale
import com.osg.truebase.data.LanguageSupport
import com.osg.truebase.ui.screens.settings.ISettingsDelegator
import com.osg.truebase.ui.theme.supportedLanguages

object PlatformLocale : ISettingsDelegator{
    override fun loadLanguage(language: LanguageSupport) {
        console.error("not possible to change language in web app need to change browser language")
    }
}

actual val settingsDelegator: ISettingsDelegator = PlatformLocale

private external object Intl {
    class NumberFormat(locales: String, options: dynamic) {
        fun format(l: Double): String
    }
}

private fun formatAsUSD(currencyCode: String): dynamic = js("({ style: 'currency', currency: currencyCode})")


actual val localUnits: ILocalUnits  = object : ILocalUnits {
    override fun formatPrice(price: Double): String {
        val supportedLanguage = Locale.current.supportedLanguages
        val formatter = Intl.NumberFormat(supportedLanguage.languageCodes.first(), formatAsUSD(supportedLanguage.currencyCode))
        return formatter.format(price)
    }

    override fun getHeightMetricUnit(): String {
        return "cm"
    }

    override fun getDistanceMetricUnit(): String {
        return "km"
    }
}