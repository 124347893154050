package com.osg.previewLab.ice

import com.osg.truebase.data.iceDef.IceMultiMediaDownloader
import com.osg.truebase.data.nodeData.IRemoteMediaResource
import com.osg.truebase.data.resources.ILocalMediaResource
import kotlinx.coroutines.delay
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.readResourceBytes

class MultiMediaDownloaderSimImpl(
    private val delayTime: Long = 500,
    private val localRemoteAllMedia: List<ILocalMediaResource>,
) : IceMultiMediaDownloader {
    @OptIn(InternalResourceApi::class)
    override suspend fun downloadMultimedia(media: IRemoteMediaResource): ByteArray {
        delay(delayTime)

        val mediaEnum = localRemoteAllMedia.first { it.mediaPath.endsWith(media.mediaPath) }
        return readResourceBytes(mediaEnum.mediaPath)
    }
}