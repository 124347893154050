package com.osg.appData.matchZone

import com.osg.truebase.data.iceDef.IceTag
import kotlinx.serialization.Serializable


@Serializable
data class MatchInfo(
    val balance: Price = Price(),
    val matchTime: Long = 0,
    val matchingVibesIds: List<IceTag> = emptyList()
)

@Serializable
data class Price(
    val raw: Double = 0.0,
)