package com.osg.truebase.ui.nativeViews

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler

@Composable
actual fun WebView(modifier: Modifier, url: String) {
    val uriHandler = LocalUriHandler.current
    LaunchedEffect(url) {
        uriHandler.openUri(url)
    }
}