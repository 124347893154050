package com.osg.truebase.data.iceDef

import kotlinx.serialization.Serializable

@Serializable
sealed interface IceLiveEvent{
    val uid: String
    val round: Int
    val timestamp: Long
}

@Serializable
data class CaLiveEvent(
    val data: Int,
    override val round: Int,
    override val uid: String,
    override val timestamp: Long,
): IceLiveEvent

@Serializable
data class HtLiveEvent(
    val data: String,
    val hints: Int,
    override val round: Int = -1,
    override val uid: String,
    override val timestamp: Long,
): IceLiveEvent