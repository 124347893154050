package harmoney.trueAutoResources


enum class IceMediaLocalHarmoneyMusic(override val mediaPath: String): com.osg.truebase.data.resources.ILocalMediaResource {
	WITHOUT_YOU("composeResources/com.osg.truebase.harmoney.generated.resources/files/harmonyTunes/without_you.mp3"),
	IM_BLUE_WIN("composeResources/com.osg.truebase.harmoney.generated.resources/files/harmonyTunes/im_blue_win.mp3"),
	HT_SKY_FULL_WIN("composeResources/com.osg.truebase.harmoney.generated.resources/files/harmonyTunes/ht_sky_full_win.mp3"),
	GOLD_DIGGER("composeResources/com.osg.truebase.harmoney.generated.resources/files/harmonyTunes/gold_digger.mp3"),
	GREEN_EYES("composeResources/com.osg.truebase.harmoney.generated.resources/files/harmonyTunes/green_eyes.mp3"),
	GOLD_DIGGER_WIN("composeResources/com.osg.truebase.harmoney.generated.resources/files/harmonyTunes/gold_digger_win.mp3"),
	HT_SKY_FULL("composeResources/com.osg.truebase.harmoney.generated.resources/files/harmonyTunes/ht_sky_full.mp3"),
	CIRCLE_OF_LIFE_WIN("composeResources/com.osg.truebase.harmoney.generated.resources/files/harmonyTunes/circle_of_life_win.mp3"),
	CIRCLE_OF_LIFE("composeResources/com.osg.truebase.harmoney.generated.resources/files/harmonyTunes/circle_of_life.mp3"),
	BLUE("composeResources/com.osg.truebase.harmoney.generated.resources/files/harmonyTunes/blue.mp3"),
	CAME_HERE_FOR_LOVE_WIN("composeResources/com.osg.truebase.harmoney.generated.resources/files/harmonyTunes/came_here_for_love_win.mp3"),
	CAME_HERE_FOR_LOVE("composeResources/com.osg.truebase.harmoney.generated.resources/files/harmonyTunes/came_here_for_love.mp3");
}
enum class IceMediaLocalHarmoneyDatabase(override val mediaPath: String): com.osg.truebase.data.resources.ILocalMediaResource {
	HARMONY_TUNES("composeResources/com.osg.truebase.harmoney.generated.resources/files/data/harmony_tunes.db");
}

val IceMediaLocalHarmoneyAllMedia: List<com.osg.truebase.data.resources.ILocalMediaResource> = IceMediaLocalHarmoneyMusic.entries +
	IceMediaLocalHarmoneyDatabase.entries