package osg.uiZone.matchZone.icons
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.materialIcon
import androidx.compose.material.icons.materialPath
import androidx.compose.ui.graphics.vector.ImageVector

public val Icons.Outlined.LocalBar: ImageVector
    get() {
        if (_localBar != null) {
            return _localBar!!
        }
        _localBar = materialIcon(name = "Outlined.LocalBar") {
            materialPath {
                moveTo(14.77f, 9.0f)
                lineTo(12.0f, 12.11f)
                lineTo(9.23f, 9.0f)
                horizontalLineToRelative(5.54f)
                moveTo(21.0f, 3.0f)
                horizontalLineTo(3.0f)
                verticalLineToRelative(2.0f)
                lineToRelative(8.0f, 9.0f)
                verticalLineToRelative(5.0f)
                horizontalLineTo(6.0f)
                verticalLineToRelative(2.0f)
                horizontalLineToRelative(12.0f)
                verticalLineToRelative(-2.0f)
                horizontalLineToRelative(-5.0f)
                verticalLineToRelative(-5.0f)
                lineToRelative(8.0f, -9.0f)
                verticalLineTo(3.0f)
                close()
                moveTo(7.43f, 7.0f)
                lineTo(5.66f, 5.0f)
                horizontalLineToRelative(12.69f)
                lineToRelative(-1.78f, 2.0f)
                horizontalLineTo(7.43f)
                close()
            }
        }
        return _localBar!!
    }

private var _localBar: ImageVector? = null
