package com.osg.truebase.ui.assigners.def

import androidx.compose.runtime.Immutable
import com.osg.truebase.ui.animation.LottieAnimation
import com.osg.ui.core.resources.UiText

@Immutable
data class GenericQuestionAssigner<TARGET>(
    val genObjAssigner: GenObjAssigner<TARGET>,
    val questionUiData: QuestionUiData,
)


fun <TARGET> GenObjAssigner<TARGET>.toGenericQuestionAssigner(
    questionUiData: QuestionUiData,
    ): GenericQuestionAssigner<TARGET> {
    return GenericQuestionAssigner(
        genObjAssigner = this,
        questionUiData = questionUiData,
    )
}

fun <ASSIGNER_TYPE, TARGET> GenericQuestionAssigner<ASSIGNER_TYPE>.genQuestionTransformer(
    reverseTransformer: (TARGET) -> ASSIGNER_TYPE?,
    transformer: (ASSIGNER_TYPE, TARGET?) -> TARGET,
): GenericQuestionAssigner<TARGET>{
    return GenericQuestionAssigner(
        genObjAssigner = genObjAssigner.genObjTransformer(reverseTransformer, transformer),
        questionUiData = questionUiData,
    )
}

data class QuestionUiData(
    val title: UiText,
    val animationResource: LottieAnimation,
    val directions: UiText,
)