package com.osg.ice.harmonyTunes.ui

import androidx.compose.foundation.layout.Box
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.osg.truebase.player.audio.PlayerState

@Composable
fun WaitingForPlayerAnimation(
    modifier: Modifier = Modifier,
    playerState: PlayerState,
    onHintClick: () -> Unit,
){
    Box{
        VinylAlbumCoverAnimation(
            modifier = modifier.align(Alignment.Center),
            playerState = playerState,
        )
        if (playerState == PlayerState.Finished) {
            Hint(
                onHintClick = onHintClick,
                modifier = Modifier.align(Alignment.Center)
            )
        }
    }
}