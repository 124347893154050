package com.osg.appData.candidate


import com.osg.appData.auth.BasicIdentity
import kotlinx.serialization.Serializable


@Serializable
data class MatchRequestData(
    val requesterIdentity: BasicIdentity,
    val matchIdentity: BasicIdentity,
    val requesterDecision: Decision,
    val candidateDecision: Decision

)


@Serializable
data class UnMatchRequest(
    val requesterIdentity: BasicIdentity,
    val matchIdentity: BasicIdentity,
)