package osg.uiZone.matchZone.chat

import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.core.tween
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.togetherWith
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.runtime.*
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.composed
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.focus.focusTarget
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.theme.spacing
import osg.uiZone.expect.media.RecordOperations
import osg.uiZone.matchZone.icons.Bolt
import osg.uiZone.matchZone.sparkMove.SparkMovesCollection


enum class ActionButtonState {
    Record,
    Text,
}

sealed class UserInputState() {
    data object Text : UserInputState()
    data object Special : UserInputState()
}

@Composable
fun UserInputChatBox(
    onSendChatClickListener: (String) -> Unit,
    onRecorderOps: (RecordOperations) -> Unit = {},
    onSparkMoveClick: (SparkMovesCollection) -> Unit,
    modifier: Modifier
) {
    var textState by rememberSaveable(stateSaver = TextFieldValue.Saver) {
        mutableStateOf(TextFieldValue())
    }

    var currentInputSelector by remember {
        mutableStateOf<UserInputState>(
            UserInputState.Text
        )
    }

    Surface(
        modifier = Modifier,
        tonalElevation = 2.dp,
        contentColor = MaterialTheme.colorScheme.secondary
    ) {
        AnimatedContent(
            modifier = modifier,
            targetState = currentInputSelector,
            transitionSpec = {
                fadeIn(
                    animationSpec = tween(200)
                ) togetherWith fadeOut(animationSpec = tween(200))
            },
            label = "Animated Content"
        ) { targetState ->
            when (targetState) {
                is UserInputState.Text -> {
                    UserInputText(
                        modifier = Modifier,
                        textFieldValue = textState,
                        onTextChanged = { textState = it },
                        onSpecialInputClicked = {
                            currentInputSelector = UserInputState.Special
                        },
                        onSend = onSendChatClickListener,
                        onRecorderOps = onRecorderOps,
                    )
                }

                is UserInputState.Special -> {
                    SelectorExpanded(
                        modifier = Modifier.onFocusLost {
                            currentInputSelector = UserInputState.Text
                        },
                        onSparkMoveClick = onSparkMoveClick,
                        onInputSelector = {
                            currentInputSelector = it
                        }
                    )
                }
            }
        }
    }
}

fun Modifier.onFocusLost(onLost: () -> Unit) = this.composed {
    var isFocused by remember { mutableStateOf(false) }
    onFocusChanged { focusState ->
        if (!focusState.isFocused && isFocused)
            onLost()
        isFocused = focusState.isFocused
    }
}

@Composable
private fun SelectorExpanded(
    modifier: Modifier,
    onSparkMoveClick: (SparkMovesCollection) -> Unit,
    onInputSelector: (UserInputState) -> Unit
) {
    val focusRequester = remember { FocusRequester() }
    LaunchedEffect(Unit) {
        focusRequester.requestFocus()
    }

    SpecialInput(
        modifier = modifier
            .focusRequester(focusRequester)
            .focusTarget(),
        onSparkMoveClick = onSparkMoveClick,
        onInputSelector = onInputSelector
    )
}


@Composable
fun InputSelectorButton(
    onClick: () -> Unit,
    icon: ImageVector,
    description: String,
    modifier: Modifier = Modifier
) {
    IconButton(
        onClick = onClick,
        modifier = modifier
    ) {
        Icon(
            icon,
            modifier = Modifier,
            contentDescription = description
        )
    }
}


@Composable
fun UserInputText(
    modifier: Modifier = Modifier,
    textFieldValue: TextFieldValue,
    onTextChanged: (TextFieldValue) -> Unit,
    onSpecialInputClicked: () -> Unit,
    onSend: (String) -> Unit,
    onRecorderOps: (RecordOperations) -> Unit,
) {
    val actionButtonState = if (textFieldValue.text.isEmpty()) {
        ActionButtonState.Record
    } else {
        ActionButtonState.Text
    }

    var isRecordingMessage by remember { mutableStateOf(false) }
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
            .padding(
                horizontal = MaterialTheme.spacing.small,
                vertical = MaterialTheme.spacing.small
            ),
        horizontalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small)
    ) {

        if (isRecordingMessage) {
            RecordingIndicator(
                modifier = Modifier.weight(1f),
            )
        } else {
            InputSelectorButton(
                onClick = onSpecialInputClicked,
                icon = Icons.Outlined.Bolt,
                description = "Special",
                modifier = Modifier
            )

            BasicChatTextField(
                modifier = Modifier,
                textFieldValue = textFieldValue,
                onValueChange = onTextChanged
            )
        }

        MultiActionButton(
            modifier = Modifier,
            actionButtonState = actionButtonState,
            recording = isRecordingMessage,
            onStartRecording = {
                onRecorderOps(RecordOperations.Start)
                val consumed = isRecordingMessage.not()
                isRecordingMessage = true
                consumed
            },
            onSendText = {
                onSend(textFieldValue.text)
                onTextChanged(TextFieldValue())
            },
            onFinishRecording = {
                isRecordingMessage = false
                onRecorderOps(RecordOperations.Stop)
            },
            onCancelRecording = {
                isRecordingMessage = false
                onRecorderOps(RecordOperations.Cancel)
            },
        )
    }
}
