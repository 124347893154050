package com.osg.utils.time

import kotlinx.datetime.Clock
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atTime
import kotlinx.datetime.format.Padding
import kotlinx.datetime.format.char
import kotlinx.datetime.minus
import kotlinx.datetime.plus
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime
import kotlin.math.abs
import kotlin.time.Duration
import kotlin.time.DurationUnit
import kotlin.time.toDuration

object TimeService {
    fun toDefaultFormat(year: Int, month: Int, day: Int): String {
        return "$year-$month-$day"
    }
    fun calculateAge(birthDateString: String): Int {
        val supportedFormats = listOf(
            LocalDate.Format {
                year(); char('-')
                monthNumber(padding = Padding.NONE)
                char('-')
                dayOfMonth(padding = Padding.NONE)
            },
            LocalDate.Format { monthNumber(); char('/'); dayOfMonth(); char('/'); year() },
            LocalDate.Format { dayOfMonth(); char('/'); monthNumber(); char('/'); year() },
        )
        for (format in supportedFormats) {
            try {
                val birthDate = LocalDate.parse(birthDateString, format)
                val birthDateLong: Long = birthDate.toEpochDays() * 24 * 60 * 60 * 1000L
                return calculateAge(birthDateLong)
            } catch (_: Exception) {
                continue
            }
        }
        throw IllegalArgumentException("Invalid date format")
    }

    fun calculateAge(birthDateStamp: Long): Int {
        val birthDate = birthDateStamp.millisToUtcDateTime()
        val currentDate = nowDateTime
        return currentDate.year - birthDate.year
    }

    private fun systemTimeNow(): Instant = Clock.System.now()
    fun systemTimeIn(duration: Duration): Instant = Clock.System.now() + duration
    private fun nowAsEpochMilliseconds(): Long = systemTimeNow().toEpochMilliseconds()

    fun Long.millisToUtcDateTime(): LocalDateTime {
        return Instant.fromEpochMilliseconds(this).toLocalDateTime(TimeZone.UTC)
    }

    fun Long.secToUtcDateTime(): LocalDateTime {
        return Instant.fromEpochSeconds(this).toLocalDateTime(TimeZone.UTC)
    }
    fun Long.toDefaultFormat(): String {
        val birthDate = this.millisToUtcDateTime()
        return toDefaultFormat(birthDate.year, birthDate.monthNumber, birthDate.dayOfMonth)
    }

    val nowDateTime: LocalDateTime
        get() {
            return getUtcTime().millisToUtcDateTime()
        }

    fun Long.daysDifferenceFromNow(): Long {
        return differenceFromNowToDuration().inWholeDays
    }

    fun Long.secDifferenceFromNow(): Long {
        return differenceFromNowToDuration().inWholeSeconds
    }

    fun Long.differenceFromNowToDuration(): Duration {
        val diff = getUtcTime() - this
        return diff.toDuration(DurationUnit.MILLISECONDS)
    }

    fun getUtcTime(): Long = nowAsEpochMilliseconds()

    fun getUtcInstDaysFromNowAt(dayFromNow: Int, atHour: Int): Instant {
        val date = nowDateTime.date
        val datePeriod = DatePeriod(days = abs(dayFromNow))

        val dateTime = if (dayFromNow > 0) {
            date.plus(datePeriod)
        } else {
            date.minus(datePeriod)
        }

        val atTime = dateTime.atTime(atHour, 0)
        return atTime.toInstant(TimeZone.UTC)
    }
}