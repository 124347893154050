package com.osg.appData.matchZone.invitation

import com.osg.truebase.data.iceDef.InviteAction


enum class InvitationStatus {
    NEW,
    APPROVED,
    REJECTED,
    COMPLETED,
    NOT_RESPONDED
}

val InviteAction.status: InvitationStatus
    get() {
        return when (this) {
            InviteAction.NEW -> InvitationStatus.NEW
            InviteAction.APPROVED -> InvitationStatus.APPROVED
            InviteAction.REJECTED -> InvitationStatus.REJECTED
        }
    }