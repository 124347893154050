package ice.trueAutoResources


internal enum class LocalResourceImages(override val mediaPath: String): com.osg.truebase.data.resources.ILocalImages {
	BEAT_SYNC("composeResources/com.osg.truebase.ice.generated.resources/drawable/beat_sync.webp"),
	ONE_AI("composeResources/com.osg.truebase.ice.generated.resources/drawable/one_ai.webp"),
	BLIND_CANVAS("composeResources/com.osg.truebase.ice.generated.resources/drawable/blind_canvas.webp"),
	VINYL_INNER_IMAGE("composeResources/com.osg.truebase.ice.generated.resources/drawable/vinyl_inner_image.webp"),
	CHALLENGE_ACCEPTED("composeResources/com.osg.truebase.ice.generated.resources/drawable/challenge_accepted.webp"),
	HARMONY_TUNES("composeResources/com.osg.truebase.ice.generated.resources/drawable/harmony_tunes.webp"),
	HARMONY_TUNES_T("composeResources/com.osg.truebase.ice.generated.resources/drawable/harmony_tunes_t.webp"),
	VINYL_BACKGROUND("composeResources/com.osg.truebase.ice.generated.resources/drawable/vinyl_background.webp");
}
internal enum class LocalResourceAnimations(override val mediaPath: String): com.osg.truebase.data.resources.ILocalAnimations {
	ANIMATION_LOADING("composeResources/com.osg.truebase.ice.generated.resources/files/animation_loading.json"),
	ANIMATION_CROSSEDFINGERS("composeResources/com.osg.truebase.ice.generated.resources/files/animation_crossedFingers.json"),
	LOTTIE_DJ("composeResources/com.osg.truebase.ice.generated.resources/files/lottie_dj.json");
}
internal enum class LocalResourceLottie(override val mediaPath: String): com.osg.truebase.data.resources.ILocalAnimations {
	ANIMATION_ELEPHANT("composeResources/com.osg.truebase.ice.generated.resources/files/animation_elephant.lottie");
}

internal val LocalResourceAllMedia: List<com.osg.truebase.data.resources.ILocalMediaResource> = LocalResourceImages.entries +
	LocalResourceAnimations.entries +
	LocalResourceLottie.entries