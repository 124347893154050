package com.osg.truebase.spotlight

import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Rect
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.IntSize
import androidx.compose.ui.unit.LayoutDirection
import kotlin.math.pow
import kotlin.math.roundToInt

enum class Corner {
    TOP_LEFT,
    TOP_RIGHT,
    BOTTOM_LEFT,
    BOTTOM_RIGHT
}

val Corner.flip: Corner
    get() = when (this) {
        Corner.TOP_LEFT -> Corner.TOP_RIGHT
        Corner.TOP_RIGHT -> Corner.TOP_LEFT
        Corner.BOTTOM_LEFT -> Corner.BOTTOM_RIGHT
        Corner.BOTTOM_RIGHT -> Corner.BOTTOM_LEFT
    }

val Corner.inverse: Corner
    get() = when (this) {
        Corner.TOP_LEFT -> Corner.BOTTOM_RIGHT
        Corner.TOP_RIGHT -> Corner.BOTTOM_LEFT
        Corner.BOTTOM_LEFT -> Corner.TOP_RIGHT
        Corner.BOTTOM_RIGHT -> Corner.TOP_LEFT
    }


data class CornerRect(
    val corner: Corner,
    val offset: Offset
)


val Rect.corners: List<CornerRect>
    get() = listOf(
        CornerRect(Corner.TOP_LEFT, topLeft),
        CornerRect(Corner.TOP_RIGHT, topRight),
        CornerRect(Corner.BOTTOM_LEFT, bottomLeft),
        CornerRect(Corner.BOTTOM_RIGHT, bottomRight)
    )

fun Rect.closestCornerTo(offset: Offset): CornerRect {
    val distances = corners.map {
        (it.offset - offset).getDistance()
    }

    return corners[distances.indexOf(distances.min())]
}

internal fun calculateTextOffset(
    closestCornerOffset: Offset,
    corner: Corner,
    textRect: Rect,
    screenSize: IntSize,
    layoutDirection: LayoutDirection
): Offset {
    val isLtr = layoutDirection == LayoutDirection.Ltr
    val textCornerOffset = when (corner) {
        Corner.TOP_LEFT -> Offset(-textRect.width, -textRect.height)
        Corner.TOP_RIGHT -> Offset(0f, -textRect.height)
        Corner.BOTTOM_LEFT -> Offset(-textRect.width, 0f)
        Corner.BOTTOM_RIGHT -> Offset(0f, 0f)
    }

    // Apply x offset based on direction and y offset directly
    val initialX = closestCornerOffset.x + textCornerOffset.x * (if (isLtr) 1 else -1)
    val initialY = closestCornerOffset.y + textCornerOffset.y

    // Handle RTL/LTR screen bounds differently
    val boundedX = if (isLtr) {
        initialX.coerceIn(0f, screenSize.width - textRect.width)
    } else {
        val normalizedX = -screenSize.width + initialX
        normalizedX.coerceIn(-screenSize.width + textRect.width, 0f)
    }

    return Offset(
        x = boundedX,
        y = initialY.coerceIn(0f, screenSize.height - textRect.height)
    )
}

fun Rect.radius(): Float {
    return (topLeft - bottomRight).getDistance()/2f
}

fun Offset.toIntOffset(): IntOffset {
    return IntOffset(x = x.roundToInt(), y = y.roundToInt())
}

fun IntOffset.toOffset(): Offset {
    return Offset(x.toFloat(), y.toFloat())
}