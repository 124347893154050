package osg.uiZone.matchZone.icons
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.materialIcon
import androidx.compose.material.icons.materialPath
import androidx.compose.ui.graphics.vector.ImageVector

public val Icons.AutoMirrored.Filled.CallSplit: ImageVector
    get() {
        if (_callSplit != null) {
            return _callSplit!!
        }
        _callSplit = materialIcon(name = "AutoMirrored.Filled.CallSplit", autoMirror = true) {
            materialPath {
                moveTo(14.0f, 4.0f)
                lineToRelative(2.29f, 2.29f)
                lineToRelative(-2.88f, 2.88f)
                lineToRelative(1.42f, 1.42f)
                lineToRelative(2.88f, -2.88f)
                lineTo(20.0f, 10.0f)
                lineTo(20.0f, 4.0f)
                close()
                moveTo(10.0f, 4.0f)
                lineTo(4.0f, 4.0f)
                verticalLineToRelative(6.0f)
                lineToRelative(2.29f, -2.29f)
                lineToRelative(4.71f, 4.7f)
                lineTo(11.0f, 20.0f)
                horizontalLineToRelative(2.0f)
                verticalLineToRelative(-8.41f)
                lineToRelative(-5.29f, -5.3f)
                close()
            }
        }
        return _callSplit!!
    }

private var _callSplit: ImageVector? = null
