package com.osg.trumatch.bots.botProfiles

import bots.trueAutoResources.BotsMediaRemoteImages
import com.osg.appData.profile.*
import com.osg.appData.register.OpenDeepQuestions
import com.osg.truebase.data.nodeData.RemoteProfileImage
import com.osg.truebase.data.user.Gender
import com.osg.truebase.preview.data.IsraelLocation

internal val talMoradProfile = FullProfile(
    personalProfile = PersonalProfile(

        facebookId = "10225277936830287",
        lastName = "Morad",
        preferencesMatch = PreferencesMatch(
            ageMax = 40,
            ageMin = 20,
            maxDistance = 50,
            dateGender = Gender.Female
        ),
    ),
    publicProfile = PublicProfile(
        birthDay = "06/29/1997",
        firstName = "Tal",
        gender = Gender.Male,
        height = 180,
        occupation = "Software Developer",
        picturesCommentList = listOf(
            RemoteProfileImage(
                comment = "",
                mediaPath = BotsMediaRemoteImages.M3I0.mediaPath
            ),
            RemoteProfileImage(
                comment = "Coding in the zone 💻✨ #DeveloperLife",
                mediaPath = BotsMediaRemoteImages.M3I1.mediaPath
            ),
            RemoteProfileImage(
                comment = "",
                mediaPath = BotsMediaRemoteImages.M3I2.mediaPath
            )
        ),
        stage = UserStage.REGISTERED,
        verified = true,
        openQuestionAnswer = listOf(
            OpenAnswer(
                answer = "Passionate software developer with a love for coding and problem-solving. Always eager to learn new technologies and take on challenging projects. Let's build something amazing together. 💻 #DeveloperLife",
                questionId = OpenDeepQuestions.INTRODUCTION
            )
        ),
        location = IsraelLocation.ASHDOD.location,
    )
)