package com.osg.matchmaker.ml

import appData.trueAutoResources.MatchingInterest
import com.osg.appData.candidate.MatchIncentive
import com.osg.appData.ml.*
import com.osg.appData.profile.FullProfile
import com.osg.appData.utills.sortByOther
import com.osg.matchmaker.ml.LlmInstructions.PROMOTION_INSTRUCTION
import com.osg.matchmaker.ml.ds.CandidateDecisionData


class MatchOs(
    private val mlHandler: IMlHandler
) {
    suspend fun runMatchOs(
        candidateDecisionDataList: List<CandidateDecisionData>,
        requesterProfile: FullProfile
    ): MatchOsOutput {
        val mlInput = prepareMlInput(candidateDecisionDataList, requesterProfile)
        val mlOutput = mlHandler.mathOsRequest(mlInput).outputs.first()
        return mlOutput
    }

    private fun prepareMlInput(
        candidateReactionPair: List<CandidateDecisionData>,
        requesterProfile: FullProfile
    ): MatchOsInput {
        return MatchOsInput(
            requesterFeatures = getProfileFeatures(requesterProfile),
            candidatesFeatures = candidateReactionPair.map { getProfileFeatures(it.fullProfile) },
        )
    }

    private fun getProfileFeatures(profile: FullProfile): UserFeatures {
        return UserFeatures(
            id = profile.publicProfile.uid,
            stringFeatures = getProfileStringFeatures(profile)
        )
    }

    private fun getProfileStringFeatures(profile: FullProfile): List<String> {
        return profileFeaturesExtractors.map { it.lambda(profile) }
    }

    suspend fun resolveMatchIncentives(
        matchOsOutput: MatchOsOutput,
        requestProfile: FullProfile,
        candidates: List<CandidateDecisionData>
    ): List<MatchIncentive> {
        val features = matchOsOutput.candidateGrades.map {
            resolveMatchingFeatures(it.matchingPointsGrade)
        }

        val llmInputs = features.mapIndexed { index, candidateFeature ->
            buildLlmInput(candidateFeature, requestProfile, candidates[index].fullProfile)
        }

        val adminRequest = LlmAdminRequest(
            instruction = PROMOTION_INSTRUCTION,
            prompts = llmInputs
        )
        val llmResponse = mlHandler.lovingBotRequest(adminRequest)
        return features.mapIndexed { index, candidateFeatures ->
            MatchIncentive(
                matchingInterests = candidateFeatures,
                promotion = llmResponse.results[index]
            )
        }
    }


    private fun resolveMatchingFeatures(matchingPointsGrade: List<Float>): List<MatchingInterest> {
        val matchingInterests = MatchingInterest.entries.sortByOther(matchingPointsGrade)

        return matchingInterests.take(3)
    }
}
