package com.osg.ice.ui

import androidx.compose.animation.*
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.osg.ice.state.IceMatchResult
import com.osg.ice.state.IceUiPlayerProfile
import com.osg.truebase.shapes.RoundedPolygonShape
import com.osg.ui.core.commonStates.asPainter

@Composable
fun MatchResultSingleMode(
    currentResult: IceMatchResult,
    prevResult: IceMatchResult,
    myPlayerProfile: IceUiPlayerProfile,
    opponentProfile: IceUiPlayerProfile,
    modifier: Modifier,
) {
    Row(
        modifier = modifier,
        horizontalArrangement = Arrangement.spacedBy(8.dp, Alignment.CenterHorizontally),
        verticalAlignment = Alignment.Top,
    ) {
        TeamSymbolView(
            modifier = Modifier.weight(1f),
            teamName = myPlayerProfile.name,
            teamAvatar = myPlayerProfile.trueProfileImage.asPainter(),
        )
        ScoreText(
            modifier = Modifier.weight(1f, fill = false).align(Alignment.CenterVertically),
            currentResult = currentResult,
            prevResult = prevResult,
        )
        TeamSymbolView(
            modifier = Modifier.weight(1f),
            teamName = opponentProfile.name,
            teamAvatar = opponentProfile.trueProfileImage.asPainter(),
        )
    }
}

data class ScoreUiState(
    val prevScore: String,
    val nextScore: String = prevScore,
)

@Composable
fun ScoreText(
    modifier: Modifier,
    currentResult: IceMatchResult,
    prevResult: IceMatchResult,
) {
    Row(
        modifier = modifier,
    ) {
        TeamScoreText(
            scoreState = ScoreUiState(
                nextScore = currentResult.myScore.toString(),
                prevScore = prevResult.myScore.toString(),
            ),
            modifier = Modifier,
        )
        Text(
            text = " - ",
            style = MaterialTheme.typography.displaySmall,
        )
        TeamScoreText(
            scoreState = ScoreUiState(
                nextScore = currentResult.opponentScore.toString(),
                prevScore = prevResult.opponentScore.toString(),
            ),
            modifier = Modifier,
        )
    }
}

@Composable
fun TeamScoreText(
    scoreState: ScoreUiState,
    modifier: Modifier,
) {
    var translationState by remember { mutableStateOf(scoreState.prevScore) }
    AnimatedContent(
        modifier = modifier,
        targetState = translationState,
        transitionSpec = {
            (slideInVertically(animationSpec = tween(500)) +
                    fadeIn(animationSpec = tween(500)))
                .togetherWith(
                    (slideOutVertically(animationSpec = tween(500)) +
                            fadeOut(animationSpec = tween(500)))
                )
        },
    ) { targetScore ->
        Text(
            modifier = Modifier,
            text = targetScore,
            style = MaterialTheme.typography.headlineLarge,
        )
    }

    LaunchedEffect(Unit) {
        translationState = scoreState.nextScore
    }
}


@Composable
fun TeamSymbolView(
    modifier: Modifier = Modifier,
    teamAvatar: Painter,
    teamName: String = "",
) {
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {

        Image(
            modifier = Modifier.graphicsLayer {
                this.shadowElevation = 6.dp.toPx()
                this.shape = RoundedPolygonShape(
                    numVertices = 6,
                    rounding = 0.2f,
                )
                this.clip = true
                this.ambientShadowColor = Color.Black
                this.spotShadowColor = Color.Black
            }.background(MaterialTheme.colorScheme.primaryContainer)
                .aspectRatio(1f)
                .weight(1f, fill = false),
            painter = teamAvatar,
            contentDescription = teamName,
        )
        if (teamName.isNotEmpty()) {
            Text(
                text = teamName,
                textAlign = TextAlign.Center,
                style = MaterialTheme.typography.bodyLarge,
            )
        }
    }

}