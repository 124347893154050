package osg.uiZone.search


import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.osg.appData.auth.basicIdentity
import com.osg.appData.candidate.*
import com.osg.appData.common.DataRoot
import com.osg.appData.functions.FunctionResponseType
import com.osg.appData.functions.ITmMatchMakerCloudFunctions
import com.osg.appData.functions.ITmMatchRequestCloudFunctions
import com.osg.def.database.IDownloadMedia
import com.osg.def.database.IPlatformStorage
import com.osg.truebase.data.logger.AppLogger
import com.osg.coroutines.dispechers.ioDispatcher
import com.osg.ui.core.commonStates.TrueImage
import com.osg.utils.pmap
import com.osg.utils.waitForSuccess
import kotlinx.coroutines.*
import kotlinx.coroutines.channels.ClosedReceiveChannelException
import kotlinx.coroutines.channels.ReceiveChannel
import kotlinx.coroutines.channels.produce
import kotlinx.coroutines.flow.*
import org.koin.mp.KoinPlatform.getKoin
import osg.uiZone.common.baseRepos.ICommonMessagingService
import osg.uiZone.common.baseRepos.MessagingService
import osg.uiZone.common.baseRepos.database.ISearchCandidateHandlerDependencies
import osg.uiZone.common.baseRepos.database.ISearchInteractionsDependencies
import osg.uiZone.common.baseRepos.database.ItsMatchFlow
import osg.uiZone.common.baseRepos.profile.IMyFullProfileFlow
import osg.uiZone.common.baseRepos.profile.toLoadingImage
import osg.uiZone.di.FunctionsService
import osg.uiZone.search.ds.CheckMatchState
import osg.uiZone.search.ds.SearchScreenState
import osg.uiZone.search.repo.SearchScope


data class CurrentCandidateData(
    val idx: Int = 0,
    val lastDecision: Decision = Decision.UNKNOWN
)

interface ISearchViewModelDependencies :
    ISearchCandidateHandlerDependencies,
    IMyFullProfileFlow,
    ItsMatchFlow,
    ISearchInteractionsDependencies


class SearchCfDependencies :
    ITmMatchMakerCloudFunctions by FunctionsService(),
    ITmMatchRequestCloudFunctions by FunctionsService()

class SearchViewModel(
    private val searchViewModelDependencies: ISearchViewModelDependencies = SearchScope().searchRepository,
    private val searchCloudFunctions: SearchCfDependencies = SearchCfDependencies(),
    private val messagingService: ICommonMessagingService = MessagingService(),
    private val mediaHandler: IDownloadMedia = getKoin().get<IPlatformStorage>(),
) : ViewModel() {
    private val searchHandler = SearchCandidateHandler(searchViewModelDependencies, searchCloudFunctions)
    private val currentCandidateData = MutableStateFlow(CurrentCandidateData())

    val matchStateFlow: StateFlow<CheckMatchState> = searchViewModelDependencies.getItsMatchFlow()
        .distinctUntilChanged()
        .transform { userPath ->
            emit(CheckMatchState.Match)
        }.stateIn(
            viewModelScope,
            SharingStarted.WhileSubscribed(5000),
            CheckMatchState.NoMatch
        )

    init {
        AppLogger.d(this::class.simpleName!!, "init")
    }

    override fun onCleared() {
        super.onCleared()
        SearchScope.closeSearchScope()
        AppLogger.d(this::class.simpleName!!, "onCleared SearchScope closed")
    }

    @OptIn(ExperimentalCoroutinesApi::class)
    private var candidateProducer: ReceiveChannel<PipeOutput> = viewModelScope.produce(
        context = ioDispatcher()
    ) {
        val uid = searchViewModelDependencies.myProfileStateFlow.waitForSuccess().publicProfile.uid
        searchHandler.getCandidateFlow(
            myUid = uid,
            CANDIDATE_PAGE_SIZE
        ).collect { candidates ->
            send(
                PipeOutput(
                    candidates = candidates.map { it.toUi() },
                    requestStatus = if (candidates.isEmpty()) {
                        RequestStatus.FoundNothing
                    } else {
                        RequestStatus.Loading
                    }
                )
            )
        }
    }

    suspend fun CandidateProfile.toUi(): CandidateProfileUi {
        val images = this.publicProfile.picturesCommentList.pmap {
            mediaHandler.toLoadingImage(it)
        }
        return CandidateProfileUi(
            candidateProfile = this,
            images = images
        )
    }

    @OptIn(DelicateCoroutinesApi::class)
    private val candidatePipeFlowState: StateFlow<PipeOutput> = flow {
        val collectedCandidates = mutableListOf<CandidateProfileUi>()
        currentCandidateData.collect { nextIdx ->
            val remainingCandidates = collectedCandidates.size - nextIdx.idx
            if (remainingCandidates < CANDIDATE_PAGE_SIZE) {
                try {
                    AppLogger.d("requesting more candidates")
                    val pipeOutput = candidateProducer.receive()
                    val newCandidates = pipeOutput.candidates.filter { pipCandidate ->
                        pipCandidate.candidateProfile.uid !in collectedCandidates.map { it.candidateProfile.uid }
                    }

                    val requestStatus = if (newCandidates.isEmpty()) {
                        RequestStatus.FoundNothing
                    } else {
                        pipeOutput.requestStatus
                    }

                    AppLogger.d("received newCandidates: ${newCandidates.size}, requestStatus: $requestStatus")

                    collectedCandidates.addAll(newCandidates)
                    emit(
                        PipeOutput(
                            candidates = collectedCandidates,
                            requestStatus = requestStatus
                        )
                    )
                } catch (_: ClosedReceiveChannelException) {
                    AppLogger.e("candidateProducer is closed ")
                    emit(
                        PipeOutput(
                            candidates = collectedCandidates,
                            requestStatus = RequestStatus.FoundNothing
                        )
                    )
                }
            }
        }
    }.stateIn(
        viewModelScope,
        SharingStarted.WhileSubscribed(5000),
        PipeOutput()
    )

    val searchUiState: StateFlow<SearchScreenState> = combine(
        candidatePipeFlowState,
        currentCandidateData
    ) { candidatePipe, nextCandidateData ->
        AppLogger.d("nextCandidateIdx: ${nextCandidateData.idx} pipeSize: ${candidatePipe.candidates.size}")
        if (candidatePipe.candidates.size > nextCandidateData.idx) {
            SearchScreenState.Search(
                currentCandidate = candidatePipe.candidates[nextCandidateData.idx].candidateProfile,
                lastDecision = nextCandidateData.lastDecision,
                candidateIdx = nextCandidateData.idx,
                profileImages = candidatePipe.candidates[nextCandidateData.idx].images
            )
        } else if (candidatePipe.requestStatus == RequestStatus.FoundNothing) {
            SearchScreenState.NoMoreCandidates
        } else {
            SearchScreenState.Loading
        }
    }.stateIn(
        viewModelScope,
        SharingStarted.WhileSubscribed(5000),
        SearchScreenState.Loading
    )


    fun onNotificationApproved() {
        AppLogger.d(this::class.simpleName!!, "onNotificationApproved")
        viewModelScope.launch {
            val uid = searchViewModelDependencies.myProfileStateFlow.waitForSuccess().publicProfile.uid
            messagingService.updateToken(uid = uid)
        }
    }

    fun onDecision(finalDecision: FinalDecision) {
        AppLogger.d(this::class.simpleName!!, "decision: $finalDecision")
        currentCandidateData.value = CurrentCandidateData(
            idx = finalDecision.candidateIdx + 1,
            lastDecision = finalDecision.decision
        )

        viewModelScope.launch {
            val myPublicProfile = searchViewModelDependencies.myProfileStateFlow.waitForSuccess().publicProfile
            if (finalDecision.decision.isLikeType()) {
                val latestCandidateDecision = fetchLatestCandidateDecision(finalDecision.candidateProfile)
                if (finalDecision.decision.areMatched(latestCandidateDecision)) {
                    val matchRequestData = MatchRequestData(
                        requesterIdentity = myPublicProfile.basicIdentity,
                        matchIdentity = finalDecision.candidateProfile.publicProfile.basicIdentity,
                        requesterDecision = finalDecision.decision,
                        candidateDecision = latestCandidateDecision
                    )

                    val success = searchCloudFunctions.match(
                        matchRequestData = matchRequestData
                    )

                    if (success != FunctionResponseType.SUCCESS) {
                        AppLogger.e(this::class.simpleName!!, "matchRequest failed so reaction is not set")
                        return@launch
                    }
                }
            }

            val reaction = Reaction(
                fromUid = myPublicProfile.uid,
                toUid = finalDecision.candidateProfile.uid,
                decision = finalDecision.decision
            )

            val writePaths = DataRoot.allReactionWritePath(reaction.fromUid, reaction.toUid)
            writePaths.forEach { path ->
                searchViewModelDependencies.setReaction(path, reaction)
            }
        }
    }


    private suspend fun fetchLatestCandidateDecision(
        candidateProfile: CandidateProfile,
    ): Decision {
        val myUid = searchViewModelDependencies.myProfileStateFlow.waitForSuccess().publicProfile.uid
        return when (candidateProfile.decision) {
            Decision.UNKNOWN -> {
                getCandidateDecision(
                    myUid = myUid,
                    uid = candidateProfile.uid
                )
            }

            else -> candidateProfile.decision
        }
    }

    private suspend fun getCandidateDecision(
        myUid: String,
        uid: String
    ): Decision {
        val reactionPath = DataRoot.reactionOnUserPath(
            uid = myUid,
            candidateUid = uid
        )

        return try {
            searchViewModelDependencies.fetchCandidateReaction(reactionPath).decision
        } catch (_: Exception) {
            Decision.UNKNOWN
        }
    }

    data class PipeOutput(
        val candidates: List<CandidateProfileUi> = emptyList(),
        val requestStatus: RequestStatus = RequestStatus.Loading,
    )

    enum class RequestStatus {
        Loading,
        FoundNothing,
    }

    companion object {
        const val CANDIDATE_PAGE_SIZE: Int = 5
    }
}

data class CandidateProfileUi(
    val candidateProfile: CandidateProfile,
    val images: List<TrueImage>,
)