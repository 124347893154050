package com.osg.trumatch.bots.botProfiles

import bots.trueAutoResources.BotsMediaRemoteImages
import com.osg.appData.profile.*
import com.osg.appData.register.OpenDeepQuestions
import com.osg.truebase.data.nodeData.RemoteProfileImage
import com.osg.truebase.data.user.Gender
import com.osg.truebase.preview.data.IsraelLocation


internal val yehudaProfile = FullProfile(
    personalProfile = PersonalProfile(

        facebookId = "10225277936830286",
        lastName = "Levi",
        preferencesMatch = PreferencesMatch(
            ageMax = 40,
            ageMin = 20,
            maxDistance = 50,
            dateGender = Gender.Female
        ),

    ),
    publicProfile = PublicProfile(
        birthDay = "06/29/1979",
        firstName = "Yehuda",
        gender = Gender.Male,
        height = 180,
        occupation = "Actor",
        picturesCommentList = listOf(
            RemoteProfileImage(
                comment = "",
                mediaPath = BotsMediaRemoteImages.M2I0.mediaPath
            ),
            RemoteProfileImage(
                comment = "On set 🎬✨ #ActorLife",
                mediaPath = BotsMediaRemoteImages.M2I1.mediaPath
            ),
            RemoteProfileImage(
                comment = "",
                mediaPath = BotsMediaRemoteImages.M2I2.mediaPath
            )
        ),
        stage = UserStage.REGISTERED,
        verified = true,
        openQuestionAnswer = listOf(
            OpenAnswer(
                answer = "Seasoned actor with a passion for storytelling. Always looking for the next big role and meaningful connections. Let's create some unforgettable moments together. 🎭 #ActorLife #Storyteller",
                questionId = OpenDeepQuestions.INTRODUCTION
            )
        ),
        location = IsraelLocation.ASHDOD.location,
    )
)

