package osg.uiZone.common.paint

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.BlurredEdgeTreatment
import androidx.compose.ui.draw.blur
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.BlendMode
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.CompositingStrategy
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.positionInWindow
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import common.ds.Place

@Composable
fun GlassView(
    imageBitmap: Painter,
    childMeasures: List<Place>,
    childCornerRadius: Int = 30,
    blurX: Dp = 5.dp,
    blurY: Dp = 5.dp,
    strokeSize: Dp = 2.dp,
    blendMode: BlendMode = BlendMode.Src
) {
    val glassViewMeasure = remember { mutableStateOf(Place()) }
    Image(
        painter = imageBitmap,
        contentScale = ContentScale.Crop,
        contentDescription = null,
        modifier = Modifier
            .fillMaxSize()
            .blur(
                radiusX = blurX,
                radiusY = blurY,
                edgeTreatment = BlurredEdgeTreatment.Unbounded
            )
            .onGloballyPositioned {
                glassViewMeasure.value.apply {
                    sizeX = it.size.width
                    sizeY = it.size.height
                    offsetX = it.positionInWindow().x
                    offsetY = it.positionInWindow().y
                }
            }
    )
    Image(painter = imageBitmap,
        contentDescription = null,
        contentScale = ContentScale.Crop,
        modifier = Modifier
            .fillMaxSize()
            .graphicsLayer {
                compositingStrategy = CompositingStrategy.Offscreen
            }
            .drawWithCache {
                onDrawWithContent {
                    this@onDrawWithContent.drawContent()
                    for (i in childMeasures.indices) {
                        drawRoundRect(
                            color = Color.White,
                            topLeft = Offset(
                                childMeasures[i].offsetX - glassViewMeasure.value.offsetX,
                                childMeasures[i].offsetY - glassViewMeasure.value.offsetY
                            ),
                            size = Size(
                                childMeasures[i].sizeX.toFloat(),
                                childMeasures[i].sizeY.toFloat()
                            ),
                            alpha = 0.2f,
                            cornerRadius = CornerRadius(childCornerRadius.dp.toPx()),
                            blendMode = blendMode,
                        )
                        drawRoundRect(
                            color = Color.White,
                            topLeft = Offset(
                                childMeasures[i].offsetX - glassViewMeasure.value.offsetX,
                                childMeasures[i].offsetY - glassViewMeasure.value.offsetY
                            ),
                            size = Size(
                                childMeasures[i].sizeX.toFloat(),
                                childMeasures[i].sizeY.toFloat()
                            ),
                            alpha = 0.4f,
                            cornerRadius = CornerRadius(childCornerRadius.dp.toPx()),
                            style = Stroke(strokeSize.toPx()),
                            blendMode = blendMode
                        )
                    }
                }
            }
    )
}