package osg.uiZone.matchZone.bar

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material.icons.outlined.Clear
import androidx.compose.material.icons.outlined.Person
import androidx.compose.material.icons.outlined.Settings
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import com.osg.truebase.spotlight.tutorialTarget
import com.osg.truebase.ui.button.DropDownAnimatingIcon
import com.osg.truebase.ui.commonStates.MainViewUiState
import com.osg.truebase.ui.ds.IGeneralIconButtonUi
import com.osg.truebase.ui.ds.toItem
import com.osg.truebase.ui.theme.dimensions
import com.osg.ui.core.commonStates.TrueVector
import com.osg.ui.core.commonStates.asTrueVector
import com.osg.ui.core.resources.UiText
import com.osg.ui.core.resources.uiText
import osg.uiZone.common.bar.JourneyBarTitle
import osg.uiZone.common.bar.ProfileImageBar
import osg.uiZone.common.composeUtils.rememberPrevious
import osg.uiZone.common.pyrotechnics.animatedNavBar.colorButtons.ColorButton
import osg.uiZone.matchZone.DstButtonUiState
import osg.uiZone.matchZone.TopBarState
import osg.uiZone.matchZone.state.matchZoneDestinations
import osg.uiZone.matchZone.state.placeSettings
import osg.uiZone.navigation.*
import osg.uiZone.profile.profileItemsState
import osg.uiZone.search.CandidateProfileUi
import osg.uiZone.search.screens.ProfileItemsList
import osg.uiZone.tutorial.TutorialStepCollection
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.profileOption
import truematch.uizone.generated.resources.settings_title
import truematch.uizone.generated.resources.unMatchOption


@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun MatchZoneTopBar(
    topBarState:  MainViewUiState<TopBarState>,
    selectedDestination: MatchZoneDestination,
    onNavigation: (Destination) -> Unit = {},
    onBack: () -> Unit = {},
    modifier: Modifier = Modifier,
) {
    var openDialog by remember { mutableStateOf(false) }
    if (openDialog && topBarState is MainViewUiState.Success) {
        CandidatePreviewDialog(
            candidateProfileUi = topBarState.data.candidateProfileUi,
            onDismiss = { openDialog = false }
        )
    }
    Box(
        modifier = modifier
            .shadow(
                elevation = 12.dp,
            )
    ) {
        val placeSettings = selectedDestination.placeSettings
        TopAppBar(
            modifier = Modifier.tutorialTarget(TutorialStepCollection.SharedJourneyExplanation),
            title = {
                if (topBarState is MainViewUiState.Success) {
                    when (selectedDestination) {
                        MatchZoneChat -> {
                            ProfileImageBar(
                                image = topBarState.data.candidateProfileUi.images.first(),
                                candidateName = topBarState.data.candidateProfileUi.candidateProfile.publicProfile.firstName,
                                onClick = {
                                    openDialog = true
                                },
                                modifier = modifier
                            )
                        }

                        SharedJourney -> {
                            JourneyBarTitle(
                                myImage = topBarState.data.myProfileImage,
                                matchImage = topBarState.data.candidateProfileUi.images.first(),
                                onClick = {
                                    openDialog = true
                                },
                            )
                        }

                        IceBreakerFeed -> {}
                        SparkFeed -> {}
                    }
                }
            },
            navigationIcon = {
                if (placeSettings.showBack) {
                    IconButton(onClick = onBack) {
                        Icon(
                            imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                            contentDescription = "Localized description"
                        )
                    }
                }
            },
            actions = {

                Row(
                    modifier = Modifier,
                    horizontalArrangement = Arrangement.spacedBy(
                        alignment = Alignment.End,
                        space = MaterialTheme.dimensions.actionIconTopBarSpace
                    ),
                ) {
                    var prevSelectedPlace = rememberPrevious(selectedDestination)
                    val prevSelectedIndex = matchZoneDestinations.indexOf(prevSelectedPlace)
                    val selectedIndex = matchZoneDestinations.indexOf(selectedDestination)

                    matchZoneDestinations.forEachIndexed { index, colorAnimationButton ->
                        if (colorAnimationButton !in placeSettings.navigationOptions) {
                            return@forEachIndexed
                        }
                        val buttonDstState = if (topBarState is MainViewUiState.Success){
                            topBarState.data.resolveButtonUiState(colorAnimationButton)
                        }else{
                            DstButtonUiState()
                        }
                        ColorButton(
                            modifier = Modifier.tutorialTarget(colorAnimationButton.mainZonePlaces.tutorialStep),
                            index = index,
                            selectedIndex = selectedIndex,
                            prevSelectedIndex = prevSelectedIndex,
                            onClick = { onNavigation(colorAnimationButton) },
                            animationType = colorAnimationButton.mainZonePlaces.animationType,
                            notificationCount = buttonDstState.notificationCount,
                        )
                    }
                    if (placeSettings.showOptions) {
                        DropDownAnimatingIcon(
                            dropDownOptions = DropDownDestination.entries.map {
                                it.toItem{
                                    onNavigation(
                                        it.destination
                                    )
                                }
                            },
                        )
                    }
                }
            },
        )
    }
}

@Composable
fun CandidatePreviewDialog(
    candidateProfileUi: CandidateProfileUi,
    onDismiss: () -> Unit,
) {
    Dialog(
        onDismissRequest = onDismiss
    ) {
        Card(
            modifier = Modifier
                .fillMaxSize()
        ) {
            ProfileItemsList(
                profileItemsState = candidateProfileUi.candidateProfile.profileItemsState(
                    candidateProfileUi.images
                ),
                onBack = onDismiss
            )
        }
    }
}

enum class DropDownDestination(
    override val trueVector: TrueVector,
    override val uiText: UiText,
    val destination: Destination
): IGeneralIconButtonUi {
    ProfileEdit(
        Icons.Outlined.Person.asTrueVector(),
        Res.string.profileOption.uiText,
        ProfileEditDestination
    ),
    UnMatch(
        Icons.Outlined.Clear.asTrueVector(),
        Res.string.unMatchOption.uiText,
        UnamtchZone
    ),
    Settings(
        Icons.Outlined.Settings.asTrueVector(),
        Res.string.settings_title.uiText,
        SettingsDestination
    );

}
