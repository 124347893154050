package com.osg.ice.ui.comp

import androidx.compose.foundation.*
import androidx.compose.foundation.layout.*
import androidx.compose.material3.FilterChip
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.osg.ice.ui.ds.IEntertainmentDataUi
import com.osg.truebase.ice.generated.resources.Res
import com.osg.truebase.ice.generated.resources.description
import com.osg.truebase.ui.paint.PreGradientBackground
import com.osg.ui.core.resources.string
import com.osg.truebase.ui.theme.spacing
import org.jetbrains.compose.resources.stringResource
import com.osg.truebase.ice.generated.resources.see_less
import com.osg.truebase.ice.generated.resources.see_more
import com.osg.ui.core.resources.UiText


@OptIn(ExperimentalLayoutApi::class)
@Composable
internal fun EntertainmentBody(
    entertainment: IEntertainmentDataUi,
    scroll: ScrollState,
    modifier: Modifier = Modifier,
) {
    Column(
        modifier = modifier
            .verticalScroll(scroll),
        verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small)
    ) {
        Title(
            modifier = Modifier,
            entertainmentName =  entertainment.name,
            slogan = entertainment.slogan,
        )
        TitleDescription(
            titleRes = Res.string.description.string,
            description = entertainment.description.string
        )

        FlowRow(
            modifier = Modifier.padding(horizontal = MaterialTheme.spacing.small),
            horizontalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small),
        ) {
            entertainment.vibes.forEach { vibe ->
                val tagUi = vibe.tagUi
                FilterChip(
                    onClick = {},
                    label = {
                        Text("${tagUi.emoji} ${tagUi.nameResource.string}")
                    },
                    selected = false,
                )
            }
        }
    }
}

enum class SeeMore {
    EXPAND,
    COLLAPSE,
    INITIAL,
}

@Composable
private fun TitleDescription(
    titleRes: String? = null,
    description: String,
    modifier: Modifier = Modifier,
) {
    var seeMore by remember { mutableStateOf(SeeMore.INITIAL) }
    Column(
        modifier = modifier,
        verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small)
    ) {
        val descriptionStyle = if (titleRes != null) {
            MaterialTheme.typography.bodyLarge
        } else {
            MaterialTheme.typography.bodyLarge.copy(
                fontWeight = FontWeight.SemiBold
            )
        }

        if (titleRes != null) {
            Text(
                text = titleRes,
                style = MaterialTheme.typography.labelSmall,
            )
        }
        Text(
            text = description,
            style = descriptionStyle,
            maxLines = if (seeMore != SeeMore.EXPAND) 5 else Int.MAX_VALUE,
            overflow = TextOverflow.Ellipsis,
            onTextLayout = { textLayoutResult ->
                if (textLayoutResult.hasVisualOverflow) {
                    seeMore = SeeMore.COLLAPSE
                }
            },
        )
        if (seeMore != SeeMore.INITIAL) {
            val textButton = when (seeMore) {
                SeeMore.COLLAPSE -> stringResource(Res.string.see_more)
                SeeMore.EXPAND -> stringResource(Res.string.see_less)
                else -> ""
            }

            Text(
                text = textButton,
                style = MaterialTheme.typography.labelLarge,
                color = MaterialTheme.colorScheme.primary,
                textAlign = TextAlign.Center,
                modifier = Modifier
                    .heightIn(20.dp)
                    .fillMaxWidth()
                    .padding(top = 8.dp)
                    .clickable {
                        seeMore = when (seeMore) {
                            SeeMore.COLLAPSE -> SeeMore.EXPAND
                            SeeMore.EXPAND -> SeeMore.COLLAPSE
                            else -> SeeMore.INITIAL
                        }
                    }
            )
        }
    }
}


@Composable
private fun Title(
    entertainmentName: UiText,
    slogan: UiText,
    modifier: Modifier = Modifier,
) {
    Column(
        verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small),
        modifier = modifier
    ) {
        Text(
            text = entertainmentName.string,
            style = MaterialTheme.typography.headlineMedium,
            color = MaterialTheme.colorScheme.onSurface,
        )
        Text(
            text = slogan.string,
            style = MaterialTheme.typography.bodyLarge,
            fontSize = 20.sp,
            color = MaterialTheme.colorScheme.onSurfaceVariant.copy(alpha = 0.7f),
        )
        HorizontalDivider(
            modifier = modifier,
            thickness = 1.dp,
            color = MaterialTheme.colorScheme.onSurface.copy(alpha = 0.12f)
        )
    }
}

@Composable
fun EntertainmentImage(
    modifier: Modifier = Modifier,
    imagePadding: PaddingValues = PaddingValues(0.dp),
    painter: Painter,
) {
    Box(
        modifier = modifier
            .background(
                Brush.horizontalGradient(
                    PreGradientBackground.Game.colors
                )
            ).fillMaxWidth()
    ) {
        Image(
            painter = painter,
            contentDescription = null,
            modifier = Modifier
                .padding(imagePadding)
                .align(Alignment.Center),
            contentScale = ContentScale.Inside,
        )
    }
}