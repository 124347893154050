@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package truematch.uizone.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val billie_r: DrawableResource by 
      lazy { init_billie_r() }

  public val blind_canvas: DrawableResource by 
      lazy { init_blind_canvas() }

  public val celeb: DrawableResource by 
      lazy { init_celeb() }

  public val challenge_accepted: DrawableResource by 
      lazy { init_challenge_accepted() }

  public val chat_nav: DrawableResource by 
      lazy { init_chat_nav() }

  public val check_list: DrawableResource by 
      lazy { init_check_list() }

  public val circle_background: DrawableResource by 
      lazy { init_circle_background() }

  public val drink: DrawableResource by 
      lazy { init_drink() }

  public val edsheeran_r: DrawableResource by 
      lazy { init_edsheeran_r() }

  public val eminem_r: DrawableResource by 
      lazy { init_eminem_r() }

  public val emoji_game: DrawableResource by 
      lazy { init_emoji_game() }

  public val escape: DrawableResource by 
      lazy { init_escape() }

  public val harmony_tunes: DrawableResource by 
      lazy { init_harmony_tunes() }

  public val heart_bold: DrawableResource by 
      lazy { init_heart_bold() }

  public val heart_pattern: DrawableResource by 
      lazy { init_heart_pattern() }

  public val ic_android_black_24dp: DrawableResource by 
      lazy { init_ic_android_black_24dp() }

  public val ic_ios: DrawableResource by 
      lazy { init_ic_ios() }

  public val ice_move: DrawableResource by 
      lazy { init_ice_move() }

  public val ice_skate: DrawableResource by 
      lazy { init_ice_skate() }

  public val image_guess: DrawableResource by 
      lazy { init_image_guess() }

  public val imagindragon_r: DrawableResource by 
      lazy { init_imagindragon_r() }

  public val janga: DrawableResource by 
      lazy { init_janga() }

  public val matcher: DrawableResource by 
      lazy { init_matcher() }

  public val musical_move: DrawableResource by 
      lazy { init_musical_move() }

  public val no_match_yet_img: DrawableResource by 
      lazy { init_no_match_yet_img() }

  public val one_ai: DrawableResource by 
      lazy { init_one_ai() }

  public val puzzel: DrawableResource by 
      lazy { init_puzzel() }

  public val puzzle: DrawableResource by 
      lazy { init_puzzle() }

  public val quadrangle_background: DrawableResource by 
      lazy { init_quadrangle_background() }

  public val rectangle_background: DrawableResource by 
      lazy { init_rectangle_background() }

  public val rose: DrawableResource by 
      lazy { init_rose() }

  public val rose_move: DrawableResource by 
      lazy { init_rose_move() }

  public val sailboat: DrawableResource by 
      lazy { init_sailboat() }

  public val soon: DrawableResource by 
      lazy { init_soon() }

  public val tape: DrawableResource by 
      lazy { init_tape() }

  public val verified: DrawableResource by 
      lazy { init_verified() }

  public val who_wants: DrawableResource by 
      lazy { init_who_wants() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("billie_r", CommonMainDrawable0.billie_r)
  map.put("blind_canvas", CommonMainDrawable0.blind_canvas)
  map.put("celeb", CommonMainDrawable0.celeb)
  map.put("challenge_accepted", CommonMainDrawable0.challenge_accepted)
  map.put("chat_nav", CommonMainDrawable0.chat_nav)
  map.put("check_list", CommonMainDrawable0.check_list)
  map.put("circle_background", CommonMainDrawable0.circle_background)
  map.put("drink", CommonMainDrawable0.drink)
  map.put("edsheeran_r", CommonMainDrawable0.edsheeran_r)
  map.put("eminem_r", CommonMainDrawable0.eminem_r)
  map.put("emoji_game", CommonMainDrawable0.emoji_game)
  map.put("escape", CommonMainDrawable0.escape)
  map.put("harmony_tunes", CommonMainDrawable0.harmony_tunes)
  map.put("heart_bold", CommonMainDrawable0.heart_bold)
  map.put("heart_pattern", CommonMainDrawable0.heart_pattern)
  map.put("ic_android_black_24dp", CommonMainDrawable0.ic_android_black_24dp)
  map.put("ic_ios", CommonMainDrawable0.ic_ios)
  map.put("ice_move", CommonMainDrawable0.ice_move)
  map.put("ice_skate", CommonMainDrawable0.ice_skate)
  map.put("image_guess", CommonMainDrawable0.image_guess)
  map.put("imagindragon_r", CommonMainDrawable0.imagindragon_r)
  map.put("janga", CommonMainDrawable0.janga)
  map.put("matcher", CommonMainDrawable0.matcher)
  map.put("musical_move", CommonMainDrawable0.musical_move)
  map.put("no_match_yet_img", CommonMainDrawable0.no_match_yet_img)
  map.put("one_ai", CommonMainDrawable0.one_ai)
  map.put("puzzel", CommonMainDrawable0.puzzel)
  map.put("puzzle", CommonMainDrawable0.puzzle)
  map.put("quadrangle_background", CommonMainDrawable0.quadrangle_background)
  map.put("rectangle_background", CommonMainDrawable0.rectangle_background)
  map.put("rose", CommonMainDrawable0.rose)
  map.put("rose_move", CommonMainDrawable0.rose_move)
  map.put("sailboat", CommonMainDrawable0.sailboat)
  map.put("soon", CommonMainDrawable0.soon)
  map.put("tape", CommonMainDrawable0.tape)
  map.put("verified", CommonMainDrawable0.verified)
  map.put("who_wants", CommonMainDrawable0.who_wants)
}

internal val Res.drawable.billie_r: DrawableResource
  get() = CommonMainDrawable0.billie_r

private fun init_billie_r(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:billie_r",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/billie_r.jpg", -1, -1),
    )
)

internal val Res.drawable.blind_canvas: DrawableResource
  get() = CommonMainDrawable0.blind_canvas

private fun init_blind_canvas(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:blind_canvas",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/blind_canvas.webp", -1, -1),
    )
)

internal val Res.drawable.celeb: DrawableResource
  get() = CommonMainDrawable0.celeb

private fun init_celeb(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:celeb",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/celeb.webp", -1, -1),
    )
)

internal val Res.drawable.challenge_accepted: DrawableResource
  get() = CommonMainDrawable0.challenge_accepted

private fun init_challenge_accepted(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:challenge_accepted",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/challenge_accepted.webp", -1, -1),
    )
)

internal val Res.drawable.chat_nav: DrawableResource
  get() = CommonMainDrawable0.chat_nav

private fun init_chat_nav(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:chat_nav",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/chat_nav.xml", -1, -1),
    )
)

internal val Res.drawable.check_list: DrawableResource
  get() = CommonMainDrawable0.check_list

private fun init_check_list(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:check_list",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/check_list.webp", -1, -1),
    )
)

internal val Res.drawable.circle_background: DrawableResource
  get() = CommonMainDrawable0.circle_background

private fun init_circle_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:circle_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/circle_background.xml", -1, -1),
    )
)

internal val Res.drawable.drink: DrawableResource
  get() = CommonMainDrawable0.drink

private fun init_drink(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:drink",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/drink.xml", -1, -1),
    )
)

internal val Res.drawable.edsheeran_r: DrawableResource
  get() = CommonMainDrawable0.edsheeran_r

private fun init_edsheeran_r(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:edsheeran_r",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/edsheeran_r.jpeg", -1, -1),
    )
)

internal val Res.drawable.eminem_r: DrawableResource
  get() = CommonMainDrawable0.eminem_r

private fun init_eminem_r(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:eminem_r",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/eminem_r.jpeg", -1, -1),
    )
)

internal val Res.drawable.emoji_game: DrawableResource
  get() = CommonMainDrawable0.emoji_game

private fun init_emoji_game(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:emoji_game",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/emoji_game.webp", -1, -1),
    )
)

internal val Res.drawable.escape: DrawableResource
  get() = CommonMainDrawable0.escape

private fun init_escape(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:escape",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/escape.webp", -1, -1),
    )
)

internal val Res.drawable.harmony_tunes: DrawableResource
  get() = CommonMainDrawable0.harmony_tunes

private fun init_harmony_tunes(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:harmony_tunes",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/harmony_tunes.webp", -1, -1),
    )
)

internal val Res.drawable.heart_bold: DrawableResource
  get() = CommonMainDrawable0.heart_bold

private fun init_heart_bold(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:heart_bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/heart_bold.xml", -1, -1),
    )
)

internal val Res.drawable.heart_pattern: DrawableResource
  get() = CommonMainDrawable0.heart_pattern

private fun init_heart_pattern(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:heart_pattern",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/heart_pattern.png", -1, -1),
    )
)

internal val Res.drawable.ic_android_black_24dp: DrawableResource
  get() = CommonMainDrawable0.ic_android_black_24dp

private fun init_ic_android_black_24dp(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_android_black_24dp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/ic_android_black_24dp.xml", -1, -1),
    )
)

internal val Res.drawable.ic_ios: DrawableResource
  get() = CommonMainDrawable0.ic_ios

private fun init_ic_ios(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_ios",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/ic_ios.xml", -1, -1),
    )
)

internal val Res.drawable.ice_move: DrawableResource
  get() = CommonMainDrawable0.ice_move

private fun init_ice_move(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ice_move",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/ice_move.webp", -1, -1),
    )
)

internal val Res.drawable.ice_skate: DrawableResource
  get() = CommonMainDrawable0.ice_skate

private fun init_ice_skate(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ice_skate",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/ice_skate.xml", -1, -1),
    )
)

internal val Res.drawable.image_guess: DrawableResource
  get() = CommonMainDrawable0.image_guess

private fun init_image_guess(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:image_guess",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/image_guess.webp", -1, -1),
    )
)

internal val Res.drawable.imagindragon_r: DrawableResource
  get() = CommonMainDrawable0.imagindragon_r

private fun init_imagindragon_r(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:imagindragon_r",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/imagindragon_r.jpg", -1, -1),
    )
)

internal val Res.drawable.janga: DrawableResource
  get() = CommonMainDrawable0.janga

private fun init_janga(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:janga",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/janga.webp", -1, -1),
    )
)

internal val Res.drawable.matcher: DrawableResource
  get() = CommonMainDrawable0.matcher

private fun init_matcher(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:matcher",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/matcher.webp", -1, -1),
    )
)

internal val Res.drawable.musical_move: DrawableResource
  get() = CommonMainDrawable0.musical_move

private fun init_musical_move(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:musical_move",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/musical_move.webp", -1, -1),
    )
)

internal val Res.drawable.no_match_yet_img: DrawableResource
  get() = CommonMainDrawable0.no_match_yet_img

private fun init_no_match_yet_img(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:no_match_yet_img",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/no_match_yet_img.webp", -1, -1),
    )
)

internal val Res.drawable.one_ai: DrawableResource
  get() = CommonMainDrawable0.one_ai

private fun init_one_ai(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:one_ai",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/one_ai.webp", -1, -1),
    )
)

internal val Res.drawable.puzzel: DrawableResource
  get() = CommonMainDrawable0.puzzel

private fun init_puzzel(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:puzzel",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/puzzel.xml", -1, -1),
    )
)

internal val Res.drawable.puzzle: DrawableResource
  get() = CommonMainDrawable0.puzzle

private fun init_puzzle(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:puzzle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/puzzle.webp", -1, -1),
    )
)

internal val Res.drawable.quadrangle_background: DrawableResource
  get() = CommonMainDrawable0.quadrangle_background

private fun init_quadrangle_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:quadrangle_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/quadrangle_background.xml", -1, -1),
    )
)

internal val Res.drawable.rectangle_background: DrawableResource
  get() = CommonMainDrawable0.rectangle_background

private fun init_rectangle_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:rectangle_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/rectangle_background.xml", -1, -1),
    )
)

internal val Res.drawable.rose: DrawableResource
  get() = CommonMainDrawable0.rose

private fun init_rose(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:rose",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/rose.xml", -1, -1),
    )
)

internal val Res.drawable.rose_move: DrawableResource
  get() = CommonMainDrawable0.rose_move

private fun init_rose_move(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:rose_move",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/rose_move.webp", -1, -1),
    )
)

internal val Res.drawable.sailboat: DrawableResource
  get() = CommonMainDrawable0.sailboat

private fun init_sailboat(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:sailboat",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/sailboat.xml", -1, -1),
    )
)

internal val Res.drawable.soon: DrawableResource
  get() = CommonMainDrawable0.soon

private fun init_soon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:soon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/soon.xml", -1, -1),
    )
)

internal val Res.drawable.tape: DrawableResource
  get() = CommonMainDrawable0.tape

private fun init_tape(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:tape",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/tape.xml", -1, -1),
    )
)

internal val Res.drawable.verified: DrawableResource
  get() = CommonMainDrawable0.verified

private fun init_verified(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:verified",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/verified.webp", -1, -1),
    )
)

internal val Res.drawable.who_wants: DrawableResource
  get() = CommonMainDrawable0.who_wants

private fun init_who_wants(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:who_wants",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/drawable/who_wants.webp", -1, -1),
    )
)
