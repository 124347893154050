package osg.uiZone.expect.permission

import androidx.compose.runtime.*
import com.osg.truebase.data.logger.AppLogger
import kotlinx.coroutines.launch
import osg.uiZone.common.dialog.PermissionRationalPopup

interface PermissionHandler {
    @Composable
    fun AskPermission(permission: PermissionType, callback: (PermissionStatus) -> Unit)

    @Composable
    fun ResolvePermissionStatus(permission: PermissionType, callback: (PermissionStatus) -> Unit)

    @Composable
    fun Force(permission: PermissionType, callback: (PermissionStatus) -> Unit)
}



@Composable
fun PermissionHandlerScreen(
    permissionType: PermissionUiRationale,
    onPermissionStatus: (PermissionStatus) -> Unit,
    permissionHandlerImpl: PermissionHandler? = null
){
    val permissionHandler = permissionHandlerImpl ?: permissionHandler
    var stage by remember { mutableStateOf(PermissionStage.Check) }
    val coroutineScope = rememberCoroutineScope()
    HandlePermissionStage(
        stage = stage,
        permissionUiRationale = permissionType,
        permissionHandlerImpl = permissionHandler,
        onSeenRationale = {
            coroutineScope.launch {
                stage = PermissionStage.Request
            }
        },
    ){ status ->
        coroutineScope.launch {
            AppLogger.d("PermissionHandlerScreen", "onResponseLambda: $status")
            when(status){
                PermissionStatus.GRANTED -> {
                    onPermissionStatus(status)
                }
                PermissionStatus.DENIED -> {
                    if (permissionType.isMandatory) {
                        stage = PermissionStage.Force
                    }else{
                        onPermissionStatus(status)
                    }
                }
                PermissionStatus.NOT_CHECKED -> {
                    stage = PermissionStage.ShowRationale
                }
            }
        }
    }
}

@Composable
fun HandlePermissionStage(
    stage: PermissionStage,
    permissionUiRationale: PermissionUiRationale,
    permissionHandlerImpl: PermissionHandler,
    onSeenRationale: () -> Unit,
    onPermissionStatus: (PermissionStatus) -> Unit,
){
    val permissionType = permissionUiRationale.permissionType
    AppLogger.d("HandlePermissionStage", "stage: $stage permissionRequest: ${permissionUiRationale.name}")
    when(stage){
        PermissionStage.Check -> {
            permissionHandlerImpl.ResolvePermissionStatus(permissionType, onPermissionStatus)
        }
        PermissionStage.Force -> {
            permissionHandlerImpl.Force(permissionType, onPermissionStatus)
        }
        PermissionStage.Request -> {
            permissionHandlerImpl.AskPermission(permissionType, onPermissionStatus)
        }
        PermissionStage.ShowRationale -> {
            PermissionRationalPopup(
                permissionUiRationale = permissionUiRationale,
                onNotMandatoryDismiss = {
                    onPermissionStatus(PermissionStatus.DENIED)
                },
                onConfirm = {
                    onSeenRationale()
                }
            )
        }

    }
}