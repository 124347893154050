@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.osg.truebase.ice.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val adrenaline: StringResource by 
      lazy { init_adrenaline() }

  public val adventure: StringResource by 
      lazy { init_adventure() }

  public val art: StringResource by 
      lazy { init_art() }

  public val beat_sync_description: StringResource by 
      lazy { init_beat_sync_description() }

  public val beat_sync_details: StringResource by 
      lazy { init_beat_sync_details() }

  public val beat_sync_name: StringResource by 
      lazy { init_beat_sync_name() }

  public val beat_sync_slogan: StringResource by 
      lazy { init_beat_sync_slogan() }

  public val blind_canvas_description: StringResource by 
      lazy { init_blind_canvas_description() }

  public val blind_canvas_details: StringResource by 
      lazy { init_blind_canvas_details() }

  public val blind_canvas_name: StringResource by 
      lazy { init_blind_canvas_name() }

  public val blind_canvas_slogan: StringResource by 
      lazy { init_blind_canvas_slogan() }

  public val challenge_accepted_description: StringResource by 
      lazy { init_challenge_accepted_description() }

  public val challenge_accepted_details: StringResource by 
      lazy { init_challenge_accepted_details() }

  public val challenge_accepted_instruction_for_other: StringResource by 
      lazy { init_challenge_accepted_instruction_for_other() }

  public val challenge_accepted_instruction_for_player: StringResource by 
      lazy { init_challenge_accepted_instruction_for_player() }

  public val challenge_accepted_name: StringResource by 
      lazy { init_challenge_accepted_name() }

  public val challenge_accepted_slogan: StringResource by 
      lazy { init_challenge_accepted_slogan() }

  public val culinary: StringResource by 
      lazy { init_culinary() }

  public val description: StringResource by 
      lazy { init_description() }

  public val fantasy: StringResource by 
      lazy { init_fantasy() }

  public val funny: StringResource by 
      lazy { init_funny() }

  public val game_match_loose_title: StringResource by 
      lazy { init_game_match_loose_title() }

  public val game_match_win_title: StringResource by 
      lazy { init_game_match_win_title() }

  public val game_over_sub_title_loose: StringResource by 
      lazy { init_game_over_sub_title_loose() }

  public val game_over_sub_title_win: StringResource by 
      lazy { init_game_over_sub_title_win() }

  public val game_over_title_loose: StringResource by 
      lazy { init_game_over_title_loose() }

  public val game_over_title_win: StringResource by 
      lazy { init_game_over_title_win() }

  public val harmony_tunes_description: StringResource by 
      lazy { init_harmony_tunes_description() }

  public val harmony_tunes_details: StringResource by 
      lazy { init_harmony_tunes_details() }

  public val harmony_tunes_instruction_for_other: StringResource by 
      lazy { init_harmony_tunes_instruction_for_other() }

  public val harmony_tunes_instruction_for_player: StringResource by 
      lazy { init_harmony_tunes_instruction_for_player() }

  public val harmony_tunes_name: StringResource by 
      lazy { init_harmony_tunes_name() }

  public val harmony_tunes_slogan: StringResource by 
      lazy { init_harmony_tunes_slogan() }

  public val hint: StringResource by 
      lazy { init_hint() }

  public val i_m_ready: StringResource by 
      lazy { init_i_m_ready() }

  public val intimacy: StringResource by 
      lazy { init_intimacy() }

  public val invite_game_button: StringResource by 
      lazy { init_invite_game_button() }

  public val lie_ai_description: StringResource by 
      lazy { init_lie_ai_description() }

  public val lie_ai_details: StringResource by 
      lazy { init_lie_ai_details() }

  public val lie_ai_name: StringResource by 
      lazy { init_lie_ai_name() }

  public val lie_ai_slogan: StringResource by 
      lazy { init_lie_ai_slogan() }

  public val loose_sub_title: StringResource by 
      lazy { init_loose_sub_title() }

  public val memories: StringResource by 
      lazy { init_memories() }

  public val mindfulness: StringResource by 
      lazy { init_mindfulness() }

  public val music: StringResource by 
      lazy { init_music() }

  public val mystery: StringResource by 
      lazy { init_mystery() }

  public val nature: StringResource by 
      lazy { init_nature() }

  public val nostalgia: StringResource by 
      lazy { init_nostalgia() }

  public val number_of_rounds: StringResource by 
      lazy { init_number_of_rounds() }

  public val party: StringResource by 
      lazy { init_party() }

  public val pause: StringResource by 
      lazy { init_pause() }

  public val play: StringResource by 
      lazy { init_play() }

  public val problem_solving: StringResource by 
      lazy { init_problem_solving() }

  public val relax: StringResource by 
      lazy { init_relax() }

  public val repeat: StringResource by 
      lazy { init_repeat() }

  public val romance: StringResource by 
      lazy { init_romance() }

  public val see_less: StringResource by 
      lazy { init_see_less() }

  public val see_more: StringResource by 
      lazy { init_see_more() }

  public val soon: StringResource by 
      lazy { init_soon() }

  public val sport: StringResource by 
      lazy { init_sport() }

  public val trivia: StringResource by 
      lazy { init_trivia() }

  public val turn_of: StringResource by 
      lazy { init_turn_of() }

  public val user_continue: StringResource by 
      lazy { init_user_continue() }

  public val waiting_for_other_player: StringResource by 
      lazy { init_waiting_for_other_player() }

  public val waiting_for_your_invitation_response: StringResource by 
      lazy { init_waiting_for_your_invitation_response() }

  public val waiting_for_your_match: StringResource by 
      lazy { init_waiting_for_your_match() }

  public val win_sub_title: StringResource by 
      lazy { init_win_sub_title() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("adrenaline", CommonMainString0.adrenaline)
  map.put("adventure", CommonMainString0.adventure)
  map.put("art", CommonMainString0.art)
  map.put("beat_sync_description", CommonMainString0.beat_sync_description)
  map.put("beat_sync_details", CommonMainString0.beat_sync_details)
  map.put("beat_sync_name", CommonMainString0.beat_sync_name)
  map.put("beat_sync_slogan", CommonMainString0.beat_sync_slogan)
  map.put("blind_canvas_description", CommonMainString0.blind_canvas_description)
  map.put("blind_canvas_details", CommonMainString0.blind_canvas_details)
  map.put("blind_canvas_name", CommonMainString0.blind_canvas_name)
  map.put("blind_canvas_slogan", CommonMainString0.blind_canvas_slogan)
  map.put("challenge_accepted_description", CommonMainString0.challenge_accepted_description)
  map.put("challenge_accepted_details", CommonMainString0.challenge_accepted_details)
  map.put("challenge_accepted_instruction_for_other",
      CommonMainString0.challenge_accepted_instruction_for_other)
  map.put("challenge_accepted_instruction_for_player",
      CommonMainString0.challenge_accepted_instruction_for_player)
  map.put("challenge_accepted_name", CommonMainString0.challenge_accepted_name)
  map.put("challenge_accepted_slogan", CommonMainString0.challenge_accepted_slogan)
  map.put("culinary", CommonMainString0.culinary)
  map.put("description", CommonMainString0.description)
  map.put("fantasy", CommonMainString0.fantasy)
  map.put("funny", CommonMainString0.funny)
  map.put("game_match_loose_title", CommonMainString0.game_match_loose_title)
  map.put("game_match_win_title", CommonMainString0.game_match_win_title)
  map.put("game_over_sub_title_loose", CommonMainString0.game_over_sub_title_loose)
  map.put("game_over_sub_title_win", CommonMainString0.game_over_sub_title_win)
  map.put("game_over_title_loose", CommonMainString0.game_over_title_loose)
  map.put("game_over_title_win", CommonMainString0.game_over_title_win)
  map.put("harmony_tunes_description", CommonMainString0.harmony_tunes_description)
  map.put("harmony_tunes_details", CommonMainString0.harmony_tunes_details)
  map.put("harmony_tunes_instruction_for_other",
      CommonMainString0.harmony_tunes_instruction_for_other)
  map.put("harmony_tunes_instruction_for_player",
      CommonMainString0.harmony_tunes_instruction_for_player)
  map.put("harmony_tunes_name", CommonMainString0.harmony_tunes_name)
  map.put("harmony_tunes_slogan", CommonMainString0.harmony_tunes_slogan)
  map.put("hint", CommonMainString0.hint)
  map.put("i_m_ready", CommonMainString0.i_m_ready)
  map.put("intimacy", CommonMainString0.intimacy)
  map.put("invite_game_button", CommonMainString0.invite_game_button)
  map.put("lie_ai_description", CommonMainString0.lie_ai_description)
  map.put("lie_ai_details", CommonMainString0.lie_ai_details)
  map.put("lie_ai_name", CommonMainString0.lie_ai_name)
  map.put("lie_ai_slogan", CommonMainString0.lie_ai_slogan)
  map.put("loose_sub_title", CommonMainString0.loose_sub_title)
  map.put("memories", CommonMainString0.memories)
  map.put("mindfulness", CommonMainString0.mindfulness)
  map.put("music", CommonMainString0.music)
  map.put("mystery", CommonMainString0.mystery)
  map.put("nature", CommonMainString0.nature)
  map.put("nostalgia", CommonMainString0.nostalgia)
  map.put("number_of_rounds", CommonMainString0.number_of_rounds)
  map.put("party", CommonMainString0.party)
  map.put("pause", CommonMainString0.pause)
  map.put("play", CommonMainString0.play)
  map.put("problem_solving", CommonMainString0.problem_solving)
  map.put("relax", CommonMainString0.relax)
  map.put("repeat", CommonMainString0.repeat)
  map.put("romance", CommonMainString0.romance)
  map.put("see_less", CommonMainString0.see_less)
  map.put("see_more", CommonMainString0.see_more)
  map.put("soon", CommonMainString0.soon)
  map.put("sport", CommonMainString0.sport)
  map.put("trivia", CommonMainString0.trivia)
  map.put("turn_of", CommonMainString0.turn_of)
  map.put("user_continue", CommonMainString0.user_continue)
  map.put("waiting_for_other_player", CommonMainString0.waiting_for_other_player)
  map.put("waiting_for_your_invitation_response",
      CommonMainString0.waiting_for_your_invitation_response)
  map.put("waiting_for_your_match", CommonMainString0.waiting_for_your_match)
  map.put("win_sub_title", CommonMainString0.win_sub_title)
}

internal val Res.string.adrenaline: StringResource
  get() = CommonMainString0.adrenaline

private fun init_adrenaline(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:adrenaline", "adrenaline",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    10, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 10,
    34),
    )
)

internal val Res.string.adventure: StringResource
  get() = CommonMainString0.adventure

private fun init_adventure(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:adventure", "adventure",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    49, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 45,
    29),
    )
)

internal val Res.string.art: StringResource
  get() = CommonMainString0.art

private fun init_art(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:art", "art",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    83, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 75,
    15),
    )
)

internal val Res.string.beat_sync_description: StringResource
  get() = CommonMainString0.beat_sync_description

private fun init_beat_sync_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:beat_sync_description", "beat_sync_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/beat_sync.commonMain.cvr",
    10, 409),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/beat_sync.commonMain.cvr", 10,
    269),
    )
)

internal val Res.string.beat_sync_details: StringResource
  get() = CommonMainString0.beat_sync_details

private fun init_beat_sync_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:beat_sync_details", "beat_sync_details",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/beat_sync.commonMain.cvr",
    420, 193),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/beat_sync.commonMain.cvr",
    280, 165),
    )
)

internal val Res.string.beat_sync_name: StringResource
  get() = CommonMainString0.beat_sync_name

private fun init_beat_sync_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:beat_sync_name", "beat_sync_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/beat_sync.commonMain.cvr",
    614, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/beat_sync.commonMain.cvr",
    446, 34),
    )
)

internal val Res.string.beat_sync_slogan: StringResource
  get() = CommonMainString0.beat_sync_slogan

private fun init_beat_sync_slogan(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:beat_sync_slogan", "beat_sync_slogan",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/beat_sync.commonMain.cvr",
    657, 92),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/beat_sync.commonMain.cvr",
    481, 64),
    )
)

internal val Res.string.blind_canvas_description: StringResource
  get() = CommonMainString0.blind_canvas_description

private fun init_blind_canvas_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:blind_canvas_description", "blind_canvas_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/blind_canvas.commonMain.cvr",
    10, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/blind_canvas.commonMain.cvr",
    10, 68),
    )
)

internal val Res.string.blind_canvas_details: StringResource
  get() = CommonMainString0.blind_canvas_details

private fun init_blind_canvas_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:blind_canvas_details", "blind_canvas_details",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/blind_canvas.commonMain.cvr",
    99, 156),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/blind_canvas.commonMain.cvr",
    79, 148),
    )
)

internal val Res.string.blind_canvas_name: StringResource
  get() = CommonMainString0.blind_canvas_name

private fun init_blind_canvas_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:blind_canvas_name", "blind_canvas_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/blind_canvas.commonMain.cvr",
    256, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/blind_canvas.commonMain.cvr",
    228, 41),
    )
)

internal val Res.string.blind_canvas_slogan: StringResource
  get() = CommonMainString0.blind_canvas_slogan

private fun init_blind_canvas_slogan(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:blind_canvas_slogan", "blind_canvas_slogan",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/blind_canvas.commonMain.cvr",
    310, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/blind_canvas.commonMain.cvr",
    270, 59),
    )
)

internal val Res.string.challenge_accepted_description: StringResource
  get() = CommonMainString0.challenge_accepted_description

private fun init_challenge_accepted_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:challenge_accepted_description", "challenge_accepted_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/challenge_accepted.commonMain.cvr",
    10, 254),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/challenge_accepted.commonMain.cvr",
    10, 194),
    )
)

internal val Res.string.challenge_accepted_details: StringResource
  get() = CommonMainString0.challenge_accepted_details

private fun init_challenge_accepted_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:challenge_accepted_details", "challenge_accepted_details",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/challenge_accepted.commonMain.cvr",
    265, 510),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/challenge_accepted.commonMain.cvr",
    205, 362),
    )
)

internal val Res.string.challenge_accepted_instruction_for_other: StringResource
  get() = CommonMainString0.challenge_accepted_instruction_for_other

private fun init_challenge_accepted_instruction_for_other(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:challenge_accepted_instruction_for_other", "challenge_accepted_instruction_for_other",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/challenge_accepted.commonMain.cvr",
    776, 104),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/challenge_accepted.commonMain.cvr",
    568, 88),
    )
)

internal val Res.string.challenge_accepted_instruction_for_player: StringResource
  get() = CommonMainString0.challenge_accepted_instruction_for_player

private fun init_challenge_accepted_instruction_for_player(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:challenge_accepted_instruction_for_player", "challenge_accepted_instruction_for_player",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/challenge_accepted.commonMain.cvr",
    881, 85),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/challenge_accepted.commonMain.cvr",
    657, 89),
    )
)

internal val Res.string.challenge_accepted_name: StringResource
  get() = CommonMainString0.challenge_accepted_name

private fun init_challenge_accepted_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:challenge_accepted_name", "challenge_accepted_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/challenge_accepted.commonMain.cvr",
    967, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/challenge_accepted.commonMain.cvr",
    747, 55),
    )
)

internal val Res.string.challenge_accepted_slogan: StringResource
  get() = CommonMainString0.challenge_accepted_slogan

private fun init_challenge_accepted_slogan(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:challenge_accepted_slogan", "challenge_accepted_slogan",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/challenge_accepted.commonMain.cvr",
    1027, 77),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/challenge_accepted.commonMain.cvr",
    803, 65),
    )
)

internal val Res.string.culinary: StringResource
  get() = CommonMainString0.culinary

private fun init_culinary(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:culinary", "culinary",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    111, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 91,
    28),
    )
)

internal val Res.string.description: StringResource
  get() = CommonMainString0.description

private fun init_description(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:description", "description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ), "composeResources/com.osg.truebase.ice.generated.resources/values-he/common.commonMain.cvr",
    10, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/common.commonMain.cvr", 10,
    35),
    )
)

internal val Res.string.fantasy: StringResource
  get() = CommonMainString0.fantasy

private fun init_fantasy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fantasy", "fantasy",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    152, 31),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 120,
    27),
    )
)

internal val Res.string.funny: StringResource
  get() = CommonMainString0.funny

private fun init_funny(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:funny", "funny",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    184, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 148,
    21),
    )
)

internal val Res.string.game_match_loose_title: StringResource
  get() = CommonMainString0.game_match_loose_title

private fun init_game_match_loose_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_match_loose_title", "game_match_loose_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_breakers.commonMain.cvr",
    10, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_breakers.commonMain.cvr",
    10, 66),
    )
)

internal val Res.string.game_match_win_title: StringResource
  get() = CommonMainString0.game_match_win_title

private fun init_game_match_win_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_match_win_title", "game_match_win_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_breakers.commonMain.cvr",
    77, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_breakers.commonMain.cvr",
    77, 64),
    )
)

internal val Res.string.game_over_sub_title_loose: StringResource
  get() = CommonMainString0.game_over_sub_title_loose

private fun init_game_over_sub_title_loose(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_over_sub_title_loose", "game_over_sub_title_loose",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_breakers.commonMain.cvr",
    142, 117),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_breakers.commonMain.cvr",
    142, 117),
    )
)

internal val Res.string.game_over_sub_title_win: StringResource
  get() = CommonMainString0.game_over_sub_title_win

private fun init_game_over_sub_title_win(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_over_sub_title_win", "game_over_sub_title_win",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_breakers.commonMain.cvr",
    260, 71),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_breakers.commonMain.cvr",
    260, 71),
    )
)

internal val Res.string.game_over_title_loose: StringResource
  get() = CommonMainString0.game_over_title_loose

private fun init_game_over_title_loose(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_over_title_loose", "game_over_title_loose",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_breakers.commonMain.cvr",
    332, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_breakers.commonMain.cvr",
    332, 57),
    )
)

internal val Res.string.game_over_title_win: StringResource
  get() = CommonMainString0.game_over_title_win

private fun init_game_over_title_win(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_over_title_win", "game_over_title_win",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_breakers.commonMain.cvr",
    390, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_breakers.commonMain.cvr",
    390, 67),
    )
)

internal val Res.string.harmony_tunes_description: StringResource
  get() = CommonMainString0.harmony_tunes_description

private fun init_harmony_tunes_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:harmony_tunes_description", "harmony_tunes_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/harmony_tunes.commonMain.cvr",
    10, 197),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/harmony_tunes.commonMain.cvr",
    10, 165),
    )
)

internal val Res.string.harmony_tunes_details: StringResource
  get() = CommonMainString0.harmony_tunes_details

private fun init_harmony_tunes_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:harmony_tunes_details", "harmony_tunes_details",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/harmony_tunes.commonMain.cvr",
    208, 605),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/harmony_tunes.commonMain.cvr",
    176, 433),
    )
)

internal val Res.string.harmony_tunes_instruction_for_other: StringResource
  get() = CommonMainString0.harmony_tunes_instruction_for_other

private fun init_harmony_tunes_instruction_for_other(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:harmony_tunes_instruction_for_other", "harmony_tunes_instruction_for_other",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/harmony_tunes.commonMain.cvr",
    814, 107),
    )
)

internal val Res.string.harmony_tunes_instruction_for_player: StringResource
  get() = CommonMainString0.harmony_tunes_instruction_for_player

private fun init_harmony_tunes_instruction_for_player(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:harmony_tunes_instruction_for_player", "harmony_tunes_instruction_for_player",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/harmony_tunes.commonMain.cvr",
    922, 108),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/harmony_tunes.commonMain.cvr",
    610, 92),
    )
)

internal val Res.string.harmony_tunes_name: StringResource
  get() = CommonMainString0.harmony_tunes_name

private fun init_harmony_tunes_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:harmony_tunes_name", "harmony_tunes_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/harmony_tunes.commonMain.cvr",
    1031, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/harmony_tunes.commonMain.cvr",
    703, 46),
    )
)

internal val Res.string.harmony_tunes_slogan: StringResource
  get() = CommonMainString0.harmony_tunes_slogan

private fun init_harmony_tunes_slogan(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:harmony_tunes_slogan", "harmony_tunes_slogan",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/harmony_tunes.commonMain.cvr",
    1094, 76),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/harmony_tunes.commonMain.cvr",
    750, 56),
    )
)

internal val Res.string.hint: StringResource
  get() = CommonMainString0.hint

private fun init_hint(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:hint", "hint",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/harmony_tunes.commonMain.cvr",
    807, 20),
    )
)

internal val Res.string.i_m_ready: StringResource
  get() = CommonMainString0.i_m_ready

private fun init_i_m_ready(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:i_m_ready", "i_m_ready",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ), "composeResources/com.osg.truebase.ice.generated.resources/values-he/common.commonMain.cvr",
    46, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/common.commonMain.cvr", 46,
    25),
    )
)

internal val Res.string.intimacy: StringResource
  get() = CommonMainString0.intimacy

private fun init_intimacy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:intimacy", "intimacy",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    214, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 170,
    28),
    )
)

internal val Res.string.invite_game_button: StringResource
  get() = CommonMainString0.invite_game_button

private fun init_invite_game_button(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:invite_game_button", "invite_game_button",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_breakers.commonMain.cvr",
    458, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_breakers.commonMain.cvr",
    458, 34),
    )
)

internal val Res.string.lie_ai_description: StringResource
  get() = CommonMainString0.lie_ai_description

private fun init_lie_ai_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:lie_ai_description", "lie_ai_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ), "composeResources/com.osg.truebase.ice.generated.resources/values-he/lie_ai.commonMain.cvr",
    10, 174),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/lie_ai.commonMain.cvr", 10,
    114),
    )
)

internal val Res.string.lie_ai_details: StringResource
  get() = CommonMainString0.lie_ai_details

private fun init_lie_ai_details(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:lie_ai_details", "lie_ai_details",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ), "composeResources/com.osg.truebase.ice.generated.resources/values-he/lie_ai.commonMain.cvr",
    185, 514),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/lie_ai.commonMain.cvr", 125,
    370),
    )
)

internal val Res.string.lie_ai_name: StringResource
  get() = CommonMainString0.lie_ai_name

private fun init_lie_ai_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:lie_ai_name", "lie_ai_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ), "composeResources/com.osg.truebase.ice.generated.resources/values-he/lie_ai.commonMain.cvr",
    700, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/lie_ai.commonMain.cvr", 496,
    39),
    )
)

internal val Res.string.lie_ai_slogan: StringResource
  get() = CommonMainString0.lie_ai_slogan

private fun init_lie_ai_slogan(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:lie_ai_slogan", "lie_ai_slogan",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ), "composeResources/com.osg.truebase.ice.generated.resources/values-he/lie_ai.commonMain.cvr",
    748, 77),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/lie_ai.commonMain.cvr", 536,
    61),
    )
)

internal val Res.string.loose_sub_title: StringResource
  get() = CommonMainString0.loose_sub_title

private fun init_loose_sub_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:loose_sub_title", "loose_sub_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_breakers.commonMain.cvr",
    493, 127),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_breakers.commonMain.cvr",
    493, 127),
    )
)

internal val Res.string.memories: StringResource
  get() = CommonMainString0.memories

private fun init_memories(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:memories", "memories",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    255, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 199,
    28),
    )
)

internal val Res.string.mindfulness: StringResource
  get() = CommonMainString0.mindfulness

private fun init_mindfulness(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:mindfulness", "mindfulness",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    292, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 228,
    35),
    )
)

internal val Res.string.music: StringResource
  get() = CommonMainString0.music

private fun init_music(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:music", "music",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    328, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 264,
    21),
    )
)

internal val Res.string.mystery: StringResource
  get() = CommonMainString0.mystery

private fun init_mystery(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:mystery", "mystery",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    358, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 286,
    27),
    )
)

internal val Res.string.nature: StringResource
  get() = CommonMainString0.nature

private fun init_nature(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:nature", "nature",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    394, 22),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 314,
    22),
    )
)

internal val Res.string.nostalgia: StringResource
  get() = CommonMainString0.nostalgia

private fun init_nostalgia(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:nostalgia", "nostalgia",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    417, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 337,
    29),
    )
)

internal val Res.string.number_of_rounds: StringResource
  get() = CommonMainString0.number_of_rounds

private fun init_number_of_rounds(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:number_of_rounds", "number_of_rounds",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ), "composeResources/com.osg.truebase.ice.generated.resources/values-he/common.commonMain.cvr",
    76, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/common.commonMain.cvr", 72,
    48),
    )
)

internal val Res.string.party: StringResource
  get() = CommonMainString0.party

private fun init_party(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:party", "party",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    459, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 367,
    21),
    )
)

internal val Res.string.pause: StringResource
  get() = CommonMainString0.pause

private fun init_pause(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:pause", "pause",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/player.commonMain.cvr", 10,
    21),
    )
)

internal val Res.string.play: StringResource
  get() = CommonMainString0.play

private fun init_play(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:play", "play",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/player.commonMain.cvr", 32,
    20),
    )
)

internal val Res.string.problem_solving: StringResource
  get() = CommonMainString0.problem_solving

private fun init_problem_solving(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:problem_solving", "problem_solving",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    489, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 389,
    43),
    )
)

internal val Res.string.relax: StringResource
  get() = CommonMainString0.relax

private fun init_relax(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:relax", "relax",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    541, 25),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 433,
    21),
    )
)

internal val Res.string.repeat: StringResource
  get() = CommonMainString0.repeat

private fun init_repeat(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:repeat", "repeat",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/player.commonMain.cvr", 53,
    22),
    )
)

internal val Res.string.romance: StringResource
  get() = CommonMainString0.romance

private fun init_romance(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:romance", "romance",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    567, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 455,
    27),
    )
)

internal val Res.string.see_less: StringResource
  get() = CommonMainString0.see_less

private fun init_see_less(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:see_less", "see_less",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ), "composeResources/com.osg.truebase.ice.generated.resources/values-he/common.commonMain.cvr",
    133, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/common.commonMain.cvr", 121,
    28),
    )
)

internal val Res.string.see_more: StringResource
  get() = CommonMainString0.see_more

private fun init_see_more(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:see_more", "see_more",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ), "composeResources/com.osg.truebase.ice.generated.resources/values-he/common.commonMain.cvr",
    170, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/common.commonMain.cvr", 150,
    28),
    )
)

internal val Res.string.soon: StringResource
  get() = CommonMainString0.soon

private fun init_soon(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:soon", "soon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/common.commonMain.cvr", 179,
    20),
    )
)

internal val Res.string.sport: StringResource
  get() = CommonMainString0.sport

private fun init_sport(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sport", "sport",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    607, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 483,
    21),
    )
)

internal val Res.string.trivia: StringResource
  get() = CommonMainString0.trivia

private fun init_trivia(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:trivia", "trivia",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_tags.commonMain.cvr",
    637, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_tags.commonMain.cvr", 505,
    22),
    )
)

internal val Res.string.turn_of: StringResource
  get() = CommonMainString0.turn_of

private fun init_turn_of(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:turn_of", "turn_of",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ), "composeResources/com.osg.truebase.ice.generated.resources/values-he/common.commonMain.cvr",
    207, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/common.commonMain.cvr", 200,
    27),
    )
)

internal val Res.string.user_continue: StringResource
  get() = CommonMainString0.user_continue

private fun init_user_continue(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:user_continue", "user_continue",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ), "composeResources/com.osg.truebase.ice.generated.resources/values-he/common.commonMain.cvr",
    247, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/common.commonMain.cvr", 228,
    33),
    )
)

internal val Res.string.waiting_for_other_player: StringResource
  get() = CommonMainString0.waiting_for_other_player

private fun init_waiting_for_other_player(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:waiting_for_other_player", "waiting_for_other_player",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_breakers.commonMain.cvr",
    621, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_breakers.commonMain.cvr",
    621, 80),
    )
)

internal val Res.string.waiting_for_your_invitation_response: StringResource
  get() = CommonMainString0.waiting_for_your_invitation_response

private fun init_waiting_for_your_invitation_response(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:waiting_for_your_invitation_response", "waiting_for_your_invitation_response",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_breakers.commonMain.cvr",
    702, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_breakers.commonMain.cvr",
    702, 64),
    )
)

internal val Res.string.waiting_for_your_match: StringResource
  get() = CommonMainString0.waiting_for_your_match

private fun init_waiting_for_your_match(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:waiting_for_your_match", "waiting_for_your_match",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_breakers.commonMain.cvr",
    767, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_breakers.commonMain.cvr",
    767, 62),
    )
)

internal val Res.string.win_sub_title: StringResource
  get() = CommonMainString0.win_sub_title

private fun init_win_sub_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:win_sub_title", "win_sub_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ice.generated.resources/values-he/ice_breakers.commonMain.cvr",
    830, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/values/ice_breakers.commonMain.cvr",
    830, 61),
    )
)
