package com.osg.truebase.ui.graphics.konfetti.emitter

import kotlin.time.Duration
import kotlin.time.DurationUnit
import kotlin.time.toDuration


/**
 * Emitter class that holds the duration that the emitter will create confetti particles
 */
data class EmitterConfig(
    val duration: Duration,
    val totalParticles: Int,
    val timeRegulation: (Long) -> Float = { 1f }
) {

    val emittingTimeMs = duration.inWholeMilliseconds
    private val initialAmountPerSec: Float = totalParticles.toFloat() / emittingTimeMs.toFloat() * 1000f

    fun getDynamicPerSecAmount(
        frameTime: Long,
    ): Float{
        return initialAmountPerSec * timeRegulation(frameTime)
    }

    companion object {
        fun perSecondParticlesEmitter(
            amount: Int,
            timeRegulation: (Long) -> Float = { 1f }
        ): EmitterConfig {
            val longDuration = 5.toDuration(DurationUnit.MINUTES)
            val amountPerSec = longDuration.inWholeSeconds * amount
            return EmitterConfig(longDuration, amountPerSec.toInt(), timeRegulation)
        }
    }
}
