package com.osg.matchmaker.ml.fake

import com.osg.appData.ml.BatchMatchOsOutput
import com.osg.appData.ml.CandidateMlGrade
import com.osg.appData.ml.MatchOsInput
import com.osg.appData.ml.MatchOsOutput
import com.osg.truebase.data.iceDef.IceTag
import kotlin.random.Random

fun getFakeMatchOsOutput(matchOsInput: MatchOsInput): BatchMatchOsOutput{
    return BatchMatchOsOutput(
        outputs = matchOsInput.candidatesFeatures.map {
            MatchOsOutput(
                requesterUid = matchOsInput.requesterFeatures.id,
                candidateGrades = matchOsInput.candidatesFeatures.map {
                    CandidateMlGrade(
                        uid = it.id,
                        embeddingGrade = Random.nextFloat(),
                        matchingPointsGrade = IceTag.entries.map {
                            Random.nextFloat()
                        }
                    )
                }
            )
        }
    )
}