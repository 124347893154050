package com.osg.truebase.ui.graphics.konfetti.models

import androidx.compose.runtime.Immutable
import androidx.compose.ui.geometry.Rect
import androidx.compose.ui.graphics.ImageBitmap

sealed interface Shape {
    data object Circle : Shape {
        val rect = Rect
    }
    data object Square : Shape
    data class Rectangle(
        /** The ratio of height to width. Must be within range [0, 1] */
        val heightRatio: Float
    ) : Shape {
        init {
            require(heightRatio in 0f..1f)
        }
    }

    /**
     * A drawable shape
     * @param drawable drawable
     * @param tint Set to `false` to opt out of tinting the drawable, keeping its original colors.
     * @param applyAlpha Set too false to not apply alpha to drawables
     */
    @Immutable
    data class DrawableShape(
        val drawable: ImageBitmap,
        val tint: Boolean = true,
        val applyAlpha: Boolean = true,
    ) : Shape
}
