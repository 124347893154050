package com.osg.truebase.ui.assigners.wrapper

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.annotatedText.asAnnotatedString
import com.osg.ui.core.resources.UiText
import com.osg.truebase.ui.theme.spacing

/**
 * A scrollable container with the question's title, direction, and dynamic content.
 *
 * @param title String resource to use for the question's title
 * @param modifier Modifier to apply to the entire wrapper
 * @param directions String resource to use for the question's directions; the direction
 * UI will be omitted if null is passed
 * @param content Composable to display after the title and option directions
 */
@Composable
fun QuestionWrapper(
    title: UiText,
    modifier: Modifier = Modifier,
    directions: UiText = UiText.Empty,
    animationCompose: (@Composable ColumnScope.() -> Unit) = { },
    content: @Composable ColumnScope.() -> Unit,
) {
    Column(
        modifier = modifier.padding(horizontal = 16.dp),
        verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.medium),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        animationCompose()
        if (title !is UiText.Empty) {
            QuestionTitle(title)
        }
        if (directions !is UiText.Empty) {
            QuestionDirections(directions)
        }
        content()
    }
}

@Composable
fun QuestionTitle(
    title: UiText,
    modifier: Modifier = Modifier,
) {
    Text(
        text = title.asAnnotatedString(),
        style = MaterialTheme.typography.titleMedium,
        color = MaterialTheme.colorScheme.onSurface,
        textAlign = TextAlign.Center,
        modifier = modifier
            .fillMaxWidth()
            .padding(horizontal = 16.dp)
    )
}


@Composable
fun QuestionDirections(
    directionsResourceId: UiText,
    modifier: Modifier = Modifier,
) {
    Text(
        text = directionsResourceId.asAnnotatedString(
            MaterialTheme.typography.bodySmall.toSpanStyle().copy(
                color = MaterialTheme.colorScheme.onSurface
                    .copy(alpha = 0.6f)
            )
        ),
        textAlign = TextAlign.Center,
        modifier = modifier
            .fillMaxWidth()
            .padding(horizontal = 8.dp)
    )
}