package com.osg.truebase.data.iceDef

import com.osg.truebase.data.iceDef.ht.MusicGenre
import kotlinx.serialization.Serializable

@Serializable
sealed interface IIceBreakerInvitation {
    val gameRootPath: String
    val inviterUid: String

    val settingsPath: String
        get() = IceDirStruct.iceSettingsPath(gameRootPath)
    val eventsPath: String
        get() = IceDirStruct.iceEventPath(gameRootPath)
}



interface IChallengeAcceptedInvitation: IIceBreakerInvitation{
    val rounds: Int
}

interface IHarmonyTuneInvitation: IIceBreakerInvitation{
    val rounds: Int
    val genres: List<MusicGenre>
}