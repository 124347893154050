package osg.uiZone.common.baseRepos.handlers

import com.osg.appData.matchZone.Message
import com.osg.appData.profile.Presence
import com.osg.def.database.DatabaseService
import com.osg.def.database.KmpDatabaseException
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.nodeData.QueryType
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import osg.uiZone.common.baseRepos.database.IChatDatabaseDependencies
import osg.uiZone.common.baseRepos.database.IPresenceFlow

interface IChatHandlerDependencies: IChatDatabaseDependencies, IPresenceFlow

val DatabaseService.chatRepo: IChatHandlerDependencies
    get() = object : IChatHandlerDependencies {
        override fun getChatFlow(
            path: String,
            timeStampField: String,
            fromTime: Long
        ): Flow<Message> {
            return getChildFlow<Message>(
                path = path,
                query = QueryType.StartAt(timeStampField, fromTime.toDouble())
            ).map { it.data }
        }

        override suspend fun fetchChatHistory(path: String): List<Message> {
            return try {
                fetchObject<Map<String, Message>>(path).values.toList()
            } catch (_: KmpDatabaseException.ValueNotFound) {
                AppLogger.d("ChatRepository", "No chat history found")
                emptyList()
            }
        }


        override suspend fun sendMessage(
            chatPath: String,
            message: Message
        ): Message {
            push(chatPath, message)
            return message
        }

        override fun getPresenceFlow(path: String): Flow<Presence> {
            return getChildFlow<Presence>(path).map { it.data }
        }
    }