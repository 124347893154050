package osg.uiZone.matchZone.state

import androidx.compose.runtime.Immutable
import com.osg.appData.matchZone.MatchInfo
import com.osg.ice.ui.ds.IEntertainmentCollection
import com.osg.ui.core.resources.UiText
import osg.uiZone.matchZone.state.tmEntUi.EntertainmentDetailsUiType
import osg.uiZone.matchZone.state.tmEntUi.TmEntertainmentUiType
import osg.uiZone.matchZone.state.tmEntUi.toEntDetails

data class TmEntertainmentCollection(
    override val entertainmentDataUiList: List<TmEntertainmentUiType>,
    override val name: UiText
) : IEntertainmentCollection

@Immutable
class EntFeedData(
    val matchInfo: MatchInfo,
    val entertainmentCollections: List<TmEntertainmentCollection>,
){
    fun findEntertainment(eId: String): TmEntertainmentUiType{
        return entertainmentCollections.flatMap { it.entertainmentDataUiList }.first { it.id == eId }
    }

    fun toEntDetails(eId: String): EntertainmentDetailsUiType{
        return findEntertainment(eId).toEntDetails()
    }
}