package com.osg.appData.common

import com.osg.appData.candidate.Decision
import com.osg.appData.candidate.Reaction
import com.osg.appData.matchZone.Message
import com.osg.truebase.data.logger.AppLogger
import kotlinx.serialization.Serializable

@Serializable
data class UsersInteractRecord(
    var seenCandidates: Map<String, Reaction> = emptyMap(),
    val onUserReaction: Map<String, Reaction> = emptyMap(),
    val unMatchAiConv: Map<String, Map<String, Message>> = emptyMap(),

    )

fun <T> Iterable<T>.filterOnRecord(
    usersInteractRecord: UsersInteractRecord,
    uid: (T) -> String
): List<T> {
    val matchedUids = usersInteractRecord.unMatchAiConv.keys
    val seenCandidates = usersInteractRecord.seenCandidates.filter {
        if (it.value.decision != Decision.UNKNOWN) {
            AppLogger.e("Candidate ${it.key} is not seen yet but in seenCandidates")
            true
        } else {
            false
        }
    }.keys

    val dislikedUser = usersInteractRecord.onUserReaction.filter {
        it.value.decision == Decision.DISLIKE
    }.keys

    val notRelevantCandidates = matchedUids + seenCandidates + dislikedUser
    return this.filter {
        uid(it) !in notRelevantCandidates
    }
}
