package osg.uiZone.matchZone.chat

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Send
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.osg.ui.core.resources.string
import com.osg.truebase.ui.theme.spacing
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.textfield_hint

@Composable
fun BasicUserInputChatBox(
    onSendChatClickListener: (String) -> Unit,
    modifier: Modifier
) {
    var textFieldValue by rememberSaveable(stateSaver = TextFieldValue.Companion.Saver) {
        mutableStateOf(TextFieldValue())
    }
    Surface(
        modifier = Modifier,
        tonalElevation = 2.dp,
        contentColor = MaterialTheme.colorScheme.secondary
    ){
        Row(
            verticalAlignment = Alignment.Companion.CenterVertically,
            modifier = modifier
                .padding(
                    horizontal = MaterialTheme.spacing.small,
                    vertical = MaterialTheme.spacing.small
                ),
            horizontalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.extraSmall)
        ) {
            BasicChatTextField(
                modifier = Modifier.padding(start = MaterialTheme.spacing.small),
                textFieldValue = textFieldValue,
                onValueChange = { textFieldValue = it }
            )
            ChatBoxSendButton(
                modifier = Modifier,
                enabled = textFieldValue.text.isNotEmpty(),
                onClick = {
                    onSendChatClickListener(textFieldValue.text)
                    textFieldValue = TextFieldValue()
                }
            )
        }
    }
}



@Composable
fun ChatBoxSendButton(
    modifier: Modifier,
    enabled: Boolean = true,
    onClick: () -> Unit,
){
    IconButton(
        onClick = onClick,
        modifier = modifier,
        enabled = enabled,
    ) {
        Icon(
            imageVector = Icons.AutoMirrored.Default.Send,
            modifier = Modifier,
            contentDescription = "Send Button"
        )
    }
}

@Composable
fun RowScope.BasicChatTextField(
    modifier : Modifier,
    textFieldValue: TextFieldValue,
    onValueChange: (TextFieldValue) -> Unit
){
    BasicTextField(
        modifier = modifier
            .weight(1f)
            .background(
                color = MaterialTheme.colorScheme.surface,
                shape = RoundedCornerShape(MaterialTheme.spacing.medium)
            ),
        value = textFieldValue,
        onValueChange = onValueChange,
        decorationBox = { innerTextField ->
            Box(
                modifier = Modifier.padding(MaterialTheme.spacing.small)
            ){
                if (textFieldValue.text.isEmpty()) {
                    Text(
                        text = Res.string.textfield_hint.string,
                        fontSize = 14.sp
                    )
                }
                innerTextField()
            }
        }
    )
}