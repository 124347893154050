@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package truematch.uizone.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.FontResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainFont0 {
  public val dancing_script: FontResource by 
      lazy { init_dancing_script() }
}

@InternalResourceApi
internal fun _collectCommonMainFont0Resources(map: MutableMap<String, FontResource>) {
  map.put("dancing_script", CommonMainFont0.dancing_script)
}

internal val Res.font.dancing_script: FontResource
  get() = CommonMainFont0.dancing_script

private fun init_dancing_script(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:dancing_script",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/font/dancing_script.ttf", -1, -1),
    )
)
