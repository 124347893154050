package com.osg.ice.ui.ds

import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.graphics.ColorMatrix
import androidx.compose.ui.text.style.TextOverflow
import com.osg.truebase.data.iceDef.IEntertainmentData
import com.osg.truebase.data.iceDef.IceTag
import com.osg.ui.core.resources.string
import com.osg.ui.core.commonStates.TrueImage
import com.osg.truebase.ui.paint.PreGradientBackground
import com.osg.ui.core.resources.UiText

interface IEntertainmentDataUi: IEntertainmentData {
    override val image: TrueImage
    val name: UiText
    val slogan: UiText
    val description: UiText

    val smallSubTitle: UiText
        get() = slogan

    val enabled: Boolean
        get() = true
    val backgroundGradient: List<Color>
            get() = if (enabled)  PreGradientBackground.Game.colors else PreGradientBackground.Grey.colors

    val imageFilter: ColorFilter?
            get() = if (enabled) null else ColorFilter.colorMatrix(ColorMatrix().apply { setToSaturation(0f) })
}

@Immutable
data class EntertainmentDataUi(
    override val id: String,
    override val name: UiText,
    override val slogan: UiText,
    override val description: UiText,
    override val image: TrueImage,
    override val vibes: List<IceTag>,
) : IEntertainmentDataUi

interface IEntertainmentCollection {
    val name: UiText
    val entertainmentDataUiList: List<IEntertainmentDataUi>
    @Composable
    fun CollectionHeader(){
        Text(
            text = name.string,
            style = MaterialTheme.typography.titleLarge,
            maxLines = 1,
            overflow = TextOverflow.Ellipsis,
            modifier = Modifier.wrapContentWidth(Alignment.Start)
        )
    }
}

@Immutable
data class EntertainmentCollection(
    override val name: UiText,
    override val entertainmentDataUiList: List<IEntertainmentDataUi>,
) : IEntertainmentCollection
