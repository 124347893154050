package osg.uiZone.matchZone.state.tmEntUi

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.ui.Modifier
import com.osg.appData.matchZone.Eligibility
import com.osg.appData.matchZone.ITmEntertainmentData
import com.osg.appData.matchZone.invitation.EntertainmentType
import com.osg.appData.matchZone.invitation.InvitationContent
import com.osg.appData.matchZone.invitation.InvitationStatus
import com.osg.ice.ui.ds.IEntertainmentDataUi
import com.osg.truebase.data.iceDef.InviteAction
import com.osg.ui.core.resources.UiArray
import com.osg.ui.core.resources.UiText
import com.osg.ui.core.resources.toUiText
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.entertainment_stage

data class InvitationContentImpl(
    override val content: String,
    override val type: EntertainmentType,
    override val status: InvitationStatus
): InvitationContent

@Immutable
sealed interface TmEntertainmentUiType : IEntertainmentDataUi, ITmEntertainmentData {
    val ingredients: UiArray
    val eligibility: Eligibility
    val value: Int
    val entData: ITmEntertainmentData

    override val smallSubTitle: UiText
        get() = Res.string.entertainment_stage.toUiText(unlockStage.toString())
}



sealed interface IEntResponse {
    @Composable
    fun FabView(
        modifier: Modifier = Modifier,
        onOption: (InvitationContent) -> Unit
    )

    fun toResponse(response: InviteAction): InvitationContent
}