package com.osg.ui.core.commonStates

import androidx.compose.animation.Crossfade
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Image
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.layout.ContentScale
import com.osg.coroutines.dispechers.ioDispatcher
import com.osg.truebase.data.logger.AppLogger
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

@Composable
fun TrueImage.AsyncImage(
    modifier: Modifier = Modifier.Companion,
    durationMillis: Int = 500,
    maxDimPixels: Int = 512,
    contentScale: ContentScale = ContentScale.Companion.Fit,
) {
    var painterState: Painter? by remember {
        mutableStateOf(null)
    }
    LaunchedEffect(Unit) {
        withContext(ioDispatcher()) {
            try {
                val painter = this@AsyncImage.fromPainterCache(maxDimPixels)
                withContext(Dispatchers.Main) {
                    painterState = painter
                }
            } catch (e: Exception) {
                AppLogger.e("asBitmap", "Error loading image $this\n $e")
            }
        }
    }
    Crossfade(
        modifier = modifier,
        targetState = painterState,
        animationSpec = tween(durationMillis),
        label = "cross fade"
    ) { p ->
        if (p != null) {
            Image(
                contentScale = contentScale,
                painter = p,
                contentDescription = null
            )
        } else {
            Image(
                contentScale = contentScale,
                painter = loadingPainterState(),
                contentDescription = null
            )
        }
    }
}


