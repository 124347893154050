package com.osg.truebase.ui.animation

import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.nodeData.IRemoteMediaResource
import com.osg.truebase.data.resources.ILocalAnimations
import io.github.alexzhirkevich.compottie.DotLottie
import io.github.alexzhirkevich.compottie.LottieCompositionSpec
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.readResourceBytes
import ui.trueAutoResources.LocalResourceAnimations


sealed class LottieAnimation {
    data object Loading : LottieAnimation()
    data class Resource(val resourcePath: String) : LottieAnimation()
    data class Raw(val raw: String) : LottieAnimation()
    data class DotLottieResource(val resourcePath: String) : LottieAnimation()
    data class DotLottie(val dotLottieBytes: ByteArray) : LottieAnimation() {
        override fun equals(other: Any?): Boolean {
            if (this === other) return true
            if (other == null || this::class != other::class) return false

            other as DotLottie

            return dotLottieBytes.contentEquals(other.dotLottieBytes)
        }

        override fun hashCode(): Int {
            return dotLottieBytes.contentHashCode()
        }
    }
}

sealed class WebPAnimation {
    data class Resource(val resourcePath: String) : WebPAnimation()
    data class Raw(val raw: ByteArray) : WebPAnimation() {
        override fun equals(other: Any?): Boolean {
            if (this === other) return true
            if (other == null || this::class != other::class) return false

            other as Raw

            return raw.contentEquals(other.raw)
        }

        override fun hashCode(): Int {
            return raw.contentHashCode()
        }
    }
}

enum class AnimationType {
    DotLottie,
    Lottie,
}

fun String.toAnimationType(): AnimationType {
    return when {
        this.endsWith(".json") -> AnimationType.Lottie
        this.endsWith(".lottie") -> AnimationType.DotLottie
        else -> throw IllegalArgumentException("Unknown media type: $this")
    }
}

fun ByteArray.toAnimation(
    media: IRemoteMediaResource,
): LottieAnimation {
    val animationType = media.mediaPath.toAnimationType()
    return when (animationType) {
        AnimationType.Lottie -> {
            LottieAnimation.Raw(this.decodeToString())
        }
        AnimationType.DotLottie -> {
           LottieAnimation.DotLottie(this)
        }
    }
}

fun String.toAnimationResource(): LottieAnimation {
    val animationType = toAnimationType()
    return when (animationType) {
        AnimationType.Lottie -> {
            LottieAnimation.Resource(this)
        }
        AnimationType.DotLottie -> {
            LottieAnimation.DotLottieResource(this)
        }
    }
}

fun IRemoteMediaResource.toResource(): LottieAnimation {
    return this.mediaPath.toAnimationResource()
}

fun ILocalAnimations.toResource(): LottieAnimation {
    return this.mediaPath.toAnimationResource()
}


@OptIn(InternalResourceApi::class)
suspend fun loadLottieComposition(
    animationData: LottieAnimation,
): LottieCompositionSpec {
    AppLogger.d("loadLottieComposition", "animationData type is ${animationData::class.simpleName}")
    return when (animationData) {
        is LottieAnimation.Resource -> {
            val bytes = readResourceBytes(animationData.resourcePath)
            LottieCompositionSpec.JsonString(bytes.decodeToString())
        }

        is LottieAnimation.Raw -> {
            LottieCompositionSpec.JsonString(animationData.raw)
        }

        is LottieAnimation.DotLottie -> {
            LottieCompositionSpec.DotLottie(
                archive = animationData.dotLottieBytes
            )
        }

        is LottieAnimation.DotLottieResource -> {
            val bytes = readResourceBytes(animationData.resourcePath)
            LottieCompositionSpec.DotLottie(
                archive = bytes
            )
        }

        LottieAnimation.Loading -> {
            val bytes = readResourceBytes(
                LocalResourceAnimations.ANIMATION_LOADING.mediaPath
            )
            LottieCompositionSpec.JsonString(bytes.decodeToString())
        }
    }
}

@OptIn(InternalResourceApi::class)
suspend fun loadByteAnimation(
    animationData: WebPAnimation,
): ByteArray {
    return when (animationData) {
        is WebPAnimation.Resource -> {
            readResourceBytes(animationData.resourcePath)
        }

        is WebPAnimation.Raw -> {
            AppLogger.d("loaded: TrueAnimation.Raw")
            animationData.raw
        }
    }
}