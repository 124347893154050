package com.osg.appData.ml

import kotlinx.serialization.Serializable

@Serializable
data class MatchOsInput(
    val requesterFeatures: UserFeatures = UserFeatures(),
    val candidatesFeatures: List<UserFeatures> = emptyList(),
    val targets: MatchOsOutput = MatchOsOutput()
)


@Serializable
data class BatchMatchOsInput(
    val inputs: List<MatchOsInput> = emptyList(),
)

