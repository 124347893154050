package osg.uiZone.matchZone.entertainment.data

import androidx.compose.runtime.Immutable
import com.osg.appData.matchZone.Price
import com.osg.ice.IIceBreakerDependencies
import com.osg.truebase.ui.animation.LottieAnimation
import com.osg.truebase.ui.animation.toResource
import com.osg.ui.core.resources.UiText
import com.osg.ui.core.resources.toUiText
import com.osg.ui.core.resources.uiText
import osg.uiZone.expect.formatPrice
import truematch.uizone.generated.resources.*
import uiZone.trueAutoResources.LocalAnimations

sealed interface IceScreenUiStates


data class IceInProcces(
    val iceBreakerDependencies: IIceBreakerDependencies
): IceScreenUiStates

@Immutable
sealed interface IceResultsScreenUiState: IceScreenUiStates{
    val resultTitle: UiText
    val subTitle: UiText
    val animation: LottieAnimation
    val isShowButton: Boolean
    fun showButton(): IceResultsScreenUiState
    @Immutable
    data class WinGame(
        override var isShowButton: Boolean = false,
        val prize: Price
    ): IceResultsScreenUiState{
        override val resultTitle: UiText = Res.string.game_over_title_win.toUiText(prize.formatPrice())
        override val subTitle: UiText = Res.string.game_over_sub_title_win.uiText
        override val animation: LottieAnimation = LocalAnimations.ANIMATION_WIN_ICE.toResource()
        override fun showButton(): IceResultsScreenUiState = copy(isShowButton = true)
    }

    @Immutable
    data class LooseGame(
        override var isShowButton: Boolean = false
    ) : IceResultsScreenUiState{
        override val resultTitle: UiText = Res.string.game_over_title_loose.uiText
        override val subTitle: UiText = Res.string.game_over_sub_title_loose.uiText
        override val animation: LottieAnimation = LocalAnimations.ANIMATION_TALK.toResource()
        override fun showButton(): IceResultsScreenUiState = copy(isShowButton = true)
    }
}