package osg.uiZone.tutorial


import com.osg.truebase.spotlight.IComposableTextLoader
import com.osg.truebase.spotlight.ITutorialStepUi

interface IBaeAppStep : ITutorialStepUi {
    override val buttonTextLoader: IComposableTextLoader
        get() = IComposableTextLoader { "Next" }

}

enum class TutorialStepCollection: IBaeAppStep {
    SearchPromoIncentive {
        override val titleLoader: IComposableTextLoader = IComposableTextLoader { "✨ Match Potential" }
        override val textLoader: IComposableTextLoader = IComposableTextLoader { "Discover what makes this match special and why we think you'll click" }
    },
    SearchPromoWantToSeeMore {
        override val titleLoader: IComposableTextLoader = IComposableTextLoader { "💌 Explore More" }
        override val textLoader: IComposableTextLoader = IComposableTextLoader { "Take a closer look at your potential match before making a connection" }
    },
    SharedJourneyExplanation {
        override val titleLoader: IComposableTextLoader = IComposableTextLoader { "🎉 Perfect Match!" }
        override val textLoader: IComposableTextLoader = IComposableTextLoader { "It's all about you and your match here 🤞" }
    },
    MatchZoneIceBreakerButton {
        override val titleLoader: IComposableTextLoader = IComposableTextLoader { "🎮 Ice Breakers" }
        override val textLoader: IComposableTextLoader = IComposableTextLoader { "Play interactive games together and break the ice in a fun way" }
    },
    MatchZoneSparksButton {
        override val titleLoader: IComposableTextLoader = IComposableTextLoader { "✨ Spark Moments" }
        override val textLoader: IComposableTextLoader = IComposableTextLoader { "Turn your Ice Breaker wins into real-world date experiences" }
    },
    MatchZoneChatButton {
        override val titleLoader: IComposableTextLoader = IComposableTextLoader { "💭 Start Chatting" }
        override val textLoader: IComposableTextLoader = IComposableTextLoader { "Begin your conversation and get to know each other better" }
    },
    MatchZoneStoryButton {
        override val titleLoader: IComposableTextLoader = IComposableTextLoader { "🖼 Your Match Story" }
        override val textLoader: IComposableTextLoader = IComposableTextLoader { "Each relationship stage reveals a new, meaningful moment from your match?s journey"}
    },
    ChatSpacialMoveButton {
        override val titleLoader: IComposableTextLoader = IComposableTextLoader { "⭐ Special Move" }
        override val textLoader: IComposableTextLoader = IComposableTextLoader { "Make a unique gesture to stand out and show you care" }
    };

    override val idx by lazy {
        ordinal
    }

    override val id: String by lazy {
        name
    }
}