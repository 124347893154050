package com.osg.truebase.ui.dialog

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable

@Immutable
data class ImageBytes(
    val bytes: ByteArray,
    val fileType: String
)

interface IPlatformImagePicker {
    @Composable
    fun ImagePicker(
        onImagePicked: (ImageBytes?) -> Unit
    )
}


expect val platformImagePicker: IPlatformImagePicker

public sealed class PickerType {
    public data object Image : PickerType()
    public data object Video : PickerType()
    public data object ImageAndVideo : PickerType()
    public data class File(
        val extensions: List<String>? = null
    ) : PickerType()
}
