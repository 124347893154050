package osg.uiZone.register.model

import androidx.compose.ui.text.input.KeyboardType
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.osg.appData.common.OnlineSettingsData
import com.osg.appData.common.SettingsService
import com.osg.appData.profile.FullProfile
import com.osg.appData.profile.OpenAnswer
import com.osg.appData.register.OpenDeepQuestions
import com.osg.appData.register.ScDeepQuestions
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.ui.animation.toResource
import com.osg.truebase.ui.assigners.LinesType
import com.osg.truebase.ui.assigners.ScFieldAssigner
import com.osg.truebase.ui.assigners.TextFieldAssigner
import com.osg.truebase.ui.assigners.asScData
import com.osg.truebase.ui.assigners.def.GenericQuestionAssigner
import com.osg.truebase.ui.assigners.def.QuestionUiData
import com.osg.truebase.ui.assigners.def.genObjTransformer
import com.osg.truebase.ui.commonStates.MainViewUiState
import com.osg.truebase.ui.commonStates.toSuccess
import com.osg.ui.core.resources.toUiArray
import com.osg.ui.core.resources.uiText
import com.osg.utils.filterToData
import com.osg.utils.waitForSuccess
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.getResourceUri
import osg.uiZone.common.vmRepo.IRegisterRepository
import osg.uiZone.common.vmRepo.RegisterRepositoryImpl
import osg.uiZone.profile.ProfileExt.isScQuestionAnswer
import osg.uiZone.profile.ProfileExt.isWatchedDeepQuestionsIntro
import osg.uiZone.profile.ProfileExt.setPublicOpenQuestionAnswer
import osg.uiZone.profile.ProfileExt.setWatchedDeepQuestionsIntro
import osg.uiZone.register.data.buildProfileAssigners
import osg.uiZone.register.data.questionData
import osg.uiZone.register.ds.RegisterScreenState
import uiZone.trueAutoResources.LocalAnimations
import uiZone.trueAutoResources.LocalVideos

class RegisterSetupViewModel(
    private val regRepo : IRegisterRepository = RegisterRepositoryImpl()
) : ViewModel(){
    val uiState: MutableStateFlow<MainViewUiState<RegisterScreenState>> = MutableStateFlow(MainViewUiState.Loading)
    private val onlineSettings: OnlineSettingsData = SettingsService.onlineSettings
    init {
        AppLogger.d(this::class.simpleName!!, "init")
        viewModelScope.launch {
            val initialProfile = regRepo.myProfileStateFlow.filterToData().first()
            AppLogger.d(this::class.simpleName!!, "initialProfile = $initialProfile")

            val currentLocation = regRepo.currentLocationState.waitForSuccess()

            AppLogger.d(this::class.simpleName!!, "currentLocation = $currentLocation")
            val questionData = initialProfile.buildProfileAssigners(
                currentLocation = currentLocation,
                asyncOptions = regRepo
            )

            uiState.value = RegisterScreenState.Questions(
                questionData = questionData,
                initialProfile = initialProfile,
            ).toSuccess()
        }
    }

    @OptIn(InternalResourceApi::class)
    private fun onFinishTechnicalQuestions(
        profile: FullProfile
    ){
        if (profile.isWatchedDeepQuestionsIntro()) {
            onFinishDeepQuestionsIntro(profile)
        } else {
            uiState.value = RegisterScreenState.DeepQuestionsIntro(
                initialProfile = profile,
                videoPath = getResourceUri(LocalVideos.QUESTIONS_INTRO.mediaPath)
            ).toSuccess()
        }
    }

    fun onFinishDeepQuestionsIntro(profile: FullProfile) {
        profile.setWatchedDeepQuestionsIntro()

        uiState.value = RegisterScreenState.Questions(
            questionData = buildDeepQuestions(profile),
            initialProfile = profile,
        ).toSuccess()
        viewModelScope.launch {
            regRepo.saveMyFullProfile(profile)
        }
    }

    fun onFinishedQuestions(
        profile: FullProfile
    ) {
        if (profile.publicProfile.openQuestionAnswer.any()) {
            onFinishDeepQuestions(profile)
        } else {
            onFinishTechnicalQuestions(profile)
        }
    }


    private fun onFinishDeepQuestions(profile: FullProfile) {
        uiState.value = RegisterScreenState.AddPictures(
            minimumImageToSave = onlineSettings.minPictures,
        ).toSuccess()
        viewModelScope.launch {
            regRepo.saveMyFullProfile(profile)
        }
    }

    override fun onCleared() {
        super.onCleared()
        AppLogger.d("onCleared")
    }
}

fun buildDeepQuestions(
    fullProfile: FullProfile
): List<GenericQuestionAssigner<FullProfile>> {
    val scQuestions = ScDeepQuestions.entries.filter {
        fullProfile.isScQuestionAnswer(it.name).not()
    }

    val openQuestions = OpenDeepQuestions.entries.filter { openQ ->
        fullProfile.publicProfile.openQuestionAnswer.any { it.questionId == openQ }.not()
    }


    val deepProfileAssigners = scQuestions.map {
        it.toScProfileAssigner()
    } + openQuestions.map {
        it.toProfileAssigner()
    }
    return deepProfileAssigners
}

fun OpenDeepQuestions.toProfileAssigner(): GenericQuestionAssigner<FullProfile>{
    return GenericQuestionAssigner<FullProfile>(
        genObjAssigner = TextFieldAssigner(
            linesType = LinesType.Multi(7),
            keyboardType = KeyboardType.Text,
            validate = { true },
            directions = this.questionData.directions.uiText
        ).genObjTransformer(
            reverseTransformer = { profile ->
                profile.publicProfile.openQuestionAnswer.firstOrNull{this == it.questionId}?.answer
            },
            transformer = { a, t ->
                t!!.apply {
                    setPublicOpenQuestionAnswer(
                        OpenAnswer(
                            questionId = this@toProfileAssigner,
                            answer = a
                        )
                    )
                }
            }
        ),
        questionUiData = QuestionUiData(
            animationResource = LocalAnimations.BOT_ANIM.toResource(),
            directions = questionData.directions.uiText,
            title = questionData.title.uiText
        )
    )
}

fun ScDeepQuestions.toScProfileAssigner(): GenericQuestionAssigner<FullProfile>{
    val qId = this.name
    return GenericQuestionAssigner<FullProfile>(
        genObjAssigner = ScFieldAssigner(
            possibleAnswers = this.questionData.options.toUiArray().asScData(),
        ).genObjTransformer(
            reverseTransformer = {
                it.personalProfile.questionAnswer[qId]
            },
            transformer = { a, t ->
                t!!.copy(
                    personalProfile = t.personalProfile.copy(
                        questionAnswer = t.personalProfile.questionAnswer + mapOf(qId to a)
                    )
                )
            }
        ),
        questionUiData = QuestionUiData(
            animationResource = LocalAnimations.BOT_ANIM.toResource(),
            directions = questionData.directions.uiText,
            title = questionData.title.uiText
        )
    )
}