package osg.uiZone.landing.model

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.size
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.graphics.shader.MESH_SHADER
import com.osg.truebase.ui.graphics.shader.TrueShader
import com.osg.truebase.ui.graphics.shader.shaderBackground
import com.osg.truebase.ui.paint.PreGradientBackground
import com.osg.ui.core.resources.painter
import osg.cross.landing.ds.CommonResources

@Composable
fun LandingShader(
    modifier: Modifier = Modifier,
) {
    Box(
        modifier = modifier.fillMaxSize().shaderBackground(
            TrueShader(shaderData = MESH_SHADER).runtimeEffect.apply {
                PreGradientBackground.LogoBackground.colors.forEachIndexed { index, color ->
                    setColorUniform("iColor${index + 1}", color)
                }
            }
        ),
    ) {
        Image(
            modifier = Modifier.Companion
                .size(200.dp)
                .align(Alignment.Companion.Center),
            contentScale = ContentScale.Companion.FillWidth,
            painter = CommonResources.Logo.drawableResource.painter,
            contentDescription = "Logo",
        )
    }
}