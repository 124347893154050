package osg.uiZone.profile

import com.osg.appData.profile.FullProfile
import com.osg.appData.profile.OpenAnswer
import com.osg.appData.profile.SignUpData
import kotlin.math.roundToInt

object ProfileExt {

    private fun FullProfile.setSignUpData(
        signInData: SignUpData,
        uid: String
    ) {
        this.apply {
            personalProfile.lastName = signInData.lastName
            publicProfile.firstName = signInData.firstName
            publicProfile.birthDay = signInData.birthDay
            personalProfile.email = signInData.email
            publicProfile.uid = uid
            publicProfile.gender = signInData.gender
            publicProfile.location = signInData.userLocation
            personalProfile.signInType = signInData.signInType
        }
    }


    fun FullProfile.setPublicOpenQuestionAnswer(openAnswer: OpenAnswer) {
        this.publicProfile.openQuestionAnswer = this.publicProfile.openQuestionAnswer.filter {
            it.questionId != openAnswer.questionId
        } + openAnswer
    }

    fun FullProfile.isScQuestionAnswer(id: String): Boolean {
        return this.personalProfile.questionAnswer.containsKey(id)
    }

    fun FullProfile.setPreferencesAge(rangeDataResult: ClosedFloatingPointRange<Float>) {
        this.personalProfile.apply {
            this.preferencesMatch = this.preferencesMatch.copy(
                ageMin = rangeDataResult.start.roundToInt(),
                ageMax = rangeDataResult.endInclusive.roundToInt()
            )
        }
    }

    fun FullProfile.isWatchedDeepQuestionsIntro(): Boolean {
        return this.watchedQuestionIntro
    }

    fun FullProfile.setWatchedDeepQuestionsIntro() {
        this.watchedQuestionIntro = true
    }

}