package com.osg.ice

import androidx.compose.runtime.Composable
import com.osg.ice.ui.comp.DragFab
import com.osg.ice.ui.ds.EntertainmentDataUi
import com.osg.ice.ui.EntertainmentDetailsScreen
import com.osg.truebase.data.LanguageSupport
import com.osg.truebase.ice.generated.resources.Res
import com.osg.truebase.ice.generated.resources.i_m_ready
import com.osg.truebase.ice.generated.resources.start_vec
import com.osg.truebase.ice.generated.resources.waiting_for_your_match
import com.osg.ui.core.resources.string
import com.osg.ui.core.resources.vector
import com.osg.truebase.ui.theme.LocalTmConfig

data class PlayerSettings(
    val languageSupport: LanguageSupport
)

interface IceBreakerModel {
    val uiData: EntertainmentDataUi
    fun onStartGame(playerSettings: PlayerSettings)

    @Composable
    fun LandingContent(){
        EntertainmentDetailsScreen(
            icebreakerUiData = uiData,
        ){
            val languageSupport = LocalTmConfig.current.language
            DragFab(
                fabIcon = Res.drawable.start_vec.vector,
                text = Res.string.i_m_ready.string,
                extend = true,
                onClick = {
                    onStartGame(PlayerSettings(
                        languageSupport
                    ))
                },
                postClickText = Res.string.waiting_for_your_match.string,
            )
        }
    }
}