package com.osg.utils

/**
 * Returns a new list with the element at the specified index replaced with the given element.
 *
 * @param index The index of the element to replace.
 * @param elem The element to replace the existing element with.
 * @return A new list with the element at the specified index replaced.
 * @throws IndexOutOfBoundsException if the index is out of range (index < 0 || index >= size).
 */
fun <E> Iterable<E>.updated(index: Int, elem: E) = mapIndexed { i, existing ->  if (i == index) elem else existing }

/**
 * Removes all elements that match the given predicate from the mutable iterable and returns them as a list.
 *
 * @param predicate A function that returns true for elements to be removed.
 * @return A list of elements that were removed.
 */
fun <E> MutableIterable<E>.extract(predicate: (E) -> Boolean): List<E> {
    return filter(predicate).also { matching ->
        removeAll { it in matching }
    }
}

/**
 * Removes all elements that match the given predicate from the mutable list and returns them as a list.
 *
 * @param predicate A function that returns a list of elements to be removed.
 * @return A list of elements that were removed.
 */
fun <E> MutableList<E>.extractItems(predicate: List<E>.() -> List<E>): List<E>{
    return predicate().also { matching ->
        removeAll { it in matching }
    }
}

/**
 * Removes the first element that matches the given predicate from the mutable list and returns it.
 *
 * @param predicate A function that returns true for the element to be removed.
 * @return The element that was removed.
 */
fun <E> MutableList<E>.extractFirst(predicate: (E) -> Boolean): E {
    return first(predicate).also { matching ->
        remove(matching)
    }
}

fun <E> MutableList<E>.extractFirstOrNull(predicate: (E) -> Boolean): E? {
    return firstOrNull(predicate)?.also { matching ->
        remove(matching)
    }
}


fun <T> Collection<T>.replaceOrAdd(
    element: T,
    predicate: (T) -> Boolean = { it == element }
): List<T> {
    val idx = indexOfFirst(predicate)
    return if (idx == -1) {
        this + element
    } else {
        updated(idx, element)
    }
}

fun <T> Collection<T>.filterAt(idx: Int): List<T> {
    return filterIndexed { i, _ -> i != idx }
}