package com.osg.truebase.ui.signIn

import androidx.compose.foundation.layout.*
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.osg.truebase.signin.ISignInProvider
import com.osg.truebase.signin.SignInDialogs
import com.osg.truebase.signin.SignInDialogs.SignInDialog
import com.osg.truebase.signin.SignInResult
import com.osg.truebase.ui.dialog.AlertDialogExitWithError
import com.osg.truebase.ui.dialog.FatalErrorType
import com.osg.truebase.ui.theme.spacing

@Composable
fun SignInDialogController(
    signInDialogs: SignInDialogs,
    onCollisionExceptionConfirmed: () -> Unit,
    onComplete: (Result<SignInResult>) -> Unit
) {
    when (signInDialogs) {
        is SignInDialog -> {
            signInDialogs.signInProvider.SignInDialog(
                onComplete = onComplete
            )
        }

        is SignInDialogs.CollisionException -> {
            SignInCollisionDialog(
                registeredMethod = signInDialogs.signInProvider,
                onSignInClicked = onCollisionExceptionConfirmed
            )
        }

        is SignInDialogs.UnExpectedException -> {
            AlertDialogExitWithError(
                fatalErrorType = FatalErrorType.PERMISSION_ERROR
            )
        }

        is SignInDialogs.None -> {
            // Do nothing
        }
    }
}

@Composable
fun SignInControllers(
    modifier: Modifier = Modifier,
    signInProviders: List<ISignInProvider>,
    onComplete: (Result<SignInResult>) -> Unit = {}
) {

    var signInDialogs by remember {
        mutableStateOf<SignInDialogs>(SignInDialogs.None)
    }

    SignInDialogController(
        signInDialogs = signInDialogs,
        onCollisionExceptionConfirmed = {
            signInDialogs = SignInDialogs.None
        },
        onComplete =  onComplete
    )

    Column(
        modifier = modifier,
        verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.medium)
    ) {
        signInProviders.forEach { provider ->
            BrandedButton(
                modifier = Modifier
                    .widthIn(max = 280.dp),
                brandedButtonType = provider.identifier.brandedButtonType,
                onClick = {
                    signInDialogs = SignInDialog(provider)
                }
            )
        }
    }
}