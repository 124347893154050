package uiZone.trueAutoResources


internal enum class LocalImages(override val mediaPath: String): com.osg.truebase.data.resources.ILocalImages {
	VERIFIED("composeResources/truematch.uizone.generated.resources/drawable/verified.webp"),
	CHECK_LIST("composeResources/truematch.uizone.generated.resources/drawable/check_list.webp"),
	IMAGE_GUESS("composeResources/truematch.uizone.generated.resources/drawable/image_guess.webp"),
	EMOJI_GAME("composeResources/truematch.uizone.generated.resources/drawable/emoji_game.webp"),
	MATCHER("composeResources/truematch.uizone.generated.resources/drawable/matcher.webp"),
	BILLIE_R("composeResources/truematch.uizone.generated.resources/drawable/billie_r.jpg"),
	ESCAPE("composeResources/truematch.uizone.generated.resources/drawable/escape.webp"),
	ICE_MOVE("composeResources/truematch.uizone.generated.resources/drawable/ice_move.webp"),
	MUSICAL_MOVE("composeResources/truematch.uizone.generated.resources/drawable/musical_move.webp"),
	NO_MATCH_YET_IMG("composeResources/truematch.uizone.generated.resources/drawable/no_match_yet_img.webp"),
	CELEB("composeResources/truematch.uizone.generated.resources/drawable/celeb.webp"),
	ONE_AI("composeResources/truematch.uizone.generated.resources/drawable/one_ai.webp"),
	ROSE_MOVE("composeResources/truematch.uizone.generated.resources/drawable/rose_move.webp"),
	BLIND_CANVAS("composeResources/truematch.uizone.generated.resources/drawable/blind_canvas.webp"),
	CHALLENGE_ACCEPTED("composeResources/truematch.uizone.generated.resources/drawable/challenge_accepted.webp"),
	PUZZLE("composeResources/truematch.uizone.generated.resources/drawable/puzzle.webp"),
	JANGA("composeResources/truematch.uizone.generated.resources/drawable/janga.webp"),
	WHO_WANTS("composeResources/truematch.uizone.generated.resources/drawable/who_wants.webp"),
	HEART_PATTERN("composeResources/truematch.uizone.generated.resources/drawable/heart_pattern.png"),
	HARMONY_TUNES("composeResources/truematch.uizone.generated.resources/drawable/harmony_tunes.webp"),
	EMINEM_R("composeResources/truematch.uizone.generated.resources/drawable/eminem_r.jpeg"),
	IMAGINDRAGON_R("composeResources/truematch.uizone.generated.resources/drawable/imagindragon_r.jpg"),
	EDSHEERAN_R("composeResources/truematch.uizone.generated.resources/drawable/edsheeran_r.jpeg");
}
internal enum class LocalVideos(override val mediaPath: String): com.osg.truebase.data.resources.ILocalMediaResource {
	QUESTIONS_INTRO("composeResources/truematch.uizone.generated.resources/files/questions_intro.mp4");
}
internal enum class LocalFonts(override val mediaPath: String): com.osg.truebase.data.resources.ILocalMediaResource {
	DANCING_SCRIPT("composeResources/truematch.uizone.generated.resources/font/dancing_script.ttf");
}
internal enum class LocalAnimations(override val mediaPath: String): com.osg.truebase.data.resources.ILocalAnimations {
	ANIMATION_LOADING_SEARCH("composeResources/truematch.uizone.generated.resources/files/animation_loading_search.json"),
	BOT_ANIM("composeResources/truematch.uizone.generated.resources/files/bot_anim.json"),
	ANIMATION_SPARK_LOADING("composeResources/truematch.uizone.generated.resources/files/animation_spark_loading.json"),
	ANIMATION_WIN_ICE("composeResources/truematch.uizone.generated.resources/files/animation_win_ice.json"),
	ANIMATION_WORK("composeResources/truematch.uizone.generated.resources/files/animation_work.json"),
	ANIMATION_INVITE("composeResources/truematch.uizone.generated.resources/files/animation_invite.json"),
	BIRTHDAY_ANIM("composeResources/truematch.uizone.generated.resources/files/birthday_anim.json"),
	ANIMATION_ICE_LOADING("composeResources/truematch.uizone.generated.resources/files/animation_ice_loading.json"),
	ANIMATION_HEIGHT("composeResources/truematch.uizone.generated.resources/files/animation_height.json"),
	ANIMATION_HOME("composeResources/truematch.uizone.generated.resources/files/animation_home.json"),
	ANIMATION_AGE_RANGE("composeResources/truematch.uizone.generated.resources/files/animation_age_range.json"),
	ANIMATION_TALK("composeResources/truematch.uizone.generated.resources/files/animation_talk.json"),
	ANIMATION_LOGO("composeResources/truematch.uizone.generated.resources/files/animation_logo.json");
}

internal val LocalAllMedia: List<com.osg.truebase.data.resources.ILocalMediaResource> = LocalImages.entries +
	LocalVideos.entries +
	LocalFonts.entries +
	LocalAnimations.entries