package com.osg.truebase.database.events

import com.osg.truebase.data.logger.AppLogger

data class NativeEvent(
    val params: Map<String, String>,
    val serilizedData: String,
    val invokedRegPath: String
){
    val resolvedPath by lazy {
        params.resolvePath(invokedRegPath)
    }

    val resolvedPathParent by lazy {
        resolvedPath.substringBeforeLast("/")
    }
}

val firebaseKeysRegex by  lazy {
    Regex("\\{(\\w+)\\}")
}

fun String.toFirebasePathRegex(): Regex {
    val regexPattern = replace(firebaseKeysRegex) { match ->
        "(?<${match.groupValues[1]}>[^\\/]+)" // Named group capturing any sequence except '/'
    }
    return Regex(regexPattern)

}



data class EventHandler(
    val path: String,
    val handler: suspend (NativeEvent) -> Unit
){
    init {
        AppLogger.d("botEventHandler", "tracking path: $path")
    }
}

fun EventHandler.extractParams(invokedPath: String): Map<String, String>? {
    val valuesRegex = path.toFirebasePathRegex()
    val matchResult = valuesRegex.matchEntire(invokedPath) ?: return null
    val keyResults = firebaseKeysRegex.findAll(path).map {  it.groupValues[1] }
    return keyResults.map {
        it to matchResult.groups[it]!!.value
    }.toMap()
}

private fun Map<String, String>.resolvePath(string: String): String{
    var path = string
    this.forEach { (key, value) ->
        path = path.replace("{$key}", value)
    }
    return path
}
