package osg.uiZone.profile

import com.osg.appData.resource.StorageQueries
import com.osg.truebase.data.nodeData.RemoteProfileImage
import com.osg.utils.time.TimeService

fun newProfileImage(uid: String, fileType: String): RemoteProfileImage {
    val fileName = "${TimeService.getUtcTime()}.$fileType"
    val path = StorageQueries.userImagePath(uid, fileName)
    val media = RemoteProfileImage(mediaPath = path)
    return media
}