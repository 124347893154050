package osg.uiZone.search

import com.osg.appData.candidate.CandidateNode
import com.osg.appData.candidate.CandidateProfile
import com.osg.utils.pmap
import osg.uiZone.common.baseRepos.database.IPublicProfileFetcher


interface ICandidateNodeProcessor{
    suspend fun candidateNodeToProfile(candidatesBatch: List<CandidateNode>): List<CandidateProfile>
}

class CandidateNodeProcessorImpl(
    private val candidateHandler: IPublicProfileFetcher
): ICandidateNodeProcessor {
    override suspend fun candidateNodeToProfile(
        candidatesBatch: List<CandidateNode>
    ): List<CandidateProfile> {
        val profiles = candidatesBatch.pmap {
            candidateHandler.fetchPublicProfile(
                it.uid,
            )
        }

        return profiles.zip(candidatesBatch).map { (profile, candidateNode) ->
            CandidateProfile(
                matchIncentive = candidateNode.matchIncentive,
                publicProfile = profile,
                decision = candidateNode.decision
            )
        }
    }
}