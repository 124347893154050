package root.data.trueAutoResources


internal enum class SparksMediaRemoteImages(override val mediaPath: String): com.osg.truebase.data.nodeData.IRemoteImageResource {
	BIKE("spark/bike.webp"),
	ESCAPE_ROOM("spark/escape_room.webp"),
	SPA("spark/spa.webp"),
	PAINT_DATE("spark/paint_date.webp"),
	COUPON_DIZZY("spark/coupon_dizzy.webp"),
	BENEDICT_COUPON("spark/benedict_coupon.webp"),
	COFFEE("spark/coffee.webp"),
	FOO_COUPON("spark/foo_coupon.webp"),
	CONCERT("spark/concert.webp"),
	HORSES("spark/horses.webp");
}

internal val SparksMediaRemoteAllMedia: List<com.osg.truebase.data.nodeData.IRemoteMediaResource> = SparksMediaRemoteImages.entries