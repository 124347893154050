package ui.trueAutoResources


internal enum class LocalResourceFonts(override val mediaPath: String): com.osg.truebase.data.resources.ILocalMediaResource {
	LORA_VARIABLE_FONT_WGHT("composeResources/com.osg.truebase.ui.generated.resources/font/lora_variable_font_wght.ttf");
}
internal enum class LocalResourceAnimations(override val mediaPath: String): com.osg.truebase.data.resources.ILocalAnimations {
	ANIMATION_PAPER_PLANE("composeResources/com.osg.truebase.ui.generated.resources/files/animation_paper_plane.json"),
	ANIMATION_LOADING("composeResources/com.osg.truebase.ui.generated.resources/files/animation_loading.json"),
	ANIMATION_BLUSHING_ALT("composeResources/com.osg.truebase.ui.generated.resources/files/animation_blushing_alt.json"),
	ANIMATION_CHEERS("composeResources/com.osg.truebase.ui.generated.resources/files/animation_cheers.json");
}
internal enum class LocalResourceLottie(override val mediaPath: String): com.osg.truebase.data.resources.ILocalAnimations {
	GENDER("composeResources/com.osg.truebase.ui.generated.resources/files/gender.lottie");
}

internal val LocalResourceAllMedia: List<com.osg.truebase.data.resources.ILocalMediaResource> = LocalResourceFonts.entries +
	LocalResourceAnimations.entries +
	LocalResourceLottie.entries