@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package truematch.uizone.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val Soon: StringResource by 
      lazy { init_Soon() }

  public val add_image_text: StringResource by 
      lazy { init_add_image_text() }

  public val add_photo: StringResource by 
      lazy { init_add_photo() }

  public val adrenaline: StringResource by 
      lazy { init_adrenaline() }

  public val adventure: StringResource by 
      lazy { init_adventure() }

  public val advisor_header: StringResource by 
      lazy { init_advisor_header() }

  public val age_range: StringResource by 
      lazy { init_age_range() }

  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val apple_login: StringResource by 
      lazy { init_apple_login() }

  public val art: StringResource by 
      lazy { init_art() }

  public val auth_error: StringResource by 
      lazy { init_auth_error() }

  public val back: StringResource by 
      lazy { init_back() }

  public val balance_title: StringResource by 
      lazy { init_balance_title() }

  public val birth_day_reg: StringResource by 
      lazy { init_birth_day_reg() }

  public val blind_canvas_description: StringResource by 
      lazy { init_blind_canvas_description() }

  public val blind_canvas_details: StringResource by 
      lazy { init_blind_canvas_details() }

  public val blind_canvas_name: StringResource by 
      lazy { init_blind_canvas_name() }

  public val blind_canvas_slogan: StringResource by 
      lazy { init_blind_canvas_slogan() }

  public val brand_guidelines: StringResource by 
      lazy { init_brand_guidelines() }

  public val challenge_accepted_description: StringResource by 
      lazy { init_challenge_accepted_description() }

  public val challenge_accepted_details: StringResource by 
      lazy { init_challenge_accepted_details() }

  public val challenge_accepted_instruction_for_other: StringResource by 
      lazy { init_challenge_accepted_instruction_for_other() }

  public val challenge_accepted_instruction_for_player: StringResource by 
      lazy { init_challenge_accepted_instruction_for_player() }

  public val challenge_accepted_name: StringResource by 
      lazy { init_challenge_accepted_name() }

  public val challenge_accepted_slogan: StringResource by 
      lazy { init_challenge_accepted_slogan() }

  public val check_list_description: StringResource by 
      lazy { init_check_list_description() }

  public val check_list_details: StringResource by 
      lazy { init_check_list_details() }

  public val check_list_name: StringResource by 
      lazy { init_check_list_name() }

  public val check_list_slogan: StringResource by 
      lazy { init_check_list_slogan() }

  public val city_input: StringResource by 
      lazy { init_city_input() }

  public val close: StringResource by 
      lazy { init_close() }

  public val collision_detected_body: StringResource by 
      lazy { init_collision_detected_body() }

  public val collision_detected_title: StringResource by 
      lazy { init_collision_detected_title() }

  public val communication_style: StringResource by 
      lazy { init_communication_style() }

  public val confirm: StringResource by 
      lazy { init_confirm() }

  public val confirm_password: StringResource by 
      lazy { init_confirm_password() }

  public val create_account: StringResource by 
      lazy { init_create_account() }

  public val creativity: StringResource by 
      lazy { init_creativity() }

  public val culinary: StringResource by 
      lazy { init_culinary() }

  public val curiosity: StringResource by 
      lazy { init_curiosity() }

  public val dance: StringResource by 
      lazy { init_dance() }

  public val date_gender: StringResource by 
      lazy { init_date_gender() }

  public val decline_permission: StringResource by 
      lazy { init_decline_permission() }

  public val default_web_client_id: StringResource by 
      lazy { init_default_web_client_id() }

  public val detail_header: StringResource by 
      lazy { init_detail_header() }

  public val direction_type: StringResource by 
      lazy { init_direction_type() }

  public val dismiss: StringResource by 
      lazy { init_dismiss() }

  public val distance_range: StringResource by 
      lazy { init_distance_range() }

  public val done: StringResource by 
      lazy { init_done() }

  public val draw: StringResource by 
      lazy { init_draw() }

  public val emoji_description: StringResource by 
      lazy { init_emoji_description() }

  public val emoji_name: StringResource by 
      lazy { init_emoji_name() }

  public val emoji_resonance_description: StringResource by 
      lazy { init_emoji_resonance_description() }

  public val emoji_resonance_details: StringResource by 
      lazy { init_emoji_resonance_details() }

  public val emoji_resonance_name: StringResource by 
      lazy { init_emoji_resonance_name() }

  public val emoji_resonance_slogan: StringResource by 
      lazy { init_emoji_resonance_slogan() }

  public val emotional_connection: StringResource by 
      lazy { init_emotional_connection() }

  public val enter_your_height: StringResource by 
      lazy { init_enter_your_height() }

  public val entertainment_stage: StringResource by 
      lazy { init_entertainment_stage() }

  public val error_title: StringResource by 
      lazy { init_error_title() }

  public val exit_app: StringResource by 
      lazy { init_exit_app() }

  public val expand_search: StringResource by 
      lazy { init_expand_search() }

  public val facebook_app_id: StringResource by 
      lazy { init_facebook_app_id() }

  public val facebook_client_token: StringResource by 
      lazy { init_facebook_client_token() }

  public val facebook_login: StringResource by 
      lazy { init_facebook_login() }

  public val fantasy: StringResource by 
      lazy { init_fantasy() }

  public val fb_login_protocol_scheme: StringResource by 
      lazy { init_fb_login_protocol_scheme() }

  public val feature_not_available: StringResource by 
      lazy { init_feature_not_available() }

  public val feedback: StringResource by 
      lazy { init_feedback() }

  public val financial_compatibility: StringResource by 
      lazy { init_financial_compatibility() }

  public val finish_register_button_text: StringResource by 
      lazy { init_finish_register_button_text() }

  public val forgot_password: StringResource by 
      lazy { init_forgot_password() }

  public val funny: StringResource by 
      lazy { init_funny() }

  public val gallery_rationale_message: StringResource by 
      lazy { init_gallery_rationale_message() }

  public val gallery_rationale_title: StringResource by 
      lazy { init_gallery_rationale_title() }

  public val game_detail_header: StringResource by 
      lazy { init_game_detail_header() }

  public val game_feed_title: StringResource by 
      lazy { init_game_feed_title() }

  public val game_ingredients_title: StringResource by 
      lazy { init_game_ingredients_title() }

  public val game_invitation_text: StringResource by 
      lazy { init_game_invitation_text() }

  public val game_match_loose_title: StringResource by 
      lazy { init_game_match_loose_title() }

  public val game_match_win_title: StringResource by 
      lazy { init_game_match_win_title() }

  public val game_over_sub_title_loose: StringResource by 
      lazy { init_game_over_sub_title_loose() }

  public val game_over_sub_title_win: StringResource by 
      lazy { init_game_over_sub_title_win() }

  public val game_over_title_loose: StringResource by 
      lazy { init_game_over_title_loose() }

  public val game_over_title_win: StringResource by 
      lazy { init_game_over_title_win() }

  public val go_to_match_zone: StringResource by 
      lazy { init_go_to_match_zone() }

  public val google_login: StringResource by 
      lazy { init_google_login() }

  public val harmony_tunes_description: StringResource by 
      lazy { init_harmony_tunes_description() }

  public val harmony_tunes_details: StringResource by 
      lazy { init_harmony_tunes_details() }

  public val harmony_tunes_instruction_for_other: StringResource by 
      lazy { init_harmony_tunes_instruction_for_other() }

  public val harmony_tunes_instruction_for_player: StringResource by 
      lazy { init_harmony_tunes_instruction_for_player() }

  public val harmony_tunes_name: StringResource by 
      lazy { init_harmony_tunes_name() }

  public val harmony_tunes_slogan: StringResource by 
      lazy { init_harmony_tunes_slogan() }

  public val hide_password: StringResource by 
      lazy { init_hide_password() }

  public val hint: StringResource by 
      lazy { init_hint() }

  public val i_m_ready: StringResource by 
      lazy { init_i_m_ready() }

  public val in_my_free_time: StringResource by 
      lazy { init_in_my_free_time() }

  public val in_pic_description: StringResource by 
      lazy { init_in_pic_description() }

  public val in_pic_details: StringResource by 
      lazy { init_in_pic_details() }

  public val in_pic_name: StringResource by 
      lazy { init_in_pic_name() }

  public val in_pic_slogan: StringResource by 
      lazy { init_in_pic_slogan() }

  public val intellectual_attraction: StringResource by 
      lazy { init_intellectual_attraction() }

  public val intimacy: StringResource by 
      lazy { init_intimacy() }

  public val invitation_notification_channel: StringResource by 
      lazy { init_invitation_notification_channel() }

  public val invitation_notification_title: StringResource by 
      lazy { init_invitation_notification_title() }

  public val invite_button_text: StringResource by 
      lazy { init_invite_button_text() }

  public val invite_button_text_post: StringResource by 
      lazy { init_invite_button_text_post() }

  public val invite_game_button: StringResource by 
      lazy { init_invite_game_button() }

  public val its_match_description: StringResource by 
      lazy { init_its_match_description() }

  public val its_match_string: StringResource by 
      lazy { init_its_match_string() }

  public val janga_description: StringResource by 
      lazy { init_janga_description() }

  public val janga_details: StringResource by 
      lazy { init_janga_details() }

  public val janga_name: StringResource by 
      lazy { init_janga_name() }

  public val janga_slogan: StringResource by 
      lazy { init_janga_slogan() }

  public val jumpBottom: StringResource by 
      lazy { init_jumpBottom() }

  public val kindness_and_warmth: StringResource by 
      lazy { init_kindness_and_warmth() }

  public val leave_to_search: StringResource by 
      lazy { init_leave_to_search() }

  public val licenses: StringResource by 
      lazy { init_licenses() }

  public val lie_ai_description: StringResource by 
      lazy { init_lie_ai_description() }

  public val lie_ai_details: StringResource by 
      lazy { init_lie_ai_details() }

  public val lie_ai_name: StringResource by 
      lazy { init_lie_ai_name() }

  public val lie_ai_slogan: StringResource by 
      lazy { init_lie_ai_slogan() }

  public val loading: StringResource by 
      lazy { init_loading() }

  public val location_error: StringResource by 
      lazy { init_location_error() }

  public val location_not_supported: StringResource by 
      lazy { init_location_not_supported() }

  public val location_preview: StringResource by 
      lazy { init_location_preview() }

  public val location_rationale_message: StringResource by 
      lazy { init_location_rationale_message() }

  public val location_rationale_title: StringResource by 
      lazy { init_location_rationale_title() }

  public val lock_entertainment_money: StringResource by 
      lazy { init_lock_entertainment_money() }

  public val log_out: StringResource by 
      lazy { init_log_out() }

  public val loose_sub_title: StringResource by 
      lazy { init_loose_sub_title() }

  public val match_notification_channel: StringResource by 
      lazy { init_match_notification_channel() }

  public val match_notification_title: StringResource by 
      lazy { init_match_notification_title() }

  public val matcher_ia_description: StringResource by 
      lazy { init_matcher_ia_description() }

  public val matcher_ia_name: StringResource by 
      lazy { init_matcher_ia_name() }

  public val matchmaker_picks: StringResource by 
      lazy { init_matchmaker_picks() }

  public val memories: StringResource by 
      lazy { init_memories() }

  public val message_notification_channel: StringResource by 
      lazy { init_message_notification_channel() }

  public val message_notification_title: StringResource by 
      lazy { init_message_notification_title() }

  public val metric_units: StringResource by 
      lazy { init_metric_units() }

  public val mindfulness: StringResource by 
      lazy { init_mindfulness() }

  public val move_celebrity_description: StringResource by 
      lazy { init_move_celebrity_description() }

  public val move_celebrity_title: StringResource by 
      lazy { init_move_celebrity_title() }

  public val move_icebreaker_description: StringResource by 
      lazy { init_move_icebreaker_description() }

  public val move_icebreaker_title: StringResource by 
      lazy { init_move_icebreaker_title() }

  public val move_musical_gif_description: StringResource by 
      lazy { init_move_musical_gif_description() }

  public val move_musical_gif_title: StringResource by 
      lazy { init_move_musical_gif_title() }

  public val move_title: StringResource by 
      lazy { init_move_title() }

  public val music: StringResource by 
      lazy { init_music() }

  public val mystery: StringResource by 
      lazy { init_mystery() }

  public val nature: StringResource by 
      lazy { init_nature() }

  public val network_error: StringResource by 
      lazy { init_network_error() }

  public val neutral: StringResource by 
      lazy { init_neutral() }

  public val next: StringResource by 
      lazy { init_next() }

  public val no_matches_content: StringResource by 
      lazy { init_no_matches_content() }

  public val no_matches_title: StringResource by 
      lazy { init_no_matches_title() }

  public val nostalgia: StringResource by 
      lazy { init_nostalgia() }

  public val notification_for_match_rationale_message: StringResource by 
      lazy { init_notification_for_match_rationale_message() }

  public val notification_for_match_rationale_title: StringResource by 
      lazy { init_notification_for_match_rationale_title() }

  public val notification_for_message_rationale_message: StringResource by 
      lazy { init_notification_for_message_rationale_message() }

  public val notification_for_message_rationale_title: StringResource by 
      lazy { init_notification_for_message_rationale_title() }

  public val occupation_input: StringResource by 
      lazy { init_occupation_input() }

  public val open_mindedness: StringResource by 
      lazy { init_open_mindedness() }

  public val or: StringResource by 
      lazy { init_or() }

  public val party: StringResource by 
      lazy { init_party() }

  public val pause: StringResource by 
      lazy { init_pause() }

  public val permission_error: StringResource by 
      lazy { init_permission_error() }

  public val physical_chemistry: StringResource by 
      lazy { init_physical_chemistry() }

  public val pieces_of_match_description: StringResource by 
      lazy { init_pieces_of_match_description() }

  public val pieces_of_match_details: StringResource by 
      lazy { init_pieces_of_match_details() }

  public val pieces_of_match_name: StringResource by 
      lazy { init_pieces_of_match_name() }

  public val pieces_of_match_slogan: StringResource by 
      lazy { init_pieces_of_match_slogan() }

  public val play: StringResource by 
      lazy { init_play() }

  public val play_games: StringResource by 
      lazy { init_play_games() }

  public val preferenceAuthorQuote: StringResource by 
      lazy { init_preferenceAuthorQuote() }

  public val preferenceQuote: StringResource by 
      lazy { init_preferenceQuote() }

  public val previous: StringResource by 
      lazy { init_previous() }

  public val privacy_policy: StringResource by 
      lazy { init_privacy_policy() }

  public val problem_solving: StringResource by 
      lazy { init_problem_solving() }

  public val profileOption: StringResource by 
      lazy { init_profileOption() }

  public val puzzle_heart: StringResource by 
      lazy { init_puzzle_heart() }

  public val question_count: StringResource by 
      lazy { init_question_count() }

  public val question_intro_direction: StringResource by 
      lazy { init_question_intro_direction() }

  public val question_intro_preview_title: StringResource by 
      lazy { init_question_intro_preview_title() }

  public val question_intro_title: StringResource by 
      lazy { init_question_intro_title() }

  public val question_q1_direction: StringResource by 
      lazy { init_question_q1_direction() }

  public val question_q1_preview_title: StringResource by 
      lazy { init_question_q1_preview_title() }

  public val question_q1_title: StringResource by 
      lazy { init_question_q1_title() }

  public val read: StringResource by 
      lazy { init_read() }

  public val record_message: StringResource by 
      lazy { init_record_message() }

  public val reg_gender: StringResource by 
      lazy { init_reg_gender() }

  public val relax: StringResource by 
      lazy { init_relax() }

  public val remain_image_text: StringResource by 
      lazy { init_remain_image_text() }

  public val rematch: StringResource by 
      lazy { init_rematch() }

  public val remove_account: StringResource by 
      lazy { init_remove_account() }

  public val repeat: StringResource by 
      lazy { init_repeat() }

  public val retake_photo: StringResource by 
      lazy { init_retake_photo() }

  public val romance: StringResource by 
      lazy { init_romance() }

  public val sc_direction: StringResource by 
      lazy { init_sc_direction() }

  public val sc_q1_title: StringResource by 
      lazy { init_sc_q1_title() }

  public val sc_q2_title: StringResource by 
      lazy { init_sc_q2_title() }

  public val sc_q3_title: StringResource by 
      lazy { init_sc_q3_title() }

  public val sc_q4_title: StringResource by 
      lazy { init_sc_q4_title() }

  public val sc_q5_title: StringResource by 
      lazy { init_sc_q5_title() }

  public val sc_q6_title: StringResource by 
      lazy { init_sc_q6_title() }

  public val sc_q7_title: StringResource by 
      lazy { init_sc_q7_title() }

  public val see_less: StringResource by 
      lazy { init_see_less() }

  public val see_more: StringResource by 
      lazy { init_see_more() }

  public val select_all: StringResource by 
      lazy { init_select_all() }

  public val select_date: StringResource by 
      lazy { init_select_date() }

  public val select_one: StringResource by 
      lazy { init_select_one() }

  public val selfie_skills: StringResource by 
      lazy { init_selfie_skills() }

  public val selfies: StringResource by 
      lazy { init_selfies() }

  public val sense_of_adventure: StringResource by 
      lazy { init_sense_of_adventure() }

  public val sense_of_humor: StringResource by 
      lazy { init_sense_of_humor() }

  public val settings_account: StringResource by 
      lazy { init_settings_account() }

  public val settings_title: StringResource by 
      lazy { init_settings_title() }

  public val show_password: StringResource by 
      lazy { init_show_password() }

  public val sign_button_format: StringResource by 
      lazy { init_sign_button_format() }

  public val sign_in: StringResource by 
      lazy { init_sign_in() }

  public val sign_in_create_account: StringResource by 
      lazy { init_sign_in_create_account() }

  public val sign_in_guest: StringResource by 
      lazy { init_sign_in_guest() }

  public val slider_direction: StringResource by 
      lazy { init_slider_direction() }

  public val some_permissions_are_not_granted: StringResource by 
      lazy { init_some_permissions_are_not_granted() }

  public val spark_feed_title: StringResource by 
      lazy { init_spark_feed_title() }

  public val spark_ingredients_title: StringResource by 
      lazy { init_spark_ingredients_title() }

  public val spark_invitation_text: StringResource by 
      lazy { init_spark_invitation_text() }

  public val spontaneity: StringResource by 
      lazy { init_spontaneity() }

  public val sport: StringResource by 
      lazy { init_sport() }

  public val stage_title: StringResource by 
      lazy { init_stage_title() }

  public val strongly_dislike: StringResource by 
      lazy { init_strongly_dislike() }

  public val strongly_like: StringResource by 
      lazy { init_strongly_like() }

  public val survey_result_description: StringResource by 
      lazy { init_survey_result_description() }

  public val survey_result_subtitle: StringResource by 
      lazy { init_survey_result_subtitle() }

  public val survey_result_title: StringResource by 
      lazy { init_survey_result_title() }

  public val swipe_escape_description: StringResource by 
      lazy { init_swipe_escape_description() }

  public val swipe_escape_details: StringResource by 
      lazy { init_swipe_escape_details() }

  public val swipe_escape_name: StringResource by 
      lazy { init_swipe_escape_name() }

  public val swipe_escape_slogan: StringResource by 
      lazy { init_swipe_escape_slogan() }

  public val swipe_to_cancel_recording: StringResource by 
      lazy { init_swipe_to_cancel_recording() }

  public val tags: StringResource by 
      lazy { init_tags() }

  public val takeaway: StringResource by 
      lazy { init_takeaway() }

  public val term_of_service: StringResource by 
      lazy { init_term_of_service() }

  public val terms_and_conditions: StringResource by 
      lazy { init_terms_and_conditions() }

  public val textfield_hint: StringResource by 
      lazy { init_textfield_hint() }

  public val today: StringResource by 
      lazy { init_today() }

  public val touch_and_hold_to_record: StringResource by 
      lazy { init_touch_and_hold_to_record() }

  public val trivia: StringResource by 
      lazy { init_trivia() }

  public val true_matcher_name: StringResource by 
      lazy { init_true_matcher_name() }

  public val type_your_answer: StringResource by 
      lazy { init_type_your_answer() }

  public val unMatchOption: StringResource by 
      lazy { init_unMatchOption() }

  public val unexpected_error: StringResource by 
      lazy { init_unexpected_error() }

  public val unmatchOpening: StringResource by 
      lazy { init_unmatchOpening() }

  public val user_continue: StringResource by 
      lazy { init_user_continue() }

  public val waiting_for_other_player: StringResource by 
      lazy { init_waiting_for_other_player() }

  public val waiting_for_your_invitation_response: StringResource by 
      lazy { init_waiting_for_your_invitation_response() }

  public val waiting_for_your_match: StringResource by 
      lazy { init_waiting_for_your_match() }

  public val watch_movies: StringResource by 
      lazy { init_watch_movies() }

  public val who_wants_description: StringResource by 
      lazy { init_who_wants_description() }

  public val who_wants_details: StringResource by 
      lazy { init_who_wants_details() }

  public val who_wants_name: StringResource by 
      lazy { init_who_wants_name() }

  public val who_wants_slogan: StringResource by 
      lazy { init_who_wants_slogan() }

  public val win_sub_title: StringResource by 
      lazy { init_win_sub_title() }

  public val work_out: StringResource by 
      lazy { init_work_out() }

  public val yesterday: StringResource by 
      lazy { init_yesterday() }

  public val your_first_name_is: StringResource by 
      lazy { init_your_first_name_is() }

  public val your_last_name_is: StringResource by 
      lazy { init_your_last_name_is() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("Soon", CommonMainString0.Soon)
  map.put("add_image_text", CommonMainString0.add_image_text)
  map.put("add_photo", CommonMainString0.add_photo)
  map.put("adrenaline", CommonMainString0.adrenaline)
  map.put("adventure", CommonMainString0.adventure)
  map.put("advisor_header", CommonMainString0.advisor_header)
  map.put("age_range", CommonMainString0.age_range)
  map.put("app_name", CommonMainString0.app_name)
  map.put("apple_login", CommonMainString0.apple_login)
  map.put("art", CommonMainString0.art)
  map.put("auth_error", CommonMainString0.auth_error)
  map.put("back", CommonMainString0.back)
  map.put("balance_title", CommonMainString0.balance_title)
  map.put("birth_day_reg", CommonMainString0.birth_day_reg)
  map.put("blind_canvas_description", CommonMainString0.blind_canvas_description)
  map.put("blind_canvas_details", CommonMainString0.blind_canvas_details)
  map.put("blind_canvas_name", CommonMainString0.blind_canvas_name)
  map.put("blind_canvas_slogan", CommonMainString0.blind_canvas_slogan)
  map.put("brand_guidelines", CommonMainString0.brand_guidelines)
  map.put("challenge_accepted_description", CommonMainString0.challenge_accepted_description)
  map.put("challenge_accepted_details", CommonMainString0.challenge_accepted_details)
  map.put("challenge_accepted_instruction_for_other",
      CommonMainString0.challenge_accepted_instruction_for_other)
  map.put("challenge_accepted_instruction_for_player",
      CommonMainString0.challenge_accepted_instruction_for_player)
  map.put("challenge_accepted_name", CommonMainString0.challenge_accepted_name)
  map.put("challenge_accepted_slogan", CommonMainString0.challenge_accepted_slogan)
  map.put("check_list_description", CommonMainString0.check_list_description)
  map.put("check_list_details", CommonMainString0.check_list_details)
  map.put("check_list_name", CommonMainString0.check_list_name)
  map.put("check_list_slogan", CommonMainString0.check_list_slogan)
  map.put("city_input", CommonMainString0.city_input)
  map.put("close", CommonMainString0.close)
  map.put("collision_detected_body", CommonMainString0.collision_detected_body)
  map.put("collision_detected_title", CommonMainString0.collision_detected_title)
  map.put("communication_style", CommonMainString0.communication_style)
  map.put("confirm", CommonMainString0.confirm)
  map.put("confirm_password", CommonMainString0.confirm_password)
  map.put("create_account", CommonMainString0.create_account)
  map.put("creativity", CommonMainString0.creativity)
  map.put("culinary", CommonMainString0.culinary)
  map.put("curiosity", CommonMainString0.curiosity)
  map.put("dance", CommonMainString0.dance)
  map.put("date_gender", CommonMainString0.date_gender)
  map.put("decline_permission", CommonMainString0.decline_permission)
  map.put("default_web_client_id", CommonMainString0.default_web_client_id)
  map.put("detail_header", CommonMainString0.detail_header)
  map.put("direction_type", CommonMainString0.direction_type)
  map.put("dismiss", CommonMainString0.dismiss)
  map.put("distance_range", CommonMainString0.distance_range)
  map.put("done", CommonMainString0.done)
  map.put("draw", CommonMainString0.draw)
  map.put("emoji_description", CommonMainString0.emoji_description)
  map.put("emoji_name", CommonMainString0.emoji_name)
  map.put("emoji_resonance_description", CommonMainString0.emoji_resonance_description)
  map.put("emoji_resonance_details", CommonMainString0.emoji_resonance_details)
  map.put("emoji_resonance_name", CommonMainString0.emoji_resonance_name)
  map.put("emoji_resonance_slogan", CommonMainString0.emoji_resonance_slogan)
  map.put("emotional_connection", CommonMainString0.emotional_connection)
  map.put("enter_your_height", CommonMainString0.enter_your_height)
  map.put("entertainment_stage", CommonMainString0.entertainment_stage)
  map.put("error_title", CommonMainString0.error_title)
  map.put("exit_app", CommonMainString0.exit_app)
  map.put("expand_search", CommonMainString0.expand_search)
  map.put("facebook_app_id", CommonMainString0.facebook_app_id)
  map.put("facebook_client_token", CommonMainString0.facebook_client_token)
  map.put("facebook_login", CommonMainString0.facebook_login)
  map.put("fantasy", CommonMainString0.fantasy)
  map.put("fb_login_protocol_scheme", CommonMainString0.fb_login_protocol_scheme)
  map.put("feature_not_available", CommonMainString0.feature_not_available)
  map.put("feedback", CommonMainString0.feedback)
  map.put("financial_compatibility", CommonMainString0.financial_compatibility)
  map.put("finish_register_button_text", CommonMainString0.finish_register_button_text)
  map.put("forgot_password", CommonMainString0.forgot_password)
  map.put("funny", CommonMainString0.funny)
  map.put("gallery_rationale_message", CommonMainString0.gallery_rationale_message)
  map.put("gallery_rationale_title", CommonMainString0.gallery_rationale_title)
  map.put("game_detail_header", CommonMainString0.game_detail_header)
  map.put("game_feed_title", CommonMainString0.game_feed_title)
  map.put("game_ingredients_title", CommonMainString0.game_ingredients_title)
  map.put("game_invitation_text", CommonMainString0.game_invitation_text)
  map.put("game_match_loose_title", CommonMainString0.game_match_loose_title)
  map.put("game_match_win_title", CommonMainString0.game_match_win_title)
  map.put("game_over_sub_title_loose", CommonMainString0.game_over_sub_title_loose)
  map.put("game_over_sub_title_win", CommonMainString0.game_over_sub_title_win)
  map.put("game_over_title_loose", CommonMainString0.game_over_title_loose)
  map.put("game_over_title_win", CommonMainString0.game_over_title_win)
  map.put("go_to_match_zone", CommonMainString0.go_to_match_zone)
  map.put("google_login", CommonMainString0.google_login)
  map.put("harmony_tunes_description", CommonMainString0.harmony_tunes_description)
  map.put("harmony_tunes_details", CommonMainString0.harmony_tunes_details)
  map.put("harmony_tunes_instruction_for_other",
      CommonMainString0.harmony_tunes_instruction_for_other)
  map.put("harmony_tunes_instruction_for_player",
      CommonMainString0.harmony_tunes_instruction_for_player)
  map.put("harmony_tunes_name", CommonMainString0.harmony_tunes_name)
  map.put("harmony_tunes_slogan", CommonMainString0.harmony_tunes_slogan)
  map.put("hide_password", CommonMainString0.hide_password)
  map.put("hint", CommonMainString0.hint)
  map.put("i_m_ready", CommonMainString0.i_m_ready)
  map.put("in_my_free_time", CommonMainString0.in_my_free_time)
  map.put("in_pic_description", CommonMainString0.in_pic_description)
  map.put("in_pic_details", CommonMainString0.in_pic_details)
  map.put("in_pic_name", CommonMainString0.in_pic_name)
  map.put("in_pic_slogan", CommonMainString0.in_pic_slogan)
  map.put("intellectual_attraction", CommonMainString0.intellectual_attraction)
  map.put("intimacy", CommonMainString0.intimacy)
  map.put("invitation_notification_channel", CommonMainString0.invitation_notification_channel)
  map.put("invitation_notification_title", CommonMainString0.invitation_notification_title)
  map.put("invite_button_text", CommonMainString0.invite_button_text)
  map.put("invite_button_text_post", CommonMainString0.invite_button_text_post)
  map.put("invite_game_button", CommonMainString0.invite_game_button)
  map.put("its_match_description", CommonMainString0.its_match_description)
  map.put("its_match_string", CommonMainString0.its_match_string)
  map.put("janga_description", CommonMainString0.janga_description)
  map.put("janga_details", CommonMainString0.janga_details)
  map.put("janga_name", CommonMainString0.janga_name)
  map.put("janga_slogan", CommonMainString0.janga_slogan)
  map.put("jumpBottom", CommonMainString0.jumpBottom)
  map.put("kindness_and_warmth", CommonMainString0.kindness_and_warmth)
  map.put("leave_to_search", CommonMainString0.leave_to_search)
  map.put("licenses", CommonMainString0.licenses)
  map.put("lie_ai_description", CommonMainString0.lie_ai_description)
  map.put("lie_ai_details", CommonMainString0.lie_ai_details)
  map.put("lie_ai_name", CommonMainString0.lie_ai_name)
  map.put("lie_ai_slogan", CommonMainString0.lie_ai_slogan)
  map.put("loading", CommonMainString0.loading)
  map.put("location_error", CommonMainString0.location_error)
  map.put("location_not_supported", CommonMainString0.location_not_supported)
  map.put("location_preview", CommonMainString0.location_preview)
  map.put("location_rationale_message", CommonMainString0.location_rationale_message)
  map.put("location_rationale_title", CommonMainString0.location_rationale_title)
  map.put("lock_entertainment_money", CommonMainString0.lock_entertainment_money)
  map.put("log_out", CommonMainString0.log_out)
  map.put("loose_sub_title", CommonMainString0.loose_sub_title)
  map.put("match_notification_channel", CommonMainString0.match_notification_channel)
  map.put("match_notification_title", CommonMainString0.match_notification_title)
  map.put("matcher_ia_description", CommonMainString0.matcher_ia_description)
  map.put("matcher_ia_name", CommonMainString0.matcher_ia_name)
  map.put("matchmaker_picks", CommonMainString0.matchmaker_picks)
  map.put("memories", CommonMainString0.memories)
  map.put("message_notification_channel", CommonMainString0.message_notification_channel)
  map.put("message_notification_title", CommonMainString0.message_notification_title)
  map.put("metric_units", CommonMainString0.metric_units)
  map.put("mindfulness", CommonMainString0.mindfulness)
  map.put("move_celebrity_description", CommonMainString0.move_celebrity_description)
  map.put("move_celebrity_title", CommonMainString0.move_celebrity_title)
  map.put("move_icebreaker_description", CommonMainString0.move_icebreaker_description)
  map.put("move_icebreaker_title", CommonMainString0.move_icebreaker_title)
  map.put("move_musical_gif_description", CommonMainString0.move_musical_gif_description)
  map.put("move_musical_gif_title", CommonMainString0.move_musical_gif_title)
  map.put("move_title", CommonMainString0.move_title)
  map.put("music", CommonMainString0.music)
  map.put("mystery", CommonMainString0.mystery)
  map.put("nature", CommonMainString0.nature)
  map.put("network_error", CommonMainString0.network_error)
  map.put("neutral", CommonMainString0.neutral)
  map.put("next", CommonMainString0.next)
  map.put("no_matches_content", CommonMainString0.no_matches_content)
  map.put("no_matches_title", CommonMainString0.no_matches_title)
  map.put("nostalgia", CommonMainString0.nostalgia)
  map.put("notification_for_match_rationale_message",
      CommonMainString0.notification_for_match_rationale_message)
  map.put("notification_for_match_rationale_title",
      CommonMainString0.notification_for_match_rationale_title)
  map.put("notification_for_message_rationale_message",
      CommonMainString0.notification_for_message_rationale_message)
  map.put("notification_for_message_rationale_title",
      CommonMainString0.notification_for_message_rationale_title)
  map.put("occupation_input", CommonMainString0.occupation_input)
  map.put("open_mindedness", CommonMainString0.open_mindedness)
  map.put("or", CommonMainString0.or)
  map.put("party", CommonMainString0.party)
  map.put("pause", CommonMainString0.pause)
  map.put("permission_error", CommonMainString0.permission_error)
  map.put("physical_chemistry", CommonMainString0.physical_chemistry)
  map.put("pieces_of_match_description", CommonMainString0.pieces_of_match_description)
  map.put("pieces_of_match_details", CommonMainString0.pieces_of_match_details)
  map.put("pieces_of_match_name", CommonMainString0.pieces_of_match_name)
  map.put("pieces_of_match_slogan", CommonMainString0.pieces_of_match_slogan)
  map.put("play", CommonMainString0.play)
  map.put("play_games", CommonMainString0.play_games)
  map.put("preferenceAuthorQuote", CommonMainString0.preferenceAuthorQuote)
  map.put("preferenceQuote", CommonMainString0.preferenceQuote)
  map.put("previous", CommonMainString0.previous)
  map.put("privacy_policy", CommonMainString0.privacy_policy)
  map.put("problem_solving", CommonMainString0.problem_solving)
  map.put("profileOption", CommonMainString0.profileOption)
  map.put("puzzle_heart", CommonMainString0.puzzle_heart)
  map.put("question_count", CommonMainString0.question_count)
  map.put("question_intro_direction", CommonMainString0.question_intro_direction)
  map.put("question_intro_preview_title", CommonMainString0.question_intro_preview_title)
  map.put("question_intro_title", CommonMainString0.question_intro_title)
  map.put("question_q1_direction", CommonMainString0.question_q1_direction)
  map.put("question_q1_preview_title", CommonMainString0.question_q1_preview_title)
  map.put("question_q1_title", CommonMainString0.question_q1_title)
  map.put("read", CommonMainString0.read)
  map.put("record_message", CommonMainString0.record_message)
  map.put("reg_gender", CommonMainString0.reg_gender)
  map.put("relax", CommonMainString0.relax)
  map.put("remain_image_text", CommonMainString0.remain_image_text)
  map.put("rematch", CommonMainString0.rematch)
  map.put("remove_account", CommonMainString0.remove_account)
  map.put("repeat", CommonMainString0.repeat)
  map.put("retake_photo", CommonMainString0.retake_photo)
  map.put("romance", CommonMainString0.romance)
  map.put("sc_direction", CommonMainString0.sc_direction)
  map.put("sc_q1_title", CommonMainString0.sc_q1_title)
  map.put("sc_q2_title", CommonMainString0.sc_q2_title)
  map.put("sc_q3_title", CommonMainString0.sc_q3_title)
  map.put("sc_q4_title", CommonMainString0.sc_q4_title)
  map.put("sc_q5_title", CommonMainString0.sc_q5_title)
  map.put("sc_q6_title", CommonMainString0.sc_q6_title)
  map.put("sc_q7_title", CommonMainString0.sc_q7_title)
  map.put("see_less", CommonMainString0.see_less)
  map.put("see_more", CommonMainString0.see_more)
  map.put("select_all", CommonMainString0.select_all)
  map.put("select_date", CommonMainString0.select_date)
  map.put("select_one", CommonMainString0.select_one)
  map.put("selfie_skills", CommonMainString0.selfie_skills)
  map.put("selfies", CommonMainString0.selfies)
  map.put("sense_of_adventure", CommonMainString0.sense_of_adventure)
  map.put("sense_of_humor", CommonMainString0.sense_of_humor)
  map.put("settings_account", CommonMainString0.settings_account)
  map.put("settings_title", CommonMainString0.settings_title)
  map.put("show_password", CommonMainString0.show_password)
  map.put("sign_button_format", CommonMainString0.sign_button_format)
  map.put("sign_in", CommonMainString0.sign_in)
  map.put("sign_in_create_account", CommonMainString0.sign_in_create_account)
  map.put("sign_in_guest", CommonMainString0.sign_in_guest)
  map.put("slider_direction", CommonMainString0.slider_direction)
  map.put("some_permissions_are_not_granted", CommonMainString0.some_permissions_are_not_granted)
  map.put("spark_feed_title", CommonMainString0.spark_feed_title)
  map.put("spark_ingredients_title", CommonMainString0.spark_ingredients_title)
  map.put("spark_invitation_text", CommonMainString0.spark_invitation_text)
  map.put("spontaneity", CommonMainString0.spontaneity)
  map.put("sport", CommonMainString0.sport)
  map.put("stage_title", CommonMainString0.stage_title)
  map.put("strongly_dislike", CommonMainString0.strongly_dislike)
  map.put("strongly_like", CommonMainString0.strongly_like)
  map.put("survey_result_description", CommonMainString0.survey_result_description)
  map.put("survey_result_subtitle", CommonMainString0.survey_result_subtitle)
  map.put("survey_result_title", CommonMainString0.survey_result_title)
  map.put("swipe_escape_description", CommonMainString0.swipe_escape_description)
  map.put("swipe_escape_details", CommonMainString0.swipe_escape_details)
  map.put("swipe_escape_name", CommonMainString0.swipe_escape_name)
  map.put("swipe_escape_slogan", CommonMainString0.swipe_escape_slogan)
  map.put("swipe_to_cancel_recording", CommonMainString0.swipe_to_cancel_recording)
  map.put("tags", CommonMainString0.tags)
  map.put("takeaway", CommonMainString0.takeaway)
  map.put("term_of_service", CommonMainString0.term_of_service)
  map.put("terms_and_conditions", CommonMainString0.terms_and_conditions)
  map.put("textfield_hint", CommonMainString0.textfield_hint)
  map.put("today", CommonMainString0.today)
  map.put("touch_and_hold_to_record", CommonMainString0.touch_and_hold_to_record)
  map.put("trivia", CommonMainString0.trivia)
  map.put("true_matcher_name", CommonMainString0.true_matcher_name)
  map.put("type_your_answer", CommonMainString0.type_your_answer)
  map.put("unMatchOption", CommonMainString0.unMatchOption)
  map.put("unexpected_error", CommonMainString0.unexpected_error)
  map.put("unmatchOpening", CommonMainString0.unmatchOpening)
  map.put("user_continue", CommonMainString0.user_continue)
  map.put("waiting_for_other_player", CommonMainString0.waiting_for_other_player)
  map.put("waiting_for_your_invitation_response",
      CommonMainString0.waiting_for_your_invitation_response)
  map.put("waiting_for_your_match", CommonMainString0.waiting_for_your_match)
  map.put("watch_movies", CommonMainString0.watch_movies)
  map.put("who_wants_description", CommonMainString0.who_wants_description)
  map.put("who_wants_details", CommonMainString0.who_wants_details)
  map.put("who_wants_name", CommonMainString0.who_wants_name)
  map.put("who_wants_slogan", CommonMainString0.who_wants_slogan)
  map.put("win_sub_title", CommonMainString0.win_sub_title)
  map.put("work_out", CommonMainString0.work_out)
  map.put("yesterday", CommonMainString0.yesterday)
  map.put("your_first_name_is", CommonMainString0.your_first_name_is)
  map.put("your_last_name_is", CommonMainString0.your_last_name_is)
}

internal val Res.string.Soon: StringResource
  get() = CommonMainString0.Soon

private fun init_Soon(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Soon", "Soon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/entertainmemt.commonMain.cvr", 10,
    20),
    )
)

internal val Res.string.add_image_text: StringResource
  get() = CommonMainString0.add_image_text

private fun init_add_image_text(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_image_text", "add_image_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 49, 54),
    )
)

internal val Res.string.add_photo: StringResource
  get() = CommonMainString0.add_photo

private fun init_add_photo(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_photo", "add_photo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 104,
    29),
    )
)

internal val Res.string.adrenaline: StringResource
  get() = CommonMainString0.adrenaline

private fun init_adrenaline(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:adrenaline", "adrenaline",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    10, 34),
    )
)

internal val Res.string.adventure: StringResource
  get() = CommonMainString0.adventure

private fun init_adventure(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:adventure", "adventure",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    45, 29),
    )
)

internal val Res.string.advisor_header: StringResource
  get() = CommonMainString0.advisor_header

private fun init_advisor_header(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:advisor_header", "advisor_header",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 10, 38),
    )
)

internal val Res.string.age_range: StringResource
  get() = CommonMainString0.age_range

private fun init_age_range(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:age_range", "age_range",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 134,
    81),
    )
)

internal val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 49, 28),
    )
)

internal val Res.string.apple_login: StringResource
  get() = CommonMainString0.apple_login

private fun init_apple_login(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:apple_login", "apple_login",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 78, 27),
    )
)

internal val Res.string.art: StringResource
  get() = CommonMainString0.art

private fun init_art(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:art", "art",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    75, 15),
    )
)

internal val Res.string.auth_error: StringResource
  get() = CommonMainString0.auth_error

private fun init_auth_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:auth_error", "auth_error",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/error_strings.commonMain.cvr", 10,
    78),
    )
)

internal val Res.string.back: StringResource
  get() = CommonMainString0.back

private fun init_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:back", "back",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 216,
    20),
    )
)

internal val Res.string.balance_title: StringResource
  get() = CommonMainString0.balance_title

private fun init_balance_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:balance_title", "balance_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 237,
    41),
    )
)

internal val Res.string.birth_day_reg: StringResource
  get() = CommonMainString0.birth_day_reg

private fun init_birth_day_reg(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:birth_day_reg", "birth_day_reg",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 106, 85),
    )
)

internal val Res.string.blind_canvas_description: StringResource
  get() = CommonMainString0.blind_canvas_description

private fun init_blind_canvas_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:blind_canvas_description", "blind_canvas_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/blind_canvas.commonMain.cvr", 10,
    68),
    )
)

internal val Res.string.blind_canvas_details: StringResource
  get() = CommonMainString0.blind_canvas_details

private fun init_blind_canvas_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:blind_canvas_details", "blind_canvas_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/blind_canvas.commonMain.cvr", 79,
    148),
    )
)

internal val Res.string.blind_canvas_name: StringResource
  get() = CommonMainString0.blind_canvas_name

private fun init_blind_canvas_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:blind_canvas_name", "blind_canvas_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/blind_canvas.commonMain.cvr", 228,
    41),
    )
)

internal val Res.string.blind_canvas_slogan: StringResource
  get() = CommonMainString0.blind_canvas_slogan

private fun init_blind_canvas_slogan(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:blind_canvas_slogan", "blind_canvas_slogan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/blind_canvas.commonMain.cvr", 270,
    35),
    )
)

internal val Res.string.brand_guidelines: StringResource
  get() = CommonMainString0.brand_guidelines

private fun init_brand_guidelines(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:brand_guidelines", "brand_guidelines",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 192, 48),
    )
)

internal val Res.string.challenge_accepted_description: StringResource
  get() = CommonMainString0.challenge_accepted_description

private fun init_challenge_accepted_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:challenge_accepted_description", "challenge_accepted_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/challenge_accepted.commonMain.cvr",
    10, 206),
    )
)

internal val Res.string.challenge_accepted_details: StringResource
  get() = CommonMainString0.challenge_accepted_details

private fun init_challenge_accepted_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:challenge_accepted_details", "challenge_accepted_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/challenge_accepted.commonMain.cvr",
    217, 354),
    )
)

internal val Res.string.challenge_accepted_instruction_for_other: StringResource
  get() = CommonMainString0.challenge_accepted_instruction_for_other

private fun init_challenge_accepted_instruction_for_other(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:challenge_accepted_instruction_for_other", "challenge_accepted_instruction_for_other",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/challenge_accepted_app.commonMain.cvr",
    10, 92),
    )
)

internal val Res.string.challenge_accepted_instruction_for_player: StringResource
  get() = CommonMainString0.challenge_accepted_instruction_for_player

private fun init_challenge_accepted_instruction_for_player(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:challenge_accepted_instruction_for_player", "challenge_accepted_instruction_for_player",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/challenge_accepted_app.commonMain.cvr",
    103, 89),
    )
)

internal val Res.string.challenge_accepted_name: StringResource
  get() = CommonMainString0.challenge_accepted_name

private fun init_challenge_accepted_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:challenge_accepted_name", "challenge_accepted_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/challenge_accepted.commonMain.cvr",
    572, 55),
    )
)

internal val Res.string.challenge_accepted_slogan: StringResource
  get() = CommonMainString0.challenge_accepted_slogan

private fun init_challenge_accepted_slogan(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:challenge_accepted_slogan", "challenge_accepted_slogan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/challenge_accepted.commonMain.cvr",
    628, 65),
    )
)

internal val Res.string.check_list_description: StringResource
  get() = CommonMainString0.check_list_description

private fun init_check_list_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:check_list_description", "check_list_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/check_list.commonMain.cvr", 10,
    70),
    )
)

internal val Res.string.check_list_details: StringResource
  get() = CommonMainString0.check_list_details

private fun init_check_list_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:check_list_details", "check_list_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/check_list.commonMain.cvr", 81,
    146),
    )
)

internal val Res.string.check_list_name: StringResource
  get() = CommonMainString0.check_list_name

private fun init_check_list_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:check_list_name", "check_list_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/check_list.commonMain.cvr", 228,
    39),
    )
)

internal val Res.string.check_list_slogan: StringResource
  get() = CommonMainString0.check_list_slogan

private fun init_check_list_slogan(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:check_list_slogan", "check_list_slogan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/check_list.commonMain.cvr", 268,
    41),
    )
)

internal val Res.string.city_input: StringResource
  get() = CommonMainString0.city_input

private fun init_city_input(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:city_input", "city_input",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 279,
    54),
    )
)

internal val Res.string.close: StringResource
  get() = CommonMainString0.close

private fun init_close(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:close", "close",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 334,
    21),
    )
)

internal val Res.string.collision_detected_body: StringResource
  get() = CommonMainString0.collision_detected_body

private fun init_collision_detected_body(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:collision_detected_body", "collision_detected_body",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/common_directions.commonMain.cvr",
    10, 119),
    )
)

internal val Res.string.collision_detected_title: StringResource
  get() = CommonMainString0.collision_detected_title

private fun init_collision_detected_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:collision_detected_title", "collision_detected_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/common_directions.commonMain.cvr",
    130, 56),
    )
)

internal val Res.string.communication_style: StringResource
  get() = CommonMainString0.communication_style

private fun init_communication_style(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:communication_style", "communication_style",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_interest.commonMain.cvr",
    10, 55),
    )
)

internal val Res.string.confirm: StringResource
  get() = CommonMainString0.confirm

private fun init_confirm(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:confirm", "confirm",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 241, 27),
    )
)

internal val Res.string.confirm_password: StringResource
  get() = CommonMainString0.confirm_password

private fun init_confirm_password(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_password", "confirm_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 356,
    48),
    )
)

internal val Res.string.create_account: StringResource
  get() = CommonMainString0.create_account

private fun init_create_account(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:create_account", "create_account",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 405,
    42),
    )
)

internal val Res.string.creativity: StringResource
  get() = CommonMainString0.creativity

private fun init_creativity(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:creativity", "creativity",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_interest.commonMain.cvr",
    66, 34),
    )
)

internal val Res.string.culinary: StringResource
  get() = CommonMainString0.culinary

private fun init_culinary(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:culinary", "culinary",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    91, 28),
    )
)

internal val Res.string.curiosity: StringResource
  get() = CommonMainString0.curiosity

private fun init_curiosity(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:curiosity", "curiosity",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_interest.commonMain.cvr",
    101, 29),
    )
)

internal val Res.string.dance: StringResource
  get() = CommonMainString0.dance

private fun init_dance(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dance", "dance",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 448,
    21),
    )
)

internal val Res.string.date_gender: StringResource
  get() = CommonMainString0.date_gender

private fun init_date_gender(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:date_gender", "date_gender",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 470,
    75),
    )
)

internal val Res.string.decline_permission: StringResource
  get() = CommonMainString0.decline_permission

private fun init_decline_permission(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:decline_permission", "decline_permission",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/permission_rationale_strings.commonMain.cvr",
    10, 38),
    )
)

internal val Res.string.default_web_client_id: StringResource
  get() = CommonMainString0.default_web_client_id

private fun init_default_web_client_id(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:default_web_client_id", "default_web_client_id",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 269,
    125),
    )
)

internal val Res.string.detail_header: StringResource
  get() = CommonMainString0.detail_header

private fun init_detail_header(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:detail_header", "detail_header",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 395, 33),
    )
)

internal val Res.string.direction_type: StringResource
  get() = CommonMainString0.direction_type

private fun init_direction_type(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:direction_type", "direction_type",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 546,
    50),
    )
)

internal val Res.string.dismiss: StringResource
  get() = CommonMainString0.dismiss

private fun init_dismiss(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dismiss", "dismiss",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 597,
    27),
    )
)

internal val Res.string.distance_range: StringResource
  get() = CommonMainString0.distance_range

private fun init_distance_range(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:distance_range", "distance_range",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 625,
    90),
    )
)

internal val Res.string.done: StringResource
  get() = CommonMainString0.done

private fun init_done(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:done", "done",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 716,
    20),
    )
)

internal val Res.string.draw: StringResource
  get() = CommonMainString0.draw

private fun init_draw(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:draw", "draw",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 737,
    20),
    )
)

internal val Res.string.emoji_description: StringResource
  get() = CommonMainString0.emoji_description

private fun init_emoji_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:emoji_description", "emoji_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/emoji.commonMain.cvr", 10, 121),
    )
)

internal val Res.string.emoji_name: StringResource
  get() = CommonMainString0.emoji_name

private fun init_emoji_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:emoji_name", "emoji_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/emoji.commonMain.cvr", 132, 38),
    )
)

internal val Res.string.emoji_resonance_description: StringResource
  get() = CommonMainString0.emoji_resonance_description

private fun init_emoji_resonance_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:emoji_resonance_description", "emoji_resonance_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/emoji_resonance.commonMain.cvr",
    10, 131),
    )
)

internal val Res.string.emoji_resonance_details: StringResource
  get() = CommonMainString0.emoji_resonance_details

private fun init_emoji_resonance_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:emoji_resonance_details", "emoji_resonance_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/emoji_resonance.commonMain.cvr",
    142, 431),
    )
)

internal val Res.string.emoji_resonance_name: StringResource
  get() = CommonMainString0.emoji_resonance_name

private fun init_emoji_resonance_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:emoji_resonance_name", "emoji_resonance_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/emoji_resonance.commonMain.cvr",
    574, 48),
    )
)

internal val Res.string.emoji_resonance_slogan: StringResource
  get() = CommonMainString0.emoji_resonance_slogan

private fun init_emoji_resonance_slogan(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:emoji_resonance_slogan", "emoji_resonance_slogan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/emoji_resonance.commonMain.cvr",
    623, 78),
    )
)

internal val Res.string.emotional_connection: StringResource
  get() = CommonMainString0.emotional_connection

private fun init_emotional_connection(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:emotional_connection", "emotional_connection",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_interest.commonMain.cvr",
    131, 56),
    )
)

internal val Res.string.enter_your_height: StringResource
  get() = CommonMainString0.enter_your_height

private fun init_enter_your_height(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_your_height", "enter_your_height",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 758,
    49),
    )
)

internal val Res.string.entertainment_stage: StringResource
  get() = CommonMainString0.entertainment_stage

private fun init_entertainment_stage(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:entertainment_stage", "entertainment_stage",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 429, 39),
    )
)

internal val Res.string.error_title: StringResource
  get() = CommonMainString0.error_title

private fun init_error_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:error_title", "error_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/error_strings.commonMain.cvr", 89,
    51),
    )
)

internal val Res.string.exit_app: StringResource
  get() = CommonMainString0.exit_app

private fun init_exit_app(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:exit_app", "exit_app",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/error_strings.commonMain.cvr",
    141, 24),
    )
)

internal val Res.string.expand_search: StringResource
  get() = CommonMainString0.expand_search

private fun init_expand_search(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:expand_search", "expand_search",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 10, 57),
    )
)

internal val Res.string.facebook_app_id: StringResource
  get() = CommonMainString0.facebook_app_id

private fun init_facebook_app_id(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:facebook_app_id", "facebook_app_id",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 469, 43),
    )
)

internal val Res.string.facebook_client_token: StringResource
  get() = CommonMainString0.facebook_client_token

private fun init_facebook_client_token(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:facebook_client_token", "facebook_client_token",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 513, 73),
    )
)

internal val Res.string.facebook_login: StringResource
  get() = CommonMainString0.facebook_login

private fun init_facebook_login(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:facebook_login", "facebook_login",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 587, 34),
    )
)

internal val Res.string.fantasy: StringResource
  get() = CommonMainString0.fantasy

private fun init_fantasy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fantasy", "fantasy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    120, 27),
    )
)

internal val Res.string.fb_login_protocol_scheme: StringResource
  get() = CommonMainString0.fb_login_protocol_scheme

private fun init_fb_login_protocol_scheme(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fb_login_protocol_scheme", "fb_login_protocol_scheme",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 622, 40),
    )
)

internal val Res.string.feature_not_available: StringResource
  get() = CommonMainString0.feature_not_available

private fun init_feature_not_available(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:feature_not_available", "feature_not_available",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 808,
    57),
    )
)

internal val Res.string.feedback: StringResource
  get() = CommonMainString0.feedback

private fun init_feedback(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:feedback", "feedback",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 663, 28),
    )
)

internal val Res.string.financial_compatibility: StringResource
  get() = CommonMainString0.financial_compatibility

private fun init_financial_compatibility(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:financial_compatibility", "financial_compatibility",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_interest.commonMain.cvr",
    188, 63),
    )
)

internal val Res.string.finish_register_button_text: StringResource
  get() = CommonMainString0.finish_register_button_text

private fun init_finish_register_button_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:finish_register_button_text", "finish_register_button_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 866,
    59),
    )
)

internal val Res.string.forgot_password: StringResource
  get() = CommonMainString0.forgot_password

private fun init_forgot_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:forgot_password", "forgot_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 926,
    47),
    )
)

internal val Res.string.funny: StringResource
  get() = CommonMainString0.funny

private fun init_funny(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:funny", "funny",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    148, 21),
    )
)

internal val Res.string.gallery_rationale_message: StringResource
  get() = CommonMainString0.gallery_rationale_message

private fun init_gallery_rationale_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gallery_rationale_message", "gallery_rationale_message",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/permission_rationale_strings.commonMain.cvr",
    49, 97),
    )
)

internal val Res.string.gallery_rationale_title: StringResource
  get() = CommonMainString0.gallery_rationale_title

private fun init_gallery_rationale_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gallery_rationale_title", "gallery_rationale_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/permission_rationale_strings.commonMain.cvr",
    147, 63),
    )
)

internal val Res.string.game_detail_header: StringResource
  get() = CommonMainString0.game_detail_header

private fun init_game_detail_header(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_detail_header", "game_detail_header",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 692, 38),
    )
)

internal val Res.string.game_feed_title: StringResource
  get() = CommonMainString0.game_feed_title

private fun init_game_feed_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:game_feed_title", "game_feed_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 731, 39),
    )
)

internal val Res.string.game_ingredients_title: StringResource
  get() = CommonMainString0.game_ingredients_title

private fun init_game_ingredients_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_ingredients_title", "game_ingredients_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 771, 58),
    )
)

internal val Res.string.game_invitation_text: StringResource
  get() = CommonMainString0.game_invitation_text

private fun init_game_invitation_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_invitation_text", "game_invitation_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 68, 104),
    )
)

internal val Res.string.game_match_loose_title: StringResource
  get() = CommonMainString0.game_match_loose_title

private fun init_game_match_loose_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_match_loose_title", "game_match_loose_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/ice_breakers.commonMain.cvr", 10,
    66),
    )
)

internal val Res.string.game_match_win_title: StringResource
  get() = CommonMainString0.game_match_win_title

private fun init_game_match_win_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_match_win_title", "game_match_win_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/ice_breakers.commonMain.cvr", 77,
    64),
    )
)

internal val Res.string.game_over_sub_title_loose: StringResource
  get() = CommonMainString0.game_over_sub_title_loose

private fun init_game_over_sub_title_loose(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_over_sub_title_loose", "game_over_sub_title_loose",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/ice_breakers.commonMain.cvr", 142,
    117),
    )
)

internal val Res.string.game_over_sub_title_win: StringResource
  get() = CommonMainString0.game_over_sub_title_win

private fun init_game_over_sub_title_win(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_over_sub_title_win", "game_over_sub_title_win",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/ice_breakers.commonMain.cvr", 260,
    71),
    )
)

internal val Res.string.game_over_title_loose: StringResource
  get() = CommonMainString0.game_over_title_loose

private fun init_game_over_title_loose(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_over_title_loose", "game_over_title_loose",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/ice_breakers.commonMain.cvr", 332,
    57),
    )
)

internal val Res.string.game_over_title_win: StringResource
  get() = CommonMainString0.game_over_title_win

private fun init_game_over_title_win(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_over_title_win", "game_over_title_win",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/ice_breakers.commonMain.cvr", 390,
    67),
    )
)

internal val Res.string.go_to_match_zone: StringResource
  get() = CommonMainString0.go_to_match_zone

private fun init_go_to_match_zone(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:go_to_match_zone", "go_to_match_zone",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 173, 56),
    )
)

internal val Res.string.google_login: StringResource
  get() = CommonMainString0.google_login

private fun init_google_login(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:google_login", "google_login",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 830, 28),
    )
)

internal val Res.string.harmony_tunes_description: StringResource
  get() = CommonMainString0.harmony_tunes_description

private fun init_harmony_tunes_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:harmony_tunes_description", "harmony_tunes_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/harmony_tunes.commonMain.cvr", 10,
    165),
    )
)

internal val Res.string.harmony_tunes_details: StringResource
  get() = CommonMainString0.harmony_tunes_details

private fun init_harmony_tunes_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:harmony_tunes_details", "harmony_tunes_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/harmony_tunes.commonMain.cvr",
    176, 429),
    )
)

internal val Res.string.harmony_tunes_instruction_for_other: StringResource
  get() = CommonMainString0.harmony_tunes_instruction_for_other

private fun init_harmony_tunes_instruction_for_other(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:harmony_tunes_instruction_for_other", "harmony_tunes_instruction_for_other",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/harmony_tunes_app.commonMain.cvr",
    10, 91),
    )
)

internal val Res.string.harmony_tunes_instruction_for_player: StringResource
  get() = CommonMainString0.harmony_tunes_instruction_for_player

private fun init_harmony_tunes_instruction_for_player(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:harmony_tunes_instruction_for_player", "harmony_tunes_instruction_for_player",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/harmony_tunes_app.commonMain.cvr",
    102, 92),
    )
)

internal val Res.string.harmony_tunes_name: StringResource
  get() = CommonMainString0.harmony_tunes_name

private fun init_harmony_tunes_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:harmony_tunes_name", "harmony_tunes_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/harmony_tunes.commonMain.cvr",
    606, 46),
    )
)

internal val Res.string.harmony_tunes_slogan: StringResource
  get() = CommonMainString0.harmony_tunes_slogan

private fun init_harmony_tunes_slogan(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:harmony_tunes_slogan", "harmony_tunes_slogan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/harmony_tunes.commonMain.cvr",
    653, 56),
    )
)

internal val Res.string.hide_password: StringResource
  get() = CommonMainString0.hide_password

private fun init_hide_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:hide_password", "hide_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 974,
    41),
    )
)

internal val Res.string.hint: StringResource
  get() = CommonMainString0.hint

private fun init_hint(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:hint", "hint",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/harmony_tunes_app.commonMain.cvr",
    195, 20),
    )
)

internal val Res.string.i_m_ready: StringResource
  get() = CommonMainString0.i_m_ready

private fun init_i_m_ready(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:i_m_ready", "i_m_ready",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 859, 33),
    )
)

internal val Res.string.in_my_free_time: StringResource
  get() = CommonMainString0.in_my_free_time

private fun init_in_my_free_time(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:in_my_free_time", "in_my_free_time",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1016,
    63),
    )
)

internal val Res.string.in_pic_description: StringResource
  get() = CommonMainString0.in_pic_description

private fun init_in_pic_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:in_pic_description", "in_pic_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/in_pic.commonMain.cvr", 10, 82),
    )
)

internal val Res.string.in_pic_details: StringResource
  get() = CommonMainString0.in_pic_details

private fun init_in_pic_details(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:in_pic_details", "in_pic_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/in_pic.commonMain.cvr", 93, 182),
    )
)

internal val Res.string.in_pic_name: StringResource
  get() = CommonMainString0.in_pic_name

private fun init_in_pic_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:in_pic_name", "in_pic_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/in_pic.commonMain.cvr", 276, 47),
    )
)

internal val Res.string.in_pic_slogan: StringResource
  get() = CommonMainString0.in_pic_slogan

private fun init_in_pic_slogan(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:in_pic_slogan", "in_pic_slogan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/in_pic.commonMain.cvr", 324, 49),
    )
)

internal val Res.string.intellectual_attraction: StringResource
  get() = CommonMainString0.intellectual_attraction

private fun init_intellectual_attraction(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:intellectual_attraction", "intellectual_attraction",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_interest.commonMain.cvr",
    252, 63),
    )
)

internal val Res.string.intimacy: StringResource
  get() = CommonMainString0.intimacy

private fun init_intimacy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:intimacy", "intimacy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    170, 28),
    )
)

internal val Res.string.invitation_notification_channel: StringResource
  get() = CommonMainString0.invitation_notification_channel

private fun init_invitation_notification_channel(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:invitation_notification_channel", "invitation_notification_channel",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 893, 55),
    )
)

internal val Res.string.invitation_notification_title: StringResource
  get() = CommonMainString0.invitation_notification_title

private fun init_invitation_notification_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:invitation_notification_title", "invitation_notification_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 949, 85),
    )
)

internal val Res.string.invite_button_text: StringResource
  get() = CommonMainString0.invite_button_text

private fun init_invite_button_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:invite_button_text", "invite_button_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 274, 34),
    )
)

internal val Res.string.invite_button_text_post: StringResource
  get() = CommonMainString0.invite_button_text_post

private fun init_invite_button_text_post(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:invite_button_text_post", "invite_button_text_post",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 230, 43),
    )
)

internal val Res.string.invite_game_button: StringResource
  get() = CommonMainString0.invite_game_button

private fun init_invite_game_button(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:invite_game_button", "invite_game_button",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/ice_breakers.commonMain.cvr", 458,
    34),
    )
)

internal val Res.string.its_match_description: StringResource
  get() = CommonMainString0.its_match_description

private fun init_its_match_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:its_match_description", "its_match_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 309, 69),
    )
)

internal val Res.string.its_match_string: StringResource
  get() = CommonMainString0.its_match_string

private fun init_its_match_string(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:its_match_string", "its_match_string",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 379, 44),
    )
)

internal val Res.string.janga_description: StringResource
  get() = CommonMainString0.janga_description

private fun init_janga_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:janga_description", "janga_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/janga.commonMain.cvr", 10, 93),
    )
)

internal val Res.string.janga_details: StringResource
  get() = CommonMainString0.janga_details

private fun init_janga_details(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:janga_details", "janga_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/janga.commonMain.cvr", 104, 333),
    )
)

internal val Res.string.janga_name: StringResource
  get() = CommonMainString0.janga_name

private fun init_janga_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:janga_name", "janga_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/janga.commonMain.cvr", 438, 26),
    )
)

internal val Res.string.janga_slogan: StringResource
  get() = CommonMainString0.janga_slogan

private fun init_janga_slogan(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:janga_slogan", "janga_slogan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/janga.commonMain.cvr", 465, 68),
    )
)

internal val Res.string.jumpBottom: StringResource
  get() = CommonMainString0.jumpBottom

private fun init_jumpBottom(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:jumpBottom", "jumpBottom",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1035,
    38),
    )
)

internal val Res.string.kindness_and_warmth: StringResource
  get() = CommonMainString0.kindness_and_warmth

private fun init_kindness_and_warmth(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:kindness_and_warmth", "kindness_and_warmth",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_interest.commonMain.cvr",
    316, 55),
    )
)

internal val Res.string.leave_to_search: StringResource
  get() = CommonMainString0.leave_to_search

private fun init_leave_to_search(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:leave_to_search", "leave_to_search",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 424, 35),
    )
)

internal val Res.string.licenses: StringResource
  get() = CommonMainString0.licenses

private fun init_licenses(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:licenses", "licenses",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1074,
    28),
    )
)

internal val Res.string.lie_ai_description: StringResource
  get() = CommonMainString0.lie_ai_description

private fun init_lie_ai_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:lie_ai_description", "lie_ai_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/lie_ai.commonMain.cvr", 10, 114),
    )
)

internal val Res.string.lie_ai_details: StringResource
  get() = CommonMainString0.lie_ai_details

private fun init_lie_ai_details(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:lie_ai_details", "lie_ai_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/lie_ai.commonMain.cvr", 125, 370),
    )
)

internal val Res.string.lie_ai_name: StringResource
  get() = CommonMainString0.lie_ai_name

private fun init_lie_ai_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:lie_ai_name", "lie_ai_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/lie_ai.commonMain.cvr", 496, 51),
    )
)

internal val Res.string.lie_ai_slogan: StringResource
  get() = CommonMainString0.lie_ai_slogan

private fun init_lie_ai_slogan(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:lie_ai_slogan", "lie_ai_slogan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/lie_ai.commonMain.cvr", 548, 61),
    )
)

internal val Res.string.loading: StringResource
  get() = CommonMainString0.loading

private fun init_loading(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:loading", "loading",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1103,
    31),
    )
)

internal val Res.string.location_error: StringResource
  get() = CommonMainString0.location_error

private fun init_location_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:location_error", "location_error",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/error_strings.commonMain.cvr",
    166, 74),
    )
)

internal val Res.string.location_not_supported: StringResource
  get() = CommonMainString0.location_not_supported

private fun init_location_not_supported(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:location_not_supported", "location_not_supported",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/error_strings.commonMain.cvr",
    241, 78),
    )
)

internal val Res.string.location_preview: StringResource
  get() = CommonMainString0.location_preview

private fun init_location_preview(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:location_preview", "location_preview",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/search.commonMain.cvr", 10, 40),
    )
)

internal val Res.string.location_rationale_message: StringResource
  get() = CommonMainString0.location_rationale_message

private fun init_location_rationale_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:location_rationale_message", "location_rationale_message",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/permission_rationale_strings.commonMain.cvr",
    211, 162),
    )
)

internal val Res.string.location_rationale_title: StringResource
  get() = CommonMainString0.location_rationale_title

private fun init_location_rationale_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:location_rationale_title", "location_rationale_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/permission_rationale_strings.commonMain.cvr",
    374, 80),
    )
)

internal val Res.string.lock_entertainment_money: StringResource
  get() = CommonMainString0.lock_entertainment_money

private fun init_lock_entertainment_money(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:lock_entertainment_money", "lock_entertainment_money",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1135,
    36),
    )
)

internal val Res.string.log_out: StringResource
  get() = CommonMainString0.log_out

private fun init_log_out(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:log_out", "log_out",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1172,
    23),
    )
)

internal val Res.string.loose_sub_title: StringResource
  get() = CommonMainString0.loose_sub_title

private fun init_loose_sub_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:loose_sub_title", "loose_sub_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/ice_breakers.commonMain.cvr", 493,
    127),
    )
)

internal val Res.string.match_notification_channel: StringResource
  get() = CommonMainString0.match_notification_channel

private fun init_match_notification_channel(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:match_notification_channel", "match_notification_channel",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1196,
    42),
    )
)

internal val Res.string.match_notification_title: StringResource
  get() = CommonMainString0.match_notification_title

private fun init_match_notification_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:match_notification_title", "match_notification_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1239,
    84),
    )
)

internal val Res.string.matcher_ia_description: StringResource
  get() = CommonMainString0.matcher_ia_description

private fun init_matcher_ia_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:matcher_ia_description", "matcher_ia_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 460, 66),
    )
)

internal val Res.string.matcher_ia_name: StringResource
  get() = CommonMainString0.matcher_ia_name

private fun init_matcher_ia_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:matcher_ia_name", "matcher_ia_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 527, 39),
    )
)

internal val Res.string.matchmaker_picks: StringResource
  get() = CommonMainString0.matchmaker_picks

private fun init_matchmaker_picks(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:matchmaker_picks", "matchmaker_picks",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/entertainmemt.commonMain.cvr", 31,
    52),
    )
)

internal val Res.string.memories: StringResource
  get() = CommonMainString0.memories

private fun init_memories(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:memories", "memories",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    199, 28),
    )
)

internal val Res.string.message_notification_channel: StringResource
  get() = CommonMainString0.message_notification_channel

private fun init_message_notification_channel(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:message_notification_channel", "message_notification_channel",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1324,
    48),
    )
)

internal val Res.string.message_notification_title: StringResource
  get() = CommonMainString0.message_notification_title

private fun init_message_notification_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:message_notification_title", "message_notification_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1373,
    78),
    )
)

internal val Res.string.metric_units: StringResource
  get() = CommonMainString0.metric_units

private fun init_metric_units(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:metric_units", "metric_units",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1080,
    28),
    )
)

internal val Res.string.mindfulness: StringResource
  get() = CommonMainString0.mindfulness

private fun init_mindfulness(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:mindfulness", "mindfulness",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    228, 35),
    )
)

internal val Res.string.move_celebrity_description: StringResource
  get() = CommonMainString0.move_celebrity_description

private fun init_move_celebrity_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:move_celebrity_description", "move_celebrity_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/spark_move.commonMain.cvr", 10,
    166),
    )
)

internal val Res.string.move_celebrity_title: StringResource
  get() = CommonMainString0.move_celebrity_title

private fun init_move_celebrity_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:move_celebrity_title", "move_celebrity_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/spark_move.commonMain.cvr", 177,
    48),
    )
)

internal val Res.string.move_icebreaker_description: StringResource
  get() = CommonMainString0.move_icebreaker_description

private fun init_move_icebreaker_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:move_icebreaker_description", "move_icebreaker_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/spark_move.commonMain.cvr", 226,
    135),
    )
)

internal val Res.string.move_icebreaker_title: StringResource
  get() = CommonMainString0.move_icebreaker_title

private fun init_move_icebreaker_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:move_icebreaker_title", "move_icebreaker_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/spark_move.commonMain.cvr", 362,
    45),
    )
)

internal val Res.string.move_musical_gif_description: StringResource
  get() = CommonMainString0.move_musical_gif_description

private fun init_move_musical_gif_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:move_musical_gif_description", "move_musical_gif_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/spark_move.commonMain.cvr", 408,
    148),
    )
)

internal val Res.string.move_musical_gif_title: StringResource
  get() = CommonMainString0.move_musical_gif_title

private fun init_move_musical_gif_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:move_musical_gif_title", "move_musical_gif_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/spark_move.commonMain.cvr", 557,
    46),
    )
)

internal val Res.string.move_title: StringResource
  get() = CommonMainString0.move_title

private fun init_move_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:move_title", "move_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/spark_move.commonMain.cvr", 604,
    46),
    )
)

internal val Res.string.music: StringResource
  get() = CommonMainString0.music

private fun init_music(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:music", "music",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    264, 21),
    )
)

internal val Res.string.mystery: StringResource
  get() = CommonMainString0.mystery

private fun init_mystery(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:mystery", "mystery",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    286, 27),
    )
)

internal val Res.string.nature: StringResource
  get() = CommonMainString0.nature

private fun init_nature(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:nature", "nature",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    314, 22),
    )
)

internal val Res.string.network_error: StringResource
  get() = CommonMainString0.network_error

private fun init_network_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:network_error", "network_error",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/error_strings.commonMain.cvr",
    320, 73),
    )
)

internal val Res.string.neutral: StringResource
  get() = CommonMainString0.neutral

private fun init_neutral(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:neutral", "neutral",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1109,
    27),
    )
)

internal val Res.string.next: StringResource
  get() = CommonMainString0.next

private fun init_next(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:next", "next",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1137,
    20),
    )
)

internal val Res.string.no_matches_content: StringResource
  get() = CommonMainString0.no_matches_content

private fun init_no_matches_content(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:no_matches_content", "no_matches_content",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 567, 122),
    )
)

internal val Res.string.no_matches_title: StringResource
  get() = CommonMainString0.no_matches_title

private fun init_no_matches_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:no_matches_title", "no_matches_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 690, 80),
    )
)

internal val Res.string.nostalgia: StringResource
  get() = CommonMainString0.nostalgia

private fun init_nostalgia(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:nostalgia", "nostalgia",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    337, 29),
    )
)

internal val Res.string.notification_for_match_rationale_message: StringResource
  get() = CommonMainString0.notification_for_match_rationale_message

private fun init_notification_for_match_rationale_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:notification_for_match_rationale_message", "notification_for_match_rationale_message",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/permission_rationale_strings.commonMain.cvr",
    455, 164),
    )
)

internal val Res.string.notification_for_match_rationale_title: StringResource
  get() = CommonMainString0.notification_for_match_rationale_title

private fun init_notification_for_match_rationale_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:notification_for_match_rationale_title", "notification_for_match_rationale_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/permission_rationale_strings.commonMain.cvr",
    620, 98),
    )
)

internal val Res.string.notification_for_message_rationale_message: StringResource
  get() = CommonMainString0.notification_for_message_rationale_message

private fun init_notification_for_message_rationale_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:notification_for_message_rationale_message", "notification_for_message_rationale_message",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/permission_rationale_strings.commonMain.cvr",
    719, 162),
    )
)

internal val Res.string.notification_for_message_rationale_title: StringResource
  get() = CommonMainString0.notification_for_message_rationale_title

private fun init_notification_for_message_rationale_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:notification_for_message_rationale_title", "notification_for_message_rationale_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/permission_rationale_strings.commonMain.cvr",
    882, 80),
    )
)

internal val Res.string.occupation_input: StringResource
  get() = CommonMainString0.occupation_input

private fun init_occupation_input(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:occupation_input", "occupation_input",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1158,
    76),
    )
)

internal val Res.string.open_mindedness: StringResource
  get() = CommonMainString0.open_mindedness

private fun init_open_mindedness(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:open_mindedness", "open_mindedness",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_interest.commonMain.cvr",
    372, 43),
    )
)

internal val Res.string.or: StringResource
  get() = CommonMainString0.or

private fun init_or(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:or", "or",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1235,
    14),
    )
)

internal val Res.string.party: StringResource
  get() = CommonMainString0.party

private fun init_party(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:party", "party",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    367, 21),
    )
)

internal val Res.string.pause: StringResource
  get() = CommonMainString0.pause

private fun init_pause(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:pause", "pause",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/harmony_tunes_app.commonMain.cvr",
    216, 21),
    )
)

internal val Res.string.permission_error: StringResource
  get() = CommonMainString0.permission_error

private fun init_permission_error(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:permission_error", "permission_error",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/error_strings.commonMain.cvr",
    394, 80),
    )
)

internal val Res.string.physical_chemistry: StringResource
  get() = CommonMainString0.physical_chemistry

private fun init_physical_chemistry(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:physical_chemistry", "physical_chemistry",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_interest.commonMain.cvr",
    416, 50),
    )
)

internal val Res.string.pieces_of_match_description: StringResource
  get() = CommonMainString0.pieces_of_match_description

private fun init_pieces_of_match_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:pieces_of_match_description", "pieces_of_match_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/pieces_of_match.commonMain.cvr",
    10, 123),
    )
)

internal val Res.string.pieces_of_match_details: StringResource
  get() = CommonMainString0.pieces_of_match_details

private fun init_pieces_of_match_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:pieces_of_match_details", "pieces_of_match_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/pieces_of_match.commonMain.cvr",
    134, 171),
    )
)

internal val Res.string.pieces_of_match_name: StringResource
  get() = CommonMainString0.pieces_of_match_name

private fun init_pieces_of_match_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:pieces_of_match_name", "pieces_of_match_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/pieces_of_match.commonMain.cvr",
    306, 48),
    )
)

internal val Res.string.pieces_of_match_slogan: StringResource
  get() = CommonMainString0.pieces_of_match_slogan

private fun init_pieces_of_match_slogan(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:pieces_of_match_slogan", "pieces_of_match_slogan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/pieces_of_match.commonMain.cvr",
    355, 58),
    )
)

internal val Res.string.play: StringResource
  get() = CommonMainString0.play

private fun init_play(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:play", "play",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/harmony_tunes_app.commonMain.cvr",
    238, 20),
    )
)

internal val Res.string.play_games: StringResource
  get() = CommonMainString0.play_games

private fun init_play_games(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:play_games", "play_games",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1250,
    42),
    )
)

internal val Res.string.preferenceAuthorQuote: StringResource
  get() = CommonMainString0.preferenceAuthorQuote

private fun init_preferenceAuthorQuote(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:preferenceAuthorQuote", "preferenceAuthorQuote",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1293,
    41),
    )
)

internal val Res.string.preferenceQuote: StringResource
  get() = CommonMainString0.preferenceQuote

private fun init_preferenceQuote(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:preferenceQuote", "preferenceQuote",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1335,
    91),
    )
)

internal val Res.string.previous: StringResource
  get() = CommonMainString0.previous

private fun init_previous(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:previous", "previous",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1427,
    28),
    )
)

internal val Res.string.privacy_policy: StringResource
  get() = CommonMainString0.privacy_policy

private fun init_privacy_policy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:privacy_policy", "privacy_policy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1452,
    42),
    )
)

internal val Res.string.problem_solving: StringResource
  get() = CommonMainString0.problem_solving

private fun init_problem_solving(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:problem_solving", "problem_solving",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    389, 43),
    )
)

internal val Res.string.profileOption: StringResource
  get() = CommonMainString0.profileOption

private fun init_profileOption(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:profileOption", "profileOption",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 771, 33),
    )
)

internal val Res.string.puzzle_heart: StringResource
  get() = CommonMainString0.puzzle_heart

private fun init_puzzle_heart(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:puzzle_heart", "puzzle_heart",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 805, 36),
    )
)

internal val Res.string.question_count: StringResource
  get() = CommonMainString0.question_count

private fun init_question_count(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:question_count", "question_count",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1456,
    34),
    )
)

internal val Res.string.question_intro_direction: StringResource
  get() = CommonMainString0.question_intro_direction

private fun init_question_intro_direction(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:question_intro_direction", "question_intro_direction",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/deep_questions.commonMain.cvr",
    10, 108),
    )
)

internal val Res.string.question_intro_preview_title: StringResource
  get() = CommonMainString0.question_intro_preview_title

private fun init_question_intro_preview_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:question_intro_preview_title", "question_intro_preview_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/deep_questions.commonMain.cvr",
    119, 48),
    )
)

internal val Res.string.question_intro_title: StringResource
  get() = CommonMainString0.question_intro_title

private fun init_question_intro_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:question_intro_title", "question_intro_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/deep_questions.commonMain.cvr",
    168, 72),
    )
)

internal val Res.string.question_q1_direction: StringResource
  get() = CommonMainString0.question_q1_direction

private fun init_question_q1_direction(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:question_q1_direction", "question_q1_direction",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/deep_questions.commonMain.cvr",
    241, 77),
    )
)

internal val Res.string.question_q1_preview_title: StringResource
  get() = CommonMainString0.question_q1_preview_title

private fun init_question_q1_preview_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:question_q1_preview_title", "question_q1_preview_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/deep_questions.commonMain.cvr",
    319, 53),
    )
)

internal val Res.string.question_q1_title: StringResource
  get() = CommonMainString0.question_q1_title

private fun init_question_q1_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:question_q1_title", "question_q1_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/deep_questions.commonMain.cvr",
    373, 61),
    )
)

internal val Res.string.read: StringResource
  get() = CommonMainString0.read

private fun init_read(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:read", "read",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1491,
    20),
    )
)

internal val Res.string.record_message: StringResource
  get() = CommonMainString0.record_message

private fun init_record_message(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:record_message", "record_message",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/common_directions.commonMain.cvr",
    187, 50),
    )
)

internal val Res.string.reg_gender: StringResource
  get() = CommonMainString0.reg_gender

private fun init_reg_gender(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:reg_gender", "reg_gender",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1512,
    46),
    )
)

internal val Res.string.relax: StringResource
  get() = CommonMainString0.relax

private fun init_relax(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:relax", "relax",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    433, 21),
    )
)

internal val Res.string.remain_image_text: StringResource
  get() = CommonMainString0.remain_image_text

private fun init_remain_image_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:remain_image_text", "remain_image_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1559,
    49),
    )
)

internal val Res.string.rematch: StringResource
  get() = CommonMainString0.rematch

private fun init_rematch(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:rematch", "rematch",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 842, 27),
    )
)

internal val Res.string.remove_account: StringResource
  get() = CommonMainString0.remove_account

private fun init_remove_account(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:remove_account", "remove_account",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1495,
    42),
    )
)

internal val Res.string.repeat: StringResource
  get() = CommonMainString0.repeat

private fun init_repeat(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:repeat", "repeat",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/harmony_tunes_app.commonMain.cvr",
    259, 22),
    )
)

internal val Res.string.retake_photo: StringResource
  get() = CommonMainString0.retake_photo

private fun init_retake_photo(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:retake_photo", "retake_photo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1609,
    36),
    )
)

internal val Res.string.romance: StringResource
  get() = CommonMainString0.romance

private fun init_romance(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:romance", "romance",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    455, 27),
    )
)

internal val Res.string.sc_direction: StringResource
  get() = CommonMainString0.sc_direction

private fun init_sc_direction(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sc_direction", "sc_direction",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/sc_deep_questions.commonMain.cvr",
    1759, 68),
    )
)

internal val Res.string.sc_q1_title: StringResource
  get() = CommonMainString0.sc_q1_title

private fun init_sc_q1_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sc_q1_title", "sc_q1_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/sc_deep_questions.commonMain.cvr",
    1828, 123),
    )
)

internal val Res.string.sc_q2_title: StringResource
  get() = CommonMainString0.sc_q2_title

private fun init_sc_q2_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sc_q2_title", "sc_q2_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/sc_deep_questions.commonMain.cvr",
    1952, 71),
    )
)

internal val Res.string.sc_q3_title: StringResource
  get() = CommonMainString0.sc_q3_title

private fun init_sc_q3_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sc_q3_title", "sc_q3_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/sc_deep_questions.commonMain.cvr",
    2024, 43),
    )
)

internal val Res.string.sc_q4_title: StringResource
  get() = CommonMainString0.sc_q4_title

private fun init_sc_q4_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sc_q4_title", "sc_q4_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/sc_deep_questions.commonMain.cvr",
    2068, 99),
    )
)

internal val Res.string.sc_q5_title: StringResource
  get() = CommonMainString0.sc_q5_title

private fun init_sc_q5_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sc_q5_title", "sc_q5_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/sc_deep_questions.commonMain.cvr",
    2168, 79),
    )
)

internal val Res.string.sc_q6_title: StringResource
  get() = CommonMainString0.sc_q6_title

private fun init_sc_q6_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sc_q6_title", "sc_q6_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/sc_deep_questions.commonMain.cvr",
    2248, 79),
    )
)

internal val Res.string.sc_q7_title: StringResource
  get() = CommonMainString0.sc_q7_title

private fun init_sc_q7_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sc_q7_title", "sc_q7_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/sc_deep_questions.commonMain.cvr",
    2328, 99),
    )
)

internal val Res.string.see_less: StringResource
  get() = CommonMainString0.see_less

private fun init_see_less(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:see_less", "see_less",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1538,
    28),
    )
)

internal val Res.string.see_more: StringResource
  get() = CommonMainString0.see_more

private fun init_see_more(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:see_more", "see_more",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1567,
    28),
    )
)

internal val Res.string.select_all: StringResource
  get() = CommonMainString0.select_all

private fun init_select_all(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_all", "select_all",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1646,
    50),
    )
)

internal val Res.string.select_date: StringResource
  get() = CommonMainString0.select_date

private fun init_select_date(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_date", "select_date",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1697,
    35),
    )
)

internal val Res.string.select_one: StringResource
  get() = CommonMainString0.select_one

private fun init_select_one(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_one", "select_one",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1733,
    34),
    )
)

internal val Res.string.selfie_skills: StringResource
  get() = CommonMainString0.selfie_skills

private fun init_selfie_skills(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:selfie_skills", "selfie_skills",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1768,
    61),
    )
)

internal val Res.string.selfies: StringResource
  get() = CommonMainString0.selfies

private fun init_selfies(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:selfies", "selfies",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1830,
    63),
    )
)

internal val Res.string.sense_of_adventure: StringResource
  get() = CommonMainString0.sense_of_adventure

private fun init_sense_of_adventure(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sense_of_adventure", "sense_of_adventure",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_interest.commonMain.cvr",
    467, 50),
    )
)

internal val Res.string.sense_of_humor: StringResource
  get() = CommonMainString0.sense_of_humor

private fun init_sense_of_humor(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sense_of_humor", "sense_of_humor",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_interest.commonMain.cvr",
    518, 42),
    )
)

internal val Res.string.settings_account: StringResource
  get() = CommonMainString0.settings_account

private fun init_settings_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settings_account", "settings_account",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1596,
    36),
    )
)

internal val Res.string.settings_title: StringResource
  get() = CommonMainString0.settings_title

private fun init_settings_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settings_title", "settings_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1633,
    34),
    )
)

internal val Res.string.show_password: StringResource
  get() = CommonMainString0.show_password

private fun init_show_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:show_password", "show_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1894,
    41),
    )
)

internal val Res.string.sign_button_format: StringResource
  get() = CommonMainString0.sign_button_format

private fun init_sign_button_format(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sign_button_format", "sign_button_format",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1668,
    50),
    )
)

internal val Res.string.sign_in: StringResource
  get() = CommonMainString0.sign_in

private fun init_sign_in(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sign_in", "sign_in",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 2053,
    27),
    )
)

internal val Res.string.sign_in_create_account: StringResource
  get() = CommonMainString0.sign_in_create_account

private fun init_sign_in_create_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sign_in_create_account", "sign_in_create_account",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 1936,
    70),
    )
)

internal val Res.string.sign_in_guest: StringResource
  get() = CommonMainString0.sign_in_guest

private fun init_sign_in_guest(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sign_in_guest", "sign_in_guest",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 2007,
    45),
    )
)

internal val Res.string.slider_direction: StringResource
  get() = CommonMainString0.slider_direction

private fun init_slider_direction(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:slider_direction", "slider_direction",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 2081,
    44),
    )
)

internal val Res.string.some_permissions_are_not_granted: StringResource
  get() = CommonMainString0.some_permissions_are_not_granted

private fun init_some_permissions_are_not_granted(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:some_permissions_are_not_granted", "some_permissions_are_not_granted",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/error_strings.commonMain.cvr",
    475, 140),
    )
)

internal val Res.string.spark_feed_title: StringResource
  get() = CommonMainString0.spark_feed_title

private fun init_spark_feed_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:spark_feed_title", "spark_feed_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1719,
    40),
    )
)

internal val Res.string.spark_ingredients_title: StringResource
  get() = CommonMainString0.spark_ingredients_title

private fun init_spark_ingredients_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:spark_ingredients_title", "spark_ingredients_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1760,
    43),
    )
)

internal val Res.string.spark_invitation_text: StringResource
  get() = CommonMainString0.spark_invitation_text

private fun init_spark_invitation_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:spark_invitation_text", "spark_invitation_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 870, 125),
    )
)

internal val Res.string.spontaneity: StringResource
  get() = CommonMainString0.spontaneity

private fun init_spontaneity(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:spontaneity", "spontaneity",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_interest.commonMain.cvr",
    561, 35),
    )
)

internal val Res.string.sport: StringResource
  get() = CommonMainString0.sport

private fun init_sport(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sport", "sport",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    483, 21),
    )
)

internal val Res.string.stage_title: StringResource
  get() = CommonMainString0.stage_title

private fun init_stage_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:stage_title", "stage_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 2126,
    35),
    )
)

internal val Res.string.strongly_dislike: StringResource
  get() = CommonMainString0.strongly_dislike

private fun init_strongly_dislike(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:strongly_dislike", "strongly_dislike",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 2162,
    48),
    )
)

internal val Res.string.strongly_like: StringResource
  get() = CommonMainString0.strongly_like

private fun init_strongly_like(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:strongly_like", "strongly_like",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 2211,
    41),
    )
)

internal val Res.string.survey_result_description: StringResource
  get() = CommonMainString0.survey_result_description

private fun init_survey_result_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:survey_result_description", "survey_result_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 2253,
    233),
    )
)

internal val Res.string.survey_result_subtitle: StringResource
  get() = CommonMainString0.survey_result_subtitle

private fun init_survey_result_subtitle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:survey_result_subtitle", "survey_result_subtitle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 2487,
    74),
    )
)

internal val Res.string.survey_result_title: StringResource
  get() = CommonMainString0.survey_result_title

private fun init_survey_result_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:survey_result_title", "survey_result_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 2562,
    39),
    )
)

internal val Res.string.swipe_escape_description: StringResource
  get() = CommonMainString0.swipe_escape_description

private fun init_swipe_escape_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:swipe_escape_description", "swipe_escape_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/swipe_escape.commonMain.cvr", 10,
    112),
    )
)

internal val Res.string.swipe_escape_details: StringResource
  get() = CommonMainString0.swipe_escape_details

private fun init_swipe_escape_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:swipe_escape_details", "swipe_escape_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/swipe_escape.commonMain.cvr", 123,
    136),
    )
)

internal val Res.string.swipe_escape_name: StringResource
  get() = CommonMainString0.swipe_escape_name

private fun init_swipe_escape_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:swipe_escape_name", "swipe_escape_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/swipe_escape.commonMain.cvr", 260,
    41),
    )
)

internal val Res.string.swipe_escape_slogan: StringResource
  get() = CommonMainString0.swipe_escape_slogan

private fun init_swipe_escape_slogan(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:swipe_escape_slogan", "swipe_escape_slogan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/swipe_escape.commonMain.cvr", 302,
    47),
    )
)

internal val Res.string.swipe_to_cancel_recording: StringResource
  get() = CommonMainString0.swipe_to_cancel_recording

private fun init_swipe_to_cancel_recording(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:swipe_to_cancel_recording", "swipe_to_cancel_recording",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/common_directions.commonMain.cvr",
    238, 61),
    )
)

internal val Res.string.tags: StringResource
  get() = CommonMainString0.tags

private fun init_tags(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tags", "tags",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1804,
    20),
    )
)

internal val Res.string.takeaway: StringResource
  get() = CommonMainString0.takeaway

private fun init_takeaway(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:takeaway", "takeaway",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 2602,
    132),
    )
)

internal val Res.string.term_of_service: StringResource
  get() = CommonMainString0.term_of_service

private fun init_term_of_service(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:term_of_service", "term_of_service",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1825,
    43),
    )
)

internal val Res.string.terms_and_conditions: StringResource
  get() = CommonMainString0.terms_and_conditions

private fun init_terms_and_conditions(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:terms_and_conditions", "terms_and_conditions",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 2735,
    184),
    )
)

internal val Res.string.textfield_hint: StringResource
  get() = CommonMainString0.textfield_hint

private fun init_textfield_hint(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:textfield_hint", "textfield_hint",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/strings.commonMain.cvr", 1869,
    42),
    )
)

internal val Res.string.today: StringResource
  get() = CommonMainString0.today

private fun init_today(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:today", "today",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 2920,
    21),
    )
)

internal val Res.string.touch_and_hold_to_record: StringResource
  get() = CommonMainString0.touch_and_hold_to_record

private fun init_touch_and_hold_to_record(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:touch_and_hold_to_record", "touch_and_hold_to_record",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/common_directions.commonMain.cvr",
    300, 64),
    )
)

internal val Res.string.trivia: StringResource
  get() = CommonMainString0.trivia

private fun init_trivia(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:trivia", "trivia",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/matching_vibes.commonMain.cvr",
    505, 22),
    )
)

internal val Res.string.true_matcher_name: StringResource
  get() = CommonMainString0.true_matcher_name

private fun init_true_matcher_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:true_matcher_name", "true_matcher_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 996, 37),
    )
)

internal val Res.string.type_your_answer: StringResource
  get() = CommonMainString0.type_your_answer

private fun init_type_your_answer(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:type_your_answer", "type_your_answer",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 2942,
    48),
    )
)

internal val Res.string.unMatchOption: StringResource
  get() = CommonMainString0.unMatchOption

private fun init_unMatchOption(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:unMatchOption", "unMatchOption",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 1034, 41),
    )
)

internal val Res.string.unexpected_error: StringResource
  get() = CommonMainString0.unexpected_error

private fun init_unexpected_error(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:unexpected_error", "unexpected_error",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/error_strings.commonMain.cvr",
    616, 60),
    )
)

internal val Res.string.unmatchOpening: StringResource
  get() = CommonMainString0.unmatchOpening

private fun init_unmatchOpening(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:unmatchOpening", "unmatchOpening",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/match.commonMain.cvr", 1076, 306),
    )
)

internal val Res.string.user_continue: StringResource
  get() = CommonMainString0.user_continue

private fun init_user_continue(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:user_continue", "user_continue",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 2991,
    33),
    )
)

internal val Res.string.waiting_for_other_player: StringResource
  get() = CommonMainString0.waiting_for_other_player

private fun init_waiting_for_other_player(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:waiting_for_other_player", "waiting_for_other_player",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/ice_breakers.commonMain.cvr", 621,
    80),
    )
)

internal val Res.string.waiting_for_your_invitation_response: StringResource
  get() = CommonMainString0.waiting_for_your_invitation_response

private fun init_waiting_for_your_invitation_response(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:waiting_for_your_invitation_response", "waiting_for_your_invitation_response",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/ice_breakers.commonMain.cvr", 702,
    64),
    )
)

internal val Res.string.waiting_for_your_match: StringResource
  get() = CommonMainString0.waiting_for_your_match

private fun init_waiting_for_your_match(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:waiting_for_your_match", "waiting_for_your_match",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/ice_breakers.commonMain.cvr", 767,
    62),
    )
)

internal val Res.string.watch_movies: StringResource
  get() = CommonMainString0.watch_movies

private fun init_watch_movies(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:watch_movies", "watch_movies",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 3025,
    36),
    )
)

internal val Res.string.who_wants_description: StringResource
  get() = CommonMainString0.who_wants_description

private fun init_who_wants_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:who_wants_description", "who_wants_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/who_wants.commonMain.cvr", 10,
    89),
    )
)

internal val Res.string.who_wants_details: StringResource
  get() = CommonMainString0.who_wants_details

private fun init_who_wants_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:who_wants_details", "who_wants_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/who_wants.commonMain.cvr", 100,
    301),
    )
)

internal val Res.string.who_wants_name: StringResource
  get() = CommonMainString0.who_wants_name

private fun init_who_wants_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:who_wants_name", "who_wants_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/who_wants.commonMain.cvr", 402,
    58),
    )
)

internal val Res.string.who_wants_slogan: StringResource
  get() = CommonMainString0.who_wants_slogan

private fun init_who_wants_slogan(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:who_wants_slogan", "who_wants_slogan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/who_wants.commonMain.cvr", 461,
    32),
    )
)

internal val Res.string.win_sub_title: StringResource
  get() = CommonMainString0.win_sub_title

private fun init_win_sub_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:win_sub_title", "win_sub_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/ice_breakers.commonMain.cvr", 830,
    61),
    )
)

internal val Res.string.work_out: StringResource
  get() = CommonMainString0.work_out

private fun init_work_out(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:work_out", "work_out",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 3062,
    28),
    )
)

internal val Res.string.yesterday: StringResource
  get() = CommonMainString0.yesterday

private fun init_yesterday(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:yesterday", "yesterday",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 3091,
    29),
    )
)

internal val Res.string.your_first_name_is: StringResource
  get() = CommonMainString0.your_first_name_is

private fun init_your_first_name_is(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:your_first_name_is", "your_first_name_is",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 3121,
    54),
    )
)

internal val Res.string.your_last_name_is: StringResource
  get() = CommonMainString0.your_last_name_is

private fun init_your_last_name_is(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:your_last_name_is", "your_last_name_is",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 3176,
    49),
    )
)
