package com.osg.appData.matchZone


import com.osg.appData.matchZone.invitation.EntertainmentType
import com.osg.truebase.data.iceDef.IEntertainmentData
import com.osg.truebase.data.iceDef.IceTag
import com.osg.truebase.data.nodeData.RemoteImage
import kotlinx.serialization.Serializable

interface ITmEntertainmentData: IEntertainmentData {
    val unlockStage: Int
    val type: EntertainmentType
}

@Serializable
data class SparkNodeData(
    override val id: String,
    override val unlockStage: Int = 0,
    override val image: RemoteImage,
    override val vibes: List<IceTag> = emptyList(),
    val enabled: Boolean = false,
    val value: Int = 0,
    val eligibility: Eligibility = Eligibility.YES,
    val ingredients: List<String> = emptyList(),
    val slogan: String = "",
    val description: String = "",
    val details: String = "",
    val name: String = "",
) : ITmEntertainmentData{
    override val type: EntertainmentType = EntertainmentType.SPARK
}