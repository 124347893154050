package com.osg.appData.location

import com.osg.truebase.data.user.GeoLocationDegrees
import com.osg.truebase.data.user.UserLocation
import kotlinx.serialization.Serializable

@Serializable
data class PlatformCoordination(override val lang: Double,
                                override val lat: Double
) : GeoLocationDegrees

interface IPlatformGps {
    suspend fun fetchGpsLocation(): GeoLocationDegrees
}

interface ICurrentLocation {
    suspend fun fetchCurrentLocation(
        geoLocationDegrees: GeoLocationDegrees
    ): UserLocation
}

fun interface IAutoCompleteCities {
    suspend fun autocompleteCitiesNearby(
        countryCode: String,
        userInput: String
    ): List<String>
}


interface IGeo: IAutoCompleteCities, ICurrentLocation

