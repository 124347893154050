package com.osg.truebase.ui.assigners.wrapper

import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.AnimatedContentTransitionScope
import androidx.compose.animation.core.TweenSpec
import androidx.compose.animation.core.tween
import androidx.compose.animation.togetherWith
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.components.clearFocusOnTap
import com.osg.truebase.ui.animation.LottieAnimationView
import com.osg.truebase.ui.assigners.def.GenericQuestionAssigner
import com.osg.truebase.ui.generated.resources.Res
import com.osg.truebase.ui.strings.CommonStringRes
import com.osg.truebase.ui_strings.generated.resources.*
import com.osg.truebase.ui.theme.spacing


private const val CONTENT_ANIMATION_DURATION = 300

@Composable
fun <T> GenericQuestionScreen(
    questionAssigners: List<GenericQuestionAssigner<T>>,
    initialFillerState: T,
    initialIndex: Int = 0,
    onFinish: (T) -> Unit,
    onUpdated: (T) -> Unit = {},
) {
    var fillState by remember { mutableStateOf(initialFillerState) }
    if (questionAssigners.isEmpty()) {
        LaunchedEffect(Unit) {
            onFinish(fillState)
        }
        return
    }

    var questionIndex by remember { mutableStateOf(initialIndex) }
    var isNextEnabled by remember(questionIndex) { mutableStateOf(false) }

    Scaffold(
        modifier = Modifier
            .fillMaxSize()
            .clearFocusOnTap(),
        bottomBar = {
            QuestionsBottomBar(
                shouldShowPreviousButton = questionIndex > 0,
                isNextButtonEnabled = isNextEnabled,
                onPreviousPressed = {
                    questionIndex--
                },
                onNextPressed = {
                    if (questionIndex < questionAssigners.size - 1) {
                        questionIndex++
                        onUpdated(fillState)
                    } else {
                        onFinish(fillState)
                    }
                },
                nextButtonTextRes = CommonStringRes.continueToNext,
            )
        }
    )
    { paddingValues ->
        Column(
            modifier = Modifier.padding(paddingValues)
        ) {
            QuestionTopAppBar(
                questionIndex = questionIndex,
                totalQuestionsCount = questionAssigners.size,
            )
            AnimatedContent(
                modifier = Modifier.padding(top = MaterialTheme.spacing.small),
                targetState = questionIndex,
                transitionSpec = {
                    val animationSpec: TweenSpec<IntOffset> = tween(CONTENT_ANIMATION_DURATION)

                    val direction = getTransitionDirection(
                        initialIndex = initialState,
                        targetIndex = targetState,
                    )

                    slideIntoContainer(
                        towards = direction,
                        animationSpec = animationSpec,
                    ) togetherWith slideOutOfContainer(
                        towards = direction,
                        animationSpec = animationSpec
                    )
                },
                label = "surveyScreenDataAnimation"
            ) { idx ->
                val question = questionAssigners[idx]
                val uiData = question.questionUiData
                QuestionWrapper(
                    title = uiData.title,
                    animationCompose = {
                        LottieAnimationView(
                            modifier = Modifier
                                .height(150.dp)
                                .fillMaxWidth(),
                            animationData = uiData.animationResource,
                            contentScale = ContentScale.FillHeight
                        )
                    },
                    directions = uiData.directions,
                    modifier = Modifier
                ) {
                    question.genObjAssigner.content(
                        Modifier,
                        fillState,
                    ) {
                        fillState = it
                        isNextEnabled = true
                    }
                }
            }
        }
    }
}

private fun getTransitionDirection(
    initialIndex: Int,
    targetIndex: Int
): AnimatedContentTransitionScope.SlideDirection {
    return if (targetIndex > initialIndex) {
        // Going forwards in the survey: Set the initial offset to start
        // at the size of the content so it slides in from right to left, and
        // slides out from the left of the screen to -fullWidth
        AnimatedContentTransitionScope.SlideDirection.Left
    } else {
        // Going back to the previous question in the set, we do the same
        // transition as above, but with different offsets - the inverse of
        // above, negative fullWidth to enter, and fullWidth to exit.
        AnimatedContentTransitionScope.SlideDirection.Right
    }
}