package com.osg.ice.challengeAccepted

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.lifecycle.viewmodel.compose.viewModel
import com.osg.def.database.IDownloadMedia
import com.osg.ice.CaDependencies
import com.osg.ice.IceRepository
import com.osg.ice.challengeAccepted.ui.ChallengeAcceptedRoundScreen
import com.osg.ice.state.IceMatchResult
import com.osg.ice.state.IceUiPlayerProfile
import com.osg.ice.ui.MatchResultSingleMode
import com.osg.ice.ui.MatchResultTeamMode
import com.osg.truebase.data.iceDef.IceMultiMediaDownloader
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.ui.commonStates.MainViewUi

sealed interface ChallengeAcceptGameMode {
    @Composable
    fun MatchResultViewType(
        currentResult: IceMatchResult,
        prevResult: IceMatchResult,
        myPlayerProfile: IceUiPlayerProfile,
        opponentProfile: IceUiPlayerProfile,
        modifier: Modifier,
    )

    data object TeamMode: ChallengeAcceptGameMode{
        @Composable
        override fun MatchResultViewType(
            currentResult: IceMatchResult,
            prevResult: IceMatchResult,
            myPlayerProfile: IceUiPlayerProfile,
            opponentProfile: IceUiPlayerProfile,
            modifier: Modifier,
        ) {
            MatchResultTeamMode(
                currentResult = currentResult,
                prevResult = prevResult,
                myPlayerProfile = myPlayerProfile,
                opponentProfile = opponentProfile,
                modifier = modifier,
            )
        }
    }
    data object SingleMode: ChallengeAcceptGameMode{
        @Composable
        override fun MatchResultViewType(
            currentResult: IceMatchResult,
            prevResult: IceMatchResult,
            myPlayerProfile: IceUiPlayerProfile,
            opponentProfile: IceUiPlayerProfile,
            modifier: Modifier,
        ) {
            MatchResultSingleMode(
                currentResult = currentResult,
                prevResult = prevResult,
                myPlayerProfile = myPlayerProfile,
                opponentProfile = opponentProfile,
                modifier = modifier,
            )
        }
    }
}

class ChallengeAccepted(
    private val iceBreakerRepository: IceRepository,
    private val mediaDbDownloader: IDownloadMedia,
    private val iceDataStorage: IceMultiMediaDownloader,
    private val gameMode: ChallengeAcceptGameMode = ChallengeAcceptGameMode.TeamMode,
) {
    init {
        AppLogger.d("ChallengeAccepted", "ChallengeAccepted init")
    }

    @Composable
    fun ChallengeAcceptedEntry(
        iceBreakerDependencies: CaDependencies,
        modifier: Modifier = Modifier,
        onGameResults: (IceMatchResult) -> Unit,
    ) {
        val caViewModel = viewModel {
            ChallengeAcceptedModel(
                iceBreakerDependencies = iceBreakerDependencies,
                mediaDownloader = mediaDbDownloader,
                iceDataStorage = iceDataStorage,
                iceBreakerRepository = iceBreakerRepository,
            )
        }
        val state by caViewModel.challengeAcceptedState.collectAsState()
        state.MainViewUi(
            ladingContent = {
                caViewModel.LandingContent()
            }
        ){ iceUiStates ->
            gameMode.ChallengeAcceptedRoundScreen(
                modifier = modifier,
                roundData = iceUiStates,
                onOptionSelected = caViewModel::onOptionSelected,
                onNextRound = caViewModel::loadRound,
                onExitWithResult = onGameResults,
            )
        }
    }
}