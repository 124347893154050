package com.osg.truebase.data.logger

actual val BaseLoggerFactory: PlatformLoggerFactory = object : PlatformLoggerFactory {
    override fun getLogger(subsystem: String, tag: String): PlatformLogger {
        return JsLogger("$subsystem:$tag|")
    }
}


class JsLogger(
    private val tag: String
) : PlatformLogger {

    override fun e(message: String) {
        console.error(tag, message)
    }

    override fun d(message: String) {
        console.log(tag, message)
    }
}