package com.osg.ui.core.resources

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Stable
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.graphics.vector.ImageVector
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.vectorResource

val DrawableResource.painter: Painter
    @Stable
    @Composable
    get() = painterResource(this)


val DrawableResource.vector: ImageVector
    @Stable
    @Composable
    get() = vectorResource(this)