package com.osg.appData.ml

import kotlinx.serialization.Serializable

@Serializable
data class UserFeatures(
    val id: String = "",
    val stringFeatures: List<String> = emptyList(),
)

