package com.osg.truebase.ui.screens.settings

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.osg.truebase.data.LanguageSupport
import com.osg.ui.core.resources.string
import com.osg.ui.core.commonStates.asVector
import com.osg.truebase.ui.components.LinksPanel
import com.osg.truebase.ui.graphics.shimmerRadialAnimation
import com.osg.truebase.ui.locale.LocaleDropdownMenu
import com.osg.truebase.ui.theme.LocalTmConfig
import com.osg.truebase.ui.theme.spacing
import kotlin.math.roundToInt
import com.osg.truebase.ui.strings.CommonStringRes
import com.osg.truebase.ui_strings.generated.resources.settings_title
import com.osg.truebase.ui.ds.GeneralIconButtonItem
import com.osg.truebase.ui.ds.PreDefinedButtonUi
import com.osg.truebase.ui.ds.UrlStringRes
import com.osg.truebase.ui.ds.toItem
import com.osg.truebase.ui_strings.generated.resources.settings_account
import com.osg.truebase.ui_strings.generated.resources.settings_back
import com.osg.truebase.ui_strings.generated.resources.settings_preferences


class TopWithFooter(
    override val spacing: Dp = 0.dp
) : Arrangement.Vertical {
    override fun Density.arrange(
        totalSize: Int,
        sizes: IntArray,
        outPositions: IntArray
    ) {
        var y = 0
        sizes.forEachIndexed { index, size ->
            outPositions[index] = y
            y += size + spacing.toPx().roundToInt()
        }
        if (y < totalSize) {
            val lastIndex = outPositions.lastIndex
            outPositions[lastIndex] = totalSize - sizes.last()
        }
    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun SettingsScreen(
    urlStringResList: List<UrlStringRes>,
    onBack: () -> Unit,
    onLogOut: () -> Unit,
    onRemoveAccount: () -> Unit,
    onSelectLanguage: (LanguageSupport) -> Unit,
    extraSettings: List<GeneralIconButtonItem> = emptyList(),
    modifier: Modifier = Modifier,
) {
    Scaffold(
        modifier = modifier,
        topBar = {
            CenterAlignedTopAppBar(
                title = {
                    Text(
                        text = CommonStringRes.settings_title.string,
                        style = MaterialTheme.typography.headlineMedium,
                        modifier = Modifier,
                    )
                },
                navigationIcon = {
                    IconButton(
                        onClick = onBack,
                        modifier = modifier
                    ) {
                        Icon(
                            modifier = Modifier,
                            imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                            contentDescription = CommonStringRes.settings_back.string
                        )
                    }
                }
            )
        },
    ) { paddingValues ->
        Column(
            horizontalAlignment = Alignment.Start,
            verticalArrangement = TopWithFooter(MaterialTheme.spacing.small),
            modifier = Modifier
                .padding(paddingValues)
                .padding(MaterialTheme.spacing.small)
                .fillMaxSize()
                .verticalScroll(rememberScrollState()),
        ) {
            Text(
                text = CommonStringRes.settings_preferences.string,
                style = MaterialTheme.typography.titleMedium,
                modifier = Modifier,
            )

            val currentConfig = LocalTmConfig.current
            LocaleDropdownMenu(
                onSelectLanguage = onSelectLanguage,
                currentLanguage = currentConfig.language,
            )

            Spacer(modifier = Modifier.padding(8.dp))

            Text(
                text = CommonStringRes.settings_account.string,
                style = MaterialTheme.typography.titleMedium,
                modifier = Modifier,
            )

            val generalSettingItems = listOf(
                PreDefinedButtonUi.LOGOUT.toItem(onLogOut),
                PreDefinedButtonUi.REMOVE_ACCOUNT.toItem(onRemoveAccount),
            ) + extraSettings

            generalSettingItems.forEach {
                GeneralSettingItem(
                    modifier = Modifier,
                    onClick = it.onClick,
                    icon = it.trueVector.asVector(),
                    text = it.uiText.string,
                )
            }

            LinksPanel(
                modifier = Modifier.fillMaxWidth(),
                urlStringResList = urlStringResList
            )
        }
    }
}



@Composable
fun GeneralSettingItem(
    icon: ImageVector,
    text: String,
    onClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    var isClicked by remember { mutableStateOf(false) }
    Card(
        modifier = modifier.fillMaxWidth()
    ) {
        IconButton(
            onClick = onClick,
            modifier = Modifier.fillMaxWidth().shimmerRadialAnimation(isClicked),
            enabled = isClicked.not()
        ) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth().padding(start = 8.dp),
                horizontalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small)
            ) {
                Icon(
                    imageVector = icon,
                    contentDescription = text,
                )
                Text(
                    text = text,
                    modifier = Modifier
                )
            }
        }
    }
}