package com.osg.trumatch.bots.botProfiles

import bots.trueAutoResources.BotsMediaRemoteImages
import com.osg.appData.profile.*
import com.osg.appData.register.OpenDeepQuestions
import com.osg.truebase.data.nodeData.RemoteProfileImage
import com.osg.truebase.data.user.Gender
import com.osg.truebase.preview.data.IsraelLocation

internal val marshallProfile = FullProfile(
    personalProfile = PersonalProfile(

        facebookId = "10225277936830287",
        lastName = "Eriksen",
        preferencesMatch = PreferencesMatch(
            ageMax = 40,
            ageMin = 20,
            maxDistance = 100,
            dateGender = Gender.Female
        ),
    ),
    publicProfile = PublicProfile(
        birthDay = "05/02/1978",
        firstName = "Marshall",
        gender = Gender.Male,
        height = 195,
        occupation = "Lawyer",
        picturesCommentList = listOf(
            RemoteProfileImage(
                comment = "",
                mediaPath = BotsMediaRemoteImages.M1I0.mediaPath
            ),
            RemoteProfileImage(
                comment = "In the courtroom ⚖️✨ #LawyerLife",
                mediaPath = BotsMediaRemoteImages.M1I1.mediaPath
            ),
            RemoteProfileImage(
                comment = "",
                mediaPath = BotsMediaRemoteImages.M1I2.mediaPath
            )
        ),
        stage = UserStage.REGISTERED,
        verified = true,
        openQuestionAnswer = listOf(
            OpenAnswer(
                answer = "Passionate lawyer and environmental advocate. Always looking for the next big case and meaningful connections. Let's create some unforgettable moments together. 🌍 #LawyerLife #EnvironmentalAdvocate",
                questionId = OpenDeepQuestions.INTRODUCTION
            )
        ),
        location = IsraelLocation.TEL_AVIV.location,
    )
)