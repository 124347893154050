package com.osg.appData.register

import com.osg.appData.profile.FullProfile

enum class RegisterProfileTechnicalElements(
    val isNotAnswered: (FullProfile) -> Boolean,
) {
    Name(
        { it.publicProfile.firstName.isBlank() }
    ),
    LastName(
        { it.personalProfile.lastName.isBlank() }
    ),
    Date(
        isNotAnswered = FullProfile::checkBirthDayBlank
    ),
    Gender(
        isNotAnswered = {it.publicProfile.gender == null}
    ),
    DateGender(
        isNotAnswered = {
            it.personalProfile.preferencesMatch.dateGender == null
        }
    ),
    Occupation(
        isNotAnswered = {it.publicProfile.occupation.isBlank()}
    ),
    Height(
        isNotAnswered = {
            it.publicProfile.height == null
        }
    ),
    AgePreferences(
        isNotAnswered = {
            it.resolvePreferencesAge() == null
        }
    ),
    DistancePreferences(
        isNotAnswered = {
            it.personalProfile.preferencesMatch.maxDistance == null
        }
    ),
    Location(
        isNotAnswered = {
            it.publicProfile.location == null
        }
    );
}

