package com.osg.def.auth

import kotlinx.coroutines.flow.StateFlow

sealed interface LoggedStates{
    data class Loggedin(val uid: String) : LoggedStates
    data object LoggedOut : LoggedStates
    data object Loading: LoggedStates
}

interface ILoggedStateProvider {
    val loggedStateFlow: StateFlow<LoggedStates>
}