package com.osg.utils

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.filterIsInstance
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.map

sealed interface ResourceSteadyState <out T>

sealed interface ResourceState <out T> {
    data class Success<T>(val data: T) : ResourceState<T>, ResourceSteadyState<T>
    data object Loading : ResourceState<Nothing>
    data class Error(val error: Throwable) : ResourceState<Nothing>, ResourceSteadyState<Nothing>
}

fun <T> T.toResourceSucces(): ResourceState<T> = ResourceState.Success(this)

inline fun <reified R> Flow<ResourceState<R>>.filterToData(): Flow<R> = this.filterIsInstance<ResourceState.Success<R>>().map { it.data }
inline fun <reified R> Flow<ResourceState<R>>.filterToSteady(): Flow<ResourceSteadyState<R>> = this.filterIsInstance<ResourceSteadyState<R>>()


suspend fun <T> Flow<ResourceState<T>>.waitForSuccess(): T {
    return this.filterIsInstance<ResourceState.Success<T>>()
        .map { it.data }
        .first()
}