package osg.uiZone.profile

sealed class EditDialogToShow {
    data class RemovePicture(
        val idx: Int,
    ) : EditDialogToShow()
    data object Pick: EditDialogToShow()
    data object Loading : EditDialogToShow()
    data object AskPermission : EditDialogToShow()
    data object None : EditDialogToShow()
}