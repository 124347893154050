package bot

import com.osg.appData.candidate.Reaction
import com.osg.appData.common.UserData
import com.osg.appData.matchZone.Message
import com.osg.appData.matchZone.invitation.Invitation
import com.osg.def.database.DatabaseService
import com.osg.truebase.ice.events.IBaseBotActionRepo
import com.osg.truebase.ice.events.botRepo

interface IBotActionRepo: IBaseBotActionRepo {
    suspend fun pushMessage(path: String, message: Message)
    suspend fun pushInvitation(path: String, data: Invitation)
    suspend fun fetchUsersData(path: String): Map<String, UserData>
    suspend fun setReaction(path: String, data: Reaction)
}

val DatabaseService.tmBotRepo: IBotActionRepo
    get() = object : IBotActionRepo, IBaseBotActionRepo by this.botRepo {
        override suspend fun pushMessage(path: String, message: Message) {
            push(path, message)
        }

        override suspend fun pushInvitation(path: String, data: Invitation) {
            push(path, data)
        }

        override suspend fun fetchUsersData(path: String): Map<String, UserData> {
            return fetchObject(path)
        }

        override suspend fun setReaction(path: String, data: Reaction) {
            setNode(path, data)
        }
    }