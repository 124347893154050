package osg.uiZone.matchZone

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.imePadding
import androidx.compose.foundation.layout.navigationBarsPadding
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import osg.uiZone.expect.media.RecordOperations
import osg.uiZone.matchZone.chat.ChatMessages
import osg.uiZone.matchZone.chat.UserInputChatBox
import osg.uiZone.matchZone.sparkMove.SparkMovesCollection


@Composable
fun ChatScreen(
    chatZoneUiData: ChatZoneUiData,
    sendMessage: (String) -> Unit,
    modifier: Modifier,
    onRecorderOps: (RecordOperations) -> Unit = {},
    onSparkMoveClick: (SparkMovesCollection) -> Unit,
) {
    Column(
        modifier
    ) {
        ChatMessages(
            conversation = chatZoneUiData.conversation,
            myUid = chatZoneUiData.myUid,
            modifier = Modifier.weight(1f),
        )
        // let this element handle the padding so that the elevation is shown behind the navigation bar
        UserInputChatBox(
            modifier = Modifier
                .navigationBarsPadding()
                .imePadding(),
            onSendChatClickListener = sendMessage,
            onRecorderOps = onRecorderOps,
            onSparkMoveClick = onSparkMoveClick
        )
    }
}