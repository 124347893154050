package osg.uiZone.search.screens

import androidx.compose.animation.AnimatedContent
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import com.osg.appData.candidate.Decision
import com.osg.appData.candidate.FinalDecision
import com.osg.truebase.ui.animation.LottieAnimationView
import com.osg.truebase.ui.animation.toResource
import com.osg.utils.time.TimeService
import osg.uiZone.common.pyrotechnics.navigation.PreviewType
import osg.uiZone.common.pyrotechnics.navigation.searchTransition
import osg.uiZone.expect.permission.PermissionHandlerScreen
import osg.uiZone.expect.permission.PermissionStatus
import osg.uiZone.expect.permission.PermissionUiRationale
import osg.uiZone.search.components.CandidatePromoCard
import osg.uiZone.search.ds.SearchScreenState
import uiZone.trueAutoResources.LocalAnimations


@Composable
fun SearchScreen(
    searchScreenState: SearchScreenState,
    onDecision: (FinalDecision) -> Unit,
    showTopBar: (Boolean) -> Unit,
    onNotificationApproved: () -> Unit,
    onPreferences: () -> Unit = {},
) {
    when (searchScreenState) {
        is SearchScreenState.Search -> {
            val firstSightTime by remember(searchScreenState.currentCandidate.uid) {
                mutableStateOf(TimeService.getUtcTime())
            }
            var previewType by remember(
                searchScreenState.currentCandidate.uid
            ) {
                mutableStateOf<PreviewType>(
                    PreviewType.Promo(searchScreenState)
                )
            }
            showTopBar(previewType is PreviewType.Promo)
            SearchMainScreen(
                previewType = previewType,
                onDecisionLambda = { decision ->
                    onDecision(
                        FinalDecision(
                            candidateProfile = searchScreenState.currentCandidate,
                            decision = decision,
                            firstSightTime = TimeService.getUtcTime() - firstSightTime,
                            candidateIdx = searchScreenState.candidateIdx
                        )
                    )
                },
                onLikeToSeeMore = { previewType = PreviewType.Preview(searchScreenState) },
                onBackToPromo = { previewType = PreviewType.Promo(searchScreenState) },
            )
            if (searchScreenState.candidateIdx == 1) {
                SearchPopup(
                    onNotificationApproved = onNotificationApproved
                )
            }
        }

        is SearchScreenState.NoMoreCandidates -> {
            showTopBar(true)
            NoMoreCandidatesScreen(
                onPreferences = onPreferences,
            )
            SearchPopup(
                onNotificationApproved = onNotificationApproved
            )
        }

        is SearchScreenState.Loading -> {
            LottieAnimationView(
                modifier = Modifier.fillMaxSize(),
                animationData = LocalAnimations.ANIMATION_LOADING_SEARCH.toResource(),
            )
        }
    }
}

@Composable
fun SearchPopup(
    onNotificationApproved: () -> Unit,
) {
    var isShown by remember { mutableStateOf(false) }
    if (isShown.not()) {
        PermissionHandlerScreen(
            permissionType = PermissionUiRationale.NOTIFICATIONS_MATCH,
            onPermissionStatus = {
                isShown = true
                if (it == PermissionStatus.GRANTED) {
                    onNotificationApproved()
                }
            },
        )
    }
}

@Composable
fun SearchMainScreen(
    previewType: PreviewType,
    onLikeToSeeMore: () -> Unit,
    onBackToPromo: () -> Unit,
    onDecisionLambda: (Decision) -> Unit,
) {
    AnimatedContent(
        targetState = previewType,
        transitionSpec = searchTransition,
        label = "Animated SearchScreen state"
    )
    { previewTypeState ->
        when (previewTypeState) {
            is PreviewType.Promo -> {
                SearchPromoScreen(
                    promoData = previewTypeState,
                    onDislike = {
                        onDecisionLambda(Decision.DISLIKE)
                    },
                    onLikeToSeeMore = onLikeToSeeMore,
                )
            }

            is PreviewType.Preview -> {
                CandidatePreview(
                    candidatePreviewState = previewTypeState.profileItemsState,
                    onDecision = onDecisionLambda,
                    onBack = onBackToPromo,
                )
            }
        }
    }
}

@Composable
private fun SearchPromoScreen(
    promoData: PreviewType.Promo,
    onDislike: () -> Unit,
    onLikeToSeeMore: () -> Unit,
) {
    CandidatePromoCard(
        modifier = Modifier,
        promoData = promoData,
        onDislike = onDislike,
        onLikeToSeeMore = onLikeToSeeMore,
    )
}
