package osg.uiZone.common.vmRepo


import com.osg.appData.getPlatform
import com.osg.def.auth.ILoggedStateProvider
import com.osg.def.auth.IUidStatus
import com.osg.truebase.signin.AppSignInData
import com.osg.truebase.signin.ISignInProviders
import com.osg.truebase.signin.SignInScopes
import osg.uiZone.common.baseRepos.auth.injectAuth
import osg.uiZone.common.baseRepos.auth.injectSignIn
import osg.uiZone.common.baseRepos.profile.IMyFullProfileFlow
import osg.uiZone.common.baseRepos.profile.IMyProfileDependencies
import osg.uiZone.common.baseRepos.profile.ISaveMyFullProfile
import osg.uiZone.common.baseRepos.profile.MyProfileScope.Companion.myProfileHandler
import secrets.BuildConfigSecrets

interface ILandingRepository :
    IMyFullProfileFlow,
    ISaveMyFullProfile,
    IUidStatus,
    ISignInProviders,
    ILoggedStateProvider

val appSignInData = AppSignInData(
    clientId = getPlatform().clientId,
    appId = BuildConfigSecrets.APPLICATION_ID,
    scopes = listOf(SignInScopes.Name, SignInScopes.Email),
)

class LandingRepositoryImpl(
    signInProviders: ISignInProviders = injectSignIn(),
) : ILandingRepository,
    IMyProfileDependencies by myProfileHandler,
    ILoggedStateProvider by injectAuth(),
    ISignInProviders by signInProviders