package osg.uiZone.matchZone.model

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.osg.appData.matchZone.invitation.InvitationContent
import com.osg.coroutines.dispechers.ioDispatcher
import com.osg.truebase.ui.commonStates.MainViewUiState
import com.osg.truebase.ui.commonStates.toSuccess
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.filterIsInstance
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.stateIn
import kotlinx.coroutines.launch
import osg.uiZone.common.vmRepo.entertainment.IEntertainmentFetcher
import osg.uiZone.common.vmRepo.matchZone.IMatchInfoRepoFlow
import osg.uiZone.common.vmRepo.matchZone.MatchScope
import osg.uiZone.matchZone.state.tmEntUi.EntertainmentDetailsUiType
import osg.uiZone.matchZone.state.tmEntUi.ResponseEntertainmentUi
import osg.uiZone.matchZone.state.tmEntUi.TmEntApproved
import osg.uiZone.matchZone.state.tmEntUi.TmEntertainmentUiType
import osg.uiZone.navigation.DstArgumentHolder
import osg.uiZone.navigation.EntertainmentDetailsDstArgument

fun interface InvitationResponse {
    suspend fun onInvitationResponse(
        response: InvitationContent,
    )
}

interface IEntertainmentDetailsRepo: IEntertainmentFetcher, IMatchInfoRepoFlow, InvitationResponse

class EntertainmentDetailsModel(
    private val entertainmentDetailsRepo: IEntertainmentDetailsRepo = MatchScope().matchZoneRepository
): ViewModel() {
    val uiState: StateFlow<MainViewUiState<TmEntertainmentUiType>> = DstArgumentHolder.argumentStateFlow
        .filterIsInstance<EntertainmentDetailsDstArgument>().map { argType ->
        val detailsUiType = argType.detailsUiType
        val entUi = entertainmentDetailsRepo.fetchEntertainment(
            id= detailsUiType.contentId,
            type = detailsUiType.type,
        )

        when(detailsUiType){
            is EntertainmentDetailsUiType.ExistingInvitation -> {
                ResponseEntertainmentUi(
                    invitation = detailsUiType.invitation,
                    entertainment = entUi
                )
            }

            is EntertainmentDetailsUiType.ApprovedInvitation -> {
                TmEntApproved(
                    invitation = detailsUiType.invitation,
                    entertainment = entUi
                )
            }

            is EntertainmentDetailsUiType.NewInvitation -> {
                entUi
            }
        }.toSuccess()
    }.stateIn(
        scope = viewModelScope,
        started = kotlinx.coroutines.flow.SharingStarted.WhileSubscribed(5_000),
        initialValue = MainViewUiState.Loading
    )


    fun onInvitationResponse(
        response: InvitationContent,
    ) {
        // since even if the view model popped before the coroutine is finished
        CoroutineScope(ioDispatcher()).launch {
            entertainmentDetailsRepo.onInvitationResponse(response)
        }
    }

}