package spark.media.trueAutoResources


enum class SparksMediaLocalImages(override val mediaPath: String): com.osg.truebase.data.resources.ILocalImages {
	BIKE("composeResources/truematch.spark_media.generated.resources/files/spark/bike.webp"),
	ESCAPE_ROOM("composeResources/truematch.spark_media.generated.resources/files/spark/escape_room.webp"),
	SPA("composeResources/truematch.spark_media.generated.resources/files/spark/spa.webp"),
	PAINT_DATE("composeResources/truematch.spark_media.generated.resources/files/spark/paint_date.webp"),
	COUPON_DIZZY("composeResources/truematch.spark_media.generated.resources/files/spark/coupon_dizzy.webp"),
	BENEDICT_COUPON("composeResources/truematch.spark_media.generated.resources/files/spark/benedict_coupon.webp"),
	COFFEE("composeResources/truematch.spark_media.generated.resources/files/spark/coffee.webp"),
	FOO_COUPON("composeResources/truematch.spark_media.generated.resources/files/spark/foo_coupon.webp"),
	CONCERT("composeResources/truematch.spark_media.generated.resources/files/spark/concert.webp"),
	HORSES("composeResources/truematch.spark_media.generated.resources/files/spark/horses.webp");
}

val SparksMediaLocalAllMedia: List<com.osg.truebase.data.resources.ILocalMediaResource> = SparksMediaLocalImages.entries