package com.osg.ui.core.commonStates

import androidx.compose.animation.core.*
import androidx.compose.runtime.Composable
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.TileMode
import androidx.compose.ui.graphics.drawscope.DrawScope
import androidx.compose.ui.graphics.painter.Painter

class LoadingPainter(
    val offset: Float,
    val brushSize: Float,
    val shimmerColors: List<Color>,
): Painter() {
    override val intrinsicSize: Size = Size(1000f, 1000f)

    override fun DrawScope.onDraw() {
        drawRect(
            brush = Brush.Companion.linearGradient(
                tileMode = TileMode.Mirror,
                colors = shimmerColors,
                start = Offset(offset, y = offset ),
                end = Offset(brushSize + offset, y = brushSize + offset ),
            ),
        )
    }
}



@Composable
fun loadingPainterState(
    shimmerColors: List<Color> = listOf(
        Color(0x80EBEBF4),
        Color(0xFFF4F4F4),
        Color(0x80EBEBF4),
    ),
): Painter{

    val transition = rememberInfiniteTransition(label = "")
    val brushSize = 400f
    val translateAnimation = transition.animateFloat(
        initialValue = 0f,
        targetValue = brushSize * 2f,
        animationSpec = infiniteRepeatable(
            animation = tween(
                durationMillis = 8000,
                easing = LinearEasing,
            ),
            repeatMode = RepeatMode.Restart,
        ),
        label = "Shimmer loading animation",
    )

    return LoadingPainter(
        offset = translateAnimation.value,
        brushSize = brushSize,
        shimmerColors = shimmerColors,
    )
}