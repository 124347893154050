package com.osg.trumatch.bots

import com.osg.appData.bots.TestUsers
import com.osg.appData.profile.FullProfile
import com.osg.appData.profile.PublicProfile
import com.osg.appData.profile.UserStage
import com.osg.trumatch.bots.nonProd.UserTestStage
import com.osg.trumatch.bots.simProfiles.julietProfile
import com.osg.trumatch.bots.simProfiles.oriProfile

val TestUsers.fullProfile: FullProfile
    get() {
        val profile = when(this){
            TestUsers.MaleTestUser -> oriProfile
            TestUsers.MaleTestUser2 -> oriProfile
            TestUsers.FemaleTestUser -> julietProfile
        }
        return profile.copy(
            publicProfile = profile.publicProfile.copy(
                uid = this.uid
            )
        )
    }

fun TestUsers.changeUserStage(stage: UserTestStage): FullProfile {
    return when(stage) {
        UserTestStage.START_REGISTRATION -> {
            FullProfile(
                publicProfile = PublicProfile(
                    uid = this.uid
                )
            )
        }
        UserTestStage.UPLOAD_PROFILE_PICTURE -> {
            fullProfile.copy(
                publicProfile = this.fullProfile.publicProfile.copy(
                    stage = UserStage.NEW
                )
            )
        }
        else -> {
            this.fullProfile
        }
    }
}