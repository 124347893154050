package osg.uiZone.profile

import com.osg.truebase.ui.dialog.ImageBytes
import osg.uiZone.matchZone.model.EditImageType
import osg.uiZone.matchZone.model.NewImage

fun List<EditImageType>.editImage(
    editImageCommand: EditImageCommand,
): List<EditImageType> {
    return when (editImageCommand) {
        is EditImageCommand.Edit -> {
            replaceImage(editImageCommand.idx, editImageCommand.imageBytes)
        }

        is EditImageCommand.Delete -> deleteImageComment(editImageCommand.idx)
        is EditImageCommand.Add -> addImage(editImageCommand.imageBytes)
    }
}


fun List<EditImageType>.deleteImageComment(
    idx: Int,
): List<EditImageType> {
    return filterIndexed { index, _ -> index != idx }
}

fun List<EditImageType>.replaceImage(
    idx: Int,
    imageBytes: ImageBytes,
): List<EditImageType> {
    val image = NewImage(imageBytes)
    return toMutableList().apply {
        set(idx, image)
    }
}

fun List<EditImageType>.addImage(
    imageBytes: ImageBytes,
) : List<EditImageType>{
    val image = NewImage(imageBytes)
    return this + image
}