package osg.uiZone.matchZone.entertainment.baseContent

import com.osg.appData.matchZone.ice.IcebreakerCollection
import com.osg.truebase.data.iceDef.IceTag
import com.osg.ui.core.commonStates.asTrueImage
import com.osg.ui.core.resources.uiText
import osg.uiZone.matchZone.entertainment.TMEntertainmentDataUiBase
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.who_wants_description
import truematch.uizone.generated.resources.who_wants_name
import truematch.uizone.generated.resources.who_wants_slogan
import uiZone.trueAutoResources.LocalImages

val whoWantsBase = TMEntertainmentDataUiBase(
    description = Res.string.who_wants_description.uiText,
    image = LocalImages.WHO_WANTS.asTrueImage(),
    name = Res.string.who_wants_name.uiText,
    
    unlockStage = 1,
    slogan = Res.string.who_wants_slogan.uiText,
    vibes = listOf(IceTag.Trivia),
    id = IcebreakerCollection.WhoWants.name,
)