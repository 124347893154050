package com.osg.appData.ml


import com.osg.appData.matchZone.Message
import com.osg.appData.profile.PublicProfile
import kotlinx.serialization.Serializable


@Serializable
data class LlmUnmatchChatRequest(
    val chatPath: String = "",
    val myProfile: PublicProfile = PublicProfile(),
    val candidateProfile: PublicProfile = PublicProfile(),
    val messages: List<Message> = emptyList(),
)