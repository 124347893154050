package com.osg.trumatch.rootdata

import com.osg.appData.candidate.Decision
import com.osg.appData.candidate.Reaction
import com.osg.appData.common.DataRoot
import com.osg.appData.common.UserData
import com.osg.appData.common.UsersInteractRecord
import com.osg.appData.profile.FullProfile
import com.osg.appData.profile.TechnicalProfile
import com.osg.appData.profile.asTechnical
import com.osg.truebase.data.user.Gender
import com.osg.trumatch.bots.nonProd.Bots


fun buildRoot(
    clonedDataRoot: DataRoot,
): DataRoot{
    val users = Bots.entries.map {
        it.fullProfile
    }


    val usersData = users.map {
        UserData(
            profile = it,
            interactRecord = buildInteractionRecord(it)
        )
    } + clonedDataRoot.usersData.values

    val userDataMap = usersData.associateBy {
        it.profile.publicProfile.uid
    }

    val usersMap = buildUserMap(usersData.map { it.profile })
    return clonedDataRoot.copy(
        usersData = userDataMap,
        userMap = usersMap,
        entertainmentStore = entStore,
    )

}
private fun buildInteractionRecord(
    profile: FullProfile,
): UsersInteractRecord{
    return UsersInteractRecord(
        onUserReaction = Bots.entries.associate {
            it.fullProfile.publicProfile.uid to Reaction(
                fromUid = it.fullProfile.publicProfile.uid,
                toUid = profile.publicProfile.uid,
                firstSightDuration = 1000,
                decision = Decision.LIKE
            )
        }
    )
}

private fun buildUserMap(
    users: List<FullProfile>
): Map<Gender, Map<String, TechnicalProfile>> {
    val genderMap = emptyMap<Gender, Map<String, TechnicalProfile>>().toMutableMap()
    val usersUidMap = users.associateBy {
        it.publicProfile.uid
    }.mapValues {
        it.value.asTechnical()
    }

    Gender.entries.forEach { gender ->
        genderMap[gender] = usersUidMap.filterKeys {
            usersUidMap[it]!!.gender == gender
        }
    }



    return genderMap
}