package com.osg.def.database

import com.osg.truebase.data.nodeData.QueryType
import com.osg.truebase.data.nodeData.TransactionTarget
import kotlinx.serialization.Serializable

interface IAppConfig{
    val emulatorIp: String?
    val printDebugInfo: Boolean
}

fun interface LaunchStringResolver {
    fun resolve(): String?
}

@Serializable
data class EnvironmentConfig(
    override val emulatorIp: String? = null,
    override val printDebugInfo: Boolean = false,
): IAppConfig

enum class FirebaseServicesSpec(
    val emulatorPort: Int,
) {
    Functions(5001),
    Storage(5002),
    Database(5003),
    Auth(9099);
}

data class KmpSnapshot(
    val key: String,
    val serializedValue: String?
)



fun interface IDisconnect{
    fun setFalseOnDisconnect(path: String)
}

fun interface IRemoveNode{
    suspend fun removeNode(nodePath: String)
}

interface IDatabaseEngine: IDisconnect, IRemoveNode {
    suspend fun setPrimitiveNode(path: String, value: Any)
    suspend fun setNode(path: String, serializedValue: String)
    suspend fun pushNode(path: String, serializedValue: String): String?
    suspend fun updateNode(path: String, serializedValue: String)
    suspend fun fetchObject(path: String): String
    suspend fun runQuery(path: String, queryType: QueryType): String
    fun observeNewChild(
        path: String,
        completionHandler: (KmpSnapshot?, String?) -> Unit
    ): ListenerRegistration

    fun observeNewChild(
        path: String,
        query: QueryType.StartAt,
        completionHandler: (KmpSnapshot?, String?) -> Unit
    ): ListenerRegistration

    fun observeValueChange(
        path: String,
        completionHandler: (String?, Exception?) -> Unit
    ): ListenerRegistration

    suspend fun transaction(path:String, transaction: (String?) -> TransactionTarget<String>): Boolean
}