@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.osg.truebase.ui_strings.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val Soon: StringResource by 
      lazy { init_Soon() }

  public val apple_login: StringResource by 
      lazy { init_apple_login() }

  public val auth_error: StringResource by 
      lazy { init_auth_error() }

  public val back: StringResource by 
      lazy { init_back() }

  public val choose_one: StringResource by 
      lazy { init_choose_one() }

  public val close: StringResource by 
      lazy { init_close() }

  public val collision_detected_body: StringResource by 
      lazy { init_collision_detected_body() }

  public val collision_detected_title: StringResource by 
      lazy { init_collision_detected_title() }

  public val confirm: StringResource by 
      lazy { init_confirm() }

  public val continueToNext: StringResource by 
      lazy { init_continueToNext() }

  public val current_of_total: StringResource by 
      lazy { init_current_of_total() }

  public val dismiss: StringResource by 
      lazy { init_dismiss() }

  public val done: StringResource by 
      lazy { init_done() }

  public val email_name: StringResource by 
      lazy { init_email_name() }

  public val error_title: StringResource by 
      lazy { init_error_title() }

  public val exit_app: StringResource by 
      lazy { init_exit_app() }

  public val facebook_login: StringResource by 
      lazy { init_facebook_login() }

  public val female: StringResource by 
      lazy { init_female() }

  public val google_login: StringResource by 
      lazy { init_google_login() }

  public val location_error: StringResource by 
      lazy { init_location_error() }

  public val location_not_supported: StringResource by 
      lazy { init_location_not_supported() }

  public val male: StringResource by 
      lazy { init_male() }

  public val network_error: StringResource by 
      lazy { init_network_error() }

  public val next: StringResource by 
      lazy { init_next() }

  public val permission_error: StringResource by 
      lazy { init_permission_error() }

  public val previous: StringResource by 
      lazy { init_previous() }

  public val record_message: StringResource by 
      lazy { init_record_message() }

  public val settings_account: StringResource by 
      lazy { init_settings_account() }

  public val settings_back: StringResource by 
      lazy { init_settings_back() }

  public val settings_feedback: StringResource by 
      lazy { init_settings_feedback() }

  public val settings_language: StringResource by 
      lazy { init_settings_language() }

  public val settings_licenses: StringResource by 
      lazy { init_settings_licenses() }

  public val settings_logout: StringResource by 
      lazy { init_settings_logout() }

  public val settings_preferences: StringResource by 
      lazy { init_settings_preferences() }

  public val settings_privacy_policy: StringResource by 
      lazy { init_settings_privacy_policy() }

  public val settings_remove_account: StringResource by 
      lazy { init_settings_remove_account() }

  public val settings_terms_of_service: StringResource by 
      lazy { init_settings_terms_of_service() }

  public val settings_title: StringResource by 
      lazy { init_settings_title() }

  public val sign_button_format: StringResource by 
      lazy { init_sign_button_format() }

  public val some_permissions_are_not_granted: StringResource by 
      lazy { init_some_permissions_are_not_granted() }

  public val swipe_to_cancel_recording: StringResource by 
      lazy { init_swipe_to_cancel_recording() }

  public val touch_and_hold_to_record: StringResource by 
      lazy { init_touch_and_hold_to_record() }

  public val unexpected_error: StringResource by 
      lazy { init_unexpected_error() }

  public val what_gender: StringResource by 
      lazy { init_what_gender() }

  public val your_first_name_is: StringResource by 
      lazy { init_your_first_name_is() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("Soon", CommonMainString0.Soon)
  map.put("apple_login", CommonMainString0.apple_login)
  map.put("auth_error", CommonMainString0.auth_error)
  map.put("back", CommonMainString0.back)
  map.put("choose_one", CommonMainString0.choose_one)
  map.put("close", CommonMainString0.close)
  map.put("collision_detected_body", CommonMainString0.collision_detected_body)
  map.put("collision_detected_title", CommonMainString0.collision_detected_title)
  map.put("confirm", CommonMainString0.confirm)
  map.put("continueToNext", CommonMainString0.continueToNext)
  map.put("current_of_total", CommonMainString0.current_of_total)
  map.put("dismiss", CommonMainString0.dismiss)
  map.put("done", CommonMainString0.done)
  map.put("email_name", CommonMainString0.email_name)
  map.put("error_title", CommonMainString0.error_title)
  map.put("exit_app", CommonMainString0.exit_app)
  map.put("facebook_login", CommonMainString0.facebook_login)
  map.put("female", CommonMainString0.female)
  map.put("google_login", CommonMainString0.google_login)
  map.put("location_error", CommonMainString0.location_error)
  map.put("location_not_supported", CommonMainString0.location_not_supported)
  map.put("male", CommonMainString0.male)
  map.put("network_error", CommonMainString0.network_error)
  map.put("next", CommonMainString0.next)
  map.put("permission_error", CommonMainString0.permission_error)
  map.put("previous", CommonMainString0.previous)
  map.put("record_message", CommonMainString0.record_message)
  map.put("settings_account", CommonMainString0.settings_account)
  map.put("settings_back", CommonMainString0.settings_back)
  map.put("settings_feedback", CommonMainString0.settings_feedback)
  map.put("settings_language", CommonMainString0.settings_language)
  map.put("settings_licenses", CommonMainString0.settings_licenses)
  map.put("settings_logout", CommonMainString0.settings_logout)
  map.put("settings_preferences", CommonMainString0.settings_preferences)
  map.put("settings_privacy_policy", CommonMainString0.settings_privacy_policy)
  map.put("settings_remove_account", CommonMainString0.settings_remove_account)
  map.put("settings_terms_of_service", CommonMainString0.settings_terms_of_service)
  map.put("settings_title", CommonMainString0.settings_title)
  map.put("sign_button_format", CommonMainString0.sign_button_format)
  map.put("some_permissions_are_not_granted", CommonMainString0.some_permissions_are_not_granted)
  map.put("swipe_to_cancel_recording", CommonMainString0.swipe_to_cancel_recording)
  map.put("touch_and_hold_to_record", CommonMainString0.touch_and_hold_to_record)
  map.put("unexpected_error", CommonMainString0.unexpected_error)
  map.put("what_gender", CommonMainString0.what_gender)
  map.put("your_first_name_is", CommonMainString0.your_first_name_is)
}

public val Res.string.Soon: StringResource
  get() = CommonMainString0.Soon

private fun init_Soon(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Soon", "Soon",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/shared.commonMain.cvr",
    10, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/shared.commonMain.cvr",
    10, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/shared.commonMain.cvr",
    10, 20),
    )
)

public val Res.string.apple_login: StringResource
  get() = CommonMainString0.apple_login

private fun init_apple_login(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:apple_login", "apple_login",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/login.commonMain.cvr",
    10, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/login.commonMain.cvr",
    10, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/login.commonMain.cvr",
    10, 27),
    )
)

public val Res.string.auth_error: StringResource
  get() = CommonMainString0.auth_error

private fun init_auth_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:auth_error", "auth_error",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/error_strings.commonMain.cvr",
    10, 106),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/error_strings.commonMain.cvr",
    10, 106),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/error_strings.commonMain.cvr",
    10, 78),
    )
)

public val Res.string.back: StringResource
  get() = CommonMainString0.back

private fun init_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:back", "back",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/shared.commonMain.cvr",
    39, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/shared.commonMain.cvr",
    39, 24),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/shared.commonMain.cvr",
    31, 20),
    )
)

public val Res.string.choose_one: StringResource
  get() = CommonMainString0.choose_one

private fun init_choose_one(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_one", "choose_one",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/register.commonMain.cvr",
    10, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/register.commonMain.cvr",
    10, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/register.commonMain.cvr",
    10, 34),
    )
)

public val Res.string.close: StringResource
  get() = CommonMainString0.close

private fun init_close(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:close", "close",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/shared.commonMain.cvr",
    64, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/shared.commonMain.cvr",
    64, 25),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/shared.commonMain.cvr",
    52, 21),
    )
)

public val Res.string.collision_detected_body: StringResource
  get() = CommonMainString0.collision_detected_body

private fun init_collision_detected_body(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:collision_detected_body", "collision_detected_body",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/login.commonMain.cvr",
    38, 135),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/login.commonMain.cvr",
    38, 135),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/login.commonMain.cvr",
    38, 119),
    )
)

public val Res.string.collision_detected_title: StringResource
  get() = CommonMainString0.collision_detected_title

private fun init_collision_detected_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:collision_detected_title", "collision_detected_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/login.commonMain.cvr",
    174, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/login.commonMain.cvr",
    174, 68),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/login.commonMain.cvr",
    158, 56),
    )
)

public val Res.string.confirm: StringResource
  get() = CommonMainString0.confirm

private fun init_confirm(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:confirm", "confirm",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/login.commonMain.cvr",
    243, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/login.commonMain.cvr",
    243, 23),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/login.commonMain.cvr",
    215, 27),
    )
)

public val Res.string.continueToNext: StringResource
  get() = CommonMainString0.continueToNext

private fun init_continueToNext(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:continueToNext", "continueToNext",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/shared.commonMain.cvr",
    90, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/shared.commonMain.cvr",
    90, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/shared.commonMain.cvr",
    74, 34),
    )
)

public val Res.string.current_of_total: StringResource
  get() = CommonMainString0.current_of_total

private fun init_current_of_total(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:current_of_total", "current_of_total",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/shared.commonMain.cvr",
    125, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/shared.commonMain.cvr",
    125, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/shared.commonMain.cvr",
    109, 28),
    )
)

public val Res.string.dismiss: StringResource
  get() = CommonMainString0.dismiss

private fun init_dismiss(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dismiss", "dismiss",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/shared.commonMain.cvr",
    162, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/shared.commonMain.cvr",
    162, 23),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/shared.commonMain.cvr",
    138, 27),
    )
)

public val Res.string.done: StringResource
  get() = CommonMainString0.done

private fun init_done(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:done", "done",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/shared.commonMain.cvr",
    186, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/shared.commonMain.cvr",
    186, 24),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/shared.commonMain.cvr",
    166, 20),
    )
)

public val Res.string.email_name: StringResource
  get() = CommonMainString0.email_name

private fun init_email_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:email_name", "email_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/contact.commonMain.cvr",
    10, 26),
    )
)

public val Res.string.error_title: StringResource
  get() = CommonMainString0.error_title

private fun init_error_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:error_title", "error_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/error_strings.commonMain.cvr",
    117, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/error_strings.commonMain.cvr",
    117, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/error_strings.commonMain.cvr",
    89, 51),
    )
)

public val Res.string.exit_app: StringResource
  get() = CommonMainString0.exit_app

private fun init_exit_app(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:exit_app", "exit_app",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/error_strings.commonMain.cvr",
    165, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/error_strings.commonMain.cvr",
    165, 24),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/error_strings.commonMain.cvr",
    141, 24),
    )
)

public val Res.string.facebook_login: StringResource
  get() = CommonMainString0.facebook_login

private fun init_facebook_login(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:facebook_login", "facebook_login",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/login.commonMain.cvr",
    267, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/login.commonMain.cvr",
    267, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/login.commonMain.cvr",
    243, 34),
    )
)

public val Res.string.female: StringResource
  get() = CommonMainString0.female

private fun init_female(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:female", "female",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/register.commonMain.cvr",
    49, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/register.commonMain.cvr",
    49, 26),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/register.commonMain.cvr",
    45, 22),
    )
)

public val Res.string.google_login: StringResource
  get() = CommonMainString0.google_login

private fun init_google_login(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:google_login", "google_login",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/login.commonMain.cvr",
    310, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/login.commonMain.cvr",
    310, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/login.commonMain.cvr",
    278, 28),
    )
)

public val Res.string.location_error: StringResource
  get() = CommonMainString0.location_error

private fun init_location_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:location_error", "location_error",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/error_strings.commonMain.cvr",
    190, 110),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/error_strings.commonMain.cvr",
    190, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/error_strings.commonMain.cvr",
    166, 74),
    )
)

public val Res.string.location_not_supported: StringResource
  get() = CommonMainString0.location_not_supported

private fun init_location_not_supported(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:location_not_supported", "location_not_supported",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/error_strings.commonMain.cvr",
    301, 94),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/error_strings.commonMain.cvr",
    301, 94),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/error_strings.commonMain.cvr",
    241, 78),
    )
)

public val Res.string.male: StringResource
  get() = CommonMainString0.male

private fun init_male(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:male", "male",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/register.commonMain.cvr",
    76, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/register.commonMain.cvr",
    76, 20),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/register.commonMain.cvr",
    68, 20),
    )
)

public val Res.string.network_error: StringResource
  get() = CommonMainString0.network_error

private fun init_network_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:network_error", "network_error",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/error_strings.commonMain.cvr",
    396, 101),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/error_strings.commonMain.cvr",
    396, 101),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/error_strings.commonMain.cvr",
    320, 73),
    )
)

public val Res.string.next: StringResource
  get() = CommonMainString0.next

private fun init_next(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:next", "next",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/shared.commonMain.cvr",
    211, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/shared.commonMain.cvr",
    211, 20),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/shared.commonMain.cvr",
    187, 20),
    )
)

public val Res.string.permission_error: StringResource
  get() = CommonMainString0.permission_error

private fun init_permission_error(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:permission_error", "permission_error",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/error_strings.commonMain.cvr",
    498, 112),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/error_strings.commonMain.cvr",
    498, 112),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/error_strings.commonMain.cvr",
    394, 80),
    )
)

public val Res.string.previous: StringResource
  get() = CommonMainString0.previous

private fun init_previous(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:previous", "previous",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/shared.commonMain.cvr",
    232, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/shared.commonMain.cvr",
    232, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/shared.commonMain.cvr",
    208, 28),
    )
)

public val Res.string.record_message: StringResource
  get() = CommonMainString0.record_message

private fun init_record_message(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:record_message", "record_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/login.commonMain.cvr",
    343, 58),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/login.commonMain.cvr",
    343, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/login.commonMain.cvr",
    307, 50),
    )
)

public val Res.string.settings_account: StringResource
  get() = CommonMainString0.settings_account

private fun init_settings_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settings_account", "settings_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/settings.commonMain.cvr",
    10, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/settings.commonMain.cvr",
    10, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/settings.commonMain.cvr",
    10, 36),
    )
)

public val Res.string.settings_back: StringResource
  get() = CommonMainString0.settings_back

private fun init_settings_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settings_back", "settings_back",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/settings.commonMain.cvr",
    51, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/settings.commonMain.cvr",
    51, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/settings.commonMain.cvr",
    47, 29),
    )
)

public val Res.string.settings_feedback: StringResource
  get() = CommonMainString0.settings_feedback

private fun init_settings_feedback(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settings_feedback", "settings_feedback",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/settings.commonMain.cvr",
    85, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/settings.commonMain.cvr",
    85, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/settings.commonMain.cvr",
    77, 41),
    )
)

public val Res.string.settings_language: StringResource
  get() = CommonMainString0.settings_language

private fun init_settings_language(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settings_language", "settings_language",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/settings.commonMain.cvr",
    131, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/settings.commonMain.cvr",
    131, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/settings.commonMain.cvr",
    119, 37),
    )
)

public val Res.string.settings_licenses: StringResource
  get() = CommonMainString0.settings_licenses

private fun init_settings_licenses(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settings_licenses", "settings_licenses",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/settings.commonMain.cvr",
    165, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/settings.commonMain.cvr",
    165, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/settings.commonMain.cvr",
    157, 37),
    )
)

public val Res.string.settings_logout: StringResource
  get() = CommonMainString0.settings_logout

private fun init_settings_logout(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settings_logout", "settings_logout",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/settings.commonMain.cvr",
    211, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/settings.commonMain.cvr",
    211, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/settings.commonMain.cvr",
    195, 31),
    )
)

public val Res.string.settings_preferences: StringResource
  get() = CommonMainString0.settings_preferences

private fun init_settings_preferences(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settings_preferences", "settings_preferences",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/settings.commonMain.cvr",
    251, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/settings.commonMain.cvr",
    251, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/settings.commonMain.cvr",
    227, 44),
    )
)

public val Res.string.settings_privacy_policy: StringResource
  get() = CommonMainString0.settings_privacy_policy

private fun init_settings_privacy_policy(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settings_privacy_policy", "settings_privacy_policy",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/settings.commonMain.cvr",
    296, 67),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/settings.commonMain.cvr",
    296, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/settings.commonMain.cvr",
    272, 51),
    )
)

public val Res.string.settings_remove_account: StringResource
  get() = CommonMainString0.settings_remove_account

private fun init_settings_remove_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settings_remove_account", "settings_remove_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/settings.commonMain.cvr",
    364, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/settings.commonMain.cvr",
    364, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/settings.commonMain.cvr",
    324, 51),
    )
)

public val Res.string.settings_terms_of_service: StringResource
  get() = CommonMainString0.settings_terms_of_service

private fun init_settings_terms_of_service(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settings_terms_of_service", "settings_terms_of_service",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/settings.commonMain.cvr",
    420, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/settings.commonMain.cvr",
    420, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/settings.commonMain.cvr",
    376, 57),
    )
)

public val Res.string.settings_title: StringResource
  get() = CommonMainString0.settings_title

private fun init_settings_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settings_title", "settings_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/settings.commonMain.cvr",
    482, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/settings.commonMain.cvr",
    482, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/settings.commonMain.cvr",
    434, 34),
    )
)

public val Res.string.sign_button_format: StringResource
  get() = CommonMainString0.sign_button_format

private fun init_sign_button_format(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sign_button_format", "sign_button_format",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/login.commonMain.cvr",
    402, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/login.commonMain.cvr",
    402, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/login.commonMain.cvr",
    358, 50),
    )
)

public val Res.string.some_permissions_are_not_granted: StringResource
  get() = CommonMainString0.some_permissions_are_not_granted

private fun init_some_permissions_are_not_granted(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:some_permissions_are_not_granted", "some_permissions_are_not_granted",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/error_strings.commonMain.cvr",
    611, 188),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/error_strings.commonMain.cvr",
    611, 188),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/error_strings.commonMain.cvr",
    475, 140),
    )
)

public val Res.string.swipe_to_cancel_recording: StringResource
  get() = CommonMainString0.swipe_to_cancel_recording

private fun init_swipe_to_cancel_recording(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:swipe_to_cancel_recording", "swipe_to_cancel_recording",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/login.commonMain.cvr",
    457, 73),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/login.commonMain.cvr",
    457, 73),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/login.commonMain.cvr",
    409, 61),
    )
)

public val Res.string.touch_and_hold_to_record: StringResource
  get() = CommonMainString0.touch_and_hold_to_record

private fun init_touch_and_hold_to_record(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:touch_and_hold_to_record", "touch_and_hold_to_record",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/login.commonMain.cvr",
    531, 80),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/login.commonMain.cvr",
    531, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/login.commonMain.cvr",
    471, 64),
    )
)

public val Res.string.unexpected_error: StringResource
  get() = CommonMainString0.unexpected_error

private fun init_unexpected_error(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:unexpected_error", "unexpected_error",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/error_strings.commonMain.cvr",
    800, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/error_strings.commonMain.cvr",
    800, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/error_strings.commonMain.cvr",
    616, 60),
    )
)

public val Res.string.what_gender: StringResource
  get() = CommonMainString0.what_gender

private fun init_what_gender(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:what_gender", "what_gender",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/register.commonMain.cvr",
    97, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/register.commonMain.cvr",
    97, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/register.commonMain.cvr",
    89, 67),
    )
)

public val Res.string.your_first_name_is: StringResource
  get() = CommonMainString0.your_first_name_is

private fun init_your_first_name_is(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:your_first_name_is", "your_first_name_is",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("he"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-he/register.commonMain.cvr",
    145, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("iw"),
    ),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values-iw/register.commonMain.cvr",
    145, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_strings.generated.resources/values/register.commonMain.cvr",
    157, 58),
    )
)
