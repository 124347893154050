package com.osg.truebase.ui.button

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Send
import androidx.compose.material.icons.filled.Close
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.ui.graphics.Color
import com.osg.truebase.data.iceDef.InviteAction
import com.osg.truebase.ui_button.generated.resources.Res
import com.osg.truebase.ui_button.generated.resources.baseline_event_available_24

import com.osg.ui.core.resources.UiText
import com.osg.ui.core.commonStates.TrueImage
import com.osg.ui.core.commonStates.TrueVector
import com.osg.ui.core.commonStates.asPainter
import com.osg.ui.core.commonStates.asTrueVector
import com.osg.ui.core.commonStates.asVector


sealed interface FabItem {
    val label: UiText
    val identifier: String

    @Composable
    fun Content()
}

@Immutable
data class FabItemVector(
    val trueVector: TrueVector,
    override val label: UiText = UiText.Empty,
    override val identifier: String = "",
) : FabItem {
    @Composable
    override fun Content() {
        Icon(
            imageVector = trueVector.asVector(),
            contentDescription = null,
        )
    }
}

@Immutable
data class FabItemPainter(
    val iconPainter: TrueImage,
    override val label: UiText = UiText.Empty,
    override val identifier: String,
) : FabItem {
    @Composable
    override fun Content() {
        Icon(
            painter = iconPainter.asPainter(),
            contentDescription = null,
            tint = Color.Unspecified
        )
    }
}

val InviteAction.fabItem: FabItem
    get() {
        return when (this) {
            InviteAction.NEW -> FabItemVector(
                trueVector = Icons.AutoMirrored.Filled.Send.asTrueVector(),
                label = UiText.Empty,
                identifier = this.name
            )
            InviteAction.APPROVED -> {
                FabItemVector(
                    trueVector = Res.drawable.baseline_event_available_24.asTrueVector(),
                    label = UiText.Empty,
                    identifier = this.name
                )
            }
            InviteAction.REJECTED -> {
                FabItemVector(
                    trueVector = Icons.Default.Close.asTrueVector(),
                    label = UiText.Empty,
                    identifier = this.name
                )
            }
        }
    }
