package osg.uiZone.graph

import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavController
import androidx.navigation.NavGraphBuilder
import androidx.navigation.compose.composable
import com.osg.truebase.ui.commonStates.MainViewUi
import com.osg.truebase.ui.screens.settings.SettingsScreen
import osg.uiZone.common.settings.SettingModel
import osg.uiZone.common.settings.commonUrls
import osg.uiZone.navigation.LandingDestination
import osg.uiZone.navigation.ProfileEditDestination
import osg.uiZone.navigation.SettingsDestination
import osg.uiZone.profile.EditProfileViewModel
import osg.uiZone.profile.ProfileEditScreen

fun NavGraphBuilder.commonGraph(
    navController: NavController
) {
    composable<SettingsDestination> {
        val settingsModel = viewModel { SettingModel() }
        SettingsScreen(
            onLogOut = {
                settingsModel.onLogOut {
                    navController.navigate(LandingDestination)
                }
            },
            onRemoveAccount = {
                settingsModel.onRemoveClicked {
                    navController.navigate(LandingDestination) {
                        popUpTo(LandingDestination){
                            inclusive = true
                        }
//                        val startDst = navController.graph.findStartDestination()
//                        popUpTo(startDst) {
//                            inclusive = true
//                        }
                    }
                }
            },
            onSelectLanguage = settingsModel::onUpdateSettings,
            urlStringResList = commonUrls,
            onBack = { navController.navigateUp() },
        )
    }
    composable<ProfileEditDestination> {
        val editProfileViewModel = viewModel { EditProfileViewModel() }
        val editProfileScreenState by editProfileViewModel.editUiState.collectAsState()
        editProfileScreenState.MainViewUi {
            ProfileEditScreen(
                editProfileScreenState = it,
                onExit = navController::navigateUp,
                onSave = editProfileViewModel::saveProfile
            )
        }

    }
}