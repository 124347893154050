package osg.uiZone.profile

import androidx.compose.runtime.Immutable
import com.osg.appData.candidate.MatchIncentive
import com.osg.appData.profile.IPublicProfile
import com.osg.appData.profile.OpenAnswer
import com.osg.truebase.data.user.UserLocation
import com.osg.ui.core.commonStates.TrueImage
import com.osg.ui.core.resources.UiText
import com.osg.ui.core.resources.toUiText
import com.osg.ui.core.resources.uiText
import osg.uiZone.register.data.questionData


@Immutable
class CoverImage(
    val truePicture: TrueImage,
    val itemActionOptions: List<ItemToolOption<EditImageCommand>> = emptyList()
)

data class EditableDetails(
    val occupation: String,
    val location: UserLocation
)

@Immutable
data class TechnicalDetails(
    val name: String,
    val age: String,
    val occupation: String,
    val country: String,
    val city: String,
    val verified: Boolean,
    val itemActionOptions: List<ItemToolOption<EditableDetails>> = emptyList()
){
    companion object {
        fun IPublicProfile.toTechnicalDetails(
            itemActionOptions: List<ItemToolOption<EditableDetails>> = emptyList()
        ): TechnicalDetails {
            return TechnicalDetails(
                name = firstName,
                age = age.toString(),
                occupation = occupation,
                verified = verified,
                city = location!!.city,
                country = location!!.country,
                itemActionOptions = itemActionOptions
            )
        }
    }
}

@Immutable
sealed class IncentiveCardType {
    @Immutable
    data class TextCard(
        val title: UiText,
        val description: String,
        val profileImage: TrueImage,
        val idx: String,
        val itemActionOptions: List<ItemToolOption<OpenAnswer>> = emptyList()
    ) : IncentiveCardType() {
        companion object {
            fun IPublicProfile.toTextCards(
                itemActionOptions: List<ItemToolOption<OpenAnswer>>,
                trueProfileImage: TrueImage
            ): List<TextCard> {
                return openQuestionAnswer.mapIndexed { idx, question ->
                    TextCard(
                        title = question.questionId.questionData.previewTitle.toUiText(
                            this.firstName
                        ),
                        description = question.answer,
                        profileImage = trueProfileImage,
                        idx = question.questionId.name,
                        itemActionOptions = itemActionOptions
                    )
                }
            }
        }
    }

    @Immutable
    data class AiMatchIncentive(
        val matchIncentive: MatchIncentive,
        val initialExpanded: Boolean = false,
    ) : IncentiveCardType()

    @Immutable
    data class ImageComment(
        val truePicture: TrueImage,
        val profileImage: TrueImage,
        val comment: UiText,
        val candidateName: String,
        val idx: Int,
        val itemActionOptions: List<ItemToolOption<EditImageCommand>> = emptyList()
    ) : IncentiveCardType() {
        companion object {
            fun IPublicProfile.toImageCommentCards(
                itemActionOptions: List<ItemToolOption<EditImageCommand>> = emptyList(),
                profileImage: TrueImage,
                profileImages: List<TrueImage>,
            ): List<ImageComment> {
                return profileImages.mapIndexed {idx, image ->
                    ImageComment(
                        truePicture = image,
                        profileImage = profileImage,
                        candidateName = firstName,
                        itemActionOptions = itemActionOptions,
                        comment = picturesCommentList[idx].comment.uiText,
                        idx = idx + 1
                    )
                }
            }
        }
    }
}


fun <T> blendLists(vararg lists: List<T>): List<T> {
    val totalSize = lists.sumOf { it.size }
    val arrayDeques = lists.map { ArrayDeque(it) }
    return buildList {
        repeat(totalSize) {
            for (i in 0 until lists.size) {
                if (arrayDeques[i].isNotEmpty()) {
                    add(arrayDeques[i].removeFirst())
                    break
                }
            }
        }
    }
}