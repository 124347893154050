package osg.uiZone.matchZone.journey

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.GridItemSpan
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.osg.appData.matchZone.MatchInfo
import com.osg.appData.relationshipStage.computeRelationshipStage
import com.osg.truebase.spotlight.tutorialTarget
import com.osg.truebase.ui.dialog.FunctionalityNotAvailablePopup
import com.osg.ui.core.resources.vector
import osg.uiZone.expect.formatPrice
import osg.uiZone.matchZone.journey.items.*
import osg.uiZone.matchZone.state.JourneyPanelItem
import osg.uiZone.matchZone.state.SparkEvent
import osg.uiZone.matchZone.state.tmEntUi.NewEntertainmentUiData
import osg.uiZone.tutorial.TutorialStepCollection
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.sailboat

val journeyTitleWeight: FontWeight = FontWeight.Bold
val journeyValWeight: FontWeight = FontWeight.Bold

@Composable
fun SharedJourneyScreen(
    items: List<JourneyPanelItem>,
    modifier: Modifier = Modifier
) {
    var functionalityNotAvailablePopupShown by remember { mutableStateOf(false) }
    if (functionalityNotAvailablePopupShown) {
        FunctionalityNotAvailablePopup { functionalityNotAvailablePopupShown = false }
    }
    LazyVerticalGrid(
        modifier = modifier,
        columns = GridCells.Fixed(3),
    ) {
        items(
            items = items,
            span = {
                GridItemSpan(it.spanSize)
            }

        ) { journeyPanelItem ->
            when (journeyPanelItem) {
                is JourneyPanelItem.StageHighlights -> {
                    StoryList(
                        modifier = Modifier.tutorialTarget(TutorialStepCollection.MatchZoneStoryButton),
                        stageHighlights = journeyPanelItem,
                        onHighlightClicked = {
                            functionalityNotAvailablePopupShown = true
                        }
                    )
                }

                is JourneyPanelItem.RelationShipStage -> {
                    RelationShipStageCard(
                        modifier = Modifier.Companion,
                        relationShipStage = journeyPanelItem
                    )
                }

                is JourneyPanelItem.Balance -> {
                    JourneyBalance(
                        journeyBalanceData = journeyPanelItem
                    )
                }

                is JourneyPanelItem.Playlist -> {
                    JourneyPlaylist(
                        journeyPlaylistData = journeyPanelItem
                    )
                }

                is JourneyPanelItem.SaveTheDate -> {
                    JourneyEvents(
                        journeySaveTheData = journeyPanelItem,
                    )
                }
            }
        }
        item(
            span = {
                GridItemSpan(this.maxLineSpan)
            }
        ) {
            Image(
                imageVector = Res.drawable.sailboat.vector,
                contentDescription = null,
                modifier = Modifier.height(100.dp)
            )
        }
    }
}

fun genJourneyPanelItems(
    stageHighLights: List<StageHighLightUi>,
    matchInfo: MatchInfo,
    entData: List<NewEntertainmentUiData>,
    approvedSparkEvents: List<SparkEvent>,
): List<JourneyPanelItem> {
    val currentStage = matchInfo.computeRelationshipStage()
    val journeyPanelItem = mutableListOf(
        JourneyPanelItem.RelationShipStage(
            stage = currentStage,
            entData = entData,
        ),
        JourneyPanelItem.Balance(
            balance = matchInfo.balance.formatPrice()
        ),
        JourneyPanelItem.Playlist(
            playlist = listOf() // TODO: add playlist
        )
    )

    if (approvedSparkEvents.isNotEmpty()) {
        journeyPanelItem.add(
            JourneyPanelItem.SaveTheDate(
                sparkEvents = approvedSparkEvents
            )
        )
    }

    if (stageHighLights.isNotEmpty()) {
        journeyPanelItem.add(
            JourneyPanelItem.StageHighlights(
                currentStage = currentStage,
                highlights = stageHighLights
            )
        )
    }

    return journeyPanelItem.sortedBy { it.order }
}