package com.osg.trumatch.bots.simProfiles

import bots.trueAutoResources.BotsMediaRemoteImages
import com.osg.appData.profile.*
import com.osg.appData.register.OpenDeepQuestions
import com.osg.truebase.data.nodeData.RemoteProfileImage
import com.osg.truebase.data.user.Gender
import com.osg.truebase.preview.data.IsraelLocation

internal val oriProfile = FullProfile(
    personalProfile = PersonalProfile(

        lastName = "Spokoini",
        preferencesMatch = PreferencesMatch(
            ageMax = 35,
            ageMin = 25,
            maxDistance = 100,
            dateGender = Gender.Female,
        ),
        
    ),
    publicProfile = PublicProfile(
        height = 176,
        birthDay = "01/24/1993",
        gender = Gender.Male,
        firstName = "Ori",
        occupation = "TrueMatch Founder",
        openQuestionAnswer = listOf(
            OpenAnswer(
                answer = "Hi, \uD83D\uDC4B By day, I'm a computer engineer, but at night, I'm the dreamer of inventions that could change the world. I find joy in creating and inventing new technologies, as well as in building riverside camps. Engaging in deep conversations about the meaning of life is my forte, but I'm also enthusiastic about discussing last night's reality show. I'm in search of someone who possesses a heart brimming with passion and a spirit filled with dreams. \uD83C\uDF0E",
                questionId = OpenDeepQuestions.INTRODUCTION
            )
        ),
        location = IsraelLocation.RAMAT_GAN.location,
        stage = UserStage.REGISTERED,
        verified = true,
        picturesCommentList = listOf(
            RemoteProfileImage(
                comment = "",
                mediaPath = BotsMediaRemoteImages.ORI1.mediaPath
            ),
            RemoteProfileImage(
                comment = "On set 🎬✨ #ActorLife",
                mediaPath = BotsMediaRemoteImages.ORI2.mediaPath
            ),
            RemoteProfileImage(
                comment = "",
                mediaPath = BotsMediaRemoteImages.ORI3.mediaPath
            )
        ),
    ),
)
