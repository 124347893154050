package com.osg.truebase.shapes

import androidx.compose.ui.geometry.Rect
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Matrix
import androidx.compose.ui.graphics.Outline
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.LayoutDirection
import androidx.graphics.shapes.CornerRounding
import androidx.graphics.shapes.Cubic
import androidx.graphics.shapes.RoundedPolygon

fun RoundedPolygon.getBounds() = calculateBounds().let { Rect(it[0], it[1], it[2], it[3]) }
class RoundedPolygonShape(
    private val polygon: RoundedPolygon,
    private val rotation: Float = 0f,
) : Shape {
    private var matrix: Matrix = Matrix()
    private var path = Path()
    override fun createOutline(
        size: Size,
        layoutDirection: LayoutDirection,
        density: Density
    ): Outline {
        path.rewind()
        path = polygon.toPath()
        matrix.reset()
        val bounds = polygon.getBounds()
        val maxDimension = maxOf(bounds.width, bounds.height)
        matrix.scale(size.width / maxDimension, size.height / maxDimension)
        matrix.translate(-bounds.left, -bounds.top)
        matrix.rotateZ(rotation)
        path.transform(matrix)
        return Outline.Generic(path)
    }

    constructor(
        numVertices: Int,
        rounding: Float,
        rotation: Float = 0f
    ) : this(
        polygon = RoundedPolygon(
            numVertices = numVertices,
            rounding = CornerRounding(rounding),
        ),
        rotation = rotation
    )

}

fun RoundedPolygon.toPath(
    path: Path = Path(),
): Path {
    pathFromCubics(
        path = path,
        cubics = cubics,
    )
    return path
}

fun pathFromCubics(path: Path, cubics: List<Cubic>) {
    var first = true
    path.rewind()
    for (i in 0 until cubics.size) {
        val cubic = cubics[i]
        if (first) {
            path.moveTo(cubic.anchor0X, cubic.anchor0Y)
            first = false
        }
        path.cubicTo(
            cubic.control0X,
            cubic.control0Y,
            cubic.control1X,
            cubic.control1Y,
            cubic.anchor1X,
            cubic.anchor1Y
        )
    }
    path.close()
}