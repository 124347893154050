package com.osg.appData.ml

import com.osg.appData.profile.FullProfile
import com.osg.appData.profile.PublicProfile
import com.osg.truebase.data.user.UserLocation

data class ProfileFeature(
    val name: String,
    val maxOptions: Int,
    val lambda: (FullProfile) -> String,
)

val profileFeaturesExtractors: List<ProfileFeature> = listOf(
    ProfileFeature(
        PublicProfile::uid.name,
        10000
    )
    { it.publicProfile.uid },
    ProfileFeature(UserLocation::city.name, 1000) { it.publicProfile.location?.city?:"" },
    ProfileFeature(PublicProfile::height.name, 100) { it.publicProfile.height.toString() },
)