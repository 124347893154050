package com.osg.trumatch.bots.botProfiles

import bots.trueAutoResources.BotsMediaRemoteImages
import com.osg.appData.profile.*
import com.osg.appData.register.OpenDeepQuestions
import com.osg.truebase.data.nodeData.RemoteProfileImage
import com.osg.truebase.data.user.Gender
import com.osg.truebase.preview.data.IsraelLocation

internal val yaliProfile = FullProfile(
    personalProfile = PersonalProfile(
        facebookId = "10225277936830286",
        lastName = "Smith",
        preferencesMatch = PreferencesMatch(
            ageMax = 35,
            ageMin = 25,
            maxDistance = 200,
            dateGender = Gender.Male
        ),
    ),
    publicProfile = PublicProfile(
        birthDay = "05/15/1997",
        firstName = "Yali",
        gender = Gender.Female,
        height = 169,
        occupation = "Psychology Student",
        picturesCommentList = listOf(
            RemoteProfileImage(
                comment = "",
                mediaPath = BotsMediaRemoteImages.YALI0.mediaPath
            ),
            RemoteProfileImage(
                comment = "Exploring the human mind 🧠 #Psychology",
                mediaPath = BotsMediaRemoteImages.YALI1.mediaPath
            ),
            RemoteProfileImage(
                comment = "",
                mediaPath = BotsMediaRemoteImages.YALI2.mediaPath
            )
        ),
        stage = UserStage.REGISTERED,
        openQuestionAnswer = listOf(
            OpenAnswer(
                answer = "Dedicated psychology student with a passion for understanding human behavior. Looking to connect with like-minded individuals and share meaningful experiences. Let's dive into the depths of the mind together. 🧠 #PsychologyLife #DeepConnections",
                questionId = OpenDeepQuestions.INTRODUCTION
            )
        ),
        location = IsraelLocation.JERUSALEM.location,
    )
)