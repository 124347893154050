package osg.uiZone.register.data

import com.osg.appData.common.QuestionType
import com.osg.appData.register.ScDeepQuestions
import org.jetbrains.compose.resources.StringArrayResource
import org.jetbrains.compose.resources.StringResource
import truematch.uizone.generated.resources.*

data class ScDeepQuestion(
    val title: StringResource,
    val questionType: QuestionType = QuestionType.PRIVATE,
    var directions: StringResource,
    var options: StringArrayResource,
)

val ScDeepQuestions.questionData: ScDeepQuestion
    get() {
        return when(this) {
            ScDeepQuestions.Q1 -> ScDeepQuestion(
                title = Res.string.sc_q1_title,
                directions = Res.string.sc_direction,
                options = Res.array.sc_q1_options,
            )
            ScDeepQuestions.Q2 -> ScDeepQuestion(
                title = Res.string.sc_q2_title,
                directions = Res.string.sc_direction,
                options = Res.array.sc_q2_options,
            )
            ScDeepQuestions.Q3 -> ScDeepQuestion(
                title = Res.string.sc_q3_title,
                directions = Res.string.sc_direction,
                options = Res.array.sc_q3_options,
            )
            ScDeepQuestions.Q4 -> ScDeepQuestion(
                title = Res.string.sc_q4_title,
                directions = Res.string.sc_direction,
                options = Res.array.sc_q4_options,
            )
            ScDeepQuestions.Q5 -> ScDeepQuestion(
                title = Res.string.sc_q5_title,
                directions = Res.string.sc_direction,
                options = Res.array.sc_q5_options,
            )
            ScDeepQuestions.Q6 -> ScDeepQuestion(
                title = Res.string.sc_q6_title,
                directions = Res.string.sc_direction,
                options = Res.array.sc_q6_options,
            )
            ScDeepQuestions.Q7 -> ScDeepQuestion(
                title = Res.string.sc_q7_title,
                directions = Res.string.sc_direction,
                options = Res.array.sc_q1_options,
            )
        }
    }