package com.osg.appData.profile


import com.osg.appData.matchZone.MatchZoneNode
import kotlinx.serialization.Serializable

enum class MatchStatus{
    Active,
    UnMatchedByOther
}

@Serializable
data class MatchZoneData(
    val candidateUid: String,
    val matchZoneNodePath: String,
    val seenPromo: Boolean = false,
    val superLiked: Boolean = false,
    val matchStatus: MatchStatus = MatchStatus.Active
) {
    fun computeInvitationPath(): String {
        return MatchZoneNode.resolveInvitationPath(matchZoneNodePath)
    }

    fun computeChatPath(): String {
        return MatchZoneNode.resolveChatPath(matchZoneNodePath)
    }

    fun computeMatchInfoPath(): String {
        return "$matchZoneNodePath/${MatchZoneNode::matchInfo.name}"
    }
}