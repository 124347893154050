package com.osg.appData.profile


import com.osg.truebase.data.user.SignInIdentifier
import kotlinx.serialization.Serializable

@Serializable
data class PersonalProfile (
    var lastName: String = "",
    var facebookId: String = "",
    var signInType: SignInIdentifier? = null,
    var preferencesMatch: PreferencesMatch = PreferencesMatch(),
    val questionAnswer: Map<String, Int> = emptyMap(),
    var openQuestionAnswer: List<OpenAnswer> = emptyList(),
    var email: String = "",
)