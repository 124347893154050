package com.osg.appData.candidate

import kotlinx.serialization.Serializable

@Serializable
enum class Decision {
    LIKE,
    SUPER_LIKE,
    DISLIKE,
    UNKNOWN
}


data class FinalDecision(
    val candidateProfile: CandidateProfile,
    val candidateIdx: Int,
    val decision: Decision,
    val firstSightTime: Long,
)

fun Decision.isLikeType(): Boolean {
    return this == Decision.LIKE || this == Decision.SUPER_LIKE
}

fun Decision.areMatched(other: Decision): Boolean {
    return this.isLikeType() && other.isLikeType()
}