package com.osg.ice.ui.comp

import com.osg.truebase.data.iceDef.IceTag
import com.osg.truebase.ice.generated.resources.Res
import com.osg.truebase.ice.generated.resources.adrenaline
import com.osg.truebase.ice.generated.resources.adventure
import com.osg.truebase.ice.generated.resources.art
import com.osg.truebase.ice.generated.resources.culinary
import com.osg.truebase.ice.generated.resources.fantasy
import com.osg.truebase.ice.generated.resources.funny
import com.osg.truebase.ice.generated.resources.intimacy
import com.osg.truebase.ice.generated.resources.memories
import com.osg.truebase.ice.generated.resources.mindfulness
import com.osg.truebase.ice.generated.resources.music
import com.osg.truebase.ice.generated.resources.mystery
import com.osg.truebase.ice.generated.resources.nature
import com.osg.truebase.ice.generated.resources.nostalgia
import com.osg.truebase.ice.generated.resources.party
import com.osg.truebase.ice.generated.resources.problem_solving
import com.osg.truebase.ice.generated.resources.relax
import com.osg.truebase.ice.generated.resources.romance
import com.osg.truebase.ice.generated.resources.sport
import com.osg.truebase.ice.generated.resources.trivia
import org.jetbrains.compose.resources.StringResource

data class TagUi(
    val nameResource: StringResource,
    val emoji: String,
)

enum class IceTagEmoji(val emoji: String) {
    Music("🎵"),
    Romance("🌹"),
    Party("🕺"),
    Funny("🤣"),
    Drinks("🍸"),
    SpecialPizza("🍕"),
    SushiRolls("🍣"),
    AlcoholDrinks("🍹"),
    Dessert("🍰"),
    Cheese("🧀"),
    Pretzels("🥨"),
    Wine("🍷"),
    Surprises("🎁"),
    Interests("🎨"),
    Lifestyle("🪞"),
    Spontaneity("✈️"),
    Humor("🙊"),
    Think("🤔"),
    Sync("📶"),
    Trivia("🧠"),
    Nostalgia("📼"),
    Culinary("🍜"),
    Food("🍜"),
    Mystery("🕵️‍♂️"),
    ProblemSolving("🕵️‍♂️"),
    Fantasy("🧚‍♂️"),
    Intimacy("🌄"),
    Art("🎨"),
    Adventure("🏞️"),
    OpenMindedness("🎁"),
    Warmth("☀️"),
    Ambitious("🚀"),
    Trust("🤝"),
    Sports("🤸"),
    Relax("🛀"),
    Chemistry("🧪"),
    Emotion("🤗"),
    IntellectualAttraction("🧠"),
    Curiosity("🔍"),
    Perspective("👁"),
    Memories("💭"),
    Storytelling("🎙️"),
    Nature("🌳"),
    Sense("👃"),
    Coffee("☕"),
    Tea("🍵"),
    Cocktails("🍹"),
    Croissant("🥐"),
    Cake("🍰"),
    Creativity("🎨"),
    Communication("📞"),
    FinancialCompatibility("📈")
}

val IceTag.tagUi: TagUi
    get() {
        return when (this) {
            IceTag.Music -> TagUi(
                nameResource = Res.string.music,
                emoji = IceTagEmoji.Music.emoji
            )
            IceTag.Relax -> TagUi(
                nameResource = Res.string.relax,
                emoji = IceTagEmoji.Relax.emoji
            )
            IceTag.Sport -> TagUi(
                nameResource = Res.string.sport,
                emoji = IceTagEmoji.Sports.emoji
            )
            IceTag.Adrenaline -> TagUi(
                nameResource = Res.string.adrenaline,
                emoji = IceTagEmoji.Adventure.emoji
            )
            IceTag.Nature -> TagUi(
                nameResource = Res.string.nature,
                emoji = IceTagEmoji.Nature.emoji
            )
            IceTag.Romance -> TagUi(
                nameResource = Res.string.romance,
                emoji = IceTagEmoji.Romance.emoji
            )
            IceTag.Party -> TagUi(
                nameResource = Res.string.party,
                emoji = IceTagEmoji.Party.emoji
            )
            IceTag.Funny -> TagUi(
                nameResource = Res.string.funny,
                emoji = IceTagEmoji.Funny.emoji
            )
            IceTag.Art -> TagUi(
                nameResource = Res.string.art,
                emoji = IceTagEmoji.Art.emoji
            )
            IceTag.Fantasy -> TagUi(
                nameResource = Res.string.fantasy,
                emoji = IceTagEmoji.Fantasy.emoji
            )
            IceTag.Memories -> TagUi(
                nameResource = Res.string.memories,
                emoji = IceTagEmoji.Memories.emoji
            )
            IceTag.Mindfulness -> TagUi(
                nameResource = Res.string.mindfulness,
                emoji = IceTagEmoji.OpenMindedness.emoji
            )
            IceTag.Trivia -> TagUi(
                nameResource = Res.string.trivia,
                emoji = IceTagEmoji.Trivia.emoji
            )
            IceTag.Culinary -> TagUi(
                nameResource = Res.string.culinary,
                emoji = IceTagEmoji.Culinary.emoji
            )
            IceTag.Adventure -> TagUi(
                nameResource = Res.string.adventure,
                emoji = IceTagEmoji.Adventure.emoji
            )
            IceTag.Intimacy -> TagUi(
                nameResource = Res.string.intimacy,
                emoji = IceTagEmoji.Intimacy.emoji
            )
            IceTag.Nostalgia -> TagUi(
                nameResource = Res.string.nostalgia,
                emoji = IceTagEmoji.Nostalgia.emoji
            )
            IceTag.ProblemSolving -> TagUi(
                nameResource = Res.string.problem_solving,
                emoji = IceTagEmoji.ProblemSolving.emoji
            )
            IceTag.Mystery -> TagUi(
                nameResource = Res.string.mystery,
                emoji = IceTagEmoji.Mystery.emoji
            )
        }
    }