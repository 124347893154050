package com.osg.appData.profile

import com.osg.truebase.data.nodeData.IImageDeclaration
import com.osg.truebase.data.nodeData.RemoteImage
import kotlinx.serialization.Serializable

interface IStageHighLight {
    val title: String
    val unlockStage: Int
    val media: IImageDeclaration
}

@Serializable
data class StageHighLight(
    override val title: String,
    override val unlockStage: Int,
    override val media: RemoteImage,
): IStageHighLight

