package osg.uiZone.matchZone.sparkMove

import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.StringResource
import truematch.uizone.generated.resources.*

data class SparkMoveData(
    val image: DrawableResource,
    val name: StringResource,
    val description: StringResource,
    val enabled: Boolean,
)

enum class SparkMovesCollection(
    val sparkMoveData: SparkMoveData
) {
//    Solo(
//        SparkMoveData(
//            image = Res.drawable.rose_move,
//            name = "Solo",
//            description = "Make your own move and show your creativity.",
//            enabled = true
//        )
//    ),
    MusicalGif(
        SparkMoveData(
            image = Res.drawable.musical_move,
            name = Res.string.move_musical_gif_title,
            description = Res.string.move_musical_gif_description,
            enabled = false
        )
    ),
    IceBreaker(
        SparkMoveData(
            image = Res.drawable.ice_move,
            name = Res.string.move_icebreaker_title,
            description = Res.string.move_icebreaker_description,
            enabled = true
        )
    ),
    CelebrityBlessing(
        SparkMoveData(
            image = Res.drawable.celeb,
            name = Res.string.move_celebrity_title,
            description = Res.string.move_celebrity_description,
            enabled = false
        )
    ),
}