package osg.uiZone.navigation

import kotlinx.serialization.Serializable


@Serializable
sealed interface Destination

@Serializable
data object SettingsDestination : Destination

@Serializable
data object ProfileEditDestination: Destination