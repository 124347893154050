package com.osg.truebase.data.iceDef.ch

import com.osg.truebase.data.iceDef.IceRoundData
import com.osg.truebase.data.nodeData.IRemoteMediaResource
import com.osg.truebase.data.nodeData.RemoteMedia
import kotlinx.serialization.Serializable

@Serializable
data class ChallengeAcceptedRoundContent(
    val title: String = "",
    val options: List<String> = emptyList(),
    val animation: RemoteMedia,
    val difficulty: Int = 0,
    override val id: String = "",
): IceRoundData

