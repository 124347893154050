package com.osg.ice.harmonyTunes.ui

import androidx.compose.animation.core.*
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.PlayArrow
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.LargeFloatingActionButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.geometry.center
import androidx.compose.ui.graphics.*
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.graphics.drawscope.rotate
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.layout.layout
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.osg.truebase.ice.generated.resources.Res
import com.osg.truebase.ice.generated.resources.outline_emoji_objects_24
import com.osg.truebase.ice.generated.resources.outline_pause_24
import com.osg.truebase.ice.generated.resources.outline_replay_24
import com.osg.truebase.ice.generated.resources.pause
import com.osg.truebase.ice.generated.resources.play
import com.osg.truebase.ice.generated.resources.repeat
import com.osg.truebase.ice.generated.resources.vinyl_background
import com.osg.truebase.ice.generated.resources.vinyl_inner_image
import com.osg.truebase.player.audio.PlayerAction
import com.osg.truebase.player.audio.PlayerState
import com.osg.ui.core.commonStates.TrueVector
import com.osg.ui.core.commonStates.asTrueVector
import com.osg.ui.core.commonStates.asVector
import com.osg.truebase.ui.paint.PreGradientBackground
import com.osg.ui.core.resources.painter
import com.osg.ui.core.resources.string
import com.osg.ui.core.resources.vector
import com.osg.truebase.ui.theme.brandColors
import org.jetbrains.compose.resources.StringResource
import kotlin.math.roundToInt


@Composable
private fun VinylAlbumCover(
    modifier: Modifier = Modifier,
    rotationDegrees: Float = 0f,
    painter: Painter,
    playerState: PlayerState,
) {
    Box(
        modifier = modifier
    ) {
        Image(
            modifier = Modifier
                .align(Alignment.Center)
                .fillMaxSize()
                .rotate(rotationDegrees),
            painter = Res.drawable.vinyl_background.painter,
            contentScale =  ContentScale.Inside,
            contentDescription = "Vinyl Background"
        )


        /**
         * Song album cover image overlaid on the vinyl background image
         */
        val sizeRatio = 0.5f
        Image(
            modifier = Modifier
                .align(Alignment.Center)
                .aspectRatio(1f)
                .layout{ measurable, constraints ->

                    val placeable = measurable.measure(constraints.copy(
                        minWidth = (constraints.maxWidth * sizeRatio).roundToInt(),
                        minHeight = (constraints.maxHeight * sizeRatio).roundToInt(),
                        maxWidth =  (constraints.maxWidth * sizeRatio).roundToInt(),
                        maxHeight = (constraints.maxHeight * sizeRatio).roundToInt()
                    ))
                    layout(placeable.width, placeable.height) {
                        placeable.place(0, 0)
                    }
                }
                .clip(CircleShape)
                .rotate(rotationDegrees)
                .alpha(if (playerState == PlayerState.Finished) 0.0f else 1f)
                .graphicsLayer {
                    compositingStrategy = CompositingStrategy.Offscreen
                }
                .drawWithCache {
                    onDrawWithContent {
                        this@onDrawWithContent.drawContent()
                        drawCircle(
                            color = Color.White,
                            radius = size.maxDimension * 0.065f,
                            center = size.center,
                            blendMode =  BlendMode.Clear
                        )
                    }
                },
            painter = painter,
            contentScale =  ContentScale.Crop,
            contentDescription = "Song cover"
        )
    }
}

@Composable
fun VinylAlbumCoverAnimation(
    playerState: PlayerState,
    modifier: Modifier = Modifier,
) {
    var currentRotation by remember {
        mutableFloatStateOf(0f)
    }

    val rotation = remember {
        Animatable(currentRotation)
    }

    LaunchedEffect(playerState) {
        if (playerState == PlayerState.Playing) {
            rotation.animateTo(
                targetValue = currentRotation + 360f,
                animationSpec = infiniteRepeatable(
                    animation = tween(3000, easing = LinearEasing),
                    repeatMode = RepeatMode.Restart
                )
            ) {
                currentRotation = value
            }
        } else {
            if (currentRotation > 0f) {
                rotation.animateTo(
                    targetValue = currentRotation + 50,
                    animationSpec = tween(
                        1250,
                        easing = LinearOutSlowInEasing
                    )
                ) {
                    currentRotation = value
                }
            }
        }
    }

    VinylAlbumCover(
        modifier = modifier,
        painter = Res.drawable.vinyl_inner_image.painter,
        rotationDegrees = rotation.value,
        playerState = playerState,
    )
}

val PlayerAction.icon: TrueVector
    get() = when (this) {
        PlayerAction.Play -> Icons.Filled.PlayArrow.asTrueVector()
        PlayerAction.Pause -> Res.drawable.outline_pause_24.asTrueVector()
        PlayerAction.Repeat -> Res.drawable.outline_replay_24.asTrueVector()
    }

val PlayerAction.label: StringResource
    get() = when (this) {
        PlayerAction.Play -> Res.string.play
        PlayerAction.Pause -> Res.string.pause
        PlayerAction.Repeat -> Res.string.repeat
    }


@Composable
fun PlayerButton(
    modifier: Modifier = Modifier,
    onClick: (PlayerAction) -> Unit,
    playerButton: PlayerAction
) {
    Row(
        modifier = modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceEvenly
    )
    {
        IconButton(
            modifier = modifier,
            onClick = { onClick(playerButton) },
        ) {
            Icon(
                imageVector = playerButton.icon.asVector(),
                contentDescription = playerButton.label.string,
                tint = MaterialTheme.brandColors.orangeTm,
                modifier = Modifier
                    .fillMaxSize()
                    .semantics { role = Role.Button }
            )
        }
    }
}

@Composable
fun Hint(
    onHintClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    ImageFloatingButtonAnimated(
        onClick = onHintClick,
        modifier = modifier,
        imageVector = Res.drawable.outline_emoji_objects_24.vector,
    )
}

@Composable
fun ImageFloatingButtonAnimated(
    modifier: Modifier = Modifier,
    onClick: () -> Unit = {},
    imageVector: ImageVector,
    borderWidth: Dp = 5.dp,
    size: Dp = 70.dp
) {

    LargeFloatingActionButton(
        modifier = modifier.size(size),
        onClick = onClick,
        shape = CircleShape,
    ) {
        val infiniteTransition = rememberInfiniteTransition(label = "")
        val rotateAnimation by infiniteTransition.animateFloat(
            initialValue = 0f,
            targetValue = 360f,
            animationSpec = infiniteRepeatable(
                animation = tween(2000, easing = LinearEasing),
            ), label = ""
        )
        Image(
            imageVector = imageVector,
            contentScale = ContentScale.Fit,
            contentDescription = null,
            modifier = Modifier
                .drawBehind {
                    rotate(rotateAnimation){
                        drawCircle(
                            Brush.sweepGradient(
                                PreGradientBackground.Rainbow.colors
                            ),
                            style = Stroke(width = borderWidth.toPx())
                        )
                    }
                }
                .aspectRatio(1f)
                .padding(borderWidth)
                .clip(CircleShape)
                .padding(borderWidth)
        )
    }
}
