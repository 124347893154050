package com.osg.truematch.demo
import baseModuleSim.AuthSim
import bot.TmBotActions
import bot.tmBotRepo
import com.osg.def.database.DatabaseService
import com.osg.def.database.LaunchStringResolver
import com.osg.di.startBaseKoin
import com.osg.previewLab.database.DatabaseStorageSim
import com.osg.previewLab.database.SimDataBaseEngine
import com.osg.previewLab.signin.SignInProviderSim
import com.osg.truebase.datastore.IDataStoreEngine
import com.osg.truebase.player.audio.IPlatformAudioPlayer
import com.osg.truematch.demo.baseModuleSim.*
import org.koin.core.parameter.ParametersHolder
import org.koin.core.scope.Scope
import osg.uiZone.di.tmKoinModules


fun startKoinDemoApp(
    audioPlayerLoader: Scope.(ParametersHolder) -> IPlatformAudioPlayer,
    buildDataStore: Scope.(ParametersHolder) -> IDataStoreEngine,
    launchStringResolver: Scope.(ParametersHolder) -> LaunchStringResolver,
    simManager: SimManager
){
    startBaseKoin(
        databaseLoader = {
            SimDataBaseEngine(
                delayMs = simManager.databaseDelay,
                simInitializer = simManager::prepareDb,
                eventsListLoader = {
                    val databaseService = get<DatabaseService>()
                    TmBotActions(
                        databaseService.tmBotRepo
                    ).valCreatedEvents.map { it.eventHandler }
                }
            )
        },
        platformStorageLoader = {
            DatabaseStorageSim(
                simManager.initialStorageData,
                delay = simManager.dataStorageDelay
            )
        },
        authLoader = {
            AuthSim(
                underTestUid = simManager.initialProfile.publicProfile.uid
            )
        },
        signInLoader = { SignInProviderSim() },
        platformModules = tmKoinModules(
            databaseFunctions = {
                CloudFunctionsEngineSim(
                    callerUid = simManager.initialProfile.publicProfile.uid,
                    simInitializer = simManager.cfInitializer()
                )
            },
            messagingLoader = { MessagingServiceSim() },
            platformGps = { PlatformGpsSim() },
            platformNetwork = { PlatformNetworkSim() },
        ),
        audioPlayerLoader = audioPlayerLoader,
        buildDataStore = buildDataStore,
        launchStringResolver = launchStringResolver
    )
}