package com.osg.ice.ui

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.asPaddingValues
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.statusBars
import androidx.compose.foundation.rememberScrollState
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.osg.ice.ui.comp.EntertainmentBody
import com.osg.ice.ui.comp.EntertainmentImage
import com.osg.ice.ui.ds.IEntertainmentDataUi
import com.osg.ui.core.commonStates.asPainter
import com.osg.truebase.ui.theme.spacing

@Composable
fun EntertainmentDetailsScreen(
    modifier: Modifier = Modifier,
    icebreakerUiData: IEntertainmentDataUi,
    actionContent: @Composable () -> Unit,
) {
    Scaffold(
        contentWindowInsets = WindowInsets(0, 0, 0, 0),
        modifier = modifier.fillMaxSize(),
        floatingActionButton = {
            actionContent()
        }
    ) { innerPadding ->
        val scroll = rememberScrollState(0)
        Column(
            modifier = Modifier.padding(innerPadding),
            verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small)
        ){
            EntertainmentImage(
                modifier = Modifier.weight(3f, fill = false),
                imagePadding = WindowInsets.statusBars.asPaddingValues(),
                painter = icebreakerUiData.image.asPainter()
            )

            EntertainmentBody(
                modifier = Modifier.weight(7f).padding(horizontal = MaterialTheme.spacing.medium),
                entertainment = icebreakerUiData,
                scroll = scroll,
            )
        }
    }
}