package com.osg.ice.challengeAccepted

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.osg.def.database.IDownloadMedia
import com.osg.ice.*
import com.osg.ice.challengeAccepted.ds.ChallengeAcceptedAnswer
import com.osg.ice.challengeAccepted.ds.dataUi
import com.osg.ice.state.ChallengeAcceptedUiState
import com.osg.ice.state.IceMatchResult
import com.osg.ice.state.IceUiPlayerProfile
import com.osg.ice.state.RoundStatus
import com.osg.ice.ui.ds.EntertainmentDataUi
import com.osg.truebase.data.iceDef.*
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.user.PlayerDataEssential
import com.osg.truebase.ice.generated.resources.Res
import com.osg.truebase.ice.generated.resources.icecream_tilon
import com.osg.truebase.ice.generated.resources.icecream_watermelon
import com.osg.truebase.ui.animation.toAnimation
import com.osg.truebase.ui.commonStates.MainViewUiState
import com.osg.ui.core.commonStates.TrueImage
import com.osg.ui.core.commonStates.asTrueImage
import com.osg.truebase.ui.commonStates.toSuccess
import com.osg.utils.pmap
import com.osg.utils.time.TimeService.getUtcTime
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.take
import kotlinx.coroutines.flow.toList
import kotlinx.coroutines.launch


data class PlayersProfile(
    val myProfile: IceUiPlayerProfile,
    val opponentProfile: IceUiPlayerProfile,
)

val defaultResourceImage = listOf(
    Res.drawable.icecream_tilon.asTrueImage(),
    Res.drawable.icecream_watermelon.asTrueImage()
)


// step 1 - master game settings declaration
// step 2 - download game content
// step 3 - start game
// step 4 - round result
// step 5 - user click on next round
class ChallengeAcceptedModel(
    private val iceBreakerDependencies: CaDependencies,
    private val mediaDownloader: IDownloadMedia,
    private val iceBreakerRepository: IceRepository,
    iceDataStorage: IceMultiMediaDownloader,
) : ViewModel(), IceBreakerModel {
    private val playersToAnswer: Int = 2
    private val iceDataStorageHandler = IceDataStorageHandler(iceDataStorage)
    private val playersProfileStates = MutableStateFlow<PlayersProfile>(
        PlayersProfile(
            myProfile = iceBreakerDependencies.myPlayerProfile.toProfileUi(
                defaultResourceImage[0]
            ),
            opponentProfile = iceBreakerDependencies.partnerPlayerProfile.toProfileUi(
                defaultResourceImage[1]
            )
        )
    )

    private lateinit var uiRounds: List<ChallengeAcceptedUiState>
    private var iceMatchResultsState = IceMatchResult(
        myScore = 0,
        opponentScore = 0
    )

    val challengeAcceptedState = MutableStateFlow<MainViewUiState<ChallengeAcceptedUiState>>(MainViewUiState.Loading)

    private fun PlayerDataEssential.toProfileUi(
        defaultImage: TrueImage
    ): IceUiPlayerProfile {
        val image: TrueImage = profileImage.let {
            if (it == null) defaultImage else {
                TrueImage.Loading {
                    mediaDownloader.downloadMedia(it)
                }
            }
        }
        return IceUiPlayerProfile(
            name = name,
            trueProfileImage = image
        )
    }


    override fun onStartGame(playerSettings: PlayerSettings) {
        viewModelScope.launch {
            uiRounds = fetchGameContent(playerSettings)
            loadRound(0)
        }
        listenToGameEvents()
    }

    fun loadRound(idx: Int) {
        challengeAcceptedState.value = uiRounds[idx].toSuccess()
    }

    fun onOptionSelected(answer: ChallengeAcceptedAnswer) {
        val roundLiveData = CaLiveEvent(
            uid = iceBreakerDependencies.myPlayerProfile.uid,
            timestamp = getUtcTime(),
            round = answer.round,
            data = answer.answerIdx,
        )

        viewModelScope.launch {
            iceBreakerRepository.pushLiveData(path = iceBreakerDependencies.eventsPath, value = roundLiveData)
        }
    }

    private suspend fun fetchGameContent(
        playerSettings: PlayerSettings
    ): List<ChallengeAcceptedUiState> {
        iceBreakerRepository.pushPreferences(
            iceBreakerDependencies.settingsPath,
            IceContentPreferences(
                seenRoundsIds=iceBreakerDependencies.seenRoundsIds,
                uid = iceBreakerDependencies.myPlayerProfile.uid,
            )
        )
        val unifiedPreferences = iceBreakerRepository
            .getPreferencesFlow(iceBreakerDependencies.settingsPath)
            .take(2).toList()

        val iceContentQuery = ChallengeAcceptedQuery(
            seenRoundsIds = unifiedPreferences.map { it.seenRoundsIds }.flatten(),
            language = playerSettings.languageSupport, // 1 question for each player = round
            totalRounds = iceBreakerDependencies.rounds * 2,
        )

        val gameContent = iceDataStorageHandler.fetchIceContent(iceContentQuery) as ChallengeAcceptedContent

        val animations = gameContent.roundsData.pmap {
            iceDataStorageHandler.downloadMultimedia(it.animation)
        }

        return gameContent.roundsData.mapIndexed { idx, roundContent ->
            ChallengeAcceptedUiState(
                question = roundContent.title,
                options = roundContent.options,
                round = idx,
                isMyTurn = iceBreakerDependencies.isMyTurn(idx),
                totalRounds = gameContent.roundsData.size,
                myProfile = playersProfileStates.value.myProfile,
                opponentProfile = playersProfileStates.value.opponentProfile,
                roundStatus = RoundStatus.Initial(
                    animation = animations[idx].toAnimation(
                        media = roundContent.animation
                    )
                )
            )
        }
    }

    private fun listenToGameEvents() {
        viewModelScope.launch {
            val events = emptyList<CaLiveEvent>().toMutableList()
            iceBreakerRepository.getLiveDataFlow(iceBreakerDependencies.eventsPath).collect { iceEvents ->
                events.add(iceEvents as CaLiveEvent)
                handleIceLiveDataEvent(events)
            }
        }
    }

    private fun handleIceLiveDataEvent(iceEvents: List<CaLiveEvent>) {
        if (iceEvents.isEmpty()) {
            return
        }
        val iceLiveEvent = iceEvents.last()
        AppLogger.d("Received iceLiveData: $iceLiveEvent")
        val roundAnswers = iceEvents.filter { iceLiveEvent.round == it.round }
        if (roundAnswers.size == playersToAnswer) {
            showResult(roundAnswers)
        } else if (iceLiveEvent.uid == iceBreakerDependencies.myPlayerProfile.uid) {
            challengeAcceptedState.value = uiRounds[iceLiveEvent.round].copy(
                roundStatus = RoundStatus.WaitingForOpponentAnswer(
                    myAnswerIndex = iceLiveEvent.data,
                )
            ).toSuccess()
        }
    }

    private fun showResult(roundAnswers: List<CaLiveEvent>) {
        val myAnswer = roundAnswers.first { it.uid == iceBreakerDependencies.myPlayerProfile.uid }
        val opponentAnswer = roundAnswers.first { it.uid != iceBreakerDependencies.myPlayerProfile.uid }
        val round = roundAnswers.first().round
        val isMyTurn = iceBreakerDependencies.isMyTurn(round)
        val win = myAnswer.data == opponentAnswer.data
        val prevState = iceMatchResultsState
        iceMatchResultsState = if (win) {
            if (isMyTurn) {
                IceMatchResult(
                    myScore = iceMatchResultsState.myScore + 1,
                    opponentScore = iceMatchResultsState.opponentScore,
                )
            } else {
                IceMatchResult(
                    myScore = iceMatchResultsState.myScore,
                    opponentScore = iceMatchResultsState.opponentScore + 1,
                )
            }
        } else {
            IceMatchResult(
                opponentScore = iceMatchResultsState.opponentScore,
                myScore = iceMatchResultsState.myScore,
            )
        }

        challengeAcceptedState.value = uiRounds[round].copy(
            roundStatus = RoundStatus.ShowAnswers(
                myAnswerIndex = myAnswer.data,
                opponentAnswerIndex = opponentAnswer.data,
                currentResult = iceMatchResultsState,
                prevResult = prevState
            )
        ).toSuccess()
    }

    override val uiData: EntertainmentDataUi = SharedIcebreakerCollection.ChallengeAccepted.dataUi
}
