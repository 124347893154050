package com.osg.appData.candidate

import appData.trueAutoResources.MatchingInterest
import com.osg.truebase.data.iceDef.IceTag
import kotlinx.serialization.Serializable


@Serializable
data class MatchIncentive (
    val matchingInterests: List<MatchingInterest> = emptyList(),
    val matchingVibes: List<IceTag> = emptyList(),
    val promotion: String = "",
)