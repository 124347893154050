package com.osg.truebase.ui.datastore
import com.osg.truebase.data.LanguageSupport
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.json.JsonElement
import kotlin.reflect.KProperty1

data class EditProperty(
    val property: KProperty1<*, *>,
    val value: JsonElement
)


interface IBaseConfig{
    val language: LanguageSupport
}


interface IReadDataStore {
    val settingsFlow: Flow<IBaseConfig>
}

interface IWriteDataStore {
    suspend fun edit(editProperty: EditProperty)
}

interface IDataStore: IReadDataStore, IWriteDataStore