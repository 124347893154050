package com.osg.truebase.player.video

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.positionInWindow
import androidx.compose.ui.platform.LocalDensity
import kotlinx.browser.document
import org.w3c.dom.HTMLVideoElement


@Composable
actual fun VideoPlayer(modifier: Modifier, videoUri: String, onFinished: () -> Unit) {
    val videoElement = remember {
        (document.createElement("video") as HTMLVideoElement).apply {
            autoplay = true
            src = videoUri
            style.zIndex = "2"
            style.position = "absolute"
            addEventListener("ended", {
                onFinished()
            })
        }
    }
    DisposableEffect(videoElement) {
        val f = document.body?.firstChild
        document.body?.insertBefore(videoElement, f!!)
        onDispose {
            document.body?.removeChild(videoElement)
            videoElement.srcObject = null
        }
    }
    val density = LocalDensity.current
    Box(modifier = modifier
        .fillMaxSize()
        .onGloballyPositioned { coordinates ->
            with(density) {
                with(videoElement.style) {
                    top = "${coordinates.positionInWindow().y.toDp().value}px"
                    left = "${coordinates.positionInWindow().x.toDp().value}px"
                    width = "${coordinates.size.width.toDp().value}px"
                    height = "${coordinates.size.height.toDp().value}px"
                }
            }
        })
}