package osg.uiZone.graph.entry


import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.ui.commonStates.MainViewUiState
import com.osg.truebase.ui.commonStates.toSuccess
import com.osg.truebase.ui.datastore.EditProperty
import com.osg.truebase.ui.dialog.FatalErrorType
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.json.addAll
import kotlinx.serialization.json.buildJsonArray
import osg.uiZone.common.baseRepos.BaseConfig
import osg.uiZone.common.baseRepos.NetworkService
import com.osg.appData.network.NetworkStatus
import osg.uiZone.common.vmRepo.EntryModelRepositoryImpl
import osg.uiZone.common.vmRepo.IEntryModelRepository
import osg.uiZone.expect.permission.PermissionStatus
import osg.uiZone.navigation.GlobalPopUp
import osg.uiZone.navigation.GlobalState

sealed class PermissionStage {
    data object CheckingPermissions : PermissionStage()
    data object Finished : PermissionStage()
}



class EntryModel(
    private val entryModelRepository: IEntryModelRepository = EntryModelRepositoryImpl()
) : ViewModel() {
    private val permissionsStatusState =
        MutableStateFlow<PermissionStage>(PermissionStage.CheckingPermissions)
    private val regTokenUpdateFlow = entryModelRepository.updateTokenFlow.stateIn(
        scope = viewModelScope,
        started = SharingStarted.WhileSubscribed(5_000),
        initialValue = ""
    )


    val uiState: StateFlow<MainViewUiState<GlobalState>> =
        combine(
            permissionsStatusState,
            NetworkService.connectivityFlow,
            entryModelRepository.settingsFlow,
            regTokenUpdateFlow,
        ) { permissionsStatus, connectivityState, tmConfig, regToken ->
            AppLogger.d("EntryModel", "permissionsStatus $ $permissionsStatus connectivityState $connectivityState regToken $regToken")
            val popUp = if (permissionsStatus == PermissionStage.CheckingPermissions) {
                GlobalPopUp.CheckingPermissions
            }
            else if (connectivityState == NetworkStatus.Lost) {
                GlobalPopUp.Error(FatalErrorType.NETWORK_ERROR)
            }
            else {
                GlobalPopUp.None
            }

            GlobalState(
                tmConfig = BaseConfig(tmConfig),
                globalPopUp = popUp
            ).toSuccess()
        }.stateIn(
            scope = viewModelScope,
            started = SharingStarted.WhileSubscribed(5_000),
            initialValue = MainViewUiState.Loading
        )

    @OptIn(ExperimentalSerializationApi::class)
    fun onSeen(ids : Set<String>) {
        viewModelScope.launch {
            entryModelRepository.edit(
                EditProperty(
                    property = BaseConfig::seenSpotlights,
                    value = buildJsonArray {
                        addAll(ids)
                    }
                )
            )
        }
    }

    fun onResume() {
        viewModelScope.launch {
            entryModelRepository.onLogin()
        }
    }

    fun onPause() {
        AppLogger.d("EntryModel", "onPause")
        viewModelScope.launch {
            entryModelRepository.onDisconnect()
        }
    }

    fun onPermissionsStatus(permissionStatus: PermissionStatus) {
        when (permissionStatus) {
            PermissionStatus.GRANTED -> {
                permissionsStatusState.value = PermissionStage.Finished
            }
            else -> {
                val errorMsg = "unexpected permission status $permissionStatus location is mandatory"
                AppLogger.e("EntryModel", errorMsg)
            }
        }
    }
}
