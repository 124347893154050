package com.osg.matchmaker.search

import com.osg.appData.candidate.CandidateNode
import com.osg.appData.candidate.CandidateZone
import com.osg.appData.common.NotificationData
import com.osg.appData.common.UserData
import com.osg.appData.functions.FunctionResponseType
import com.osg.appData.matchZone.MatchZoneNode
import com.osg.appData.matchZone.Message
import com.osg.appData.ml.BatchMatchOsOutput
import com.osg.appData.ml.LlmAdminRequest
import com.osg.appData.ml.LlmOutput
import com.osg.appData.ml.MatchOsInput
import com.osg.appData.profile.FullProfile
import com.osg.appData.profile.MatchZoneData
import com.osg.appData.profile.Presence
import com.osg.appData.profile.TechnicalProfile
import com.osg.def.database.DatabaseService
import com.osg.def.database.KmpDatabaseException
import com.osg.matchmaker.matchZone.IMatchZoneCfRepo
import com.osg.matchmaker.ml.fake.getFakeLovingBotOutput
import com.osg.matchmaker.ml.fake.getFakeMatchOsOutput
import com.osg.truebase.data.nodeData.QueryType
import com.osg.truebase.data.nodeData.TransactionTarget
import kotlinx.serialization.json.JsonElement

class CfRepo(
    val databaseService: DatabaseService
) : ISearchCfRepo, IMatchZoneCfRepo {
    override suspend fun fetchUserData(path: String): UserData {
        return databaseService.fetchObject(path)
    }

    override suspend fun fetchProfile(path: String): FullProfile {
        return databaseService.fetchObject(path)
    }

    override suspend fun setCandidateZone(path: String, data: CandidateZone) {
        databaseService.setNode(path, data)
    }

    override suspend fun queryTechnicalProfiles(
        path: String, query: QueryType
    ): Map<String, TechnicalProfile> {
        return databaseService.runQuery(path, query)
    }

    override suspend fun updateNode(
        path: String, data: Map<String, CandidateNode>
    ) {
        databaseService.updateNodeMap(path, data)
    }

    override suspend fun fetchCandidateNode(path: String): CandidateNode {
        return databaseService.fetchObject(path)
    }

    override suspend fun storeMatchZoneNode(
        path: String, matchZoneNode: MatchZoneNode
    ) {
        databaseService.setNode(path, matchZoneNode)
    }

    override suspend fun storeMatchZoneData(path: String, matchZoneData: MatchZoneData) {
        databaseService.setNode(path, matchZoneData)
    }

    override suspend fun fetchPresence(path: String): Presence {
        return try {
            databaseService.fetchObject(path)
        } catch (e: KmpDatabaseException.ValueNotFound) {
            Presence()
        }
    }

    override suspend fun mathOsRequest(matchOsInput: MatchOsInput): BatchMatchOsOutput {
        return getFakeMatchOsOutput(matchOsInput)
    }

    override suspend fun lovingBotRequest(llmAdminRequest: LlmAdminRequest): LlmOutput {
        return getFakeLovingBotOutput(llmAdminRequest)
    }

    override suspend fun transaction(
        path: String, transaction: (TechnicalProfile?) -> TechnicalProfile?
    ): Boolean {
        return databaseService.transaction<TechnicalProfile>(path) { previousValue ->
            val newValue = transaction(previousValue)
            if (newValue != previousValue) {
                TransactionTarget<TechnicalProfile>(true, newValue)
            } else {
                TransactionTarget<TechnicalProfile>(false, null)
            }
        }
    }

    override suspend fun sendNote(
        notificationData: NotificationData
    ): FunctionResponseType {
        return FunctionResponseType.SUCCESS
    }

    override suspend fun pushMassage(path: String, data: Message) {
        databaseService.push(path, data)
    }

    override suspend fun removeNode(path: String) {
        databaseService.removeNode(path)
    }

    override suspend fun updateMatchStatus(path: String, data: JsonElement) {
        databaseService.updateNode(path, data)
    }
}