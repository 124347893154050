package osg.uiZone.matchZone.entertainment

import com.osg.appData.matchZone.ITmEntertainmentData
import com.osg.appData.matchZone.SparkNodeData
import com.osg.appData.matchZone.invitation.EntertainmentType
import com.osg.ice.ui.ds.IEntertainmentDataUi
import com.osg.truebase.data.iceDef.IceTag
import com.osg.ui.core.commonStates.TrueImage
import com.osg.ui.core.resources.UiArray
import com.osg.ui.core.resources.UiText
import com.osg.ui.core.resources.rawToUiArray
import com.osg.ui.core.resources.uiText

data class TMEntertainmentDataUiBase(
    override val name: UiText,
    override val slogan: UiText,
    override val description: UiText,
    override val unlockStage: Int,
    override val image: TrueImage,
    override val vibes: List<IceTag>,
    override val enabled: Boolean = false,
    override val id: String,
    val ingredients: UiArray = UiArray.Empty,
) : IEntertainmentDataUi, ITmEntertainmentData {
    override val type: EntertainmentType = EntertainmentType.GAME
}

fun IEntertainmentDataUi.toEntertainmentDataBase(
    unlockStage: Int,
    ingredients: UiArray = UiArray.Empty,
): TMEntertainmentDataUiBase {
    return TMEntertainmentDataUiBase(
        name = name,
        slogan = slogan,
        description = description,
        image = image as TrueImage.Resource,
        vibes = vibes,
        enabled = enabled,
        id = id,
        ingredients = ingredients,
        unlockStage = unlockStage
    )
}


fun SparkNodeData.toEntertainmentDataBaseUi(
    trueImage: TrueImage,
): TMEntertainmentDataUiBase {
    return TMEntertainmentDataUiBase(
        name = name.uiText,
        slogan = slogan.uiText,
        description = description.uiText,
        ingredients = ingredients.rawToUiArray(),
        image = trueImage,
        enabled = enabled,
        id = id,
        unlockStage = unlockStage,
        vibes = vibes,
    )
}