@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.osg.truebase.ice.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val baseline_auto_awesome_24: DrawableResource by 
      lazy { init_baseline_auto_awesome_24() }

  public val beat_sync: DrawableResource by 
      lazy { init_beat_sync() }

  public val blind_canvas: DrawableResource by 
      lazy { init_blind_canvas() }

  public val challenge_accepted: DrawableResource by 
      lazy { init_challenge_accepted() }

  public val harmony_tunes: DrawableResource by 
      lazy { init_harmony_tunes() }

  public val harmony_tunes_t: DrawableResource by 
      lazy { init_harmony_tunes_t() }

  public val icecream_tilon: DrawableResource by 
      lazy { init_icecream_tilon() }

  public val icecream_watermelon: DrawableResource by 
      lazy { init_icecream_watermelon() }

  public val one_ai: DrawableResource by 
      lazy { init_one_ai() }

  public val outline_emoji_objects_24: DrawableResource by 
      lazy { init_outline_emoji_objects_24() }

  public val outline_pause_24: DrawableResource by 
      lazy { init_outline_pause_24() }

  public val outline_replay_24: DrawableResource by 
      lazy { init_outline_replay_24() }

  public val start_vec: DrawableResource by 
      lazy { init_start_vec() }

  public val vinyl_background: DrawableResource by 
      lazy { init_vinyl_background() }

  public val vinyl_inner_image: DrawableResource by 
      lazy { init_vinyl_inner_image() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("baseline_auto_awesome_24", CommonMainDrawable0.baseline_auto_awesome_24)
  map.put("beat_sync", CommonMainDrawable0.beat_sync)
  map.put("blind_canvas", CommonMainDrawable0.blind_canvas)
  map.put("challenge_accepted", CommonMainDrawable0.challenge_accepted)
  map.put("harmony_tunes", CommonMainDrawable0.harmony_tunes)
  map.put("harmony_tunes_t", CommonMainDrawable0.harmony_tunes_t)
  map.put("icecream_tilon", CommonMainDrawable0.icecream_tilon)
  map.put("icecream_watermelon", CommonMainDrawable0.icecream_watermelon)
  map.put("one_ai", CommonMainDrawable0.one_ai)
  map.put("outline_emoji_objects_24", CommonMainDrawable0.outline_emoji_objects_24)
  map.put("outline_pause_24", CommonMainDrawable0.outline_pause_24)
  map.put("outline_replay_24", CommonMainDrawable0.outline_replay_24)
  map.put("start_vec", CommonMainDrawable0.start_vec)
  map.put("vinyl_background", CommonMainDrawable0.vinyl_background)
  map.put("vinyl_inner_image", CommonMainDrawable0.vinyl_inner_image)
}

internal val Res.drawable.baseline_auto_awesome_24: DrawableResource
  get() = CommonMainDrawable0.baseline_auto_awesome_24

private fun init_baseline_auto_awesome_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_auto_awesome_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/drawable/baseline_auto_awesome_24.xml", -1, -1),
    )
)

internal val Res.drawable.beat_sync: DrawableResource
  get() = CommonMainDrawable0.beat_sync

private fun init_beat_sync(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:beat_sync",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/drawable/beat_sync.webp", -1, -1),
    )
)

internal val Res.drawable.blind_canvas: DrawableResource
  get() = CommonMainDrawable0.blind_canvas

private fun init_blind_canvas(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:blind_canvas",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/drawable/blind_canvas.webp", -1, -1),
    )
)

internal val Res.drawable.challenge_accepted: DrawableResource
  get() = CommonMainDrawable0.challenge_accepted

private fun init_challenge_accepted(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:challenge_accepted",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/drawable/challenge_accepted.webp", -1, -1),
    )
)

internal val Res.drawable.harmony_tunes: DrawableResource
  get() = CommonMainDrawable0.harmony_tunes

private fun init_harmony_tunes(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:harmony_tunes",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/drawable/harmony_tunes.webp", -1, -1),
    )
)

internal val Res.drawable.harmony_tunes_t: DrawableResource
  get() = CommonMainDrawable0.harmony_tunes_t

private fun init_harmony_tunes_t(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:harmony_tunes_t",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/drawable/harmony_tunes_t.webp", -1, -1),
    )
)

internal val Res.drawable.icecream_tilon: DrawableResource
  get() = CommonMainDrawable0.icecream_tilon

private fun init_icecream_tilon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:icecream_tilon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/drawable/icecream_tilon.xml", -1, -1),
    )
)

internal val Res.drawable.icecream_watermelon: DrawableResource
  get() = CommonMainDrawable0.icecream_watermelon

private fun init_icecream_watermelon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:icecream_watermelon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/drawable/icecream_watermelon.xml", -1, -1),
    )
)

internal val Res.drawable.one_ai: DrawableResource
  get() = CommonMainDrawable0.one_ai

private fun init_one_ai(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:one_ai",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/drawable/one_ai.webp", -1, -1),
    )
)

internal val Res.drawable.outline_emoji_objects_24: DrawableResource
  get() = CommonMainDrawable0.outline_emoji_objects_24

private fun init_outline_emoji_objects_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:outline_emoji_objects_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/drawable/outline_emoji_objects_24.xml", -1, -1),
    )
)

internal val Res.drawable.outline_pause_24: DrawableResource
  get() = CommonMainDrawable0.outline_pause_24

private fun init_outline_pause_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:outline_pause_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/drawable/outline_pause_24.xml", -1, -1),
    )
)

internal val Res.drawable.outline_replay_24: DrawableResource
  get() = CommonMainDrawable0.outline_replay_24

private fun init_outline_replay_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:outline_replay_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/drawable/outline_replay_24.xml", -1, -1),
    )
)

internal val Res.drawable.start_vec: DrawableResource
  get() = CommonMainDrawable0.start_vec

private fun init_start_vec(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:start_vec",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/drawable/start_vec.xml", -1, -1),
    )
)

internal val Res.drawable.vinyl_background: DrawableResource
  get() = CommonMainDrawable0.vinyl_background

private fun init_vinyl_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:vinyl_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/drawable/vinyl_background.webp", -1, -1),
    )
)

internal val Res.drawable.vinyl_inner_image: DrawableResource
  get() = CommonMainDrawable0.vinyl_inner_image

private fun init_vinyl_inner_image(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:vinyl_inner_image",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ice.generated.resources/drawable/vinyl_inner_image.webp", -1, -1),
    )
)
