package com.osg.ui.core.commonStates

import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.async
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock

class AsyncCache<K, V>(private val maxSize: Int = 20) {
    private val mutex = Mutex()
    private val cache = LinkedHashMap<K, Deferred<V>>()
    private val accessOrder = mutableListOf<K>()

    suspend fun getOrLoad(key: K, load: suspend () -> V): V = coroutineScope {
        val deferred = mutex.withLock {
            var cached = cache[key]
            if (cached == null || cached.isCancelled) {
                // LAZY - to free the mutex lock as fast as possible
                cached = async(start = CoroutineStart.LAZY) { load() }
                cache[key] = cached
                accessOrder.add(key)
                if (cache.size > maxSize) {
                    val eldestKey = accessOrder.removeAt(0)
                    cache.remove(eldestKey)
                }
            } else {
                accessOrder.remove(key)
                accessOrder.add(key)
            }
            cached
        }
        deferred.await()
    }

    //@TestOnly
    fun clear() {
        cache.clear()
    }
}