package com.osg.utils

import kotlin.coroutines.EmptyCoroutineContext.get
import kotlin.random.Random

fun randomString(length: Int): String {
    val charArray = CharArray(length) { nextChar() }
    return charArray.concatToString()
}
fun nextChar() = listOf(
    Random.nextInt(48, 58),
    Random.nextInt(65, 91),
    Random.nextInt(97, 123)
).random().toChar()

fun <E> Collection<E>.random(): E = elementAt(Random.nextInt(size))
