package com.osg.appData.candidate


import com.osg.appData.profile.PublicProfile
import kotlinx.serialization.Serializable

@Serializable
data class CandidateProfile(
    val matchIncentive: MatchIncentive,
    val publicProfile: PublicProfile,
    val decision: Decision = Decision.UNKNOWN,
){
    val uid: String
        get() = publicProfile.uid
}