package com.osg.truebase.spotlight

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp

@Composable
fun TutorialCard(
    step: ITutorialStepUi,
    corner: Corner,
    modifier: Modifier = Modifier,
    onSeen: (ITutorialStep) -> Unit
) {
    TutorialCardContent(
        step = step,
        onSeen = onSeen,
        shape = when(corner){
            Corner.TOP_LEFT -> RoundedCornerShape(16.dp, 16.dp, 4.dp, 16.dp)
            Corner.TOP_RIGHT -> RoundedCornerShape(16.dp, 16.dp, 16.dp, 4.dp)
            Corner.BOTTOM_LEFT -> RoundedCornerShape(16.dp, 4.dp, 16.dp, 16.dp)
            Corner.BOTTOM_RIGHT ->  RoundedCornerShape(4.dp, 16.dp, 16.dp, 16.dp)
        },

        modifier = modifier
    )
}


@Composable
fun TutorialCardContent(
    modifier: Modifier = Modifier,
    shape: Shape = RoundedCornerShape(16.dp, 16.dp, 4.dp, 16.dp),
    step: ITutorialStepUi,
    onSeen: (ITutorialStep) -> Unit
){
    ElevatedCard(
        modifier = modifier.width(260.dp),
        shape = shape
    ) {
        Column(
            modifier = Modifier.padding(16.dp).fillMaxWidth(),
            verticalArrangement = Arrangement.spacedBy(8.dp),
        ) {
            Column(
                modifier = Modifier,
                verticalArrangement = Arrangement.spacedBy(8.dp),
                horizontalAlignment = Alignment.Start
            ) {
                step.titleLoader?.let { loader ->
                    Text(
                        text = loader.loadText(),
                        style = MaterialTheme.typography.headlineSmall
                    )
                }

                Text(text = step.textLoader.loadText(), textAlign = TextAlign.Start)
            }
            TextButton(
                modifier = Modifier.align(Alignment.End),
                onClick = {
                    onSeen(step)
                }
            ) {
                Text(step.buttonTextLoader.loadText())
            }
        }
    }
}