package com.osg.truebase.data.iceDef.ht

import com.osg.truebase.data.iceDef.IceRoundData
import com.osg.truebase.data.nodeData.IRemoteMediaResource
import com.osg.truebase.data.nodeData.RemoteAudio
import kotlinx.serialization.Serializable

interface IHtRoundData {
    val correctAnswer: String
    val difficulty: Int
    val genre: MusicGenre
    val bitSound: IRemoteMediaResource
    val winSound: IRemoteMediaResource
}

@Serializable
data class HtRoundData(
    override val bitSound: RemoteAudio,
    override val winSound: RemoteAudio,
    override val correctAnswer: String,
    override val difficulty: Int = 0,
    override val genre: MusicGenre = MusicGenre.POP
): IHtRoundData, IceRoundData{
    override val id: String = correctAnswer
}

