package com.osg.def.database

sealed class KmpDatabaseException(
    message: String,
    val path: String,
) : Exception("error on path ${path}\n error: $message") {
    class ValueNotFound(
        path: String
    ) : KmpDatabaseException("Value not Found", path)
    class FirebaseError(
        message: String,
        path: String,
    ): KmpDatabaseException(message, path)
    class UnknownError(
        message: String,
        path: String
    ): KmpDatabaseException(message, path)

    companion object {
        fun classifyException(path: String, message: String?): KmpDatabaseException {
            if(message == null) {
                return UnknownError("Unknown Error", path)
            }
            return when {
                message.contains("Permission denied") -> FirebaseError("Permission denied", path)
                else -> UnknownError(message, path)
            }

        }
    }
}