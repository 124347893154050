package osg.uiZone.common.vmRepo.entertainment

import com.osg.appData.matchZone.Eligibility
import com.osg.appData.matchZone.MatchInfo
import com.osg.appData.matchZone.invitation.Invitation
import com.osg.appData.matchZone.invitation.InvitationStatus
import com.osg.appData.relationshipStage.computeRelationshipStage
import com.osg.truebase.data.iceDef.IceTag
import com.osg.ui.core.resources.uiText
import com.osg.utils.extractFirstOrNull
import com.osg.utils.extractItems
import osg.uiZone.matchZone.entertainment.TMEntertainmentDataUiBase
import osg.uiZone.matchZone.state.EntFeedData
import osg.uiZone.matchZone.state.TmEntertainmentCollection
import osg.uiZone.matchZone.state.tmEntUi.NewEntertainmentUiData
import osg.uiZone.matchZone.state.tmEntUi.ResponseEntertainmentUi
import osg.uiZone.matchZone.state.tmEntUi.TmEntApproved
import osg.uiZone.matchZone.state.tmEntUi.toEntertainmentDataUi
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.matchmaker_picks
import truematch.uizone.generated.resources.waiting_for_your_invitation_response
import kotlin.math.abs


fun prepareEntertainmentCollections(
    entertainments: List<TMEntertainmentDataUiBase>,
    matchInfo: MatchInfo,
    activeInvitations: List<Invitation>
): EntFeedData {

    val remainingEntertainments = entertainments.map {
        it.toEntertainmentDataUi(
            matchInfo = matchInfo,
        )
    }.sortedWith(
        entertainmentComparator(
            matchingVibes = matchInfo.matchingVibesIds,
            relationshipStage = matchInfo.computeRelationshipStage()
        )
    ).toMutableList()


    val notRespondedInvitations = activeInvitations.filter {
        listOf(InvitationStatus.NOT_RESPONDED, InvitationStatus.NEW).contains(it.status)
    }.mapNotNull { notRespondInvitation ->
        remainingEntertainments.extractFirstOrNull { notRespondInvitation.content == it.id }?.let { ent ->
            ResponseEntertainmentUi(
                invitation = notRespondInvitation,
                entertainment = ent
            )
        }
    }

    val notRespondedCollection = TmEntertainmentCollection(
        entertainmentDataUiList = notRespondedInvitations,
        name = Res.string.waiting_for_your_invitation_response.uiText
    )

    val approvedInvitations = activeInvitations.filter {
        it.status == InvitationStatus.APPROVED
    }.mapNotNull { approvedInvitation ->
        remainingEntertainments.extractFirstOrNull { approvedInvitation.content == it.id }?.let { ent ->
            TmEntApproved(
                invitation = approvedInvitation,
                entertainment = ent
            )
        }
    }

    val approvedCollection = TmEntertainmentCollection(
        entertainmentDataUiList = approvedInvitations,
        name = "Active".uiText
    )

    val forYouCollection = TmEntertainmentCollection(
        entertainmentDataUiList = remainingEntertainments.extractItems { take(3) },
        name = Res.string.matchmaker_picks.uiText
    )

    val entertainmentsGrouped = remainingEntertainments.groupBy {
        it.vibes.first().name
    }.map { (vibeName, entertainments) ->
        TmEntertainmentCollection(
            name = vibeName.uiText,
            entertainmentDataUiList = entertainments
        )
    }.sortedByDescending {
        it.entertainmentDataUiList.size
    }

    val col = (listOf(approvedCollection, notRespondedCollection, forYouCollection) + entertainmentsGrouped).filter {
        it.entertainmentDataUiList.isNotEmpty()
    }

    return EntFeedData(
        entertainmentCollections = col,
        matchInfo =  matchInfo,
    )
}


internal fun entertainmentComparator(
    matchingVibes: List<IceTag>,
    relationshipStage: Int
): Comparator<NewEntertainmentUiData> = compareBy<NewEntertainmentUiData> { entertainment ->
    entertainment.eligibility == Eligibility.YES
}.thenBy { entertainment ->
    entertainment.enabled
}.thenBy { entertainment ->
    entertainment.vibes.count { it in matchingVibes }
}.thenBy { entertainment ->
    -abs(entertainment.unlockStage - relationshipStage)
}.reversed()
