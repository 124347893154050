package com.osg.ice.ui.comp


import androidx.compose.foundation.gestures.detectDragGestures
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.material3.ExtendedFloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.unit.IntOffset
import kotlin.math.roundToInt


@Composable
fun DragFab(
    fabIcon: ImageVector,
    text: String,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    extend: Boolean = false,
    postClickText: String = "",
) {
    var isClicked by remember { mutableStateOf(false) }
    var offsetX by remember { mutableFloatStateOf(0f) }
    var offsetY by remember { mutableFloatStateOf(0f) }
    Box(
        modifier
            .offset { IntOffset(offsetX.roundToInt(), offsetY.roundToInt()) }
            .wrapContentSize()
            .pointerInput(Unit) {
                detectDragGestures { change, dragAmount ->
                    change.consume()
                    offsetX += dragAmount.x
                    offsetY += dragAmount.y
                }
            }
    ) {

        val resolvedText = if (isClicked) postClickText else text
        ExtendedFloatingActionButton(
            modifier = Modifier,
            onClick = {
                if (isClicked.not()) {
                    onClick()
                }
                isClicked = true
            },
            expanded = (extend || isClicked) && resolvedText.isNotEmpty(),
            icon = { Icon(fabIcon, text) },
            text = { Text(text = resolvedText) },
        )
    }
}