package com.osg.truebase.ui.graphics.konfetti.emitter

import androidx.compose.ui.geometry.Rect
import com.osg.truebase.ui.graphics.konfetti.Party
import com.osg.truebase.ui.graphics.konfetti.Position
import com.osg.truebase.ui.graphics.konfetti.Rotation
import com.osg.truebase.ui.graphics.konfetti.models.Shape
import com.osg.truebase.ui.graphics.konfetti.models.Size
import com.osg.truebase.ui.graphics.konfetti.models.Vector
import com.osg.utils.toRadians
import kotlin.math.ceil

import kotlin.math.cos
import kotlin.math.sin
import kotlin.random.Random

class PartyEmitter(
    private val emitterConfig: EmitterConfig,
    private val pixelDensity: Float,
) : BaseEmitter() {

    var currentSecondReminders: Int = emitterConfig.getDynamicPerSecAmount(0L).toInt()
    var lastRecordedSec: Long = -1
    /**
     * If timer isn't started yet, set initial start time
     * Create the first confetti immediately and update the last emitting time
     */
    override fun createConfetti(deltaTime: Float, frameTime: Long, party: Party, drawArea: Rect): List<Confetti> {
        val particlesToCreate = getNumberOfParticlesToCreate(deltaTime, frameTime)
        val particles = (1..particlesToCreate).map { createParticle(party, drawArea) }
        return particles
    }

    fun getNumberOfParticlesToCreate(deltaTime: Float, frameTime: Long): Int {
        val currentAmountPerSec = emitterConfig.getDynamicPerSecAmount(frameTime)
        if (isDifferentSecond(frameTime)) {
            currentSecondReminders = currentAmountPerSec.toInt()
        }
        val amount: Int = ceil(deltaTime * currentAmountPerSec).toInt()
        val particlesToCreate = minOf(amount, currentSecondReminders)
        currentSecondReminders -= particlesToCreate
        return particlesToCreate
    }

    fun isDifferentSecond(ms: Long): Boolean {
        val sec = ms / 1000
        val isDifferent = sec != lastRecordedSec
        if (isDifferent) {
            lastRecordedSec = sec
        }
        return isDifferent
    }

    /**
     * Create particle based on the [Party] configuration
     * @param party Configurations used for creating the initial Confetti states
     * @param drawArea the area and size of the canvas
     */
    private fun createParticle(party: Party, drawArea: Rect): Confetti {
        with(party) {
            val randomSize = size[Random.nextInt(size.size)]
            return Confetti(
                location = position.get(drawArea).run { Vector(x, y) },
                width = randomSize.sizeInDp * pixelDensity,
                mass = randomSize.massWithVariance(),
                shape = getRandomShape(party.shapes),
                color = colors[Random.nextInt(colors.size)],
                lifespan = timeToLive,
                fadeOut = fadeOutEnabled,
                velocity = getVelocity(),
                damping = party.damping,
                rotationSpeed2D = rotation.rotationSpeed() * party.rotation.multiplier2D,
                rotationSpeed3D = rotation.rotationSpeed() * party.rotation.multiplier3D,
                pixelDensity = pixelDensity
            )
        }
    }

    /**
     * Calculate a rotation speed multiplier based on the base and variance
     * @return rotation speed and return 0 when rotation is disabled
     */
    private fun Rotation.rotationSpeed(): Float {
        if (!enabled) return 0f
        val randomValue = Random.nextFloat() * 2f - 1f
        return speed + (speed * variance * randomValue)
    }

    private fun Party.getSpeed(): Float =
        if (maxSpeed == -1f) speed
        else ((maxSpeed - speed) * Random.nextFloat()) + speed

    /**
     * Get the mass with a slight variance added to create randomness between how each particle
     * will react in speed when moving up or down
     */
    private fun Size.massWithVariance(): Float = mass + (mass * (Random.nextFloat() * massVariance))

    /**
     * Calculate velocity based on radian and speed
     * @return [Vector] velocity
     */
    private fun Party.getVelocity(): Vector {
        val speed = getSpeed()
        val radian = getAngle().toRadians()
        val vx = speed * cos(radian).toFloat()
        val vy = speed * sin(radian).toFloat()
        return Vector(vx, vy)
    }

    private fun Party.getAngle(): Double {
        if (spread == 0) return angle.toDouble()

        val minAngle = angle - (spread / 2)
        val maxAngle = angle + (spread / 2)
        return (maxAngle - minAngle) * Random.nextDouble() + minAngle
    }

    private fun Position.get(drawArea: Rect): Position.Absolute {
        return when (this) {
            is Position.Absolute -> Position.Absolute(x, y)
            is Position.Relative -> {
                Position.Absolute(
                    drawArea.width * x.toFloat(),
                    drawArea.height * y.toFloat()
                )
            }
            is Position.Between -> {
                val minPos = min.get(drawArea)
                val maxPos = max.get(drawArea)
                return Position.Absolute(
                    x = Random.nextFloat().times(maxPos.x.minus(minPos.x)) + minPos.x,
                    y = Random.nextFloat().times(maxPos.y.minus(minPos.y)) + minPos.y
                )
            }
        }
    }

    /**
     * When the shape is a DrawableShape, mutate the drawable so that all drawables
     * have different values when drawn on the canvas.
     */
    private fun getRandomShape(shapes: List<Shape>): Shape {
        return when (val shape = shapes[Random.nextInt(shapes.size)]) {
            is Shape.DrawableShape -> {
                val mutatedState = shape.drawable
                shape.copy(drawable = mutatedState)
            }
            else -> shape
        }
    }

    override fun isFinished(
        totalCreated: Int,
    ): Boolean {
        return if (emitterConfig.emittingTimeMs > 0L) {
            totalCreated >= emitterConfig.totalParticles
        } else false
    }
}