package com.osg.previewLab.database

import com.osg.def.database.IPlatformStorage
import com.osg.truebase.data.nodeData.IRemoteMediaResource
import com.osg.truebase.data.resources.ILocalMediaResource
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.readResourceBytes

class DatabaseStorageSim(
    private val localRemoteAllMedia: List<ILocalMediaResource>,
    delay: Long = 500
): IPlatformStorage {
    val data = MutableStateFlow(
        emptyMap<String, ByteArray>()
    )

    private val delayVariance = ((delay - delay*0.3).toLong()..(delay + delay*0.3).toLong())
    private val delay: Long
            get(){
                return delayVariance.random()
            }

    @OptIn(InternalResourceApi::class)
    override suspend fun downloadMedia(media: IRemoteMediaResource): ByteArray {
        delay(delay)
        data.value[media.mediaPath]?.let {
            return it
        }

        val mediaPath = localRemoteAllMedia.first { it.mediaPath.contains(media.mediaPath) }
        return readResourceBytes(mediaPath.mediaPath)
    }


    override suspend fun uploadMedia(media: IRemoteMediaResource, data: ByteArray) {
        delay(delay)
        this.data.value = this.data.value + (media.mediaPath to data)
    }

    override suspend fun filesInPath(path: String): List<String> {
        TODO("Not yet implemented")
    }

    override suspend fun delete(path: String) {
        TODO("Not yet implemented")
    }
}