package osg.uiZone.common.settings

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.osg.appData.auth.basicIdentity
import com.osg.appData.candidate.UnMatchRequest
import com.osg.appData.common.DataRoot.Companion.userDataPath
import com.osg.appData.common.DataRoot.Companion.userMapPath
import com.osg.appData.functions.ITmCloudFunctions
import com.osg.appData.functions.IUnMatchRequest
import com.osg.appData.profile.FullProfile
import com.osg.appData.profile.MatchStatus
import com.osg.def.auth.IPlatformAuth
import com.osg.def.auth.ISignOut
import com.osg.def.auth.IUidFlowStatus
import com.osg.def.database.IRemoveNode
import com.osg.truebase.data.LanguageSupport
import com.osg.truebase.ui.datastore.EditProperty
import com.osg.truebase.ui.datastore.IBaseConfig
import com.osg.truebase.ui.datastore.IWriteDataStore
import com.osg.utils.waitForSuccess
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import org.koin.mp.KoinPlatform.getKoin
import osg.uiZone.common.baseRepos.BaseDataStore
import osg.uiZone.common.baseRepos.database.IPublicProfileFetcher
import osg.uiZone.common.baseRepos.database.publicProfileFetcher
import osg.uiZone.common.baseRepos.profile.IMyFullProfileFlow
import osg.uiZone.common.baseRepos.profile.MyProfileScope
import osg.uiZone.common.baseRepos.profile.MyProfileScope.Companion.myProfileHandler
import osg.uiZone.common.baseRepos.profile.databaseService
import osg.uiZone.common.vmRepo.matchZone.MatchScope
import osg.uiZone.di.FunctionsService
import osg.uiZone.search.repo.SearchScope

interface ISettingsDependencies:
    IRemoveNode,
    IMyFullProfileFlow,
    IPublicProfileFetcher,
    IUnMatchRequest,
    ISignOut,
    IUidFlowStatus,
    IWriteDataStore

class SettingsDependenciesImpl(
) : ISettingsDependencies,
    IRemoveNode by databaseService,
    IMyFullProfileFlow by myProfileHandler,
    IPublicProfileFetcher by databaseService.publicProfileFetcher,
    IWriteDataStore by getKoin().get<BaseDataStore>(),
    IPlatformAuth by getKoin().get<IPlatformAuth>(),
    ITmCloudFunctions by FunctionsService()

class SettingModel(
    private val settingsDependencies: ISettingsDependencies = SettingsDependenciesImpl()
) : ViewModel(){
    fun onRemoveClicked(
        onRemoveComplete: () -> Unit
    ) {
        viewModelScope.launch {
            val profile = settingsDependencies.myProfileStateFlow.waitForSuccess()
            profile.unmatchIfNeeded()
            closeAllScopes()

            settingsDependencies.removeNode(
                userMapPath(
                    profile.publicProfile.uid,
                    profile.publicProfile.gender!!
                )
            )
            settingsDependencies.removeNode(
                userDataPath(
                    profile.publicProfile.uid,
                )
            )

            settingsDependencies.signOut()
            onRemoveComplete()
        }
    }

    fun onLogOut(
        onLogOutComplete: () -> Unit
    ) {
        closeAllScopes()
        settingsDependencies.signOut()
        onLogOutComplete()
    }

    fun onUpdateSettings(language: LanguageSupport){
        viewModelScope.launch {
            settingsDependencies.edit(
                EditProperty(
                    property = IBaseConfig::language,
                    value = JsonPrimitive(language.name)
                )
            )
        }
    }

    private suspend fun FullProfile.unmatchIfNeeded() {
        matchZoneData?.let { matchZoneData ->
            if (matchZoneData.matchStatus == MatchStatus.Active) {
                val candidateProfile = settingsDependencies.fetchPublicProfile(matchZoneData.candidateUid)
                settingsDependencies.unMatch(
                    UnMatchRequest(
                        publicProfile.basicIdentity,
                        candidateProfile.basicIdentity
                    )
                )
            }
        }

    }
}


fun closeAllScopes() {
    MatchScope.closeMatchScope()
    SearchScope.closeSearchScope()
    MyProfileScope.closeMyProfileScope()
}