package com.osg.truebase.ui.graphics.konfetti

import com.osg.truebase.ui.graphics.konfetti.emitter.EmitterConfig
import com.osg.truebase.ui.graphics.konfetti.models.Size
import kotlin.time.DurationUnit
import kotlin.time.toDuration

class Presets {
    companion object {
        fun festive(): List<Party> {
            val party = Party(
                speed = 30f,
                maxSpeed = 50f,
                damping = 0.9f,
                angle = Angle.Companion.TOP,
                spread = 10,
                size = listOf(Size.SMALL, Size.LARGE),
                timeToLive = 3000L,
                rotation = Rotation(),
                delay = 0,
                colors = listOf(0xfce18a, 0xff726d, 0xf4306d, 0xb48def),
                emitterConfig = EmitterConfig(duration = 100.toDuration(DurationUnit.MILLISECONDS), totalParticles = 30),
                position = Position.Relative(0.5, 1.0)
            )

            return listOf(
                party,
                party.copy(
                    speed = 55f,
                    maxSpeed = 65f,
                    spread = 10,
                    emitterConfig = EmitterConfig(duration = 100.toDuration(DurationUnit.MILLISECONDS), totalParticles = 10),
                ),
                party.copy(
                    speed = 50f,
                    maxSpeed = 60f,
                    spread = 120,
                    emitterConfig = EmitterConfig(duration = 100.toDuration(DurationUnit.MILLISECONDS), totalParticles = 40),
                ),
                party.copy(
                    speed = 65f,
                    maxSpeed = 80f,
                    spread = 10,
                    emitterConfig = EmitterConfig(duration = 100.toDuration(DurationUnit.MILLISECONDS), totalParticles = 10),
                )
            )
        }

        fun explode(): List<Party> {
            return listOf(
                Party(
                    speed = 0f,
                    maxSpeed = 30f,
                    damping = 0.9f,
                    spread = 360,
                    colors = listOf(0xfce18a, 0xff726d, 0xf4306d, 0xb48def),
                    emitterConfig = EmitterConfig(duration = 100.toDuration(DurationUnit.MILLISECONDS), totalParticles = 30),
                    position = Position.Relative(0.5, 0.3)
                )
            )
        }

        fun parade(): List<Party> {
            val party = Party(
                speed = 10f,
                maxSpeed = 30f,
                damping = 0.9f,
                angle = Angle.Companion.RIGHT - 45,
                spread = Spread.Companion.SMALL,
                colors = listOf(0xfce18a, 0xff726d, 0xf4306d, 0xb48def),
                emitterConfig = EmitterConfig.perSecondParticlesEmitter(30),
                position = Position.Relative(0.0, 0.5)
            )

            return listOf(
                party,
                party.copy(
                    angle = party.angle - 90, // flip angle from right to left
                    position = Position.Relative(1.0, 0.5)
                ),
            )
        }

        fun rain(): List<Party> {
            return listOf(
                Party(
                    speed = 0f,
                    maxSpeed = 15f,
                    damping = 0.9f,
                    angle = Angle.Companion.BOTTOM,
                    spread = Spread.Companion.ROUND,
                    colors = listOf(0xfce18a, 0xff726d, 0xf4306d, 0xb48def),
                    emitterConfig = EmitterConfig.perSecondParticlesEmitter(30),
                    position = Position.Relative(0.0, 0.0).between(Position.Relative(1.0, 0.0))
                )
            )
        }
    }
}
