package osg.uiZone.di

import com.osg.appData.functions.*
import com.osg.def.database.IAppConfig
import org.koin.core.module.Module
import org.koin.core.module.dsl.singleOf
import org.koin.core.parameter.ParametersHolder
import org.koin.core.scope.Scope
import org.koin.dsl.binds
import org.koin.dsl.module
import com.osg.appData.location.IPlatformGps
import com.osg.appData.messaging.IPlatformMessagingService
import com.osg.appData.network.IPlatformNetwork
import osg.uiZone.common.baseRepos.handlers.LocationService

fun tmKoinModules(
    databaseFunctions: (IAppConfig) -> ICloudFunctionsEngine,
    messagingLoader: () -> IPlatformMessagingService,
    platformGps: Scope.(ParametersHolder) -> IPlatformGps,
    platformNetwork: Scope.(ParametersHolder) -> IPlatformNetwork,
    highLevelModules: List<Module> = emptyList(),
): List<Module> {
    return listOf(
        module {
            single<ICloudFunctionsEngine> {
                databaseFunctions(get())
            }
        },
        module {
            singleOf(::FunctionsService) binds arrayOf(
                IGenLlmResponse::class,
                ITmNotificationAccountFunctions::class,
                IUnMatchRequest::class,
                ITmMatchMakerCloudFunctions::class,
                ITmMatchRequestCloudFunctions::class,
                ITmCloudFunctions::class,
            )
        },
        module {
            singleOf<IPlatformMessagingService>(messagingLoader)
        },
        module {
            single<IPlatformGps>(definition = platformGps)
        },
        module {
            singleOf(::LocationService)
        },
        module {
            single<IPlatformNetwork>(definition = platformNetwork)
        },
    ) + highLevelModules

}

