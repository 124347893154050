package com.osg.truebase.ui.signIn

import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import com.osg.truebase.signin.ISignInProvider
import com.osg.truebase.ui.generated.resources.Res
import com.osg.truebase.ui.strings.CommonStringRes
import com.osg.truebase.ui_strings.generated.resources.collision_detected_title
import com.osg.truebase.ui_strings.generated.resources.confirm
import com.osg.ui.core.resources.asFormattedText
import com.osg.ui.core.resources.string
import com.osg.ui.core.resources.vector


@Composable
fun SignInCollisionDialog(
    registeredMethod: ISignInProvider,
    onSignInClicked: () -> Unit,
) {
    val brandedButtonType = registeredMethod.identifier.brandedButtonType
    AlertDialog(
        icon = {
            Icon(
                imageVector = brandedButtonType.drawableResource.vector,
                contentDescription = CommonStringRes.collision_detected_title.string
            )
        },
        title = {
            Text(text = CommonStringRes.collision_detected_title.string)
        },
        text = {
            Text(
                text = CommonStringRes
                    .collision_detected_title
                    .asFormattedText(registeredMethod.identifier.brandedButtonType.stringRes)
            )
        },
        onDismissRequest = onSignInClicked,
        confirmButton = {
            TextButton(
                onClick = onSignInClicked
            ) {
                Text(CommonStringRes.confirm.string)
            }
        }
    )
}