package osg.uiZone.matchZone.entertainment

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.statusBarsPadding
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.osg.appData.matchZone.invitation.InvitationContent
import com.osg.ice.ui.EntertainmentDetailsScreen
import osg.uiZone.common.bar.FloatingNavFab
import osg.uiZone.matchZone.state.tmEntUi.*
import osg.uiZone.navigation.IceBreakerDestination

@Composable
fun TmEntertainmentDetailsScreen(
    entertainmentDataType: TmEntertainmentUiType,
    onInvitationResponse: (InvitationContent) -> Unit,
    onStartGame: (IceBreakerDestination) -> Unit,
    onBack: () -> Unit = {}
) {
    Box{
        EntertainmentDetailsScreen(
            icebreakerUiData = entertainmentDataType,
        ){
            when(entertainmentDataType){
                is IEntResponse -> {
                    entertainmentDataType.FabView(
                        onOption = {
                            onInvitationResponse(it)
                            when(entertainmentDataType){
                                is NewEntertainmentUiData -> onBack()
                                is ResponseEntertainmentUi -> {
                                    onStartGame(
                                        IceBreakerDestination(
                                            entertainmentDataType.invitation
                                        )
                                    )
                                }
                            }
                        }
                    )
                }
                is TmEntApproved -> {
                    entertainmentDataType.EnterGameButton(
                        onNavigateToGame = onStartGame
                    )
                }
            }
        }
        FloatingNavFab(
            modifier = Modifier.align(Alignment.TopStart).statusBarsPadding(),
            onClick = onBack
        )
    }

}