package osg.uiZone.register.ui

import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.requiredHeightIn
import androidx.compose.foundation.selection.selectableGroup
import androidx.compose.material3.Scaffold
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.ui.animation.CommonAnimation
import com.osg.truebase.ui.animation.LottieAnimationView
import com.osg.truebase.ui.assigners.wrapper.QuestionWrapper
import com.osg.truebase.ui.assigners.wrapper.QuestionsBottomBar
import com.osg.ui.core.commonStates.TrueImage
import com.osg.ui.core.resources.toUiText
import com.osg.ui.core.resources.uiText
import osg.uiZone.profile.EditImageCommand
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.add_image_text
import truematch.uizone.generated.resources.finish_register_button_text
import truematch.uizone.generated.resources.remain_image_text

@Composable
fun ProfileImagePick(
    currentImages: List<TrueImage>,
    minimumImageToSave: Int,
    editImageCommand: (EditImageCommand) -> Unit,
    onPreviousPressed: () -> Unit = {},
    onNextPressed: () -> Unit
) {
    AppLogger.d("ProfileImageAssigner", "recomposing ProfileImageAssigner")
    var isClicked by remember { mutableStateOf(false) }
    Scaffold(
        bottomBar = {
            QuestionsBottomBar(
                shouldShowPreviousButton = false,
                isNextButtonEnabled = currentImages.size >= minimumImageToSave,
                onPreviousPressed = onPreviousPressed,
                onNextPressed = {
                    isClicked = true
                    onNextPressed()
                },
                showLoading = isClicked,
                nextButtonTextRes = Res.string.finish_register_button_text,
            )
        }
    ) { paddingValues ->
        QuestionWrapper(
            title = Res.string.add_image_text.uiText,
            directions = Res.string.remain_image_text.toUiText(minimumImageToSave.toString()),
            animationCompose = {
                LottieAnimationView(
                    modifier = Modifier
                        .weight(1f, fill = false).requiredHeightIn(max = 120.dp),
                    animationData = CommonAnimation.BLUSHING.trueAnimation,
                )
            },
            modifier = Modifier
                .padding(paddingValues)
                .selectableGroup(),
        )
        {
            EditProfileImagesView(
                editImageCommand = editImageCommand,
                currentImages = currentImages
            )
        }
    }
}
