package osg.uiZone.matchZone.entertainment

import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.core.tween
import androidx.compose.foundation.layout.*
import androidx.compose.material3.Button
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.animation.LottieAnimationView
import com.osg.ui.core.resources.string
import com.osg.truebase.ui.graphics.konfetti.ConfettiView
import com.osg.truebase.ui.graphics.konfetti.Presets
import com.osg.ui.core.resources.string
import com.osg.truebase.ui.theme.spacing
import osg.uiZone.matchZone.entertainment.data.IceResultsScreenUiState
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.user_continue


@Composable
fun IceResultsScreen(
    iceResultsScreenUiState: IceResultsScreenUiState,
    modifier: Modifier = Modifier,
    onContinue :() -> Unit,
) {

    Scaffold(
        modifier = modifier.fillMaxSize(),
    ) { innerPadding ->
        Column(
            modifier = Modifier
                .padding(innerPadding),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.extraExtraLarge)
        ) {
            LottieAnimationView(
                modifier = Modifier
                    .fillMaxWidth()
                    .weight(1f),
                animationData = iceResultsScreenUiState.animation,
            )

            IceResultsContent(
                modifier = Modifier
                    .fillMaxWidth(),
                title = iceResultsScreenUiState.resultTitle.string,
                subTitle = iceResultsScreenUiState.subTitle.string,
            )

            val animatedAlpha by animateFloatAsState(
                targetValue = if (iceResultsScreenUiState.isShowButton) 1f else 0f,
                animationSpec = tween(300)
            )
            Button(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(
                        horizontal = MaterialTheme.spacing.medium,
                        vertical = MaterialTheme.spacing.medium
                    )
                    .height(48.dp).graphicsLayer {
                        alpha = animatedAlpha
                    },
                onClick = onContinue,
                enabled =  iceResultsScreenUiState.isShowButton,
            ) {
                Text(text = Res.string.user_continue.string)
            }
        }
        ConfettiView(
            modifier = Modifier.fillMaxSize(),
            parties = Presets.explode() + Presets.festive(),
        )
    }
}

@Composable
fun IceResultsContent(
    modifier: Modifier = Modifier,
    title: String,
    subTitle: String,
) {
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Text(
            text = title,
            style = MaterialTheme.typography.displaySmall,
            color = MaterialTheme.colorScheme.onSurface,
            textAlign = TextAlign.Center,
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = MaterialTheme.spacing.medium)
        )
        Text(
            text = subTitle,
            style = MaterialTheme.typography.bodyLarge,
            color = MaterialTheme.colorScheme.onSurface,
            textAlign = TextAlign.Center,
            modifier = Modifier
                .fillMaxWidth()
                .padding(vertical = 24.dp, horizontal = 16.dp)
        )
    }
}