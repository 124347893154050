package com.osg.appData.ml

import kotlinx.serialization.Serializable

@Serializable
data class MatchOsOutput(
    val requesterUid: String = "",
    val candidateGrades: List<CandidateMlGrade> = emptyList(),
)

@Serializable
data class BatchMatchOsOutput(
    val outputs: List<MatchOsOutput> = emptyList(),
)

