package osg.uiZone.matchZone.journey.items

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.Icons

import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.LineHeightStyle
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.osg.ui.core.commonStates.asPainter
import osg.uiZone.common.graphic.GradientProgressBar
import osg.uiZone.matchZone.icons.Route
import osg.uiZone.matchZone.journey.journeyTitleWeight
import osg.uiZone.matchZone.journey.journeyValWeight
import osg.uiZone.matchZone.state.JourneyPanelItem
import osg.uiZone.matchZone.state.tmEntUi.NewEntertainmentUiData
import osg.uiZone.search.components.OverlappingRow

@Composable
fun RelationShipStageCard(
    modifier: Modifier = Modifier,
    relationShipStage: JourneyPanelItem.RelationShipStage,
) {
    val title = "STAGE"
    val imageVector = Icons.Default.Route
    Column(
        modifier = modifier.padding(16.dp),
        verticalArrangement = Arrangement.spacedBy(10.dp)
    ) {
        Text(
            style = MaterialTheme.typography.titleMedium.copy(
                fontWeight = journeyTitleWeight
            ),
            text = title
        )
        Row(
            modifier = Modifier,
            horizontalArrangement = Arrangement.spacedBy(8.dp)
        ) {
            Icon(
                imageVector = imageVector,
                contentDescription = title,
            )
            Text(
                text = relationShipStage.stage.toString(),
                style = MaterialTheme.typography.titleLarge.copy(
                    lineHeightStyle = LineHeightStyle(
                        alignment = LineHeightStyle.Alignment.Top,
                        trim = LineHeightStyle.Trim.Both
                    ),
                    fontWeight = journeyValWeight
                )
            )
            Text(
                style = MaterialTheme.typography.bodySmall,
                text = "Each stage unlocks new opportunities to discover another layer in your journey",
            )
        }
        StageNewFeaturesBar(
            modifier = Modifier.Companion.fillMaxWidth(),
            entData = relationShipStage.entData,
            currentStage = relationShipStage.stage.toFloat(),
            maxStage = 10f,
            initialStage = relationShipStage.initialStage.toFloat()
        )
    }
}

@Composable
fun StageNewFeaturesBar(
    entData: List<NewEntertainmentUiData>,
    modifier: Modifier = Modifier,
    currentStage: Float = 1f,
    maxStage: Float = 10f,
    initialStage: Float = 0f // for preview only
) {
    Column(
        modifier = modifier,
        verticalArrangement = Arrangement.spacedBy(4.dp)
    ) {
        GradientProgressBar(
            currentValue = currentStage,
            maxValue = maxStage,
            indicatorColor = listOf(
                MaterialTheme.colorScheme.primary,
                Color.Red,
            ),
            initialValue = initialStage,
            trackColor = MaterialTheme.colorScheme.surface,
            height = 8.dp,
            modifier = Modifier
                .fillMaxWidth(),
        )
        Row(
            modifier = Modifier.fillMaxWidth()
        ) {
            val nextFeaturesPlace = currentStage / maxStage
            Spacer(modifier = Modifier.fillMaxWidth(nextFeaturesPlace))
            StageNewFeatures(
                modifier = Modifier.padding(start = 8.dp),
                entData = entData.filter {
                    it.unlockStage == currentStage.toInt() + 1
                },
            )
        }
    }
}


@Composable
fun StageNewFeatures(
    entData: List<NewEntertainmentUiData>,
    modifier: Modifier = Modifier,
    imagesSize: Dp = 20.dp,
    maxItems: Int = 3
) {
    val items = entData.take(maxItems)
    OverlappingRow(
        overlapFactor = 0.5f,
        modifier = modifier,
    ) {
        items.forEach { ent ->
            Image(
                painter = ent.image.asPainter(256),
                contentDescription = null,
                contentScale = ContentScale.Crop,
                modifier = Modifier
                    .size(imagesSize)
                    .clip(CircleShape)
                    .background(
                        color = MaterialTheme.colorScheme.surfaceContainer,
                    )
            )
        }
    }
}