package com.osg.appData.ml

import kotlinx.serialization.Serializable

@Serializable
data class LlmAdminRequest(
    val instruction: String = "",
    val prompts: List<LlmPrompts> = emptyList(),
)

@Serializable
data class LlmPrompts(
    val messages: List<LlmMessage> = emptyList(),
)

@Serializable
data class LlmMessage(
    val sender: SenderType = SenderType.USER,
    val message: String,
)
enum class SenderType {
    USER,
    ADMIN,
}