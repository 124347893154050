package com.osg.truebase.ui.assigners.data

import androidx.compose.ui.text.input.KeyboardType
import com.osg.truebase.data.user.Gender
import com.osg.truebase.ui.animation.CommonAnimation
import com.osg.truebase.ui.assigners.ScFieldAssigner
import com.osg.truebase.ui.assigners.SingleChoiceOption
import com.osg.ui.core.resources.UiText
import com.osg.ui.core.resources.uiText
import com.osg.truebase.ui.assigners.TextFieldAssigner
import com.osg.truebase.ui.assigners.asScData
import com.osg.truebase.ui.assigners.def.GenericQuestionAssigner
import com.osg.truebase.ui.assigners.def.QuestionUiData
import com.osg.truebase.ui.assigners.def.genObjTransformer
import com.osg.truebase.ui.assigners.def.toGenericQuestionAssigner
import com.osg.ui.core.commonStates.asTrueVector
import com.osg.truebase.ui.generated.resources.Res
import com.osg.truebase.ui.strings.CommonStringRes
import com.osg.truebase.ui_strings.generated.resources.*
import com.osg.truebase.ui.generated.resources.baseline_male_24
import com.osg.truebase.ui.generated.resources.baseline_female_24

import org.jetbrains.compose.resources.StringResource

val Gender.stringResource: StringResource
    get(){
        return when(this){
            Gender.Male -> CommonStringRes.male
            Gender.Female -> CommonStringRes.female
        }
    }

val nameQuestionAssigner = GenericQuestionAssigner<String>(
    questionUiData = QuestionUiData(
        title = CommonStringRes.your_first_name_is.uiText,
        animationResource = CommonAnimation.BLUSHING.trueAnimation,
        directions = UiText.Empty,
    ),
    genObjAssigner = TextFieldAssigner(
        directions = UiText.Empty,
        keyboardType = KeyboardType.Text,
        validate = { true }
    ),
)

val genderAssigner: GenericQuestionAssigner<Gender> = ScFieldAssigner(
    possibleAnswers = listOf(
        SingleChoiceOption(
            Gender.Male.stringResource.uiText,
            Res.drawable.baseline_male_24.asTrueVector()
        ),
        SingleChoiceOption(
            Gender.Female.stringResource.uiText,
            Res.drawable.baseline_female_24.asTrueVector()
        )
    ).asScData()
).genObjTransformer<Int ,Gender>(
    reverseTransformer = {
        Gender.entries.indexOf(it)
    },
    transformer = { a, t ->
        Gender.entries[a]
    }
).toGenericQuestionAssigner(
    questionUiData = QuestionUiData(
        title = CommonStringRes.what_gender.uiText,
        animationResource = CommonAnimation.Gender.trueAnimation,
        directions = CommonStringRes.choose_one.uiText
    )
)

