package osg.uiZone.matchZone.entertainment.baseContent

import com.osg.appData.matchZone.ice.IcebreakerCollection
import com.osg.truebase.data.iceDef.IceTag
import com.osg.ui.core.commonStates.asTrueImage
import com.osg.ui.core.resources.uiText
import com.osg.utils.plus
import osg.uiZone.matchZone.entertainment.TMEntertainmentDataUiBase
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.emoji_resonance_description
import truematch.uizone.generated.resources.emoji_resonance_name
import truematch.uizone.generated.resources.emoji_resonance_slogan
import uiZone.trueAutoResources.LocalImages

val emojiResonanceBase = TMEntertainmentDataUiBase(
    description = Res.string.emoji_resonance_description.uiText,
    image = LocalImages.EMOJI_GAME.asTrueImage(),
    name = Res.string.emoji_resonance_name.uiText,
    
    unlockStage = 2,
    slogan = Res.string.emoji_resonance_slogan.uiText,
    vibes = IceTag.Funny + IceTag.ProblemSolving,
    id = IcebreakerCollection.EmojiResonance.name,
)