package com.osg.ice

import com.osg.def.database.DatabaseService
import com.osg.truebase.data.iceDef.IceContentPreferences
import com.osg.truebase.data.iceDef.IceLiveEvent
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map

interface ILiveDataRepo {
    suspend fun pushLiveData(path: String, value: IceLiveEvent)
    fun getLiveDataFlow(path: String): Flow<IceLiveEvent>
}

class IceRepositoryImpl(
    private val databaseService: DatabaseService
): IceRepository {
    override suspend fun pushPreferences(
        path: String,
        value: IceContentPreferences
    ) {
        databaseService.push(path, value)
    }

    override fun getPreferencesFlow(path: String): Flow<IceContentPreferences> {
        return databaseService.getChildFlow<IceContentPreferences>(path).map { it.data }
    }

    override suspend fun pushLiveData(path: String, value: IceLiveEvent) {
        databaseService.push(path, value)
    }

    override fun getLiveDataFlow(path: String): Flow<IceLiveEvent> {
        return databaseService.getChildFlow<IceLiveEvent>(path).map { it.data }
    }

}
