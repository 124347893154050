package com.osg.truebase.data.nodeData

import kotlinx.serialization.Serializable

interface IImageDeclaration

interface IMediaResource{
    val mediaPath: String
}

interface IRemoteMediaResource: IMediaResource

interface IRemoteImageResource: IRemoteMediaResource, IImageDeclaration

interface IRemoteProfileImage: IRemoteImageResource{
    val comment: String
}

@Serializable
data class RemoteImage(
    override val mediaPath: String,
): IRemoteImageResource

fun IMediaResource.toRemoteImage(): RemoteImage{
    return RemoteImage(mediaPath)
}

@Serializable
data class RemoteMedia(
    override val mediaPath: String,
): IRemoteMediaResource

fun IMediaResource.toRemoteMedia(): RemoteMedia{
    return RemoteMedia(mediaPath)
}


@Serializable
data class RemoteProfileImage(
    override val mediaPath: String,
    override val comment: String = "",
): IRemoteProfileImage

@Serializable
data class RemoteAudio(
    override val mediaPath: String,
): IRemoteMediaResource

fun IMediaResource.toRemoteAudio(): RemoteAudio{
    return RemoteAudio(mediaPath)
}
