package com.osg.truematch.demo.baseModuleSim

import com.osg.appData.functions.FunctionResponseType
import com.osg.appData.functions.ICloudFunctionsEngine
import com.osg.appData.functions.ITmCloudFunctions
import com.osg.matchmaker.matchZone.UnMatchZoneCf
import com.osg.matchmaker.search.CfRepo
import com.osg.matchmaker.search.SearchZoneCf
import com.osg.truebase.data.nodeData.decodeFromHexString
import kotlinx.coroutines.flow.MutableStateFlow
import org.koin.core.component.KoinComponent
import org.koin.core.component.get


class CloudFunctionsEngineSim(
    var callerUid: String,
    private val simInitializer: suspend CloudFunctionsEngineSim.() -> Unit
) : ICloudFunctionsEngine, KoinComponent {
    private val searchZoneCf = SearchZoneCf(
        CfRepo(get())
    )
    private val unMatchZoneCf = UnMatchZoneCf(CfRepo(get()))
    private val isInitializedStateFlow = MutableStateFlow(false)
    override suspend fun callFirebaseFunction(
        functionName: String,
        data: String
    ): FunctionResponseType {
        if (!isInitializedStateFlow.value) {
            isInitializedStateFlow.value = true
            val callerUid = callerUid
            simInitializer()
            this.callerUid = callerUid
        }
        return when (functionName) {
            ITmCloudFunctions::match.name -> {
                searchZoneCf.onItsAMatch(
                    data.decodeFromHexString()
                )
            }

            ITmCloudFunctions::unMatch.name -> {
                unMatchZoneCf.onUnMatched(
                    data.decodeFromHexString()
                )
            }

            ITmCloudFunctions::findMeMatchRequest.name -> {
                searchZoneCf.findMatch(callerUid)
            }

            ITmCloudFunctions::sendNote.name -> {
                searchZoneCf.findMatch(callerUid)
            }


            else -> {
                throw Exception("no matching function for $functionName")
            }
        }
    }
}

