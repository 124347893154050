package osg.uiZone.matchZone.model

import androidx.compose.runtime.Immutable
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.osg.appData.matchZone.ice.IcebreakerCollection
import com.osg.appData.relationshipStage.computeRelationshipStage
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.ui.commonStates.MainViewUiState
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.stateIn
import osg.uiZone.common.vmRepo.matchZone.ISharedJourneyRepo
import osg.uiZone.common.vmRepo.matchZone.MatchScope
import osg.uiZone.matchZone.journey.genJourneyPanelItems
import osg.uiZone.matchZone.state.JourneyPanelItem
import osg.uiZone.matchZone.state.tmEntUi.baseContent
import osg.uiZone.matchZone.state.tmEntUi.toEntertainmentDataUi

@Immutable
data class JourneyPanelState(
    val journeyPanelItems: List<JourneyPanelItem>
)

class SharedJourneyModel(
    matchZoneRepo: ISharedJourneyRepo = MatchScope().matchZoneRepository,
) : ViewModel() {

    val uiState: StateFlow<MainViewUiState<JourneyPanelState>> = combine(
        matchZoneRepo.getMatchInfoFlow(),
        matchZoneRepo.getMatchZoneProfilesFlow()
    ) { matchInfo, profilesUi ->
        val stage = matchInfo.computeRelationshipStage()
        val games = IcebreakerCollection.entries.map {
            it.baseContent.toEntertainmentDataUi(matchInfo)
        }.filter {
            it.unlockStage == stage + 1
        }

        val journeyPanelItems = genJourneyPanelItems(
            stageHighLights = profilesUi.candidateProfile.stageHighLights,
            matchInfo = matchInfo,
            entData = games,
            approvedSparkEvents = emptyList()
        )

        MainViewUiState.Success(
            JourneyPanelState(
                journeyPanelItems = journeyPanelItems
            )
        )
    }.stateIn(
        scope = viewModelScope,
        started = SharingStarted.WhileSubscribed(),
        initialValue = MainViewUiState.Loading
    )

    override fun onCleared() {
        super.onCleared()
        MatchScope.closeMatchScope()
        AppLogger.d("MatchScope", "onCleared")
    }
}