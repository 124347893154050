@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package truematch.web.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.FontResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainFont0 {
  public val crimson_text_bold: FontResource by 
      lazy { init_crimson_text_bold() }

  public val crimson_text_regular: FontResource by 
      lazy { init_crimson_text_regular() }

  public val noto_color_emoji: FontResource by 
      lazy { init_noto_color_emoji() }
}

@InternalResourceApi
internal fun _collectCommonMainFont0Resources(map: MutableMap<String, FontResource>) {
  map.put("crimson_text_bold", CommonMainFont0.crimson_text_bold)
  map.put("crimson_text_regular", CommonMainFont0.crimson_text_regular)
  map.put("noto_color_emoji", CommonMainFont0.noto_color_emoji)
}

public val Res.font.crimson_text_bold: FontResource
  get() = CommonMainFont0.crimson_text_bold

private fun init_crimson_text_bold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:crimson_text_bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.web.generated.resources/font/crimson_text_bold.ttf", -1, -1),
    )
)

public val Res.font.crimson_text_regular: FontResource
  get() = CommonMainFont0.crimson_text_regular

private fun init_crimson_text_regular(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:crimson_text_regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.web.generated.resources/font/crimson_text_regular.ttf", -1, -1),
    )
)

public val Res.font.noto_color_emoji: FontResource
  get() = CommonMainFont0.noto_color_emoji

private fun init_noto_color_emoji(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:noto_color_emoji",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.web.generated.resources/font/noto_color_emoji.ttf", -1, -1),
    )
)
