package osg.uiZone.landing.model

import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavController
import androidx.navigation.NavGraphBuilder
import androidx.navigation.compose.composable
import com.osg.truebase.data.logger.AppLogger
import osg.uiZone.landing.ui.LandingScreen
import osg.uiZone.navigation.LandingDestination

fun NavGraphBuilder.landingGraph(
    navController: NavController
) {
    composable<LandingDestination>{
        val landingModel = viewModel{ LandingModel() }
        val uiState by landingModel.landingState.collectAsState()
        when(val stata = uiState){
            is LandingUiState -> {
                LandingScreen(
                    uiState = stata,
                    onSignInResult = landingModel::onSignInResult
                )
            }
            is LoadingDestination -> {
                LandingShader()
                LaunchedEffect(stata) {
                    stata.destination?.let { dst ->
                        AppLogger.d("LandingScreen", "Navigate to: $dst")
                        navController.navigate(dst)
                    }
                }
            }
        }
    }
}



