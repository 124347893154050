package com.osg.truebase.ui.assigners

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.material3.Card
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.ExposedDropdownMenuDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.unit.dp
import com.osg.ui.core.resources.string
import com.osg.truebase.ui.assigners.def.GenObjAssigner
import com.osg.truebase.ui.intrinsic.pxToDp
import com.osg.ui.core.resources.UiText
import com.osg.truebase.ui.theme.spacing
import kotlinx.coroutines.launch

@Immutable
data class DropDownAssigner(
    private val initialOptions: List<String>,
    private val directions: UiText,
    private val asyncOptions: suspend (String) -> List<String> = { emptyList() }
) : GenObjAssigner<String> {
    @Composable
    override fun content(
        modifier: Modifier,
        current: String?,
        onAnswered: (String) -> Unit,
    ) {
        val focusManager = LocalFocusManager.current
        var textInput by remember { mutableStateOf(current?: "") }
        var autocompleteOptions by remember { mutableStateOf(initialOptions) }
        var expanded by remember { mutableStateOf(false) }
        val autoCollateCache = remember { mutableMapOf<String, List<String>>() }
        val scope = rememberCoroutineScope()
        DropDownSelectionAutoComplete(
            direction = directions,
            textInput = textInput,
            autocompleteOptions = autocompleteOptions,
            onValueChange = {
                textInput = it
            },
            onSelect = {
                expanded = false
                focusManager.clearFocus()
                textInput = it
                onAnswered(it)
            },
            expanded = expanded,
            modifier = modifier,
        )

        LaunchedEffect(textInput) {
            if(current == textInput){
                onAnswered(textInput)
                return@LaunchedEffect
            }

            scope.launch {
                autocompleteOptions =
                    autoCollateCache[textInput] ?: asyncOptions(textInput).also { options ->
                        if (options.isNotEmpty()) {
                            val sortedOptions = options.sortedByDescending {
                                it.startsWith(textInput, ignoreCase = true)
                            }
                            autoCollateCache[textInput] = sortedOptions
                            expanded = true
                        } else {
                            expanded = false
                        }
                    }
            }
        }
    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun DropDownSelectionAutoComplete(
    direction: UiText,
    textInput: String,
    autocompleteOptions: List<String>,
    onValueChange: (String) -> Unit,
    onSelect: (String) -> Unit,
    expanded: Boolean,
    modifier: Modifier = Modifier,
) {
    var textFieldSize by remember {
        mutableStateOf(0)
    }
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        TextField(
            modifier = Modifier.onGloballyPositioned { coordinates ->
                textFieldSize = coordinates.size.width
            },
            value = textInput,
            onValueChange = onValueChange,
            label = {
                Text(direction.string)
            },
            trailingIcon = {
                ExposedDropdownMenuDefaults.TrailingIcon(
                    expanded = expanded
                )
            },
            colors = ExposedDropdownMenuDefaults.textFieldColors(),
            keyboardActions = KeyboardActions(onDone = {
                onSelect(textInput)
            }),
        )
        AnimatedVisibility(visible = expanded) {
            val widthDp = textFieldSize.pxToDp()
            AutoComplete(
                modifier = Modifier.width(widthDp),
                autocompleteOptions = autocompleteOptions,
                onValueChange = onSelect,
            )
        }
    }
}


@Composable
fun AutoComplete(
    modifier: Modifier = Modifier,
    autocompleteOptions: List<String>,
    onValueChange: (String) -> Unit,
) {
    Card(
        modifier = modifier,
        shape = RoundedCornerShape(10.dp)
    ) {
        LazyColumn(
            modifier = Modifier.heightIn(max = 150.dp),
        ) {
            items(autocompleteOptions.size) { selectionOption ->
                val text = autocompleteOptions[selectionOption]
                DropOption(
                    text = text,
                    onClick = onValueChange
                )
            }
        }
    }
}


@Composable
fun DropOption(
    text: String,
    onClick: (String) -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .clickable {
                onClick(text)
            }
            .padding(MaterialTheme.spacing.small)
    ) {
        Text(text = text)
    }
}