
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import androidx.navigation.ExperimentalBrowserHistoryApi
import bot.media.trueAutoResources.BotsMediaLocalAllMedia
import com.osg.appData.bots.TestUsers
import com.osg.def.database.LaunchStringResolver
import com.osg.def.jsEnvironment
import com.osg.truebase.datastore.DataStoreEngine
import com.osg.truebase.player.audio.JsAudioPlayer
import com.osg.truebase.ui.theme.TrueTheme
import com.osg.truebase.web.PreLoadFallback
import com.osg.truematch.demo.baseModuleSim.SimManager
import com.osg.truematch.demo.startKoinDemoApp
import com.osg.trumatch.bots.changeUserStage
import com.osg.trumatch.bots.nonProd.UserTestStage
import kotlinx.browser.document
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.skiko.wasm.onWasmReady
import org.w3c.dom.HTMLDivElement
import spark.media.trueAutoResources.SparksMediaLocalAllMedia


@OptIn(ExperimentalBrowserHistoryApi::class)
fun main() {
    val divElement = document.getElementById("splash")
    val divHtmlElement = divElement as HTMLDivElement
    val initialProfile = TestUsers.MaleTestUser.changeUserStage(
        UserTestStage.SEARCH
    )
    startKoinDemoApp(
        audioPlayerLoader = { JsAudioPlayer() },
        launchStringResolver = {
            LaunchStringResolver(::jsEnvironment)
        },
        buildDataStore = { DataStoreEngine() },
        simManager = SimManager(
            initialStorageData = BotsMediaLocalAllMedia + SparksMediaLocalAllMedia,
            initialProfile = initialProfile,
        ),
    )
    onWasmReady {
        @OptIn(ExperimentalComposeUiApi::class)
        CanvasBasedWindow(canvasElementId = "ComposeTarget") {
            PreLoadFallback {
                val transitionDuration = 3000
                fadeOutElement(divHtmlElement, transitionDuration)
                TrueTheme {
                    WebLandingContent()
                }

//                Box(
//                    modifier = Modifier.aspectRatio(0.6f)
//                ){
//                    tmPreviewApp.AppEntry()
//                }
                //            WebLandingScreen(
                //                modifier = Modifier,
                //                onSendMail = { mail ->
                //
                //                }
                //            )

            }
        }
    }
}

//fun loadAnimation(div: HTMLDivElement): AnimationItem {
//    val jsonFileUrl = "animation_logo.json"
//    val animData = obj<AnimData> {
//        container = div
//        renderer = "svg"
//        loop = true
//        autoplay = true
//        path = jsonFileUrl
//    }
//
//    return lottie.loadAnimation(animData)
//}


@OptIn(DelicateCoroutinesApi::class)
fun fadeOutElement(
    element: HTMLDivElement,
    transitionDuration: Int
) {
    GlobalScope.launch {
        delay(1000)
        val fadeOut = transitionDuration / 3
        element.style.transition = "opacity ${fadeOut}ms ease-out"
        element.style.opacity = "0"
        delay(fadeOut.toLong())
        element.style.zIndex = "-1"
    }
}

/**
 * Return empty JS Object
 */
//fun obj(): dynamic = js("{}")

/**
 * Helper function for creating JavaScript objects with given type.
 */
//inline fun <T> obj(init: T.() -> Unit): T {
//    return (obj().unsafeCast<T>()).apply(init)
//}