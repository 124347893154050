package com.osg.def

import com.osg.def.database.IAppConfig
import com.osg.truebase.data.logger.AppLogger
import kotlinx.browser.window
import org.w3c.dom.url.URL

fun jsEnvironment(): String? {
    val launchStringName = PlatformEnvironmentAux.launchStringName
    val url = URL(window.location.href)
    return url.searchParams.get(launchStringName)
}