package com.osg.ice.challengeAccepted.ui


import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.unit.dp
import com.osg.ice.state.RoundStatus
import com.osg.truebase.ui.color.lightnessFactor
import com.osg.truebase.ui.color.negBinaryColor
import com.osg.truebase.ui.graphics.shimmerLoadingAnimation
import com.osg.truebase.ui.theme.brandColors
import com.osg.truebase.ui.theme.spacing

data class RadioButtonColor(
    val background: Color,
    val border: Color,
    val textColor: Color,
)

@Composable
fun SingleChoiceCh(
    roundStatus: RoundStatus,
    possibleString: List<String>,
    modifier: Modifier = Modifier,
    icons: List<ImageVector>? = null,
    onOptionSelected: (Int) -> Unit,
){
    val iconList = icons ?: List(possibleString.size) { null }
    val textStyle = if (possibleString.size > 4){
        MaterialTheme.typography.bodyMedium
    } else {
        MaterialTheme.typography.bodyLarge
    }

    var localSelectedIndex by remember(
        possibleString
    ) { mutableStateOf(-1) }

    Column(
        modifier,
        verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small)
    ) {
        possibleString.indices.forEach { index ->
            val radioColors = roundStatus.ResolveRadioButtonColor(index)
            RadioButtonWithImageRow(
                modifier = Modifier.shimmerLoadingAnimation(
                    runShimmer = roundStatus is RoundStatus.Initial && localSelectedIndex == index,
                ),
                text = possibleString[index],
                imageVector = iconList[index],
                textStyle = textStyle,
                onClicked = {
                    onOptionSelected(index)
                    localSelectedIndex = index
                },
                background = radioColors.background,
                border = radioColors.border,
                textColor = radioColors.textColor,
                enabled = roundStatus.enabledSelection
            )
        }
    }
}


@Composable
fun RoundStatus.ResolveRadioButtonColor(index: Int): RadioButtonColor{
    val default = RadioButtonColor(
        background = MaterialTheme.colorScheme.surface,
        border = MaterialTheme.colorScheme.outline,
        textColor = MaterialTheme.colorScheme.onSurface
    )

    val selectedColor = RadioButtonColor(
        background = MaterialTheme.colorScheme.primaryContainer,
        border = MaterialTheme.colorScheme.primary,
        textColor = MaterialTheme.colorScheme.onPrimaryContainer
    )

    val looseColor = RadioButtonColor(
        background = MaterialTheme.colorScheme.errorContainer,
        border = MaterialTheme.colorScheme.error,
        textColor = MaterialTheme.colorScheme.onErrorContainer
    )

    val winColor = RadioButtonColor(
        background = MaterialTheme.brandColors.gain,
        border = MaterialTheme.brandColors.gain.lightnessFactor(0.8f),
        textColor = MaterialTheme.brandColors.gain.negBinaryColor(),
    )
    return when(this){
        is RoundStatus.WaitingForOpponentAnswer if index == this.myAnswerIndex -> {
            selectedColor
        }
        is RoundStatus.ShowAnswers if index.isEqualOneOf(this.myAnswerIndex, this.opponentAnswerIndex)-> {
            if (this.myAnswerIndex == this.opponentAnswerIndex){
                winColor
            } else {
                looseColor
            }
        }
        else -> {
            default
        }
    }
}

fun Int.isEqualOneOf(vararg values: Int): Boolean {
    values.forEach {
        if (this == it) return true
    }
    return false
}


@Composable
fun RadioButtonWithImageRow(
    text: String,
    background: Color,
    border: Color,
    textColor: Color,
    imageVector: ImageVector?,
    enabled: Boolean,
    onClicked: () -> Unit,
    textStyle: TextStyle,
    modifier: Modifier = Modifier,
) {
    Surface(
        shape = MaterialTheme.shapes.small,
        color = background,
        border = BorderStroke(
            width = 1.dp,
            color = border
        ),
        modifier = modifier
            .clip(MaterialTheme.shapes.small)
            .clickable(
                onClick = onClicked,
                enabled = enabled,
            )
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            if (imageVector != null) {
                Image(
                    imageVector = imageVector,
                    contentDescription = null,
                    modifier = Modifier
                        .size(56.dp)
                        .clip(MaterialTheme.shapes.extraSmall)
                        .padding(start = 0.dp, end = 8.dp)
                )
                Spacer(Modifier.width(8.dp))
            }

            Text(
                text = text,
                modifier = Modifier.weight(1f),
                style = textStyle,
                color = textColor
            )
        }
    }
}
