package com.osg.truebase.ui.animation

import ui.trueAutoResources.LocalResourceAnimations
import ui.trueAutoResources.LocalResourceLottie

enum class CommonAnimation(
    val trueAnimation: LottieAnimation
){
    BLUSHING(
        LocalResourceAnimations.ANIMATION_BLUSHING_ALT.toResource()
    ),
    FLY(
        LocalResourceAnimations.ANIMATION_PAPER_PLANE.toResource()
    ),
    LOADING(
        LocalResourceAnimations.ANIMATION_LOADING.toResource()
    ),
    CHEERS(
        LocalResourceAnimations.ANIMATION_CHEERS.toResource()
    ),
    Gender(
        LocalResourceLottie.GENDER.toResource()
    ),
}