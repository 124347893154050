package com.osg.ice.harmonyTunes.ds

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Button
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.unit.dp
import com.osg.ice.challengeAccepted.ui.LoadingScreen
import com.osg.ice.harmonyTunes.ui.PlayerButton
import com.osg.ice.harmonyTunes.ui.WaitingForPlayerAnimation
import com.osg.ice.harmonyTunes.ui.doubleSideLetter.AnswerState
import com.osg.ice.harmonyTunes.ui.doubleSideLetter.deriveAnswerState
import com.osg.ice.state.IceMatchResult
import com.osg.truebase.ice.generated.resources.Res
import com.osg.truebase.ice.generated.resources.user_continue
import com.osg.truebase.ice.generated.resources.waiting_for_other_player
import com.osg.truebase.player.audio.PlayerAction
import com.osg.truebase.player.audio.PlayerState
import com.osg.truebase.player.audio.action
import com.osg.truebase.ui.animation.LottieAnimation
import com.osg.truebase.ui.animation.LottieAnimationView
import com.osg.truebase.ui.animation.toResource
import com.osg.ui.core.resources.string
import com.osg.truebase.ui.theme.spacing
import ice.trueAutoResources.LocalResourceAnimations
import ice.trueAutoResources.LocalResourceLottie


@Immutable
sealed interface HtRoundStatus {
    val correctAnswer: String
    fun resolveAnswerState(
        answer: TextFieldValue,
    ): AnswerState {
        return deriveAnswerState(answer, correctAnswer)
    }
    @Composable
    fun Header(
        modifier: Modifier,
        onHintClick: () -> Unit = {}
    )

    @Composable
    fun BottomControl(
        onPlayerAction: (PlayerAction) -> Unit,
        onNextRound: (Int) -> Unit,
        onGameResults: (IceMatchResult) -> Unit,
    ){}

    @Immutable
    data class WaitingForOpponentAnswer(
        override val correctAnswer: String,
    ) : HtRoundStatus {
        @Composable
        override fun Header(
            modifier: Modifier,
            onHintClick: () -> Unit
        ) {
            LoadingScreen(
                modifier = modifier,
                loadingResourceText = Res.string.waiting_for_other_player
            )
        }
    }

    @Immutable
    data class WaitingForYourAnswer(
        override val correctAnswer: String,
        val playerState: PlayerState = PlayerState.Paused,
        val otherOpWrongAnswer: String? = null,
        val currentRound: Int,
        val hintsUsed: Int
    ) : HtRoundStatus {
        @Composable
        override fun Header(
            modifier: Modifier,
            onHintClick: () -> Unit
        ) {
            WaitingForPlayerAnimation(
                modifier = modifier,
                playerState = playerState,
                onHintClick = onHintClick,
            )
        }

        @Composable
        override fun BottomControl(
            onPlayerAction: (PlayerAction) -> Unit,
            onNextRound: (Int) -> Unit,
            onGameResults: (IceMatchResult) -> Unit,
        ){
             PlayerButton(
                 playerButton = playerState.action,
                 onClick = onPlayerAction
             )
        }
    }

    sealed interface IRoundResult : HtRoundStatus {
        val matchResult: IceMatchResult
        val animation: LottieAnimation
        val totalRounds: Int
        val currentRound: Int
        @Composable
        override fun Header(
            modifier: Modifier,
            onHintClick: () -> Unit
        ) {
            LottieAnimationView(
                modifier = modifier,
                animationData = animation
            )
        }

        @Composable
        override fun BottomControl(
            onPlayerAction: (PlayerAction) -> Unit,
            onNextRound: (Int) -> Unit,
            onGameResults: (IceMatchResult) -> Unit,
        ){
            Button(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(
                        horizontal = MaterialTheme.spacing.medium,
                        vertical = MaterialTheme.spacing.medium
                    )
                    .height(48.dp),
                onClick = {
                    val nextRound = currentRound + 1
                    if (nextRound == totalRounds) {
                        onGameResults(matchResult)
                    } else{
                        onNextRound(nextRound)
                    }
                },
            ) {
                Text(text = Res.string.user_continue.string)
            }
        }

        @Immutable
        data class LooseRound(
            override val correctAnswer: String,
            override val currentRound: Int,
            override val totalRounds: Int,
            override val matchResult: IceMatchResult
        ) : IRoundResult {
            override val animation: LottieAnimation = LocalResourceAnimations.ANIMATION_CROSSEDFINGERS.toResource()
        }

        @Immutable
        data class WinRound(
            override val correctAnswer: String,
            override val currentRound: Int,
            override val totalRounds: Int,
            override val matchResult: IceMatchResult,
            val isIWroteTheAnswer: Boolean
        ) : IRoundResult {
            override val animation: LottieAnimation = LocalResourceLottie.ANIMATION_ELEPHANT.toResource()
        }
    }
}
