package osg.uiZone.search.screens

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.core.tween
import androidx.compose.animation.scaleIn
import androidx.compose.animation.scaleOut
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.*
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.geometry.Rect
import androidx.compose.ui.geometry.RoundRect
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.*
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.unit.dp
import androidx.compose.ui.zIndex
import com.osg.appData.candidate.Decision
import com.osg.truebase.ui.theme.spacing
import osg.uiZone.common.bar.DecisionBar
import osg.uiZone.common.bar.FloatingNavFab
import osg.uiZone.profile.IncentiveCardType
import osg.uiZone.profile.ProfileUiItems
import osg.uiZone.search.components.*
import osg.uiZone.utills.isScrolledToTheEnd


@Composable
fun CandidatePreview(
    candidatePreviewState: ProfileUiItems,
    modifier: Modifier = Modifier,
    onDecision: (Decision) -> Unit = {},
    onBack: () -> Unit = {},
) {
    val listState = rememberLazyListState(
        initialFirstVisibleItemIndex = candidatePreviewState.initialPos
    )
    var isSeenProfile by remember { mutableStateOf(false) }
    if (listState.isScrolledToTheEnd()) {
        isSeenProfile = true
    }

    Box(
        modifier = modifier
            .fillMaxSize()
            .navigationBarsPadding()
    ) {
        ProfileItemsList(
            profileItemsState = candidatePreviewState,
            listState = listState,
            onBack = onBack,
        ) {
            DecisionBar(
                modifier = Modifier
                    .fillParentMaxWidth()
                    .padding(
                        horizontal = MaterialTheme.spacing.medium,
                        vertical = MaterialTheme.spacing.small
                    ).animateItem(),
                onLike = { onDecision(Decision.LIKE) },
                onDislike = { onDecision(Decision.DISLIKE) },
                onSuperLike = { onDecision(Decision.SUPER_LIKE) },
            )
        }

        CandidateDecisionBar(
            modifier = Modifier
                .align(Alignment.BottomCenter)
                .padding(MaterialTheme.spacing.small),
            visible = isSeenProfile &&
                    listState.isScrollInProgress.not() &&
                    listState.isScrolledToTheEnd().not(),
            onDecision = onDecision
        )
    }
}


@Composable
fun ProfileItemsList(
    profileItemsState: ProfileUiItems,
    modifier: Modifier = Modifier,
    listState: LazyListState = rememberLazyListState(),
    onBack: () -> Unit,
    extraNavigationFab: @Composable () -> Unit = {},
    lastItem: @Composable LazyItemScope.() -> Unit = {},
) {
    Box(
        modifier = modifier
    ) {
        AnimatedVisibility(
            modifier = Modifier.align(Alignment.TopStart).zIndex(1f),
            visible = listState.isScrollInProgress.not(),
            enter = scaleIn(),
            exit = scaleOut(),
        ) {
            Row{
                FloatingNavFab(
                    onClick = onBack
                )
                extraNavigationFab()
            }
        }
        LazyColumn(
            state = listState,
        ) {
            item {
                CoverImageView(
                    coverImage = profileItemsState.header,
                    modifier = Modifier.fillMaxWidth().heightIn(max = 500.dp)
                )
            }
            item {
                TechnicalDetails(
                    modifier = Modifier.padding(
                        horizontal = MaterialTheme.spacing.medium,
                    ),
                    profileUiDetails = profileItemsState.technicalDetails,
                    iconColor = Color.Black,
                    textColor = Color.Black
                )
                HorizontalDivider(
                    modifier = Modifier.padding(
                        horizontal = MaterialTheme.spacing.medium,
                        vertical = MaterialTheme.spacing.small
                    )
                )
            }
            items(profileItemsState.previewItemsList) { profileItem ->
                IncentiveCardItemView(
                    content = profileItem,
                    modifier = Modifier.padding(
                        horizontal = MaterialTheme.spacing.medium,
                    )
                )
                HorizontalDivider(
                    modifier = Modifier.padding(
                        horizontal = MaterialTheme.spacing.medium,
                        vertical = MaterialTheme.spacing.small
                    )
                )
            }
            item {
                lastItem()
            }
        }
    }
}

@Composable
fun IncentiveCardItemView(
    content: IncentiveCardType,
    modifier: Modifier = Modifier,
) {
//    Rebugger(
//        trackMap = mapOf(
//            "content" to content,
//        ),
//        composableName = "IncentiveCardItemView"
//    )
    when (content) {
        is IncentiveCardType.TextCard -> {
            IncentiveCard(
                modifier = modifier,
                textCard = content
            )
        }

        is IncentiveCardType.ImageComment -> {
            ImageCommentCard(
                modifier = modifier.heightIn(max = 500.dp),
                imageCommentCard = content
            )
        }

        is IncentiveCardType.AiMatchIncentive -> {
            AiTextCard(
                aiMatchIncentive = content,
                modifier = modifier,
            )
        }
    }
}

class Drawer : Shape {
    override fun createOutline(
        size: Size,
        layoutDirection: LayoutDirection,
        density: Density
    ): Outline {
        val path = Path().apply {
            addRoundRect(
                RoundRect(
                    left = 0f,
                    top = size.height * 0.95f,
                    right = size.width,
                    bottom = size.height,
                    topLeftCornerRadius = CornerRadius(
                        x = size.width / 2,
                        y = size.width / 2
                    ),
                    topRightCornerRadius = CornerRadius(
                        x = size.width / 2,
                        y = size.width / 2
                    )
                )
            )
        }

        val path2 = Path().apply {
            addRect(
                Rect(
                    left = 0f,
                    top = 0f,
                    right = size.width,
                    bottom = size.height,
                )
            )
        }

        val resultPath = Path.combine(
            PathOperation.Difference,
            path2,
            path,
        )

        return Outline.Generic(path = resultPath)
    }
}

@Composable
fun CandidateDecisionBar(
    onDecision: (Decision) -> Unit,
    visible: Boolean,
    modifier: Modifier = Modifier
) {
    val animatedSize by animateFloatAsState(
        targetValue = if (visible) 1f else 0f,
        animationSpec = tween(300)
    )

    DecisionBar(
        modifier = modifier.graphicsLayer {
            scaleX = animatedSize
            scaleY = animatedSize
            transformOrigin = TransformOrigin.Center
        },
        onLike = { onDecision(Decision.LIKE) },
        onDislike = { onDecision(Decision.DISLIKE) },
        onSuperLike = { onDecision(Decision.SUPER_LIKE) },
    )
}
