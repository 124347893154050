package com.osg.truebase.ui.graphics.konfetti

import androidx.compose.ui.geometry.Rect
import com.osg.truebase.ui.graphics.konfetti.emitter.BaseEmitter
import com.osg.truebase.ui.graphics.konfetti.emitter.Confetti
import com.osg.truebase.ui.graphics.konfetti.emitter.PartyEmitter

/**
 * PartySystem is responsible for requesting particles from the emitter and updating the particles
 * everytime a new frame is requested.
 * @param party configuration class with instructions on how to create the particles for the Emitter
 * @param pixelDensity default value taken from resources to measure based on pixelDensity
 */
class PartySystem(
    val party: Party,
    pixelDensity: Float
) {
    private var emitter: BaseEmitter = PartyEmitter(party.emitterConfig, pixelDensity)

    private val activeParticles = mutableListOf<Confetti>()
    var totalCreatedParticles: Int = 0
    // Called every frame to create and update the particles state
    // returns a list of particles that are ready to be rendered
    fun render(deltaTime: Float,
               frameTime: Long,
               drawArea: Rect
    ): List<Particle> {
        val newParticles = emitter.createConfetti(deltaTime, frameTime, party, drawArea)
        val maxParticles =  party.emitterConfig.totalParticles - totalCreatedParticles
        val itemsToTake = minOf(newParticles.size, maxParticles)
        val newParticlesToBeAdded = newParticles.take(
            itemsToTake
        )
        totalCreatedParticles += newParticlesToBeAdded.size
        activeParticles.addAll(newParticlesToBeAdded)

        activeParticles.forEach { it.render(deltaTime, drawArea) }

        activeParticles.removeAll { it.isDead() }

        return activeParticles.filter { it.drawParticle }.map { it.toParticle() }
    }

    /**
     * When the emitter is done emitting.
     * @return true if the emitter is done emitting or false when it's still busy or needs to start
     * based on the delay
     */
    fun isDoneEmitting(): Boolean =
        emitter.isFinished(totalCreatedParticles)

    fun isFinished(): Boolean = activeParticles.isEmpty() && isDoneEmitting()
}

/**
 * Convert a confetti object to a particle object with instructions on how to draw
 * the confetti to a canvas
 */
fun Confetti.toParticle(): Particle {
    return Particle(
        location.x,
        location.y,
        width,
        width,
        alphaColor,
        rotation,
        scaleX,
        shape,
        alpha,
    )
}