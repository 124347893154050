package osg.uiZone.search.screens

import androidx.compose.animation.*
import androidx.compose.animation.core.*
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.BasicText
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Favorite
import androidx.compose.material.icons.filled.Star
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawWithContent
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.style.TextMotion
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.IntSize
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.graphics.konfetti.ConfettiView
import com.osg.truebase.ui.graphics.konfetti.Presets
import com.osg.truebase.ui.intrinsic.dpToPx
import com.osg.truebase.ui.theme.spacing
import com.osg.ui.core.commonStates.TrueImage
import com.osg.ui.core.commonStates.asPainter
import com.osg.ui.core.resources.painter
import com.osg.ui.core.resources.string
import kotlinx.coroutines.delay
import osg.uiZone.common.paint.ImagesDuet
import osg.uiZone.common.pyrotechnics.text.AnimateTextColor
import truematch.uizone.generated.resources.*
import kotlin.math.pow
import kotlin.math.sqrt

open class MatchImages(
    open val candidateImage: TrueImage,
    open val myProfileImage: TrueImage,
)


sealed interface ItsMatchUiStates

sealed interface LoadingMatchAnimationState: ItsMatchUiStates{
    data class  Loading(
        val iterations: Int,
    ): LoadingMatchAnimationState
    data class FinalScene(
        val trueImage: TrueImage
    ): LoadingMatchAnimationState
}

sealed interface PromoUiStates{
    data object Loading: PromoUiStates
    data class Ready(
        val superLike: Boolean = false,
        override val candidateImage: TrueImage,
        override val myProfileImage: TrueImage,
    ): PromoUiStates, ItsMatchUiStates, MatchImages(
        candidateImage = candidateImage,
        myProfileImage = myProfileImage,
    )
}




@Composable
fun ItsMatchScreen(
    promoUiState: PromoUiStates,
    onNavMatchZone: () -> Unit,
) {
    var iterations by remember { mutableStateOf(0) }
    var isFinishedPreview by remember { mutableStateOf(false) }
    val itsMatchUiStates: ItsMatchUiStates  = when(promoUiState){
        is PromoUiStates.Ready if isFinishedPreview -> {
            promoUiState
        }
        is PromoUiStates.Ready if iterations > 3-> {
            LoadingMatchAnimationState.FinalScene(promoUiState.candidateImage)
        }
        else -> {
            LoadingMatchAnimationState.Loading(iterations)
        }
    }

    ItsMatchDerivedState(
        uiState = itsMatchUiStates,
        onFinishedPreview = {
            isFinishedPreview = true
        },
        onNavMatchZone = onNavMatchZone
    )

    LaunchedEffect(iterations) {
        if (itsMatchUiStates is LoadingMatchAnimationState.Loading){
            delay(1000)
            iterations++
        }
    }
}

@Composable
fun ItsMatchDerivedState(
    uiState: ItsMatchUiStates,
    onFinishedPreview: () -> Unit = {},
    onNavMatchZone: () -> Unit,
){
    when (uiState){
        is LoadingMatchAnimationState -> {
            ItsMatchLoadingScreen(
                animationState = uiState,
                onFinishedPreview = onFinishedPreview,
            )
        }
        is PromoUiStates.Ready -> {
            ItsMatchView(
                uiState = uiState,
                navigateToMatchZone = onNavMatchZone
            )
            ConfettiView(
                modifier = Modifier.fillMaxSize(),
                parties = Presets.explode() + Presets.festive(),
                onFinished = { }
            )
        }
    }
}

@Composable
fun ItsMatchView(
    uiState: PromoUiStates.Ready,
    navigateToMatchZone: () -> Unit,
    modifier: Modifier = Modifier
) {
    val imageWidth = 75.dp * 2
    val imageHeight = 105.dp * 2
    val overLap = 1 / 8f
    var showButtonAnimState by remember { mutableStateOf(false) }
    LaunchedEffect(Unit) {
        delay(1000)
        showButtonAnimState = true
    }
    Column(
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = modifier
            .fillMaxSize()
            .background(
                Brush.verticalGradient(
                    listOf(
                        Color(0xFFf6d365),
                        Color(0xFFfda085),
                    )
                )
            ),
    ) {
        AnimateTextColor(
            modifier = Modifier
                .height(imageHeight / 2)
                .fillMaxWidth(),
            text = Res.string.its_match_string.string,
        )
        Spacer(modifier = Modifier.height(MaterialTheme.spacing.medium))
        Box(
            modifier = Modifier,
        ) {
            ImagesDuet(
                firstImage = uiState.candidateImage.asPainter(512),
                secondImage = uiState.myProfileImage.asPainter(512),
                modifier = Modifier,
                imageWidth = imageWidth,
                imageHeight = imageHeight,
                overLapRatio = overLap
            )
            if (uiState.superLike) {
                Icon(
                    imageVector = Icons.Filled.Star,
                    tint = Color(0xFFf6d365),
                    modifier = Modifier
                        .align(Alignment.BottomCenter)
                        .size(imageWidth / 4),
                    contentDescription = null,
                )
            }
        }
        Text(
            modifier = Modifier.padding(
                top = MaterialTheme.spacing.large,
            ),
            text = Res.string.its_match_description.string,
            color = MaterialTheme.colorScheme.onSurface,
        )

        AnimatedContent(
            targetState = showButtonAnimState,
            transitionSpec = {
                (slideInVertically { height -> height } + fadeIn())
                    .togetherWith(slideOutVertically { height -> -height } + fadeOut())
                    .using(
                        // Disable clipping since the faded slide-in/out should
                        // be displayed out of bounds.
                        SizeTransform(clip = false)
                    )
            },
            label = ""
        ) { showButtonAnim ->
            if (showButtonAnim) {
                val initialColor = MaterialTheme.colorScheme.onSurface
                val targetValueColor = Color.Red
                val infiniteTransition = rememberInfiniteTransition(label = "infinite transition")
                val animatedColor by infiniteTransition.animateColor(
                    initialValue = initialColor,
                    targetValue = targetValueColor,
                    animationSpec = infiniteRepeatable(tween(1000), RepeatMode.Reverse),
                    label = "color"
                )
                ExtendedFloatingActionButton(
                    containerColor = MaterialTheme.colorScheme.surface,
                    modifier = Modifier
                        .height(60.dp)
                        .padding(16.dp),
                    onClick = navigateToMatchZone,
                    icon = {
                        Icon(
                            Icons.Filled.Favorite,
                            Res.string.go_to_match_zone.string,
                            tint = Color.Red
                        )
                    },

                    text = {
                        BasicText(
                            text = Res.string.go_to_match_zone.string,
                            style = LocalTextStyle.current.merge(
                                textMotion = TextMotion.Animated,
                                fontStyle = FontStyle.Italic
                            ),
                            color = {
                                animatedColor
                            },
                        )
                    },
                )
            }
        }
    }
}


@Composable
fun ItsMatchLoadingScreen(
    animationState: LoadingMatchAnimationState,
    onFinishedPreview: () -> Unit,
    radius: Dp = 150.dp
) {
    val imageRatio = 1.75f
    val widthPlusHeight = sqrt((2 * radius.value).pow(2))
    val imageWidth = widthPlusHeight / (imageRatio + 1)
    val imageHeight = imageWidth * imageRatio

    val radiusPx = radius.dpToPx()
    var screenSize by remember { mutableStateOf(IntSize.Zero) }
    val height = screenSize.height.toFloat()
    val width = screenSize.width.toFloat()

    val keyPoints = listOf(
        Offset(radiusPx, radiusPx),
        Offset(radiusPx, height - radiusPx),
        Offset(width - radiusPx, radiusPx),
        Offset(width - radiusPx, height - radiusPx),
        Offset(radiusPx*2, radiusPx),
        Offset(radiusPx*2, height - radiusPx),
        Offset((width - radiusPx*2), radiusPx),
        Offset((width - radiusPx*2), height - radiusPx),
    )
    val finalPoint = Offset((width / 2), (height / 2))

    val transition = updateTransition(animationState, label = "transition")
    val center by transition.animateOffset(
        transitionSpec = {
            tween(
                durationMillis = 850,
                delayMillis = 50,
                easing = LinearOutSlowInEasing
            )
        },
        label = "offset",
    ){
        when(it) {
            is LoadingMatchAnimationState.Loading -> keyPoints[it.iterations % keyPoints.size]
            is LoadingMatchAnimationState.FinalScene -> finalPoint
        }
    }

    val alpha by transition.animateFloat {
        when(it) {
            is LoadingMatchAnimationState.Loading -> 0f
            is LoadingMatchAnimationState.FinalScene -> 1f
        }
    }

    LaunchedEffect(transition.currentState){
        if (transition.currentState is LoadingMatchAnimationState.FinalScene) {
            delay(200)
            onFinishedPreview()
        }
    }

    Box(
        modifier = Modifier
            .fillMaxSize()
            .onSizeChanged {
                screenSize = it
            }
            .background(
                Brush.verticalGradient(
                    listOf(
                        Color(0xFFf6d365),
                        Color(0xFFfda085),
                    )
                )
            ),
    ) {
        Image(
            modifier = Modifier
                .fillMaxSize(),
            painter = Res.drawable.heart_pattern.painter,
            contentScale = ContentScale.Crop,
            contentDescription = null
        )
        if (animationState is LoadingMatchAnimationState.FinalScene) {
            Image(
                modifier = Modifier
                    .align(Alignment.Center)
                    .height(imageHeight.dp)
                    .width(imageWidth.dp)
                    .clip(RoundedCornerShape(16.dp))
                    .alpha(alpha),
                painter = animationState.trueImage.asPainter(),
                contentDescription = null,
                contentScale = ContentScale.Crop
            )
        }
        Box(
            modifier = Modifier
                .fillMaxSize()
                .drawWithContent {
                drawContent()
                drawRect(
                    Brush.radialGradient(
                        listOf(Color.Transparent, Color.Black),
                        center = center,
                        radius = radiusPx,
                    )
                )
            }
        )
    }
}



