package com.osg.truebase.data.iceDef

import com.osg.truebase.data.iceDef.ch.ChallengeAcceptedRoundContent
import com.osg.truebase.data.iceDef.ht.HtRoundData
import kotlinx.serialization.Serializable

@Serializable
sealed interface IceContent{
    val roundsData: List<IceRoundData>
}

@Serializable
data class ChallengeAcceptedContent(
    override val roundsData: List<ChallengeAcceptedRoundContent> = emptyList(),
): IceContent

@Serializable
data class HtContent(
    override val roundsData: List<HtRoundData> = emptyList(),
): IceContent

interface IceRoundData {
    val id: String
}
