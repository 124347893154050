package osg.uiZone.common.baseRepos.database

import com.osg.appData.matchZone.invitation.Invitation
import com.osg.def.database.DatabaseService
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map

fun interface IInvitationTracker {
    fun getInvitationFlow(path: String): Flow<Invitation>
}

val DatabaseService.invitationTracker: IInvitationTracker
    get() = IInvitationTracker{ path ->
        getChildFlow<Invitation>(path).map { it.data }
    }
