package com.osg.truebase.ui.color

import androidx.compose.foundation.Image
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.graphics.DefaultAlpha
import androidx.compose.ui.graphics.ImageBitmap
import androidx.compose.ui.graphics.luminance
import androidx.compose.ui.graphics.toPixelMap
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.util.fastCoerceIn
import com.osg.ui.core.imageProcces.scale
import kotlin.math.abs
import kotlin.math.max
import kotlin.math.min

fun Color.negBinaryColor(): Color {
    return if(luminance() > 0.5f) Color.Black else Color.White
}


data class HslColor(
    val hue: Float,
    val saturation: Float,
    val lightness: Float,
    val alpha: Float = 1f,
) {
    val color = Color.hsl(hue, saturation, lightness, alpha)
}

fun Color.lightnessFactor(
    factor: Float
): Color {
    val hsl = toHsl()

    return Color.hsl(
        hue = hsl.hue,
        saturation = hsl.saturation,
        lightness = (hsl.lightness * factor).fastCoerceIn(0f, 1f),
        alpha = alpha
    )
}

fun Color.saturationFactor(
    factor: Float
): Color {
    val hsl = toHsl()
    return Color.hsl(
        hue = hsl.hue,
        lightness = hsl.lightness,
        saturation = (hsl.saturation * factor).fastCoerceIn(0f, 1f),
        alpha = alpha
    )
}

fun Color.toHsl(): HslColor {
    val max =
        max(red.toDouble(), max(green.toDouble(), blue.toDouble())).toFloat()
    val min =
        min(red.toDouble(), min(green.toDouble(), blue.toDouble())).toFloat()
    val deltaMaxMin = max - min
    var h: Float
    val s: Float
    val l = (max + min) / 2f
    if (max == min) {
        // Monochromatic
        s = 0f
        h = s
    } else {
        h = when (max) {
            red -> (green - blue) / deltaMaxMin % 6f
            green -> (blue - red) / deltaMaxMin + 2f
            else -> (red - green) / deltaMaxMin + 4f
        }
        s = (deltaMaxMin / (1f - abs((2f * l - 1f).toDouble()))).toFloat()
    }
    h = h * 60f % 360f
    if (h < 0) {
        h += 360f
    }

    return HslColor(
        hue = h.fastCoerceIn(0f, 360f),
        saturation = s.fastCoerceIn(0f, 1f),
        lightness = l.fastCoerceIn(0f, 1f),
        alpha = alpha
    )
}

fun Color.inverseColor(): Color {
    return Color(
        red = 1f - red,
        green = 1f - green,
        blue = 1f - blue,
        alpha = alpha,
    )
}


@Composable
fun ImageDetector(
    modifier: Modifier = Modifier.Companion,
    imageBitmap: ImageBitmap,
    contentDescription: String?,
    alignment: Alignment = Alignment.Companion.Center,
    contentScale: ContentScale = ContentScale.Companion.Fit,
    alpha: Float = DefaultAlpha,
    colorFilter: ColorFilter? = null,
    onDetect: (color: Color) -> Unit,
) {
    Image(
        bitmap = imageBitmap,
        contentDescription = contentDescription,
        modifier = modifier,
        alignment = alignment,
        contentScale = contentScale,
        alpha = alpha,
        colorFilter = colorFilter
    )

    LaunchedEffect(Unit) {
        val dominantColor = imageBitmap.computeDominantColor()
        onDetect(dominantColor)
    }
}

suspend fun ImageBitmap.computeDominantColor(): Color {
    val scaled = this.scale(9, 9)
    val pixelMap = scaled.toPixelMap()
    var redSum = 0f
    var greenSum = 0f
    var blueSum = 0f
    var alphaSum = 0f
    var pixelCount = 0

    for (y in 0 until pixelMap.height) {
        for (x in 0 until pixelMap.width) {
            val color = pixelMap[x, y]
            if (color.alpha < 0.1f) {
                continue
            }

            pixelCount++
            redSum += color.red
            greenSum += color.green
            blueSum += color.blue
            alphaSum += color.alpha
        }
    }

    return Color(
        red = redSum / pixelCount,
        green = greenSum / pixelCount,
        blue = blueSum / pixelCount,
        alpha = alphaSum / pixelCount
    )
}