package osg.uiZone.matchZone.journey.items

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.blur
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.osg.appData.profile.IStageHighLight
import com.osg.truebase.ui.paint.PreGradientBackground
import com.osg.ui.core.commonStates.AsyncImage
import com.osg.ui.core.commonStates.TrueImage
import osg.uiZone.matchZone.journey.journeyTitleWeight
import osg.uiZone.matchZone.state.JourneyPanelItem

class StageHighLightUi(
    override val media: TrueImage,
    stageHighLight: IStageHighLight
): IStageHighLight by stageHighLight

@Composable
fun StoryList(
    stageHighlights: JourneyPanelItem.StageHighlights,
    onHighlightClicked: () -> Unit,
    modifier: Modifier = Modifier
) {
    val sortedList = stageHighlights.highlights.sortedBy { it.unlockStage }
    Column(
        modifier = modifier.padding(start = 16.dp, top = 16.dp),
        verticalArrangement = Arrangement.spacedBy(10.dp)
    ) {
        Text(
            style = MaterialTheme.typography.titleMedium.copy(
                fontWeight = journeyTitleWeight
            ),
            text = "HIGHLIGHTS"
        )
        LazyRow(
            modifier = Modifier.height(100.dp),
            horizontalArrangement = Arrangement.spacedBy(4.dp),
            contentPadding = PaddingValues(horizontal = 8.dp)
        ) {
            items(sortedList) {
                StoryItem(
                    modifier = Modifier,
                    currentStage = stageHighlights.currentStage,
                    stageHighLight = it,
                    onClick = onHighlightClicked
                )
            }
        }
    }

}

private val enableStroke by lazy {
    BorderStroke(
        3.dp,
        Brush.sweepGradient(
            PreGradientBackground.StoryColors.colors
        )
    )
}

private val disableStroke by lazy {
    BorderStroke(
        3.dp,
        SolidColor(Color.LightGray)
    )
}

@Composable
fun StoryItem(
    currentStage: Int,
    stageHighLight: StageHighLightUi,
    borderWidth: Dp = 3.dp,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
) {

    val isEnabled = stageHighLight.unlockStage <= currentStage

    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(4.dp)
    ) {
        stageHighLight.media.AsyncImage(
            maxDimPixels = 256,
            contentScale = ContentScale.Crop,
            modifier = Modifier
                .weight(5f)
                .aspectRatio(1f)
                .border(
                    border = if (isEnabled) enableStroke else disableStroke,
                    shape = CircleShape
                )
                .padding(borderWidth * 2)
                .clip(CircleShape)
                .clickable(
                    onClick = onClick,
                    enabled = isEnabled
                )
                .then(
                    if (isEnabled) Modifier else Modifier.blur(16.dp)
                )
        )
        Text(
            modifier = Modifier.weight(1f, false),
            text = if (isEnabled) {
                stageHighLight.title
            } else {
                "Stage ${stageHighLight.unlockStage}"
            },
            style = MaterialTheme.typography.labelSmall
        )
    }

}