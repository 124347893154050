package osg.uiZone.graph

import androidx.compose.runtime.Composable
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.ui.dialog.AlertDialogExitWithError
import osg.uiZone.expect.permission.PermissionHandlerScreen
import osg.uiZone.expect.permission.PermissionStatus
import osg.uiZone.navigation.GlobalPopUp
import osg.uiZone.navigation.GlobalState

@Composable
fun TmPopUp(
    globalPopUp: GlobalPopUp,
    onPermissionStatus: (PermissionStatus) -> Unit
) {
    AppLogger.d("TmPopUp", "globalPopUp: $globalPopUp")
    when (globalPopUp) {
        is GlobalPopUp.CheckingPermissions -> {
            PermissionHandlerScreen(
                permissionType = globalPopUp.permissionRequest,
                onPermissionStatus = onPermissionStatus
            )
        }
        is GlobalPopUp.Error -> {
            AlertDialogExitWithError(
                fatalErrorType = globalPopUp.errorType
            )
        }
        is GlobalPopUp.None -> {
            // No PopUp
        }


    }
}