package osg.uiZone.matchZone.chat

import com.osg.appData.matchZone.Message
import com.osg.ui.core.resources.UiText
import com.osg.ui.core.resources.uiText
import com.osg.utils.time.TimeService.daysDifferenceFromNow
import com.osg.utils.time.TimeService.millisToUtcDateTime
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.today
import truematch.uizone.generated.resources.yesterday
import kotlin.time.DurationUnit
import kotlin.time.toDuration

fun Message.isDifferentDays(otherMessage: Message): Boolean {
    val messageDay = this.timestamp.toDuration(DurationUnit.MILLISECONDS)
    val otherMessageDay = otherMessage.timestamp.toDuration(DurationUnit.MILLISECONDS)
    val daysDifference = messageDay.inWholeDays - otherMessageDay.inWholeDays
    return daysDifference > 0
}

fun Message.isFromMe(myUid: String): Boolean {
    return this.uid == myUid
}


fun Message.messageTimestamp(): UiText {
    val days = this.timestamp.daysDifferenceFromNow()
    return when (days) {
        0L -> {
            Res.string.today.uiText
        }
        1L -> {
            Res.string.yesterday.uiText
        }
        else -> {
            val dateTime = this.timestamp.millisToUtcDateTime()
            "${dateTime.dayOfMonth} ${dateTime.monthNumber}, ${dateTime.year}".uiText
        }
    }
}