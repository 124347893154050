package osg.uiZone.search.components

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.statusBarsPadding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import androidx.compose.ui.zIndex
import com.osg.ui.core.commonStates.asPainter
import com.osg.truebase.ui.theme.brandColors
import com.osg.truebase.ui.theme.spacing
import osg.uiZone.profile.CoverImage
import osg.uiZone.profile.EditImageCommand
import osg.uiZone.profile.ItemToolOption
import osg.uiZone.search.screens.Drawer

@Composable
fun CoverImageView(
    coverImage: CoverImage,
    modifier: Modifier = Modifier.Companion
) {
    var itemToolOption by remember { mutableStateOf<ItemToolOption<EditImageCommand>?>(null) }
    Box {
        CompositionLocalProvider(LocalContentColor provides MaterialTheme.brandColors.navIconColor) {
            EditIconBar(
                modifier = Modifier.align(Alignment.TopEnd)
                    .zIndex(1f)
                    .padding(
                        top = MaterialTheme.spacing.small,
                        end = MaterialTheme.spacing.medium
                    )
                    .statusBarsPadding()
                    .background(
                        color = MaterialTheme.brandColors.navIconSurface,
                        shape = RoundedCornerShape(8.dp)
                    ),
                itemActionOptions = coverImage.itemActionOptions,
                onEditClicked = { itemToolOption = it }
            )
        }
        Image(
            painter = coverImage.truePicture.asPainter(),
            contentScale = ContentScale.Companion.FillWidth,
            contentDescription = null,
            modifier = modifier.clip(Drawer())
        )
    }

    ItemImageToolDialog(0, itemToolOption) {
        itemToolOption = null
    }
}