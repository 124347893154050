package osg.uiZone.common.dialog

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import com.osg.truebase.ui.error.ExitApp
import com.osg.ui.core.commonStates.asVector
import com.osg.ui.core.resources.string
import osg.uiZone.expect.permission.PermissionUiRationale
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.confirm
import truematch.uizone.generated.resources.decline_permission
import truematch.uizone.generated.resources.exit_app

@Composable
fun PermissionRationalPopup(
    permissionUiRationale: PermissionUiRationale,
    onNotMandatoryDismiss: () -> Unit,
    onConfirm: () -> Unit
) {
    var exitApp by remember { mutableStateOf(false) }
    val title = permissionUiRationale.title.string
    AlertDialog(
        icon = {
            Icon(permissionUiRationale.icon.asVector(), contentDescription = title)
        },
        title = {
            Text(
                modifier = Modifier.fillMaxWidth(),
                textAlign = TextAlign.Center,
                text = title
            )
        },
        text = {
            Text(
                modifier = Modifier.fillMaxWidth(),
                textAlign = TextAlign.Center,
                text = permissionUiRationale.message.string
            )
        },
        onDismissRequest = onNotMandatoryDismiss,
        confirmButton = {
            TextButton(
                onClick = onConfirm
            ) {
                Text(Res.string.confirm.string)
            }
        },
        dismissButton = {
            TextButton(
                onClick = {
                    if (permissionUiRationale.isMandatory) {
                        exitApp = true
                    } else {
                        onNotMandatoryDismiss()
                    }
                }
            ) {
                val dismissText = if (permissionUiRationale.isMandatory) {
                    Res.string.exit_app
                } else {
                    Res.string.decline_permission
                }

                Text(dismissText.string)
            }
        }
    )

    if (exitApp) {
        ExitApp()
    }
}