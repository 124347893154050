package osg.uiZone.matchZone

import androidx.compose.foundation.layout.*
import androidx.compose.material.icons.Icons
import androidx.compose.material3.CenterAlignedTopAppBar
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Scaffold
import androidx.compose.material3.ScaffoldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.unit.dp
import com.osg.appData.matchZone.Message
import com.osg.appData.resource.SpecsQueries
import com.osg.truebase.ui.button.OptionsButton
import com.osg.truebase.ui.ds.IGeneralIconButtonUi
import com.osg.truebase.ui.ds.toItem
import com.osg.ui.core.commonStates.TrueVector
import com.osg.ui.core.commonStates.asTrueImage
import com.osg.ui.core.commonStates.asTrueVector
import com.osg.ui.core.resources.UiText
import com.osg.ui.core.resources.asFormattedText
import com.osg.ui.core.resources.string
import com.osg.ui.core.resources.uiText
import com.osg.utils.time.TimeService.getUtcTime
import osg.uiZone.common.bar.ProfileImageBar
import osg.uiZone.matchZone.chat.BasicUserInputChatBox
import osg.uiZone.matchZone.chat.ChatMessages
import osg.uiZone.matchZone.icons.CallSplit
import osg.uiZone.matchZone.icons.Undo
import osg.uiZone.matchZone.state.UnmatchUiState
import truematch.uizone.generated.resources.*

@Composable
fun UnmatchedMainScreen(
    unmatchUiStates: UnmatchUiState,
    onRematchClicked: () -> Unit,
    onLeaveToSearchClicked: () -> Unit,
    onSendChatClickListener: (String) -> Unit,
    modifier: Modifier = Modifier,
) {
    val openingText = Res.string.unmatchOpening.asFormattedText(
        unmatchUiStates.unmatchedName
    )
    val openingMessage = Message(
        text = openingText,
        uid = SpecsQueries.MATCH_MAKER_ID,
        timestamp = if (unmatchUiStates.conversation.isEmpty()) {
            getUtcTime()
        } else {
            unmatchUiStates.conversation.first().timestamp - 10
        }
    )

    val conversationWithOpening = listOf(openingMessage) + unmatchUiStates.conversation
    Scaffold(
        modifier = modifier,
        topBar = {
            UnmatchTopBar(
                isUserUnmatched = unmatchUiStates.isUserUnmatched,
                onRematchClicked = onRematchClicked,
                onLeaveToSearchClicked = onLeaveToSearchClicked,
                modifier = Modifier,
            )
        },
        bottomBar = {
            BasicUserInputChatBox(
                onSendChatClickListener = onSendChatClickListener,
                modifier = Modifier
                    .fillMaxWidth()
                    .navigationBarsPadding()
                    .imePadding()
            )
        },
        contentWindowInsets = ScaffoldDefaults
            .contentWindowInsets
            .exclude(WindowInsets.navigationBars)
            .exclude(WindowInsets.ime),
    ) { paddingValues ->
        Column(
            Modifier
                .fillMaxSize()
                .padding(paddingValues)
        ) {
            ChatMessages(
                conversation = conversationWithOpening,
                myUid = unmatchUiStates.myUid,
                modifier = Modifier.weight(1f),
            )
        }
    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun UnmatchTopBar(
    isUserUnmatched: Boolean,
    onRematchClicked: () -> Unit,
    onLeaveToSearchClicked: () -> Unit,
    modifier: Modifier = Modifier,
) {
    Box(
        modifier = modifier.shadow(elevation = 20.dp)
    ) {
        CenterAlignedTopAppBar(
            modifier = modifier,
            title = {
                ProfileImageBar(
                    candidateName = Res.string.matcher_ia_name.string,
                    image = Res.drawable.matcher.asTrueImage(),
                    modifier = Modifier,
                )
            },
            actions = {
                OptionsButton(
                    dropDownOptions = buildList {
                        if (isUserUnmatched) {
                            add(
                                UnmatchDropDownOption.LEAVE_TO_SEARCH.toItem(
                                    onLeaveToSearchClicked
                                )
                            )
                        }
                        add(
                            UnmatchDropDownOption.REMATCH.toItem(
                                onRematchClicked
                            )
                        )
                    },
                    imageVector = Icons.AutoMirrored.Filled.CallSplit,
                )
            }
        )
    }
}


enum class UnmatchDropDownOption(
    override val trueVector: TrueVector,
    override val uiText: UiText,
): IGeneralIconButtonUi {
    REMATCH(
        Icons.AutoMirrored.Outlined.Undo.asTrueVector(),
        Res.string.rematch.uiText
    ),
    LEAVE_TO_SEARCH(
        Res.drawable.puzzel.asTrueVector(),
        Res.string.leave_to_search.uiText,
    );
}
