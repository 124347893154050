package com.osg.truebase.data.iceDef

enum class IceTag {
    Music,
    Relax,
    Sport,
    Adrenaline,
    Nature,
    Romance,
    Party,
    Funny,
    Art,
    Fantasy,
    Memories,
    Mindfulness,
    Trivia,
    Culinary,
    Adventure,
    Intimacy,
    Nostalgia,
    ProblemSolving,
    Mystery
}