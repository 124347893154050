package com.osg.truebase.spotlight

import androidx.compose.runtime.Composable

// Data class for a tutorial step
interface ITutorialStep {
    val idx: Int
    val id: String
}

fun interface IComposableTextLoader {
    @Composable
    fun loadText(): String
}

interface ITutorialStepUi: ITutorialStep {
    val textLoader: IComposableTextLoader
    val titleLoader: IComposableTextLoader?
        get() {
            return null
        }
    val buttonTextLoader: IComposableTextLoader
}