package com.osg.truebase.data.iceDef

import com.osg.truebase.data.LanguageSupport
import com.osg.truebase.data.iceDef.ht.MusicGenre
import kotlinx.serialization.Serializable

@Serializable
sealed interface IceQuery {
    val seenRoundsIds: List<String>
    val language: LanguageSupport
    val totalRounds: Int
    fun filterContent(content: IceContent): IceContent
}

@Serializable
data class ChallengeAcceptedQuery(
    override val seenRoundsIds: List<String>,
    override val language: LanguageSupport,
    override val totalRounds: Int,
): IceQuery {
    override fun filterContent(content: IceContent): IceContent {
        val caContent = content as ChallengeAcceptedContent
        return ChallengeAcceptedContent(
            roundsData = caContent.roundsData.filter{
                it.id !in seenRoundsIds
            }.take(totalRounds)
        )
    }
}

@Serializable
data class HtQuery(
    override val seenRoundsIds: List<String>,
    override val language: LanguageSupport,
    override val totalRounds: Int,
    val genres: List<MusicGenre>,
): IceQuery{
    override fun filterContent(content: IceContent): HtContent {
        val caContent = content as HtContent
        return HtContent(
            roundsData = caContent.roundsData.filter{
                it.id !in seenRoundsIds
            }.take(totalRounds)
        )
    }
}