package osg.uiZone.matchZone.entertainment.baseContent

import com.osg.appData.matchZone.ice.IcebreakerCollection
import com.osg.truebase.data.iceDef.IceTag
import com.osg.ui.core.commonStates.asTrueImage
import com.osg.ui.core.resources.uiText
import com.osg.utils.plus
import osg.uiZone.matchZone.entertainment.TMEntertainmentDataUiBase
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.in_pic_description
import truematch.uizone.generated.resources.in_pic_name
import truematch.uizone.generated.resources.in_pic_slogan
import uiZone.trueAutoResources.LocalImages

val inPictureBase = TMEntertainmentDataUiBase(
    description = Res.string.in_pic_description.uiText,
    image = LocalImages.IMAGE_GUESS.asTrueImage(),
    name = Res.string.in_pic_name.uiText,
    
    unlockStage = 1,
    slogan = Res.string.in_pic_slogan.uiText,
    vibes = IceTag.Trivia + IceTag.Memories,
    id = IcebreakerCollection.InPicture.name,
)