package com.osg.truebase.ui.graphics.konfetti

import androidx.compose.animation.core.withInfiniteAnimationFrameMillis
import androidx.compose.foundation.Canvas
import androidx.compose.ui.graphics.drawscope.withTransform
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.platform.LocalDensity
import com.osg.utils.time.TimeService.getUtcTime
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.produceState
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Rect
import com.osg.truebase.data.logger.AppLogger

@Immutable
data class FrameData(
    val particles: List<Particle> = emptyList(),
    val isFinished: Boolean = false
)


@Composable
fun ConfettiView(
    modifier: Modifier = Modifier,
    parties: List<Party>,
    onFinished: () -> Unit = {},
) {
    val drawArea = remember {
        mutableStateOf(Rect.Zero)
    }

    val frameData by produceFrameState(
        parties = parties,
        drawArea = drawArea.value
    )

    ConfettiCanvas(
        modifier = modifier.onGloballyPositioned {
            drawArea.value = Rect(0f, 0f, it.size.width.toFloat(), it.size.height.toFloat())
        },
        particles = frameData.particles
    )

    LaunchedEffect(frameData.isFinished) {
        if (frameData.isFinished){
            AppLogger.d("ConfettiView: onFinished")
            onFinished()
        }
    }
}

@Composable
fun produceFrameState(
    parties: List<Party>,
    drawArea: Rect
): State<FrameData>{
    if (drawArea == Rect.Zero){
        return object : State<FrameData>{
            override val value: FrameData
                get() = FrameData()
        }
    }

    val pixelDensity = LocalDensity.current
    return produceState(
        FrameData()
    ) {
        val partySystems = parties.map {
            PartySystem(
                party = it,
                pixelDensity = pixelDensity.density
            )
        }
        var initialFrameTime = -1L
        var prevFrameTime = -1L
        while (true) {
            withInfiniteAnimationFrameMillis { frameMs ->
                initialFrameTime = if (initialFrameTime == -1L) frameMs else initialFrameTime
                val normalizedFrameTime = frameMs - initialFrameTime
                val deltaMs = if (prevFrameTime > 0) (normalizedFrameTime - prevFrameTime) else 0
                val fParticle = partySystems.map { particleSystem ->
                    // Do not start particleSystem yet if totalTimeRunning is below delay
                    if (normalizedFrameTime < particleSystem.party.delay) return@map listOf()

                    particleSystem.render(
                        deltaTime = deltaMs.div(1000f),
                        frameTime = normalizedFrameTime,
                        drawArea = drawArea
                    )
                }.flatten()
                prevFrameTime = normalizedFrameTime
                val isFinished = partySystems.all { it.isFinished() }
                value = FrameData(fParticle, isFinished)
            }
        }
    }
}

@Composable
fun ConfettiCanvas(
    modifier: Modifier = Modifier,
    particles: List<Particle>,
) {
    Canvas(
        modifier = modifier,
        onDraw = {
            particles.forEach { particle ->
                withTransform({
                    rotate(
                        degrees = particle.rotation,
                        pivot = Offset(
                            x = particle.x + (particle.width / 2),
                            y = particle.y + (particle.height / 2)
                        )
                    )
                    scale(
                        scaleX = particle.scaleX,
                        scaleY = 1f,
                        pivot = Offset(particle.x + (particle.width / 2), particle.y)
                    )
                }) {
                    particle.shape.draw(this, particle)
                }
            }
        }
    )
}