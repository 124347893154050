package com.osg.ice.harmonyTunes.ui.doubleSideLetter

import androidx.compose.runtime.Immutable
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.input.TextFieldValue
import com.osg.truebase.ui.theme.IceColors

sealed interface DecorAnswerState {
    data class NotFull(
        val currentValue: String,
        val correctValue: String
    ): DecorAnswerState
    data class Correct(
        val currentValue: String,
    ): DecorAnswerState
    data class Wrong(
        val currentValue: String,
        val correctValue: String
    ): DecorAnswerState
}


@Immutable
data class AnswerState(
    val currentValue: TextFieldValue = TextFieldValue(),
    val answerType: AnswerStateType = AnswerStateType.NotFull,
    val transformedValues: List<WordState> = emptyList(),
){
    val xDelay = 150
    val xDuration = xDelay*2
    val yDuration = xDuration
    val yDelay = xDelay/5
}

enum class AnswerStateType {
    Correct,
    Wrong,
    NotFull
}

fun deriveAnswerState(
    answer: TextFieldValue,
    correctAnswer: String,
): AnswerState {
    val words = processInput(
        answer, correctAnswer
    )
    val answerStateType = resolveWordListState(words)
    return AnswerState(
        currentValue = answer,
        transformedValues = words,
        answerType = answerStateType
    )
}

fun resolveWordListState(
    words: List<WordState>
): AnswerStateType {
    return if (words.any { it.answerState == AnswerStateType.NotFull }) {
        AnswerStateType.NotFull
    } else if (words.any { it.answerState == AnswerStateType.Wrong }) {
        AnswerStateType.Wrong
    } else {
        AnswerStateType.Correct
    }
}

fun initialWordList(
    correctAnswer: String
): AnswerState {
    var counter = -1
    val transformedValues = correctAnswer.split(" ").map { word ->
        WordState(
            word.map { _ ->
                counter++
                LetterState(
                    value = " ",
                    idx = counter,
                    letterType = LetterType.Empty
                )
            }
        )
    }

    return AnswerState(
        transformedValues = transformedValues
    )
}

fun processInput(
    userInput: TextFieldValue,
    sequenceMask: String
): List<WordState> {
    val userInputNoSpaces = userInput.text.replace(" ", "")
    val wordList = mutableListOf<WordState>()
    var letterStateList = mutableListOf<LetterState>()
    var counter = 0
    sequenceMask.forEach { letter ->
        if (letter == ' ') {
            wordList.add(WordState(letterStateList))
            letterStateList = mutableListOf()
        } else {
            val letterVal = userInputNoSpaces.getOrNull(counter)?.uppercase() ?: " "
            val letterType = when {
                letterVal == letter.uppercase() -> LetterType.Correct
                counter == userInput.selection.start -> LetterType.Cursor
                letterVal == " " -> LetterType.Empty
                else -> LetterType.Wrong
            }

            val letterState = LetterState(
                value = letterVal,
                letterType = letterType,
                idx = counter
            )

            letterStateList.add(letterState)
            counter++
        }
    }
    wordList.add(WordState(letterStateList))
    return wordList
}

enum class LetterType(
    val frontColor: Color,
    val backColor: Color
) {
    Correct(Color.Unspecified, IceColors.correctAnswer),
    Wrong(
        Color.Unspecified,
        IceColors.wrongAnswer
    ),
    Empty(Color.Unspecified, Color.Unspecified),
    Cursor(
        IceColors.cursorColor,
        Color.Unspecified
    );

    fun isFinal(): Boolean {
        return this == Correct || this == Wrong
    }
}

fun trimAnswer(answer: String): String {
    return answer.replace(" ", "").lowercase()
}