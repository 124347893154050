package osg.uiZone.matchZone

import androidx.compose.runtime.Immutable
import com.osg.appData.matchZone.invitation.EntertainmentType
import com.osg.appData.matchZone.invitation.Invitation
import com.osg.appData.matchZone.invitation.InvitationStatus
import com.osg.ui.core.commonStates.TrueImage
import com.osg.ui.core.resources.UiText
import osg.uiZone.navigation.*
import osg.uiZone.search.CandidateProfileUi

data class DstButtonUiState(
    val notificationCount: Int = 0
)

@Immutable
data class TopBarState(
    val myProfileImage: TrueImage,
    val candidateProfileUi: CandidateProfileUi,
    val title: UiText = UiText.Empty,
    val activeInvitations: List<Invitation> = emptyList(),
) {
    private val Invitation.isISender: Boolean
        get() = this.senderUid == candidateProfileUi.candidateProfile.uid

    fun resolveButtonUiState(dst: MatchZoneDestination): DstButtonUiState {
        val activeInvitations = activeInvitations.filter {
            when (it.status) {
                InvitationStatus.NEW -> it.isISender.not()
                InvitationStatus.NOT_RESPONDED -> it.isISender.not()
                InvitationStatus.APPROVED -> true
                InvitationStatus.REJECTED -> false
                InvitationStatus.COMPLETED -> false
            }
        }
        return when (dst) {
            MatchZoneChat -> {
                // TODO new massages count
                DstButtonUiState()
            }

            SharedJourney -> {
                DstButtonUiState()
            }

            IceBreakerFeed -> {
                DstButtonUiState(
                    notificationCount = activeInvitations.count {
                        it.type == EntertainmentType.GAME
                    },
                )
            }
            SparkFeed -> {
                DstButtonUiState(
                    notificationCount = activeInvitations.count {
                        it.type == EntertainmentType.SPARK
                    },
                )
            }
        }

    }

}

