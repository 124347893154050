package com.osg.appData.register


enum class ScDeepQuestions {
    Q1,
    Q2,
    Q3,
    Q4,
    Q5,
    Q6,
    Q7,
}

