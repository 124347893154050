package osg.uiZone.search.components

import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import osg.uiZone.profile.ItemToolOption


@Composable
fun <T> TopCardView(
    title: String,
    modifier: Modifier = Modifier,
    itemActionOptions: List<ItemToolOption<T>>,
    onEditClicked: (ItemToolOption<T>) -> Unit = {},
) {
    Row(
        modifier = modifier,
    ) {
        Text(
            text = title,
            fontWeight = FontWeight.Bold,
            color = MaterialTheme.colorScheme.onSurface,
        )
        Spacer(modifier = Modifier.weight(1f))
        EditIconBar(
            itemActionOptions = itemActionOptions,
            onEditClicked = onEditClicked
        )
    }
}

@Composable
fun<T> EditIconBar(
    modifier: Modifier = Modifier,
    itemActionOptions: List<ItemToolOption<T>>,
    onEditClicked: (ItemToolOption<T>) -> Unit = {},
) {
    Row(
        modifier = modifier,
    ) {
        itemActionOptions.forEach { itemActionOption ->
            SwingIconButton(
                imageVector = itemActionOption.icon,
                onClick = {
                    onEditClicked(itemActionOption)
                }
            )
        }
    }
}