package osg.uiZone.common.baseRepos.profile

import com.osg.appData.common.DataRoot
import com.osg.appData.common.DataRoot.Companion.userMapPath
import com.osg.appData.profile.FullProfile
import com.osg.appData.profile.Presence
import com.osg.appData.profile.PublicProfile
import com.osg.appData.profile.TechnicalProfile
import com.osg.def.auth.AuthService
import com.osg.def.auth.IUidStatus
import com.osg.def.auth.LoggedStates
import com.osg.def.database.DatabaseService
import com.osg.def.database.IPlatformStorage
import com.osg.def.database.KmpDatabaseException
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.nodeData.RemoteProfileImage
import com.osg.truebase.data.user.UserLocation
import com.osg.ui.core.imageProcces.compressJpeg
import com.osg.utils.ResourceState
import com.osg.utils.pmap
import com.osg.utils.time.TimeService.getUtcTime
import com.osg.utils.toResourceSucces
import com.osg.utils.waitForSuccess
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.*
import org.koin.core.component.KoinScopeComponent
import org.koin.core.component.getScopeName
import org.koin.core.module.Module
import org.koin.core.module.dsl.scopedOf
import org.koin.core.scope.Scope
import org.koin.dsl.module
import org.koin.mp.KoinPlatform.getKoin
import osg.uiZone.common.baseRepos.handlers.LocationService
import osg.uiZone.matchZone.model.EditImageType
import osg.uiZone.matchZone.model.LocalProfileImage
import osg.uiZone.matchZone.model.NewImage
import osg.uiZone.profile.IEditProfileDependencies
import osg.uiZone.profile.newProfileImage
import osg.uiZone.register.model.ImagePickDependencies

interface IMyProfileDependencies :
    IMyFullProfileFlow,
    ISaveMyProfile,
    ISaveMyFullProfile,
    IProfileImagesStateFlow,
    ISaveMyImages,
    IEditProfileDependencies,
    ImagePickDependencies,
    IUserLocationStateFlow,
    IPresence,
    ILoggedUid,
    IUidStatus,
    IUpdateUserMap


val databaseService: DatabaseService by lazy {
    getKoin().get()
}

val profileRepoModule: Module = module {
    scope<MyProfileScope> {
        scopedOf(::MyProfileHandler)
    }
}

class MyProfileScope : KoinScopeComponent {
    override val scope: Scope = getKoin().getOrCreateScope(this::class.simpleName!!, getScopeName())
    val profileRepository: MyProfileHandler by scope.inject()

    companion object {
        val myProfileHandler: MyProfileHandler
            get() = MyProfileScope().profileRepository

        fun closeMyProfileScope(){
            getKoin().getScopeOrNull(MyProfileScope::class.simpleName!!)?.let {
                val myProfileHandler: MyProfileHandler by it.inject()
                myProfileHandler.close()
                it.close()
            }
        }
    }
}


class MyProfileHandler(
    private val databaseService: DatabaseService,
    private val authService: AuthService,
    private val platformStorage: IPlatformStorage
) : IMyProfileDependencies {
    val coroutineScope: CoroutineScope = CoroutineScope(Dispatchers.Default)


    override val myProfileStateFlow: StateFlow<ResourceState<FullProfile>> = authService
        .loggedStateFlow
        .filterIsInstance<LoggedStates.Loggedin>()
        .transform {
            val uid = it.uid
            val path = DataRoot.fullProfilePath(uid)
            emitAll(
                databaseService.onValueChangeFlow<FullProfile>(path).map { profile ->
                    profile?.toResourceSucces() ?: ResourceState.Error(KmpDatabaseException.ValueNotFound(path))
                }
            )
        }.stateIn(
            scope = coroutineScope,
            started = SharingStarted.Lazily,
            initialValue = ResourceState.Loading
        )

    override val currentLocationState: StateFlow<ResourceState<UserLocation>> = LocationService()
        .currentLocationState.map {
            ResourceState.Success(it)
        }
        .stateIn(
            scope = coroutineScope,
            started = SharingStarted.Lazily,
            initialValue = ResourceState.Loading
        )

    private val myImagesFlow = authService.loggedStateFlow.filterIsInstance<LoggedStates.Loggedin>().transform {
        val uid = authService.getUid()!!
        val path = DataRoot.profileImagesPath(uid)
        emitAll(databaseService.onValueChangeFlow<List<RemoteProfileImage>>(path))
    }

    override val myLocalImagesFlow: StateFlow<List<LocalProfileImage>?> = myImagesFlow.map {
        AppLogger.d("got images $it")
        it?.pmap { remoteImage ->
            platformStorage.toLocalImage(remoteImage)
        } ?: emptyList()
    }.stateIn(
        scope = coroutineScope,
        started = SharingStarted.Lazily,
        initialValue = null
    )

    override suspend fun saveMyProfile(profile: PublicProfile) {
        databaseService.setNode(
            DataRoot.publicProfilePath(profile.uid),
            profile
        )
    }

    override suspend fun saveMyFullProfile(profile: FullProfile) {
        databaseService.setNode(
            DataRoot.fullProfilePath(profile.publicProfile.uid),
            profile
        )
    }

    override suspend fun saveMyImages(images: List<EditImageType>) {
        val uid = authService.getUid()!!
        val localIms = images.pmap {
            when (it ) {
                is LocalProfileImage -> {
                    RemoteProfileImage(
                        mediaPath = it.mediaPath,
                        comment = it.comment,
                    )
                }
                is NewImage -> {
                    val ti = it.imageBytes.bytes.compressJpeg(90)
                    newProfileImage(
                        uid = uid,
                        fileType = "jpeg"
                    ).also { newImage ->
                        platformStorage.uploadMedia(newImage, ti)
                    }
                }
            }
        }

        val newProfile = myProfileStateFlow.waitForSuccess().publicProfile.copy(
            picturesCommentList = localIms
        )

        saveMyProfile(newProfile)

    }

    override fun getUid(): String? = authService.getUid()

    override suspend fun onLogin() {
        setPresence(true)
        databaseService.setFalseOnDisconnect(
            path = DataRoot.isOnlinePath(onUid()),
        )
    }

    override suspend fun onDisconnect() {
        setPresence(false)
    }

    override suspend fun onUid(): String =
        authService.loggedStateFlow.filterIsInstance<LoggedStates.Loggedin>().first().uid

    private suspend fun presencePath() = DataRoot.presencePath(onUid())
    suspend fun setPresence(online: Boolean) {
        databaseService.setNode(presencePath(), Presence(online, getUtcTime()))
    }

    override suspend fun updateUserMap(technicalProfile: TechnicalProfile) {
        val path = userMapPath(onUid(), technicalProfile.gender)
        databaseService.setNode(path, technicalProfile)
    }

    fun close() {
        coroutineScope.coroutineContext.cancel()
    }


}


