package osg.uiZone.common.vmRepo.matchZone

import com.osg.appData.candidate.CandidateNode
import com.osg.appData.candidate.CandidateProfile
import com.osg.appData.common.DataRoot
import com.osg.appData.common.DataRoot.Companion.seenPromoMatchPaths
import com.osg.appData.functions.ITmNotificationAccountFunctions
import com.osg.appData.matchZone.EntertainmentStore
import com.osg.appData.matchZone.EntertainmentStore.Companion.entDataPath
import com.osg.appData.matchZone.EntertainmentStore.Companion.entStorePath
import com.osg.appData.matchZone.MatchInfo
import com.osg.appData.matchZone.MatchZoneNode
import com.osg.appData.matchZone.SparkNodeData
import com.osg.appData.matchZone.ice.IcebreakerCollection
import com.osg.appData.matchZone.invitation.EntertainmentType
import com.osg.appData.matchZone.invitation.Invitation
import com.osg.appData.matchZone.invitation.InvitationContent
import com.osg.appData.matchZone.invitation.toInvitation
import com.osg.appData.profile.Presence
import com.osg.coroutines.dispechers.ioDispatcher
import com.osg.def.database.DatabaseService
import com.osg.def.database.IDownloadMedia
import com.osg.def.database.ISetNode
import com.osg.ice.CaDependencies
import com.osg.ice.HtDependencies
import com.osg.ice.IIceBreakerDependencies
import com.osg.truebase.data.iceDef.SharedIcebreakerCollection
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.nodeData.TransactionTarget
import com.osg.truebase.data.user.PlayerDataEssential
import com.osg.truebase.ui.commonStates.MainViewUiState
import com.osg.truebase.ui.commonStates.toSuccess
import com.osg.utils.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.*
import org.koin.core.component.KoinScopeComponent
import org.koin.core.component.getScopeName
import org.koin.core.module.Module
import org.koin.core.scope.Scope
import org.koin.dsl.module
import org.koin.mp.KoinPlatform.getKoin
import osg.uiZone.common.baseRepos.database.*
import osg.uiZone.common.baseRepos.handlers.ChatHandler
import osg.uiZone.common.baseRepos.handlers.Conversation
import osg.uiZone.common.baseRepos.handlers.IChatHandler
import osg.uiZone.common.baseRepos.handlers.chatRepo
import osg.uiZone.common.baseRepos.profile.*
import osg.uiZone.common.baseRepos.profile.MyProfileScope.Companion.myProfileHandler
import osg.uiZone.common.vmRepo.entertainment.prepareEntertainmentCollections
import osg.uiZone.matchZone.TopBarState
import osg.uiZone.matchZone.entertainment.TMEntertainmentDataUiBase
import osg.uiZone.matchZone.entertainment.toEntertainmentDataBaseUi
import osg.uiZone.matchZone.model.*
import osg.uiZone.matchZone.state.EntFeedData
import osg.uiZone.matchZone.state.tmEntUi.NewEntertainmentUiData
import osg.uiZone.matchZone.state.tmEntUi.baseContent
import osg.uiZone.matchZone.state.tmEntUi.baseContentList
import osg.uiZone.matchZone.state.tmEntUi.toEntertainmentDataUi
import osg.uiZone.search.CandidateProfileUi
import osg.uiZone.search.screens.MatchImages
import osg.uiZone.search.screens.PromoUiStates

interface IMatchZoneChatRepo: ILoggedUid{
    fun conversationFlow(): Flow<Conversation>
    suspend fun sendMessage(message: String)
}

val matchRepoModule: Module = module {
    scope<MatchScope>{
        scoped<MatchZoneRepoImpl>{
            MatchZoneRepoImpl(
                notificationAccountFunctions = get(),
                databaseDep = MatchZoneDatabaseDep(get()),
                mediaRepoDep = get()
            )
        }
    }
}

class MatchScope: KoinScopeComponent{
    override val scope: Scope = getKoin().getOrCreateScope("MatchScope", getScopeName())
    val matchZoneRepository: MatchZoneRepoImpl by scope.inject()

    companion object{
        fun closeMatchScope(){
            getKoin().getScopeOrNull("MatchScope")?.let {
                val matchZonehRepository: MatchZoneRepoImpl by it.inject()
                matchZonehRepository.close()
                it.close()
            }
        }
    }
}


interface IMatchZoneDatabaseDep:
    IMyFullProfileFlow,
    IProfileImagesStateFlow,
    IPublicProfileFlow,
    IInvitationTracker,
    ISetNode,
    ILoggedUid,
    ISparkNodeData,
    IFetchEntStore,
    IMatchInfoFlow

class MatchZoneDatabaseDep(
    databaseService: DatabaseService
): IMatchZoneDatabaseDep,
    IMyProfileDependencies by myProfileHandler,
    IPublicProfileFlow by databaseService.publicProfileFlow,
    IInvitationTracker by databaseService.invitationTracker,
    ISetNode by databaseService,
    ISparkNodeData by databaseService.sparkDataNode,
    IFetchEntStore by databaseService.entStoreFetcher,
    IMatchInfoFlow by databaseService.matchInfoFlow



interface IMatchZoneRepo :
    IMatchZoneChatRepo,
    ISharedJourneyRepo,
    IMatchZonePromoRepo,
    IEntertainmentDetailsRepo,
    IEntertainmentFeedRepo,
    IMatchZoneBarDependencies,
    IceBreakerSetupDependencies


class MatchZoneRepoImpl(
    notificationAccountFunctions: ITmNotificationAccountFunctions,
    databaseDep: IMatchZoneDatabaseDep,
    private val mediaRepoDep: IDownloadMedia,
) : IMatchZoneDatabaseDep by databaseDep,
    IMatchZoneRepo {
    init {
        AppLogger.d("MatchZoneRepoImpl Created")
    }

    private val coroutineScope: CoroutineScope = CoroutineScope(ioDispatcher())
    private val chatHandler: IChatHandler = ChatHandler(databaseService.chatRepo, notificationAccountFunctions)

    private val candidatePublicProfileFlow = dependencyFlow {
        val candidateUid = myProfileStateFlow.waitForSuccess().matchZoneData!!.candidateUid
        publicProfileFlow(candidateUid)
    }


    private val matchInfoStateFlow = myProfileStateFlow.filterToData().startWithFirst { profile ->
        val matchInfoPath = profile.matchZoneData!!.computeMatchInfoPath()
        getMatchInfoFlow(matchInfoPath)
    }.stateIn(
        coroutineScope,
        SharingStarted.Lazily,
        null
    )

    override val invitationFlow: StateFlow<List<Invitation>> = myProfileStateFlow.filterToData().startWithFirst { profile ->
        val invitationPath = profile.matchZoneData!!.computeInvitationPath()
        getLatestInvitationFlow(
            path = invitationPath
        )
    }.stateIn(
        coroutineScope,
        SharingStarted.Lazily,
        emptyList()
    )

    private val matchZoneProfilesStateFlow = combine(
        myProfileStateFlow.filterToData(),
        candidatePublicProfileFlow.filterNotNull()
    ) { myProfile, candidateProfile ->
        MatchZoneProfiles(
            mediaRepoDep.fetchProfileUi(myProfile.publicProfile),
            mediaRepoDep.fetchProfileUi(candidateProfile)
        )
    }.stateIn(
        coroutineScope,
        SharingStarted.Lazily,
        null
    )

    private val matchPresenceFlow = myProfileStateFlow.filterToData().startWithFirst { profile ->
        chatHandler.presenceFlow(profile.matchZoneData!!.candidateUid)
    }.stateIn(
        coroutineScope,
        SharingStarted.Lazily,
        Presence()
    )

    private suspend fun fetchAllEntertainmentsType(
        type: EntertainmentType,
    ): List<TMEntertainmentDataUiBase> {
        return when (type) {
            EntertainmentType.GAME -> IcebreakerCollection.baseContentList

            EntertainmentType.SPARK -> fetchSparksData()
        }
    }

    override fun getMatchInfoFlow(): Flow<MatchInfo> = matchInfoStateFlow.filterNotNull()

    override fun getMatchZoneProfilesFlow(): Flow<MatchZoneProfiles> = matchZoneProfilesStateFlow.filterNotNull()


    override fun conversationFlow(): Flow<Conversation> = myProfileStateFlow.filterToData().startWithFirst {
        val chatPath = it.matchZoneData!!.computeChatPath()
        chatHandler.conversationFlow(chatPath)
    }

    override suspend fun sendMessage(message: String) {
        val profile = myProfileStateFlow.waitForSuccess()
        val chatPath = profile.matchZoneData!!.computeChatPath()
        chatHandler.sendMessage(
            chatPath,
            message,
            profile.publicProfile.uid,
            profile.matchZoneData!!.candidateUid,
            matchPresenceFlow.value
        )
    }

    fun matchImagesFlow(): Flow<MatchImages> = matchZoneProfilesStateFlow.filterNotNull().map {
        MatchImages(
            myProfileImage = it.myProfile.picturesCommentList.first().trueImage,
            candidateImage = it.candidateProfile.picturesCommentList.first().trueImage
        )
    }

    override fun promoDataFlow(): Flow<PromoUiStates.Ready> =
        combine(matchImagesFlow(), myProfileStateFlow.filterToData()) { matchImages, profile ->
            PromoUiStates.Ready(
                superLike = profile.matchZoneData!!.superLiked,
                candidateImage = matchImages.candidateImage,
                myProfileImage = matchImages.myProfileImage
            )
        }


    override suspend fun setWatchedPromo() {
        val seenPromoPath = seenPromoMatchPaths(
            myProfileStateFlow.waitForSuccess().publicProfile.uid
        )
        setBooleanNode(seenPromoPath, true)
    }


    override fun entFeedFlow(type: EntertainmentType): Flow<EntFeedData> = dependencyFlow {
        val entertainments = fetchAllEntertainmentsType(
            type = type,
        )
        combine(
            getMatchInfoFlow(),
            invitationFlow
        ) { matchInfo, invitations ->
            prepareEntertainmentCollections(
                entertainments = entertainments,
                matchInfo = matchInfo,
                activeInvitations = invitations
            )
        }
    }


    private suspend fun fetchSparksData(): List<TMEntertainmentDataUiBase> {
        val sparks = fetchEntStore(entStorePath).sparks
        return sparks.values.pmap { ent ->
            ent.toEntertainmentDataBaseUi(
                trueImage = mediaRepoDep.toLoadingImage(ent.image)
            )
        }
    }

    private fun TMEntertainmentDataUiBase.resolveGamesUiData(
        matchInfo: MatchInfo,
    ): NewEntertainmentUiData {
        return toEntertainmentDataUi(
            matchInfo = matchInfo,
        )
    }


    override suspend fun fetchEntertainment(
        id: String,
        type: EntertainmentType
    ): NewEntertainmentUiData {
        val matchInfo = getMatchInfoFlow().first()
        return when (type) {
            EntertainmentType.GAME -> {
                val iceCollect = IcebreakerCollection.valueOf(id)
                iceCollect.baseContent.resolveGamesUiData(matchInfo)
            }

            EntertainmentType.SPARK -> {
                val ent = fetchSparkUi(entDataPath(id))
                ent.toEntertainmentDataBaseUi(
                    trueImage = mediaRepoDep.toLoadingImage(ent.image)
                ).toEntertainmentDataUi(
                    matchInfo = matchInfo,
                )
            }
        }
    }


    private fun getLatestInvitationFlow(
        path: String
    ): Flow<List<Invitation>> {
        var invitations = emptyList<Invitation>()
        return databaseService.getInvitationFlow(path).transform { lastInvitations ->
            invitations = filterLatestInvitations(invitations + lastInvitations)
            emit(invitations)
        }
    }

    private fun filterLatestInvitations(
        invitation: List<Invitation>,
    ): List<Invitation> {
        val invitationById = invitation.groupBy { it.content }
        return invitationById.map { idGroup -> idGroup.value.maxByOrNull { it.sentTimestamp }!! }
    }

    override suspend fun onInvitationResponse(response: InvitationContent) {
        val profile = myProfileStateFlow.waitForSuccess()
        val invitation = response.toInvitation(profile.publicProfile.uid)
        val invitationPath = MatchZoneNode.resolveInvitationPath(
            profile.matchZoneData!!.matchZoneNodePath
        )
        databaseService.push(invitationPath, invitation)
    }

    override suspend fun fetchIceBreakerDependencies(invitation: Invitation): IIceBreakerDependencies {
        val profile = myProfileStateFlow.waitForSuccess()
        val gameLiveDataPath = MatchZoneNode.resolveIceBreakersPath(
            matchZoneNodePath = profile.matchZoneData!!.matchZoneNodePath,
            gameId = invitation.content
        )

        val myPlayerProfile = PlayerDataEssential(
            uid = profile.publicProfile.uid,
            name = profile.publicProfile.firstName,
            profileImage = profile.publicProfile.picturesCommentList.first()
        )
        val candidateProfile = candidatePublicProfileFlow.firstNotNull()
        val candidatePlayerProfile = PlayerDataEssential(
            uid = candidateProfile.uid,
            name = candidateProfile.firstName,
            profileImage = candidateProfile.picturesCommentList.first()
        )
        val seenRoundsIds = listOf("blah") //TODO
        val rounds = 4 //TODO
        val sharedIcebreakerCollection = SharedIcebreakerCollection.valueOf(invitation.content)
        when (sharedIcebreakerCollection) {
            SharedIcebreakerCollection.ChallengeAccepted -> {
                return CaDependencies(
                    gameRootPath = gameLiveDataPath,
                    rounds = rounds,
                    inviterUid = invitation.senderUid,
                    myPlayerProfile = myPlayerProfile,
                    partnerPlayerProfile = candidatePlayerProfile,
                    seenRoundsIds = seenRoundsIds
                )
            }

            SharedIcebreakerCollection.HarmonyTunes -> {
                return HtDependencies(
                    gameRootPath = gameLiveDataPath,
                    rounds = rounds,
                    genres = emptyList(),
                    inviterUid = invitation.senderUid,
                    myPlayerProfile = myPlayerProfile,
                    partnerPlayerProfile = candidatePlayerProfile,
                    seenRoundsIds = seenRoundsIds
                )
            }
        }

    }

    override suspend fun updateBalance(value: Double) {
        val path = myProfileStateFlow.waitForSuccess().matchZoneData!!.computeMatchInfoPath()
        databaseService.transactionBalance(path){ res ->
            if (res == null) {
                TransactionTarget(
                    success = false,
                    null
                )
            } else {
                TransactionTarget(
                    success = true,
                    res.copy(
                        balance = res.balance.copy(
                            raw =  res.balance.raw + value,
                        )
                    )
                )
            }
        }
    }

    override suspend fun fetchSparkUi(path: String): SparkNodeData {
        return databaseService.fetchObject(path)
    }


    override suspend fun fetchEntStore(path: String): EntertainmentStore {
        return databaseService.fetchObject(path)
    }

    val candidateNodeStateFlow: StateFlow<CandidateNode?> = flow {
        val myProfile = myProfileStateFlow.waitForSuccess()
        val candidateUid = myProfile.matchZoneData!!.candidateUid
        val candidateNode = databaseService.fetchCandidateNode(myProfile.publicProfile.uid, candidateUid)
        emit(candidateNode)
    }.onCompletion { cause ->
        AppLogger.d("CandidateRepo", "CandidateRepo Completed $cause (check if scpoe is closed)")
    }.stateIn(
        coroutineScope,
        SharingStarted.Lazily,
        null
    )

    override val candidateProfileUiFlow: Flow<CandidateProfileUi> = combine(
        candidateNodeStateFlow.filterNotNull(),
        candidatePublicProfileFlow.filterNotNull()
    ){ candidateNode, candidateProfile ->
        val candidate = CandidateProfile(
            publicProfile = candidateProfile,
            matchIncentive = candidateNode.matchIncentive,
            decision = candidateNode.decision
        )
        CandidateProfileUi(
            candidateProfile = candidate,
            images = candidateProfile.picturesCommentList.pmap {
                mediaRepoDep.toLoadingImage(it)
            }
        )
    }

    val barOnlineData: StateFlow<MainViewUiState<TopBarState>> = combine(
        candidateProfileUiFlow,
        myLocalImagesFlow,
        invitationFlow
    ){ candidateProfile, localImages, invitationsUiState ->
        AppLogger.d("MatchZoneBarModel", "TopBarState: success")
        if(localImages == null){
            MainViewUiState.Loading
        }else{
            TopBarState(
                myProfileImage = localImages.first().trueImage,
                candidateProfileUi = candidateProfile,
                activeInvitations = invitationsUiState
            ).toSuccess()
        }
    }.stateIn(
        scope = coroutineScope,
        started = SharingStarted.Lazily,
        initialValue = MainViewUiState.Loading
    )

    fun close(){
        coroutineScope.coroutineContext.cancel()
    }
}

suspend fun DatabaseService.transactionBalance(
    path: String,
    transactionOperation: (MatchInfo?) -> TransactionTarget<MatchInfo>
) {
    transaction(path, transactionOperation)
}

fun DatabaseService.getInvitationFlow(path: String): Flow<Invitation> {
    return getChildFlow<Invitation>(path).map { it.data }
}

fun DatabaseService.getMatchInfoFlow(path: String): Flow<MatchInfo> {
    return onValueChangeFlow<MatchInfo>(path).filterNotNull()
}

suspend fun DatabaseService.fetchCandidateNode(requesterUid: String, candidateUid: String): CandidateNode {
    val path = DataRoot.userCandidateArchivePath(requesterUid, candidateUid)
    return fetchObject(path)
}