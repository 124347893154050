package osg.uiZone.di

import com.osg.appData.candidate.MatchRequestData
import com.osg.appData.candidate.UnMatchRequest
import com.osg.appData.common.NotificationData
import com.osg.appData.functions.*
import com.osg.appData.ml.LlmUnmatchChatRequest
import com.osg.truebase.data.nodeData.toHexString
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject


class FunctionsService():
    ITmCloudFunctions,
    KoinComponent {
    private val functionService: ICloudFunctionsEngine by inject()
    override suspend fun findMeMatchRequest(uid: String): FunctionResponseType {
        return functionService.callFirebaseFunction(
            ITmCloudFunctions::findMeMatchRequest.name,
        )
    }

    override suspend fun generateLlmResponse(llmUnmatchChatRequest: LlmUnmatchChatRequest): FunctionResponseType {
        return functionService.callFirebaseFunction(
            ITmCloudFunctions::generateLlmResponse.name,
            data = llmUnmatchChatRequest.toHexString()
        )
    }

    override suspend fun match(matchRequestData: MatchRequestData): FunctionResponseType {
        return functionService.callFirebaseFunction(
            ITmCloudFunctions::match.name,
            data = matchRequestData.toHexString()
        )
    }

    override suspend fun unMatch(unMatchRequest: UnMatchRequest): FunctionResponseType {
        return functionService.callFirebaseFunction(
            ITmCloudFunctions::unMatch.name,
            data = unMatchRequest.toHexString()
        )
    }

    override suspend fun sendNote(
        notificationData: NotificationData
    ): FunctionResponseType {
        return functionService.callFirebaseFunction(
            ITmNotificationAccountFunctions::sendNote.name,
            notificationData.toHexString()
        )
    }

    override suspend fun removeAccount(uid: String): FunctionResponseType {
        return functionService.callFirebaseFunction(
            ITmAcountRemovalCloudFunctions::removeAccount.name,
        )
    }
}