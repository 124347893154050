package com.osg.matchmaker.ml.ds

import com.osg.appData.candidate.Decision
import com.osg.appData.profile.FullProfile

data class CandidateDecisionData(
    val fullProfile: FullProfile,
    val decision: Decision = Decision.UNKNOWN,
    val firstSightDuration: Int = 0,
)
