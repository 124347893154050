package com.osg.ice.harmonyTunes.model

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.osg.ice.*
import com.osg.ice.challengeAccepted.ds.dataUi
import com.osg.ice.harmonyTunes.ds.HtRoundStatus
import com.osg.ice.harmonyTunes.ui.doubleSideLetter.trimAnswer
import com.osg.ice.state.IceMatchResult
import com.osg.ice.ui.ds.EntertainmentDataUi
import com.osg.truebase.data.iceDef.*
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.nodeData.IRemoteMediaResource
import com.osg.truebase.player.audio.IPlatformAudioPlayer
import com.osg.truebase.player.audio.PlayerAction
import com.osg.truebase.player.audio.TrueAudio
import com.osg.truebase.player.audio.TrueAudioPlayer
import com.osg.truebase.ui.commonStates.MainViewUiState
import com.osg.truebase.ui.commonStates.toSuccess
import com.osg.utils.dependencyFlow
import com.osg.utils.pmap
import com.osg.utils.time.TimeService.getUtcTime
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch

sealed interface RoundIceEventState {

    data object NoAnswer : RoundIceEventState

    data class RightAnswer(
        val hints: Int,
        val data: String,
        val isMyAnswer: Boolean,
        val score: Int
    ) : RoundIceEventState

    data class WrongAnswer(
        val data: String
    ) : RoundIceEventState
}

private data class RoundEvents(
    val round: Int,
    val myEvent: HtLiveEvent? = null,
    val opEvent: HtLiveEvent? = null
)

data class UserRoundState(
    val round: Int = -1,
    val hints: Int = 1,
    val answer: String = ""
)

// internal states: 1. initialDataLoading 2. userInput(playerAction, roundIdx) 3. live event
class HarmonyTunesModel(
    private val iceBreakerDependencies: HtDependencies,
    private val htRepo: IceRepository,
    iceDataStorage: IceMultiMediaDownloader,
    platformAudioPlayer: IPlatformAudioPlayer
) : ViewModel(), IceBreakerModel {
    private val iceStorageHandler = IceDataStorageHandler(iceDataStorage)
    private val audioPlayer = TrueAudioPlayer(platformAudioPlayer)
    private val clueSize = 5000L
    private val userRoundStateFlow = MutableStateFlow(UserRoundState())

    private val iceContentFlow = MutableStateFlow<HtContent?>(null)
    private val iceMatchResultsState = emptyMap<Int, RoundIceEventState.RightAnswer>().toMutableMap()

    private val htRoundStatusFlow = dependencyFlow {
        var roundEvents = emptyList<HtLiveEvent>()
        htRepo.getLiveDataFlow(iceBreakerDependencies.eventsPath).map { liveEvent ->
            roundEvents = roundEvents + liveEvent as HtLiveEvent
            roundEvents = roundEvents.filter { it.round == liveEvent.round }
            RoundEvents(
                round = liveEvent.round,
                myEvent = roundEvents.firstOrNull { it.uid == iceBreakerDependencies.myPlayerProfile.uid },
                opEvent = roundEvents.firstOrNull { it.uid != iceBreakerDependencies.myPlayerProfile.uid }
            )
        }
    }.stateIn(
        scope = viewModelScope,
        started = SharingStarted.WhileSubscribed(),
        initialValue = RoundEvents(
            round = -1
        )
    )

    fun resolveRoundAnswerState(
        currentRound: Int,
        htLiveEvent: HtLiveEvent?,
        correctAnswer: String,
        isMyAnswer: Boolean,
    ): RoundIceEventState {
        if (htLiveEvent == null || htLiveEvent.round < currentRound) {
            return RoundIceEventState.NoAnswer
        }
        return if (isCorrectAnswer(htLiveEvent.data, correctAnswer)) {
            RoundIceEventState.RightAnswer(
                hints = htLiveEvent.hints,
                data = htLiveEvent.data,
                isMyAnswer = isMyAnswer,
                score = calcRoundPoints(
                    questionValue = iceContentFlow.value!!.roundsData[currentRound].difficulty,
                    hints = htLiveEvent.hints
                )
            )
        } else {
            RoundIceEventState.WrongAnswer(
                data = htLiveEvent.data
            )
        }
    }

    fun calcRoundPoints(
        questionValue: Int,
        hints: Int
    ): Int {
        return maxOf(0, questionValue - hints)
    }

    fun calcIceMatchResult(): IceMatchResult {
        return IceMatchResult(
            myScore = iceMatchResultsState.values.filter { it.isMyAnswer }.sumOf {
                it.score
            },
            opponentScore = iceMatchResultsState.values.filter { it.isMyAnswer.not() }.sumOf {
                it.score
            }
        )
    }




    val uiState: StateFlow<MainViewUiState<HtRoundStatus>> = combine(
        iceContentFlow.filterNotNull(),
        audioPlayer.playerState,
        htRoundStatusFlow,
        userRoundStateFlow
    ) { iceContent, playerState, htRoundStatus, userRound ->
        AppLogger.d("HarmonyTunesModel audioPlayer.playerState: $playerState, htRoundStatus: $htRoundStatus, userRound: $userRound")
        if (userRound.round == -1) {
            return@combine MainViewUiState.Loading
        }
        val roundData = iceContent.roundsData
        val currentAnswer = iceContent.roundsData[userRound.round].correctAnswer
        val userInput = resolveRoundAnswerState(
            currentRound = userRound.round,
            htLiveEvent = htRoundStatus.myEvent,
            correctAnswer = currentAnswer,
            isMyAnswer = true
        )
        val opAnswer = resolveRoundAnswerState(
            currentRound = userRound.round,
            htLiveEvent = htRoundStatus.opEvent,
            correctAnswer = currentAnswer,
            isMyAnswer = false
        )
         when (userInput) {
            is RoundIceEventState.RightAnswer -> {
                iceMatchResultsState[htRoundStatus.round] = userInput
                HtRoundStatus.IRoundResult.WinRound(
                    isIWroteTheAnswer = true,
                    matchResult = calcIceMatchResult(),
                    totalRounds = roundData.size,
                    correctAnswer =  currentAnswer,
                    currentRound = userRound.round
                )
            }

            is RoundIceEventState.NoAnswer if (opAnswer is RoundIceEventState.RightAnswer) -> {
                iceMatchResultsState[htRoundStatus.round] = opAnswer
                HtRoundStatus.IRoundResult.WinRound(
                    isIWroteTheAnswer = false,
                    matchResult = calcIceMatchResult(),
                    totalRounds = iceContent.roundsData.size,
                    currentRound = userRound.round,
                    correctAnswer = currentAnswer
                )
            }

            is RoundIceEventState.WrongAnswer if (opAnswer is RoundIceEventState.WrongAnswer) -> {
                HtRoundStatus.IRoundResult.LooseRound(
                    totalRounds = iceContent.roundsData.size,
                    matchResult = calcIceMatchResult(),
                    correctAnswer =  currentAnswer,
                    currentRound = userRound.round
                )
            }

            is RoundIceEventState.WrongAnswer -> {
                HtRoundStatus.WaitingForOpponentAnswer(
                    correctAnswer =  currentAnswer,
                )
            }

            is RoundIceEventState.NoAnswer if (opAnswer is RoundIceEventState.WrongAnswer) -> {
                HtRoundStatus.WaitingForYourAnswer(
                    playerState = playerState,
                    otherOpWrongAnswer = opAnswer.data,
                    hintsUsed = userRound.hints,
                    currentRound = userRound.round,
                    correctAnswer =  currentAnswer,
                )
            }

            is RoundIceEventState.NoAnswer -> {
                HtRoundStatus.WaitingForYourAnswer(
                    playerState = playerState,
                    hintsUsed = userRound.hints,
                    currentRound = userRound.round,
                    correctAnswer =  currentAnswer,
                )
            }
        }.toSuccess()
    }.stateIn(
        scope = viewModelScope,
        started = SharingStarted.WhileSubscribed(),
        initialValue = MainViewUiState.Loading
    )

    init {
        AppLogger.d(this::class.simpleName, "HarmonyTunesModel init")
    }

    fun loadRound(roundIdx: Int) {
        userRoundStateFlow.value = UserRoundState(
            round = roundIdx
        )
        viewModelScope.launch {
            val roundData = iceContentFlow.filterNotNull().first().roundsData
            audioPlayer.setTrack(roundData[roundIdx].bitSound.mediaPath)
            viewModelScope.launch {
                replayWithHints(1)
            }
        }
    }

    fun onUserAnswer(
        userAnswer: UserRoundState
    ) {
        val liveEvent = HtLiveEvent(
            uid = iceBreakerDependencies.myPlayerProfile.uid,
            round = userAnswer.round,
            data = userAnswer.answer,
            timestamp = getUtcTime(),
            hints = userAnswer.hints
        )
        viewModelScope.launch {
            htRepo.pushLiveData(iceBreakerDependencies.eventsPath, liveEvent)
        }

    }

    fun playWinSound(roundIdx: Int) {
        viewModelScope.launch {
            audioPlayer.setTrack(iceContentFlow.value!!.roundsData[roundIdx].winSound.mediaPath)
            audioPlayer.play()
        }
    }

    override fun onCleared() {
        super.onCleared()
        audioPlayer.release()
        AppLogger.d(this::class.simpleName, "audioPlayer.stop an release")
    }

    suspend fun prepareIce(
        playerSettings: PlayerSettings
    ) {
        htRepo.pushPreferences(
            iceBreakerDependencies.settingsPath,
            IceContentPreferences(
                seenRoundsIds = iceBreakerDependencies.seenRoundsIds,
                uid = iceBreakerDependencies.myPlayerProfile.uid
            )
        )
        val unifiedPreferences = htRepo
            .getPreferencesFlow(iceBreakerDependencies.settingsPath)
            .take(2).toList()

        val iceContentQuery = HtQuery(
            seenRoundsIds = unifiedPreferences.map { it.seenRoundsIds }.flatten(),
            language = playerSettings.languageSupport, // 1 question for each player = round
            totalRounds = iceBreakerDependencies.rounds,
            genres = iceBreakerDependencies.genres
        )

        val iceContent = iceStorageHandler.fetchIceContent(iceContentQuery) as HtContent
        val audioList = iceContent.roundsData.pmap { roundData ->
            listOf(
                iceStorageHandler.asTrueAudio(roundData.bitSound),
                iceStorageHandler.asTrueAudio(roundData.winSound)
            )
        }.flatten()

        audioPlayer.initialize(audioList)
        iceContentFlow.value = iceContent
    }


    private fun isCorrectAnswer(
        answer: String,
        correctAnswer: String
    ): Boolean {
        val userAnswer = trimAnswer(answer)
        val correctAnswer = trimAnswer(correctAnswer)
        return userAnswer == correctAnswer

    }


    fun onHintAndPlay() {
        val hints = userRoundStateFlow.value.hints + 1
        userRoundStateFlow.value = userRoundStateFlow.value.copy(
            hints = hints
        )
        viewModelScope.launch {
            replayWithHints(hints)
        }
        AppLogger.d(this::class.simpleName, "cluesGiven: $hints")
    }

    private suspend fun replayWithHints(
        hints: Int,
    ) {
        audioPlayer.seekTo(0)
        audioPlayer.setBoundary(hints * clueSize)
        audioPlayer.play()
    }

    fun action(action: PlayerAction) {
        AppLogger.d(this::class.simpleName, "action: $action")
        viewModelScope.launch {
            when (action) {
                PlayerAction.Play -> {
                    audioPlayer.play()
                }

                PlayerAction.Pause -> {
                    audioPlayer.pause()
                }

                PlayerAction.Repeat -> {
                    replayWithHints(userRoundStateFlow.value.hints)
                }
            }
        }
    }

    override val uiData: EntertainmentDataUi = SharedIcebreakerCollection.HarmonyTunes.dataUi
    override fun onStartGame(playerSettings: PlayerSettings){
        viewModelScope.launch {
            prepareIce(playerSettings)
            loadRound(0)
        }
    }
}

suspend fun IceMultiMediaDownloader.asTrueAudio(
    media: IRemoteMediaResource
): TrueAudio {
    return TrueAudio.Raw(
        byteArray = downloadMultimedia(media),
        mediaPath = media.mediaPath
    )
}