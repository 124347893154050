package com.osg.truebase.data.user 

enum class Gender{
    Male,
    Female,
}

fun Gender.not(): Gender{
    return when(this){
        Gender.Male -> Gender.Female
        Gender.Female -> Gender.Male
    }
}