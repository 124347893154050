package osg.uiZone.common.baseRepos

import com.osg.appData.network.IPlatformNetwork
import com.osg.appData.network.NetworkStatus
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.coroutines.flow.debounce
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

object NetworkService: KoinComponent {
    private val networkImpl: IPlatformNetwork by inject()

    @OptIn(FlowPreview::class)
    val connectivityFlow =  callbackFlow {
        networkImpl.observeConnectivity {
            trySend(it)
        }
        awaitClose {}
    }.debounce{
        when(it){
            NetworkStatus.Available -> 0L
            else -> 3000L
        }
    }
}