package osg.uiZone.matchZone.entertainment.game

import androidx.compose.runtime.Stable
import androidx.compose.ui.graphics.Color
import com.osg.appData.matchZone.Eligibility
import com.osg.appData.matchZone.invitation.EntertainmentType
import com.osg.truebase.data.resources.ILocalAnimations
import com.osg.truebase.ui.paint.PreGradientBackground
import org.jetbrains.compose.resources.StringResource
import osg.uiZone.matchZone.entertainment.EntertainmentProperties
import osg.uiZone.matchZone.entertainment.TMEntertainmentDataUiBase
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.game_detail_header
import truematch.uizone.generated.resources.game_feed_title
import truematch.uizone.generated.resources.game_ingredients_title
import uiZone.trueAutoResources.LocalAnimations

@Stable
object GameEnt : EntertainmentProperties() {
    override val loadingAnimation: ILocalAnimations = LocalAnimations.ANIMATION_ICE_LOADING

    override fun getType(): EntertainmentType {
        return EntertainmentType.GAME
    }

    override fun getFeedTitleResource(): StringResource {
        return Res.string.game_feed_title
    }

    override fun getIngredientTitleResource(): StringResource {
        return Res.string.game_ingredients_title
    }

    override fun getGradientColors(): List<Color> {
        return PreGradientBackground.Game.colors
    }

    override fun getDetailsTitleResource(): StringResource {
        return Res.string.game_detail_header
    }
}

fun TMEntertainmentDataUiBase.resolveEligibility(
    relationshipStage: Int,
): Eligibility {
    return if (relationshipStage < unlockStage) {
        Eligibility.STAGE_NOT_MATCHED
    } else {
        Eligibility.YES
    }
}