package com.osg.truebase.datastore

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.serialization.json.JsonElement
import kotlin.reflect.KProperty1

class DataStoreEngine(): IDataStoreEngine{
    private val jsStore = MutableStateFlow(emptyMap<String, JsonElement>())
    override val settingsFlow: Flow<Map<String, JsonElement>> = jsStore


    override suspend fun edit(
        property: KProperty1<*, *>,
        value: JsonElement
    ) {
        val prevMap = jsStore.value.toMutableMap()
        prevMap[property.name] = value
        jsStore.value = prevMap
    }
}