package osg.uiZone.common.baseRepos.handlers

import com.osg.appData.common.DataRoot
import com.osg.appData.common.NotificationData
import com.osg.appData.common.NotificationType
import com.osg.appData.common.NotificationUiData
import com.osg.appData.functions.ITmNotificationAccountFunctions
import com.osg.appData.matchZone.Message
import com.osg.appData.profile.Presence
import com.osg.truebase.data.logger.AppLogger
import com.osg.utils.time.TimeService.getUtcTime
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import osg.uiZone.common.baseRepos.database.IChatDatabaseDependencies


interface IChatHandler {
    fun presenceFlow(uid: String): Flow<Presence>
    fun conversationFlow(chatPath: String): Flow<Conversation>
    suspend fun sendMessage(
        chatPath: String,
        message: String,
        senderUid: String,
        toUid: String,
        presence: Presence
    )
}

class BasicChatHandler(
    private val chatRepository: IChatDatabaseDependencies,
) {
    fun conversationFlow(chatPath: String): Flow<Conversation> = flow {
        var messages = chatRepository.fetchChatHistory(chatPath)
        emit(
            Conversation(
                messages = messages,
            )
        )
        chatRepository.getChatFlow(
            chatPath,
            Message::timestamp.name,
            getUtcTime()
        ).collect {
            messages = messages + it
            emit(
                Conversation(
                    messages = messages,
                )
            )
        }
    }

    suspend fun sendMessage(
        chatPath: String,
        message: String,
        senderUid: String,
    ) {
        val timeStamp = getUtcTime()
        val messageStruct = Message(message, senderUid, timeStamp)
        chatRepository.sendMessage(chatPath, messageStruct)
        AppLogger.d("ChatRepository", "Message sent: $message")
    }
}


class ChatHandler(
    private val chatRepository: IChatHandlerDependencies,
    private val notificationRepo: ITmNotificationAccountFunctions,
):  IChatHandler{
    private val basicChatHandler = BasicChatHandler(chatRepository)

    override fun presenceFlow(uid: String): Flow<Presence>{
        val presencePath = DataRoot.presencePath(uid)
        return chatRepository.getPresenceFlow(presencePath)
    }

    override fun conversationFlow(chatPath: String): Flow<Conversation> = basicChatHandler.conversationFlow(chatPath)

    override suspend fun sendMessage(
        chatPath: String,
        message: String,
        senderUid: String,
        toUid: String,
        presence: Presence,
    ){
        basicChatHandler.sendMessage(chatPath, message, senderUid)
        if (presence.online.not()) {
            val notificationData = NotificationData(
                fromUid = senderUid,
                toUid = toUid,
                uiData = NotificationUiData(
                    NotificationType.MESSAGE
                )
            )
            notificationRepo.sendNote(
                notificationData
            )
            AppLogger.d("User is offline should send notification")
        }
    }
}


data class Conversation(
    val messages: List<Message>,
)