package com.osg.matchmaker.matchZone


import com.osg.appData.bots.BOT_UID_PREFIX
import com.osg.appData.common.MatchZonesRoot


const val INITIAL_BALANCE: Double = 20.0 // TODO probably should be dynamic with currency conversion

fun resolveMatchZonePath(requesterUid: String, matchUid: String): String {
    val isOneBot = listOf(requesterUid, matchUid).any { it.contains(BOT_UID_PREFIX) }
    val preFix = if (isOneBot){
        MatchZonesRoot.botMatchZonePath
    } else{
        MatchZonesRoot.userMatchZonePath
    }
    return "$preFix/${requesterUid}_$matchUid"
}