package com.osg.truebase.ui.components


import androidx.compose.foundation.layout.*
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import com.osg.truebase.ui.nativeViews.WebView
import com.osg.ui.core.resources.string
import com.osg.truebase.ui.ds.UrlStringRes

@OptIn(ExperimentalLayoutApi::class)
@Composable
fun LinksPanel(
    modifier: Modifier = Modifier,
    urlStringResList: List<UrlStringRes>
) {
    var uriState by remember { mutableStateOf("") }
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Bottom,
    ) {
        HorizontalDivider(modifier = Modifier.padding(vertical = 8.dp))
        FlowRow(
            horizontalArrangement = Arrangement.SpaceAround,
            modifier = Modifier,
        ) {

            urlStringResList.forEach { urlStringRes ->
                TmTextButton(
                    onClick = { uriState = urlStringRes.url },
                    content = {
                        Text(urlStringRes.uiText.string)
                    }
                )
            }
        }
    }
    if (uriState.isNotEmpty()) {
        Dialog(onDismissRequest = { uriState = "" }) {
            WebView(
                modifier = Modifier.fillMaxSize(),
                url=uriState
            )
        }
    }
}
/**
 * Now in Android text button with generic content slot. Wraps Material 3 [TextButton].
 *
 * @param onClick Will be called when the user clicks the button.
 * @param modifier Modifier to be applied to the button.
 * @param enabled Controls the enabled state of the button. When `false`, this button will not be
 * clickable and will appear disabled to accessibility services.
 * @param content The button content.
 */
@Composable
fun TmTextButton(
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    content: @Composable RowScope.() -> Unit,
) {
    TextButton(
        onClick = onClick,
        modifier = modifier,
        enabled = enabled,
        content = content,
    )
}