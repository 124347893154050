package osg.cross.theme

import androidx.compose.runtime.Immutable
import androidx.compose.runtime.compositionLocalOf
import androidx.compose.ui.text.intl.Locale


@Immutable
data class TmConfig(
    val local: Locale = Locale("en-US")
){
    companion object {
        val US = TmConfig(Locale("en-US"))
    }
}


val LocalTmConfig = compositionLocalOf { TmConfig() }