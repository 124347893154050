package osg.uiZone.matchZone.state.tmEntUi

import com.osg.appData.matchZone.invitation.EntertainmentType
import com.osg.appData.matchZone.invitation.Invitation
import kotlinx.serialization.Serializable

@Serializable
sealed interface EntertainmentDetailsUiType {
    val contentId: String
    val type: EntertainmentType

    @Serializable
    data class ExistingInvitation(
        val invitation: Invitation,
    ) : EntertainmentDetailsUiType {
        override val contentId: String = invitation.content
        override val type: EntertainmentType = invitation.type
    }

    @Serializable
    data class ApprovedInvitation(
        val invitation: Invitation,
    ) : EntertainmentDetailsUiType {
        override val contentId: String = invitation.content
        override val type: EntertainmentType = invitation.type
    }
    @Serializable
    data class NewInvitation(
        override val contentId: String,
        override val type: EntertainmentType
    ) : EntertainmentDetailsUiType
}


fun TmEntertainmentUiType.toEntDetails(): EntertainmentDetailsUiType {
    return when (this) {
        is NewEntertainmentUiData -> {
            EntertainmentDetailsUiType.NewInvitation(
                contentId = this.id,
                type = this.type
            )
        }
        is ResponseEntertainmentUi -> {
            EntertainmentDetailsUiType.ExistingInvitation(
                invitation = this.invitation
            )
        }

        is TmEntApproved -> {
            EntertainmentDetailsUiType.ApprovedInvitation(
                invitation = this.invitation
            )
        }
    }
}