package osg.uiZone.matchZone.journey.items

import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.material.icons.Icons

import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.dialog.FunctionalityNotAvailablePopup
import com.osg.truebase.ui.intrinsic.dpToPx
import com.osg.ui.core.resources.painter
import osg.uiZone.matchZone.icons.PlayCircleFilled
import osg.uiZone.matchZone.journey.journeyTitleWeight
import osg.uiZone.matchZone.state.JourneyPanelItem
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.billie_r
import truematch.uizone.generated.resources.edsheeran_r
import truematch.uizone.generated.resources.imagindragon_r

@Composable
fun JourneyPlaylist(
    journeyPlaylistData: JourneyPanelItem.Playlist,
    modifier: Modifier = Modifier
) {
    Column(
        modifier = modifier.padding(16.dp),
        verticalArrangement = Arrangement.spacedBy(10.dp)
    ) {
        Text(
            style = MaterialTheme.typography.titleMedium.copy(
                fontWeight = journeyTitleWeight
            ),
            text = "SHARED PLAYLIST"
        )
        ElevatedCard(
            modifier = modifier
                .width(320.dp),
        ) {
            Column(
                modifier = modifier.padding(16.dp),
                verticalArrangement = Arrangement.spacedBy(16.dp)
            ) {
                Box(
                    modifier = Modifier.fillMaxWidth(),
                ) {
                    AlbumCollection(
                        modifier = Modifier
                            .padding(start = 32.dp)
                            .size(50.dp)
                            .align(Alignment.CenterStart)
                    )
                    var functionalityNotAvailablePopupShown by remember { mutableStateOf(false) }
                    if (functionalityNotAvailablePopupShown) {
                        FunctionalityNotAvailablePopup { functionalityNotAvailablePopupShown = false }
                    }
                    IconButton(
                        modifier = Modifier.align(Alignment.CenterEnd),
                        onClick = { functionalityNotAvailablePopupShown = true },
                    ) {
                        Icon(
                            imageVector = Icons.Outlined.PlayCircleFilled,
                            contentDescription = "Play",
                            modifier = Modifier
                                .size(40.dp)
                        )
                    }

                }
            }
        }
    }

}


@Composable
fun AlbumCollection(
    modifier: Modifier = Modifier
) {
    var draw4 by remember { mutableStateOf(true) }
    val size = 150.dp
    val translationX = (size * 0.25f).dpToPx()
    Box(
        modifier = modifier
    ) {
        Image(
            painter = Res.drawable.edsheeran_r.painter,
            contentDescription = null,
            modifier = Modifier
                .size(size)
                .graphicsLayer(
                    translationX = animateFloatAsState(if (draw4) translationX else 0f).value,
                    rotationZ = animateFloatAsState(if (draw4) 45f else 0f).value,
                    translationY = 0f
                )
                .clickable(onClick = { draw4 = !draw4 })
        )
        Image(
            painter = Res.drawable.imagindragon_r.painter,
            contentDescription = null,
            modifier = Modifier
                .size(size)
                .graphicsLayer(
                    translationX = animateFloatAsState(if (draw4) -translationX else 0f).value,
                    rotationZ = animateFloatAsState(if (draw4) 45f else 0f).value,
                    translationY = animateFloatAsState(if (draw4) 0f else 30f).value
                )
                .clickable(onClick = { draw4 = !draw4 })
        )
        Image(
            painter = Res.drawable.billie_r.painter,
            contentDescription = null,
            modifier = Modifier
                .size(size)
                .graphicsLayer(
                    translationY = animateFloatAsState(if (draw4) 0f else 50f).value,
                    rotationZ = animateFloatAsState(if (draw4) 45f else 0f).value,
                )
                .clickable(onClick = { draw4 = !draw4 })
        )
        Spacer(modifier = Modifier.padding(60.dp))
    }
}


