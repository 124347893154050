package osg.uiZone.matchZone.chat

import androidx.compose.foundation.layout.*
import androidx.compose.material.icons.Icons
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.theme.spacing
import osg.uiZone.matchZone.icons.Keyboard
import osg.uiZone.matchZone.sparkMove.SparkMovePager
import osg.uiZone.matchZone.sparkMove.SparkMovesCollection
import osg.uiZone.search.components.SwingIconButton

@Composable
fun SpecialInput(
    modifier: Modifier = Modifier,
    onSparkMoveClick: (SparkMovesCollection) -> Unit,
    onInputSelector: (UserInputState) -> Unit
) {
    Column(
        modifier = modifier.fillMaxWidth().height(320.dp).padding(
            bottom = MaterialTheme.spacing.small
        )
    ){
        Row(
            modifier = Modifier.padding(
                start = MaterialTheme.spacing.small
            )
        ){
            SwingIconButton(
                modifier = Modifier,
                imageVector = Icons.Default.Keyboard,
                onClick = {
                    onInputSelector(UserInputState.Text)
                }
            )
        }
        SparkMovePager(
            modifier = Modifier,
            onSparkMoveClick = onSparkMoveClick
        )
    }
}