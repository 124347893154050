package osg.uiZone.search

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.osg.appData.profile.PreferencesMatch
import com.osg.truebase.ui.commonStates.MainViewUiState
import com.osg.truebase.ui.commonStates.toSuccess
import kotlinx.coroutines.flow.*
import osg.uiZone.search.repo.SearchScope

interface ISearchPreferencesRepo {
    val prefrencesState: StateFlow<PreferencesMatch?>
    fun updatePrefrencesState(data: PreferencesMatch)
}

class SearchPreferencesScreenViewModel(
    private val searchPreferencesRepo: ISearchPreferencesRepo = SearchScope().searchRepository
) : ViewModel() {
    val prefrencesState: StateFlow<MainViewUiState<PreferencesMatch>> =
        searchPreferencesRepo.prefrencesState.filterNotNull().map {
            it.toSuccess()
        }.stateIn(
            viewModelScope,
            SharingStarted.WhileSubscribed(5000),
            MainViewUiState.Loading,
        )

    fun savePrefrences(data: PreferencesMatch) {
        searchPreferencesRepo.updatePrefrencesState(data)
    }
}