package com.osg.truebase.ui.graphics.shader

import androidx.compose.ui.graphics.*
import org.jetbrains.skia.ImageFilter
import org.jetbrains.skia.RuntimeEffect
import org.jetbrains.skia.RuntimeShaderBuilder


class NonAndroidCommonRuntimeEffect(shader: TrueShader) : CommonRuntimeEffect {
    private val compositeRuntimeEffect = RuntimeEffect.makeForShader(shader.shaderData)
    private val compositeShaderBuilder = RuntimeShaderBuilder(compositeRuntimeEffect)

    override fun setFloatUniform(name: String, value1: Float) {
        compositeShaderBuilder.uniform(name, value1)
    }

    override fun setFloatUniform(name: String, value1: Float, value2: Float) {
        compositeShaderBuilder.uniform(name, value1, value2)
    }

    override fun setFloatUniform(name: String, value1: Float, value2: Float, value3: Float) {
        compositeShaderBuilder.uniform(name, value1, value2, value3)
    }

    override fun setFloatUniform(name: String, values: FloatArray) {
        compositeShaderBuilder.uniform(name, values)
    }

    override fun setColorUniform(name: String, color: Color) {
        compositeShaderBuilder.uniform(name, color.red, color.green, color.blue, color.alpha)
    }


    override val brush: Brush
        get() = ShaderBrush(compositeShaderBuilder.makeShader())

    override fun asRenderEffect(): RenderEffect {
        return ImageFilter.makeRuntimeShader(
            runtimeShaderBuilder = compositeShaderBuilder,
            shaderNames = arrayOf(COMPOSE_SHADER_CONTENT),
            inputs = arrayOf(
                null
            )
        ).asComposeRenderEffect()
    }
}

actual fun buildEffect(shader: TrueShader): CommonRuntimeEffect {
    return NonAndroidCommonRuntimeEffect(shader)
}