package com.osg.ui.core.imageProcces

import androidx.compose.ui.graphics.ImageBitmap
import androidx.compose.ui.graphics.asComposeImageBitmap
import androidx.compose.ui.graphics.asSkiaBitmap
import androidx.compose.ui.graphics.toComposeImageBitmap
import org.jetbrains.skia.Bitmap
import org.jetbrains.skia.Canvas
import org.jetbrains.skia.EncodedImageFormat
import org.jetbrains.skia.Image


actual suspend fun ByteArray.toImageBitmap(): ImageBitmap{
    return Image.makeFromEncoded(this@toImageBitmap).toComposeImageBitmap()
}

actual suspend fun ByteArray.compressJpeg(qualityPercentage: Int): ByteArray {
    val image = Image.makeFromEncoded(this@compressJpeg)
    val encoded = image.encodeToData(EncodedImageFormat.JPEG, qualityPercentage)
    return encoded!!.bytes
}

actual suspend fun ImageBitmap.scale(width: Int, height: Int): ImageBitmap {
    val scaled = scaleBitmapAspectRatio(this@scale.asSkiaBitmap(), width, height)
    return scaled.asComposeImageBitmap()
}

private fun scaleBitmapAspectRatio(
    bitmap: Bitmap,
    width: Int,
    height: Int
): Bitmap {
    val boundWidth = width.toFloat()
    val boundHeight = height.toFloat()

    val ratioX = boundWidth / bitmap.width
    val ratioY = boundHeight / bitmap.height
    val ratio = if (ratioX < ratioY) ratioX else ratioY

    val resultWidth = (bitmap.width * ratio).toInt()
    val resultHeight = (bitmap.height * ratio).toInt()

    val result = Bitmap().apply {
        allocN32Pixels(resultWidth, resultHeight)
    }
    val canvas = Canvas(result)
    canvas.drawImageRect(Image.makeFromBitmap(bitmap), result.bounds.toRect())
    canvas.readPixels(result, 0, 0)
    canvas.close()

    return result
}