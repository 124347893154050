package osg.uiZone.register.data

import com.osg.appData.register.OpenDeepQuestions
import org.jetbrains.compose.resources.StringResource
import truematch.uizone.generated.resources.*

data class OpenDeepQuestionData(
    val title: StringResource,
    val directions: StringResource,
    val previewTitle: StringResource
)

val OpenDeepQuestions.questionData: OpenDeepQuestionData
    get() = when(this){
        OpenDeepQuestions.INTRODUCTION -> OpenDeepQuestionData(
            title = Res.string.question_intro_title,
            directions = Res.string.question_intro_direction,
            previewTitle = Res.string.question_intro_preview_title
        )
        OpenDeepQuestions.Q1 -> OpenDeepQuestionData(
            title = Res.string.question_q1_title,
            directions = Res.string.question_q1_direction,
            previewTitle = Res.string.question_q1_preview_title
        )
    }