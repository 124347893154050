package osg.uiZone.common.pyrotechnics.animatedNavBar.colorButtons

import androidx.compose.animation.core.*
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.offset
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.scale
import androidx.compose.ui.graphics.TransformOrigin
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.DpOffset
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.dp
import androidx.compose.ui.util.lerp
import com.osg.ui.core.commonStates.TrueVector
import com.osg.ui.core.commonStates.asVector
import com.osg.truebase.ui.intrinsic.dpToPx
import com.osg.truebase.ui.intrinsic.pxToDp
import com.osg.ui.core.modifirer.noRippleClickable
import com.osg.ui.core.resources.vector
import org.jetbrains.compose.resources.DrawableResource
import osg.uiZone.common.pyrotechnics.animatedNavBar.animation.balltrajectory.BallAnimInfo
import kotlin.math.PI
import kotlin.math.sin

data class ButtonBackground(
    val resource: DrawableResource,
    val offset: DpOffset = DpOffset.Zero,
)


@Stable
data class ColorButtonAnimation(
    val animationSpec: FiniteAnimationSpec<Float> = tween(10000),
    val background: ButtonBackground,
    val maxDegrees: Float = 30f,
    val icon: TrueVector,
) {
    @Composable
    fun AnimatingIcon(
        modifier: Modifier,
        transition: Transition<Boolean>
    ) {

        val rotationFraction by transition.animateFloat(
            transitionSpec = { animationSpec },
            label = "fraction"
        ) {
            if (it) 1f else 0f
        }

        val rotationAngle = degreesRotationInterpolation(maxDegrees, rotationFraction)
        Icon(
            modifier = modifier
                .rotationWithTopCenterAnchor(rotationAngle),
            imageVector = icon.asVector(),
            contentDescription = null,
            tint = LocalContentColor.current
        )
    }
}

fun degreesRotationInterpolation(maxDegrees: Float, fraction: Float): Float =
    sin(fraction * 2 * PI).toFloat() * maxDegrees

@Composable
fun ColorButton(
    modifier: Modifier = Modifier,
    index: Int,
    selectedIndex: Int,
    prevSelectedIndex: Int,
    onClick: () -> Unit,
    contentDescription: String? = null,
    backgroundAnimationSpec: FiniteAnimationSpec<Float> = tween(300, easing = LinearEasing),
    animationType: ColorButtonAnimation,
    notificationCount: Int = 0,
    maxBackgroundOffset: Dp = 25.dp
) {
    Box(
        modifier = modifier.noRippleClickable { onClick() },
    ) {
        val isSelected = selectedIndex == index
        val transition = updateTransition(selectedIndex == index, label = "transition")


        val fraction by transition.animateFloat(
            transitionSpec = { backgroundAnimationSpec },
            label = "fraction"
        ) { if (it) 1f else 0f }

        val maxOffset = maxBackgroundOffset.dpToPx()

        val isFromLeft = remember(prevSelectedIndex, index, selectedIndex) {
            (prevSelectedIndex < index) || (selectedIndex > index)
        }
        val offset = calculateBackgroundOffset(
            isSelected = isSelected,
            isFromLeft = isFromLeft,
            maxOffset = maxOffset,
            fraction = fraction
        ).toInt().pxToDp()

        Image(
            modifier = Modifier
                .offset(
                    x = animationType.background.offset.x + offset,
                    y = animationType.background.offset.y
                )
                .scale(fraction)
                .align(Alignment.Center),
            imageVector = animationType.background.resource.vector,
            contentDescription = contentDescription
        )
        BadgedBox(
            modifier = Modifier.align(Alignment.Center),
            badge = {
                if (notificationCount > 0 && isSelected.not()) {
                    Badge {
                        Text(notificationCount.toString())
                    }
                }
            },
        ) {
            animationType.AnimatingIcon(
                modifier = Modifier.align(Alignment.Center),
                transition = transition,
            )
        }
    }
}

private fun calculateBackgroundOffset(
    isSelected: Boolean,
    isFromLeft: Boolean,
    fraction: Float,
    maxOffset: Float
): Float {
    val offset = if (isFromLeft) -maxOffset else maxOffset
    return if (isSelected) {
        lerp(offset, 0f, fraction)
    } else {
        lerp(-offset, 0f, fraction)
    }
}

fun Modifier.rotationWithTopCenterAnchor(degrees: Float) = this
    .graphicsLayer(
        transformOrigin = TransformOrigin(
            pivotFractionX = 0.5f,
            pivotFractionY = 0.1f,
        ),
        rotationZ = degrees
    )

fun Modifier.ballTransform(ballAnimInfo: BallAnimInfo) = this
    .offset {
        IntOffset(
            x = ballAnimInfo.offset.x.toInt(),
            y = ballAnimInfo.offset.y.toInt()
        )
    }
    .graphicsLayer {
        scaleY = ballAnimInfo.scale
        scaleX = ballAnimInfo.scale
        transformOrigin = TransformOrigin(pivotFractionX = 0.5f, 0f)
    }