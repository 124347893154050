package osg.uiZone.search.components

import androidx.annotation.FloatRange
import androidx.compose.animation.animateContentSize
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.Icons
import androidx.compose.material3.Card
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.layout.Layout
import androidx.compose.ui.layout.MeasurePolicy
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import appData.trueAutoResources.MatchingInterest
import com.osg.ui.core.modifirer.noRippleClickable
import com.osg.ui.core.resources.painter
import com.osg.ui.core.resources.string
import com.osg.truebase.ui.theme.spacing
import osg.uiZone.matchZone.icons.ChevronRight
import osg.uiZone.profile.IncentiveCardType
import osg.uiZone.utills.emoji
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.matcher
import truematch.uizone.generated.resources.matcher_ia_description
import truematch.uizone.generated.resources.matcher_ia_name

@Composable
fun AiTextCard(
    modifier: Modifier = Modifier,
    aiMatchIncentive: IncentiveCardType.AiMatchIncentive,
) {
    var seeMore by remember { mutableStateOf(aiMatchIncentive.initialExpanded) }
    Card(
        modifier = modifier
            .fillMaxWidth()
            .animateContentSize()
    ) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
        ) {
            Chevron(
                isClicked = seeMore,
                onClick = { seeMore = !seeMore },
                modifier = Modifier.align(Alignment.TopEnd)
            )

            Row(
                modifier = Modifier
                    .padding(MaterialTheme.spacing.small)
                    .fillMaxWidth(),
            ) {
                Image(
                    modifier = Modifier
                        .size(40.dp)
                        .clip(CircleShape),
                    painter = Res.drawable.matcher.painter,
                    contentDescription = aiMatchIncentive.matchIncentive.promotion,
                )
                MatchIncentiveContent(
                    modifier = Modifier.padding(horizontal = 12.dp, vertical = 4.dp),
                    aiMatchIncentive = aiMatchIncentive,
                    seeMore = seeMore
                )
            }
        }
        val matchingInterests = aiMatchIncentive.matchIncentive.matchingInterests.map {
            MatchingInterest.valueOf(it.name)
        }
        DeepChipsContent(
            modifier = Modifier.padding(MaterialTheme.spacing.small),
            matchingInterests = matchingInterests,
            seeMore = seeMore
        )
    }
}

@Composable
fun DeepChipsContent(
    modifier: Modifier = Modifier,
    matchingInterests: List<MatchingInterest>,
    seeMore: Boolean
) {
    if (seeMore) {
        DeepChipsRow(
            modifier = modifier,
            promoChips = matchingInterests
        )
    } else {
        MatchIncentivePreview(
            modifier = modifier,
            matchingPoints = matchingInterests
        )
    }
}

@Composable
fun MatchIncentiveContent(
    modifier: Modifier = Modifier,
    aiMatchIncentive: IncentiveCardType.AiMatchIncentive,
    seeMore: Boolean
) {
    Column(
        modifier = modifier,
        verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small)
    ) {
        Text(
            text = Res.string.matcher_ia_name.string,
            fontWeight = FontWeight.Bold,
            color = MaterialTheme.colorScheme.onSurface,
        )
        Text(
            text = Res.string.matcher_ia_description.string,
            style = MaterialTheme.typography.labelLarge,
            color = MaterialTheme.colorScheme.outline
        )
        Text(
            text = aiMatchIncentive.matchIncentive.promotion,
            style = MaterialTheme.typography.bodyMedium,
            color = MaterialTheme.colorScheme.onSurfaceVariant,
            maxLines = if (seeMore) Int.MAX_VALUE else 3,
            overflow = TextOverflow.Ellipsis
        )
    }
}

@Composable
fun MatchIncentivePreview(
    modifier: Modifier = Modifier,
    matchingPoints: List<MatchingInterest>
) {
    Row(
        modifier = modifier,
    ) {
        OverlappingRow(
            overlapFactor = 0.75f
        ) {
            matchingPoints.forEach {
                Text(
                    modifier = modifier,
                    text = it.emoji,
                    fontSize = 21.sp,
                    textAlign = TextAlign.Center,
                    style = MaterialTheme.typography.bodyMedium,
                )
            }
        }
    }
}

fun overlappingRowMeasurePolicy(overlapFactor: Float) =
    MeasurePolicy { measurableItems, constraints ->
        val placeables = measurableItems.map { measurable -> measurable.measure(constraints) }
        if (placeables.isEmpty()) {
            layout(0, 0) {}
            return@MeasurePolicy layout(0, 0) {}
        }
        val height = placeables.maxOf { it.height }
        val width = (placeables.subList(1, placeables.size)
            .sumOf { it.width } * overlapFactor + placeables[0].width).toInt()
        layout(width, height) {
            var xPos = 0
            for (placeable in placeables) {
                placeable.placeRelative(xPos, 0, 0f)
                xPos += (placeable.width * overlapFactor).toInt()
            }
        }
    }

@Composable
fun OverlappingRow(
    modifier: Modifier = Modifier,
    @FloatRange(from = 0.1, to = 1.0) overlapFactor: Float = 0.75f,
    content: @Composable () -> Unit,
) {
    val measurePolicy = overlappingRowMeasurePolicy(overlapFactor)
    Layout(
        measurePolicy = measurePolicy,
        content = content,
        modifier = modifier
    )
}

@Composable
fun Chevron(
    modifier: Modifier = Modifier,
    isClicked: Boolean,
    onClick: () -> Unit = {},
) {
    val rotation by animateFloatAsState(
        targetValue = if (isClicked) 90f else 0f,
        animationSpec = tween(250),
        label = "arrow rotation",
    )
    Icon(
        modifier = modifier
            .padding(MaterialTheme.spacing.small)
            .rotate(rotation)
            .noRippleClickable(
            onClick
        ),
        imageVector = Icons.Outlined.ChevronRight,
        tint = MaterialTheme.colorScheme.outline,
        contentDescription = null,
    )
}