package com.osg.truebase.ui.graphics.konfetti.emitter

import androidx.compose.ui.geometry.Rect
import com.osg.truebase.ui.graphics.konfetti.Party

/**
 * An abstract class for creating a custom emitter
 * The emitter decides if a particle should be created and when the emitter is finished
 */
abstract class BaseEmitter {

    abstract fun createConfetti(
        deltaTime: Float,
        frameTime: Long,
        party: Party,
        drawArea: Rect,
    ): List<Confetti>

    /**
     * @return true if the emitter is no longer creating any particles
     *         false if is still busy
     */
    abstract fun isFinished(
        totalCreated: Int,
    ): Boolean
}