package com.osg.truebase.ui.components
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.theme.spacing
import androidx.compose.animation.AnimatedVisibility
import com.osg.truebase.ui.intrinsic.pxToDp

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun AutoComplete(
    autocompleteOptions: List<String>,
    autoCompleteValue: String,
    direction: String,
    modifier: Modifier = Modifier,
    onValueChange: (String) -> Unit,
    onValueSelected: (String) -> Unit
) {
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        val focusManager = LocalFocusManager.current
        var isImeVisible by remember { mutableStateOf(false) }
        var textFieldSize by remember {
            mutableStateOf(0)
        }
        TextField(
            modifier = Modifier.onGloballyPositioned { coordinates ->
                textFieldSize = coordinates.size.width
            }.onFocusChanged {
                isImeVisible = it.isFocused
            },
            value = autoCompleteValue,
            onValueChange = onValueChange,
            label = {
                Text(text = direction)
            },
            trailingIcon = {
                ExposedDropdownMenuDefaults.TrailingIcon(
                    expanded = isImeVisible
                )
            },
            colors = ExposedDropdownMenuDefaults.textFieldColors(),
            keyboardActions = KeyboardActions(onDone = {
                focusManager.clearFocus()
            }),
        )
        AnimatedVisibility(visible = isImeVisible) {
            val widthDp = textFieldSize.pxToDp()
            AutoCompleteDropDown(
                modifier = Modifier.width(widthDp),
                autocompleteOptions = autocompleteOptions,
                onValueSelected = {
                    focusManager.clearFocus()
                    onValueSelected(it)
                }
            )
        }
    }
}

@Composable
fun AutoCompleteDropDown(
    modifier: Modifier = Modifier,
    autocompleteOptions: List<String>,
    onValueSelected: (String) -> Unit
) {
    Card(
        modifier = modifier,
        shape = RoundedCornerShape(10.dp)
    ) {
        LazyColumn(
            modifier = Modifier.heightIn(max = 150.dp),
        ) {
            items(autocompleteOptions) { text ->
                DropOption(
                    text = text,
                    onClick = onValueSelected
                )
            }
        }
    }
}


@Composable
fun DropOption(
    text: String,
    onClick: (String) -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .clickable {
                onClick(text)
            }
            .padding(MaterialTheme.spacing.small)
    ) {
        Text(text = text)
    }
}