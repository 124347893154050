package com.osg.ice.ui

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.osg.ice.state.IceMatchResult
import com.osg.ice.state.IceUiPlayerProfile
import com.osg.truebase.ice.generated.resources.Res
import com.osg.truebase.ice.generated.resources.baseline_auto_awesome_24
import com.osg.ui.core.commonStates.asPainter
import com.osg.ui.core.resources.vector

@Composable
fun MatchResultTeamMode(
    currentResult: IceMatchResult,
    prevResult: IceMatchResult,
    myPlayerProfile: IceUiPlayerProfile,
    opponentProfile: IceUiPlayerProfile,
    modifier: Modifier,
) {
    ElevatedCard {
        Column(
            modifier = modifier.padding(8.dp),
            verticalArrangement = Arrangement.spacedBy(8.dp),
            horizontalAlignment = Alignment.Companion.CenterHorizontally,
        ) {
            Row(
                modifier = Modifier.Companion.weight(3f),
                horizontalArrangement = Arrangement.spacedBy(8.dp),
            ) {
                TeamSymbolView(
                    modifier = Modifier.Companion.weight(1f, fill = false),
                    teamAvatar = myPlayerProfile.trueProfileImage.asPainter(),
                )
                TeamSymbolView(
                    modifier = Modifier.Companion.weight(1f, fill = false),
                    teamAvatar = opponentProfile.trueProfileImage.asPainter(),
                )
            }
            ScoreTeamCard(
                modifier = Modifier.Companion.weight(2f, fill = false),
                currentResult = currentResult,
                prevResult = prevResult,
            )
        }
    }
}


@Composable
fun ScoreTeamCard(
    currentResult: IceMatchResult,
    prevResult: IceMatchResult,
    modifier: Modifier = Modifier
) {
    val joinedScore = currentResult.myScore + currentResult.opponentScore
    val prevScore = prevResult.myScore + prevResult.opponentScore

    Column(
        modifier = modifier,
        verticalArrangement = Arrangement.spacedBy(4.dp),
        horizontalAlignment = Alignment.Companion.CenterHorizontally,
    ) {
        Text(
            modifier = Modifier.weight(1f),
            text = "Score",
            style = MaterialTheme.typography.headlineMedium,
            color = MaterialTheme.colorScheme.primary
        )
        Row(
            modifier = Modifier.weight(1f),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(4.dp),
        ){
            Icon(
                imageVector = Res.drawable.baseline_auto_awesome_24.vector,
                contentDescription = null,
            )
            TeamScoreText(
                scoreState = ScoreUiState(
                    nextScore = joinedScore.toString(),
                    prevScore = prevScore.toString(),
                ),
                modifier = Modifier,
            )
        }
    }
}