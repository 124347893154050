package com.osg.truebase.ui.theme

import androidx.compose.runtime.compositionLocalOf
import androidx.compose.ui.text.intl.Locale
import com.osg.truebase.data.LanguageSupport
import com.osg.truebase.ui.datastore.IBaseConfig


val Locale.supportedLanguages: LanguageSupport
    get() {
        val languageCode = this.language
        return LanguageSupport.entries.firstOrNull {
            it.languageCodes.contains(languageCode)
        }?: LanguageSupport.ENGLISH
    }

data class ThemeConfig(
    override val language: LanguageSupport = LanguageSupport.ENGLISH,
): IBaseConfig{
    constructor(baseConfig: IBaseConfig): this(
        language = baseConfig.language
    )
}

val LocalTmConfig = compositionLocalOf<ThemeConfig> { (
        error("STATE_NOT_PROVIDED_MESSAGE")
) }
