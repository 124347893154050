package osg.uiZone.search.components

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Close
import androidx.compose.material.icons.filled.Search
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.positionInWindow
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import appData.trueAutoResources.MatchingInterest
import com.osg.truebase.spotlight.tutorialTarget
import com.osg.truebase.ui.theme.brandColors
import com.osg.truebase.ui.theme.spacing
import com.osg.ui.core.commonStates.asPainter
import com.osg.ui.core.resources.asFormattedText
import com.osg.ui.core.resources.painter
import com.osg.ui.core.resources.string
import common.ds.Place
import osg.uiZone.common.bar.ElevatedIconButton
import osg.uiZone.common.ds.ui.ActionButton
import osg.uiZone.common.paint.GlassView
import osg.uiZone.common.pyrotechnics.navigation.PreviewType
import osg.uiZone.matchZone.icons.LocationCity
import osg.uiZone.matchZone.icons.Work
import osg.uiZone.matchZone.state.tmEntUi.stringResource
import osg.uiZone.profile.TechnicalDetails
import osg.uiZone.tutorial.TutorialStepCollection
import osg.uiZone.utills.emoji
import osg.uiZone.utills.findEmoji
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.location_preview
import truematch.uizone.generated.resources.verified

@Composable
fun CandidatePromoCard(
    promoData: PreviewType.Promo,
    modifier: Modifier = Modifier,
    onDislike: () -> Unit,
    onLikeToSeeMore: () -> Unit,
) {
    val heightButton = 55.dp
    val gradient = Brush.verticalGradient(
        colorStops = arrayOf(
            .60f to Color.Transparent,
            1f to Color.Black.copy(alpha = .5f)
        )
    )
    val actionButtons = listOf(
        ActionButton(
            icon = Icons.Filled.Close,
            color = MaterialTheme.brandColors.dislikePromo,
            onClick = onDislike
        ),
        ActionButton(
            icon = Icons.Filled.Search,
            color = MaterialTheme.brandColors.likeToSeeMore,
            onClick = onLikeToSeeMore
        ),
    )

    ElevatedCard(
        modifier = modifier
            .wrapContentSize(),
        elevation = CardDefaults.cardElevation(defaultElevation = 6.dp),
        shape = RoundedCornerShape(16.dp)
    ) {
        val childMeasures = List(2) { Place() }
        Box(Modifier.fillMaxSize()) {
            GlassView(
                imageBitmap = promoData.profileImage.asPainter(),
                childMeasures = childMeasures,
            )
            Spacer(
                Modifier
                    .fillMaxSize()
                    .background(gradient)
            )
            Box(
                Modifier.fillMaxSize(),
                contentAlignment = Alignment.TopStart
            )
            {
                DeepChips(
                    modifier = Modifier
                        .padding(8.dp)
                        .tutorialTarget(TutorialStepCollection.SearchPromoIncentive),
                    promoChips = promoData.matchingInterests
                )
            }

            Box(
                Modifier.fillMaxSize(),
                contentAlignment = Alignment.BottomStart
            )
            {
                Column {
                    TechnicalDetails(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(horizontal = 16.dp),
                        profileUiDetails = promoData.profileUiDetails,
                    )
                    Spacer(modifier = Modifier.height(8.dp))
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(horizontal = 16.dp, vertical = 16.dp)
                            .tutorialTarget(
                                TutorialStepCollection.SearchPromoWantToSeeMore
                            ),
                        verticalAlignment = Alignment.CenterVertically,
                    ) {
                        for (i in actionButtons.indices) {
                            ElevatedIconButton(
                                modifier = Modifier
                                    .windowInsetsPadding(WindowInsets.navigationBars)
                                    .height(heightButton)
                                    .weight(1f)
                                    .onGloballyPositioned {
                                        childMeasures[i].apply {
                                            sizeX = it.size.width
                                            sizeY = it.size.height
                                            offsetX = it.positionInWindow().x
                                            offsetY = it.positionInWindow().y
                                        }
                                    },
                                onClick = actionButtons[i].onClick,
                                icon = actionButtons[i].icon,
                                color = actionButtons[i].color
                            )
                            if (i != actionButtons.size - 1) {
                                Spacer(modifier = Modifier.weight(0.1f))
                            }
                        }

                    }
                }
            }
        }
    }
}

@Composable
fun DeepChips(
    modifier: Modifier = Modifier,
    promoChips: List<MatchingInterest>
) {
    Column(modifier = modifier) {
        for (i in promoChips.indices) {
            PromoChip(matchingPoint = promoChips[i])
            Spacer(modifier = Modifier.height(8.dp))
        }
    }
}

@OptIn(ExperimentalLayoutApi::class)
@Composable
fun DeepChipsRow(
    modifier: Modifier = Modifier,
    promoChips: List<MatchingInterest>
) {
    FlowRow(
        modifier = modifier,
        horizontalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small),
    ) {
        promoChips.forEach { promoChip ->
            val strInterest = promoChip.stringResource.string
            ElevatedSuggestionChip(
                modifier = Modifier,
                icon = {
                    Text(
                        text = findEmoji(strInterest),
                    )
                },
                label = { Text(text = strInterest) },
                onClick = { }
            )
        }
    }
}


@Composable
fun TechnicalDetails(
    modifier: Modifier = Modifier,
    profileUiDetails: TechnicalDetails,
    iconColor: Color = Color(0xFF77d9dd),
    textColor: Color = Color.White
) {

    val locationText = Res.string.location_preview.asFormattedText(
        profileUiDetails.city,
        profileUiDetails.country
    )

    Column(
        modifier = modifier,
        verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small)
    ) {
        NameAge(
            name = profileUiDetails.name,
            age = profileUiDetails.age,
            verified = profileUiDetails.verified,
            textColor = textColor,
        )
        ProfileDetails(
            text = locationText,
            icon = Icons.Outlined.LocationCity,
            iconColor = iconColor,
            textColor = textColor
        )
        ProfileDetails(
            text = profileUiDetails.occupation,
            icon = Icons.Outlined.Work,
            iconColor = iconColor,
            textColor = textColor
        )
    }
}

@Composable
fun PromoChip(
    matchingPoint: MatchingInterest
) {
    Row(
        modifier = Modifier
            .wrapContentSize()
            .clip(
                RoundedCornerShape(10.dp)
            )
            .background(Color.Black.copy(alpha = .4f))
            .padding(horizontal = 8.dp, vertical = 4.dp),
        verticalAlignment = Alignment.CenterVertically
    )
    {
        Text(
            text = matchingPoint.emoji,
            textAlign = TextAlign.Center
        )
        Spacer(modifier = Modifier.width(4.dp))
        Text(
            text = matchingPoint.stringResource.string,
            color = Color.White,
            textAlign = TextAlign.Center
        )
    }
}



@Composable
fun ProfileDetails(
    text: String,
    icon: ImageVector,
    iconColor: Color,
    textColor: Color
) {
    Row(verticalAlignment = Alignment.CenterVertically) {
        Icon(
            icon,
            tint = iconColor,
            contentDescription = "Example Icon"
        )
        Spacer(modifier = Modifier.width(8.dp))
        Text(
            text = text,
            color = textColor,
            textAlign = TextAlign.Center
        )
    }
}

@Composable
fun NameAge(
    modifier: Modifier = Modifier,
    name: String, age: String,
    textColor: Color = Color.White,
    verified: Boolean = true
) {
    Row(
        modifier = modifier,
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Start
    ) {
        Text(
            text = name,
            fontWeight = FontWeight.SemiBold,
            color = textColor,
            fontSize = 30.sp,
        )
        Spacer(modifier.width(8.dp))
        Text(text = age, color = textColor, fontSize = 28.sp)
        Spacer(modifier.width(12.dp))
        Image(
            painter = Res.drawable.verified.painter,
            modifier = modifier
                .size(25.dp)
                .alpha(if (verified) 1f else 0f),
            contentDescription = null
        )
    }
}