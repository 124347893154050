package osg.uiZone.common.vmRepo

import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import com.osg.appData.location.IAutoCompleteCities
import osg.uiZone.common.baseRepos.profile.*
import osg.uiZone.common.baseRepos.profile.MyProfileScope.Companion.myProfileHandler


interface IRegisterRepository:
    ISaveMyFullProfile,
    IUserLocationStateFlow,
    IMyFullProfileFlow,
    IAutoCompleteCities,
    IUpdateUserMap




class RegisterRepositoryImpl:
    IMyProfileDependencies by myProfileHandler,
    IRegisterRepository,
        KoinComponent{
    private val locationService: IAutoCompleteCities by inject()
    override suspend fun autocompleteCitiesNearby(
        countryCode: String,
        userInput: String
    ): List<String>  = locationService.autocompleteCitiesNearby(countryCode, userInput)
}


