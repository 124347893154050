package com.osg.truebase.signin

import androidx.compose.runtime.Composable
import com.osg.truebase.data.user.SignInIdentifier

interface ISignInProvider {
    val identifier: SignInIdentifier
    @Composable
    fun SignInDialog(
        onComplete: (Result<SignInResult>) -> Unit
    )
}

fun interface ISignInProviders{
    fun buildSignInProviders(
        appSignInData: AppSignInData,
    ): List<ISignInProvider>
}

sealed class SignInDialogs {
    data class SignInDialog(
        val signInProvider: ISignInProvider
    ) : SignInDialogs()

    data class CollisionException(
        val signInProvider: ISignInProvider
    ) : SignInDialogs()

    data object UnExpectedException : SignInDialogs()
    data object None : SignInDialogs()
}



enum class SignInScopes{
    Email,
    Name,
    Birthday,
    Gender,
}

data class AppSignInData(
    val clientId: String,
    val appId: String,
    val scopes: List<SignInScopes>
)

sealed class SignInException() : Throwable() {
    data object CollisionException : SignInException()
    data class UnExpectedException(val issue: String) : SignInException()
}