package osg.uiZone.search.ds

import androidx.compose.runtime.Immutable
import com.osg.appData.candidate.CandidateProfile
import com.osg.appData.candidate.Decision
import com.osg.ui.core.commonStates.TrueImage

@Immutable
sealed class SearchScreenState {
    data object Loading : SearchScreenState()
    data object NoMoreCandidates : SearchScreenState()
    data class Search(
        val currentCandidate: CandidateProfile,
        val profileImages: List<TrueImage>,
        val candidateIdx: Int,
        val lastDecision: Decision,
    ) : SearchScreenState()
}


sealed class CheckMatchState {
    data object NoMatch : CheckMatchState()
    data object Match : CheckMatchState()
}