package osg.uiZone.register

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavController
import androidx.navigation.NavGraphBuilder
import androidx.navigation.compose.composable
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.player.video.VideoPlayer
import com.osg.truebase.ui.assigners.wrapper.GenericQuestionScreen
import com.osg.truebase.ui.commonStates.MainViewUi
import osg.uiZone.navigation.RegisterDestinations
import osg.uiZone.navigation.SearchDestination
import osg.uiZone.register.ds.RegisterScreenState
import osg.uiZone.register.model.ImagePickViewModel
import osg.uiZone.register.model.RegisterSetupViewModel
import osg.uiZone.register.ui.ProfileImagePick

fun NavGraphBuilder.registerGraph(
    navController: NavController
) {
    composable<RegisterDestinations.RegisterScreen> { backStackEntry ->
        val registerViewModel = viewModel {
            RegisterSetupViewModel()
        }
        val registerState by registerViewModel.uiState.collectAsState()
        AppLogger.d("RegisterGraph", "registerState: $registerState")
        registerState.MainViewUi{ state ->
            when (state) {
                is RegisterScreenState.DeepQuestionsIntro -> {
                    Box(
                        modifier = Modifier
                            .fillMaxSize()
                            .clickable(
                                onClick = {
                                    registerViewModel.onFinishDeepQuestionsIntro(state.initialProfile)
                                }
                            )
                            .background(Color.White),
                        contentAlignment = Alignment.Center,
                    ) {
                        VideoPlayer(
                            videoUri = state.videoPath,
                            onFinished ={
                                registerViewModel.onFinishDeepQuestionsIntro(state.initialProfile)
                            },
                            modifier = Modifier.fillMaxSize()
                        )
                    }
                }

                is RegisterScreenState.Questions -> {
                    GenericQuestionScreen(
                        questionAssigners = state.questionData,
                        initialFillerState = state.initialProfile,
                        onFinish = { profile ->
                            registerViewModel.onFinishedQuestions(
                                profile = profile,
                            )
                        },
                    )
                }

                is RegisterScreenState.AddPictures -> {
                    val imageViewModel = viewModel {
                        ImagePickViewModel()
                    }
                    val screenUiState by imageViewModel.uiState.collectAsState()
                    screenUiState.MainViewUi { uiState ->
                        ProfileImagePick(
                            onPreviousPressed = navController::navigateUp,
                            currentImages = uiState.currentImages,
                            minimumImageToSave = uiState.minimumImageToSave,
                            onNextPressed = {
                                imageViewModel.finishRegister {
                                    navController.navigate(
                                        SearchDestination.SearchMain
                                    ) {
                                        popUpTo(RegisterDestinations.RegisterScreen) {
                                            inclusive = true
                                        }
                                    }
                                }
                            },
                            editImageCommand = imageViewModel::editImage,
                        )
                    }

                }
            }
        }
    }
}


