package com.osg.truebase.ui.icons
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.materialIcon
import androidx.compose.material.icons.materialPath
import androidx.compose.ui.graphics.vector.ImageVector

public val Icons.Filled.ContactMail: ImageVector
    get() {
        if (_contactMail != null) {
            return _contactMail!!
        }
        _contactMail = materialIcon(name = "Filled.ContactMail") {
            materialPath {
                moveTo(21.0f, 8.0f)
                lineTo(21.0f, 7.0f)
                lineToRelative(-3.0f, 2.0f)
                lineToRelative(-3.0f, -2.0f)
                verticalLineToRelative(1.0f)
                lineToRelative(3.0f, 2.0f)
                lineToRelative(3.0f, -2.0f)
                close()
                moveTo(22.0f, 3.0f)
                lineTo(2.0f, 3.0f)
                curveTo(0.9f, 3.0f, 0.0f, 3.9f, 0.0f, 5.0f)
                verticalLineToRelative(14.0f)
                curveToRelative(0.0f, 1.1f, 0.9f, 2.0f, 2.0f, 2.0f)
                horizontalLineToRelative(20.0f)
                curveToRelative(1.1f, 0.0f, 1.99f, -0.9f, 1.99f, -2.0f)
                lineTo(24.0f, 5.0f)
                curveToRelative(0.0f, -1.1f, -0.9f, -2.0f, -2.0f, -2.0f)
                close()
                moveTo(8.0f, 6.0f)
                curveToRelative(1.66f, 0.0f, 3.0f, 1.34f, 3.0f, 3.0f)
                reflectiveCurveToRelative(-1.34f, 3.0f, -3.0f, 3.0f)
                reflectiveCurveToRelative(-3.0f, -1.34f, -3.0f, -3.0f)
                reflectiveCurveToRelative(1.34f, -3.0f, 3.0f, -3.0f)
                close()
                moveTo(14.0f, 18.0f)
                lineTo(2.0f, 18.0f)
                verticalLineToRelative(-1.0f)
                curveToRelative(0.0f, -2.0f, 4.0f, -3.1f, 6.0f, -3.1f)
                reflectiveCurveToRelative(6.0f, 1.1f, 6.0f, 3.1f)
                verticalLineToRelative(1.0f)
                close()
                moveTo(22.0f, 12.0f)
                horizontalLineToRelative(-8.0f)
                lineTo(14.0f, 6.0f)
                horizontalLineToRelative(8.0f)
                verticalLineToRelative(6.0f)
                close()
            }
        }
        return _contactMail!!
    }

private var _contactMail: ImageVector? = null
