package com.osg.ice



import com.osg.truebase.data.iceDef.IChallengeAcceptedInvitation
import com.osg.truebase.data.iceDef.IHarmonyTuneInvitation
import com.osg.truebase.data.iceDef.ht.MusicGenre
import com.osg.truebase.data.user.PlayerDataEssential
import kotlinx.serialization.Serializable


interface IIceBreakerDependencies {
    val isInviter: Boolean
    fun isMyTurn(idx: Int): Boolean {
        return if (isInviter) {
            idx % 2 == 0
        } else {
            idx % 2 != 0
        }
    }
}




@Serializable
data class CaDependencies(
    override val gameRootPath: String,
    override val rounds: Int,
    override val inviterUid: String,
    val myPlayerProfile: PlayerDataEssential,
    val partnerPlayerProfile: PlayerDataEssential,
    val seenRoundsIds: List<String>,
): IIceBreakerDependencies, IChallengeAcceptedInvitation {
    override val isInviter: Boolean = myPlayerProfile.uid == inviterUid
}

@Serializable
data class HtDependencies(
    override val gameRootPath: String,
    override val rounds: Int,
    override val genres: List<MusicGenre>,
    override val inviterUid: String,
    val myPlayerProfile: PlayerDataEssential,
    val partnerPlayerProfile: PlayerDataEssential,
    val seenRoundsIds: List<String>,
): IIceBreakerDependencies, IHarmonyTuneInvitation {
    override val isInviter: Boolean = myPlayerProfile.uid == inviterUid
}