package com.osg.ice.ui

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.fillMaxWidth

import androidx.compose.foundation.lazy.*
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.osg.ice.ui.ds.EntertainmentCollection
import com.osg.ice.ui.ds.IEntertainmentCollection
import com.osg.truebase.ui.theme.spacing


@Composable
fun EntertainmentFeedScreen(
    entCollections: List<IEntertainmentCollection>,
    onEntDetails: (String) -> Unit = {},
    header: @Composable () -> Unit = {},
    modifier: Modifier = Modifier,
) {
    LazyColumn(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement =  Arrangement.spacedBy(MaterialTheme.spacing.small),
        modifier = modifier.fillMaxWidth(),
    ) {
        item {
            header()
        }
        items(entCollections) { collectionItem ->
            EntertainmentCollectionView(
                modifier = Modifier,
                entertainmentCollection = collectionItem,
                onEntDetails = onEntDetails,
            )
        }
    }
}
