package com.osg.truebase.ui.components.paint

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Close
import androidx.compose.material3.Card
import androidx.compose.material3.Icon
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.positionInParent
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.dp
import androidx.compose.ui.zIndex
import com.osg.ui.core.commonStates.TrueImage
import com.osg.ui.core.commonStates.asPainter
import com.osg.truebase.ui.intrinsic.dpToPx

@Composable
fun PictureHolderGrid(
    pictures: List<TrueImage>,
    totalItems:Int = 6,
    onAddPicture: () -> Unit,
    onExistPictureClicked: (Int) -> Unit
) {
    LazyVerticalGrid(
        modifier = Modifier,
        columns = GridCells.Fixed(3),
        horizontalArrangement = Arrangement.spacedBy(12.dp, Alignment.CenterHorizontally),
        verticalArrangement = Arrangement.spacedBy(8.dp)
    ) {
        items(totalItems) { index ->
            if (index < pictures.size) {
                SelectedPictureItem(
                    trueImage = pictures[index],
                    modifier = Modifier,
                    onClick = {
                        onExistPictureClicked(index)
                    }
                )
            } else {
                EmptyPictureItem(
                    modifier = Modifier,
                    onClick = onAddPicture
                )
            }
        }
    }
}

@Composable
fun EmptyPictureItem(
    modifier: Modifier = Modifier,
    borderColor : Color = Color.DarkGray,
    onClick: () -> Unit
) {
    ImageHolder(
        cornerIcon = Icons.Filled.Add,
        tint = Color.White,
        backgroundBrush = Brush.radialGradient(
            listOf(Color(0xFFec8c69), Color(0xFFFF719A), Color(0xFFFF4E50))
        ),
        onClick = onClick,
        modifier = modifier,
        content = {
            Canvas(modifier = Modifier.fillMaxSize()) {
                drawRoundRect(
                    color = borderColor, style = Stroke(
                        width = 4.dp.toPx(),
                        pathEffect = PathEffect.dashPathEffect(
                            floatArrayOf(
                                5.dp.toPx(),
                                5.dp.toPx()
                            ), 0f
                        )
                    )
                )
            }
        },
    )
}

@Composable
fun SelectedPictureItem(
    trueImage: TrueImage,
    modifier: Modifier = Modifier,
    onClick: () -> Unit
) {
    ImageHolder(
        cornerIcon = Icons.Filled.Close,
        tint = Color.Red,
        backgroundBrush = Brush.radialGradient(
            colors = listOf(Color(0xFFFFFFFF), Color(0xFFFCD6D6))
        ),
        onClick = onClick,
        modifier = modifier,
        content = {
            Image(
                painter = trueImage.asPainter(),
                contentDescription = null,
                modifier = Modifier.fillMaxSize(),
                contentScale = ContentScale.Crop
            )
        }
    )
}

@Composable
fun ImageHolder(
    cornerIcon: ImageVector,
    tint: Color = Color.White,
    backgroundBrush: Brush,
    content: @Composable () -> Unit,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    var iconPos by remember { mutableStateOf(IntOffset.Zero) }
    val offsetFIconSize = 24.dp.dpToPx() * 0.66f
    Box(
        modifier = Modifier.padding(
            bottom = 12.dp,
            end = 12.dp
        )
    ){
        Card(
            shape = RoundedCornerShape(6.dp),
            modifier = modifier
                .aspectRatio(0.6f, matchHeightConstraintsFirst = true)
                .onGloballyPositioned {
                    val offsetX = it.positionInParent().x + it.size.width - offsetFIconSize
                    val offsetY = it.positionInParent().y + it.size.height - offsetFIconSize
                    iconPos = IntOffset(offsetX.toInt(), offsetY.toInt())
                }
        ) {
            content()
        }
        Icon(
            imageVector = cornerIcon,
            tint = tint,
            modifier = Modifier
                .offset {
                    iconPos
                }
                .background(
                    brush = backgroundBrush,
                    shape = CircleShape
                )
                .clickable(onClick = onClick)
                .zIndex(1f),
            contentDescription = null
        )
    }
}


