package com.osg.ice

import com.osg.truebase.data.LanguageSupport
import com.osg.truebase.data.iceDef.ChallengeAcceptedQuery
import com.osg.truebase.data.iceDef.HtQuery
import com.osg.truebase.data.iceDef.IceContent
import com.osg.truebase.data.iceDef.IceMultiMediaDownloader
import com.osg.truebase.data.iceDef.IceQuery
import com.osg.truebase.data.nodeData.IRemoteMediaResource
import com.osg.truebase.data.nodeData.decodeFromHexString
import com.osg.truebase.data.nodeData.fromString
import ice.trueAutoResources.SharedRemoteDatabase

class IceDataStorageHandler(
    private val engine: IceMultiMediaDownloader
): IceMultiMediaDownloader by engine {
    suspend fun fetchIceContent(query: IceQuery): IceContent {
        val iceContent = engine.downloadMultimedia(query.dbRemoteFile).decodeToString().fromString<IceContent>()
        return query.filterContent(iceContent)
    }
}

val IceQuery.dbRemoteFile: IRemoteMediaResource
    get() =  when(this){
        is ChallengeAcceptedQuery -> {
            when(language){
                LanguageSupport.ENGLISH -> SharedRemoteDatabase.CHALLENGE_ACCEPTED_ENGLISH
                LanguageSupport.HEBREW -> SharedRemoteDatabase.CHALLENGE_ACCEPTED_HEBREW
            }
        }
        is HtQuery -> SharedRemoteDatabase.HARMONY_TUNES
    }