package com.osg.truebase.ui.assigners

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.material3.RangeSlider
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.osg.truebase.ui.assigners.def.GenObjAssigner
import kotlin.math.roundToInt

fun ClosedFloatingPointRange<Float>.toIntRange() = start.roundToInt()..endInclusive.roundToInt()
fun IntRange.toFloatRange() = start.toFloat()..endInclusive.toFloat()

data class RangeSliderGenAssigner(
    private val initialAnswer: ClosedFloatingPointRange<Float>,
    private val range: ClosedFloatingPointRange<Float>,
    private val steps: Int = 0,
): GenObjAssigner<ClosedFloatingPointRange<Float>> {
    @Composable
    override fun content(
        modifier: Modifier,
        current: ClosedFloatingPointRange<Float>?,
        onAnswered: (ClosedFloatingPointRange<Float>) -> Unit
    ) = RangeValueSlider(
        modifier = Modifier,
        initialAnswer = current ?: initialAnswer,
        onAnswered = onAnswered,
        steps = steps,
        range = range
    )
}

@Composable
fun RangeValueSlider(
    onAnswered: (range: ClosedFloatingPointRange<Float>) -> Unit,
    range: ClosedFloatingPointRange<Float>,
    initialAnswer: ClosedFloatingPointRange<Float>,
    modifier: Modifier = Modifier,
    steps: Int = 0
) {
    var sliderPosition by remember { mutableStateOf(initialAnswer) }
    val valueRangeState by remember { mutableStateOf(range) }


    Row(
        modifier = modifier,
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceAround
    ) {
        SliderText(
            modifier = Modifier
                .weight(2f)
                .wrapContentSize(),
            label = sliderPosition.start.roundToInt().toString()
        )
        RangeSlider(
            value = sliderPosition,
            modifier = Modifier
                .weight(6f)
                .wrapContentSize(),
            steps = steps,
            onValueChange = { range ->
                onAnswered(range)
                sliderPosition = range
            },
            valueRange = valueRangeState,
            onValueChangeFinished = {},
        )
        SliderText(
            modifier = Modifier
                .weight(2f)
                .wrapContentSize(),
            label = sliderPosition.endInclusive.roundToInt().toString()
        )
    }
}