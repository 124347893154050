package com.osg.appData.matchZone

import com.osg.appData.matchZone.invitation.Invitation
import com.osg.utils.buildPath
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement

@Serializable
data class MatchZoneNode(
    val matchInfo: MatchInfo = MatchInfo(),
    val chat: Map<String, Message> = emptyMap(),
    val invitation: Map<String, Invitation> = emptyMap(),
    val iceBreakers: Map<String, JsonElement> = emptyMap(),

){
    companion object{
        fun resolveChatPath(matchZonePath:String): String {
            return "${matchZonePath}/${MatchZoneNode::chat.name}"
        }

        fun resolveInvitationPath(matchZonePath:String): String {
            return "${matchZonePath}/${MatchZoneNode::invitation.name}"
        }
        fun resolveIceBreakersPath(matchZoneNodePath:String, gameId: String): String {
            return buildPath(matchZoneNodePath, MatchZoneNode::iceBreakers.name, gameId)
        }
    }
}
