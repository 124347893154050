package com.osg.matchmaker.singleTransaction

import com.osg.appData.auth.BasicIdentity
import com.osg.appData.bots.BOT_UID_PREFIX
import com.osg.appData.common.DataRoot.Companion.userMapPath
import com.osg.appData.profile.TechnicalProfile
import com.osg.truebase.data.logger.AppLogger

interface ISingleTransaction{
    suspend fun transaction(path: String, transaction: (currentData: TechnicalProfile?) -> TechnicalProfile?) : Boolean
}


class SingleTransactionHandler(
    private val singleTransaction: ISingleTransaction
){
    suspend fun transactSingleState(
        basicIdentity: BasicIdentity,
        targetIsSingle: Boolean
    ): Boolean {
        if (basicIdentity.uid.contains(BOT_UID_PREFIX)) {
            AppLogger.d("transactIsNotSingle", "skipping bot is single transaction")
            return true
        }

        val userMapPath = userMapPath(
            basicIdentity.uid,
            basicIdentity.gender
        )
        return singleTransaction.transaction(
            path = userMapPath
        ) { currentData: TechnicalProfile? ->
            //  The Firebase SDKs will optimistically try null in transactions if it doesn't know what the actual value is
            if (currentData != null && currentData.isSingle == targetIsSingle) {
                currentData
            }else{
                TechnicalProfile(
                    isSingle = targetIsSingle,
                    age = basicIdentity.age,
                    gender = basicIdentity.gender
                )
            }
        }
    }
}