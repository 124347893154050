package osg.uiZone.common.baseRepos

import com.osg.truebase.data.LanguageSupport
import com.osg.truebase.data.nodeData.asString
import com.osg.truebase.data.nodeData.fromString
import com.osg.truebase.datastore.IDataStoreEngine
import com.osg.truebase.ui.datastore.EditProperty
import com.osg.truebase.ui.datastore.IBaseConfig
import com.osg.truebase.ui.datastore.IDataStore
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import kotlinx.serialization.Serializable


@Serializable
data class BaseConfig(
    override val language: LanguageSupport = LanguageSupport.ENGLISH,
    val seenSpotlights: Set<String> = emptySet()
) : IBaseConfig {
    constructor(baseConfig: IBaseConfig) : this(
        language = baseConfig.language,
        seenSpotlights = (baseConfig as? BaseConfig)?.seenSpotlights ?: emptySet()
    )
}

class BaseDataStore(
    private val dataStoreEngine: IDataStoreEngine
): IDataStore {
    override val settingsFlow: Flow<BaseConfig> = dataStoreEngine.settingsFlow.map {
        if (it.isEmpty()) {
            return@map BaseConfig()
        }
        val serializedSettings = it.asString()
        serializedSettings.fromString()
    }

    override suspend fun edit(editProperty: EditProperty) {
        dataStoreEngine.edit(
            editProperty.property,
            editProperty.value
        )
    }
}