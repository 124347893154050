package osg.uiZone.common.baseRepos.profile

import com.osg.appData.profile.PublicProfile
import com.osg.appData.profile.StageHighLight
import com.osg.def.database.IDownloadMedia
import com.osg.truebase.data.nodeData.IRemoteImageResource
import com.osg.truebase.data.nodeData.IRemoteProfileImage
import com.osg.ui.core.commonStates.TrueImage
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.async
import osg.uiZone.matchZone.journey.items.StageHighLightUi
import osg.uiZone.matchZone.model.LocalProfileImage
import osg.uiZone.matchZone.model.ProfileUi
import com.osg.coroutines.dispechers.ioDispatcher

suspend fun IDownloadMedia.toLocalImage(remoteProfileImage: IRemoteProfileImage): LocalProfileImage {
    return LocalProfileImage(
        remoteProfileImage = remoteProfileImage,
        trueImage = this.toLoadingImage(remoteProfileImage)
    )
}


suspend fun IDownloadMedia.toLoadingImage(remoteImageResource: IRemoteImageResource): TrueImage.Loading {
   val startedTask =  CoroutineScope(ioDispatcher()).async{
        downloadMedia(remoteImageResource)
    }

    return TrueImage.Loading {
        startedTask.await()
    }
}

suspend fun IDownloadMedia.fetchProfileUi(publicProfile: PublicProfile): ProfileUi {
    return ProfileUi(
        publicProfile = publicProfile,
        picturesCommentList = publicProfile.picturesCommentList.map { toLocalImage(it) },
        stageHighLights = publicProfile.stageHighLights.map { toStageHighLightUi(it) },
    )
}

suspend fun IDownloadMedia.toStageHighLightUi(
    stageHighLight: StageHighLight
): StageHighLightUi {
    return StageHighLightUi(
        media = toLoadingImage(stageHighLight.media),
        stageHighLight = stageHighLight
    )
}