package osg.uiZone.matchZone.entertainment.baseContent

import com.osg.appData.matchZone.ice.IcebreakerCollection
import com.osg.truebase.data.iceDef.IceTag
import com.osg.ui.core.commonStates.asTrueImage
import com.osg.ui.core.resources.uiText
import com.osg.utils.plus
import osg.uiZone.matchZone.entertainment.TMEntertainmentDataUiBase
import truematch.uizone.generated.resources.*
import uiZone.trueAutoResources.LocalImages

val checkListBase = TMEntertainmentDataUiBase(
    description = Res.string.check_list_description.uiText,
    image = LocalImages.CHECK_LIST.asTrueImage(),
    name = Res.string.check_list_name.uiText,
    
    unlockStage = 3,
    slogan = Res.string.check_list_slogan.uiText,
    vibes = IceTag.Memories + IceTag.Adventure,
    id = IcebreakerCollection.CheckList.name,
)