package osg.uiZone.search


import com.osg.appData.candidate.CandidateNode
import com.osg.appData.candidate.CandidateProfile
import com.osg.appData.candidate.CandidateZone
import com.osg.appData.common.DataRoot.Companion.interactRecordPath
import com.osg.appData.common.DataRoot.Companion.userCandidatesPath
import com.osg.appData.common.SettingsService
import com.osg.appData.common.UsersInteractRecord
import com.osg.appData.common.filterOnRecord
import com.osg.appData.functions.ITmMatchMakerCloudFunctions
import com.osg.def.database.KmpDatabaseException
import com.osg.truebase.data.logger.AppLogger
import com.osg.utils.time.TimeService.secDifferenceFromNow
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import osg.uiZone.common.baseRepos.database.ISearchCandidateHandlerDependencies


interface ISearchCandidateHandler {
    fun getCandidateFlow(
        myUid: String,
        chunkSize: Int
    ): Flow<List<CandidateProfile>>
}

class SearchCandidateHandler(
    private val searchRepoDep: ISearchCandidateHandlerDependencies,
    private val matchMakerCloudFunctions: ITmMatchMakerCloudFunctions
) : ISearchCandidateHandler {
    private val candidateNodeProcessor = CandidateNodeProcessorImpl(searchRepoDep)
    private lateinit var usersInteractRecord: UsersInteractRecord
    override fun getCandidateFlow(
        myUid: String,
        chunkSize: Int
    ): Flow<List<CandidateProfile>> = flow {
        while (true) {
            val unSeenCandidates = requestCandidatesNodes(myUid)
            if (unSeenCandidates.isEmpty()) {
                emit(emptyList())
            }
            unSeenCandidates.chunked(chunkSize).forEach { candidatesBatch ->
                val candidatesProfiles = candidateNodeProcessor.candidateNodeToProfile(candidatesBatch)
                emit(candidatesProfiles)
            }
        }
    }

    private suspend fun requestCandidatesNodes(
        myUid: String
    ): List<CandidateNode> {
        if (!::usersInteractRecord.isInitialized) {

            usersInteractRecord = try {
                searchRepoDep.fetchUsersInteractRecord(
                    path = interactRecordPath(myUid),
                )
            } catch (_: KmpDatabaseException.ValueNotFound) {
                UsersInteractRecord()
            }
        }

        val candidatesZone = fetchCandidateZone(myUid)
        val candidates = if (isMatcherTimeout(candidatesZone.requestTimeStamp)) {
            callAiMatcher(myUid).candidateNodes
        } else {
            candidatesZone.candidateNodes
        }

        val unSeenCandidates = candidates.filterOnRecord(usersInteractRecord) {
            it.uid
        }

        return unSeenCandidates
    }


    private suspend fun callAiMatcher(
        myUid: String
    ): CandidateZone {
        matchMakerCloudFunctions.findMeMatchRequest(myUid)
        return fetchCandidateZone(myUid)
    }

    private suspend fun fetchCandidateZone(
        myUid: String
    ): CandidateZone {
        val candidatePaths = userCandidatesPath(myUid)
        return try {
            searchRepoDep.fetchCandidatesZone(candidatePaths)
        } catch (_: KmpDatabaseException.ValueNotFound) {
            CandidateZone()
        }
    }

    private fun isMatcherTimeout(lastAiMatcherCall: Long): Boolean {
        val timeDifference = lastAiMatcherCall.secDifferenceFromNow()
        val isAbove =
            SettingsService.onlineSettings.matcherAiInterval < timeDifference
        AppLogger.d(
            this::class.simpleName!!,
            "isMatcherTimeout: $isAbove, timeDifference: $timeDifference"
        )
        return true
    }
}


