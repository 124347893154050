package com.osg.appData.common

import com.osg.appData.DicNodePath
import com.osg.appData.NodePath
import com.osg.appData.RootChild
import com.osg.appData.candidate.CandidateNode
import com.osg.appData.candidate.CandidateZone
import com.osg.appData.profile.FullProfile
import com.osg.appData.profile.Presence
import kotlinx.serialization.Serializable
import kotlin.reflect.KProperty1

@Serializable
data class UserData(
    val interactRecord: UsersInteractRecord = UsersInteractRecord(),
    val candidateRecord: Map<String, CandidateNode> = emptyMap(),
    val candidates: CandidateZone = CandidateZone(),
    val profile: FullProfile = FullProfile(),
    val presence: Presence = Presence(),
    val notificationRegData: NotificationRegData = NotificationRegData(),
){
    companion object{
        val userDataRoot: RootChild<Map<String, UserData>> = RootChild(DataRoot::usersData)
        fun userDataPath(uid: String): DicNodePath<UserData> {
            return DicNodePath(userDataRoot, uid)
        }
        fun <V> KProperty1<UserData, V>.childNode(uid: String): NodePath<V> {
            return NodePath(userDataPath(uid), this)
        }
    }
}