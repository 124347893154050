package osg.uiZone.matchZone.entertainment.spark

import androidx.compose.ui.graphics.Color
import com.osg.appData.matchZone.Eligibility
import com.osg.appData.matchZone.SparkNodeData
import com.osg.appData.matchZone.invitation.EntertainmentType
import com.osg.truebase.data.resources.ILocalAnimations
import com.osg.truebase.ui.paint.PreGradientBackground
import org.jetbrains.compose.resources.StringResource
import osg.uiZone.matchZone.entertainment.EntertainmentProperties
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.detail_header
import truematch.uizone.generated.resources.spark_feed_title
import truematch.uizone.generated.resources.spark_ingredients_title
import uiZone.trueAutoResources.LocalAnimations

object SparkEnt : EntertainmentProperties() {
    override val loadingAnimation: ILocalAnimations = LocalAnimations.ANIMATION_SPARK_LOADING

    override fun getType(): EntertainmentType {
       return EntertainmentType.SPARK
    }

    override fun getFeedTitleResource(): StringResource {
        return Res.string.spark_feed_title
    }

    override fun getIngredientTitleResource(): StringResource {
        return Res.string.spark_ingredients_title
    }

    override fun getGradientColors(): List<Color> {
        return PreGradientBackground.Spark.colors
    }



    override fun getDetailsTitleResource(): StringResource {
        return Res.string.detail_header
    }
}

fun SparkNodeData.resolveEligibility(
    balance: Double,
    relationshipStage: Int,
): Eligibility {
    return if (balance < value && relationshipStage < unlockStage) {
        Eligibility.NOT_MET_ANY_REQUIREMENT
    } else if (balance < value) {
        Eligibility.NO_ENOUGH_MONEY
    } else if (relationshipStage < unlockStage) {
        Eligibility.STAGE_NOT_MATCHED
    } else {
        Eligibility.YES
    }
}