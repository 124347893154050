package com.osg.utils

import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.emitAll
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.withTimeout
import kotlin.time.Duration
import kotlin.time.measureTime

suspend fun <T> Flow<T?>.firstNotNull(): T {
    return filterNotNull().first()
}

fun <T> dependencyFlow(block: suspend () -> Flow<T>): Flow<T> = flow {
    emitAll(block())
}

inline fun <T, R> Flow<T?>.startWithFirst(crossinline transformToFlow: (value: T) -> Flow<R>): Flow<R> = dependencyFlow {
    val firstValue = firstNotNull()
    return@dependencyFlow transformToFlow(firstValue)
}

suspend fun <T1, T2> wrapMultiThreadCallback(call: (callback: (T1, T2) -> Unit) -> Unit): Pair<T1, T2> {
    val completable = CompletableDeferred<Pair<T1, T2>>()
    val closure: (T1, T2) -> Unit = { t1, t2 -> completable.complete(t1 to t2) }
    call(closure)
    return completable.await()
}

suspend fun <A, B> Iterable<A>.pmap(f: suspend (A) -> B): List<B> = coroutineScope {
    map { async { f(it) } }.awaitAll()
}

suspend inline fun Duration.runMinBlockingTime(block: suspend () -> Unit) {
    val runTime = measureTime {
        block()
    }
    val sleepTime = this - runTime
    delay(sleepTime)
}

suspend fun <T> retrySuspend(
    timeoutMillis: Long = 5000,
    attempts: Int = 5,
    block: suspend CoroutineScope.() -> T
): T{
    repeat(attempts){
        try {
            return withTimeout(timeoutMillis) {
                block()
            }
        }catch (e: Exception){
            if (it == attempts) throw e
        }
    }

    throw Exception("retrySuspend timeoutMillis ${attempts * timeoutMillis} timeoutMillis")
}

// public suspend fun <T> withTimeout(timeMillis: Long, block: suspend CoroutineScope.() -> T): T {
