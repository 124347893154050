package com.osg.trumatch.bots.nonProd

import com.osg.appData.bots.BOT_UID_PREFIX
import com.osg.appData.profile.FullProfile
import com.osg.appData.register.RegisterProfileTechnicalElements
import com.osg.appData.register.ScDeepQuestions
import com.osg.truebase.data.logger.AppLogger
import com.osg.trumatch.bots.botProfiles.*


enum class Bots(fullProfile: FullProfile) {
    Yali(yaliProfile),
    OMER(omerProfile),
    MISHEL(mishelProfile),
    YEHUDA(yehudaProfile),
    MARSHALL(marshallProfile),
    TalMorad(talMoradProfile),
    YuvalShemla(yuvalShemla)
    ;
    var fullProfile: FullProfile

    init {
        this.fullProfile = fullProfile.copy(
            publicProfile = fullProfile.publicProfile.copy(
                uid = this.getBotUid(),
                stageHighLights = stageHighlightsList
            )
        )
        fullProfile.verifyBotFields()
        this.fullProfile = this.fullProfile.setupSimProfile()
    }

    fun getBotUid(): String{
        return "${BOT_UID_PREFIX}${this.name}"
    }

    private fun FullProfile.verifyBotFields(){
        RegisterProfileTechnicalElements.entries.forEach {
            if(it.isNotAnswered(this)){
                AppLogger.d("Bot ${this.publicProfile.uid} is missing ${it.name} field")
            }
        }
    }

    companion object {
        fun getBotByUid(uid: String): Bots {
            val botName = uid.removePrefix(BOT_UID_PREFIX)
            return valueOf(botName)
        }
    }
}

fun FullProfile.setupSimProfile() : FullProfile{
    val intNameRepresentation = publicProfile.firstName.map {
        it.code
    }
    return copy(
        personalProfile = personalProfile.copy(
            questionAnswer = ScDeepQuestions.entries.mapIndexed { index, q ->
                q.name to intNameRepresentation[index % intNameRepresentation.size] % 4
            }.toMap(),
        )
    )
}

