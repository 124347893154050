package osg.uiZone.matchZone.model

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.osg.appData.matchZone.invitation.EntertainmentType
import com.osg.truebase.ui.commonStates.MainViewUiState
import com.osg.truebase.ui.commonStates.toSuccess
import kotlinx.coroutines.flow.*
import osg.uiZone.common.vmRepo.matchZone.IMatchInfoRepoFlow
import osg.uiZone.common.vmRepo.matchZone.MatchScope
import osg.uiZone.matchZone.state.EntFeedData

interface IEntertainmentFeedRepo : IMatchInfoRepoFlow {
    fun entFeedFlow(type: EntertainmentType): Flow<EntFeedData>
}

class EntertainmentFeedViewModel(
    entertainmentType: EntertainmentType,
    entertainmentFeedRepo: IEntertainmentFeedRepo = MatchScope().matchZoneRepository,
) : ViewModel() {

    val feedState: StateFlow<MainViewUiState<EntFeedData>> =
        entertainmentFeedRepo.entFeedFlow(entertainmentType).map { feed ->
            feed.toSuccess()
        }.stateIn(
            scope = viewModelScope,
            started = SharingStarted.WhileSubscribed(),
            initialValue = MainViewUiState.Loading
        )

}