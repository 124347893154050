package com.osg.truebase.ui.assigners

import androidx.compose.material3.DatePicker
import androidx.compose.material3.DisplayMode
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.rememberDatePickerState
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalFocusManager
import com.osg.truebase.ui.assigners.def.GenObjAssigner
import com.osg.utils.time.TimeService.toDefaultFormat

/**
 * Data class representing a DateGenAssigner.
 *
 * @property isAnswerValid A lambda function that takes a selectedDateMillis value and returns a Boolean indicating if the answer is valid.
 */
data class DateGenAssigner(
    private val isAnswerValid: (Long) -> Boolean,
): GenObjAssigner<String> {
    @OptIn(ExperimentalMaterial3Api::class)
    @Composable
    override fun content(
        modifier: Modifier,
        current: String?,
        onAnswered: (String) -> Unit
    ) {
        val focusManager = LocalFocusManager.current
        val datePickerState = rememberDatePickerState(
            initialDisplayMode = DisplayMode.Picker
        )

        val selectedDate = datePickerState.selectedDateMillis
        selectedDate?.let {
            if (isAnswerValid(it)){
                val defaultFormat = selectedDate.toDefaultFormat()
                onAnswered(defaultFormat)
                focusManager.clearFocus()
            }
        }

        DatePicker(
            state = datePickerState,

        )
    }
}
