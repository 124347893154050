package com.osg.ui.core.imageProcces

import androidx.compose.ui.graphics.ImageBitmap
import androidx.compose.ui.graphics.painter.BitmapPainter


expect suspend fun ByteArray.toImageBitmap(): ImageBitmap
expect suspend fun ImageBitmap.scale(width: Int, height: Int): ImageBitmap
expect suspend fun ByteArray.compressJpeg(qualityPercentage: Int): ByteArray
suspend fun ByteArray.toPainter(): BitmapPainter {
    return BitmapPainter(toImageBitmap())
}

suspend fun ImageBitmap.scale(maxTargetSize: Int): ImageBitmap {
    val maxImageDimension = maxOf(width, height)
    if (maxTargetSize > maxImageDimension){
        return this
    }

    val scale =  maxTargetSize / maxImageDimension.toFloat()
    return scale((width * scale).toInt(), (height * scale).toInt())
}