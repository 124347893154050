package osg.uiZone.landing.model


import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.osg.appData.profile.FullProfile
import com.osg.appData.profile.PublicProfile
import com.osg.def.auth.LoggedStates
import com.osg.def.database.KmpDatabaseException
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.signin.ISignInProvider
import com.osg.truebase.signin.SignInResult
import com.osg.utils.ResourceState
import com.osg.utils.filterToSteady
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.launch
import osg.uiZone.common.vmRepo.ILandingRepository
import osg.uiZone.common.vmRepo.LandingRepositoryImpl
import osg.uiZone.common.vmRepo.appSignInData
import osg.uiZone.graph.entry.ds.NavigationAux
import osg.uiZone.navigation.Destination

sealed interface LandingStates

data class LandingUiState(
    val signInSignInProvider: List<ISignInProvider>
): LandingStates

data class LoadingDestination(
    val destination: Destination? = null
): LandingStates

class LandingModel(
    val landingRepo: ILandingRepository = LandingRepositoryImpl()
) : ViewModel() {
    private val navigationAux: NavigationAux = NavigationAux()
    val landingState: MutableStateFlow<LandingStates> = MutableStateFlow(
        LoadingDestination()
    )

    init {
        viewModelScope.launch {
            landingRepo.loggedStateFlow.collect {
                landingState.value = when(it){
                    LoggedStates.LoggedOut -> {
                         LandingUiState(landingRepo.buildSignInProviders(appSignInData))
                    }
                    LoggedStates.Loading -> LoadingDestination()
                    is LoggedStates.Loggedin -> {
                        val dst = fetchDetination(
                            SignInResult(
                                uid = it.uid,
                            )
                        )
                        LoadingDestination(dst)
                    }
                }
            }
        }
    }

    fun onSignInResult(signInResult: Result<SignInResult>) {
        viewModelScope.launch {
            landingState.value = LoadingDestination(fetchDetination(signInResult.getOrThrow()))
        }

    }

    private suspend fun fetchDetination(
        signInResult: SignInResult,
    ): Destination {
        val profileSteadyState = landingRepo.myProfileStateFlow.filterToSteady().first()
        val profile = when(profileSteadyState){
            is ResourceState.Success<FullProfile> -> {
                profileSteadyState.data
            }
            is ResourceState.Error if profileSteadyState.error is KmpDatabaseException.ValueNotFound-> {
                FullProfile(
                    publicProfile = PublicProfile(
                        uid = signInResult.uid,
                    )
                ).also {
                    landingRepo.saveMyFullProfile(it)
                }
            }
            is ResourceState.Error -> {
                throw profileSteadyState.error
            }
        }

        AppLogger.d(this::class.simpleName!!, "onLoggedIn")
        return navigationAux.profileScreenStage(profile)
    }

    override fun onCleared() {
        super.onCleared()
        AppLogger.d(this::class.simpleName!!, "LandingModel onCleared")
    }
}
