package com.osg.truebase.web

import androidx.compose.runtime.*
import androidx.compose.ui.platform.LocalFontFamilyResolver
import androidx.compose.ui.text.font.Font
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import com.osg.truebase.web.generated.resources.Res
import com.osg.truebase.web.generated.resources.arimo_variable_font_wght
import com.osg.truebase.web.generated.resources.noto_color_emoji
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.Font
import org.jetbrains.compose.resources.FontResource
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi


val hebrewFont: FontResource = Res.font.arimo_variable_font_wght

val emojiFont: FontResource = Res.font.noto_color_emoji

@OptIn(ExperimentalEncodingApi::class)
val defaultEmptyFont: Font? by lazy {
    androidx.compose.ui.text.platform.Font(
        "org.jetbrains.compose.emptyFont",
        Base64.decode(emptyFontBase64)
    )
}

@ExperimentalResourceApi
@Composable
fun preloadFont(
    resource: FontResource,
    weight: FontWeight = FontWeight.Normal,
    style: FontStyle = FontStyle.Normal
): State<Font?> {
    val resState = remember(resource, weight, style) { mutableStateOf<Font?>(null) }.apply {
        value = Font(resource, weight, style).takeIf { it != defaultEmptyFont }
    }
    return resState
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun PreLoadFallback(
    content: @Composable () -> Unit
) {
    val font1 = preloadFont(hebrewFont).value
    val emojiFont = preloadFont(emojiFont).value
    val fontFamilyResolver = LocalFontFamilyResolver.current
    var isPreloadComplete by remember { mutableStateOf(false) }
    LaunchedEffect(fontFamilyResolver, emojiFont, font1) {
        if (emojiFont != null && font1 != null) {
            // we have an emoji on Strings tab
            fontFamilyResolver.preload(FontFamily(font1))
            fontFamilyResolver.preload(FontFamily(emojiFont))
            isPreloadComplete = true
        }else{
            println("PreloadFallback: font1: $font1, emojiFont: $emojiFont")
        }
    }
    if (isPreloadComplete) {
        content()
    }
}
