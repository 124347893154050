package com.osg.truebase.ui.graphics.shader

import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.RenderEffect

const val COMPOSE_SHADER_CONTENT = "contents"
interface CommonRuntimeEffect {

    /** Sets a float array uniform for this shader */
    fun setFloatUniform(name: String, value1: Float) {}

    /** Sets a float array uniform for this shader */
    fun setFloatUniform(name: String, value1: Float, value2: Float) {}

    /** Sets a float array uniform for this shader */
    fun setFloatUniform(name: String, value1: Float, value2: Float, value3: Float) {}

    /** Sets a float array uniform for this shader */
    fun setFloatUniform(name: String, values: FloatArray) {}

    fun setColorUniform(name: String, color: Color)

    /** Builds an updates ShaderBrush*/
    val brush: Brush

    fun asRenderEffect(): RenderEffect
}

data class TrueShader(
    val shaderData: String,
){
    val runtimeEffect: CommonRuntimeEffect by lazy { buildEffect(this) }
}

expect fun buildEffect(shader: TrueShader): CommonRuntimeEffect