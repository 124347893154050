package com.osg.truebase.ui.locale

import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import com.osg.truebase.data.LanguageSupport
import com.osg.ui.core.commonStates.asTrueVector
import com.osg.truebase.ui.components.AutoComplete
import com.osg.truebase.ui.components.DropDownSearch
import com.osg.truebase.ui.components.DropDownSearchText
import com.osg.truebase.ui.generated.resources.Res
import com.osg.truebase.ui.generated.resources.baseline_language_24
import com.osg.truebase.ui.strings.CommonStringRes
import com.osg.truebase.ui_strings.generated.resources.settings_language
import com.osg.ui.core.resources.string

val LanguageSupport.string: String
    get() = when (this) {
        LanguageSupport.ENGLISH -> "English"
        LanguageSupport.HEBREW -> "עברית"
    }

val allOptions = LanguageSupport.entries.map { it.string }
@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun LocaleDropdownMenu(
    modifier: Modifier = Modifier,
    currentLanguage: LanguageSupport,
    onSelectLanguage: (LanguageSupport) -> Unit
) {
    var autocompleteValue by remember { mutableStateOf(currentLanguage.string) }
    AutoComplete(
        modifier = modifier,
        direction = CommonStringRes.settings_language.string,
        autocompleteOptions = allOptions.sortedByDescending { it.contains(autocompleteValue, ignoreCase = true) }.take(5),
        onValueChange = {
            autocompleteValue = it
        },
        onValueSelected = { stringValueSelected ->
            autocompleteValue = stringValueSelected
            val languageSupport = LanguageSupport.entries.first { it.string == stringValueSelected }
            onSelectLanguage(languageSupport)
        },
        autoCompleteValue = autocompleteValue
    )
}
@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun LocaleDropdownIcon(
    onSelectLanguage: (LanguageSupport) -> Unit
) {
    DropDownSearch(
        trueIconVector = Res.drawable.baseline_language_24.asTrueVector(),
        menuItemData = allOptions,
        onOptionSelected = { index ->
            val languageSupport = LanguageSupport.entries[index]
            onSelectLanguage(languageSupport)
        }
    )
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun LocaleDropdownText(
    currentLanguage: LanguageSupport,
    onSelectLanguage: (LanguageSupport) -> Unit
) {
    DropDownSearchText(
        buttonText = currentLanguage.string,
        menuItemData = allOptions,
        onOptionSelected = { index ->
            val languageSupport = LanguageSupport.entries[index]
            onSelectLanguage(languageSupport)
        }
    )
}