package osg.uiZone.search.components

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import com.osg.ui.core.resources.string
import com.osg.ui.core.commonStates.asPainter
import com.osg.truebase.ui.dialog.platformImagePicker
import com.osg.ui.core.resources.UiText
import com.osg.truebase.ui.theme.dimensions
import osg.uiZone.common.paint.ZoomableImage
import osg.uiZone.profile.EditImageCommand
import osg.uiZone.profile.IncentiveCardType
import osg.uiZone.profile.ItemToolOption


@Composable
fun ImageCommentCard(
    imageCommentCard: IncentiveCardType.ImageComment,
    modifier: Modifier = Modifier,
) {
    var itemToolOption by remember { mutableStateOf<ItemToolOption<EditImageCommand>?>(null) }
    Row(
        modifier = modifier,
        verticalAlignment = Alignment.Top
    ) {
        Image(
            painter = imageCommentCard.profileImage.asPainter(),
            contentDescription = null,
            contentScale = ContentScale.Crop,
            modifier = Modifier
                .padding(top = 4.dp)
                .size(MaterialTheme.dimensions.smallImageSize)
                .clip(CircleShape)
        )
        Spacer(modifier = Modifier.size(8.dp))
        Column(
            modifier = Modifier
        ) {
            TopCardView<EditImageCommand>(
                modifier = Modifier.fillMaxWidth(),
                title = imageCommentCard.candidateName,
                itemActionOptions = imageCommentCard.itemActionOptions,
                onEditClicked = {
                    itemToolOption = it
                }
            )
            Column(modifier = Modifier) {
                if (imageCommentCard.comment !is UiText.Empty) {
                    Text(
                        text = imageCommentCard.comment.string,
                    )
                }
                Spacer(modifier = Modifier.size(8.dp))
                ZoomableImage(
                    painter = imageCommentCard.truePicture.asPainter(),
                    modifier = Modifier
                        .fillMaxWidth()
                        .clip(RoundedCornerShape(16.dp)),
                    contentScale = ContentScale.FillWidth,
                )
                Spacer(modifier = Modifier.size(8.dp))
            }
        }
    }

    ItemImageToolDialog(imageCommentCard.idx ,itemToolOption){
        itemToolOption = null
    }
}


@Composable
fun ItemImageToolDialog(
    imageIdx: Int,
    itemToolOption: ItemToolOption<EditImageCommand>?,
    onRelease : () -> Unit
){
    if (itemToolOption == null) return
    when(itemToolOption){
        is ItemToolOption.Edit -> {
            platformImagePicker.ImagePicker{
                if (it != null){
                    itemToolOption.onCommit(
                        EditImageCommand.Edit(
                            idx=imageIdx,
                            imageBytes = it
                        )
                    )
                }
                onRelease()
            }
        }

        is ItemToolOption.Delete -> {
            LaunchedEffect(Unit){
                itemToolOption.onDelete(
                    imageIdx
                )
                onRelease()
            }
        }
    }
}