package osg.uiZone.expect.permission

import androidx.compose.material.icons.Icons
import androidx.compose.runtime.Immutable
import androidx.compose.ui.graphics.vector.ImageVector
import com.osg.ui.core.commonStates.TrueVector
import com.osg.ui.core.commonStates.asTrueVector
import org.jetbrains.compose.resources.StringResource
import osg.uiZone.matchZone.icons.ImageSearch
import osg.uiZone.matchZone.icons.LocationDisabled
import osg.uiZone.matchZone.icons.NotificationAdd
import truematch.uizone.generated.resources.*

enum class PermissionType{
    LOCATION,
    NOTIFICATIONS,
    GALLERY,
    Audio
}

@Immutable
enum class PermissionUiRationale(
    val permissionType: PermissionType,
    val title: StringResource,
    val message: StringResource,
    val icon: TrueVector,
    val isMandatory: Boolean,
) {
    LOCATION(
        PermissionType.LOCATION,
        Res.string.location_rationale_title,
        Res.string.location_rationale_message,
        Icons.Default.LocationDisabled.asTrueVector(),
        true
    ),
    NOTIFICATIONS_MATCH(
        PermissionType.NOTIFICATIONS,
        Res.string.notification_for_match_rationale_title,
        Res.string.notification_for_match_rationale_message,
        Icons.Default.NotificationAdd.asTrueVector(),
        false
    ),
    NOTIFICATIONS_MESSAGE(
        PermissionType.NOTIFICATIONS,
        Res.string.notification_for_message_rationale_title,
        Res.string.notification_for_message_rationale_message,
        Icons.Default.NotificationAdd.asTrueVector(),
        false
    ),
    GALLERY(
        PermissionType.GALLERY,
        Res.string.gallery_rationale_title,
        Res.string.gallery_rationale_message,
        Icons.Default.ImageSearch.asTrueVector(),
        true
    ),
}

enum class PermissionStage {
    Check,
    ShowRationale,
    Request,
    Force,
}