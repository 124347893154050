package osg.cross.landing.ds


import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.StringResource
import truematch.crossui.generated.resources.Res
import truematch.crossui.generated.resources.ai_slogan
import truematch.crossui.generated.resources.connect_slogan
import truematch.crossui.generated.resources.focus_slogan
import truematch.crossui.generated.resources.games_slogan
import truematch.crossui.generated.resources.slogan_ai
import truematch.crossui.generated.resources.slogan_ai_description
import truematch.crossui.generated.resources.slogan_connect
import truematch.crossui.generated.resources.slogan_connect_description
import truematch.crossui.generated.resources.slogan_focus
import truematch.crossui.generated.resources.slogan_focus_description
import truematch.crossui.generated.resources.slogan_games
import truematch.crossui.generated.resources.slogan_games_description
import truematch.crossui.generated.resources.slogan_tasty_cashback
import truematch.crossui.generated.resources.slogan_tasty_cashback_description
import truematch.crossui.generated.resources.tasty_cashback


enum class SloganContent(
    val title: StringResource,
    val description: StringResource,
    val image: DrawableResource,
){
    Connect(
        title = Res.string.slogan_connect,
        description = Res.string.slogan_connect_description,
        image = Res.drawable.connect_slogan,
    ),
    Ai(
        title = Res.string.slogan_ai,
        description = Res.string.slogan_ai_description,
        image = Res.drawable.ai_slogan
    ),
    Games(
        title = Res.string.slogan_games,
        description = Res.string.slogan_games_description,
        image = Res.drawable.games_slogan,
    ),
    TastyCashback(
        title = Res.string.slogan_tasty_cashback,
        description = Res.string.slogan_tasty_cashback_description,
        image = Res.drawable.tasty_cashback
    ),
    Focus(
        title = Res.string.slogan_focus,
        description = Res.string.slogan_focus_description,
        image = Res.drawable.focus_slogan,
    );

}