package com.osg.appData.common

import com.osg.appData.matchZone.EntertainmentStore
import com.osg.appData.profile.*
import com.osg.appData.utills.pathJoin
import com.osg.truebase.data.user.Gender
import com.osg.utils.buildPath
import kotlinx.serialization.Serializable

@Serializable
data class DataRoot(
    val userMap: Map<Gender, Map<String, TechnicalProfile>> = emptyMap(),
    val entertainmentStore: EntertainmentStore = EntertainmentStore(),
    val usersData: Map<String, UserData> = emptyMap(),
    val matchZonesRoot: MatchZonesRoot = MatchZonesRoot(),
    val waitingList: Map<String, Map<String, String>> = emptyMap(),
) {
    companion object {
        fun userDataPath(uid: String, vararg arg: String): String {
            val parts =
                listOf(
                    DataRoot::usersData.name,
                    uid
                ) + arg.toList()
            return parts.joinToString("/")
        }

        fun interactRecordPath(uid: String): String {
            return userDataPath(
                uid,
                UserData::interactRecord.name,
            )
        }


        fun reactionRecordPath(uid: String): String {
            return pathJoin(interactRecordPath(uid), UsersInteractRecord::seenCandidates.name)
        }

        fun reactionOnUserPath(uid: String, candidateUid: String): String {
            return pathJoin(interactRecordPath(uid), UsersInteractRecord::onUserReaction.name, candidateUid)
        }

        fun allReactionWritePath(uid: String, candidateUid: String): List<String> {
            return listOf(
                reactionRecordPath(uid) + "/$candidateUid",
                reactionOnUserPath(candidateUid, uid),
            )
        }

        fun unMatchAiPath(uid: String, candidateUid: String): String {
            return pathJoin(interactRecordPath(uid), UsersInteractRecord::unMatchAiConv.name, candidateUid)
        }

        fun userCandidateArchivePath(uid: String, candidateUid: String): String {
            return userDataPath(
                uid,
                UserData::candidateRecord.name,
                candidateUid
            )
        }

        fun userArchivePath(uid: String): String {
            return userDataPath(
                uid,
                UserData::candidateRecord.name
            )
        }

        fun candidateArchivePath(uid: String, candidateUid: String): String {
            return userDataPath(
                uid,
                UserData::candidateRecord.name,
                candidateUid
            )
        }

        fun fullProfilePath(uid: String): String {
            return userDataPath(
                uid,
                UserData::profile.name,
            )
        }

        fun publicProfilePath(uid: String): String {
            return userDataPath(
                uid,
                UserData::profile.name,
                FullProfile::publicProfile.name
            )
        }

        fun profileImagesPath(uid: String): String {
            return buildPath(
                publicProfilePath(uid),
                PublicProfile::picturesCommentList.name
            )
        }

        fun presencePath(uid: String): String {
            return userDataPath(
                uid,
                UserData::presence.name,
            )
        }

        fun regTokenPath(uid: String): String {
            return userDataPath(
                uid,
                UserData::notificationRegData.name,
            )
        }

        fun isOnlinePath(uid: String): String {
            return userDataPath(
                uid,
                UserData::presence.name,
                Presence::online.name
            )
        }

        fun matchZoneDataPaths(uid: String): String {
            return userDataPath(
                uid,
                UserData::profile.name,
                FullProfile::matchZoneData.name,
            )
        }

        fun seenPromoMatchPaths(uid: String): String {
            return userDataPath(
                uid,
                UserData::profile.name,
                FullProfile::matchZoneData.name,
                MatchZoneData::seenPromo.name
            )
        }


        fun userCandidatesPath(uid: String): String {
            return userDataPath(
                uid,
                UserData::candidates.name
            )
        }

        fun userMapPath(uid: String, gender: Gender): String {
            val parts =
                listOf(
                    DataRoot::userMap.name,
                    gender.name,
                    uid
                )
            return parts.joinToString("/")
        }

        fun userMapGenderPath(gender: Gender): String {
            val parts =
                listOf(
                    DataRoot::userMap.name,
                    gender.name
                )
            return parts.joinToString("/")
        }
    }
}