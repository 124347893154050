@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.osg.truebase.ui.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val baseline_female_24: DrawableResource by 
      lazy { init_baseline_female_24() }

  public val baseline_language_24: DrawableResource by 
      lazy { init_baseline_language_24() }

  public val baseline_male_24: DrawableResource by 
      lazy { init_baseline_male_24() }

  public val contact_mail: DrawableResource by 
      lazy { init_contact_mail() }

  public val event: DrawableResource by 
      lazy { init_event() }

  public val facebook_logo_white: DrawableResource by 
      lazy { init_facebook_logo_white() }

  public val google_color: DrawableResource by 
      lazy { init_google_color() }

  public val ic_ios: DrawableResource by 
      lazy { init_ic_ios() }

  public val policy: DrawableResource by 
      lazy { init_policy() }

  public val room_service: DrawableResource by 
      lazy { init_room_service() }

  public val vec_bug: DrawableResource by 
      lazy { init_vec_bug() }

  public val vec_location_off: DrawableResource by 
      lazy { init_vec_location_off() }

  public val vec_signal_off: DrawableResource by 
      lazy { init_vec_signal_off() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("baseline_female_24", CommonMainDrawable0.baseline_female_24)
  map.put("baseline_language_24", CommonMainDrawable0.baseline_language_24)
  map.put("baseline_male_24", CommonMainDrawable0.baseline_male_24)
  map.put("contact_mail", CommonMainDrawable0.contact_mail)
  map.put("event", CommonMainDrawable0.event)
  map.put("facebook_logo_white", CommonMainDrawable0.facebook_logo_white)
  map.put("google_color", CommonMainDrawable0.google_color)
  map.put("ic_ios", CommonMainDrawable0.ic_ios)
  map.put("policy", CommonMainDrawable0.policy)
  map.put("room_service", CommonMainDrawable0.room_service)
  map.put("vec_bug", CommonMainDrawable0.vec_bug)
  map.put("vec_location_off", CommonMainDrawable0.vec_location_off)
  map.put("vec_signal_off", CommonMainDrawable0.vec_signal_off)
}

internal val Res.drawable.baseline_female_24: DrawableResource
  get() = CommonMainDrawable0.baseline_female_24

private fun init_baseline_female_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_female_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui.generated.resources/drawable/baseline_female_24.xml", -1, -1),
    )
)

internal val Res.drawable.baseline_language_24: DrawableResource
  get() = CommonMainDrawable0.baseline_language_24

private fun init_baseline_language_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_language_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui.generated.resources/drawable/baseline_language_24.xml", -1, -1),
    )
)

internal val Res.drawable.baseline_male_24: DrawableResource
  get() = CommonMainDrawable0.baseline_male_24

private fun init_baseline_male_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_male_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui.generated.resources/drawable/baseline_male_24.xml", -1, -1),
    )
)

internal val Res.drawable.contact_mail: DrawableResource
  get() = CommonMainDrawable0.contact_mail

private fun init_contact_mail(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:contact_mail",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui.generated.resources/drawable/contact_mail.xml", -1, -1),
    )
)

internal val Res.drawable.event: DrawableResource
  get() = CommonMainDrawable0.event

private fun init_event(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:event",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui.generated.resources/drawable/event.xml", -1, -1),
    )
)

internal val Res.drawable.facebook_logo_white: DrawableResource
  get() = CommonMainDrawable0.facebook_logo_white

private fun init_facebook_logo_white(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:facebook_logo_white",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui.generated.resources/drawable/facebook_logo_white.xml", -1, -1),
    )
)

internal val Res.drawable.google_color: DrawableResource
  get() = CommonMainDrawable0.google_color

private fun init_google_color(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:google_color",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui.generated.resources/drawable/google_color.xml", -1, -1),
    )
)

internal val Res.drawable.ic_ios: DrawableResource
  get() = CommonMainDrawable0.ic_ios

private fun init_ic_ios(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_ios",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui.generated.resources/drawable/ic_ios.xml", -1, -1),
    )
)

internal val Res.drawable.policy: DrawableResource
  get() = CommonMainDrawable0.policy

private fun init_policy(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:policy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui.generated.resources/drawable/policy.xml", -1, -1),
    )
)

internal val Res.drawable.room_service: DrawableResource
  get() = CommonMainDrawable0.room_service

private fun init_room_service(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:room_service",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui.generated.resources/drawable/room_service.xml", -1, -1),
    )
)

internal val Res.drawable.vec_bug: DrawableResource
  get() = CommonMainDrawable0.vec_bug

private fun init_vec_bug(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:vec_bug",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui.generated.resources/drawable/vec_bug.xml", -1, -1),
    )
)

internal val Res.drawable.vec_location_off: DrawableResource
  get() = CommonMainDrawable0.vec_location_off

private fun init_vec_location_off(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:vec_location_off",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui.generated.resources/drawable/vec_location_off.xml", -1, -1),
    )
)

internal val Res.drawable.vec_signal_off: DrawableResource
  get() = CommonMainDrawable0.vec_signal_off

private fun init_vec_signal_off(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:vec_signal_off",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui.generated.resources/drawable/vec_signal_off.xml", -1, -1),
    )
)
