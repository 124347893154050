package com.osg.appData.common

import kotlinx.serialization.Serializable


@Serializable
data class OnlineSettingsData(
    val minAnsweredQuestions: Int = 5,
    val minPictures: Int = 3,
    val maxMatchADay: Int = 15,
    val daysStageRatio: Int = 3,
    val matcherAiInterval: Int = 20
)
