package com.osg.truebase.player.audio

import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.w3c.dom.Audio
import org.w3c.dom.events.Event
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi
import kotlin.time.Duration


class JsAudioPlayer(
    override val playDelay: Duration = Duration.ZERO
)  : IPlatformAudioPlayer {
    private val audioMap = emptyMap<String, ByteArray>().toMutableMap()
    val audio = Audio()
    var currentBounderyListener: ((Event) -> Unit)? = null
    @OptIn(ExperimentalEncodingApi::class, ExperimentalResourceApi::class)
    override suspend fun initialize(audioItems: List<TrueAudio>) {
        audioItems.forEach {
            audioMap[it.hashName] = it.readBytes()
        }

        setTrack(audioItems.first().hashName)
    }

    override fun play() {
        audio.play()
    }

    override fun pause() {
        audio.pause()
    }

    override fun stop() {
        audio.pause()
    }

    override fun seekTo(positionMs: Long) {
        audio.currentTime = positionMs.toDouble() / 1000
    }

    override suspend fun addMediaItem(audioMedia: TrueAudio) {
        audioMap[audioMedia.hashName] = audioMedia.readBytes()
        setTrack(audioMedia.hashName)
    }

    override fun setBoundary(endMs: Long, onBoundary: () -> Unit) {
        currentBounderyListener?.let {
            audio.removeEventListener("timeupdate", it)
        }

        currentBounderyListener = { event ->
            if (audio.currentTime * 1000 >= endMs) {
                stop()
                onBoundary()
            }
        }

        audio.addEventListener("timeupdate", currentBounderyListener)
    }

    override fun setVolume(volume: Float) {
        audio.volume = volume.toDouble()
    }

    @OptIn(ExperimentalEncodingApi::class)
    override fun setTrack(hashName: String) {
        audio.src = "data:audio/wav;base64," + Base64.encode(audioMap[hashName]!!)
    }

    override fun release() {

    }

}

