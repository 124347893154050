package osg.uiZone.profile

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.Edit
import androidx.compose.runtime.Immutable
import androidx.compose.ui.graphics.vector.ImageVector

@Immutable
sealed class ItemToolOption<out T>(
    val icon: ImageVector
) {
    @Immutable
    data class Edit<T>(
        val onCommit: (T) -> Unit,
    ) : ItemToolOption<T>(
        icon = Icons.Default.Edit
    )

    @Immutable
    data class Delete(
        val onDelete: (Int) -> Unit,
    ) : ItemToolOption<Nothing>(
        icon = Icons.Default.Delete
    )
}