package osg.uiZone.matchZone.icons
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.materialIcon
import androidx.compose.material.icons.materialPath
import androidx.compose.ui.graphics.vector.ImageVector

public val Icons.Filled.NotificationAdd: ImageVector
    get() {
        if (_notificationAdd != null) {
            return _notificationAdd!!
        }
        _notificationAdd = materialIcon(name = "Filled.NotificationAdd") {
            materialPath {
                moveTo(10.0f, 20.0f)
                horizontalLineToRelative(4.0f)
                curveToRelative(0.0f, 1.1f, -0.9f, 2.0f, -2.0f, 2.0f)
                reflectiveCurveTo(10.0f, 21.1f, 10.0f, 20.0f)
                close()
                moveTo(14.0f, 9.0f)
                curveToRelative(0.0f, 2.61f, 1.67f, 4.83f, 4.0f, 5.66f)
                lineTo(18.0f, 17.0f)
                horizontalLineToRelative(2.0f)
                verticalLineToRelative(2.0f)
                horizontalLineTo(4.0f)
                verticalLineToRelative(-2.0f)
                horizontalLineToRelative(2.0f)
                verticalLineToRelative(-7.0f)
                curveToRelative(0.0f, -2.79f, 1.91f, -5.14f, 4.5f, -5.8f)
                verticalLineTo(3.5f)
                curveTo(10.5f, 2.67f, 11.17f, 2.0f, 12.0f, 2.0f)
                reflectiveCurveToRelative(1.5f, 0.67f, 1.5f, 1.5f)
                verticalLineToRelative(0.7f)
                curveToRelative(0.71f, 0.18f, 1.36f, 0.49f, 1.95f, 0.9f)
                curveTo(14.54f, 6.14f, 14.0f, 7.51f, 14.0f, 9.0f)
                close()
                moveTo(24.0f, 8.0f)
                horizontalLineToRelative(-3.0f)
                verticalLineTo(5.0f)
                horizontalLineToRelative(-2.0f)
                verticalLineToRelative(3.0f)
                horizontalLineToRelative(-3.0f)
                verticalLineToRelative(2.0f)
                horizontalLineToRelative(3.0f)
                verticalLineToRelative(3.0f)
                horizontalLineToRelative(2.0f)
                verticalLineToRelative(-3.0f)
                horizontalLineToRelative(3.0f)
                verticalLineTo(8.0f)
                close()
            }
        }
        return _notificationAdd!!
    }

private var _notificationAdd: ImageVector? = null
