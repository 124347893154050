package com.osg.appData.matchZone.invitation

import kotlinx.serialization.Serializable


@Serializable
enum class EntertainmentType {
    GAME, SPARK
}

