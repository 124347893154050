package com.osg.truebase.spotlight

import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.FastOutSlowInEasing
import androidx.compose.animation.core.LinearOutSlowInEasing
import androidx.compose.animation.core.VectorConverter
import androidx.compose.animation.core.tween
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.Stable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Rect
import androidx.compose.ui.geometry.toRect
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.unit.IntSize
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.unit.center
import kotlinx.coroutines.launch

data class TutorialOverlayState(
    val step: ITutorialStepUi,
    val targetBounds: Rect,
)

@Composable
fun TutorialOverlay(
    screenSize: IntSize,
    tutorialOverlayState: TutorialOverlayState,
    onSeen: (ITutorialStep) -> Unit
) {
    val layoutDirection = LocalLayoutDirection.current
    val spotLightCenter = remember { Animatable(Offset.Zero, Offset.VectorConverter) }
    val spotLightRadius = remember { Animatable(1f) }
    val closestCornerOffset = remember { Animatable(Offset.Zero, Offset.VectorConverter) }
    val textSize = remember { Animatable(0f) }
    var cornerPointer by remember {
        mutableStateOf(Corner.TOP_LEFT)
    }
    var tutorialOverlayStepTarget by remember { mutableStateOf(tutorialOverlayState.step) }

    LaunchedEffect(tutorialOverlayState){
        textSize.animateTo(
            0f,
            animationSpec = tween(
                durationMillis = 100,
                easing = FastOutSlowInEasing
            )
        )

        launch {
            spotLightRadius.animateTo(
                tutorialOverlayState.targetBounds.maxDimension + 1,
                animationSpec = tween(
                    durationMillis = 850,
                    easing = FastOutSlowInEasing
                )
            )
        }

        spotLightCenter.animateTo(
            tutorialOverlayState.targetBounds.center,
            animationSpec = tween(
                durationMillis = 850,
                easing = LinearOutSlowInEasing
            )
        )
        tutorialOverlayStepTarget = tutorialOverlayState.step
        val closestCorner = tutorialOverlayState.targetBounds.closestCornerTo(screenSize.center.toOffset())
        cornerPointer = if (layoutDirection == LayoutDirection.Ltr) closestCorner.corner else closestCorner.corner.flip

        closestCornerOffset.snapTo(
            closestCorner.offset
        )

        textSize.animateTo(
            1f,
            animationSpec = tween(
                durationMillis = 250,
                easing = FastOutSlowInEasing
            )
        )
    }
    Box(
        modifier = Modifier.Companion
            .width(screenSize.width.pxToDp())
            .height(screenSize.height.pxToDp())
            .drawBehind {
                drawRect(
                    Brush.Companion.radialGradient(
                        listOf(Color.Companion.Transparent, Color.Companion.Black.copy(alpha = 0.5f)),
                        center = spotLightCenter.value,
                        radius = spotLightRadius.value,
                    )
                )
            }
    ) {



        TutorialCard(
            step = tutorialOverlayStepTarget,
            onSeen = onSeen,
            corner = cornerPointer,
            modifier = Modifier.graphicsLayer {
                val cardOffset = calculateTextOffset(
                    corner = cornerPointer,
                    closestCornerOffset = closestCornerOffset.value,
                    textRect = size.toRect(),
                    screenSize = screenSize,
                    layoutDirection = layoutDirection
                )

                scaleX = textSize.value
                scaleY = textSize.value
                alpha = textSize.value
                translationX = cardOffset.x
                translationY = cardOffset.y
            }
        )
    }
}

@Stable
@Composable
fun Int.pxToDp() = with(LocalDensity.current) { this@pxToDp.toDp() }


fun Rect.distanceTo(other: Rect): Float = (topLeft - other.topLeft).getDistance()

fun Rect.distanceTo(other: Offset): Float = (topLeft - other).getDistance()