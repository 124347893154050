package osg.uiZone.search.repo

import com.osg.appData.candidate.CandidateZone
import com.osg.appData.candidate.Reaction
import com.osg.appData.common.DataRoot
import com.osg.appData.common.UsersInteractRecord
import com.osg.appData.profile.MatchZoneData
import com.osg.appData.profile.PreferencesMatch
import com.osg.def.auth.IUidStatus
import com.osg.def.database.DatabaseService
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import org.koin.core.component.KoinScopeComponent
import org.koin.core.component.getScopeName
import org.koin.core.module.Module
import org.koin.core.module.dsl.scopedOf
import org.koin.core.scope.Scope
import org.koin.dsl.module
import org.koin.mp.KoinPlatform.getKoin
import osg.uiZone.common.baseRepos.database.IPublicProfileFetcher
import osg.uiZone.common.baseRepos.database.publicProfileFetcher
import osg.uiZone.common.baseRepos.profile.IMyProfileDependencies
import osg.uiZone.common.baseRepos.profile.MyProfileScope.Companion.myProfileHandler
import osg.uiZone.search.ISearchPreferencesRepo
import osg.uiZone.search.ISearchViewModelDependencies


val searchRepoModule: Module = module {
    scope<SearchScope>{
        scopedOf(::SearchRepository)
    }
}

class SearchScope: KoinScopeComponent{
    override val scope: Scope = getKoin().getOrCreateScope("SearchScope", getScopeName())
    val searchRepository: SearchRepository by scope.inject()

    companion object{
        fun closeSearchScope(){
            getKoin().getScopeOrNull("SearchScope")?.let {
                val searchRepository: SearchRepository by it.inject()
                searchRepository.close()
                it.close()
            }
        }
    }
}

interface ISearchRepo : ISearchPreferencesRepo, IUidStatus, ISearchViewModelDependencies


class SearchRepository(
    val databaseService: DatabaseService
) : ISearchRepo,
    IPublicProfileFetcher by databaseService.publicProfileFetcher,
    IMyProfileDependencies by myProfileHandler {

    val coroutineScope: CoroutineScope = CoroutineScope(Dispatchers.Default)
    val preferencePath: String by lazy {
        PreferencesMatch.preferenceMatchPath(getUid()!!)
    }

    override val prefrencesState: StateFlow<PreferencesMatch?> =
        databaseService.onValueChangeFlow<PreferencesMatch>(preferencePath).stateIn(
            coroutineScope,
            SharingStarted.WhileSubscribed(),
            null
        )

    override fun updatePrefrencesState(data: PreferencesMatch) {
        coroutineScope.launch {
            databaseService.setNode(preferencePath, data)
        }
    }

    fun close(){
        coroutineScope.coroutineContext.cancel()
    }

    override suspend fun fetchUsersInteractRecord(path: String): UsersInteractRecord {
        return databaseService.fetchObject(path)
    }

    override suspend fun fetchCandidatesZone(path: String): CandidateZone {
        return databaseService.fetchObject(path)
    }

    override fun getItsMatchFlow(): Flow<MatchZoneData> {
        val path = DataRoot.matchZoneDataPaths(getUid()!!)
        return databaseService.onValueChangeFlow<MatchZoneData>(path).filterNotNull()
    }

    override suspend fun setReaction(path: String, reaction: Reaction) {
        return databaseService.setNode(path, reaction)
    }

    override suspend fun fetchCandidateReaction(path: String): Reaction {
        return databaseService.fetchObject(path)
    }
}