@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package truematch.uizone.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringArrayResource

private object CommonMainArray0 {
  public val genders: StringArrayResource by 
      lazy { init_genders() }

  public val sc_q1_options: StringArrayResource by 
      lazy { init_sc_q1_options() }

  public val sc_q2_options: StringArrayResource by 
      lazy { init_sc_q2_options() }

  public val sc_q3_options: StringArrayResource by 
      lazy { init_sc_q3_options() }

  public val sc_q4_options: StringArrayResource by 
      lazy { init_sc_q4_options() }

  public val sc_q5_options: StringArrayResource by 
      lazy { init_sc_q5_options() }

  public val sc_q6_options: StringArrayResource by 
      lazy { init_sc_q6_options() }

  public val sc_q7_options: StringArrayResource by 
      lazy { init_sc_q7_options() }
}

@InternalResourceApi
internal fun _collectCommonMainArray0Resources(map: MutableMap<String, StringArrayResource>) {
  map.put("genders", CommonMainArray0.genders)
  map.put("sc_q1_options", CommonMainArray0.sc_q1_options)
  map.put("sc_q2_options", CommonMainArray0.sc_q2_options)
  map.put("sc_q3_options", CommonMainArray0.sc_q3_options)
  map.put("sc_q4_options", CommonMainArray0.sc_q4_options)
  map.put("sc_q5_options", CommonMainArray0.sc_q5_options)
  map.put("sc_q6_options", CommonMainArray0.sc_q6_options)
  map.put("sc_q7_options", CommonMainArray0.sc_q7_options)
}

internal val Res.array.genders: StringArrayResource
  get() = CommonMainArray0.genders

private fun init_genders(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:genders", "genders",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/register.commonMain.cvr", 10, 38),
    )
)

internal val Res.array.sc_q1_options: StringArrayResource
  get() = CommonMainArray0.sc_q1_options

private fun init_sc_q1_options(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:sc_q1_options", "sc_q1_options",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/sc_deep_questions.commonMain.cvr",
    10, 242),
    )
)

internal val Res.array.sc_q2_options: StringArrayResource
  get() = CommonMainArray0.sc_q2_options

private fun init_sc_q2_options(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:sc_q2_options", "sc_q2_options",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/sc_deep_questions.commonMain.cvr",
    253, 250),
    )
)

internal val Res.array.sc_q3_options: StringArrayResource
  get() = CommonMainArray0.sc_q3_options

private fun init_sc_q3_options(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:sc_q3_options", "sc_q3_options",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/sc_deep_questions.commonMain.cvr",
    504, 250),
    )
)

internal val Res.array.sc_q4_options: StringArrayResource
  get() = CommonMainArray0.sc_q4_options

private fun init_sc_q4_options(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:sc_q4_options", "sc_q4_options",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/sc_deep_questions.commonMain.cvr",
    755, 198),
    )
)

internal val Res.array.sc_q5_options: StringArrayResource
  get() = CommonMainArray0.sc_q5_options

private fun init_sc_q5_options(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:sc_q5_options", "sc_q5_options",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/sc_deep_questions.commonMain.cvr",
    954, 234),
    )
)

internal val Res.array.sc_q6_options: StringArrayResource
  get() = CommonMainArray0.sc_q6_options

private fun init_sc_q6_options(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:sc_q6_options", "sc_q6_options",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/sc_deep_questions.commonMain.cvr",
    1189, 262),
    )
)

internal val Res.array.sc_q7_options: StringArrayResource
  get() = CommonMainArray0.sc_q7_options

private fun init_sc_q7_options(): StringArrayResource =
    org.jetbrains.compose.resources.StringArrayResource(
  "string-array:sc_q7_options", "sc_q7_options",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.uizone.generated.resources/values/sc_deep_questions.commonMain.cvr",
    1452, 306),
    )
)
