package osg.uiZone.matchZone.entertainment.baseContent

import com.osg.appData.matchZone.ice.IcebreakerCollection
import com.osg.truebase.data.iceDef.IceTag
import com.osg.ui.core.commonStates.asTrueImage
import com.osg.ui.core.resources.uiText
import com.osg.utils.plus
import osg.uiZone.matchZone.entertainment.TMEntertainmentDataUiBase
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.swipe_escape_description
import truematch.uizone.generated.resources.swipe_escape_name
import truematch.uizone.generated.resources.swipe_escape_slogan
import uiZone.trueAutoResources.LocalImages

val swipeEscapeBase = TMEntertainmentDataUiBase(
    description = Res.string.swipe_escape_description.uiText,
    image = LocalImages.ESCAPE.asTrueImage(),
    name = Res.string.swipe_escape_name.uiText,
    
    unlockStage = 3,
    slogan = Res.string.swipe_escape_slogan.uiText,
    vibes = IceTag.ProblemSolving + IceTag.Mystery,
    id = IcebreakerCollection.SwipeEscape.name,
)