package com.osg.ui.core.resources

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Stable
import org.jetbrains.compose.resources.StringResource
import org.jetbrains.compose.resources.stringResource

@Stable
@Composable
fun StringResource.asFormattedText(vararg args: StringResource): String {
    val rawString = stringResource(this)
    val argsList = args.map { stringResource(it) }
    return rawString.format(argsList)
}

val StringResource.string: String
    @Stable
    @Composable
    get() = stringResource(this).escapeChars()

@Stable
@Composable
fun StringResource.asFormattedText(vararg args: String): String {
    val rawString = stringResource(this)
    return rawString.format(args.toList())
}

private val SimpleStringFormatRegex = Regex("""%(\d?)\$?[ds]""")

fun String.format(formatArgs: List<String>): String {
    val matchResults = SimpleStringFormatRegex.findAll(this)
    var newString = this
    matchResults.forEachIndexed { i, matchResult ->
        val res = matchResult.groupValues[1]
        val idx = if( res.isEmpty() ) i else res.toInt() - 1
        val arg = formatArgs[idx].toString()
        newString = newString.replaceFirst(matchResult.value, arg)
    }

    return newString.escapeChars()
}

fun String.escapeChars(): String {
    return this.replace("\\'", "'")
}

fun String?.isValidEmail(): Boolean {
    if (this.isNullOrBlank()) return false
    val emailRegex = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
    return emailRegex.toRegex().matches(this)
}