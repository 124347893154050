package com.osg.appData.candidate

import kotlinx.serialization.Serializable

@Serializable
data class Reaction (
    var decision: Decision = Decision.UNKNOWN,
    var fromUid: String = "",
    var toUid: String = "",
    var firstSightDuration: Long = 0,
)