package com.osg.appData.profile


import com.osg.appData.NodePath
import com.osg.appData.common.UserData
import com.osg.appData.common.UserData.Companion.childNode
import kotlinx.serialization.Serializable
import kotlin.reflect.KProperty1


@Serializable
data class FullProfile(
    var personalProfile: PersonalProfile = PersonalProfile(),
    var publicProfile: PublicProfile = PublicProfile(),
    val matchZoneData: MatchZoneData? = null,
    var watchedQuestionIntro: Boolean = false,
) {
    fun checkBirthDayBlank(): Boolean {
        return publicProfile.birthDay == null
    }

    fun resolvePreferencesAge(): ClosedFloatingPointRange<Float>? {
        val minAge = this.personalProfile.preferencesMatch.ageMin
        val maxAge = this.personalProfile.preferencesMatch.ageMax

        if (minAge == null || maxAge == null) {
            return null
        }
        return minAge.toFloat()..
                maxAge.toFloat()
    }

    companion object {
        fun <V> KProperty1<FullProfile, V>.childNode(uid: String): NodePath<V> {
            val profileNode = UserData::profile.childNode(uid)
            return NodePath(profileNode, this)
        }

    }
}