package osg.uiZone.matchZone.model

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.osg.appData.profile.IPublicProfile
import com.osg.truebase.data.nodeData.IRemoteProfileImage
import com.osg.truebase.ui.commonStates.MainViewUiState
import com.osg.ui.core.commonStates.TrueImage
import com.osg.ui.core.commonStates.asTrueImage
import com.osg.truebase.ui.dialog.ImageBytes
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.stateIn
import kotlinx.coroutines.launch
import osg.uiZone.common.vmRepo.matchZone.IMatchZoneChatRepo
import osg.uiZone.common.vmRepo.matchZone.MatchScope
import osg.uiZone.expect.media.RecordOperations
import osg.uiZone.matchZone.ChatZoneUiData
import osg.uiZone.matchZone.journey.items.StageHighLightUi
import osg.uiZone.matchZone.sparkMove.SparkMovesCollection

sealed interface EditImageType{
    val trueImage: TrueImage
}
data class NewImage(
    val imageBytes: ImageBytes,
) : EditImageType{
    override val trueImage: TrueImage by lazy {
        imageBytes.bytes.asTrueImage()
    }
}

class LocalProfileImage(
    remoteProfileImage: IRemoteProfileImage,
    override val trueImage: TrueImage,
) : EditImageType, IRemoteProfileImage by remoteProfileImage{
    companion object{
        fun IRemoteProfileImage.toLocalProfileImage(
            trueImage: TrueImage
        ): LocalProfileImage{
            return LocalProfileImage(
                remoteProfileImage = this,
                trueImage = trueImage
            )
        }
    }
}

class ProfileUi(
    publicProfile: IPublicProfile,
    override val picturesCommentList: List<LocalProfileImage>,
    override val stageHighLights: List<StageHighLightUi>,
) : IPublicProfile by publicProfile

data class MatchZoneProfiles(
    val myProfile: ProfileUi,
    val candidateProfile: ProfileUi,
)

class MatchZoneChatModel(
    internal val chatZoneRepo: IMatchZoneChatRepo = MatchScope().matchZoneRepository,
) : ViewModel() {
    val uiState: StateFlow<MainViewUiState<ChatZoneUiData>> = chatZoneRepo.conversationFlow().map {
        MainViewUiState.Success(
            ChatZoneUiData(
                conversation = it.messages,
                myUid = chatZoneRepo.onUid(),
            )
        )
    }.stateIn(
        scope = viewModelScope,
        started = SharingStarted.Eagerly,
        initialValue = MainViewUiState.Loading
    )

    fun sendMessage(message: String) {
        viewModelScope.launch {
            chatZoneRepo.sendMessage(message)
        }
    }

    fun onRecorderOps(recordOperations: RecordOperations) {
        TODO()
    }

    fun onSparkMoveClick(sparkMovesCollection: SparkMovesCollection) {
        TODO()
    }
}

