package common
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.outlined.MoreVert
import androidx.compose.material3.*
import androidx.compose.material3.adaptive.currentWindowAdaptiveInfo
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.BlendMode
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.adaptiveUi.isCompact
import com.osg.truebase.ui.button.OptionsButton
import com.osg.truebase.ui.ds.GeneralIconButtonItem
import com.osg.truebase.ui.ds.toItem
import com.osg.ui.core.commonStates.asTrueVector
import com.osg.ui.core.resources.string
import com.osg.ui.core.resources.uiText
import osg.cross.landing.ds.CommonResources
import osg.uiZone.common.settings.commonUrls
import osg.uiZone.matchZone.icons.Event
import truematch.web.generated.resources.Res
import truematch.web.generated.resources.join_waiting_list_button

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun WebBar(
    modifier: Modifier = Modifier,
    onWaitingListClick: () -> Unit
) {
    val barColors = TopAppBarDefaults.topAppBarColors().copy(
        containerColor = Color.Transparent,
        actionIconContentColor = Color.White,
    )
    CenterAlignedTopAppBar(
        modifier = modifier.padding(top = 8.dp),
        colors = barColors,
        title = {
            Image(
                modifier = Modifier.height(36.dp),
                painter = CommonResources.LogoText.painter,
                contentDescription = "logo",
                contentScale = ContentScale.FillHeight,
                colorFilter = ColorFilter.tint(Color.White.copy(
                    alpha = 0.5f
                ), BlendMode.SrcAtop)
            )
        },
        actions = {
            val uriHandler = LocalUriHandler.current
            val externalLinkActions = commonUrls.map {
                it.toItem{
                    uriHandler.openUri(it.url)
                }
            } + GeneralIconButtonItem(
                uiText = Res.string.join_waiting_list_button.uiText,
                trueVector = Icons.Default.Event.asTrueVector(),
                onClick = onWaitingListClick
            )

            val isCompact = currentWindowAdaptiveInfo().windowSizeClass.isCompact()
            if (isCompact.not()) {
                ActionButtons(
                    actions = externalLinkActions
                )
            } else {
                OptionsButton(
                    dropDownOptions = externalLinkActions,
                    imageVector = Icons.Outlined.MoreVert,
                )
            }
        }
    )
}


@Composable
fun ActionButtons(
    actions: List<GeneralIconButtonItem>
) {
    actions.forEach { action ->
        TextButton(
            modifier = Modifier,
            onClick = action.onClick,
            colors = ButtonDefaults.textButtonColors().copy(
                contentColor = LocalContentColor.current
            )
        ) {
            Text(
                text = action.uiText.string,
            )
        }
    }
}