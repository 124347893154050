package com.osg.truebase.data.user

import com.osg.truebase.data.nodeData.IRemoteImageResource
import com.osg.truebase.data.nodeData.IRemoteProfileImage
import com.osg.truebase.data.nodeData.RemoteImage
import kotlinx.serialization.Serializable

interface IPlayerDataEssential {
    val uid: String
    val name: String
    val profileImage: IRemoteImageResource?
}

@Serializable
data class PlayerDataEssential(
    override val uid: String,
    override val name: String,
    override val profileImage: IRemoteImageResource? = null,
): IPlayerDataEssential