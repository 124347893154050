
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import com.osg.appData.common.DataRoot
import com.osg.truebase.ui.adaptiveUi.DualAdaptiveLayout
import com.osg.truebase.ui.graphics.coloredRippleAnimationModifier
import com.osg.truebase.ui.strings.CommonStringRes
import com.osg.truebase.ui_strings.generated.resources.email_name
import com.osg.ui.core.resources.isValidEmail
import com.osg.ui.core.resources.string
import database.DatabaseClient
import kotlinx.coroutines.launch
import truematch.web.generated.resources.Res
import truematch.web.generated.resources.join_waiting_list
import truematch.web.generated.resources.join_waiting_list_description
import truematch.web.generated.resources.thank_you_join


sealed class WaitlistState{
    data object Expanded: WaitlistState()
    data object ThankYou: WaitlistState()
}

@Composable
fun WaitlistPop(
    onDismissRequest: () -> Unit,
){
    Dialog(
        onDismissRequest = onDismissRequest
    ) {
        var waitlistState by remember {
            mutableStateOf<WaitlistState>(WaitlistState.Expanded)
        }
        val coroutineScope = rememberCoroutineScope()
        WaitlistView(
            waitlistState = waitlistState,
            onSendMailClicked = { mail ->
                val mailNode = mapOf(
                    "email" to mail
                )
                coroutineScope.launch {
                    DatabaseClient.push(
                        path = DataRoot::waitingList.name,
                        body = mailNode
                    )
                    waitlistState = WaitlistState.ThankYou
                }
            },
        )
    }
}

@Composable
fun WaitlistView(
    waitlistState: WaitlistState,
    onSendMailClicked: (String) -> Unit,
) {
    when(waitlistState){
        is WaitlistState.Expanded -> {
            WaitlistContent(
                onSendMailClicked = onSendMailClicked,
            )
        }
        is WaitlistState.ThankYou -> {
            ThankYou()
        }
    }
}


@Composable
fun ThankYou(
    modifier: Modifier = Modifier.Companion
) {
    Box(
        modifier = modifier.clip(shape = androidx.compose.foundation.shape.RoundedCornerShape(16.dp))
            .background(color = Color.Companion.Green.copy(alpha = 0.4f))
            .padding(16.dp),
        contentAlignment = Alignment.Companion.Center,
    ) {
        Text(
            Res.string.thank_you_join.string + " 🤩",
            style = MaterialTheme.typography.headlineMedium,
            color = Color.Companion.White
        )
    }
}

@Composable
fun WaitlistContent(
    onSendMailClicked: (String) -> Unit,
) {
    Column(
        modifier = Modifier.Companion.clip(
            shape = RoundedCornerShape(16.dp)
        ).background(
                color = Color.Black.copy(alpha = 0.3f),
            ).padding(16.dp),
        horizontalAlignment = Alignment.Companion.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(16.dp)
    ) {
        WaitlistTitle()
        WaitlistUserInput(
            onClickSent = onSendMailClicked
        )
    }
}

@Composable
fun WaitlistTitle(
    modifier: Modifier = Modifier
){
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        Text(
            text = Res.string.join_waiting_list.string,
            color = Color.Companion.White,
            style = MaterialTheme.typography.headlineMedium,
        )
        Text(
            text = Res.string.join_waiting_list_description.string,
            color = Color.Companion.White.copy(alpha = 0.75f),
            style = MaterialTheme.typography.bodyMedium,
            textAlign = TextAlign.Companion.Center,
        )
    }
}

@Composable
fun WaitlistUserInput(
    onClickSent: (String) -> Unit,
){
    val focusManager = LocalFocusManager.current
    var text by remember { mutableStateOf("") }
    val isEmailValid = text.isValidEmail()
    DualAdaptiveLayout(
        spacer = {
            Spacer(modifier = Modifier.Companion.size(16.dp))
        },
        firstComposable = {
            val mailString = CommonStringRes.email_name.string
            TextField(
                value = text,
                onValueChange = {
                    text = it
                },
                label = {
                    val text = if (isEmailValid || text.isEmpty()) {
                        mailString
                    } else {
                        "${mailString}*"
                    }
                    Text(text)
                },
                modifier = Modifier.Companion,
                keyboardOptions = KeyboardOptions.Companion.Default.copy(
                    keyboardType = KeyboardType.Companion.Email,
                    imeAction = ImeAction.Companion.Done
                ),
                keyboardActions = KeyboardActions(
                    onDone = {
                        focusManager.clearFocus()
                    }
                ),
                shape = RoundedCornerShape(35.dp),
                singleLine = true,
                isError = isEmailValid.not() && text.isNotEmpty(),
                colors = TextFieldDefaults.colors().copy(
                    focusedIndicatorColor = Color.Companion.Transparent,
                    unfocusedIndicatorColor = Color.Companion.Transparent,
                    errorIndicatorColor = Color.Companion.Transparent,
                )
            )
        },
        secondComposable = {
            var isSendClicked by remember { mutableStateOf(false) }
            Button(
                onClick = {
                    isSendClicked = true
                    onClickSent(text)
                },
                modifier = Modifier.Companion.height(56.dp)
                    .coloredRippleAnimationModifier(
                        start = isSendClicked,
                        expandFactor = 2f,
                    ),
                colors = ButtonDefaults.buttonColors().copy(
                    disabledContainerColor = Color.Companion.White.copy(alpha = 0.4f),
                    containerColor = if (isSendClicked) Color.Companion.Transparent else Color.Companion.White,
                    contentColor = Color.Companion.Black
                ),
                enabled = isSendClicked.not() && isEmailValid,
                shape = RoundedCornerShape(35.dp)
            ) {
                Text(Res.string.join_waiting_list.string)
            }
        }
    )
}



