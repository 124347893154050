package osg.uiZone.common.graphic


import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.tween
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.LinearGradientShader
import androidx.compose.ui.graphics.Shader
import androidx.compose.ui.graphics.ShaderBrush
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

/**
 * A composable function that displays a gradient progress bar.
 *
 * @param currentValue The current value of the progress.
 * @param initialValue The initial value of the progress to start animation from (mostly for preview).
 * @param modifier The modifier to be applied to the progress bar.
 * @param maxValue The maximum value of the progress.
 * @param indicatorColor The list of colors for the gradient indicator.
 * @param trackColor The color of the progress bar track.
 * @param radius The corner radius of the progress bar.
 * @param height The height of the progress bar.
 * @param animationDuration The duration of the progress animation in milliseconds.
 */
@Composable
fun GradientProgressBar(
    currentValue: Float,
    initialValue: Float = 0f,
    modifier: Modifier = Modifier,
    maxValue: Float = 100f,
    indicatorColor: List<Color> = listOf(
        MaterialTheme.colorScheme.secondary,
        MaterialTheme.colorScheme.primary,
    ),
    trackColor: Color = Color.Transparent,
    radius: Dp = 16.dp,
    height: Dp = 16.dp,
    animationDuration: Int = 3000,
) {
    val initialProgress = initialValue / maxValue
    val progress = remember { Animatable(initialProgress) }
    var gSize by remember { mutableStateOf(0f) }
    LaunchedEffect(currentValue) {
        progress.animateTo(
            currentValue / maxValue,
            animationSpec = tween(animationDuration),
        )
    }

    val customBrush = remember(gSize) {
        object : ShaderBrush() {
            override fun createShader(size: Size): Shader {
                return LinearGradientShader(
                    colors = indicatorColor,
                    from = Offset.Zero,
                    to = Offset(gSize, 0f),
                )
            }
        }
    }

    Box(
        contentAlignment = Alignment.CenterStart,
        modifier = modifier
            .fillMaxWidth()
            .height(height)
            .clip(RoundedCornerShape(radius))
            .background(color = trackColor)
            .onGloballyPositioned {
                gSize = it.size.width.toFloat()
            }
    ) {
        Box(
            modifier = Modifier
                .fillMaxWidth(progress.value)
                .height(height)
                .clip(RoundedCornerShape(radius))
                .background(customBrush)
        )
    }
}