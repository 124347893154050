package osg.uiZone.graph.entry.ds

import com.osg.appData.profile.FullProfile
import com.osg.appData.profile.UserStage
import osg.uiZone.navigation.*

class NavigationAux(){
    fun profileScreenStage(
        profile: FullProfile,
    ): Destination {
        if (profile.publicProfile.uid.isEmpty()) {
            return LandingDestination
        }

        return when (profile.publicProfile.stage) {
            UserStage.NEW -> {
                RegisterDestinations.RegisterScreen
            }

            UserStage.REGISTERED -> {
                val profilePath = profile.matchZoneData
                if (profilePath == null) {
                    SearchDestination.SearchMain
                } else {
                    if (profile.matchZoneData!!.seenPromo) {
                        SharedJourney
                    } else {
                        MatchZonePromo
                    }
                }
            }

            UserStage.NONE -> {
                throw IllegalStateException("UserStage is NONE")
            }
        }
    }
}
