package com.osg.truebase.ui.graphics.konfetti

import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Paint
import androidx.compose.ui.graphics.drawscope.DrawScope
import androidx.compose.ui.graphics.drawscope.drawIntoCanvas
import com.osg.truebase.ui.graphics.konfetti.models.Shape
import com.osg.truebase.ui.graphics.konfetti.models.Shape.Circle
import com.osg.truebase.ui.graphics.konfetti.models.Shape.DrawableShape
import com.osg.truebase.ui.graphics.konfetti.models.Shape.Rectangle
import com.osg.truebase.ui.graphics.konfetti.models.Shape.Square

/**
 * Draw a shape to `compose canvas`. Implementations are expected to draw within a square of size
 * `size` and must vertically/horizontally center their asset if it does not have an equal width
 * and height.
 */
fun Shape.draw(drawScope: DrawScope, particle: Particle) {
    when (this) {
        Circle -> {
            val offsetMiddle = particle.width / 2
            drawScope.drawCircle(
                color = Color(particle.color),
                center = Offset(particle.x + offsetMiddle, particle.y + offsetMiddle),
                radius = particle.width / 2
            )
        }
        Square -> {
            drawScope.drawRect(
                color = Color(particle.color),
                topLeft = Offset(particle.x, particle.y),
                size = Size(particle.width, particle.height),
            )
        }
        is Rectangle -> {
            val size = particle.width
            val height = size * heightRatio
            drawScope.drawRect(
                color = Color(particle.color),
                topLeft = Offset(particle.x, particle.y),
                size = Size(size, height)
            )
        }
        is DrawableShape -> {
            drawScope.drawIntoCanvas {canvas ->
                canvas.drawImage(drawable, Offset(particle.x, particle.y), Paint())
            }
        }
    }
}
