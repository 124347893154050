package osg.uiZone.landing.ui

import androidx.compose.animation.animateContentSize
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.pager.HorizontalPager
import androidx.compose.foundation.pager.rememberPagerState
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import com.osg.truebase.signin.SignInResult
import com.osg.truebase.ui.intrinsic.pxToDp
import com.osg.truebase.ui.signIn.SignInControllers
import com.osg.truebase.ui.theme.spacing
import com.osg.ui.core.resources.painter
import com.osg.ui.core.resources.string
import osg.cross.landing.ds.CommonResources
import osg.cross.landing.ds.SloganContent
import osg.uiZone.common.pyrotechnics.pager.SlidingIndicator
import osg.uiZone.landing.model.LandingUiState

@Composable
fun LandingScreen(
    uiState: LandingUiState,
    initialPage: Int = 0,
    modifier: Modifier = Modifier,
    onSignInResult: (Result<SignInResult>) -> Unit
) {
    val sloganContents = SloganContent.entries
    val pagerState = rememberPagerState(
        initialPage = initialPage,
        pageCount = {
            sloganContents.size
        }
    )
    var textOffset by remember { mutableStateOf(0) }
    Box(
        modifier = modifier.fillMaxSize()
    ) {
        HorizontalPager(
            state = pagerState,
        ) { page ->
            SloganCard(
                page = page,
            ) {
                val isLastPage = pagerState.currentPage == pagerState.pageCount - 1
                if (isLastPage) {
                    SignInControllers(
                        modifier = Modifier
                            .padding(bottom = MaterialTheme.spacing.medium)
                            .navigationBarsPadding(),
                        signInProviders = uiState.signInSignInProvider,
                        onComplete = onSignInResult
                    )
                }
                Spacer(
                    modifier = Modifier
                        .size(textOffset.pxToDp())
                )
            }
        }

        Image(
            modifier = Modifier.fillMaxWidth()
                .statusBarsPadding()
                .padding(top = MaterialTheme.spacing.medium)
                .padding(horizontal = MaterialTheme.spacing.medium),
            painter = CommonResources.LogoText.painter,
            contentDescription = "logo",
            contentScale = ContentScale.FillHeight,
        )
        SlidingIndicator(
            modifier = Modifier
                .align(Alignment.BottomCenter)
                .padding(bottom = MaterialTheme.spacing.medium)
                .navigationBarsPadding()
                .onGloballyPositioned {
                    textOffset = -it.size.height
                },
            totalPages = pagerState.pageCount,
            pageFraction = {
                pagerState.currentPage + pagerState.currentPageOffsetFraction
            },
        )
    }
}

@Composable
fun SloganCard(
    page: Int,
    content: @Composable () -> Unit
) {
    val sloganContents = SloganContent.entries
    val sloganContent = sloganContents[page]
    val gradient = Brush.verticalGradient(
        colorStops = arrayOf(
            .0f to Color.Black.copy(alpha = .3f),
            .45f to Color.Transparent,
            .7f to Color.Black.copy(alpha = .6f),
            1.0f to Color.Black.copy(alpha = .4f),
        )
    )
    val title = sloganContent.title.string

    Box(
        Modifier
            .fillMaxSize(),
        contentAlignment = Alignment.BottomCenter
    ) {
        Image(
            painter = sloganContent.image.painter,
            contentDescription = title,
            contentScale = ContentScale.Crop,
            modifier = Modifier.fillMaxSize()
        )
        Box(
            modifier = Modifier
                .fillMaxSize()
                .background(gradient)
        )
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .align(Alignment.BottomCenter)
                .padding(horizontal = MaterialTheme.spacing.small)
                .padding(bottom = MaterialTheme.spacing.large)
                .navigationBarsPadding()
                .animateContentSize(),

            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.medium)
        ) {
            SloganText(
                modifier = Modifier,
                title = sloganContent.title.string,
                description = sloganContent.description.string
            )
            content()
        }
    }
}

@Composable
fun SloganText(
    title: String,
    description: String,
    modifier: Modifier = Modifier
) {
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small)
    ) {
        Text(
            text = title,
            fontWeight = FontWeight.Bold,
            style = MaterialTheme.typography.headlineSmall,
            textAlign = TextAlign.Center,
            color = Color.White
        )
        Text(
            text = description,
            style = MaterialTheme.typography.bodyLarge,
            color = Color.White,
            textAlign = TextAlign.Center,
        )
    }
}