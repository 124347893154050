package challenge.trueAutoResources


enum class IceMediaLocalChallengeImages(override val mediaPath: String): com.osg.truebase.data.resources.ILocalImages {
	SHIRT_ANIM("composeResources/com.osg.truebase.challenge.generated.resources/files/animations/shirt_anim.webp");
}
enum class IceMediaLocalChallengeAnimations(override val mediaPath: String): com.osg.truebase.data.resources.ILocalAnimations {
	BOT_ANIM("composeResources/com.osg.truebase.challenge.generated.resources/files/animations/bot_anim.json"),
	IMAGE_ANIM("composeResources/com.osg.truebase.challenge.generated.resources/files/animations/image_anim.json"),
	DIZZY_FACE_ANIM("composeResources/com.osg.truebase.challenge.generated.resources/files/animations/dizzy_face_anim.json"),
	TIME_ANIM("composeResources/com.osg.truebase.challenge.generated.resources/files/animations/time_anim.json");
}
enum class IceMediaLocalChallengeLottie(override val mediaPath: String): com.osg.truebase.data.resources.ILocalAnimations {
	OPTIMIZED_FOOD_ANIM("composeResources/com.osg.truebase.challenge.generated.resources/files/animations/optimized_food_anim.lottie"),
	OPTIMIZED_ANIM_WORLD("composeResources/com.osg.truebase.challenge.generated.resources/files/animations/optimized_anim_world.lottie");
}
enum class IceMediaLocalChallengeDatabase(override val mediaPath: String): com.osg.truebase.data.resources.ILocalMediaResource {
	CHALLENGE_ACCEPTED_HEBREW("composeResources/com.osg.truebase.challenge.generated.resources/files/data/challenge_accepted_HEBREW.db"),
	CHALLENGE_ACCEPTED_ENGLISH("composeResources/com.osg.truebase.challenge.generated.resources/files/data/challenge_accepted_ENGLISH.db");
}

val IceMediaLocalChallengeAllMedia: List<com.osg.truebase.data.resources.ILocalMediaResource> = IceMediaLocalChallengeImages.entries +
	IceMediaLocalChallengeAnimations.entries +
	IceMediaLocalChallengeLottie.entries +
	IceMediaLocalChallengeDatabase.entries