package com.osg.appData.common


enum class NotificationType(
    val title: String,
    val body: String
) {
    MATCH(
        title = "Match 💘",
        body = "You have a new match!"
    ),
    MESSAGE(
        title = "Message 💌",
        body = "You have a new message "
    ),
    INVITATION(
        title = "Invitation 🎁",
        body = "You have a new invitation!"
    ),
}