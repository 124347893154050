package com.osg.truebase.ui.annotatedText

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.withStyle
import org.intellij.markdown.MarkdownElementTypes
import org.intellij.markdown.ast.ASTNode
import org.intellij.markdown.ast.getTextInNode
import org.intellij.markdown.flavours.commonmark.CommonMarkFlavourDescriptor
import org.intellij.markdown.parser.MarkdownParser


fun markdownStringToAnnotated(text: String, basedSpanStyle: SpanStyle): AnnotatedString {
    val markdown: ASTNode = MarkdownParser(CommonMarkFlavourDescriptor()).parse(
        MarkdownElementTypes.MARKDOWN_FILE,
        text,
        true
    )

    val builder = AnnotatedString.Builder()
    builder.appendMarkdown(
        markdownText = text,
        node = markdown,
        basedSpanStyle = basedSpanStyle,
    )

    return builder.toAnnotatedString()
}


fun AnnotatedString.Builder.appendMarkdown(
    markdownText: String,
    node: ASTNode,
    basedSpanStyle: SpanStyle,
    depth: Int = 0,
): AnnotatedString.Builder {
    when (node.type) {
        MarkdownElementTypes.MARKDOWN_FILE, MarkdownElementTypes.PARAGRAPH -> {
            node.children.forEach { childNode ->
                appendMarkdown(
                    markdownText = markdownText,
                    node = childNode,
                    basedSpanStyle = basedSpanStyle,
                    depth = depth + 1,
                )
            }
        }

        MarkdownElementTypes.SETEXT_1, MarkdownElementTypes.ATX_1 -> {
            withStyle(basedSpanStyle.copy(
                fontSize = basedSpanStyle.fontSize * 1.4f,
            )) {
                node.children.subList(1, node.children.size).forEach { childNode ->
                    append(
                        text = childNode.getTextInNode(markdownText).toString()
                    )
                }
            }
        }

        MarkdownElementTypes.SETEXT_2, MarkdownElementTypes.ATX_2 -> {
            withStyle(basedSpanStyle.copy(
                fontSize = basedSpanStyle.fontSize * 1.2f,
            )) {
                node.children.subList(1, node.children.size).forEach { childNode ->
                    append(
                        text = childNode.getTextInNode(markdownText).toString()
                    )
                }
            }
        }

        MarkdownElementTypes.CODE_SPAN -> {
            withStyle(basedSpanStyle.copy(background = Color.LightGray)) {
                node.children.subList(1, node.children.size - 1).forEach { childNode ->
                    append(
                        text = childNode.getTextInNode(markdownText).toString()
                    )
                }
            }
        }

        MarkdownElementTypes.STRONG -> {
            withStyle(basedSpanStyle.copy(
                fontWeight = FontWeight.Black,
                color = basedSpanStyle.color.copy(alpha = 1f),
            )) {
                node.children.drop(2).dropLast(2).forEach { childNode ->
                    append(
                        text = childNode.getTextInNode(markdownText).toString()
                    )
                }
            }
        }

        MarkdownElementTypes.EMPH -> {
            withStyle(basedSpanStyle.copy(fontStyle = FontStyle.Italic)) {
                node.children.drop(1).dropLast(1).forEach { childNode ->
                    append(
                        text = childNode.getTextInNode(markdownText).toString()
                    )
                }
            }
        }

        MarkdownElementTypes.CODE_FENCE -> {
            withStyle(basedSpanStyle.copy(background = Color.Gray)) {
                node.children.drop(1).dropLast(1).forEach { childNode ->
                    append(
                        text = childNode.getTextInNode(markdownText).toString()
                    )
                }
            }
        }

        else -> {
            withStyle(basedSpanStyle) {
                append(
                    text = node.getTextInNode(markdownText).toString()
                )
            }
        }
    }
    return this
}
