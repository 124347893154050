package com.osg.ui.core.resources

import androidx.compose.runtime.Composable
import org.jetbrains.compose.resources.stringArrayResource

@Composable
fun UiArray.asArray(): List<String> {
    return when (this) {
        is UiArray.ArrayValue -> {
            array
        }

        is UiArray.ArrayResource -> {
            stringArrayResource(resourceId)
        }

        is UiArray.StringResourceArray -> {
            array.map {
                it.string
            }
        }
        is UiArray.Empty -> {
            emptyList()
        }
    }
}