package com.osg.previewLab.database

import com.osg.def.database.*
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.nodeData.QueryType
import com.osg.truebase.data.nodeData.TransactionTarget
import com.osg.truebase.database.events.EventHandler
import com.osg.truebase.database.events.NativeEvent
import com.osg.truebase.database.events.extractParams
import com.osg.truebase.serial.findJsonElementByPath
import com.osg.truebase.serial.replaceDeepChild
import com.osg.utils.*
import kotlinx.coroutines.*
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.flow
import kotlinx.serialization.json.*


class SimDataBaseEngine(
    private val delayMs: Long = 0L,
    private val eventsListLoader: () -> List<EventHandler> = { emptyList() },
    private val simInitializer: suspend () -> JsonElement = { JsonNull },
) : IDatabaseEngine {
    private val data = MutableStateFlow<ResourceState<JsonElement>>(ResourceState.Loading)
    private val eventsList by  lazy {
        eventsListLoader()
    }

    init {
        CoroutineScope(Dispatchers.Default).launch {
            data.value = ResourceState.Success(simInitializer())
        }
    }

    override suspend fun setNode(path: String, serializedValue: String) {
        delay(delayMs)
        data.replaceDeepChild(path, Json.parseToJsonElement(serializedValue)).toResourceSucces()
    }

    override suspend fun setPrimitiveNode(path: String, value: Any) {
        delay(delayMs)
        val primitiveValue = when (value) {
            is String -> JsonPrimitive(value)
            is Int -> JsonPrimitive(value)
            is Double -> JsonPrimitive(value)
            is Boolean -> JsonPrimitive(value)
            is Long -> JsonPrimitive(value)
            else -> throw Exception("Invalid primitive type")
        }
        data.replaceDeepChild(path, primitiveValue)
    }

    override suspend fun pushNode(path: String, serializedValue: String): String? {
        delay(delayMs)
        val newChild = randomString(8)
        val fullPath = buildPath(path, newChild)
        val dataElement = Json.parseToJsonElement(serializedValue)
        data.replaceDeepChild(fullPath, dataElement)
        return newChild
    }

    override suspend fun updateNode(path: String, serializedValue: String) {
        delay(delayMs)
        val pathParts = path.split("/")
        val currentData = data.waitForSuccess().findJsonElementByPath(pathParts)
        if (currentData == null) {
            data.replaceDeepChild(path, Json.parseToJsonElement(serializedValue)).toResourceSucces()
        } else {
            val map = currentData.jsonObject.toMutableMap()
            val newValues = Json.parseToJsonElement(serializedValue)
            map.putAll(newValues.jsonObject)
            val newJsonObj = JsonObject(map)
            data.replaceDeepChild(path, newJsonObj).toResourceSucces()
        }
    }

    override suspend fun fetchObject(path: String): String {
        delay(delayMs)
        val pathParts = path.split("/")
        val currentData =
            data.waitForSuccess().findJsonElementByPath(pathParts) ?: throw KmpDatabaseException.ValueNotFound(path)
        return Json.encodeToString(currentData)
    }

    override suspend fun runQuery(
        path: String,
        queryType: QueryType
    ): String {
        delay(delayMs)
        val pathParts = path.split("/")
        val currentData =
            data.waitForSuccess().findJsonElementByPath(pathParts) ?: throw KmpDatabaseException.ValueNotFound(path)

        return when (currentData) {
            is JsonObject -> {
                val res = currentData.filterValues { value ->
                    val fieldValueRaw = value.jsonObject[queryType.field]
                    val fieldValue = fieldValueRaw?.jsonPrimitive
                    queryType.isFilter(fieldValue)
                }

                Json.encodeToString(res)
            }

            is JsonArray -> {
                val res = currentData.filter { child ->
                    val fieldValueRaw = child.jsonObject[queryType.field]
                    val fieldValue = fieldValueRaw?.jsonPrimitive
                    queryType.isFilter(fieldValue)
                }

                Json.encodeToString(res)
            }

            else -> throw Exception("Invalid data type")
        }
    }

    private fun QueryType.isFilter(
        fieldValue: JsonPrimitive?
    ): Boolean {
        return when (this) {
            is QueryType.StartAt -> {
                val doubleValue = fieldValue!!.double
                doubleValue >= this.minValue
            }

            is QueryType.EndBefore -> {
                val doubleValue = fieldValue!!.double
                doubleValue < this.maxValue
            }

            is QueryType.Equal -> {
                val doubleValue = fieldValue!!.content
                doubleValue == this.data
            }

            is QueryType.Range -> {
                val doubleValue = fieldValue!!.double
                doubleValue in this.minValue..this.maxValue
            }
        }
    }

    fun observeNewChild(
        path: String
    ) = flow {
        val pathParts = path.split("/")
        var previousData: Map<String, JsonElement> = emptyMap()
        data.filterToData().collect { newData ->
            delay(delayMs)
            newData.findJsonElementByPath(pathParts)?.jsonObject?.let { newChild ->
                val diff = newChild.filter { it.key !in previousData.keys }
                previousData = newChild
                if (diff.isNotEmpty()) {
                    diff.forEach {
                        emit(
                            KmpNode<JsonElement>(
                                key = it.key,
                                data = it.value
                            )
                        )
                    }
                }
            }
        }
    }

    val KmpNode<JsonElement>.kmpSnapshot: KmpSnapshot
        get() = KmpSnapshot(key, Json.encodeToString(data))

    override fun observeNewChild(
        path: String,
        completionHandler: (KmpSnapshot?, String?) -> Unit
    ): ListenerRegistration {
        val c = CoroutineScope(Dispatchers.Default)
        c.launch {
            observeNewChild(path).collect {
                completionHandler(it.kmpSnapshot, null)
            }
        }
        return ListenerRegistration {
            AppLogger.d("observeNewChild", "$path removed")
            c.coroutineContext.cancel()
        }
    }

    override fun observeNewChild(
        path: String,
        query: QueryType.StartAt,
        completionHandler: (KmpSnapshot?, String?) -> Unit
    ): ListenerRegistration {
        val c = CoroutineScope(Dispatchers.Default)
        c.launch {
            observeNewChild(path).collect { kmpNode ->
                val timeStamp = kmpNode.data.jsonObject[query.field]?.jsonPrimitive?.double!!
                if (timeStamp > query.minValue) {
                    completionHandler(kmpNode.kmpSnapshot, null)
                }
            }
        }

        return ListenerRegistration {
            AppLogger.d("observeNewChild", "$path removed")
            c.coroutineContext.cancel()
        }
    }


    override fun observeValueChange(
        path: String,
        completionHandler: (String?, Exception?) -> Unit
    ): ListenerRegistration {
        val c = CoroutineScope(Dispatchers.Default)
        c.launch {
            val oldData = data.waitForSuccess()
            val initialData = data.waitForSuccess().findJsonElementByPath(path.split("/"))
            completionHandler(Json.encodeToString(initialData), null)
            data.filterToData().collect { newData ->
                delay(delayMs)
                val oldJson = oldData.findJsonElementByPath(path.split("/"))
                val newJson = newData.findJsonElementByPath(path.split("/"))
                if (oldJson != newJson) {
                    completionHandler(Json.encodeToString(newJson), null)
                }
            }
        }
        return ListenerRegistration {
            AppLogger.d("observeValueChange", "$path removed")
            c.coroutineContext.cancel()
        }
    }

    override suspend fun removeNode(nodePath: String) {
        delay(delayMs)
        data.replaceDeepChild(nodePath, JsonNull)
    }

    override suspend fun transaction(
        path: String,
        transaction: (String?) -> TransactionTarget<String>
    ): Boolean {
        delay(delayMs)
        val pathParts = path.split("/")
        val currentData = data.waitForSuccess().findJsonElementByPath(pathParts)
        val transactionResult = transaction(Json.encodeToString(currentData))
        if (transactionResult.success) {
            data.replaceDeepChild(path, Json.parseToJsonElement(transactionResult.newValue!!))
        }
        return transactionResult.success
    }

    override fun setFalseOnDisconnect(path: String) {
    }

    suspend fun MutableStateFlow<ResourceState<JsonElement>>.replaceDeepChild(
        path: String,
        newChild: JsonElement
    ) {
        val pathParts = path.split("/")
        value = waitForSuccess().replaceDeepChild(pathParts, newChild).toResourceSucces()
        triggerEvents(path, newChild)
    }

    fun triggerEvents(path: String, data: JsonElement) {
        CoroutineScope(Dispatchers.Default).launch {
            eventsList.forEach { event ->
                val nativeEvent = event.toNativeEvent(path, data)
                if (nativeEvent != null) {
                    event.handler.invoke(nativeEvent)
                }
            }
        }
    }

}

fun EventHandler.toNativeEvent(
    invokedPath: String,
    data: JsonElement,
): NativeEvent? {
    val params = extractParams(invokedPath) ?: return null
    return NativeEvent(
        params = params,
        serilizedData = Json.encodeToString(data),
        invokedRegPath = path
    )
}