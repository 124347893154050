package osg.uiZone.matchZone.entertainment.baseContent

import com.osg.appData.matchZone.ice.IcebreakerCollection
import com.osg.truebase.data.iceDef.IceTag
import com.osg.ui.core.commonStates.asTrueImage
import com.osg.ui.core.resources.uiText
import com.osg.utils.plus
import osg.uiZone.matchZone.entertainment.TMEntertainmentDataUiBase
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.lie_ai_description
import truematch.uizone.generated.resources.lie_ai_name
import truematch.uizone.generated.resources.lie_ai_slogan
import uiZone.trueAutoResources.LocalImages

val lieAiBase = TMEntertainmentDataUiBase(
    description = Res.string.lie_ai_description.uiText,
    image = LocalImages.ONE_AI.asTrueImage(),
    name = Res.string.lie_ai_name.uiText,
    
    unlockStage = 2,
    slogan = Res.string.lie_ai_slogan.uiText,
    vibes = IceTag.Funny + IceTag.Memories,
    id = IcebreakerCollection.LieAi.name,
)