package osg.uiZone.common.baseRepos

import com.osg.appData.common.NotificationUiData
import com.osg.appData.messaging.IPlatformMessagingService
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.nodeData.decodeFromHexString
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.callbackFlow
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

interface ICommonMessagingService {
    val updateTokenFlow: Flow<String>
    suspend fun updateToken(uid: String)
}

class MessagingService(): ICommonMessagingService, KoinComponent {
    @PublishedApi
    internal val messagingImpl: IPlatformMessagingService by inject()
    val regTokenStateFlow = MutableStateFlow("")

    private val regTokenFlow = callbackFlow {
        messagingImpl.onTokenRefresh { token ->
            trySend(token)
        }

        awaitClose {}
    }

    override val updateTokenFlow: Flow<String> = regTokenFlow

    override suspend fun updateToken(uid: String) {
        if (regTokenStateFlow.value.isNotEmpty()) {
            AppLogger.d(this::class.simpleName!!, "updateToken: Token already updated")
            return
        }

        messagingImpl.fetchCurrentToken()
    }

}

fun toNotificationData(data: String): NotificationUiData {
    return data.decodeFromHexString()
}