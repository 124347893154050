package osg.uiZone.common.pyrotechnics.navigation

import androidx.compose.animation.*
import androidx.compose.runtime.Immutable
import appData.trueAutoResources.MatchingInterest
import com.osg.appData.candidate.Decision
import com.osg.ui.core.commonStates.TrueImage
import osg.uiZone.profile.TechnicalDetails
import osg.uiZone.profile.TechnicalDetails.Companion.toTechnicalDetails
import osg.uiZone.profile.profileItemsState
import osg.uiZone.search.ds.SearchScreenState

@Immutable
sealed class PreviewType{
    abstract fun toOther(): PreviewType

    @Immutable
    data class Promo(
        val searchState: SearchScreenState.Search,
    ) : PreviewType(){
        val profileImage: TrueImage = searchState.profileImages.first()
        val matchingInterests: List<MatchingInterest> = searchState.currentCandidate.matchIncentive.matchingInterests
        val profileUiDetails: TechnicalDetails = searchState.currentCandidate.publicProfile.toTechnicalDetails()
        val lastDecision: Decision = searchState.lastDecision
        override fun toOther() = Preview(searchState)
    }

    @Immutable
    data class Preview(
        val searchState: SearchScreenState.Search
    ) : PreviewType(){
        val profileItemsState = searchState.currentCandidate.profileItemsState(
            profileImages = searchState.profileImages
        )
        override fun toOther() = Promo(searchState)
    }
}

val searchTransition: AnimatedContentTransitionScope<PreviewType>.() -> ContentTransform by lazy {
    {
        when (val state = targetState) {
            is PreviewType.Promo -> {
                val slideDirection = when (state.lastDecision) {
                    Decision.LIKE -> slideOutHorizontally(
                        targetOffsetX = { -it / 2 }
                    )
                    Decision.DISLIKE -> slideOutHorizontally(
                        targetOffsetX = { it / 2 }
                    )
                    Decision.SUPER_LIKE -> slideOutHorizontally(
                        targetOffsetX = { -it / 2 }
                    )
                    else -> slideOutHorizontally()
                }
                fadeIn() togetherWith slideDirection
            }
            is PreviewType.Preview -> {
                slideInVertically(
                    initialOffsetY = { it / 2 }
                ) togetherWith fadeOut()
            }
        }
    }
}
