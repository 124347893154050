package osg.uiZone.graph

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.lifecycle.compose.LifecycleResumeEffect
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import com.osg.appData.location.IAutoCompleteCities
import com.osg.appData.location.ICurrentLocation
import com.osg.appData.location.IGeo
import com.osg.def.database.IAppConfig
import com.osg.def.database.LaunchStringResolver
import com.osg.di.IBaseApp
import com.osg.truebase.data.LanguageSupport
import com.osg.truebase.data.iceDef.IceMultiMediaDownloader
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.nodeData.fromString
import com.osg.truebase.spotlight.TutorialScope
import com.osg.truebase.ui.commonStates.MainViewUi
import com.osg.truebase.ui.datastore.IDataStore
import com.osg.truebase.ui.theme.ThemeConfig
import com.osg.truebase.ui.theme.TrueTheme
import kotlinx.serialization.Serializable
import org.koin.core.component.KoinComponent
import org.koin.core.module.dsl.singleOf
import org.koin.core.parameter.ParametersHolder
import org.koin.core.scope.Scope
import org.koin.dsl.bind
import org.koin.dsl.binds
import org.koin.dsl.module
import osg.uiZone.common.baseRepos.BaseDataStore
import osg.uiZone.common.baseRepos.profile.profileRepoModule
import osg.uiZone.common.vmRepo.matchZone.matchRepoModule
import osg.uiZone.graph.entry.EntryModel
import osg.uiZone.search.repo.searchRepoModule

class TrueMatchCommonApp(
    geoService: () -> IGeo,
    iceMultiMediaDownloader: Scope.(ParametersHolder) -> IceMultiMediaDownloader,
    onSetupKoin: KoinComponent.() -> Unit = {},
) : KoinComponent, IBaseApp(
    resolveEnvironmentConfig = ::resolveEnvironmentConfig,
    iceMultiMediaDownloader = iceMultiMediaDownloader,
    datastoreImpl = {
        single<BaseDataStore> {
            BaseDataStore(get())
        }.bind(IDataStore::class)
    },
    appCommonModules= listOf(
        module {
            singleOf<IGeo>(geoService) binds arrayOf(
                IGeo::class,
                IAutoCompleteCities::class,
                ICurrentLocation::class
            )
        },
        searchRepoModule,
        matchRepoModule,
        profileRepoModule
    )
) {
    @Composable
    override fun AppEntry(navController: NavHostController) {
        val mainViewModel = viewModel { EntryModel() }
        val uiLoadingState by mainViewModel.uiState.collectAsState()
        AppLogger.d("TrueMatchEntry", "uiState $uiLoadingState")
        uiLoadingState.MainViewUi {uiState ->
            TrueTheme(
                tmConfig = ThemeConfig(
                    LanguageSupport.ENGLISH // TODO support hebrew
                ),
            ) {
                TutorialScope(
                    seenIds =  uiState.tmConfig.seenSpotlights,
                    onSeen = mainViewModel::onSeen
                ) {
                    SetupNavGraph()
                    TmPopUp(
                        globalPopUp = uiState.globalPopUp,
                        onPermissionStatus = mainViewModel::onPermissionsStatus
                    )
                }
            }
        }


        LifecycleResumeEffect(Unit) {
            mainViewModel.onResume()
            onPauseOrDispose {
                mainViewModel.onPause()
            }
        }
    }

    init {
        onSetupKoin()
    }
}

@Serializable
data class TmEnvironmentConfig(
    override val emulatorIp: String? = null,
    val testUser: String? = null,
    override val printDebugInfo: Boolean = false,
) : IAppConfig

fun resolveEnvironmentConfig(launchStringResolver: LaunchStringResolver): TmEnvironmentConfig {
    val launchString = launchStringResolver.resolve()
    AppLogger.d("resolveAndroidLaunchString", "launchString: $launchString")
    val config = launchString?.let { lString ->
        return lString.fromString()
    } ?: TmEnvironmentConfig()
    return config
}