package com.osg.utils

operator fun <E : Enum<E>> E.plus(other: E): List<E> {
    return listOf(this, other)
}

operator fun <E : Enum<E>> List<E>.plus(other: E): List<E> {
    return this + listOf(other)
}

