package com.osg.truebase.data.nodeData

import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.decodeFromHexString
import kotlinx.serialization.encodeToHexString
import kotlinx.serialization.json.Json
import kotlinx.serialization.protobuf.ProtoBuf

val encodeDefaultsJson by lazy {
    Json {
        encodeDefaults = true
    }
}

@OptIn(ExperimentalSerializationApi::class)
inline fun <reified T> T.toHexString(): String {
    return ProtoBuf.Default.encodeToHexString(this)
}

@OptIn(ExperimentalSerializationApi::class)
inline fun <reified T> String.decodeFromHexString(): T {
    return ProtoBuf.Default.decodeFromHexString<T>(this)
}

inline fun <reified T> T.asString(): String {
    return encodeDefaultsJson.encodeToString(this)
}

inline fun <reified T> String.fromString(): T {
    return try {
        encodeDefaultsJson.decodeFromString(this)
    }catch (_: Exception){
        throw Exception("Error decoding string to ${T::class.simpleName} from data: $this")
    }
}