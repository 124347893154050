package com.osg.truematch.demo.baseModuleSim

import com.osg.appData.messaging.IPlatformMessagingService

class MessagingServiceSim: IPlatformMessagingService {
    override fun onTokenRefresh(callback: (String) -> Unit) {

    }

    override suspend fun fetchCurrentToken(): String {
        return "token"
    }

}