@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package truematch.crossui.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val app_slogan: StringResource by 
      lazy { init_app_slogan() }

  public val slogan_ai: StringResource by 
      lazy { init_slogan_ai() }

  public val slogan_ai_description: StringResource by 
      lazy { init_slogan_ai_description() }

  public val slogan_connect: StringResource by 
      lazy { init_slogan_connect() }

  public val slogan_connect_description: StringResource by 
      lazy { init_slogan_connect_description() }

  public val slogan_focus: StringResource by 
      lazy { init_slogan_focus() }

  public val slogan_focus_description: StringResource by 
      lazy { init_slogan_focus_description() }

  public val slogan_games: StringResource by 
      lazy { init_slogan_games() }

  public val slogan_games_description: StringResource by 
      lazy { init_slogan_games_description() }

  public val slogan_tasty_cashback: StringResource by 
      lazy { init_slogan_tasty_cashback() }

  public val slogan_tasty_cashback_description: StringResource by 
      lazy { init_slogan_tasty_cashback_description() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("app_slogan", CommonMainString0.app_slogan)
  map.put("slogan_ai", CommonMainString0.slogan_ai)
  map.put("slogan_ai_description", CommonMainString0.slogan_ai_description)
  map.put("slogan_connect", CommonMainString0.slogan_connect)
  map.put("slogan_connect_description", CommonMainString0.slogan_connect_description)
  map.put("slogan_focus", CommonMainString0.slogan_focus)
  map.put("slogan_focus_description", CommonMainString0.slogan_focus_description)
  map.put("slogan_games", CommonMainString0.slogan_games)
  map.put("slogan_games_description", CommonMainString0.slogan_games_description)
  map.put("slogan_tasty_cashback", CommonMainString0.slogan_tasty_cashback)
  map.put("slogan_tasty_cashback_description", CommonMainString0.slogan_tasty_cashback_description)
}

public val Res.string.app_slogan: StringResource
  get() = CommonMainString0.app_slogan

private fun init_app_slogan(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_slogan", "app_slogan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/values/promo_slogans.commonMain.cvr",
    10, 54),
    )
)

public val Res.string.slogan_ai: StringResource
  get() = CommonMainString0.slogan_ai

private fun init_slogan_ai(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:slogan_ai", "slogan_ai",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/values/promo_slogans.commonMain.cvr",
    259, 29),
    )
)

public val Res.string.slogan_ai_description: StringResource
  get() = CommonMainString0.slogan_ai_description

private fun init_slogan_ai_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:slogan_ai_description", "slogan_ai_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/values/promo_slogans.commonMain.cvr",
    65, 193),
    )
)

public val Res.string.slogan_connect: StringResource
  get() = CommonMainString0.slogan_connect

private fun init_slogan_connect(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:slogan_connect", "slogan_connect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/values/promo_slogans.commonMain.cvr",
    376, 50),
    )
)

public val Res.string.slogan_connect_description: StringResource
  get() = CommonMainString0.slogan_connect_description

private fun init_slogan_connect_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:slogan_connect_description", "slogan_connect_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/values/promo_slogans.commonMain.cvr",
    289, 86),
    )
)

public val Res.string.slogan_focus: StringResource
  get() = CommonMainString0.slogan_focus

private fun init_slogan_focus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:slogan_focus", "slogan_focus",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/values/promo_slogans.commonMain.cvr",
    528, 52),
    )
)

public val Res.string.slogan_focus_description: StringResource
  get() = CommonMainString0.slogan_focus_description

private fun init_slogan_focus_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:slogan_focus_description", "slogan_focus_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/values/promo_slogans.commonMain.cvr",
    427, 100),
    )
)

public val Res.string.slogan_games: StringResource
  get() = CommonMainString0.slogan_games

private fun init_slogan_games(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:slogan_games", "slogan_games",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/values/promo_slogans.commonMain.cvr",
    746, 48),
    )
)

public val Res.string.slogan_games_description: StringResource
  get() = CommonMainString0.slogan_games_description

private fun init_slogan_games_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:slogan_games_description", "slogan_games_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/values/promo_slogans.commonMain.cvr",
    581, 164),
    )
)

public val Res.string.slogan_tasty_cashback: StringResource
  get() = CommonMainString0.slogan_tasty_cashback

private fun init_slogan_tasty_cashback(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:slogan_tasty_cashback", "slogan_tasty_cashback",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/values/promo_slogans.commonMain.cvr",
    933, 49),
    )
)

public val Res.string.slogan_tasty_cashback_description: StringResource
  get() = CommonMainString0.slogan_tasty_cashback_description

private fun init_slogan_tasty_cashback_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:slogan_tasty_cashback_description", "slogan_tasty_cashback_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/values/promo_slogans.commonMain.cvr",
    795, 137),
    )
)
