package osg.uiZone.matchZone.entertainment.baseContent

import com.osg.appData.matchZone.ice.IcebreakerCollection
import com.osg.truebase.data.iceDef.IceTag
import com.osg.ui.core.commonStates.asTrueImage
import com.osg.ui.core.resources.uiText
import com.osg.utils.plus
import osg.uiZone.matchZone.entertainment.TMEntertainmentDataUiBase
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.blind_canvas_description
import truematch.uizone.generated.resources.blind_canvas_name
import truematch.uizone.generated.resources.blind_canvas_slogan
import uiZone.trueAutoResources.LocalImages

val blindCanvasBase = TMEntertainmentDataUiBase(
    description = Res.string.blind_canvas_description.uiText,
    image = LocalImages.BLIND_CANVAS.asTrueImage(),
    name = Res.string.blind_canvas_name.uiText,
    
    unlockStage = 1,
    slogan = Res.string.blind_canvas_slogan.uiText,
    vibes = IceTag.Art + IceTag.Nostalgia,
    id = IcebreakerCollection.BlindCanvas.name,
)