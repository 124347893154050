package osg.uiZone.search.screens

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.osg.appData.profile.PreferencesMatch
import com.osg.appData.register.MaxDistanceRange
import com.osg.appData.register.maxAgeRange
import com.osg.truebase.ui.assigners.RangeValueSlider
import com.osg.truebase.ui.assigners.SliderAssignerComponent
import com.osg.truebase.ui.theme.spacing
import com.osg.ui.core.commonStates.asTrueVector
import com.osg.ui.core.resources.string
import osg.uiZone.matchZone.icons.Toys
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.age_range
import truematch.uizone.generated.resources.distance_range
import kotlin.math.roundToInt

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun SearchPreferencesScreen(
    preferencesMatch: PreferencesMatch,
    modifier: Modifier = Modifier,
    savePreferences: (PreferencesMatch) -> Unit
) {
    var localPreferences by remember { mutableStateOf(preferencesMatch) }
    DisposableEffect(Unit) {
        onDispose {
            if (localPreferences != preferencesMatch) {
                savePreferences(localPreferences)
            }
        }
    }
    Column(
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small),
        modifier = Modifier.padding(MaterialTheme.spacing.small),
    ) {
        Text(
            text = Res.string.distance_range.string,
            style = MaterialTheme.typography.titleMedium,
            modifier = modifier,
        )
        SliderAssignerComponent(
            initialAnswer = localPreferences.maxDistance!!,
            onAnswered = {
                localPreferences = localPreferences.copy(
                    maxDistance = it.toInt()
                )
            },
            range = MaxDistanceRange,
            trueVector = Icons.Filled.Toys.asTrueVector(),

            )
        HorizontalDivider(
            modifier = Modifier.padding(
                horizontal = MaterialTheme.spacing.medium,
                vertical = MaterialTheme.spacing.small
            )
        )
        Text(
            text = Res.string.age_range.string,
            style = MaterialTheme.typography.titleMedium,
            modifier = modifier,
        )
        RangeValueSlider(
            range = maxAgeRange,
            initialAnswer = (localPreferences.ageMin ?: 18).toFloat()..(localPreferences.ageMax ?: 70).toFloat(),
            onAnswered = {
                localPreferences = localPreferences.copy(
                    ageMin = it.start.roundToInt(),
                    ageMax = it.endInclusive.roundToInt()
                )
            },
        )
    }
}