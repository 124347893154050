package osg.uiZone.matchZone


import androidx.compose.runtime.Immutable
import com.osg.appData.matchZone.Message
import com.osg.appData.matchZone.invitation.Invitation
import osg.uiZone.matchZone.state.tmEntUi.ResponseEntertainmentUi

enum class OpeningMoveGlobalState{
    Required,
    NotRequired,
}

@Immutable
data class ChatZoneUiData(
    val myUid: String,
    val conversation: List<Message>,
)

@Immutable
data class InvitationsClassified(
    val approvedInvitation: List<Invitation> = emptyList(),
    val rejectedInvitation: List<Invitation> = emptyList(),
    val sentInvitation: List<Invitation> = emptyList(),
    val gameInvitation: List<Invitation> = emptyList(),
    val sparkInvitation: List<Invitation> = emptyList(),
    val notSeenInvitation: List<Invitation> = emptyList(),
    val completedInvitation: List<Invitation> = emptyList(),
) {
    val all: List<Invitation>
        get() = approvedInvitation +
                rejectedInvitation +
                gameInvitation +
                sparkInvitation +
                notSeenInvitation +
                completedInvitation +
                sentInvitation
}

@Immutable
data class InvitationsContent(
    val approvedContent: List<ResponseEntertainmentUi> = emptyList(),
    val notSeenInvitation: List<ResponseEntertainmentUi> = emptyList(),
)

data class InvitationsUiState(
    val approvedContent: List<ResponseEntertainmentUi> = emptyList(),
    val notSeenInvitation: List<ResponseEntertainmentUi> = emptyList(),
    val notRespondedInvitation: List<Invitation> = emptyList()
)
