package com.osg.trumatch.bots.simProfiles

import bots.trueAutoResources.BotsMediaRemoteImages
import com.osg.appData.profile.*
import com.osg.appData.register.OpenDeepQuestions
import com.osg.truebase.data.nodeData.RemoteProfileImage
import com.osg.truebase.data.user.Gender
import com.osg.truebase.preview.data.IsraelLocation

internal val julietProfile = FullProfile(
    personalProfile = PersonalProfile(

        lastName = "Burke", // Updated last name
        preferencesMatch = PreferencesMatch(
            ageMax = 50,
            ageMin = 30,
            maxDistance = 100,
            dateGender = Gender.Male, // Updated gender preference
        ),
    ),
    publicProfile = PublicProfile(
        height = 170, // Updated height
        birthDay = "03/05/1993", // Updated birth date
        gender = Gender.Female, // Updated gender
        firstName = "Juliet", // Updated first name
        occupation = "Doctor", // Updated occupation
        openQuestionAnswer = listOf(
            OpenAnswer(
                answer = "Hi, I'm Juliet Burke. I'm a doctor with a passion for helping others. I enjoy spending time on the beach and exploring new places. I'm looking for someone who is kind, adventurous, and has a good sense of humor.",
                questionId = OpenDeepQuestions.INTRODUCTION
            )
        ),
        location = IsraelLocation.MODIIN.location,
        stage = UserStage.REGISTERED,
        picturesCommentList = listOf(
            RemoteProfileImage(
                comment = "", mediaPath = BotsMediaRemoteImages.F0I0.mediaPath
            ), RemoteProfileImage(
                comment = "Just for fun 😊", mediaPath = BotsMediaRemoteImages.F0I1.mediaPath
            ), RemoteProfileImage(
                comment = "", mediaPath = BotsMediaRemoteImages.F0I2.mediaPath
            )
        ),
    ),
)