package com.osg.truebase.ui.button

import androidx.compose.animation.core.*
import androidx.compose.foundation.gestures.detectDragGestures
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.SmallFloatingActionButton
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.draw.scale
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.dp
import com.osg.ui.core.commonStates.TrueVector
import com.osg.ui.core.commonStates.asVector
import kotlin.math.roundToInt

enum class MultiFabState {
    COLLAPSED, EXPANDED
}

val MultiFabState.next: MultiFabState
    get() = when (this) {
        MultiFabState.COLLAPSED -> MultiFabState.EXPANDED
        MultiFabState.EXPANDED -> MultiFabState.COLLAPSED
    }

@Composable
fun DragMultiFab(
    fabIcon: TrueVector,
    fabItems: List<FabItem>,
    modifier: Modifier = Modifier,
    onClick: (String) -> Unit,
) {
    var offsetX by remember { mutableFloatStateOf(0f) }
    var offsetY by remember { mutableFloatStateOf(0f) }
    Box(
        modifier
            .offset { IntOffset(offsetX.roundToInt(), offsetY.roundToInt()) }
            .wrapContentSize()
            .pointerInput(Unit) {
                detectDragGestures { change, dragAmount ->
                    change.consume()
                    offsetX + dragAmount.x
                    offsetY + dragAmount.y
                }
            }
    ) {
        var currentState by remember { mutableStateOf(MultiFabState.COLLAPSED) }

        MultiFabUi(
            fabIcon = fabIcon,
            multiFabState = currentState,
            fabItems = fabItems,
            onNextState = { currentState = currentState.next },
            onClick = onClick
        )
    }
}

@Composable
fun MultiFabUi(
    fabIcon: TrueVector,
    fabItems: List<FabItem>,
    multiFabState: MultiFabState,
    onClick: (String) -> Unit,
    onNextState: () -> Unit,
) {
    val stateTransition: Transition<MultiFabState> =
        updateTransition(targetState = multiFabState, label = "")
    Column(
        verticalArrangement = Arrangement.spacedBy(8.dp),
        horizontalAlignment =  Alignment.CenterHorizontally,
    ) {
        MultiFloatingActionButtonVector(
            stateTranslation = stateTransition,
            items = fabItems,
            onClick = onClick,
        )
        multiFabMainButton(
            fabIcon = fabIcon,
            stateTransition = stateTransition,
            onNextState = onNextState
        )
    }
}

@Composable
private fun multiFabMainButton(
    fabIcon: TrueVector,
    stateTransition: Transition<MultiFabState>,
    onNextState: () -> Unit,
) {
    val rotation: Float by stateTransition.animateFloat(
        transitionSpec = {
            if (targetState == MultiFabState.EXPANDED) {
                spring(stiffness = Spring.StiffnessLow)
            } else {
                spring(stiffness = Spring.StiffnessMedium)
            }
        },
        label = ""
    ) { state ->
        if (state == MultiFabState.EXPANDED) 45f else 0f
    }

    SmallFloatingActionButton(
        shape = CircleShape,
        onClick = onNextState
    ) {
        Icon(
            fabIcon.asVector(),
            null,
            modifier = Modifier.rotate(rotation),
        )
    }
}


@Composable
fun MultiFloatingActionButtonVector(
    stateTranslation: Transition<MultiFabState>,
    items: List<FabItem>,
    modifier: Modifier = Modifier,
    onClick: (String) -> Unit,
) {
    val scale: Float by stateTranslation.animateFloat(
        transitionSpec = {
            spring(stiffness = Spring.StiffnessLow)
        },
        label = ""
    ) { state ->
        if (state == MultiFabState.EXPANDED) 1f else 0f
    }
    val rotation: Float by stateTranslation.animateFloat(
        transitionSpec = {
            spring(stiffness = Spring.StiffnessLow)
        },
        label = ""
    ) { state ->
        if (state == MultiFabState.EXPANDED) 0f else 360f
    }
    Column(
        modifier = Modifier,
        verticalArrangement = Arrangement.spacedBy(8.dp),
    ) {
        items.forEach { item ->
            SmallFloatingActionButton(
                containerColor = MaterialTheme.colorScheme.tertiaryContainer,
                shape = CircleShape,
                modifier = modifier.height(36.dp).scale(scale).rotate(rotation),
                onClick = { onClick(item.identifier) },
            ) {
                item.Content()
            }
        }
    }
}
