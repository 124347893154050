package com.osg.di

import com.osg.def.CrashlyticsLogger
import com.osg.def.ICrashlyticsProvider
import com.osg.def.auth.AuthService
import com.osg.def.auth.IPlatformAuth
import com.osg.def.database.*
import com.osg.truebase.data.logger.BaseLoggerFactory
import com.osg.truebase.data.logger.EmptyLoggerFactory
import com.osg.truebase.data.logger.PlatformLoggerFactory
import com.osg.truebase.datastore.IDataStoreEngine
import com.osg.truebase.player.audio.IPlatformAudioPlayer
import com.osg.truebase.signin.ISignInProviders
import org.koin.core.context.startKoin
import org.koin.core.module.Module
import org.koin.core.parameter.ParametersHolder
import org.koin.core.scope.Scope
import org.koin.dsl.binds
import org.koin.dsl.module

fun startBaseKoin(
    databaseLoader: Scope.(ParametersHolder) -> IDatabaseEngine,
    platformStorageLoader: (IAppConfig) -> IPlatformStorage,
    authLoader: (IAppConfig) -> IPlatformAuth,
    signInLoader: (IAppConfig) -> ISignInProviders,
    audioPlayerLoader: Scope.(ParametersHolder) -> IPlatformAudioPlayer,
    buildDataStore: Scope.(ParametersHolder) -> IDataStoreEngine,
    launchStringResolver: Scope.(ParametersHolder) -> LaunchStringResolver,
    platformLoggerFactory: Scope.(ParametersHolder) -> PlatformLoggerFactory = {
        val appConfig = get<IAppConfig>()
        if ( appConfig.printDebugInfo) {
            BaseLoggerFactory
        } else {
            EmptyLoggerFactory
        }
    },
    platformModules: List<Module> = emptyList(),
) {
    val modules = platformModules + module {
        factory<IPlatformAudioPlayer>(
            definition = audioPlayerLoader
        )
        single<LaunchStringResolver>(
            definition = launchStringResolver
        )
        single<PlatformLoggerFactory>(
            definition = platformLoggerFactory
        )
        single<IDatabaseEngine>(
            definition = databaseLoader
        )
        single<IDataStoreEngine>(
            definition = buildDataStore
        )
        single<IPlatformStorage> { platformStorageLoader(get()) } binds
                arrayOf(
                    IPlatformStorage::class,
                    IDownloadMedia::class,
                    IUploadMedia::class,
                    IFilesInPath::class,
                    IDelete::class
                )
        single<ISignInProviders> { signInLoader(get()) }
        single<IPlatformAuth> {
            authLoader(get())
        }
    }
    startKoin {
        modules(modules)
    }
}

fun crashlyticsLoggerDef(
    crashlyticsProvider: (IAppConfig) -> ICrashlyticsProvider
): Scope.(ParametersHolder) -> PlatformLoggerFactory = {
    val auth = get<AuthService>()
    val crashlytics = crashlyticsProvider(get())
    CrashlyticsLogger(
        crashlyticsProvider = crashlytics,
        getUid = auth::getUid
    )
}