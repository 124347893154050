package osg.uiZone.common.baseRepos.database

import com.osg.appData.common.DataRoot
import com.osg.appData.profile.PublicProfile
import com.osg.def.database.DatabaseService
import kotlinx.coroutines.flow.Flow

fun interface IPublicProfileFlow{
    fun publicProfileFlow(uid: String): Flow<PublicProfile?>
}

val DatabaseService.publicProfileFlow: IPublicProfileFlow
    get() = IPublicProfileFlow{ uid ->
        val path = DataRoot.Companion.publicProfilePath(uid)
        onValueChangeFlow<PublicProfile>(path)
    }


val DatabaseService.publicProfileFetcher: IPublicProfileFetcher
    get() = IPublicProfileFetcher{ uid ->
        val path = DataRoot.publicProfilePath(uid)
        fetchObject(path)
    }

suspend fun DatabaseService.fetchPublicProfile(uid: String): PublicProfile {
    val path = DataRoot.publicProfilePath(uid)
    return fetchObject(path)
}
