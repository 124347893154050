package osg.uiZone.common.baseRepos.database

import com.osg.appData.matchZone.EntertainmentStore
import com.osg.appData.matchZone.SparkNodeData
import com.osg.def.database.DatabaseService

fun interface ISparkNodeData {
    suspend fun fetchSparkUi(path: String): SparkNodeData
}

fun interface IFetchEntStore {
    suspend fun fetchEntStore(path: String) : EntertainmentStore
}

val DatabaseService.sparkDataNode: ISparkNodeData
    get() = ISparkNodeData{ path ->
        fetchObject(path)
    }


val DatabaseService.entStoreFetcher: IFetchEntStore
    get() = IFetchEntStore{ path ->
        fetchObject(path)
    }