package osg.uiZone.matchZone.model

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.osg.appData.auth.basicIdentity
import com.osg.appData.candidate.UnMatchRequest
import com.osg.appData.common.DataRoot
import com.osg.appData.functions.IGenLlmResponse
import com.osg.appData.functions.IUnMatchRequest
import com.osg.appData.ml.LlmUnmatchChatRequest
import com.osg.appData.profile.MatchStatus
import com.osg.truebase.ui.commonStates.MainViewUiState
import com.osg.truebase.ui.commonStates.toSuccess
import com.osg.utils.*
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import osg.uiZone.common.baseRepos.database.IPublicProfileFlow
import osg.uiZone.common.baseRepos.database.publicProfileFlow
import osg.uiZone.common.baseRepos.handlers.BasicChatHandler
import osg.uiZone.common.baseRepos.handlers.IChatHandlerDependencies
import osg.uiZone.common.baseRepos.handlers.chatRepo
import osg.uiZone.common.baseRepos.profile.IMyFullProfileFlow
import osg.uiZone.common.baseRepos.profile.MyProfileScope.Companion.myProfileHandler
import osg.uiZone.common.baseRepos.profile.databaseService
import osg.uiZone.di.FunctionsService
import osg.uiZone.matchZone.state.UnmatchUiState
import osg.uiZone.navigation.SearchDestination


interface UnMatchDatabaseRepository : IChatHandlerDependencies, IPublicProfileFlow, IMyFullProfileFlow

class UnmatchRepoImpl :
    UnMatchDatabaseRepository,
    IMyFullProfileFlow by myProfileHandler,
    IChatHandlerDependencies by databaseService.chatRepo,
    IPublicProfileFlow by databaseService.publicProfileFlow

class UnmatchedModel(
    val llmCf: IGenLlmResponse = FunctionsService(),
    val umMatchFc: IUnMatchRequest = FunctionsService(),
    unMatchDatabaseRepository: UnMatchDatabaseRepository = UnmatchRepoImpl(),
) : ViewModel(), UnMatchDatabaseRepository by unMatchDatabaseRepository {

    private val candidateProfileFlow = dependencyFlow {
        val candidateUid = myProfileStateFlow.waitForSuccess().matchZoneData!!.candidateUid
        unMatchDatabaseRepository.publicProfileFlow(candidateUid)
    }

    private val chtHandler = BasicChatHandler(unMatchDatabaseRepository)


    private val chatStateFlow = myProfileStateFlow.filterToData().startWithFirst {
        val chatPath = DataRoot.unMatchAiPath(
            uid = it.publicProfile.uid,
            candidateUid = it.matchZoneData!!.candidateUid
        )
        chtHandler.conversationFlow(chatPath)
    }.stateIn(
        viewModelScope,
        SharingStarted.Lazily,
        null
    )

    val unMatchUiState: StateFlow<MainViewUiState<UnmatchUiState>> = combine(
        chatStateFlow.filterNotNull(),
        myProfileStateFlow.filterToData(),
        candidateProfileFlow.filterNotNull()
    ) { conversation, myProfile, candidateProfile ->
        myProfile.matchZoneData?.let { matchZoneData ->
            UnmatchUiState(
                conversation = conversation.messages,
                myUid = myProfile.publicProfile.uid,
                unmatchedName = candidateProfile.firstName,
                isUserUnmatched = matchZoneData.matchStatus == MatchStatus.Active
            ).toSuccess()
        } ?: MainViewUiState.Loading
    }.stateIn(
        viewModelScope,
        SharingStarted.WhileSubscribed(5_000),
        MainViewUiState.Loading
    )

    init {
        triggerLlMResponse()
    }

    private fun triggerLlMResponse() {
        viewModelScope.launch {
            val myProfile = myProfileStateFlow.waitForSuccess()
            val candidateProfile = candidateProfileFlow.firstNotNull()
            val chatPath = DataRoot.unMatchAiPath(
                uid = myProfile.publicProfile.uid,
                candidateUid = candidateProfile.uid
            )
            chatStateFlow.filterNotNull().collect {
                val conversation = it.messages
                val lastMessage = conversation.lastOrNull()
                if (lastMessage != null && lastMessage.uid == myProfile.publicProfile.uid) {
                    llmCf.generateLlmResponse(
                        LlmUnmatchChatRequest(
                            chatPath = chatPath,
                            myProfile = myProfile.publicProfile,
                            candidateProfile = candidateProfile,
                            messages = conversation
                        )
                    )
                }
            }
        }
    }

    fun sendMessage(messageStr: String) {
        viewModelScope.launch {
            val myProfile = myProfileStateFlow.waitForSuccess()
            val myUid = myProfile.publicProfile.uid
            val candidateUid = myProfile.matchZoneData!!.candidateUid
            val chatPath = DataRoot.unMatchAiPath(
                uid = myUid,
                candidateUid = candidateUid
            )

            chtHandler.sendMessage(
                message = messageStr,
                senderUid = myUid,
                chatPath = chatPath
            )
        }
    }

    fun onUnmatchFinalClicked(
        onComplete: (SearchDestination.SearchMain) -> Unit
    ) {
        viewModelScope.launch {
            val myProfile = myProfileStateFlow.waitForSuccess()
            val candidateProfile = candidateProfileFlow.firstNotNull()
            umMatchFc.unMatch(
                UnMatchRequest(
                    requesterIdentity = myProfile.publicProfile.basicIdentity,
                    matchIdentity = candidateProfile.basicIdentity
                )
            )

            onComplete(
                SearchDestination.SearchMain
            )
        }
    }

}