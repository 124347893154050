package osg.uiZone.matchZone.entertainment

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.lifecycle.viewmodel.compose.viewModel
import com.osg.appData.matchZone.invitation.EntertainmentType
import com.osg.ice.ui.EntertainmentFeedScreen
import com.osg.truebase.ui.animation.LottieAnimationView
import com.osg.truebase.ui.animation.toResource
import com.osg.truebase.ui.commonStates.MainViewUi
import osg.uiZone.matchZone.model.EntertainmentFeedViewModel
import osg.uiZone.matchZone.state.EntFeedData
import osg.uiZone.matchZone.state.tmEntUi.EntertainmentDetailsUiType
import osg.uiZone.navigation.Destination
import osg.uiZone.navigation.EntertainmentDetailsDst
import osg.uiZone.navigation.IceBreakerDestination

@Composable
fun EntFeedDestination(
    entType: EntertainmentType,
    onDestination: (Destination) -> Unit,
) {
    val viewModel = viewModel(
        key = entType.name
    ) { EntertainmentFeedViewModel(entType) }
    val uiState by viewModel.feedState.collectAsState()
    uiState.MainViewUi(
        ladingContent = {
            LottieAnimationView(
                animationData = entType.properties.loadingAnimation.toResource(),
                modifier = Modifier.fillMaxSize(),
            )
        }
    ) { entFeedData ->
        TmEntertainmentFeedScreen(
            entFeedData = entFeedData,
            onDestination = onDestination
        )
    }
}

@Composable
fun TmEntertainmentFeedScreen(
    entFeedData: EntFeedData,
    modifier: Modifier = Modifier,
    onDestination: (Destination) -> Unit = {},
) {
    EntertainmentFeedScreen(
        modifier = modifier.padding(horizontal =  16.dp).padding(top = 16.dp),
        entCollections = entFeedData.entertainmentCollections,
        header = {
            BalanceCard(entFeedData.matchInfo)
        },
        onEntDetails = { entId ->
            val entertainmentDetailsUiType = entFeedData.toEntDetails(entId)
            val destination = when(entertainmentDetailsUiType){
                is EntertainmentDetailsUiType.ApprovedInvitation -> IceBreakerDestination(
                    entertainmentDetailsUiType.invitation
                )
                is EntertainmentDetailsUiType.ExistingInvitation -> {
                    EntertainmentDetailsDst(
                        entertainmentDetailsUiType
                    )
                }
                is EntertainmentDetailsUiType.NewInvitation -> {
                    EntertainmentDetailsDst(
                        entertainmentDetailsUiType
                    )
                }
            }
            onDestination(destination)
        }
    )
}