
import androidx.compose.animation.AnimatedContent
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.pager.VerticalPager
import androidx.compose.foundation.pager.rememberPagerState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalFontFamilyResolver
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import common.LandingPages
import common.WebBar
import common.trueMatchWebTypography
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import osg.cross.graphic.blurAll
import osg.cross.resource.loadResourceFont
import osg.cross.theme.TrueTheme
import pages.DialogStates
import pages.PopWaitlist
import truematch.web.generated.resources.Res
import truematch.web.generated.resources.noto_color_emoji


@Composable
fun WebLandingScreen(
    modifier: Modifier = Modifier,
    onSendMail : suspend (String) -> Unit = {
        delay(3000)
    },
    initialPage : Int = 0 // for preview,
) {
    val pages = LandingPages.entries
    TrueTheme(
        typography = trueMatchWebTypography,
    ) {
        val scope = rememberCoroutineScope()
        val pagerState = rememberPagerState(
            initialPage = initialPage,
            pageCount = {
                pages.size
            }
        )

        var dialogState by remember { mutableStateOf(DialogStates.Initial) }
        if (pagerState.settledPage == pages.size - 1 && DialogStates.Initial == dialogState) {
            dialogState = DialogStates.Show
        }

        Scaffold(
            modifier = modifier.fillMaxSize().blurAll(dialogState == DialogStates.Show),
            topBar = {
                WebBar(
                    modifier = Modifier
                        .fillMaxWidth(),
                    onWaitingListClick = {
                        dialogState = DialogStates.Show
                    }
                )
            }
        ) { innerPadding ->
            VerticalPager(
                state = pagerState,
            ) { pageIndex ->
                val landingPage = pages[pageIndex].landingPage
                landingPage.Content(
                    isSettled = pagerState.settledPage == pageIndex,
                    innerPadding = innerPadding
                )
            }
        }


        if (DialogStates.Show == dialogState) {
            Dialog(
                onDismissRequest = {
                    dialogState = DialogStates.Seen
                }
            ) {
                if (DialogStates.Show == dialogState) {
                    var isSent by remember { mutableStateOf(false) }
                    AnimatedContent(targetState = isSent) { targetSent ->
                        if (targetSent) {
                            ThankYou()
                            LaunchedEffect(Unit){
                                delay(2000)
                                dialogState = DialogStates.Sent
                            }
                        } else {
                            PopWaitlist { mail ->
                                scope.launch {
                                    onSendMail(mail)
                                    isSent = true
                                }
                            }
                        }
                    }
                }
            }
        }

        // https://github.com/JetBrains/compose-multiplatform-core/pull/1400 issue of font if need hebrew need to add as well
        val fontFamilyResolver = LocalFontFamilyResolver.current
        val ef = loadResourceFont(Res.font.noto_color_emoji)
        LaunchedEffect(ef) {
            fontFamilyResolver.preload(ef)
        }
    }
}

@Composable
fun ThankYou(
    modifier: Modifier = Modifier
){
    Box(
        modifier = modifier.clip(shape = RoundedCornerShape(16.dp))
            .background(color = Color.Green.copy(alpha = 0.2f))
            .padding(16.dp),
        contentAlignment = Alignment.Center,
    ) {
        Text(
            "Thank you for joining the waitlist 🤩",
            style = MaterialTheme.typography.headlineMedium,
            color = Color.White
        )
    }
}