package osg.uiZone.register.data

import androidx.compose.material.icons.Icons

import androidx.compose.ui.text.input.KeyboardType
import com.osg.appData.profile.FullProfile
import com.osg.appData.register.RegisterProfileTechnicalElements
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.user.UserLocation
import com.osg.truebase.ui.animation.CommonAnimation
import com.osg.truebase.ui.animation.toResource
import com.osg.truebase.ui.assigners.*
import com.osg.truebase.ui.assigners.data.genderAssigner
import com.osg.truebase.ui.assigners.data.nameQuestionAssigner
import com.osg.truebase.ui.assigners.def.GenericQuestionAssigner
import com.osg.truebase.ui.assigners.def.QuestionUiData
import com.osg.truebase.ui.assigners.def.genObjTransformer
import com.osg.truebase.ui.assigners.def.genQuestionTransformer
import com.osg.ui.core.commonStates.asTrueVector
import com.osg.truebase.ui.locale.localUnits
import com.osg.ui.core.resources.UiText
import com.osg.ui.core.resources.toUiText
import com.osg.ui.core.resources.uiText
import com.osg.utils.time.TimeService
import com.osg.appData.location.IAutoCompleteCities
import osg.uiZone.matchZone.icons.Toys
import osg.uiZone.profile.ProfileExt.setPreferencesAge
import truematch.uizone.generated.resources.*
import uiZone.trueAutoResources.LocalAnimations
import kotlin.math.max


fun FullProfile.buildProfileAssigners(
    currentLocation: UserLocation,
    asyncOptions: IAutoCompleteCities
): List<GenericQuestionAssigner<FullProfile>> {
    val notAnswered = RegisterProfileTechnicalElements.entries.filter {
        it.isNotAnswered(this)
    }
    return notAnswered.map {
        when (it) {
            RegisterProfileTechnicalElements.Name -> nameAssigner
            RegisterProfileTechnicalElements.LastName -> lastNameAssigner
            RegisterProfileTechnicalElements.Gender -> {
                genderAssigner.genQuestionTransformer(
                    reverseTransformer = { it.publicProfile.gender },
                    transformer = { a, t ->
                        t!!.copy(
                            publicProfile = t.publicProfile.copy(
                                gender = a
                            )
                        )
                    }
                )
            }

            RegisterProfileTechnicalElements.Date -> {
                GenericQuestionAssigner<FullProfile>(
                    questionUiData = QuestionUiData(
                        title = Res.string.birth_day_reg.uiText,
                        animationResource = LocalAnimations.BIRTHDAY_ANIM.toResource(),
                        directions = UiText.Empty
                    ),
                    genObjAssigner = DateGenAssigner(
                        isAnswerValid = { age ->
                            age >= 18
                        },
                    ).genObjTransformer(
                        reverseTransformer = { it.publicProfile.birthDay },
                        transformer = { a, t ->
                            t!!.copy(
                                publicProfile = t.publicProfile.copy(
                                    birthDay = a
                                )
                            )
                        }
                    )
                )
            }

            RegisterProfileTechnicalElements.DateGender -> {
                GenericQuestionAssigner<FullProfile>(
                    questionUiData = QuestionUiData(
                        title = Res.string.date_gender.uiText,
                        animationResource = CommonAnimation.CHEERS.trueAnimation,
                        directions = Res.string.select_one.uiText,
                    ),
                    genObjAssigner = genderAssigner.genObjAssigner.genObjTransformer(
                        reverseTransformer = { it.personalProfile.preferencesMatch.dateGender },
                        transformer = { a, t ->
                            t!!.copy(
                                personalProfile = t.personalProfile.copy(
                                    preferencesMatch = t.personalProfile.preferencesMatch.copy(
                                        dateGender = a
                                    )
                                )
                            )
                        }
                    )
                )
            }

            RegisterProfileTechnicalElements.Occupation -> {
                GenericQuestionAssigner<FullProfile>(
                    questionUiData = QuestionUiData(
                        title = Res.string.occupation_input.uiText,
                        animationResource = LocalAnimations.ANIMATION_WORK.toResource(),
                        directions = Res.string.occupation_input.uiText
                    ),
                    genObjAssigner = TextFieldAssigner(
                        directions = Res.string.occupation_input.uiText,
                        keyboardType = KeyboardType.Text,
                        validate = { true }
                    ).genObjTransformer(
                        reverseTransformer = { it.publicProfile.occupation },
                        transformer = { a, t ->
                            t!!.copy(
                                publicProfile = t.publicProfile.copy(
                                    occupation = a
                                )
                            )
                        }
                    )
                )
            }

            RegisterProfileTechnicalElements.Height -> {
                GenericQuestionAssigner<FullProfile>(
                    questionUiData = QuestionUiData(
                        title = Res.string.enter_your_height.uiText,
                        animationResource = LocalAnimations.ANIMATION_HEIGHT.toResource(),
                        directions = Res.string.enter_your_height.toUiText(
                            localUnits.getHeightMetricUnit()
                        ),
                    ),
                    genObjAssigner = SliderGenAssigner(
                        initialAnswer = 170,
                        range = 120f..230f,
                        thumbIcon = Res.drawable.tape.asTrueVector(),
                    ).genObjTransformer(
                        reverseTransformer = { it.publicProfile.height },
                        transformer = { a, t ->
                            t!!.copy(
                                publicProfile = t.publicProfile.copy(
                                    height = a
                                )
                            )
                        }
                    )
                )
            }

            RegisterProfileTechnicalElements.AgePreferences -> {
                GenericQuestionAssigner<FullProfile>(
                    questionUiData = QuestionUiData(
                        title = Res.string.age_range.uiText,
                        animationResource = LocalAnimations.ANIMATION_AGE_RANGE.toResource(),
                        directions = Res.string.age_range.uiText
                    ),
                    genObjAssigner = RangeSliderGenAssigner(
                        initialAnswer = defaultAgeRange().also {
                            AppLogger.d("defaultAgeRange: $it")
                        },
                        range = 18f..70f,
                    ).genObjTransformer(
                        reverseTransformer = {
                            val min = it.personalProfile.preferencesMatch.ageMin
                            val max = it.personalProfile.preferencesMatch.ageMax
                            if (min != null && max != null) {
                                min.toFloat()..max.toFloat()
                            } else {
                                null
                            }
                        },
                        transformer = { a, t ->
                            t!!.apply {
                                setPreferencesAge(a)
                            }
                        }
                    )
                )
            }

            RegisterProfileTechnicalElements.DistancePreferences -> {
                GenericQuestionAssigner<FullProfile>(
                    questionUiData = QuestionUiData(
                        title = Res.string.distance_range.uiText,
                        animationResource = CommonAnimation.FLY.trueAnimation,
                        directions = Res.string.slider_direction.uiText,
                    ),
                    genObjAssigner = SliderGenAssigner(
                        initialAnswer = 35,
                        range = 1f..200f,
                        thumbIcon = Icons.Filled.Toys.asTrueVector(),
                    ).genObjTransformer(
                        reverseTransformer = { it.personalProfile.preferencesMatch.maxDistance },
                        transformer = { a, t ->
                            t!!.copy(
                                personalProfile = t.personalProfile.copy(
                                    preferencesMatch = t.personalProfile.preferencesMatch.copy(
                                        maxDistance = a
                                    )
                                )
                            )
                        }
                    )
                )
            }

            RegisterProfileTechnicalElements.Location -> {
                getLocationAssigner(currentLocation){
                    asyncOptions.autocompleteCitiesNearby(
                        userInput = it,
                        countryCode = currentLocation.countryCode
                    )
                }
            }
        }
    }
}

fun getLocationAssigner(
    userLocation: UserLocation,
    asyncOptions: suspend (String) -> List<String>,
): GenericQuestionAssigner<FullProfile> {
    return GenericQuestionAssigner<FullProfile>(
        genObjAssigner = DropDownAssigner(
            initialOptions = emptyList(),
            directions = Res.string.select_one.uiText,
            asyncOptions = asyncOptions
        ).genObjTransformer(
            reverseTransformer = { it.publicProfile.location?.city },
            transformer = { a, t ->
                t!!.copy(
                    publicProfile = t.publicProfile.copy(
                        location = userLocation.copy(
                            city = a,
                        )
                    )
                )
            }
        ),
        questionUiData = QuestionUiData(
            title = Res.string.city_input.toUiText(userLocation.country),
            animationResource = LocalAnimations.ANIMATION_HOME.toResource(),
            directions = Res.string.select_one.uiText
        )
    )
}


val nameAssigner: GenericQuestionAssigner<FullProfile> = nameQuestionAssigner.genQuestionTransformer(
    reverseTransformer = { it.publicProfile.firstName },
    transformer = { a, t ->
        t!!.copy(
            publicProfile = t.publicProfile.copy(
                firstName = a
            )
        )
    }
)

val lastNameAssigner: GenericQuestionAssigner<FullProfile> = nameQuestionAssigner.copy(
    questionUiData = nameQuestionAssigner.questionUiData.copy(
        title = Res.string.your_last_name_is.uiText,
        directions = Res.string.your_last_name_is.uiText,
    )
).genQuestionTransformer(
    reverseTransformer = { it.personalProfile.lastName },
    transformer = { a, t ->
        t!!.copy(
            personalProfile = t.personalProfile.copy(
                lastName = a
            )
        )
    }
)

fun FullProfile.defaultAgeRange(): ClosedFloatingPointRange<Float> {
    val currentAge = this.publicProfile.birthDay?.let {
        TimeService.calculateAge(it)
    } ?: 22
    return max(currentAge - 5f, 22f)..max(currentAge + 5f, 18f + 10f)
}