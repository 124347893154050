package com.osg.appData.matchZone.ice


enum class IcebreakerCollection{
    ChallengeAccepted,
    HarmonyTunes,
    BlindCanvas,
    LieAi,
    CheckList,
    InPicture,
    Janga,
    SwipeEscape,
    WhoWants,
    PieceOfMatch,
    EmojiResonance,
    ;

    companion object{

    }
}