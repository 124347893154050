package com.osg.truebase.player.audio

import com.osg.truebase.data.nodeData.IRemoteMediaResource
import com.osg.truebase.data.nodeData.RemoteAudio
import com.osg.truebase.data.resources.ILocalMediaResource
import com.osg.truebase.io.writeToTempFile
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.withContext
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.readResourceBytes

class TrueAudioPlayer(
    private val audioPlayer: IPlatformAudioPlayer
){
    private val playerStateFlow = MutableStateFlow(PlayerState.Paused)
    val playerState: StateFlow<PlayerState> = playerStateFlow
    suspend fun playAt(
        atTime: Instant
    ){
        val offset = atTime - Clock.System.now()
        delay(offset)
        play()
    }

    suspend fun play(){
        withContext(Dispatchers.Main) {
            audioPlayer.play()
            playerStateFlow.value = PlayerState.Playing
        }
    }

    suspend fun pause(){
        withContext(Dispatchers.Main) {
            audioPlayer.pause()
            playerStateFlow.value = PlayerState.Paused
        }
    }
    suspend fun stop(){
        withContext(Dispatchers.Main) {
            audioPlayer.stop()
            playerStateFlow.value = PlayerState.Finished
        }
    }
    suspend fun setVolume(volume: Float){
        withContext(Dispatchers.Main) {
            audioPlayer.setVolume(volume)
        }
    }

    suspend fun seekTo(positionMs: Long){
        withContext(Dispatchers.Main) {
            audioPlayer.seekTo(positionMs)
        }
    }

    suspend fun setTrack(hashName: String){
        withContext(Dispatchers.Main) {
            audioPlayer.setTrack(hashName)
        }
    }

    suspend fun addMediaItem(audioMedia: TrueAudio) = audioPlayer.addMediaItem(audioMedia)
    fun setBoundary(endMs: Long, onBoundary: () -> Unit = {}) = audioPlayer.setBoundary(endMs){
        playerStateFlow.value = PlayerState.Finished
        onBoundary()
    }

    suspend fun initialize(audioItems: List<TrueAudio>) = audioPlayer.initialize(audioItems)
    fun release() = audioPlayer.release()
}

@OptIn(InternalResourceApi::class)
fun ILocalMediaResource.asTrueAudio() = TrueAudio.Resource(resourceFullPath = this.mediaPath)

sealed class TrueAudio(
    val hashName: String,
){
    data class Resource(
        val resourceFullPath: String,
    ) : TrueAudio(
        hashName = resourceFullPath.split("/").last().split(".").first()
    )

    data class Raw(
        val byteArray: ByteArray,
        override val mediaPath: String
    ) : TrueAudio(
        hashName = mediaPath
    ), IRemoteMediaResource {
        suspend fun createFile(): String{
            val fileName = mediaPath.split("/").last()
            val filePath = writeToTempFile(fileName, byteArray)
            return filePath
        }
        override fun equals(other: Any?): Boolean {
            if (this === other) return true
            if (other == null || this::class != other::class) return false

            other as Raw

            if (!byteArray.contentEquals(other.byteArray)) return false
            if (mediaPath != other.mediaPath) return false

            return true
        }

        override fun hashCode(): Int {
            var result = byteArray.contentHashCode()
            result = 31 * result + mediaPath.hashCode()
            return result
        }

    }
    @OptIn(InternalResourceApi::class, ExperimentalResourceApi::class)
    suspend fun readBytes() = when(this){
        is Resource -> readResourceBytes(resourceFullPath)
        is Raw -> byteArray
    }
}