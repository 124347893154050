package osg.uiZone.matchZone.sparkMove

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.pager.HorizontalPager
import androidx.compose.foundation.pager.rememberPagerState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.TileMode
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.util.lerp
import com.osg.truebase.ui.paint.PreGradientBackground
import com.osg.truebase.ui.paint.drawDiagonalLabel
import com.osg.ui.core.resources.painter
import com.osg.ui.core.resources.string
import com.osg.truebase.ui.theme.spacing
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.Soon
import truematch.uizone.generated.resources.move_title
import kotlin.math.absoluteValue


@Composable
fun SparkMovePanel(
    onSparkMoveClick: (SparkMovesCollection) -> Unit,
    modifier: Modifier = Modifier
) {
    Card(
        modifier = modifier.padding(horizontal = MaterialTheme.spacing.medium),
        shape = RoundedCornerShape(16.dp),
    ) {
        Column(
            modifier = Modifier.padding(8.dp)
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Text(
                    modifier = Modifier,
                    text = Res.string.move_title.string,
                    style = MaterialTheme.typography.displaySmall.copy(
                        fontWeight = FontWeight.Bold
                    ),
                    minLines = 2,
                )
                Text(
                    modifier = Modifier,
                    text = "💫",
                    style = MaterialTheme.typography.displaySmall.copy(
                        fontWeight = FontWeight.Bold
                    ),
                )
            }
            Spacer(modifier = Modifier.height(MaterialTheme.spacing.medium))
            SparkMovePager(
                modifier = Modifier,
                onSparkMoveClick = onSparkMoveClick
            )
        }
    }
}


@Composable
fun SparkMoveItem(
    sparkMoveItem: SparkMovesCollection,
    onSparkMoveClick: (SparkMovesCollection) -> Unit,
    modifier: Modifier = Modifier
) {
    ElevatedCard(
        modifier = modifier
            .width(200.dp)
            .height(300.dp)
    ) {
        Column(
            modifier = Modifier
                .clickable(
                    onClick = { onSparkMoveClick(sparkMoveItem) },
                    enabled = sparkMoveItem.sparkMoveData.enabled
                )
                .then(
                    if (sparkMoveItem.sparkMoveData.enabled) {
                        Modifier
                    } else {
                        Modifier.drawDiagonalLabel(
                            text = Res.string.Soon.string,
                            color = MaterialTheme.colorScheme.error,
                            style = MaterialTheme.typography.titleSmall.copy(
                                color = MaterialTheme.colorScheme.onError
                            ),
                            labelTextRatio = 5f
                        )
                    }
                )
        ) {
            Box(
                modifier = Modifier
                    .height(160.dp)
                    .fillMaxWidth()
            ) {
                Box(
                    modifier = Modifier
                        .height(100.dp)
                        .fillMaxWidth()
                        .background(
                            Brush.verticalGradient(
                                colors = PreGradientBackground.SparkMoveGradient.colors,
                                tileMode = TileMode.Mirror
                            )
                        )
                )
                Image(
                    painter = sparkMoveItem.sparkMoveData.image.painter,
                    contentDescription = null,
                    modifier = Modifier
                        .size(120.dp)
                        .align(Alignment.BottomCenter)
                        .clip(CircleShape)
                        .background(
                            Brush.horizontalGradient(
                                colors = PreGradientBackground.SparkMoveGradient.colors.map {
                                    it.copy(
                                        alpha = 0.5f
                                    )
                                },
                                tileMode = TileMode.Mirror
                            )
                        )
                        .padding(16.dp),
                    contentScale = ContentScale.FillWidth,
                )
            }
            Spacer(modifier = Modifier.height(8.dp))
            SparkMoveItemContent(
                title = sparkMoveItem.sparkMoveData.name.string,
                description = sparkMoveItem.sparkMoveData.description.string
            )
        }
    }
}

@Composable
fun SparkMoveItemContent(
    title: String,
    description: String,
    modifier: Modifier = Modifier
) {
    Column(
        modifier = modifier.padding(horizontal = MaterialTheme.spacing.small),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small)
    ) {
        Text(
            text = title,
            maxLines = 2,
            style = MaterialTheme.typography.titleSmall,
            textAlign = TextAlign.Center,
            modifier = Modifier
        )
        Text(
            text = description,
            style = MaterialTheme.typography.bodySmall,
            color = MaterialTheme.colorScheme.secondary,
            textAlign = TextAlign.Center,
            modifier = Modifier
        )
    }
}

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun SparkMovePager(
    modifier: Modifier = Modifier,
    onSparkMoveClick: (SparkMovesCollection) -> Unit,
) {
    val movesData = SparkMovesCollection.entries.toList()
    val pagerState = rememberPagerState(
        initialPage = 1,
        pageCount = {
            movesData.size
        })
    HorizontalPager(
        modifier = modifier,
        state = pagerState,
        contentPadding = PaddingValues(horizontal = 88.dp),
    ) { page ->
        SparkMoveItem(
            modifier = Modifier.graphicsLayer {
                val pageOffset = (
                        (pagerState.currentPage - page) + pagerState
                            .currentPageOffsetFraction
                        ).absoluteValue
                lerp(
                    start = 0.85f,
                    stop = 1f,
                    fraction = 1f - pageOffset.coerceIn(0f, 1f)
                ).also { scale ->
                    scaleX = scale
                    scaleY = scale
                }
                alpha = lerp(
                    start = 0.5f,
                    stop = 1f,
                    fraction = 1f - pageOffset.coerceIn(0f, 1f)
                )
            },
            sparkMoveItem = movesData[page],
            onSparkMoveClick = onSparkMoveClick
        )
    }
}