package com.osg.appData.profile


import com.osg.truebase.data.nodeData.IRemoteProfileImage
import com.osg.truebase.data.nodeData.RemoteProfileImage
import com.osg.truebase.data.user.Gender
import com.osg.truebase.data.user.UserLocation
import com.osg.utils.time.TimeService
import kotlinx.serialization.Serializable

interface IPublicProfile {
    val uid: String
    val profileItemList: List<ProfileItem>
    val openQuestionAnswer: List<OpenAnswer>
    val firstName: String
    var birthDay: String?
    val location: UserLocation?
    val gender: Gender?
    val height: Int?
    val stage: UserStage
    val occupation: String
    val verified: Boolean
    val stageHighLights: List<IStageHighLight>
    val picturesCommentList: List<IRemoteProfileImage>
    val age: Int
        get() {
            return TimeService.calculateAge(birthDay!!)
        }
}

@Serializable
data class PublicProfile(
    override var uid: String = "",
    override var profileItemList: List<ProfileItem> = emptyList(),
    override var openQuestionAnswer: List<OpenAnswer> = emptyList(),
    override var firstName: String = "",
    override var birthDay: String? = null,
    override var location: UserLocation? = null,
    override var gender: Gender? = null,
    override var height: Int? = null,
    override var stage: UserStage = UserStage.NEW,
    override var occupation: String = "",
    override var verified: Boolean = true,
    override val stageHighLights: List<StageHighLight> = emptyList(),
    override var picturesCommentList: List<RemoteProfileImage> = emptyList(),
) : IPublicProfile

