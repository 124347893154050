package baseModuleSim

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.osg.def.auth.IPlatformAuth
import com.osg.truebase.data.user.SignInIdentifier
import com.osg.truebase.signin.ISignInProvider
import com.osg.truebase.signin.SignInResult
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow


class AuthSim(
    val underTestUid: String? = null
) : IPlatformAuth {
    val uidState = MutableStateFlow<String?>(underTestUid)

    override fun signOut() {
        uidState.value = null
    }

    override suspend fun signInTestUser(email: String, password: String) {
        uidState.value = underTestUid
    }

    override suspend fun removeAccount() {
        uidState.value = null
    }

    override fun getUidFlow(): Flow<String?> = uidState
}



class GoogleSignInSim(
    private val result: Result<SignInResult> = Result.success(SignInResult(uid = "testUid"))
) : ISignInProvider {
    override val identifier: SignInIdentifier = SignInIdentifier.Google

    @Composable
    override fun SignInDialog(onComplete: (Result<SignInResult>) -> Unit) {
        LaunchedEffect(Unit) {
            onComplete(
                result
            )
        }
    }
}

class AppleSignInSim(
    private val result: Result<SignInResult> = Result.success(SignInResult(uid = "testUid"))
) : ISignInProvider {
    override val identifier: SignInIdentifier = SignInIdentifier.Apple

    @Composable
    override fun SignInDialog(onComplete: (Result<SignInResult>) -> Unit) {
        LaunchedEffect(Unit) {
            onComplete(
                result
            )
        }
    }
}
