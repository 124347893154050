package com.osg.matchmaker.ml

import appData.trueAutoResources.MatchingInterest
import com.osg.appData.ml.LlmMessage
import com.osg.appData.ml.LlmPrompts
import com.osg.appData.profile.FullProfile


fun buildLlmInput(
    matchingFeatures: List<MatchingInterest>,
    requestProfile: FullProfile,
    candidateProfile: FullProfile
): LlmPrompts {
    val strFeatures = matchingFeatures.map {
        it.englishValue
    }
    val matchingPointsText = strFeatures.joinToString { it }
    val prompt = "Explain why the matching points you calculated: $matchingPointsText" +
            " are crucial for ${requestProfile.publicProfile.firstName}" +
            " and ${candidateProfile.publicProfile.firstName} matching. " +
            "Start your answer directly with the analysis " +
            "like you approaching both ${candidateProfile.publicProfile.firstName} " +
            "and ${requestProfile.publicProfile.firstName}"

    return LlmPrompts(
        messages = listOf(
            LlmMessage(
                message = prompt
            )
        ),
    )
}

