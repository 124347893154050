package osg.uiZone.register.ds

import androidx.compose.runtime.Immutable
import com.osg.appData.profile.FullProfile
import com.osg.truebase.ui.assigners.def.GenericQuestionAssigner

@Immutable
sealed interface RegisterScreenState {
    @Immutable
    data class DeepQuestionsIntro(
        val initialProfile: FullProfile,
        val videoPath: String = ""
    ) : RegisterScreenState

    @Immutable
    data class Questions(
        val initialProfile: FullProfile,
        val questionData: List<GenericQuestionAssigner<FullProfile>>,
    ) : RegisterScreenState

    @Immutable
    data class AddPictures(
        val minimumImageToSave: Int,
    ) : RegisterScreenState
}