package osg.uiZone.search.components

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.height
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.outlined.Person
import androidx.compose.material.icons.outlined.Settings
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.BlendMode
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import osg.cross.landing.ds.CommonResources
import osg.uiZone.matchZone.icons.Tune
import osg.uiZone.navigation.Destination
import osg.uiZone.navigation.ProfileEditDestination
import osg.uiZone.navigation.SearchDestination
import osg.uiZone.navigation.SettingsDestination


@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun SearchScreenTopBar(
    onNavigation: (Destination) -> Unit,
) {
    CenterAlignedTopAppBar(
        modifier = Modifier,
        title = {
            Image(
                modifier = Modifier.height(42.dp),
                painter = CommonResources.LogoText.painter,
                contentDescription = null,
                contentScale = ContentScale.FillHeight,
                colorFilter = ColorFilter.tint(
                    MaterialTheme.colorScheme.primary.copy(
                        alpha = 0.5f
                    ),
                    blendMode = BlendMode.SrcAtop,
                )
            )
        },
        navigationIcon = {
            IconButton(
                onClick = {
                    onNavigation(ProfileEditDestination)
                },
            ) {
                Icon(
                    imageVector = Icons.Outlined.Person,
                    contentDescription = null
                )
            }
        },
        actions = {
            IconButton(
                modifier = Modifier,
                onClick = {
                    onNavigation(SettingsDestination)
                },
            ) {
                Icon(
                    imageVector = Icons.Outlined.Settings,
                    contentDescription = null
                )
            }
            IconButton(
                onClick = {
                    onNavigation(SearchDestination.Preference)
                },
            ) {
                Icon(
                    imageVector = Icons.Outlined.Tune,
                    contentDescription = null
                )
            }
        },
    )
}