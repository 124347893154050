package osg.uiZone.common.baseRepos.database

import com.osg.appData.matchZone.MatchInfo
import com.osg.def.database.DatabaseService
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.filterNotNull

fun interface IMatchInfoFlow {
    fun getMatchInfoFlow(path: String): Flow<MatchInfo>
}

val DatabaseService.matchInfoFlow: IMatchInfoFlow
    get() = IMatchInfoFlow{ path ->
        onValueChangeFlow<MatchInfo>(path).filterNotNull()
    }