package osg.uiZone.matchZone.journey.items

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.LocationOn
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.color.ImageDetector
import com.osg.ui.core.commonStates.asBitmap
import com.osg.ui.core.resources.string
import com.osg.utils.time.TimeService.secToUtcDateTime
import osg.uiZone.matchZone.journey.journeyTitleWeight
import osg.uiZone.matchZone.state.JourneyPanelItem
import osg.uiZone.matchZone.state.SparkEvent


@Composable
fun JourneyEvents(
    journeySaveTheData: JourneyPanelItem.SaveTheDate,
    modifier: Modifier = Modifier
) {
    Column(
        modifier = modifier.padding(16.dp),
        verticalArrangement = Arrangement.spacedBy(10.dp)
    ) {
        Text(
            style = MaterialTheme.typography.titleMedium.copy(
                fontWeight = journeyTitleWeight
            ),
            text = "SAVE THE DATE"
        )
        Column(
            verticalArrangement = Arrangement.spacedBy((-70).dp)
        ) {
            journeySaveTheData.sparkEvents.forEachIndexed { index, sparkEvent ->
                EventCard(
                    sparkEvent = sparkEvent,
                    containerColor = MaterialTheme.colorScheme.surfaceContainerLow
                )
            }
        }
    }
}


@Composable
fun EventCard(
    sparkEvent: SparkEvent,
    containerColor: Color
) {
    ElevatedCard(
        modifier = Modifier
            .width(320.dp)
            .height(120.dp),
        colors = CardDefaults.cardColors(
            containerColor
        )
    ) {
        var colorState by remember { mutableStateOf(Color.White) }
        val colorStops = listOf(
            colorState.copy(alpha = 0.3f),
            colorState
        )
        Row(
            modifier = Modifier.background(
                brush = Brush.horizontalGradient(colorStops)
            ),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Column(
                modifier = Modifier
                    .padding(8.dp)
                    .weight(5f)
                    .fillMaxHeight(),
                verticalArrangement = Arrangement.SpaceBetween
            ) {
                DateDisplay(
                    dateTimeEpoch = sparkEvent.dateTimeEpoch
                )
                LocationDisplay(
                    location = sparkEvent.entertainment.name.string
                )

            }
            ImageDetector(
                imageBitmap = sparkEvent.entertainment.image.asBitmap(),
                contentScale = androidx.compose.ui.layout.ContentScale.Crop,
                contentDescription = null,
                modifier = Modifier
                    .fillMaxHeight()
                    .weight(3f),
                onDetect = {
                    colorState = it
                }
            )
        }
    }
}

@Composable
fun LocationDisplay(
    location: String,
    modifier: Modifier = Modifier
) {
    Row(
        modifier = modifier,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(
            modifier = Modifier.size(16.dp),
            imageVector = Icons.Default.LocationOn,
            contentDescription = "Example Icon"
        )
        Spacer(modifier = Modifier.width(8.dp))
        Text(
            text = location,
            style = MaterialTheme.typography.labelSmall
        )
    }
}


@Composable
fun DateDisplay(
    dateTimeEpoch: Long,
    modifier: Modifier = Modifier
) {
    val dateTime = dateTimeEpoch.secToUtcDateTime()
    Column(
        modifier = modifier,
    ) {
        Text(
            text = dateTime.dayOfWeek.name,
            style = MaterialTheme.typography.bodySmall
        )

        Text(
            text = "${dateTime.month.name.take(3)} ${dateTime.dayOfMonth}",
            style = MaterialTheme.typography.titleMedium.copy(
                fontWeight = FontWeight.Bold
            )
        )
    }
}