package com.osg.def.auth

import com.osg.truebase.data.logger.AppLogger
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.stateIn

fun interface IUidStatus {
    fun getUid(): String?
}

fun isTesterUid(uid: String?): Boolean{
    return uid?.startsWith(TEST_USER_UID_PREFIX) == true
}

const val TEST_USER_UID_PREFIX = "testUserUidPrefix"


class AuthService(
    private val authEngine: IPlatformAuth
): IUidStatus,
    ISignOut,
    IRemoveAccount,
    ILoggedStateProvider,
    IPlatformAuth by authEngine {
    override fun getUid(): String? {
        loggedStateFlow.value.let {
            return when(it){
                is LoggedStates.Loggedin -> it.uid
                else -> null
            }
        }
    }

    override val loggedStateFlow: StateFlow<LoggedStates> = getUidFlow().map {
        if (it == null) {
            LoggedStates.LoggedOut
        } else {
            LoggedStates.Loggedin(it)
        }
    }.stateIn(
        scope = CoroutineScope(Dispatchers.Default),
        started = SharingStarted.WhileSubscribed(),
        initialValue = LoggedStates.Loading
    )

    fun verifyNoDebugTraces(){
        try {
            val isTestUser = isTesterUid(getUid())
            if (isTestUser) {
                signOut()
            }
        }catch (e: Exception){
            AppLogger.e("verifyNoDebugTraces failed with exception: $e")
        }
    }
}