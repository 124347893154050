package com.osg.truematch.demo.baseModuleSim

import com.osg.appData.location.IAutoCompleteCities
import com.osg.appData.location.IGeo
import com.osg.appData.location.IPlatformGps
import com.osg.truebase.data.user.GeoLocationDegrees
import com.osg.truebase.data.user.UserLocation
import com.osg.truebase.preview.data.IsraelLocation
import kotlinx.coroutines.delay


class GeoSim: IGeo{
    override suspend fun fetchCurrentLocation(geoLocationDegrees: GeoLocationDegrees): UserLocation {
        return IsraelLocation.entries.first().location
    }

    override suspend fun autocompleteCitiesNearby(
        countryCode: String,
        userInput: String
    ): List<String> {
        return fetchMoreOptions(userInput)
    }
}

class PlatformGpsSim(
    val currentLocation: UserLocation = IsraelLocation.entries.first().location
): IPlatformGps{
    override suspend fun fetchGpsLocation(): GeoLocationDegrees {
        return currentLocation
    }
}

val iAutoCompleteCities = IAutoCompleteCities{ countryCode, userInput ->
    val menuItemData = IsraelLocation.entries.map {
        it.city
    }
    val itemsToShow = menuItemData
        .sortedByDescending {
            it.contains(userInput, ignoreCase = true)
        }
    delay(1000)
    itemsToShow
}

suspend fun fetchMoreOptions(query: String): List<String> {
    val menuItemData = IsraelLocation.entries.map {
        it.city
    }
    val itemsToShow = menuItemData
        .sortedByDescending {
            it.contains(query, ignoreCase = true)
        }
    delay(1000)
    return itemsToShow
}
