package com.osg.truebase.ui.signIn

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.ExtendedFloatingActionButton
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.osg.truebase.data.user.SignInIdentifier
import com.osg.truebase.ui.generated.resources.*
import com.osg.truebase.ui.graphics.shimmerLoadingAnimation
import com.osg.truebase.ui.strings.CommonStringRes
import com.osg.ui.core.resources.asFormattedText
import com.osg.ui.core.resources.vector
import com.osg.truebase.ui.utills.plus
import com.osg.truebase.ui_strings.generated.resources.apple_login
import com.osg.truebase.ui_strings.generated.resources.facebook_login
import com.osg.truebase.ui_strings.generated.resources.google_login
import com.osg.truebase.ui_strings.generated.resources.sign_button_format
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.StringResource

@Immutable
sealed class BrandedButtonType(
    val drawableResource: DrawableResource,
    val iconHeight: Dp,
    val backgroundColor: Color,
    val textColor: Color,
    val stringRes: StringResource
) {
    data object DarkFacebookButton : BrandedButtonType(
        drawableResource = Res.drawable.facebook_logo_white,
        iconHeight = 25.dp,
        backgroundColor = Color(0xFF1877f2),
        textColor = Color.White,
        stringRes = CommonStringRes.facebook_login
    )

    data object GoogleButton : BrandedButtonType(
        drawableResource = Res.drawable.google_color,
        iconHeight = 25.dp,
        backgroundColor = Color.White,
        textColor = Color.Black,
        stringRes = CommonStringRes.google_login
    )

    data object AppleButton : BrandedButtonType(
        drawableResource = Res.drawable.ic_ios,
        iconHeight = 25.dp,
        backgroundColor = Color.White,
        textColor = Color.Black,
        stringRes = CommonStringRes.apple_login
    )
}

val SignInIdentifier.brandedButtonType: BrandedButtonType
    get() = when (this) {
        SignInIdentifier.Facebook -> BrandedButtonType.DarkFacebookButton
        SignInIdentifier.Google -> BrandedButtonType.GoogleButton
        SignInIdentifier.Apple -> BrandedButtonType.AppleButton
    }

@Composable
fun BrandedButton(
    modifier: Modifier = Modifier,
    brandedButtonType: BrandedButtonType,
    onClick: () -> Unit = {},
    textStyle: TextStyle = LocalTextStyle.current.copy(fontWeight = FontWeight.Bold),
) {
    var isClicked by remember { mutableStateOf(false) }
    ExtendedFloatingActionButton(
        modifier = modifier.aspectRatio(6f).shimmerLoadingAnimation(isClicked, cornerSize = 10.dp),
        shape = RoundedCornerShape(10.dp),
        containerColor = brandedButtonType.backgroundColor,
        onClick = onClick + { isClicked = true },
        icon = {
            Image(
                modifier = Modifier.height(brandedButtonType.iconHeight),
                imageVector = brandedButtonType.drawableResource.vector,
                contentDescription = null
            )
        },
        text = {
            Box(
                modifier = Modifier
                    .fillMaxSize(),
                contentAlignment = Alignment.Center
            )
            {
                Text(
                    text = CommonStringRes.sign_button_format.asFormattedText(brandedButtonType.stringRes),
                    maxLines = 1,
                    style = textStyle.copy(color = brandedButtonType.textColor)
                )
            }

        },
    )
}

