package osg.uiZone.common.pyrotechnics.text

import androidx.compose.animation.Animatable
import androidx.compose.animation.core.LinearOutSlowInEasing
import androidx.compose.animation.core.RepeatMode
import androidx.compose.animation.core.infiniteRepeatable
import androidx.compose.animation.core.tween
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.text.BasicText
import androidx.compose.material3.LocalTextStyle
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.TransformOrigin
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.text.font.FontFamily

import androidx.compose.ui.text.style.TextMotion
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.Font
import truematch.uizone.generated.resources.Res
import truematch.uizone.generated.resources.dancing_script

val dancingScriptFamily: FontFamily
    @Composable
    get() = FontFamily(Font(Res.font.dancing_script))

@Composable
fun AnimateTextColor(
    text: String,
    modifier: Modifier = Modifier,
    fontFamily: FontFamily = dancingScriptFamily,
    animate: Boolean = true,
    srcColor: Color = Color.White
) {
    val animatedColor = remember { Animatable(srcColor) }
    val scale = remember { androidx.compose.animation.core.Animatable(2f) }
    LaunchedEffect(animate) {
        if (animate) {
            coroutineScope {
                launch {
                    animatedColor.animateTo(
                        targetValue = Color.Red,
                        animationSpec = infiniteRepeatable(tween(2000), RepeatMode.Reverse)
                    )
                }
                launch {
                    scale.animateTo(
                        targetValue = 4f,
                        animationSpec = infiniteRepeatable(tween(2000), RepeatMode.Reverse)
                    )
                }
            }
        }
        else{
            animatedColor.animateTo(
                targetValue = Color.Red,
                animationSpec = tween(
                    durationMillis = 3000,
                    easing = LinearOutSlowInEasing
                )
            )
        }
    }

    Box(modifier = modifier) {
        BasicText(
            modifier = Modifier
                .graphicsLayer {
                    scaleX = scale.value
                    scaleY = scale.value
                    transformOrigin = TransformOrigin.Center
                }
                .align(Alignment.Center),

            text = text,
            color = {
                animatedColor.value
            },
            style = LocalTextStyle.current.merge(
                fontFamily = fontFamily,
                textMotion = TextMotion.Animated
            ),
        )
    }
}
