package osg.uiZone.profile

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material.icons.Icons

import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import com.osg.appData.profile.OpenAnswer
import com.osg.appData.profile.PublicProfile
import com.osg.truebase.ui.graphics.shimmerRadialAnimation
import com.osg.utils.replaceOrAdd
import osg.uiZone.common.bar.FloatingNavFab
import osg.uiZone.matchZone.icons.Save
import osg.uiZone.matchZone.model.EditImageType
import osg.uiZone.matchZone.model.LocalProfileImage
import osg.uiZone.search.screens.ProfileItemsList

data class ProfileEditUiState(
    val publicProfile: PublicProfile,
    val profileImages: List<EditImageType>,
)

enum class SaveState{
    SAVING,
    SAVED,
    NOT_SAVED
}

@Composable
fun ProfileEditScreen(
    editProfileScreenState: ProfileEditUiState,
    onExit: () -> Unit,
    onSave: (ProfileEditUiState) -> Unit,
    modifier: Modifier = Modifier,
){
    var profileState by remember { mutableStateOf(editProfileScreenState.publicProfile) }
    var profileImages by remember(editProfileScreenState.profileImages) { mutableStateOf(editProfileScreenState.profileImages) }
    val profileItemsState = resolveProfileItemsState(
        publicProfile = profileState,
        profileImages = profileImages,
        onImageEdit = { profileImages = it },
        onChange = { profileState = it }
    )
    var saveState by remember(
        editProfileScreenState,
        profileState,
        profileImages
    ) { mutableStateOf(
        if (profileImages.all { it is LocalProfileImage || profileState != editProfileScreenState.publicProfile }){
            SaveState.NOT_SAVED
        }else {
            SaveState.SAVED
        }
    ) }
    ProfileItemsList(
        modifier = modifier.fillMaxSize(),
        profileItemsState = profileItemsState,
        onBack = onExit,
        extraNavigationFab = {

            FloatingNavFab(
                modifier = Modifier.shimmerRadialAnimation(
                    saveState == SaveState.SAVING
                ),
                enabled = saveState == SaveState.NOT_SAVED,
                vector = Icons.Default.Save,
            ){
                saveState = SaveState.SAVING
                onSave(
                    ProfileEditUiState(
                        publicProfile = profileState,
                        profileImages = profileImages
                    )
                )
            }
        }
    )
}



fun resolveProfileItemsState(
    publicProfile: PublicProfile,
    profileImages: List<EditImageType>,
    onImageEdit: (List<EditImageType>) -> Unit,
    onChange: (PublicProfile) -> Unit
): ProfileUiItems {
    return publicProfile.profileItemsState(
        technicalDetailsAction = listOf(
            ItemToolOption.Edit(
                onCommit = { editableDetails ->
                    onChange(
                        publicProfile.onTechnicalDetailsEdit(editableDetails)
                    )
                }
            )
        ),
        imageCommentCardsActions = buildList {
            add(
                ItemToolOption.Edit(
                    onCommit = {
                        onImageEdit(
                            profileImages.editImage(it)
                        )
                    },
                )
            )
            if (publicProfile.picturesCommentList.size > 3) {
                add(
                    ItemToolOption.Delete(
                        onDelete = {
                            onImageEdit(
                                profileImages.deleteImageComment(it)
                            )
                        }
                    )
                )
            }
        },
        textCardActions = listOf(
            ItemToolOption.Edit(
                onCommit = {
                    onChange(
                        publicProfile.onOpenQuestionEdit(it)
                    )
                }
            )
        ),
        profileImages = profileImages.map { it.trueImage }
    )
}

fun PublicProfile.onTechnicalDetailsEdit(
    newTechnicalDetails: EditableDetails
) : PublicProfile{
    return copy(
        location = newTechnicalDetails.location,
        occupation = newTechnicalDetails.occupation
    )
}

fun PublicProfile.onOpenQuestionEdit(
    openAnswer: OpenAnswer
) : PublicProfile{
    val newProfile = copy(
        openQuestionAnswer = openQuestionAnswer.replaceOrAdd(
            openAnswer
        ){
            it.questionId == openAnswer.questionId
        }
    )
    return newProfile
}