package osg.cross.landing.ds

import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.painter.Painter
import com.osg.ui.core.resources.painter
import org.jetbrains.compose.resources.DrawableResource
import truematch.crossui.generated.resources.Res
import truematch.crossui.generated.resources.full_logo
import truematch.crossui.generated.resources.logo_text_no_shadow

enum class CommonResources(
    val drawableResource: DrawableResource,
) {
    Logo(
        drawableResource = Res.drawable.full_logo,
    ),
    LogoText(
        drawableResource = Res.drawable.logo_text_no_shadow,
    );

    val painter: Painter
        @Composable
        get() = drawableResource.painter
}