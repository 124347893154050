package com.osg.ice.harmonyTunes.ui

import androidx.compose.foundation.layout.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.unit.dp
import androidx.lifecycle.viewmodel.compose.viewModel
import com.osg.ice.HtDependencies
import com.osg.ice.IceRepository
import com.osg.ice.harmonyTunes.ds.HtRoundStatus
import com.osg.ice.harmonyTunes.model.HarmonyTunesModel
import com.osg.ice.harmonyTunes.model.UserRoundState
import com.osg.ice.harmonyTunes.ui.doubleSideLetter.MultiDecorTextField
import com.osg.ice.state.IceMatchResult
import com.osg.truebase.data.iceDef.IceMultiMediaDownloader
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.ice.generated.resources.Res
import com.osg.truebase.ice.generated.resources.harmony_tunes_instruction_for_player
import com.osg.truebase.player.audio.IPlatformAudioPlayer
import com.osg.truebase.player.audio.PlayerAction
import com.osg.truebase.ui.assigners.wrapper.QuestionDirections
import com.osg.truebase.ui.commonStates.MainViewUi
import com.osg.ui.core.resources.uiText
import com.osg.truebase.ui.theme.LocalSurfaceColor
import com.osg.truebase.ui.theme.darkScheme

class HarmonyTunesImpl(
    private val htRepoLoader: IceRepository,
    private val iceDataStorage: IceMultiMediaDownloader,
    private val audioPlayerLoader: IPlatformAudioPlayer,
) {

    init {
        AppLogger.d("ChallengeAccepted", "ChallengeAccepted init")
    }

    @Composable
    fun HarmonyTunesEntry(
        iceBreakerDependencies: HtDependencies,
        modifier: Modifier = Modifier,
        onGameResults: (IceMatchResult) -> Unit,
    ) {
        val caViewModel = viewModel {
            HarmonyTunesModel(
                iceBreakerDependencies = iceBreakerDependencies,
                htRepo = htRepoLoader,
                iceDataStorage = iceDataStorage,
                platformAudioPlayer = audioPlayerLoader
            )
        }
        val state by caViewModel.uiState.collectAsState()
        state.MainViewUi(
            ladingContent = {
                caViewModel.LandingContent()
            }
        ){ harmonyTunesRound ->
            HarmonyTunesScreenContent(
                modifier = modifier,
                roundIceData = harmonyTunesRound,
                onPlayerAction = caViewModel::action,
                onHintClick = caViewModel::onHintAndPlay,
                onAnswerSubmit = caViewModel::onUserAnswer,
                onNextRound = caViewModel::loadRound,
                onPlayWinSound = caViewModel::playWinSound,
                onGameResults = onGameResults
            )
        }

    }
}


@Composable
fun HarmonyTunesScreenContent(
    roundIceData: HtRoundStatus,
    modifier: Modifier = Modifier,
    onPlayerAction: (PlayerAction) -> Unit = {},
    onHintClick: () -> Unit = {},
    onAnswerSubmit: (UserRoundState) -> Unit = {},
    onNextRound: (Int) -> Unit = {},
    onPlayWinSound: (Int) -> Unit = {},
    onGameResults: (IceMatchResult) -> Unit = {},
) {
    LaunchedEffect(roundIceData) {
        if (roundIceData is HtRoundStatus.IRoundResult.WinRound){
            onPlayWinSound(roundIceData.currentRound)
        }
    }
    LocalSurfaceColor(
        colorScheme = darkScheme
    ){
        Column(
            modifier = modifier.fillMaxSize(),
            verticalArrangement = Arrangement.spacedBy(16.dp),
        ) {
            roundIceData.Header(
                modifier = Modifier.fillMaxWidth().height(300.dp),
                onHintClick = onHintClick
            )
            QuestionDirections(Res.string.harmony_tunes_instruction_for_player.uiText)
            var textFieldState by remember(roundIceData.correctAnswer) { mutableStateOf(TextFieldValue()) }
            MultiDecorTextField(
                answerState = roundIceData.resolveAnswerState(textFieldState),
                modifier = Modifier.align(Alignment.CenterHorizontally),
                onValueChange = {
                    textFieldState = it
                },
                onFinishedAnimation = {
                    if (roundIceData is HtRoundStatus.WaitingForYourAnswer) {
                        onAnswerSubmit(
                            UserRoundState(
                                answer = textFieldState.text,
                                round = roundIceData.currentRound,
                                hints = roundIceData.hintsUsed
                            )
                        )
                    }
                }
            )

            roundIceData.BottomControl(
                onPlayerAction = onPlayerAction,
                onNextRound = onNextRound,
                onGameResults = onGameResults
            )
        }
    }

}
