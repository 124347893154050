package com.osg.ui.core.commonStates

import androidx.compose.runtime.*
import androidx.compose.ui.graphics.ImageBitmap
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.graphics.vector.ImageVector
import com.osg.coroutines.dispechers.ioDispatcher
import com.osg.truebase.data.logger.AppLogger
import com.osg.truebase.data.nodeData.IImageDeclaration
import com.osg.truebase.data.resources.ILocalImages
import com.osg.ui.core.imageProcces.toImageBitmap
import com.osg.ui.core.resources.vector
import com.osg.ui.core.resources.painter
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import org.jetbrains.compose.resources.*
import com.osg.ui.core.resources.rememberResourceState


val emptyImageBitmap: ImageBitmap by lazy { ImageBitmap(1, 1) }

@Immutable
sealed class TrueImage: IImageDeclaration{
    @Immutable
    data class FileResource(
        val localImage: ILocalImages
    ) : TrueImage()

    @Immutable
    data class Resource(
        val resource: DrawableResource
    ) : TrueImage()

    @Immutable
    data class Fetched(
        val data: ByteArray,
    ) : TrueImage() {
        override fun equals(other: Any?): Boolean {
            if (this === other) return true
            if (other == null || this::class != other::class) return false

            other as Fetched

            return data.contentEquals(other.data)
        }

        override fun hashCode(): Int {
            return data.contentHashCode()
        }
    }

    data class Loading(
        val loader: suspend () -> ByteArray
    ): TrueImage()
}


@OptIn(ExperimentalResourceApi::class, InternalResourceApi::class)
suspend fun TrueImage.asBytes(): ByteArray {
    return withContext(ioDispatcher()) {
        when (this@asBytes) {
            is TrueImage.Fetched -> {
                this@asBytes.data
            }

            is TrueImage.Loading -> {
                this@asBytes.loader()
            }

            is TrueImage.Resource -> {
                getDrawableResourceBytes(
                    getSystemResourceEnvironment(),
                    this@asBytes.resource
                )
            }

            is TrueImage.FileResource -> {
                readResourceBytes(this@asBytes.localImage.mediaPath)
            }
        }
    }
}


@OptIn(InternalResourceApi::class)
@Stable
@Composable
fun TrueImage.asBitmap(): ImageBitmap {
    return rememberResourceState(
        key = this,
        getDefault = { emptyImageBitmap },
        block = {
            withContext(ioDispatcher()){
                try {
                    this@asBitmap.asBytes().toImageBitmap()
                }catch (e: Exception) {
                    AppLogger.e("asBitmap", "Error loading image $this\n $e")
                    emptyImageBitmap
                }

            }
        }
    ).value
}

@Stable
@Composable
fun TrueImage.asPainter(
    maxDimPixels: Int = 1024,
    onLoaded: () -> Unit = {}
): Painter {
    if (this is TrueImage.Resource){ // because painter bytes loading is not supported for vector
        return this.resource.painter
    }

    var painterState: Painter? by remember {
        mutableStateOf(null)
    }

    LaunchedEffect(Unit){
        withContext(ioDispatcher()){
            try {
                val painter = fromPainterCache(maxDimPixels)
                withContext(Dispatchers.Main) {
                    painterState = painter
                    onLoaded()
                }
            }catch (e: Exception) {
                AppLogger.e("asBitmap", "Error loading image $this\n $e")
            }
        }
    }

    return painterState ?: loadingPainterState()
}



@Immutable
sealed class TrueVector{
    @Immutable
    data class Literal(
        val data: ImageVector,
    ) : TrueVector()

    @Immutable
    data class Resource(
        val resource: DrawableResource
    ) : TrueVector()
}

fun DrawableResource.asTrueVector(): TrueVector {
    return TrueVector.Resource(this)
}

fun ImageVector.asTrueVector(): TrueVector {
    return TrueVector.Literal(this)
}


@Composable
fun TrueVector.asVector(): ImageVector {
    return when (this) {
        is TrueVector.Resource -> {
            this.resource.vector
        }

        is TrueVector.Literal ->{
            this.data
        }
    }
}

fun ByteArray.asTrueImage(): TrueImage {
    return TrueImage.Fetched(
        data = this,
    )
}

fun DrawableResource.asTrueImage(): TrueImage.Resource {
    return TrueImage.Resource(this)
}


fun ILocalImages.asTrueImage(): TrueImage.FileResource {
    return TrueImage.FileResource(this)
}