package com.osg.appData.profile


import com.osg.appData.common.DataRoot
import com.osg.truebase.data.user.Gender
import com.osg.utils.buildPath
import kotlinx.serialization.Serializable


@Serializable
data class PreferencesMatch (
    val maxDistance: Int? = null,
    val ageMin: Int? = null,
    val ageMax: Int? = null,
    val dateGender: Gender? = null,
){
    companion object{
        fun preferenceMatchPath(uid: String): String{
            return buildPath(
                DataRoot.fullProfilePath(uid),
                FullProfile::personalProfile.name,
                PersonalProfile::preferencesMatch.name
            )
        }
    }
}