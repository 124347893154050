package com.osg.truebase.ui.assigners

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.selection.selectable
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.unit.dp
import com.osg.ui.core.resources.string
import com.osg.truebase.ui.assigners.def.GenObjAssigner
import com.osg.ui.core.commonStates.TrueVector
import com.osg.ui.core.commonStates.asVector
import com.osg.ui.core.resources.UiArray
import com.osg.ui.core.resources.UiText
import com.osg.ui.core.resources.asArray
import com.osg.ui.core.resources.uiText

sealed interface ScData{
    data class ScList(val list: List<SingleChoiceOption>): ScData
    data class ScUiArray(val uiArray: UiArray): ScData

    @Composable
    fun asScOptionList(): List<SingleChoiceOption>{
        return when(this){
            is ScList -> list
            is ScUiArray -> uiArray.asArray().map {
                SingleChoiceOption(
                    it.uiText
                )
            }
        }
    }
}
fun List<SingleChoiceOption>.asScData(): ScData{
    return ScData.ScList(this)
}

fun UiArray.asScData(): ScData{
    return ScData.ScUiArray(this)
}

data class SingleChoiceOption(
    val textOption: UiText,
    val icon: TrueVector? = null
)

data class ScFieldAssigner(
    private val possibleAnswers: ScData
): GenObjAssigner<Int> {
    @Composable
    override fun content(
        modifier: Modifier,
        current: Int?,
        onAnswered: (Int) -> Unit,
    ) {
        SingleChoiceOptionsView(
            modifier = Modifier,
            selectedIdx = current,
            possibleString = possibleAnswers.asScOptionList(),
            onOptionSelected = {
                onAnswered(it)
            },
            enableChange = true,
        )
    }
}

@Composable
fun SingleChoiceOptionsView(
    possibleString: List<SingleChoiceOption>,
    selectedIdx: Int?,
    modifier: Modifier = Modifier,
    enableChange: Boolean,
    onOptionSelected: (Int) -> Unit,
){
    val textStyle = if (possibleString.size > 4){
        MaterialTheme.typography.bodyMedium
    } else {
        MaterialTheme.typography.bodyLarge
    }
    Column(
        modifier
    ) {
        possibleString.indices.forEach { index ->
            Spacer(Modifier.height(12.dp))
            RadioButtonWithImageRow(
                singleChoiceOption = possibleString[index],
                modifier = Modifier,
                index = index,
                selectedIdx = selectedIdx,
                textStyle = textStyle,
                onOptionSelected = onOptionSelected,
                enableChange = enableChange
            )
        }
    }
}



enum class RadioButtonState(
    val background: @Composable () -> Color,
    val border: @Composable () -> Color,
    val isSelected: Boolean,
    val selectable: Boolean
) {
    SELECTED(
        background = {MaterialTheme.colorScheme.primaryContainer},
        border = {MaterialTheme.colorScheme.primary},
        isSelected = true,
        selectable = false
    ),
    OTHER_SELECTED(
        background = {MaterialTheme.colorScheme.surface},
        border = {MaterialTheme.colorScheme.outline},
        isSelected = false,
        selectable = false
    ),
    UNSELECTED(
        background = {MaterialTheme.colorScheme.surface},
        border = {MaterialTheme.colorScheme.outline},
        isSelected = false,
        selectable = true
    )
}

@Composable
fun RadioButtonWithImageRow(
    singleChoiceOption: SingleChoiceOption,
    enableChange: Boolean,
    index : Int,
    selectedIdx: Int?,
    onOptionSelected: (Int) -> Unit,
    textStyle: TextStyle,
    modifier: Modifier = Modifier,
) {
    val radioButtonState = when (selectedIdx) {
        null -> RadioButtonState.UNSELECTED
        index -> RadioButtonState.SELECTED
        else -> RadioButtonState.OTHER_SELECTED
    }

    Surface(
        shape = MaterialTheme.shapes.small,
        color = radioButtonState.background(),
        border = BorderStroke(
            width = 1.dp,
            color = radioButtonState.border()
        ),
        modifier = modifier
            .clip(MaterialTheme.shapes.small)
            .selectable(
                selected = radioButtonState.isSelected,
                enabled = radioButtonState.selectable || enableChange,
                onClick = { onOptionSelected(index) },
                role = Role.RadioButton
            )
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            if (singleChoiceOption.icon != null) {
                Icon(
                    imageVector = singleChoiceOption.icon.asVector(),
                    contentDescription = null,
                    modifier = Modifier
                        .size(56.dp)
                        .clip(MaterialTheme.shapes.extraSmall)
                        .padding(start = 0.dp, end = 8.dp)
                )
                Spacer(Modifier.width(8.dp))
            }

            Text(singleChoiceOption.textOption.string,
                Modifier.weight(1f),
                style = textStyle,
                color = MaterialTheme.colorScheme.onPrimaryContainer)
        }
    }
}