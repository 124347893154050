package osg.uiZone.navigation

import androidx.compose.runtime.Immutable
import androidx.compose.ui.text.intl.Locale
import com.osg.truebase.ui.dialog.FatalErrorType
import com.osg.truebase.ui.theme.supportedLanguages
import osg.uiZone.common.baseRepos.BaseConfig
import osg.uiZone.expect.permission.PermissionUiRationale

@Immutable
data class GlobalState(
    val tmConfig: BaseConfig = BaseConfig(
        language = Locale.current.supportedLanguages
    ),

    val globalPopUp: GlobalPopUp = GlobalPopUp.None,
)

sealed class GlobalPopUp {
    @Immutable
    data object CheckingPermissions : GlobalPopUp(){
        val permissionRequest = PermissionUiRationale.LOCATION
    }
    data class Error(val errorType: FatalErrorType) : GlobalPopUp()
    data object None : GlobalPopUp()
}
