package com.osg.ui.core.resources

import androidx.compose.runtime.*
import kotlinx.coroutines.launch


@Composable
fun <T> rememberResourceState(
    key: Any,
    getDefault: () -> T,
    block: suspend () -> T
): State<T> {
    val scope = rememberCoroutineScope()
    return remember(key) {
        mutableStateOf(getDefault()).apply {
            scope.launch {
                value = block()
            }
        }
    }
}