package com.osg.truebase.ui.components

import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.composed
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.TextFieldValue
import com.osg.truebase.data.logger.AppLogger


fun Modifier.clearFocusOnTap(): Modifier = composed {
    val focusManager = LocalFocusManager.current
    Modifier.pointerInput(Unit) {
        detectTapGestures(
            onTap = {
                focusManager.clearFocus()
            }
        )
    }
}

data class EditableTextUiState(
    val text: String,
    val isValid: Boolean
)

@Composable
fun EditableText(
    modifier: Modifier = Modifier,
    initialText: String?,
    keyboardType: KeyboardType,
    enabled: Boolean = true,
    validation: (String) -> Boolean = { true },
    onFinished: (String) -> Unit,
    contentIfNotEdited: @Composable (EditableTextUiState) -> Unit = {
        Text(
            text = it.text,
            color = if (it.isValid) MaterialTheme.colorScheme.onSurface else MaterialTheme.colorScheme.error,
        )
    },
    contentIfEmpty: @Composable () -> Unit,
) {
    var textFieldState by remember(initialText) { mutableStateOf(TextFieldValue(initialText ?: "")) }
    AppLogger.d("EditableText: ${textFieldState.text}")
    var isImeVisible by remember { mutableStateOf(false) }
    val focusManager = LocalFocusManager.current
    BasicTextField(
        value = textFieldState,
        enabled = enabled,
        onValueChange = {
            textFieldState = it
        },
        keyboardOptions = KeyboardOptions(
            keyboardType = keyboardType,
            imeAction = ImeAction.Done
        ),
        keyboardActions = KeyboardActions(onDone = {
            focusManager.clearFocus()
        }),
        modifier = modifier
            .onFocusChanged {
                isImeVisible = it.isFocused
                if (textFieldState.text.isNotEmpty() && it.isFocused.not() && validation(textFieldState.text)) {
                    onFinished(textFieldState.text)
                }
            },
        decorationBox = { innerTextField ->
            if (isImeVisible) {
                innerTextField()
            } else if (textFieldState.text.isEmpty()) {
                contentIfEmpty()
            } else {
                val isValid = validation(textFieldState.text)
                contentIfNotEdited(
                    EditableTextUiState(
                        text = textFieldState.text,
                        isValid = isValid
                    )
                )
            }
        }
    )
}