package com.osg.ice.challengeAccepted.ui

import androidx.compose.foundation.layout.*
import androidx.compose.material3.Button
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.osg.ice.challengeAccepted.ChallengeAcceptGameMode
import com.osg.ice.challengeAccepted.ds.ChallengeAcceptedAnswer
import com.osg.ice.state.ChallengeAcceptedUiState
import com.osg.ice.state.IceMatchResult
import com.osg.ice.state.IceUiPlayerProfile
import com.osg.ice.state.RoundStatus
import com.osg.truebase.ice.generated.resources.*
import com.osg.truebase.ui.animation.CommonAnimation
import com.osg.truebase.ui.animation.LottieAnimationView
import com.osg.truebase.ui.annotatedText.toAnnotatedString
import com.osg.ui.core.resources.asFormattedText
import com.osg.ui.core.resources.string
import com.osg.truebase.ui.theme.spacing
import org.jetbrains.compose.resources.StringResource


@Composable
fun ChallengeAcceptGameMode.ChallengeAcceptedRoundScreen(
    roundData: ChallengeAcceptedUiState,
    onOptionSelected: (ChallengeAcceptedAnswer) -> Unit,
    onNextRound: (Int) -> Unit,
    onExitWithResult: (IceMatchResult) -> Unit,
    modifier: Modifier = Modifier,
) {
    Scaffold(
        modifier = modifier,
        bottomBar = {
            if (roundData.roundStatus is RoundStatus.ShowAnswers) {
                Button(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(
                            horizontal = MaterialTheme.spacing.medium,
                            vertical = MaterialTheme.spacing.medium
                        )
                        .height(48.dp),
                    onClick = {
                        val nextRound = roundData.round + 1
                        if (nextRound == roundData.totalRounds) {
                            onExitWithResult(
                                roundData.roundStatus.currentResult
                            )
                        } else{
                            onNextRound(nextRound)
                        }
                    },
                ) {
                    Text(text = Res.string.user_continue.string)
                }
            }
        }
    ) { innerPadding ->
        var selected by remember { mutableIntStateOf(-1) }
        Column(
            modifier = Modifier
                .padding(innerPadding)
                .fillMaxSize(),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small)
        ) {
            RoundStatus(
                round = roundData.round,
                totalRounds = roundData.totalRounds
            )
            ChHeader(
                modifier = Modifier.height(200.dp),
                roundStatus = roundData.roundStatus,
                myPlayerProfile = roundData.myProfile,
                opponentProfile = roundData.opponentProfile,
            )
            QuestionTitleDirections(
                modifier = Modifier.padding(horizontal = MaterialTheme.spacing.medium),
                title = roundData.question,
                directions = if (roundData.isMyTurn) {
                    Res.string.challenge_accepted_instruction_for_other.string
                } else {
                    Res.string.challenge_accepted_instruction_for_player.asFormattedText(
                        roundData.opponentProfile.name
                    )
                }
            )
            SingleChoiceCh(
                modifier = Modifier.padding(horizontal = MaterialTheme.spacing.medium),
                possibleString = roundData.options,
                onOptionSelected = {
                    selected = it
                    onOptionSelected(
                        ChallengeAcceptedAnswer(
                            answerIdx = it,
                            round = roundData.round
                        )
                    )
                },
                roundStatus = roundData.roundStatus
            )
        }
    }
}


@Composable
fun RoundStatus(
    modifier: Modifier = Modifier,
    round: Int,
    totalRounds: Int,
) {
    Text(
        modifier = modifier,
        text = "Round ${round+1}/$totalRounds",
        style = MaterialTheme.typography.bodySmall,
    )
}

@Composable
fun ChallengeAcceptGameMode.ChHeader(
    modifier: Modifier = Modifier,
    roundStatus: RoundStatus,
    myPlayerProfile: IceUiPlayerProfile,
    opponentProfile: IceUiPlayerProfile,
) {
    when (roundStatus) {
        is RoundStatus.Initial -> {
            LottieAnimationView(
                modifier = modifier,
                animationData = roundStatus.animation,
                contentScale = ContentScale.FillHeight
            )
        }
        is RoundStatus.WaitingForOpponentAnswer -> LoadingScreen(
            modifier = modifier,
            loadingResourceText = Res.string.waiting_for_other_player
        )
        is RoundStatus.ShowAnswers -> MatchResultViewType(
            myPlayerProfile = myPlayerProfile,
            opponentProfile = opponentProfile,
            modifier = modifier,
            currentResult = roundStatus.currentResult,
            prevResult = roundStatus.prevResult
        )
    }
}

@Composable
fun LoadingScreen(
    modifier: Modifier = Modifier,
    loadingResourceText: StringResource
) {
    Column(
        modifier = modifier.height(250.dp).fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small, Alignment.CenterVertically)
    ) {
        LottieAnimationView(
            modifier = Modifier.weight(2f),
            animationData = CommonAnimation.LOADING.trueAnimation,
            contentScale = ContentScale.FillHeight
        )
        Text(
            textAlign = TextAlign.Center,
            modifier = Modifier.weight(1f, fill = false),
            text = loadingResourceText.string,
            color = MaterialTheme.colorScheme.onSurface,
            style = MaterialTheme.typography.titleMedium
        )
    }

}



@Composable
fun QuestionTitleDirections(
    title: String,
    directions: String,
    modifier: Modifier = Modifier,
) {
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small)
    ) {
        Text(
            text = title,
            style = MaterialTheme.typography.titleMedium,
            color = MaterialTheme.colorScheme.onSurface,
            textAlign = TextAlign.Center,
        )
        Text(
            text = directions.toAnnotatedString(
                MaterialTheme.typography.bodySmall.toSpanStyle().copy(
                    color = MaterialTheme.colorScheme.onSurface
                        .copy(alpha = 0.6f)
                )
            ),
            textAlign = TextAlign.Center,
            modifier = modifier
        )
    }
}
