package osg.uiZone.matchZone.model

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.osg.appData.bots.BOT_UID_PREFIX
import com.osg.appData.matchZone.Price
import com.osg.appData.matchZone.invitation.Invitation
import com.osg.appData.matchZone.invitation.InvitationStatus
import com.osg.ice.IIceBreakerDependencies
import com.osg.ice.state.IceMatchResult
import com.osg.truebase.ui.commonStates.MainViewUiState
import com.osg.truebase.ui.commonStates.toSuccess
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.filterIsInstance
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.launch
import osg.uiZone.common.vmRepo.matchZone.MatchScope
import osg.uiZone.matchZone.entertainment.data.IceInProcces
import osg.uiZone.matchZone.entertainment.data.IceResultsScreenUiState
import osg.uiZone.matchZone.entertainment.data.IceScreenUiStates
import osg.uiZone.matchZone.state.tmEntUi.InvitationContentImpl
import osg.uiZone.navigation.DstArgumentHolder
import osg.uiZone.navigation.IceBreakerDestinationArgument

fun interface UpdateBalance {
    suspend fun updateBalance(value: Double)
}

interface IceBreakerSetupDependencies: InvitationResponse, UpdateBalance {
    suspend fun fetchIceBreakerDependencies(invitation: Invitation): IIceBreakerDependencies
}


class IceBreakerSetupViewModel(
    val iceBreakerSetupDependencies: IceBreakerSetupDependencies = MatchScope().matchZoneRepository
) : ViewModel() {
    lateinit var iceBreakerDependencies : IIceBreakerDependencies
    lateinit var invitation: Invitation

    val uiState: MutableStateFlow<MainViewUiState<IceScreenUiStates>> = MutableStateFlow(MainViewUiState.Loading)

    init {
        viewModelScope.launch {
            invitation = DstArgumentHolder.argumentStateFlow.filterIsInstance<IceBreakerDestinationArgument>().first().invitation
            iceBreakerDependencies = iceBreakerSetupDependencies.fetchIceBreakerDependencies(invitation)
            uiState.value = IceInProcces(iceBreakerDependencies).toSuccess()
        }
    }

    fun onIceMatchResult(iceMatchResult: IceMatchResult){
        val rounds = 4.0 // TODO: get from Invitation
        val potentialPrize = 20.0 // TODO: get from Invitation
        val wonRounds = iceMatchResult.myScore + iceMatchResult.opponentScore
        val prize = (wonRounds/rounds) * potentialPrize
        val state = if ( wonRounds> 0){
            IceResultsScreenUiState.WinGame(prize = Price(prize))
        }else{
            IceResultsScreenUiState.LooseGame()
        }

        uiState.value = state.toSuccess()

        viewModelScope.launch {
            // update balance, send completion invitation
            iceBreakerSetupDependencies.onInvitationResponse(
                InvitationContentImpl(
                    content = invitation.content,
                    type = invitation.type,
                    status = InvitationStatus.COMPLETED,
                )
            )
            val isOtherBot = invitation.senderUid.startsWith(BOT_UID_PREFIX)
            if (prize > 0 && (iceBreakerDependencies.isInviter || isOtherBot)){
                iceBreakerSetupDependencies.updateBalance(prize)
            }

            uiState.value = state.showButton().toSuccess()

        }

    }
}