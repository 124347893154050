@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.osg.truebase.ui_button.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val baseline_event_available_24: DrawableResource by 
      lazy { init_baseline_event_available_24() }

  public val logout_vec: DrawableResource by 
      lazy { init_logout_vec() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("baseline_event_available_24", CommonMainDrawable0.baseline_event_available_24)
  map.put("logout_vec", CommonMainDrawable0.logout_vec)
}

internal val Res.drawable.baseline_event_available_24: DrawableResource
  get() = CommonMainDrawable0.baseline_event_available_24

private fun init_baseline_event_available_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_event_available_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_button.generated.resources/drawable/baseline_event_available_24.xml", -1, -1),
    )
)

internal val Res.drawable.logout_vec: DrawableResource
  get() = CommonMainDrawable0.logout_vec

private fun init_logout_vec(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logout_vec",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.osg.truebase.ui_button.generated.resources/drawable/logout_vec.xml", -1, -1),
    )
)
