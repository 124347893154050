package com.osg.truebase.ice.events

import com.osg.truebase.data.iceDef.CaLiveEvent
import com.osg.truebase.data.iceDef.HtLiveEvent
import com.osg.truebase.data.iceDef.IceLiveEvent
import kotlin.random.Random


interface IceLiveEventResponseType{
    fun genCorrectResponse(botUid: String, otherLiveEvent: IceLiveEvent): IceLiveEvent
    fun genCWrongResponse(botUid: String, otherLiveEvent: IceLiveEvent): IceLiveEvent
    fun genRandomResponse(botUid: String, otherLiveEvent: IceLiveEvent): IceLiveEvent
}

object CaLiveEventResonse: IceLiveEventResponseType{
    override fun genCorrectResponse(botUid: String, otherLiveEvent: IceLiveEvent): IceLiveEvent {
        val otherLiveEvent = otherLiveEvent as CaLiveEvent
        return CaLiveEvent(
            data = otherLiveEvent.data,
            round = otherLiveEvent.round,
            uid = botUid,
            timestamp = otherLiveEvent.timestamp + 10
        )
    }

    override fun genCWrongResponse(botUid: String, otherLiveEvent: IceLiveEvent): IceLiveEvent {
        return CaLiveEvent(
            data = 99,
            round = otherLiveEvent.round,
            uid = botUid,
            timestamp = otherLiveEvent.timestamp + 10
        )
    }

    override fun genRandomResponse(botUid: String, otherLiveEvent: IceLiveEvent): IceLiveEvent {
        return CaLiveEvent(
            data = Random.Default.nextInt(2),
            round = otherLiveEvent.round,
            uid = botUid,
            timestamp = otherLiveEvent.timestamp + 10
        )
    }
}

object HtLiveEventResonse: IceLiveEventResponseType{
    override fun genCorrectResponse(botUid: String, otherLiveEvent: IceLiveEvent): IceLiveEvent {
        val otherLiveEvent = otherLiveEvent as HtLiveEvent
        return HtLiveEvent(
            data = "correct",
            hints = otherLiveEvent.hints,
            round = otherLiveEvent.round,
            uid = botUid,
            timestamp = otherLiveEvent.timestamp + 10
        )
    }

    override fun genCWrongResponse(botUid: String, otherLiveEvent: IceLiveEvent): IceLiveEvent {
        val otherLiveEvent = otherLiveEvent as HtLiveEvent
        return HtLiveEvent(
            data = "wrong",
            hints = otherLiveEvent.hints,
            round = otherLiveEvent.round,
            uid = botUid,
            timestamp = otherLiveEvent.timestamp + 10
        )
    }

    override fun genRandomResponse(botUid: String, otherLiveEvent: IceLiveEvent): IceLiveEvent {
        val otherLiveEvent = otherLiveEvent as HtLiveEvent
        return HtLiveEvent(
            data = "random",
            hints = otherLiveEvent.hints,
            round = otherLiveEvent.round,
            uid = botUid,
            timestamp = otherLiveEvent.timestamp + 10
        )
    }
}
val IceLiveEvent.iceResponseType: IceLiveEventResponseType
    get() = when (this) {
        is CaLiveEvent -> {
            CaLiveEventResonse
        }
        is HtLiveEvent -> {
            HtLiveEventResonse
        }
    }