package com.osg.truebase.serial

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.decodeFromJsonElement

fun JsonElement.findJsonElementByPath(
    pathParts: List<String>
): JsonElement? {
    var currentElement: JsonElement = this
    for (key in pathParts) {
        when (currentElement) {
            is JsonArray -> {
                val index = key.toInt()
                currentElement = currentElement[index]
            }
            is JsonObject -> {
                currentElement = currentElement[key] ?: return null
            }
            is JsonPrimitive -> {
                return null
            }
            JsonNull -> null
        }
    }

    return currentElement
}


fun JsonElement?.replaceChild(childName: String, newChild: JsonElement): JsonElement {
    return when (this) {
        is JsonObject -> {
            val newMap = this.toMutableMap()
            newMap[childName] = newChild
            return JsonObject(newMap)
        }
        is JsonArray -> {
            JsonArray(
                this.toMutableList().apply {
                    val index = childName.toInt()
                    this[index] = newChild
                }
            )
        }
        else -> {
            buildJsonObject {
                put(childName, newChild)
            }
        }
    }
}

//     A                        A
//    /  \                     /  \
//    B1  B2      == >;        B1  B2
//    /    \                   /    \
//   C3     C4                C3     C4*
fun JsonElement?.replaceDeepChild(parts: List<String>, newChild: JsonElement): JsonElement {
    return if (parts.size == 1) {
        replaceChild(parts.first(), newChild)
    } else {
        val child = if (this is JsonObject) {
            this[parts.first()]
        }else{
            null
        }
        replaceChild(
            childName = parts.first(),
            newChild = child.replaceDeepChild(parts.drop(1), newChild)
        )
    }
}

inline fun <reified T> JsonElement.decodeFromJsonElement(): T {
    return Json.decodeFromJsonElement(this)
}