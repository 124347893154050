package com.osg.truebase.ui.assigners

import androidx.compose.animation.core.animateDpAsState
import androidx.compose.foundation.background
import androidx.compose.foundation.interaction.DragInteraction
import androidx.compose.foundation.interaction.Interaction
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.PressInteraction
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.osg.truebase.ui.assigners.def.GenObjAssigner
import com.osg.ui.core.commonStates.TrueVector
import com.osg.ui.core.commonStates.asVector

data class SliderGenAssigner(
    private val initialAnswer: Int,
    private val range: ClosedFloatingPointRange<Float>,
    private val thumbIcon: TrueVector
): GenObjAssigner<Int> {
    @Composable
    override fun content(
        modifier: Modifier,
        current: Int?,
        onAnswered: (Int) -> Unit
    ) = SliderAssignerComponent(
        modifier = modifier,
        initialAnswer = current?: initialAnswer,
        onAnswered = onAnswered,
        range = range,
        trueVector = thumbIcon,
    )
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun SliderAssignerComponent(
    initialAnswer: Int,
    onAnswered: (Int) -> Unit,
    range: ClosedFloatingPointRange<Float>,
    trueVector: TrueVector,
    modifier: Modifier = Modifier,
){
    var sliderValue by remember {
        mutableFloatStateOf(initialAnswer.toFloat())
    }

    val interactionSource = remember { MutableInteractionSource() }
    Slider(
        modifier = modifier,
        value = sliderValue,
        onValueChange = { newValue ->
            sliderValue = newValue
            onAnswered(newValue.toInt())
        },
        valueRange = range,
        interactionSource = interactionSource,
        steps = 0,
        thumb = {
            CustomThumb(
                interactionSource = interactionSource,
                sliderValue = sliderValue,
                trueVector = trueVector,
            )
        },
    )
}

@Composable
fun CustomThumb(
    interactionSource: MutableInteractionSource,
    modifier: Modifier = Modifier,
    sliderValue: Float,
    trueVector: TrueVector,
) {
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Box(modifier = Modifier.size(36.dp))
        IconThumb(
            modifier = Modifier.size(36.dp),
            trueVector = trueVector,
        )
        Box(modifier = Modifier.height(36.dp)){
            SliderLabel(
                interactionSource = interactionSource,
                modifier = Modifier,
                label = sliderValue.toInt().toString(),
            )
        }
    }
}

@Composable
fun IconThumb(
    trueVector: TrueVector,
    modifier: Modifier = Modifier
){
    Icon(
        modifier = modifier,
        imageVector = trueVector.asVector(),
        tint = MaterialTheme.colorScheme.primary,
        contentDescription = null
    )
}

@Composable
fun SliderLabel(
    interactionSource: MutableInteractionSource,
    label: String,
    modifier: Modifier = Modifier
) {
    val interactions = remember { mutableStateListOf<Interaction>() }
    LaunchedEffect(interactionSource) {
        interactionSource.interactions.collect { interaction ->
            when (interaction) {
                is PressInteraction.Press -> interactions.add(interaction)
                is PressInteraction.Release -> interactions.remove(interaction.press)
                is PressInteraction.Cancel -> interactions.remove(interaction.press)
                is DragInteraction.Start -> interactions.add(interaction)
                is DragInteraction.Stop -> interactions.remove(interaction.start)
                is DragInteraction.Cancel -> interactions.remove(interaction.start)
            }
        }
    }
    val interactionAnim by animateDpAsState(
        targetValue = if (interactions.isNotEmpty()) {
            (-65).dp
        } else {
            0.dp
        },
        label = "elevation"
    )
    SliderText(
        label,
        modifier = modifier
            .graphicsLayer {
                translationY = interactionAnim.toPx()
            },
    )
}

@Composable
fun SliderText(
    label: String,
    modifier: Modifier = Modifier
) {
    Text(
        label,
        textAlign = TextAlign.Center,
        color = MaterialTheme.colorScheme.inverseOnSurface,
        modifier = modifier
            .background(
                color = MaterialTheme.colorScheme.inverseSurface,
                shape = RoundedCornerShape(4.dp)
            )
            .padding(4.dp)
    )
}