package com.osg.truebase.data.iceDef

import kotlinx.serialization.Serializable

@Serializable
data class IceContentPreferences(
    val uid: String,
    val seenRoundsIds: List<String> = emptyList(),
)

data class IceDirStruct(
    val settings: List<IceContentPreferences>,
    val events: List<IceLiveEvent>,
) {
    companion object {
        fun iceSettingsPath(gameRootPath: String): String {
            return "$gameRootPath/${IceDirStruct::settings.name}"
        }

        fun iceEventPath(gameRootPath: String): String {
            return "$gameRootPath/${IceDirStruct::events.name}"
        }
    }
}