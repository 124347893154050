package osg.uiZone.common.bar

import androidx.compose.foundation.layout.Row
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.osg.ui.core.commonStates.TrueImage
import com.osg.ui.core.commonStates.asPainter
import com.osg.ui.core.modifirer.noRippleClickable
import osg.uiZone.common.paint.ImagesDuet
import osg.uiZone.matchZone.journey.journeyTitleWeight

@Composable
fun JourneyBarTitle(
    myImage: TrueImage,
    matchImage: TrueImage,
    modifier: Modifier = Modifier,
    onClick: () -> Unit = {}
){
    Row(
        modifier = modifier.noRippleClickable(onClick),
        verticalAlignment = Alignment.CenterVertically
    ) {
        ImagesDuet(
            modifier = Modifier,
            imageHeight = 230.dp / 5f,
            firstImage = myImage.asPainter(256),
            secondImage = matchImage.asPainter(256),
        )
        Text(
            style = MaterialTheme.typography.titleMedium.copy(
                fontWeight = journeyTitleWeight
            ),
            text = "MatchZone"
        )
    }
}