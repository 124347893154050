package com.osg.trumatch.bots.botProfiles

import bots.trueAutoResources.BotsMediaRemoteImages
import com.osg.appData.profile.*
import com.osg.appData.register.OpenDeepQuestions
import com.osg.truebase.data.nodeData.RemoteProfileImage
import com.osg.truebase.data.user.Gender
import com.osg.truebase.preview.data.IsraelLocation

internal val mishelProfile = FullProfile(
    personalProfile = PersonalProfile(

        facebookId = "10225277936830285",
        lastName = "Gerzig",
        preferencesMatch = PreferencesMatch(
            ageMax = 35,
            ageMin = 25,
            maxDistance = 200,
            dateGender = Gender.Male
        ),
    ),
    publicProfile = PublicProfile(
        birthDay = "01/24/1995",
        firstName = "Mishel",
        gender = Gender.Female,
        height = 173,
        occupation = "Social media model",
        picturesCommentList = listOf(
            RemoteProfileImage(
                comment = "",
                mediaPath = BotsMediaRemoteImages.F1B0.mediaPath
            ),
            RemoteProfileImage(
                comment = "Chasing Dreams on the Field ⚽️✨",
                mediaPath = BotsMediaRemoteImages.F1B1.mediaPath
            ),
            RemoteProfileImage(
                comment = "",
                mediaPath = BotsMediaRemoteImages.F1B2.mediaPath
            )
        ),
        stage = UserStage.REGISTERED,
        openQuestionAnswer = listOf(
            OpenAnswer(
                answer = "Hey there! 👋 I'm Mishel, your friendly neighborhood social media guru, navigating the digital landscape one post at a time. 📱✨ Whether I'm sharing the latest trends, capturing unforgettable moments, or spreading positive vibes, you can catch me creating content that sparks joy! 💖\n\nWhen I'm not glued to my phone, you'll find me exploring new places, indulging in a good book, or whipping up something delicious in the kitchen. 🌍📚🍲\n\nI believe in living life to the fullest, embracing every adventure, and connecting with awesome people along the way. Let's embark on this journey together and create some unforgettable memories! 🚀✨\n\nSwipe right if you're up for good vibes, laughter, and maybe a spontaneous dance party! 💃🕺",
                questionId = OpenDeepQuestions.INTRODUCTION
            )
        ),
        location = IsraelLocation.RISHON_LEZION.location
    )
)