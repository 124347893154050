package com.osg.appData.common


import kotlinx.serialization.Serializable


@Serializable
data class NotificationData(
    val fromUid: String= "",
    val toUid: String= "",
    val uiData: NotificationUiData,
)

@Serializable
data class NotificationUiData(
    val type: NotificationType,
    val extraData: String = "TODO",
)